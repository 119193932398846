import * as assign from 'lodash/assign';
import { oidcmgr } from '@inwink/apibase/auth/oidc-manager';
import { logging } from '@inwink/logging';
import { me, preferences, profile } from '../api/backoffice/me';

import { realtimeActions } from './realtimeservice';
import { rightsActions, isAllowed } from './rightsservice';
import { actions as i18nActions } from './i18nservice';
import { openUserData } from './userdata';
import { openUserSettingsData, syncSettings } from './usersettings';
import { States } from './services';
import { notificationsActions } from './notificationservice';

const logger = logging.getLogger("Data");
const reduxlogger = logging.getLogger("Redux");

const INITIAL_STATE = {
    currentUser: null,
    currentUserProfile: null,
    userPrefs: null,
    advancedMode: false,
    editTrads: false
} as States.IAppUserState;

const _g = global as any;

export function userReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    reduxlogger.debug("REDUX " + action.type, action.payload);

    switch (action.type) {
        case "USER_SETCURRENT": {
            let fullname = action.payload.currentUser.email;
            if (action.payload.userProfile && (action.payload.userProfile.firstname || action.payload.userProfile.lastname)) {
                fullname = (action.payload.userProfile.firstname || "") + " " + (action.payload.userProfile.lastname || "");
            }
            return assign({}, state, {
                currentUser: assign({}, state.currentUser, action.payload.currentUser, { fullname }),
                userPrefs: action.payload.userPrefs,
                currentUserProfile: action.payload.userProfile,
                editTrads: false
            });
        }

        case "USER_CLEARCURRENT": {
            return assign({}, state, {
                currentUser: null,
                editTrads: false
            });
        }

        case "USER_ACTIVATEADVANCEDMODE": {
            return assign({}, state, {
                advancedMode: action.payload
            });
        }

        case "USER_SETCURRENTUSERPROFILE": {
            let fullname = state.currentUser.email;
            if (action.payload && (action.payload.firstname || action.payload.lastname)) {
                fullname = (action.payload.firstname || "") + " " + (action.payload.lastname || "");
            }
            return assign({}, state, {
                currentUser: assign({}, state.currentUser, { fullname }),
                currentUserProfile: action.payload,
            });
        }

        case "USER_ACTIVATEEDITTRADS": {
            return assign({}, state, {
                editTrads: action.payload
            });
        }

        case "RIGHTS_SET": {
            if (action.payload.rights && action.payload.rights.indexOf("Root") >= 0) {
                return assign({}, state, {
                    advancedMode: true
                });
            }
            return state;
        }

        default:
            return state;
    }
}

export const actions = {
    clearCurrentUser() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({ type: "USER_CLEARCURRENT" });
            if (_g.localStorage || _g.sessionStorage) {
                _g.localStorage?.clear();
                _g.sessionStorage?.clear();
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        };
    },

    logout() {
        return (dispatch, getState: () => States.IAppState) => {
            return oidcmgr.signOut().then((res: any) => {
                if (
                    res.error === undefined
                    && res.error_description === undefined
                    && res.error_uri === undefined
                ) {
                    actions.clearCurrentUser()(dispatch, getState);
                } else {
                    logger.error(res);
                    throw res;
                }
            }, (err) => {
                // actions.clearCurrentUser()(dispatch, getState);
                logger.error(err);
                throw err;
            });
        };
    },

    checkCurrentUser() {
        return (dispatch, getState: () => States.IAppState) => {
            if (__SERVERSIDE__ || (global as any).disableAuthenticatedFeatures) {
                return;
            }
            const storedata = getState();
            const _oidcmgr = storedata.event ? oidcmgr.for({ event: storedata.event.eventid }) : oidcmgr;
            return _oidcmgr.initialize().then(() => {
                return _oidcmgr.localUser().then((user) => {
                    if (user) {
                        return actions.initCurrentUser()(dispatch, getState);
                    }
                });
            });
        };
    },

    initCurrentUser() {
        return (dispatch, getState: () => States.IAppState) => {
            return Promise.all([me(), preferences(), profile()]).then((res) => {
                const _me = res[0];
                const prefs = res[1];
                const _profile = res[2];
                const state = getState();
                const currentLanguage = (prefs && prefs.preferredLanguage) || state.i18n.currentLanguageCode;

                return i18nActions.loadLanguage(currentLanguage)(dispatch).then(() => {
                    if (!prefs || !prefs.preferredLanguage) {
                        i18nActions.saveLanguagePreferences()(dispatch, getState);
                    }
                    return openUserData(_me.id, 2).then(() => {
                        return openUserSettingsData(_me.id, 1).then(() => {
                            return syncSettings().then(() => {
                                return _me;
                            });
                        });
                    });
                }).then((data) => {
                    return Promise.all([
                        rightsActions.boRights()(dispatch, getState).then(() => {
                            if (isAllowed(getState().rights, { boRights: ["advancedmode.edit"] })) {
                                dispatch({ type: "USER_ACTIVATEADVANCEDMODE", payload: true });
                            }
                        }),
                        rightsActions.inwinkRights()(dispatch, getState),
                    ]);
                }).then(() => {
                    dispatch({
                        type: "USER_SETCURRENT",
                        payload: {
                            currentUser: _me,
                            userPrefs: prefs,
                            userProfile: _profile
                        }
                    });
                });
            }).then(() => {
                realtimeActions.registerUser()(dispatch, getState);
            }).then(() => {
                notificationsActions.checkUnread(true)(dispatch, getState);
            })
                .then(null, (err) => {
                    logger.error("error initializing current user", err);
                    return Promise.reject(err);
                });
        };
    },

    setCurrentUserProfile(p) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({ type: "USER_SETCURRENTUSERPROFILE", payload: p });
        };
    },

    activateAdvancedMode() {
        return (dispatch, getState: () => States.IAppState) => {
            console.log("advanced mode activated");
            const state = getState();
            dispatch({ type: "USER_ACTIVATEADVANCEDMODE", payload: !state.user.advancedMode });
        };
    },

    editTrads(canedit: boolean) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            console.log("edit trads mode activated");
            if (canedit) {
                document.body.classList.add("edittrads");
            } else {
                document.body.classList.remove("edittrads");
            }

            dispatch({ type: "USER_ACTIVATEEDITTRADS", payload: canedit });
        };
    },
};

/* eslint-disable max-classes-per-file */
import { Popover } from '@inwink/modals';
import { Tooltip } from '@inwink/modals/tooltip';
import * as debounce from 'lodash/debounce';
import { React, AppTextLabel, AppAsyncButton } from '../../../commons';

import "./toolbar.less";

export declare type IToolbarItemDisplay = "icon" | "iconandtext" | "text";
export interface IToolbarItem {
    id: string;
    label?: string;
    className?: string;
    icon?: string;
    important?: boolean;
    displayMode?: IToolbarItemDisplay;
    show?: () => boolean;
    badge?: () => number;
    badgeControl?: (props: IToolbarBadgeProps) => any;
    disabled?: () => boolean;
    render?: (item: IToolbarItem, isFromMore?: boolean,
        itemClicked?: (item: IToolbarItem) => void | Promise<any>, selected?: boolean) => any;
    itemClicked?: (arg: React.MouseEvent<any>) => void | Promise<any>;
    component?: any;
}

export interface IToolbarBadgeProps {
    onBadgeUpdate: () => void;
    refresh: any;
}

export interface IToolbarProps {
    className?: string;
    items: IToolbarItem[];
    selected?: string;
    moreButtonOn?: 'left' | 'right';
    onItemClicked?: (item: IToolbarItem) => void | Promise<any>;
    panelsIdWithWarning?: any[];
    panelsWarningMessage?: string;
    itemRefresh?: Record<string, any>;
}

interface IToolbarState {
    showMore?: boolean;
    showMoreItems?: IToolbarItem[];
    showMorePopover?: boolean;
}

export interface IToolbarHeaderWrapperProps {
    className?: string;
    lightMode?: boolean;
    leftToolbar: IToolbarProps;
    rightToolbar: IToolbarProps;
}

export function ToolbarHeaderWrapper(props: IToolbarHeaderWrapperProps) {
    return <header className={"iwtoolbarheaderwrapper " + (!props.lightMode ? "dark " : "light ") + (props.className || "")}>
        {props.leftToolbar ? <Toolbar
            {...props.leftToolbar}
            className={"leftwrapper " + (!props.lightMode ? "dark " : "light ") + (props.leftToolbar.className || "")}
        /> : null}
        {props.rightToolbar ? <Toolbar
            {...props.rightToolbar}
            className={"rightwrapper " + (!props.lightMode ? "dark " : "light ") + (props.rightToolbar.className || "")}
        /> : null}
    </header>;
}

export class Toolbar extends React.Component<IToolbarProps, IToolbarState> {
    itemsContainer = React.createRef<HTMLDivElement>();

    btnMore = React.createRef<HTMLButtonElement>();

    constructor(props) {
        super(props);
        this.debouncedCheckVisibility = debounce(this.checkVisibility.bind(this), 200);
        this.state = {};
    }

    // tslint:disable-next-line: no-empty
    debouncedCheckVisibility() { }

    checkVisibility() {
        if (this.itemsContainer.current) {
            const container = this.itemsContainer.current;
            const items = container.querySelectorAll(".iwtoolbar-item");
            let showMore = false;
            items.forEach((item: HTMLElement) => {
                if (item.offsetTop > 0) {
                    showMore = true;
                }
            });

            if (showMore !== this.state.showMore) {
                this.setState({ showMore: showMore, showMorePopover: false });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.debouncedCheckVisibility);
    }

    componentDidMount() {
        window.addEventListener("resize", this.debouncedCheckVisibility);
        this.checkVisibility();
    }

    componentDidUpdate() {
        this.checkVisibility();
    }

    showMore = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        if (this.itemsContainer.current) {
            const container = this.itemsContainer.current;
            const itemsElements = container.querySelectorAll(".iwtoolbar-item");
            const moreItems = [];
            itemsElements.forEach((item: HTMLElement) => {
                if (item.offsetTop > 0) {
                    moreItems.push(this.props.items[item.dataset.itemidx]);
                    // startIndex = item.dataset.itemidx;
                }
            });

            this.setState({
                showMoreItems: moreItems.filter((item) => {
                    if (item.show) {
                        return item.show();
                    }

                    return true;
                }),
                showMorePopover: true
            });
        }
    };

    hideMoreItems = () => {
        this.setState({ showMorePopover: false });
    };

    moreItemsClicked = (item) => {
        this.setState({ showMorePopover: false });
        if (this.props.onItemClicked) {
            this.props.onItemClicked(item);
        }
    };

    render() {
        const morebtnPosition = this.props.moreButtonOn ? this.props.moreButtonOn : "right";
        const classNames = ["iwtoolbar", "dark", "pos-" + morebtnPosition];
        let style = null;
        if (this.props.className) {
            classNames.push(this.props.className);
        }

        let itemsToRender = this.props.items && this.props.items.filter((item) => {
            if (item.show) {
                return item.show();
            }
            return true;
        });

        if (this.state.showMore) {
            classNames.push("showmore");
            const container = this.itemsContainer.current;
            const itemsElements = container.querySelectorAll(".iwtoolbar-item");
            let startIndex = null;
            const selectedItem = this.props.selected && this.props.items.filter((item) => item.id === this.props.selected)[0];
            const selectedIndex = selectedItem && this.props.items.indexOf(selectedItem);

            itemsElements.forEach((item: HTMLElement) => {
                if (item.offsetTop > 0 && !startIndex) {
                    startIndex = item.dataset.itemidx;
                }
            });

            if (selectedItem && selectedIndex >= startIndex) {
                itemsToRender = [...itemsToRender];
                itemsToRender.splice(selectedIndex, 1);
                itemsToRender = [selectedItem, ...itemsToRender];
            }

            if (morebtnPosition === "right") {
                style = {
                    paddingRight: this.btnMore && this.btnMore.current && ((this.btnMore.current.clientWidth + 4) + "px")
                };
            } else {
                style = {
                    paddingLeft: this.btnMore && this.btnMore.current && ((this.btnMore.current.clientWidth + 4) + "px")
                };
            }
        }

        const items = itemsToRender.map((item, idx) => <ToolbarItem
            {...this.props}
            key={item.id + '#' + idx}
            item={item}
            itemIndex={this.props.items.indexOf(item)}
            important={item.important}
        />);

        const moreItems = this.state.showMoreItems && this.state.showMoreItems.map((item, idx) => <ToolbarItem
            {...this.props}
            key={item.id + '#' + idx}
            item={item}
            itemIndex={idx}
            isFromMore={true}
            onItemClicked={this.moreItemsClicked}
            important={item.important}
        />);
        return <div className={classNames.join(" ")} style={style}>
            <div className="iwtoolbar-items" ref={this.itemsContainer}>{items}</div>
            <button type="button" className="btnmore" onClick={this.showMore} ref={this.btnMore}>
                <i className="inwink-more-v" /></button>
            <Popover
                className={"iwtoolbar-moreitemspopover" + (this.props.className ? " " + this.props.className : "")}
                show={this.state.showMorePopover}
                parent={this.btnMore.current}
                onhide={this.hideMoreItems}
            >
                <div className="iwtoolbar-moreitems">
                    {moreItems}
                </div>
            </Popover>
        </div>;
    }
}

interface IToolbarItemProps extends IToolbarProps {
    item: IToolbarItem;
    itemIndex: number;
    isFromMore?: boolean;
    important?: boolean;
}

class ToolbarItem extends React.Component<IToolbarItemProps, any> {
    itemClicked = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        let res = null;
        if (this.props.item.itemClicked) {
            res = this.props.item.itemClicked(arg);
        }
        let globalRes = null;
        if (this.props.onItemClicked) {
            globalRes = this.props.onItemClicked(this.props.item);
        }

        return res || globalRes;
    };

    // tslint:disable-next-line: no-empty
    badgeUpdate = () => { };

    render() {
        const isSelected = this.props.selected === this.props.item.id;
        const displaymode = this.props.item.displayMode || "iconandtext";
        let className = "iwtoolbar-item display-" + displaymode
            + (this.props.item.className ? " " + this.props.item.className : "") + (isSelected ? " selected" : "");
        let content = null;
        let badge = null;
        const important = this.props.important ? 'important' : '';
        let displayWarning = false;
        let tooltipNode = null;

        if (this.props.panelsIdWithWarning && this.props.item) {
            for (let i = 0; i < this.props.panelsIdWithWarning.length; i++) {
                if (this.props.panelsIdWithWarning[i] === this.props.item.id) {
                    displayWarning = true;
                }
            }
        }

        if (displayWarning) {
            tooltipNode = (
                <Tooltip content={this.props.panelsWarningMessage || 'Error'} component="span" className="tooltip">
                    <i className="inwink-warning" />
                </Tooltip>
            );
        }

        if (this.props.item.badge) {
            const badgeValue = this.props.item.badge();
            if (badgeValue) {
                badge = <div className="badge"><span className="itemscount">{badgeValue}</span></div>;
            }
        }
        if (this.props.item.badgeControl) {
            badge = this.props.item.badgeControl({
                onBadgeUpdate: this.badgeUpdate,
                refresh: this.props.itemRefresh && this.props.itemRefresh[this.props.item.id || ""]
            });
        }
        if (this.props.item.render) {
            className = className + " customrender";
            content = this.props.item.render(this.props.item, this.props.isFromMore, this.props.onItemClicked, isSelected);
        } else if (displaymode === "icon") {
            if (this.props.isFromMore) {
                content = <AppAsyncButton
                    title={this.props.item.label}
                    disabled={this.props.item.disabled && this.props.item.disabled()}
                    onClick={this.itemClicked}
                    className={`iwtoolbar-action ${important} ${badge ? 'withbadge' : ''}`}
                >
                    {this.props.item.icon && <i className={this.props.item.icon} style={{ marginRight: 10 }} />}
                    {this.props.item.label && <AppTextLabel i18n={this.props.item.label} />}
                    {tooltipNode}
                    {badge}
                </AppAsyncButton>;
            } else {
                content = <AppAsyncButton
                    title={this.props.item.label}
                    disabled={this.props.item.disabled && this.props.item.disabled()}
                    onClick={this.itemClicked}
                    className={`iwtoolbar-action ${important} ${badge ? 'withbadge' : ''}`}
                >
                    <i className={this.props.item.icon} />
                    {badge}
                </AppAsyncButton>;
            }
        } else if (displaymode === "text") {
            content = <AppAsyncButton
                title={this.props.item.label}
                disabled={this.props.item.disabled && this.props.item.disabled()}
                onClick={this.itemClicked}
                className={`iwtoolbar-action ${important} ${badge ? 'withbadge' : ''}`}
            >
                <AppTextLabel i18n={this.props.item.label} />
                {badge}
            </AppAsyncButton>;
        } else {
            content = <AppAsyncButton
                title={this.props.item.label}
                disabled={this.props.item.disabled && this.props.item.disabled()}
                onClick={this.itemClicked}
                className={`iwtoolbar-action ${important} ${badge ? 'withbadge' : ''}`}
            >
                {this.props.item.icon ? <i className={this.props.item.icon} /> : null}
                {this.props.item.label ? <AppTextLabel className="label" i18n={this.props.item.label} /> : null} {tooltipNode}
                {badge}
            </AppAsyncButton>;
        }

        return <div id={"toolbaritem" + this.props.item.id} className={className} data-itemidx={this.props.itemIndex}>
            {content}
        </div>;
    }
}

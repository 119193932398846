/* eslint-disable max-classes-per-file */
import * as debounce from 'lodash/debounce';
import { React, AppTextLabel } from '../../../commons';
import type { IDropdownEditorContentProps, IDrowdownEditorItem } from './index';
import { SelectableItem } from '../selectableitem';

export interface IDrowdownEditorPopoverProps extends IDropdownEditorContentProps {
    onCompleted?: (res?) => void;
}

interface IDrowdownEditorPopoverState {
    filter: string;
    selection: IDrowdownEditorItem[];
}

export class DrowdownEditorPopover extends React.Component<IDrowdownEditorPopoverProps, IDrowdownEditorPopoverState> {
    filterInput = React.createRef<HTMLInputElement>();

    constructor(props: IDrowdownEditorPopoverProps) {
        super(props);
        this.setFilter = debounce(this.setFilter, 300);
        this.state = {
            filter: "",
            selection: props.selection
        };
    }

    componentDidMount() {
        if (this.filterInput && this.filterInput.current) {
            this.filterInput.current.oninput = (evt: Event) => {
                this.setFilter((evt.currentTarget as any).value);
                return null;
            };
        }
    }

    setFilter = (txt) => {
        this.setState({ filter: txt });
    };

    filterChanged = (arg: React.FormEvent<HTMLInputElement>) => {
        this.setFilter(arg.currentTarget.value);
    };

    onValidate = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onChange(this.state.selection);
        this.props.onCompleted();
    };

    selectionChange = (selection) => {
        this.setState({ selection: selection });
        if (!this.props.multiselect && selection && selection.length) {
            this.props.onChange(selection);
            this.props.onCompleted();
        }
    };

    close = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onCompleted();
    };

    render() {
        const showSearch = this.props.allItems && this.props.allItems.length > 10;
        let editorItems = this.props.allItems;
        if (this.state.filter) {
            const filter = this.state.filter.toLowerCase();
            editorItems = editorItems.filter((item) => {
                return item.label.toLowerCase().indexOf(filter) >= 0;
            });
        }

        let content = null;
        const classses = ["iw-dropdowneditor-popover-items"];
        if (editorItems && editorItems.length) {
            content = editorItems.map((item) => {
                return <DrowdownEditorPopoverItem
                    {...this.props}
                    key={item.key}
                    selection={this.state.selection}
                    item={item}
                    onChange={this.selectionChange}
                />;
            });
        } else {
            classses.push("empty");
            content = <AppTextLabel i18n="dropdowneditor.empty" />;
        }

        let title = null;
        if (this.props.modaleTitle) {
            if (typeof this.props.modaleTitle === "string") {
                title = <AppTextLabel className="title" i18n={this.props.modaleTitle} />;
            } else {
                title = <span className="title">{React.createElement(this.props.modaleTitle, this.props)}</span>;
            }
        }

        return <div className="iw-dropdowneditor-popover-content">
            {title ? <header className="header-title">
                {title}
                <button type="button" className="btnclose" onClick={this.close}>
                    <i className="inwink-dialog-cancel" />
                </button>
            </header> : null}
            {showSearch ? <header>
                <input ref={this.filterInput} type="search" />
                <i className="inwink-search" />
            </header> : null}
            <section className={classses.join(" ")}>
                {content}
            </section>
            {this.props.multiselect ? <footer>
                <button type="button" className="important" onClick={this.onValidate}>
                    <AppTextLabel i18n="actions.validate" /></button>
            </footer> : null}
        </div>;
    }
}

export interface IDrowdownEditorPopoverItemProps extends IDrowdownEditorPopoverProps {
    item: IDrowdownEditorItem;
}
export class DrowdownEditorPopoverItem extends React.Component<IDrowdownEditorPopoverItemProps, any> {
    itemClicked = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        let hasExisting = false;
        let selection = (this.props.selection && this.props.selection.filter((s) => {
            if (s.key === this.props.item.key) {
                hasExisting = true;
                return false;
            }

            return true;
        })) || [];

        if (!hasExisting) {
            if (this.props.multiselect) {
                selection.push(this.props.item);
            } else {
                selection = [this.props.item];
            }
        }

        this.props.onChange(selection);
        // if (!this.props.multiselect) {
        //     this.props.onChange(selection)
        //     this.props.onCompleted();
        // }
    };

    render() {
        const isSelected = this.props.selection && this.props.selection.some((s) => s.key === this.props.item.key);
        return <SelectableItem onClick={this.itemClicked} isSelected={isSelected}>
            {this.props.item.label}
        </SelectableItem>;
    }
}

import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { DynamicFieldWrapper } from '@inwink/entityform/dynamicfieldwrapper';
import * as React from 'react';

export class BoolFieldEditor extends React.Component<IDynamicFieldEditorProps, any> {
    onValuechangeEventHandler(arg) {
        if (this.props.forceReadOnly) {
            return;
        }
        const value = (arg.currentTarget as HTMLInputElement).checked;
        this.props.onChange(this.props.entityFieldFormTemplate.key, value);
    }

    render() {
        const id = Math.random().toString(36).substring(2, 10);
        return <DynamicFieldWrapper
            {...this.props}
            labelIsAfter={true}
            labelClassName="forcheckbox"
            labelHtmlFor={this.props.entityFieldFormTemplate.key + '_' + id}
        >
            <div className={"checkbox-inwink" + (this.props.forceReadOnly ? " readonly" : "")}>
                <input
                    id={this.props.entityFieldFormTemplate.key + '_' + id}
                    name={this.props.entityFieldFormTemplate.key}
                    checked={this.props.entityFieldValue || false}
                    onChange={this.onValuechangeEventHandler.bind(this)}
                    readOnly={this.props.forceReadOnly}
                    type="checkbox"
                />
                <label htmlFor={this.props.entityFieldFormTemplate.key + '_' + id} />
            </div>
        </DynamicFieldWrapper>;
    }
}

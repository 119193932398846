/* eslint-disable max-classes-per-file */
import type { Entities } from '@inwink/entities/entities';
import { AsyncButton } from '@inwink/buttons';
import { React, AppTextLabel, Loader, assign, IPopoverManager, connectwith } from '../../../../commons';
import { editUserNotification } from '../../../../data/usernotification';
import { EntityIcon } from "../../../ui/entityicon";
import type { ICMSContext } from "../../../cmscontext";
import type { States } from "../../../../services/services";
import { confirmModal } from "../../../ui/modals/confirmmodal";
import type { IEntityPageConfiguration, IEntityViewsState } from "../entitypagebase.props";
import type { IDataSourceV3 } from '../../../../api/base/datasource';

import "./entitynotifs.less";

// const containerTransition = {
//     visible: { delayChildren: 200, staggerChildren: 50 },
//     hidden: {}
// }

// const itemTransition = {
//     visible : { x: 0, opacity : 1 },
//     removed : { scale: 0.5, opacity : 0 },
//     hidden: {x: 20, opacity : 0}
// }

// var NotifItemWrapper : any = posed.section(containerTransition);
// var NotifItem : any  = posed.div(itemTransition);

export function showNotifications(popovermgr: IPopoverManager, elt, props: IToolbarNotifsProps) {
    return popovermgr.popoverPortal(elt, ToolbarNotifs, { ...props, popovermgr, parent: parent }, "entitygridtoolbarnotifs");
}

export interface IToolbarNotifsProps {
    popovermgr?: IPopoverManager;
    cmscontext: ICMSContext;
    viewsState: IEntityViewsState;
    entityconfiguration: IEntityPageConfiguration;
    onCompleted?: (res?) => void;
    parent?: any;
    user?: States.IAppUserState;
    event?: States.IEventState;
    i18n?: States.i18nState;
}

interface IToolbarNotifsState {
    loading: boolean;
    itemVisibility: string;
    mailTriggersDataSource: IDataSourceV3<Entities.IEntity>;
    subscriptions: Entities.IEntity[];
}

@connectwith((state: States.IAppState) => {
    return {
        user: state.user,
        event: state.event,
        i18n: state.i18n
    };
})
export class ToolbarNotifs extends React.Component<IToolbarNotifsProps, IToolbarNotifsState> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            itemVisibility: "hidden",
            mailTriggersDataSource: null,
            subscriptions: null
        };
    }

    componentDidMount() {
        this.loadSubscriptions();
    }

    loadSubscriptions() {
        const expression = {
            and: [
                { name: "mailUserId", op: "eq", val: this.props.user.currentUser.id },
                { name: "targetEntityName", op: "eq", val: this.props.entityconfiguration.entityName }
            ]
        };

        if (this.props.entityconfiguration.notif.subscriptionsFilter) {
            const addendum = this.props.entityconfiguration.notif.subscriptionsFilter();
            if (addendum) {
                expression.and.push(addendum);
            }
        }
        this.props.cmscontext.getEntityConfiguration("MailTrigger", {
            ...this.props,
            forNotification: true
        } as any).then((conf) => {
            return conf.datasource.query({
                selects: {$all: true},
                expression: expression,
                order: [{ by: 'ValidFrom', desc: true }]
            }, []).then((subscriptions) => {
                this.setState({ loading: false, subscriptions: subscriptions.data, mailTriggersDataSource: conf.datasource }, () => {
                    this.setState({ itemVisibility: "visible" });
                });
            }, (err) => {
                console.error(err);
                this.setState({ loading: false });
            });
        });
    }

    addItem = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        this.props.onCompleted();
        const newitem: any = this.props.entityconfiguration.notif.newItem ? this.props.entityconfiguration.notif.newItem() : {};
        newitem.targetEntityName = this.props.entityconfiguration.entityName;
        newitem.mailUserId = this.props.user.currentUser.id;
        newitem.isEnabled = true;
        newitem.isTransactional = false;
        newitem.notificationModes = "mail";
        newitem.expression = (this.props.viewsState && this.props.viewsState.queryExpression) ? JSON.stringify({ and: [assign({}, this.props.viewsState.queryExpression)] }) : null;
        return editUserNotification(this.props,
            this.props.popovermgr,
            this.props.cmscontext,
            this.props.cmscontext.relatedTo,
            newitem,
            this.props.entityconfiguration);
    };

    editItem = (item) => {
        this.props.onCompleted();
        return editUserNotification(this.props,
            this.props.popovermgr,
            this.props.cmscontext,
            this.props.cmscontext.relatedTo,
            item,
            this.props.entityconfiguration)
            .then(() => {
                this.loadSubscriptions();
            });
    };

    removeItem = (item) => {
        return confirmModal(this.props.popovermgr, "modal.title.deleteusernotif", "modal.title.deleteusernotif.message").then((confirmed) => {
            if (confirmed) {
                return this.state.mailTriggersDataSource.delete([item.id]).then(() => {
                    this.loadSubscriptions();
                });
            }
        });
    };

    render() {
        let content;
        if (this.state.subscriptions && this.state.subscriptions.length) {
            const items = this.state.subscriptions.map((mt) => {
                return <ToolbarNotifItem {...this.props} key={mt.id} item={mt} onEdit={this.editItem} onRemove={this.removeItem} />;
            });

            // content = <NotifItemWrapper className="subscriptions" pose={this.state.itemVisibility}>
            content = <div className="subscriptions">
                {items}
            </div>;
            // </NotifItemWrapper>
        } else if (this.state.loading) {
            content = <div className="loading">
                <Loader />
            </div>;
        } else {
            content = <div className="nosubscriptions">
                <AppTextLabel i18n="entitygrid.notifs.empty" />
            </div>;
        }

        return <div className="entitynotifs">
            <header>
                <AppTextLabel i18n="entitygrid.notifs.title" component="h4" />
            </header>
            <div className="entitynotifs-items">
                {content}
            </div>
            <footer className="entitynotifs-add">
                <button onClick={this.addItem}>
                    <i className="inwink-add" /> <AppTextLabel i18n="actions.add" />
                </button>
            </footer>
        </div>;
    }
}

interface IToolbarNotifItemProps extends IToolbarNotifsProps {
    item: any;
    onRemove: (item) => Promise<void>;
    onEdit: (item) => Promise<void>;
}

class ToolbarNotifItem extends React.Component<IToolbarNotifItemProps, any> {
    edit = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onEdit(this.props.item);
    };

    remove = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        return this.props.onRemove(this.props.item);
    };

    render() {
        // return <NotifItem className="subscriptionitem clickable" onClick={this.edit}>
        return <div className="subscriptionitem clickable" onClick={this.edit}>
            <div className="detail">
                <EntityIcon entityName={this.props.item.targetEntityName} defaultIcon="inwink-bell" /> <span className="name">{this.props.item.name}</span>
            </div>
            <div className="actions">
                <AsyncButton onClick={this.remove}><i className="inwink-trash" /></AsyncButton>
            </div>
        </div>;
        // </NotifItem>
    }
}

import { getAuthTenantEntityDataSource } from '@@api/customers/authtenants';
import { getCommunityAPIProxy, getCommunityEntityDataSource } from './index';

export function getCommunityDetail(communityId: string): Promise<any> {
    const proxy = getCommunityAPIProxy();
    return Promise.all([
        proxy.getJson(`communityinfo/${communityId}`),
        proxy.getJson(`${communityId}/communitydetail/${communityId}`).then((res) => {
            res.id = res.communityId;
            return res;
        }),
        getCommunityAPIProxy().getJson(`${communityId}/parents-details`).then((res) => res, () => null)
    ]).then((results) => {
        return Object.assign({}, results[0], results[1], {
            parentNames: results[2]
        });
    });
}

export interface ICommunityParentNames {
    tenantId: string;
    tenantName: string;
    customerId: string;
    customerName: string;
}

export function updateCommunity(communityId: string, tenantId: string, customerId: string, conf: any) {
    return Promise.all([
        getAuthTenantEntityDataSource(customerId, tenantId, 'authtenantcommunity', 'authtenantcommunity')
            .update({ ...conf, id: communityId }),
        getCommunityEntityDataSource(communityId, "communitydetail", "communitydetail").update({ ...conf, id: communityId })
    ]);
}

export function getAvailableLanguages(communityId: string) : Promise<{ supportedLanguages: string[] }> {
    return getCommunityAPIProxy().getJson(`${communityId}/available-languages`);
}

import * as debounce from 'lodash/debounce';
import {
    React, AppTextLabel
} from '../../../../commons';
import type { IEntityScreenProps } from '../entitypagebase';
import { SelectionToggle } from '../tools/selectiontoggle';
import { EntityViewSelector } from '../tools/viewselector';

import './header.less';

export interface IEntityScreenSmartphoneHeaderProps extends IEntityScreenProps {
}

export class EntityScreenSmartphoneHeader extends React.Component<IEntityScreenSmartphoneHeaderProps, any> {
    constructor(props: IEntityScreenSmartphoneHeaderProps) {
        super(props);

        this.notifySearchTermChanged = debounce(this.notifySearchTermChanged, 400);
        this.state = {
            search: props.viewsState?.currentview?.content?.search
        };
    }

    searchTermChanged = (arg: React.FormEvent<HTMLInputElement>) => {
        const search = arg.currentTarget.value;
        this.setState({ search });
        this.notifySearchTermChanged(search);
    };

    notifySearchTermChanged = (search) => {
        this.props.viewsManager.updateCurrentViewContent({ search: search });
    };

    onSelectionToggled = () => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ refresh: new Date() });
    };

    render() {
        const translate = this.props.i18nHelper.translate;
        const viewcontent = this.props.viewsState?.currentview?.content;
        const filtersCount = (viewcontent && Object.keys(viewcontent.filters).length) || 0;
        let showSelectionToggle = true;
        let showViewSelector = true;
        let showSearch = true;

        if (!this.props.entityconfiguration.searchFields || !this.props.entityconfiguration.searchFields.length) {
            showSearch = false;
        }

        if (this.props.runMode === "picker" || this.props.runMode === "removedentitypage") {
            showViewSelector = false;
            if (!this.props.multiselect) {
                showSelectionToggle = false;
            }
        }

        return <header>
            <div className="searchpanel">
                {showSearch ? <>
                    <i className="inwink-search" />
                    <input
                        type="search"
                        autoComplete="off"
                        spellCheck={false}
                        value={this.state.search}
                        placeholder={translate("display.search")}
                        autoFocus={true}
                        onChange={this.searchTermChanged}
                    />
                </> : null}
                {showSelectionToggle ? <SelectionToggle {...this.props} onToggled={this.onSelectionToggled} /> : null}

                <span onClick={this.props.helpers.openFilters} className="filters">
                    {/* <span>
                        <AppTextLabel i18n={"actions.filter_token"} />
                    </span>
                     */}
                    <i className="inwink-filter" />
                    {filtersCount ? <span className="filtercount">{filtersCount}</span> : null}
                </span>
            </div>
            <div className="itemspanel">
                <div className="itemscount">
                    <AppTextLabel
                        i18n="entitypage.rowcount"
                        inject={{ rowCount: ((this.props.viewsState && this.props.viewsState.rowCount) || "0") }}
                    />
                </div>
                {showViewSelector ? <EntityViewSelector
                    entityName={this.props.entityName}
                    popovermgr={this.props.popovermgr}
                    viewsManager={this.props.viewsManager}
                    viewsState={this.props.viewsState}
                    i18n={this.props.i18n}
                /> : null}
            </div>
        </header>;
    }
}

const NULLABLE_ENTITY_FIELDS = ["person.isregistered", "user.isregistered", "person.allownetworking", "user.allownetworking"];

export function isNullableEntityField(val, entityname?) {
    if (!val) { return false; }
    if (!entityname || val.indexOf('.') >= 0) {
        return NULLABLE_ENTITY_FIELDS.indexOf(val.toLowerCase()) >= 0;
    }
    const tmp = [entityname.toLowerCase(), val.toLowerCase()].join('.');
    return NULLABLE_ENTITY_FIELDS.indexOf(tmp) >= 0;
}

import { React } from '../../../../commons';
import type { ICMSContext } from "../../../cmscontext";
import type { IEntityPageConfiguration } from "../../entitypagev3/entitypagebase.props";
import { ExpressionString } from './expressionstring';
import { ExpressionFile } from './expressionfile';
import { ExpressionBool } from './expressionbool';
import { ExpressionGuid } from './expressionguid';
import { ExpressionNumber } from './expressionnumber';
import { ExpressionDate } from './expressiondate';
import { ExpressionMultiSelectList } from './expressionmultiselectlist';
import type { IFieldPickerItem } from '../../fieldpickerv3/definitions';
import type { IExpressionDesignerOptions, IExpressionProps } from '../definitions';
import { ExpressionEntityV3 } from './expressionentityv3';
import { ExpressionEntitiesV3 } from './expressionentitiesv3';
import {ExhibitorAccountRolesEditor} from '../../../../entities/event/exhibitorAccount/editors/exhibitoraccountroleseditor';
import type { Entities } from '@inwink/entities/entities';

interface IAddExpressionValuesProps {
    field: IFieldPickerItem;
    update: (arg: any, cb?: () => void) => void;
    save: () => void;
    allowSave: boolean;
    setAllowSave: (allowSave: boolean) => void;
    cmscontext: ICMSContext;
    setExpression?: (expressions: any, cb?: () => void) => void;
    expressions?: any;
    entityconfig: IEntityPageConfiguration;
    options?: IExpressionDesignerOptions;
}

export function AddExpressionValues(props: IAddExpressionValuesProps) {
    let valueElt;

    const lowerTypeName = props.field && props.field.field ? props.field.field.type.toLowerCase() : null;

    const _props: IExpressionProps = {
        updateInline: props.update,
        entityField: props.field?.field,
        fieldItem: props.field,
        setAllowSave: props.setAllowSave,
        inline: true,
        save: props.save,
        allowSave: props.allowSave,
        cmscontext: props.cmscontext,
        setExpression: props.setExpression,
        expressions: props.expressions,
        entityconfig: props.entityconfig,
    };

    // if (props.field
    //     && props.options?.specificExpressionComputings
    //     && props.options.specificExpressionComputings[props.field.key]
    //     && props.options.specificExpressionComputings[props.field.key].before) {
    //     props.options.specificExpressionComputings[props.field.key].before(_props);
    // }

    if ((['text', 'multilinetext', 'mail', 'regex', 'string'] as any).indexOf(lowerTypeName) > -1) {
        valueElt = ExpressionString;
    } else if (lowerTypeName === 'number') {
        valueElt = ExpressionNumber;
    } else if (lowerTypeName === 'date') {
        valueElt = ExpressionDate;
    } else if (lowerTypeName === 'selectlist') {
        valueElt = ExpressionMultiSelectList;
    } else if (lowerTypeName === 'bool' || lowerTypeName === 'nullablebool') {
        valueElt = ExpressionBool;
    } else if (lowerTypeName === 'guid') {
        valueElt = ExpressionGuid;
    } else if (lowerTypeName === 'multiselectlist') {
        valueElt = ExpressionMultiSelectList;
    } else if (lowerTypeName === 'file' || lowerTypeName === 'image' || lowerTypeName === 'picture') {
        valueElt = ExpressionFile;
    } else if (lowerTypeName === 'entities') {
        valueElt = ExpressionEntitiesV3;
    } else if (lowerTypeName === 'entity') {
        valueElt = ExpressionEntityV3;
        const args: any = {};
        if (props.field.key === "package") {
            args.productType = ["package"];
        }
        (_props as any).entityArgs = args;
    } else if (lowerTypeName === 'array') {
        if (props.field.entityname.toLowerCase() === "person" && props.field.field.key.toLowerCase() === "kinds") {
            valueElt = function cmpt(p: IExpressionProps) {
                const entityField: Entities.IEntityFieldTemplateV3 = Object.assign({}, p.entityField, { value: "PersonKind" });
                let expressions = null;
                if (props.expressions && props.expressions && props.expressions.op === 'withsome') {
                    expressions = { any: { val: props.expressions.val } };
                }
                return <ExpressionEntitiesV3
                    {...(p as any)}
                    entityField={entityField}
                    idFields={["code"]}
                    expressions={expressions}
                    updateInline={(res, cb) => {
                        if (res && res.expression && res.expression.any) {
                            props.update({ expression: {
                                name: entityField.key,
                                op: 'withsome',
                                val: res.expression.any.val
                            } }, cb);
                        } else {
                            props.update(null, cb);
                        }
                    }}
                />;
            };
        } else if (props.field.entityname.toLowerCase() === "exhibitoraccount"
            && props.field.field.key?.toLowerCase() === "roles") {
            valueElt = function cmpt(p: IExpressionProps) {
                return <ExhibitorAccountRolesEditor
                    entityName="exhibitorAccount"
                    noLabel={true}
                    eventid={props.cmscontext?.eventdetail?.id}
                    displayLanguage={props.cmscontext?.displayLanguage}
                    languages={props.cmscontext?.languages}
                    entityFieldFormTemplate={p.entityField}
                    entityFieldValue={p.expressions?.val}
                    onChange={(fieldKey, fieldValue) => {
                        props.update({expression: {
                            name: fieldKey,
                            op: 'some',
                            val: fieldValue
                        }});
                        p.setAllowSave(fieldValue?.length);
                    }}
                    {...p as any} />;
            };
        }
    }

    if (!valueElt) { return null; }

    return <div id="prop-wrapper">{React.createElement(valueElt, _props)}</div>;
}

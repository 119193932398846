import { IAPIProxy, getAPIProxy } from '@inwink/apibase';
import { customerEntityViewDataSource } from '../backoffice/customerentityview';
import { userPreferences } from '../base/userPreferences';
import { getEntityDataSource, IDataSourceV3Options, IDataSourceV3 } from '../base/datasource';

let _customerAPIProxy: IAPIProxy = null;
let _boAPIProxy: IAPIProxy = null;

export const getCustomerAPIProxy = () => {
    if (!_customerAPIProxy) {
        _customerAPIProxy = getAPIProxy(inwink.config.customerApiUrl);
    }

    return _customerAPIProxy;
};

export const getBoAPIProxy = () => {
    if (!_boAPIProxy) {
        _boAPIProxy = getAPIProxy(inwink.config.backOfficeApiUrl);
    }
    return _boAPIProxy;
};

export function getCustomerEntityDataSource<T>(customerId: string, entityRoute: string, entityName: string,
    options?: IDataSourceV3Options): IDataSourceV3<T> {
    const proxy = getCustomerAPIProxy();
    const userPreferencesDatasource = customerEntityViewDataSource(customerId);
    const userpreferencesProvider = userPreferences(userPreferencesDatasource, entityName);
    return getEntityDataSource<T>(proxy, customerId + "/", entityRoute, entityName, userpreferencesProvider, options);
}

// export function getCustomerTenantEntityDataSource<T>(customerId: string, tenantId: string, entityRoute: string,
//     entityName: string, options?: IDataSourceV3Options): IDataSourceV3<T> {
//     const proxy = getCustomerAPIProxy();
//     const userPreferencesDatasource = customerEntityViewDataSource(customerId);
//     const userpreferencesProvider = userPreferences(userPreferencesDatasource, entityName);
//     return getEntityDataSource<T>(proxy, `${customerId}/${tenantId}/`, entityRoute,
//        entityName, userpreferencesProvider, options);
// }

export function getCustomerWebsiteEntityDataSource<T>(customerId: string, websiteId: string, entityRoute: string,
    entityName: string, options?: IDataSourceV3Options): IDataSourceV3<T> {
    const proxy = getCustomerAPIProxy();
    const userPreferencesDatasource = customerEntityViewDataSource(customerId);
    const userpreferencesProvider = userPreferences(userPreferencesDatasource, entityName);
    const customerOptions = {
        ...options,
        beforeCreate: (_entity) => {
            let entity = _entity;
            entity.customerId = customerId;
            entity.websiteId = websiteId;
            if (options && options.beforeCreate) {
                entity = options.beforeCreate(entity);
            }
            return entity;
        }
    };
    return getEntityDataSource<T>(proxy, `${customerId}/${websiteId}/`, entityRoute, entityName,
        userpreferencesProvider, customerOptions);
}

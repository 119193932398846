import * as assign from 'lodash/assign';
import { getTrads } from '../api/backoffice/botrads';
import { requestBrowserStorage } from './browserstorage';

// eslint-disable-next-line import/no-mutable-exports
export let i18nData;

export function openI18nData(version) {
    return new Promise((resolve, reject) => {
        requestBrowserStorage("i18ncache").then((browserstorage) => {
            i18nData = browserstorage;
            i18nData.openData("trads", version);
            resolve();
        }).catch((err) => {
            reject(err);
        });
    });
    // i18nData = new BrowserStorage("i18ncache");
    // i18nData.openData("trads", version);
}
export function cleari18nData(languageCode: any) {
    i18nData.set(languageCode, null);
    i18nData.set("lastupdated-" + languageCode, null);
    i18nData.set(languageCode, null);
}

export function syncLanguage(languageCode: any, _srctrads: any, force = false) {
    return i18nData.get("lastupdated-" + languageCode).then((date: string) => {
        let lastupdated: string;
        let lastUpdatedDate: Date;
        if (!force && date) {
            lastupdated = date;
            lastUpdatedDate = new Date(date);
        }

        return getTrads(languageCode, lastupdated).then((trads) => {
            let srctrads = _srctrads;
            if (trads && trads.length) {
                srctrads = assign({}, srctrads);
                lastUpdatedDate = lastUpdatedDate || new Date(2000, 1, 1);
                trads.forEach((t) => {
                    srctrads[t.code] = t.text;
                    const d = new Date(t.lastUpdate);
                    if (d > lastUpdatedDate) {
                        lastupdated = t.lastUpdate;
                        lastUpdatedDate = d;
                    }
                });
                i18nData.set(languageCode, JSON.stringify(srctrads));
                i18nData.set("lastupdated-" + languageCode, lastupdated);
            }
            return srctrads;
        });
    });
}

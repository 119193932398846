import { Loader } from "@inwink/loader";
import { AutoSizer, List, InfiniteLoader } from 'react-virtualized';
import * as debounce from 'lodash/debounce';
import { React, LoadingState } from '../../../commons';

import './entitylist.less';

interface IEntityListProps {
    rowHeight: number;
    datasource: any; // All this because totalCount moved from page to options....IDataSourceV3<any>;
    getItem: (entity: any, args: ReactVirtualized.rowRenderedArgs, selected?: any, isScrolling?: boolean) => any;
    getLoadingItem: (args: ReactVirtualized.rowRenderedArgs) => any;
    noItem: () => any;
    searchterm?: string;
    expands?: any;
    selected?: any;
    expression?: any;
    filters?: any;
    onRowCountChanged?: (rowscount: number) => void;
    sort?: (x: any, y: any) => number;
    pageSize?: number;
    onError?: (err) => void;
    selectedItems?: any[];
    items?: any[];
    update?: any;
    refresh?: any;
    orderBy?: Array<{ by: string, desc: boolean }>;
    loadingStateChanged?: (loading: boolean) => void;
    overscanRowCount?: number;
    useLoadingState?: boolean;
}

const listPageSize = 20;

interface IEntityListState {
    expands: any;
    totalCount: number;
    items: any[];
    loadingWrapper: Record<string, any>;
    loadingState: boolean;
    firstLoad: boolean;
    reLoad: boolean;
    pageSize: number;
}

export class EntityList extends React.Component<IEntityListProps, IEntityListState> {
    virtualList: any;

    fetching: boolean;

    constructor(props: IEntityListProps) {
        super(props);
        this.debounceLoadItems = debounce(this.loadItems.bind(this), 200);

        this.state = {
            expands: (props.expands && props.expands.list) ? props.expands.list : props.expands,
            totalCount: 0,
            items: [],
            loadingWrapper: {},
            loadingState: true,
            firstLoad: true,
            reLoad: true,
            pageSize: props.pageSize || listPageSize
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.update !== this.props.update) {
            // console.log("update");
            this.loadItems(this.props);
        }

        if (this.props.expression !== prevProps.expression) {
            // console.log("expression new", this.props.expression);
            // console.log("expression old", prevProps.expression);
            if (!this.state.reLoad) {
                this.setState({ reLoad: true, items: null, totalCount: null }, () => {
                    this.debounceLoadItems(this.props);
                });
            } else {
                this.debounceLoadItems(this.props);
            }
        }

        if (this.props.refresh !== prevProps.refresh) {
            this.refreshList();
        }

        if (this.props.items !== prevProps.items) {
            // console.log("items");
            this.setState({ items: this.props.items || [] });
            if (!this.props.items) {
                this.loadItems(this.props);
            }
        }
    }

    refreshList = () => {
        if (this.virtualList && this.virtualList._registeredChild && this.virtualList._registeredChild.forceUpdateGrid) {
            this.virtualList._registeredChild.forceUpdateGrid();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debounceLoadItems(props: IEntityListProps) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const e = "leave empty";
    }

    loadItems(props: IEntityListProps) {
        return this.fetchData(props, 0, true);
    }

    componentDidMount() {
        this.loadItems(this.props);
    }

    renderItem = (item) => {
        const entity = this.state.items[item.index];
        if (entity) {
            if (this.props.selected) {
                return <div key={item.key} data-entity={item.id} style={item.style} className="listitem">
                    {this.props.getItem(entity, item, this.props.selected.indexOf(entity.id), item.isScrolling)}
                </div>;
            }
            return <div key={item.key} data-entity={item.id} style={item.style} className="listitem">
                {this.props.getItem(entity, item, null, item.isScrolling)}
            </div>;
        }
        return <div
            key={item.key}
            data-entity={item.id}
            style={item.style}
            className="listitem loading"
        >{this.props.getLoadingItem(item)}</div>;
    };

    _isRowLoaded = ({ index }) => {
        const entity = this.state.items[index];
        return !!entity;
    };

    _loadMoreRows = ({ startIndex, stopIndex }) => {
        if (Object.keys(this.state.loadingWrapper).length >= 4) {
            // Pour éviter de flooder l'api
            return null;
        }
        const pageIndex = Math.floor(startIndex / this.state.pageSize);
        const promises = [];
        promises.push(this.fetchData(this.props, pageIndex, false));
        if (stopIndex > pageIndex * this.state.pageSize + this.state.pageSize) {
            promises.push(this.fetchData(this.props, pageIndex + 1, false));
        }
        return Promise.all(promises);
    };

    addLoading(props: IEntityListProps) {
        const stamp = new Date().getTime().toString();

        this.setState((prevstate) => {
            if (!prevstate.loadingState && props.loadingStateChanged) {
                props.loadingStateChanged(true);
            }
            return {
                loadingWrapper: Object.assign({}, prevstate.loadingWrapper, { [stamp]: stamp }),
                loadingState: true
            };
        });
        return stamp;
    }

    removeLoading(props: IEntityListProps, stamp: string) {
        this.setState((prevstate) => {
            const wrapper = Object.assign({}, prevstate.loadingWrapper);
            delete wrapper[stamp];
            const loadingState = Object.keys(wrapper).length > 0;

            if (loadingState !== prevstate.loadingState && this.props.loadingStateChanged) {
                this.props.loadingStateChanged(loadingState);
            }
            return {
                loadingState: loadingState,
                loadingWrapper: wrapper
            };
        });
    }

    fetchData = (props: IEntityListProps, page: number, totalCount: boolean) => {
        this.fetching = true;
        const loadingStamp = this.addLoading(props);

        return new Promise((resolve, reject) => {
            const options: any = {
                page: { index: page, size: this.state.pageSize, returnTotalCount: true },
                search: props.searchterm,
                expression: props.expression,
                order: props.orderBy
            };

            if (props.datasource.isV3) {
                const promises: Array<Promise<any>> = [
                    props.datasource.query(options, this.state.expands)
                ];
                if (totalCount) {
                    const countOptions = Object.assign({}, options, { page: undefined, order: undefined });
                    promises.unshift(props.datasource.count(countOptions));
                }

                return Promise.all(promises).then((res) => {
                    const data = { data: null, totalCount: null };
                    if (totalCount) {
                        data.totalCount = res[0];
                        data.data = res[1].data;
                    } else {
                        data.data = res[0].data;
                    }
                    resolve(data);
                }, reject);
            }
            return props.datasource.query(options, this.state.expands).then((res) => {
                resolve(res);
            }, reject);
        }).then((res: any) => {
            let items;
            let itemCount;
            const newState: any = {
                page: page
            };
            if (this.state.firstLoad || this.state.reLoad) {
                items = res.page || res.data;
                itemCount = res.totalCount;
                newState.firstLoad = false;
                newState.reLoad = false;
            } else {
                const newitems = [...this.state.items];
                const startIndex = page * this.state.pageSize;
                const itemsContent = (res.page ? res.page : res.data);
                itemsContent.forEach((item, idx) => {
                    newitems[idx + startIndex] = item;
                });
                items = newitems;
                itemCount = this.state.totalCount;
            }

            newState.items = items;
            newState.totalCount = itemCount;

            this.setState(newState, () => {
                this.fetching = false;
            });

            this.removeLoading(props, loadingStamp);
            if (props.onRowCountChanged) {
                props.onRowCountChanged(itemCount);
            }
        }, (err) => {
            const newState: any = {
            };
            if (this.state.firstLoad || this.state.reLoad) {
                newState.firstLoad = false;
                newState.reLoad = false;
            }

            this.setState(newState, () => {
                this.fetching = false;
            });
            this.removeLoading(props, loadingStamp);
            console.error(err);
            if (props.onError) {
                props.onError(err);
            }
        });
    };

    render() {
        const classNames = ["entitylist"];
        let content = null;

        if (this.state.loadingState && this.state.reLoad) {
            classNames.push("no-items");
            content = <Loader />;
        } else if (this.props.items) {
            content = <AutoSizer>
                {({ height, width }) => (<List
                    ref={(ref) => { this.virtualList = ref; }}
                    height={height}
                    selected={this.props.selected}
                    overscanRowCount={this.props.overscanRowCount || 10}
                    rowCount={this.state.items.length}
                    rowHeight={this.props.rowHeight}
                    noRowsRenderer={this.props.noItem}
                    rowRenderer={this.renderItem}
                    width={width}
                />)}
            </AutoSizer>;
        } else {
            content = <InfiniteLoader
                ref={(ref) => { this.virtualList = ref; }}
                isRowLoaded={this._isRowLoaded}
                loadMoreRows={this._loadMoreRows}
                rowCount={this.state.totalCount}
                selected={this.props.selected}
            >
                {({ onRowsRendered, registerChild }) => (
                    <AutoSizer>
                        {({ height, width }) => (<List
                            ref={registerChild}
                            width={width}
                            height={height}
                            selected={this.props.selected}
                            overscanRowCount={this.props.overscanRowCount || 10}
                            rowCount={this.state.totalCount}
                            rowHeight={this.props.rowHeight}
                            onRowsRendered={onRowsRendered}
                            noRowsRenderer={this.props.noItem}
                            rowRenderer={this.renderItem}
                        />)}
                    </AutoSizer>
                )}
            </InfiniteLoader>;
        }

        if (this.props.useLoadingState) {
            return <LoadingState isLoading={this.state.firstLoad}>
                <div className={classNames.join(" ")}>
                    {content}
                </div>
            </LoadingState>;
        }
        return <div className={classNames.join(" ")}>
            {content}
        </div>;
    }
}

/* eslint-disable max-classes-per-file */
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';
import { React, AppTextLabel } from '../../../commons';

interface INumberInputBaseProps {
    canEditTrad: boolean;
    i18n: string;
    min?: any;
    max?: any;
}

interface INumberInputProps extends INumberInputBaseProps {
    value: number;
    onChange: (val: number) => void;
    noLabel?: boolean;
}

export class NumberInput extends React.PureComponent<INumberInputProps, any> {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(arg: React.FormEvent<HTMLInputElement>) {
        let nb = parseFloat(arg.currentTarget.value);
        if (this.props.min !== undefined && nb < this.props.min) {
            nb = this.props.min;
        }

        if (this.props.max !== undefined && nb > this.props.max) {
            nb = this.props.max;
        }
        this.props.onChange(nb);
    }

    render() {
        return <div className="fieldbloc">
            {this.props.noLabel ? null : <AppTextLabel component="label" i18n={this.props.i18n} />}
            <AppTextLabel
                component="div"
                className="field-desc"
                defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""}
                i18n={this.props.i18n + ".desc"}
            />
            <input
                type="number"
                min={this.props.min}
                max={this.props.max}
                value={(this.props.value != null && this.props.value !== undefined ? this.props.value : "")}
                onChange={this.onChange}
            />
        </div>;
    }
}

interface INumberInputPropertyProps extends INumberInputBaseProps {
    data: any;
    path: string;
    onChange: (data: any) => void;
}

export class NumberInputProperty extends React.PureComponent<INumberInputPropertyProps, any> {
    onChange = (txt) => {
        const data = immutable.set(this.props.data, this.props.path, txt);
        this.props.onChange(data);
    };

    render() {
        const val = getPropertyValue(this.props.data, this.props.path);
        return <NumberInput
            {...this.props}
            value={val}
            onChange={this.onChange}
        />;
    }
}

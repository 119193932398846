import * as React from 'react';

export interface IAppShellMenuState {
    allowFixed: boolean;
    collapsed: boolean;
    collapsedWhenLarge: boolean;
    allowSubmenuTooltip: boolean;
}

export const AppVisualStateContext = React.createContext<string[]>(null);
export const AppShellMenuContext = React.createContext<IAppShellMenuState>(null);
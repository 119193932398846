/* eslint-disable max-classes-per-file */
import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { DynamicFieldWrapper } from '@inwink/entityform/dynamicfieldwrapper';
import { React, connectwith, CMSContextProvider } from '../../commons';
import { EventAssetPicker } from '../ui/eventassets';
import type {States} from '../../services/services';
import type { ICMSContext } from "../cmscontext";

export interface IPictureEditorProps extends IDynamicFieldEditorProps {
    event?: States.IEventState;
    customer?: States.ICustomerState;
    cmscontext?: ICMSContext;
}

@connectwith((state: States.IAppState) => ({ event: state.event, i18n: state.i18n, customer: state.customer }))
export class PictureEditor extends React.Component<IPictureEditorProps, any> {
    render() {
        return <CMSContextProvider.Consumer>
            {(cmscontext) => <PictureEditorContent {...this.props} cmscontext={cmscontext} />}
        </CMSContextProvider.Consumer>;
    }
}

class PictureEditorContent extends React.Component<IPictureEditorProps, any> {
    constructor(props: IPictureEditorProps) {
        super(props);
    }

    onValuechangeEventHandler = (url) => {
        this.props.onChange(this.props.entityFieldFormTemplate.key, url);
    };

    render() {
        return <DynamicFieldWrapper {...this.props} labelHtmlFor={this.props.entityFieldFormTemplate.key}>
            <div className="asseteditor">
                <EventAssetPicker
                    datasource={this.props.cmscontext.pictures}
                    value={this.props.entityFieldValue}
                    onChange={this.onValuechangeEventHandler}
                    forceReadOnly={this.props.forceReadOnly}
                />
            </div>
        </DynamicFieldWrapper>;
    }
}

/* eslint-disable max-classes-per-file */
import { connect } from 'react-redux';
import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { Popover } from '@inwink/modals';
import { withI18nHelper } from '@inwink/i18n';
import { DynamicFieldWrapper } from '@inwink/entityform/dynamicfieldwrapper';
import * as findIndex from 'lodash/findIndex';
import * as find from 'lodash/find';
import { DynamicFieldLabel } from '@inwink/entityform/dynamicfieldlabel';
import type { States } from '../../services/services';
import { DropdownEditor, IDrowdownEditorItem, sortItems } from "../ui/dropdowneditor";
import { React, AppTextLabel, translateEventLabel } from '../../commons';

import './dropdownmultiple.less';

export interface IDrowdownMultipleEditorProps extends IDynamicFieldEditorProps {
    noLabel?: boolean;
    minimumSelect?: number;
}

export interface IDrowdownMultipleFormEditorProps extends IDynamicFieldEditorProps {
    // allItems?: IDrowdownEditorItem[];
    excludeItemKeys?: string[];
    onUpdateItem?: (item: any) => void | Promise<void>;
    noLabel?: boolean;
}

interface IDrowdownMultipleFormEditorState {
    selection: IDrowdownEditorItem[];
    allItems: IDrowdownEditorItem[];
}

function getAllItems(props: IDrowdownMultipleFormEditorProps): IDrowdownEditorItem[] {
    const result: IDrowdownEditorItem[] = [];
    if (props.entityFieldFormTemplate.valuesList && props.entityFieldFormTemplate.valuesList.length) {
        props.entityFieldFormTemplate.valuesList.forEach((val) => {
            if (!props.excludeItemKeys || props.excludeItemKeys.indexOf(val.key.toLowerCase()) === -1) {
                let label = val.labels && val.labels[props.displayLanguage];
                let dataTrad = null;
                if (!label) {
                    const key = (props.entityName + "." + props.entityFieldFormTemplate.key + "." + val.key).toLowerCase();
                    label = props.i18nHelper.translate(key, null, key);
                    dataTrad = key;
                }

                result.push({
                    label: label,
                    key: val.key,
                    dataTrad: dataTrad
                });
            }
        });
    }

    result.sort(sortItems);
    return result;
}

export class DrowdownMultipleFormEditor
    extends React.Component<IDrowdownMultipleFormEditorProps, IDrowdownMultipleFormEditorState> {
    constructor(props: IDrowdownMultipleFormEditorProps) {
        super(props);

        const allItems = getAllItems(props);
        this.state = {
            selection: this.getSelectionFromProps(allItems, props),
            allItems: allItems
        };
    }

    componentDidUpdate(prevProps: IDrowdownMultipleFormEditorProps) {
        let patch: Partial<IDrowdownMultipleFormEditorState> = null;
        if (prevProps.entityFieldFormTemplate !== this.props.entityFieldFormTemplate
            || prevProps.displayLanguage !== this.props.displayLanguage /* || prevProps.allItems !== this.props.allItems */) {
            patch = patch || {};
            patch.allItems = getAllItems(this.props);
            patch.selection = this.getSelectionFromProps(patch.allItems, this.props);
        } else if (prevProps.entityFieldValue !== this.props.entityFieldValue) {
            patch = patch || {};
            patch.selection = this.getSelectionFromProps(this.state.allItems, this.props);
        }

        if (patch) {
            this.setState(patch as any);
        }
    }

    getSelectionFromProps(allItems: IDrowdownEditorItem[], props: IDrowdownMultipleFormEditorProps): IDrowdownEditorItem[] {
        const selection: IDrowdownEditorItem[] = [];
        const propsSelection: string[] = props.entityFieldValue || [];

        allItems.forEach((item) => {
            if (propsSelection.some((key) => key.toLowerCase() === item.key.toLowerCase())) {
                selection.push(item);
            }
        });

        return selection;
    }

    selectionChanged = (selection: IDrowdownEditorItem[]) => {
        this.props.onChange(this.props.entityFieldFormTemplate.key, selection.map((s) => s.key));
    };

    render() {
        return <DynamicFieldWrapper
            noLabel={this.props.noLabel}
            className="type-dropdownmultiple-bo"
            {...this.props}
            i18nHelper={this.props.i18nHelper}
        >
            <DropdownEditor
                allItems={this.state.allItems}
                selection={this.state.selection}
                onChange={this.selectionChanged}
                multiselect={true}
                modaleTitle={() => <DynamicFieldLabel {...this.props} />}
                onUpdateItem={this.props.onUpdateItem}
                readOnly={this.props.forceReadOnly}
            />
        </DynamicFieldWrapper>;
    }
}

export class SelectFormEditor extends React.Component<IDrowdownMultipleFormEditorProps, IDrowdownMultipleFormEditorState> {
    constructor(props: IDrowdownMultipleFormEditorProps) {
        super(props);

        const allItems = getAllItems(props);
        this.state = {
            selection: this.getSelectionFromProps(allItems, props),
            allItems: allItems
        };
    }

    componentDidUpdate(prevProps: IDrowdownMultipleFormEditorProps) {
        let patch: Partial<IDrowdownMultipleFormEditorState> = null;
        if (prevProps.entityFieldFormTemplate !== this.props.entityFieldFormTemplate
            || prevProps.displayLanguage !== this.props.displayLanguage /* || prevProps.allItems !== this.props.allItems */) {
            patch = patch || {};
            patch.allItems = getAllItems(this.props);
            patch.selection = this.getSelectionFromProps(patch.allItems, this.props);
        } else if (prevProps.entityFieldValue !== this.props.entityFieldValue) {
            patch = patch || {};
            patch.selection = this.getSelectionFromProps(this.state.allItems, this.props);
        }

        if (patch) {
            this.setState(patch as any);
        }
    }

    getSelectionFromProps(allItems: IDrowdownEditorItem[], props: IDrowdownMultipleFormEditorProps): IDrowdownEditorItem[] {
        const selection: IDrowdownEditorItem[] = [];
        const propsSelection: string = props.entityFieldValue || [];

        allItems.forEach((item) => {
            if (propsSelection === item.key) {
                selection.push(item);
            }
        });

        return selection;
    }

    selectionChanged = (selection: IDrowdownEditorItem[]) => {
        this.props.onChange(this.props.entityFieldFormTemplate.key, (selection && selection[0] && selection[0].key) || null);
    };

    render() {
        return <DynamicFieldWrapper
            className="type-selectlist-bo"
            {...this.props}
            i18nHelper={this.props.i18nHelper}
            // labelClassName={(this.props.forceReadOnly ? "" : " clickable")}
        >
            <DropdownEditor
                allItems={this.state.allItems}
                selection={this.state.selection}
                onChange={this.selectionChanged}
                modaleTitle={() => <DynamicFieldLabel {...this.props} />}
                readOnly={this.props.forceReadOnly}
            />
        </DynamicFieldWrapper>;
    }
}

interface IDrowdownMultipleEditorComponentState {
    show: boolean;
    parent: any;
    filter: string;
}
@withI18nHelper()
class DrowdownMultipleEditorComponent
    extends React.Component<IDrowdownMultipleEditorProps, IDrowdownMultipleEditorComponentState> {
    searchFilter = React.createRef<HTMLInputElement>();

    constructor(props: IDrowdownMultipleEditorProps) {
        super(props);
        this.state = {
            show: false,
            parent: null,
            filter: ""
        };
    }

    renderItem(key: string) {
        let valuesListLabels;
        if (this.props.entityFieldFormTemplate.valuesList && this.props.entityFieldFormTemplate.valuesList.length) {
            const valItem = this.props.entityFieldFormTemplate.valuesList.filter((v) => v.key === key)[0];

            if (valItem && valItem.labels) {
                valuesListLabels = valItem.labels;
            }
        }
        const label = this.getLabel(key, valuesListLabels);

        if (label) {
            // Ici on limite à 50 charactères l'affichage des options pour ne pas casser l'affichage.
            return <div title={label} key={key} className="item">
                {label && label.length && label.length > 50 ? label.slice(0, 50) + "..." : label}
                {(this.props.forceReadOnly
                    || (this.props.minimumSelect
                        && this.props.entityFieldValue.length <= this.props.minimumSelect))
                    ? null : <i onClick={(arg) => { this.refItemClicked(arg, key, null); }} className="inwink-cancel" />}
            </div>;
        }
    }

    refItemClicked = (arg, key, label) => {
        const existing = this.props.entityFieldValue || [];
        let newlist = existing.slice(0, existing.length);
        // on vérifie que toutes les valeurs existent bien dans le référentiel et on filtre celles qui sont obsolètes
        newlist = newlist.filter((entityval) => {
            const existingfield = this.props.entityFieldFormTemplate && this.props.entityFieldFormTemplate.valuesList
                ? this.props.entityFieldFormTemplate.valuesList.filter((v) => v.key === entityval.key)[0]
                : [];
            return !!existingfield;
        });
        const idx = findIndex(newlist, { key });
        if (idx >= 0) {
            if (this.props.minimumSelect && this.props.entityFieldValue.length <= this.props.minimumSelect) {
                return;
            }
            newlist.splice(idx, 1);
        } else {
            newlist.push({ key, value: label });
            if (newlist.length === ((this.props.entityFieldFormTemplate.valuesList)
                ? this.props.entityFieldFormTemplate.valuesList.length : 0)) {
                this.hidePopover();
            }
        }
        this.props.onChange((!this.props.entityFieldFormTemplate) ? null : this.props.entityFieldFormTemplate.key, newlist);
    };

    getLabel(key: string, labels) {
        const i18n = this.props.i18nHelper;
        let label = key;
        if (labels) {
            label = translateEventLabel(this.props.displayLanguage || i18n.i18n.currentLanguageCode, i18n?.i18n, labels);
        } else {
            const en = this.props.entityName ? this.props.entityName + '.' : '';
            label = i18n.translate(`${en}${this.props.entityFieldFormTemplate?.key}.${key}`.toLowerCase());
        }
        if (!label) {
            const en = (this.props.entityFieldFormTemplate as any)?.entityName + '.';
            label = i18n.translate(`${en}${(this.props.entityFieldFormTemplate as any)?.field?.key}.${key}`.toLowerCase());
        }
        return label;
    }

    renderRefItem(refitemKey, refitemLabels) {
        const selected = (this.props.entityFieldValue && find(this.props.entityFieldValue, { key: refitemKey }));
        const label = this.getLabel(refitemKey, refitemLabels);

        return (
            <div
                key={refitemKey}
                className={"item" + (this.props.forceReadOnly ? "" : " clickable") + (selected ? " selected" : "")}
                onClick={(arg) => this.refItemClicked(arg, refitemKey,
                    (refitemLabels && refitemLabels[this.props.displayLanguage]) || "")}
            >
                <div className="marker"><i className="inwink-checked" /></div>
                <span>{label}</span>
            </div>
        );
    }

    showPopover = (arg) => {
        if (this.props.forceReadOnly) {
            return;
        }
        this.setState({ show: true, parent: arg.target });
    };

    hidePopover = () => {
        this.setState({ show: false });
    };

    componentDidUpdate(prevprops: IDrowdownMultipleEditorProps, prevstate: IDrowdownMultipleEditorComponentState) {
        if (this.state.show && prevstate.show !== this.state.show) {
            const Items = this.props.entityFieldFormTemplate?.valuesList;
            if (Items && Items.length > 20) {
                setTimeout(() => {
                    if (this.searchFilter.current) {
                        this.searchFilter.current.focus();
                    }
                }, 0);
            }
        }
    }

    render() {
        const allitems = []; let filter; let useFilter = false;
        const i18n = this.props.i18nHelper;

        if (this.props.entityFieldFormTemplate.valuesList) {
            let Items = this.props.entityFieldFormTemplate.valuesList;
            if (Items.length > 20) {
                useFilter = true;
                filter = <div className="itemsfilter">
                    <input
                        ref={this.searchFilter}
                        type="search"
                        value={this.state.filter}
                        onChange={(arg) => this.setState({ filter: arg.currentTarget.value })}
                    />
                </div>;
            }
            if (useFilter && this.state.filter) {
                Items = Items.filter((i) => {
                    const t = i18n.translateBag(i.labels);
                    return t && (t.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0);
                });
            }

            Items.forEach((v) => {
                if (v.key) {
                    allitems.push(this.renderRefItem(v.key, (v.labels) ? v.labels : null));
                }
            });
        }

        const items = [];
        if (this.props.entityFieldValue && this.props.entityFieldValue.length) {
            this.props.entityFieldValue.forEach((item) => {
                const elt = this.renderItem(item.key);
                if (elt) {
                    items.push(elt);
                }
            });

            items.push((this.props.forceReadOnly
                || (this.props.minimumSelect && this.props.entityFieldValue.length === allitems.length)
            ) ? null : <div key="addlabel" onClick={this.showPopover} className="addlabel">{i18n.translate("actions.add")}</div>);
        } else if (i18n) {
            if (this.props.entityFieldFormTemplate && this.props.entityFieldFormTemplate.placeholders) {
                items.push(<div onClick={this.showPopover} key="remark" className="remark">
                    {(this.props.forceReadOnly) ? null : i18n.translateBag(this.props.entityFieldFormTemplate.placeholders)}
                </div>);
            } else {
                items.push(<div onClick={this.showPopover} key="remark" className="remark">
                    {(this.props.forceReadOnly) ? null : i18n.translate("entityform.dropdownmultiple.empty")}
                </div>);
            }
        }

        const content = <div>
            <div className={"items" + (this.props.forceReadOnly ? "" : " clickable")}>
                {items}
                <div style={{ clear: 'both' }} />
            </div>
            <Popover
                className="popover-dropdownmultiple"
                show={this.state.show}
                parent={this.state.parent}
                onhide={this.hidePopover}
            >
                <div className={"popover-dropdownmultiple-content" + (useFilter ? " withfilter" : "")}>
                    {filter}
                    <div className="items">
                        {allitems.length ? allitems : <AppTextLabel className="item" i18n="display.noresult" />}
                    </div>
                    <div style={{ clear: 'both' }} />
                </div>
            </Popover>
        </div>;

        return (this.props.noLabel) ? <div>{content}</div> : <DynamicFieldWrapper
            className="type-dropdownmultiple-bo"
            {...this.props}
            i18nHelper={i18n}
            labelClassName={(this.props.forceReadOnly ? "" : " clickable")}
            labelOnClick={this.showPopover}
        >
            {content}
        </DynamicFieldWrapper>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        currentLanguageCode: state.i18n.currentLanguageCode,
    };
}

function mapDispatchToProps() {
    return {};
}

export const DrowdownMultipleEditor: new (any) => React.Component<IDrowdownMultipleEditorProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(DrowdownMultipleEditorComponent as any) as any;

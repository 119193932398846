import { React, AppTextLabel, Loader, AppAsyncButton } from '../../../commons';
import { ItemsPickerItem } from './item';

export interface IItemsPickerProps {
    className?: string;
    isLoading?: boolean;
    selection: any[];
    readOnly?: boolean;
    itemsRenderer: (item) => any;
    onItemClicked?: (item) => void;
    onRemoveItem?: (item: any) => void;
    onUpdateItem?: (item: any) => void | Promise<void>;
    onPick: (arg: React.MouseEvent<any>) => void;
}

export class ItemsPicker extends React.Component<IItemsPickerProps, any> {
    pick = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        return this.props.onPick(arg);
    };

    render() {
        let content = null;

        if (this.props.isLoading) {
            content = <div className={"pseudoinput" + (this.props.readOnly ? " readonly" : "")}><Loader /></div>;
        } else {
            let items = [];

            if (this.props.selection && this.props.selection.length) {
                items = this.props.selection.map((item, idx) => {
                    return <ItemsPickerItem {...this.props} key={"item#" + item.id + "#" + idx} item={item} />;
                });
            }

            if (!this.props.readOnly) {
                items.push(<AppAsyncButton key="btnupdate" onClick={this.pick} className="btnupdate">
                    <AppTextLabel i18n="actions.update" /></AppAsyncButton>);
            }
            const inputContent = <div className="iw-itemspicker-items">{items}</div>;

            content = <div className={"pseudoinput" + (this.props.readOnly ? " readonly" : "")}>
                {inputContent}
            </div>;
        }

        return <div className={"iw-itemspicker"
            + (this.props.className ? " " + this.props.className : "")
            + (this.props.readOnly ? " readonly" : "")}
        >
            {content}
        </div>;
    }
}

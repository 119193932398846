import { LoadingPanel } from '@inwink/loadable';
import { React } from '../../../commons';
import { PageLoader } from "../pageloader";
import { SimpleTabsContentPanel } from './contentpanel';
import type { ISimpleTabsContentProps } from './index';

export class SimpleTabsContent extends React.Component<ISimpleTabsContentProps, any> {
    currentTabCtrl = React.createRef<SimpleTabsContentPanel>();

    constructor(props: ISimpleTabsContentProps) {
        super(props);
        let SimpleTabsContentClassName = [];
        if (this.props.options) {
            // eslint-disable-next-line no-restricted-syntax
            for (const component of this.props.options) {
                if (component?.SimpleTabsContent?.className) {
                    SimpleTabsContentClassName = [...component.SimpleTabsContent.className];
                }
            }
        }

        this.state = {
            SimpleTabsContentClassName
        };
    }

    canClosePage(context?: any) {
        if (this.currentTabCtrl) {
            return this.currentTabCtrl.current.canClosePage(context);
        }
        return Promise.resolve(true);
    }

    renderLoader = (data) => {
        return <PageLoader display="ring" data={data} />;
    };

    render() {
        const styles: any = {};
        if (this.props.removeOffsetOnItems) { styles.top = 0; }

        return (
            <div className={`simpletabs-content ${this.state.SimpleTabsContentClassName.join(' ')}`} style={styles}>
                <LoadingPanel
                    fullSizeContent
                    name="simpletabs"
                    className="simpletabs-contentwrapper"
                    noInheritanceAfterFirstLoad={this.props.loadingPanelProps?.noInheritanceAfterFirstLoad !== undefined
                        ? this.props.loadingPanelProps.noInheritanceAfterFirstLoad : false}
                    noInheritance={this.props.loadingPanelProps?.noInheritance !== undefined
                        ? this.props.loadingPanelProps.noInheritance : true}
                    loader={this.renderLoader}
                >
                    <SimpleTabsContentPanel {...this.props} ref={this.currentTabCtrl} />
                </LoadingPanel>
            </div>
        );
    }
}

import { React, AppTextLabel } from '../../commons';
import { IWizardStep } from './wizard.step';

export interface IWizardNavbarProps {
    steps: IWizardStep[];
    allowNext: boolean;
    currentStep: number;
    disabledFooter: boolean;
    onChangeCurrentStep: (step) => void;
    renderBntValidate?: () => void;
    disabledPrev?: boolean;
    displayCancel?: boolean;
    customProps?: any;
}

export class WizardNavbar extends React.Component<IWizardNavbarProps, any> {
    render() {
        let next;
        let cancel;
        let prev;
        let end;

        if (!this.props.disabledFooter && this.props.currentStep > 0 && !this.props.disabledPrev) {
            prev = <button className="prev" onClick={(arg) => this.props.onChangeCurrentStep(this.props.currentStep - 1)}><AppTextLabel i18n="wizard.previous"/></button>;
        }
        if (!this.props.disabledFooter && this.props.displayCancel && this.props.customProps?.onCompleted) {
            cancel = <button className="cancel" onClick={() => this.props.customProps.onCompleted(false)}><AppTextLabel i18n="wizard.cancel"/></button>;
        }

        if (!this.props.disabledFooter && this.props.currentStep < this.props.steps.length - 1) {
            next = <button
              className={`important ${!this.props.allowNext ? 'disabled' : ''}`}
              disabled={!this.props.allowNext}
              onClick={(arg) => this.props.onChangeCurrentStep(this.props.currentStep + 1)}>
                <AppTextLabel i18n="wizard.next"/>
              </button>;
        } else if (this.props.renderBntValidate) {
            next = this.props.renderBntValidate();
        }

        return <footer className="navbar">
            <div className="left">
                {cancel} {prev}
                {/* <div className="cancel">{cancel}</div>
                <div className="prev">{prev}</div> */}
            </div>
            <div className="right">{next} {end}</div>
        </footer>;
    }
}

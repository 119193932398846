import type { Entities } from '@inwink/entities/entities';
import type { IDataSourceV3, IInwinkEntityV3QueryOptions } from "../../../api/base/datasource";
import type { IPopoverManager } from '../../../commons';
import type { ICMSContext } from '../../cmscontext';
import type { IEntityDetailConfiguration, IEntityDetailModalProps } from './index';

import './entitydetailpage.less';

export function loadEntityDetailModule() {
    return import(/* webpackChunkName: "modentitydetailv3" */ "./entitydetailmodal/index");
}

export function openEntityDetail(
    popovermgr: IPopoverManager,
    cmscontext: ICMSContext,
    conf: IEntityDetailConfiguration,
    datasource: IDataSourceV3<Entities.IEntity>,
    options: {
        isCreate: boolean,
        canEdit: boolean,
        isDuplicate: boolean,
        queryOptions?: IInwinkEntityV3QueryOptions,
        isRemove?: boolean,
        disableAddEntityIdQueryString?: boolean
    },
    entity?: Entities.IEntity,
    entityPreValues?: Entities.IEntity,
    relationEntityId?: string
) {
    return loadEntityDetailModule().then((mod) => {
        const props: IEntityDetailModalProps = {
            popovermgr,
            cmscontext,
            conf,
            isCreate: options.isCreate,
            datasource,
            entity: entity || {} as any,
            entityPreValues,
            // onhide: resolve,
            canEdit: options.canEdit,
            isDuplicate: options.isDuplicate,
            overlayCanClose: false,
            queryOptions: options.queryOptions || null,
            relationEntityId,
            isRemove: options.isRemove,
            disableAddEntityIdQueryString: options.disableAddEntityIdQueryString
        };

        return popovermgr.modalPortal(mod.EntityDetailModal, props, "entitydetailpage-modal", {
            disableOverlayDismiss: true
        });
    });
}

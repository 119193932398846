import * as React from 'react';
import { templateDataSource } from '../../api/backoffice/template';
import { OnBoardingDefinitions } from './definition';
import { OnboardingFeature } from './feature';

export interface IOnboardingContext {
    currentKey: string;
    setOnboarding: (key: string) => void;
    showCurrent: () => void;
    reload: () => Promise<any>;
}

export let OnboardingContext = React.createContext<IOnboardingContext>(null);
interface IOnboardingContextControlState {
    context: IOnboardingContext;
    forceShow: any;
    forceLoad: any;
}

export class OnboardingContextControl extends React.Component<any, IOnboardingContextControlState> {
    currentKey: string;

    constructor(props) {
        super(props);
        this.state = {
            context: {
                currentKey: null,
                setOnboarding: this.setOnboarding,
                showCurrent: this.showCurrent,
                reload: this.reload
            },
            forceShow: new Date(),
            forceLoad: new Date()
        };
    }

    showCurrent = () => {
        this.setState({
            forceShow: new Date()
        });
    }

    reload = () => {
        if (this.state.context.currentKey) {
            this.setState({
                forceLoad: new Date()
            });
        }

        return Promise.resolve();
    }

    // loadOnboarding = (key: string) => {
    //     this.currentKey = key;
    //     if (!key) {
    //         return Promise.resolve();
    //     }
        
    //     this.setState({
    //         context: {
    //             currentKey: key,
    //             config: null,
    //             setOnboarding: this.setOnboarding,
    //             showCurrent: this.showCurrent,
    //             reload: this.reload
    //         }
    //     });

    //     let ds = templateDataSource();
    //     return ds.query({
    //         where: {
    //             templateType: "boonboarding",
    //             templateTarget: key
    //         }
    //     }).then((res) => {
    //         if (res && res.data && res.data.length) {
    //             if (this.currentKey === key) {
    //                 this.setState({
    //                     context: {
    //                         currentKey: key,
    //                         config: res.data[0].template,
    //                         setOnboarding: this.setOnboarding,
    //                         showCurrent: this.showCurrent,
    //                         reload: this.reload
    //                     }
    //                 });
    //             }
    //         }
    //     });
    // }

    setOnboarding = (key: string) => {
        if (key !== this.state.context.currentKey) {
            this.setState({
                context: {
                    currentKey: key,
                    setOnboarding: this.setOnboarding,
                    showCurrent: this.showCurrent,
                    reload: this.reload
                }
            });
        }
    }

    render() {
        let context = this.state.context;

        return <OnboardingContext.Provider value={context}>
            {this.props.children}
            { context && context.currentKey ?
                <OnboardingFeature key={context.currentKey} onboardingkey={context.currentKey} forceLoad={this.state.forceLoad} forceShow={this.state.forceShow} />
                : null }
        </OnboardingContext.Provider>;
    }
}

import { getUserSettings, setUserSettings } from '../api/backoffice/usersettings';
import { requestBrowserStorage, BrowserStorage } from './browserstorage';

// eslint-disable-next-line import/no-mutable-exports
export let userSettingsData: BrowserStorage;

export function openUserSettingsData(userid, version) {
    return new Promise((resolve, reject) => {
        requestBrowserStorage("usersettings-" + inwink.config.env + "-" + userid).then((browserstorage) => {
            userSettingsData = browserstorage;
            userSettingsData.openData("settings-" + inwink.config.env + "-" + userid, version);
            resolve();
        }).catch((err) => {
            reject(err);
        });
    });
}

export function syncSettings() {
    return userSettingsData.get("$lastupdated").then((date: string) => {
        let lastupdated: string;
        let lastUpdatedDate: Date;
        if (date) {
            lastupdated = date;
            lastUpdatedDate = new Date(date);
        }

        return getUserSettings(lastupdated).then((settings) => {
            if (settings && settings.length) {
                lastUpdatedDate = lastUpdatedDate || new Date(2000, 1, 1);
                settings.forEach((t) => {
                    userSettingsData.set(t.preferenceKey, t.preference);
                    const d = new Date(t.lastUpdate);
                    if (d > lastUpdatedDate) {
                        lastupdated = t.lastUpdate;
                        lastUpdatedDate = d;
                    }
                });
                userSettingsData.set("$lastupdated", lastupdated);
            }
        });
    }).then(null, (err) => {
        console.error("error synchronising user settings", err);
    });
}

export function getSetting(settingKey: string) {
    return userSettingsData.get(settingKey).then((_val: string) => {
        let val = _val;
        if (!val) {
            val = localStorage.getItem(settingKey);
            if (val) {
                setSetting(settingKey, val);
            }
        }
        return val;
    }, (error) => {
        console.warn("Cannot find user settings data " + settingKey, error);
        return Promise.reject(error);
    });
}

export function setSetting(settingKey: string, setting: string) {
    userSettingsData.set(settingKey, setting);
    return setUserSettings(settingKey, setting).then(null,
        (err) => console.error("error sending user setting " + settingKey, err));
}

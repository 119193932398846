import { IAPIProxy, getAPIProxy } from '@inwink/apibase';
import { eventEntityViewDataSource } from '../backoffice/evententityview';
import { userPreferences } from '../base/userPreferences';
import { IDataSourceV3Options, IDataSourceV3, getEntityDataSource } from '../base/datasource';

let _eventAPIProxy: IAPIProxy = null;

export const getEventAPIProxy = () => {
    if (!_eventAPIProxy) { _eventAPIProxy = getAPIProxy(inwink.config.eventApiUrl); }

    return _eventAPIProxy;
};

export function getEventEntityDataSource<T>(
    eventId: string,
    entityRoute: string,
    entityName: string,
    options?: IDataSourceV3Options
): IDataSourceV3<T> {
    const proxy = getEventAPIProxy();
    const userPreferencesDatasource = eventEntityViewDataSource(eventId);
    const userpreferencesProvider = userPreferences(userPreferencesDatasource, entityName);
    return getEntityDataSource<T>(proxy, eventId + "/", entityRoute, entityName, userpreferencesProvider, options);
}

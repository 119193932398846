import type { Entities } from '@inwink/entities/entities';
import { AppTextLabel, React } from '../../../commons';
import { IFieldPickerItem, sortFields } from "./definitions";
import type { IEntityPageConfiguration } from '../entitypagev3/entitypagebase.props';
import './popoveritem.less';

export interface IPopoverItemsProps {
    i18n: Entities.i18nHelper;
    displayLanguage: string;
    entityname: string;
    template: Entities.IEntityTemplateV3;
    excludeFields: string[];
    remainingFields: IFieldPickerItem;
    onSelectColumn: (column: IFieldPickerItem) => void;
    entityConfs: Record<string, IEntityPageConfiguration>;
    disabledSortOnGroup?: boolean;
}

export function PopoverItemsList(props: IPopoverItemsProps) {
    const childsObj = props.remainingFields?.childs;

    let haveGroup = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in childsObj) {
        if (Object.prototype.hasOwnProperty.call(childsObj, key)) {
            if (key === 'entity') {
                haveGroup = true;
                break;
            }
        }
    }

    const childs = props.remainingFields && childsObj && Object.keys(childsObj).map((c) => childsObj[c]);

    if (childs && (!haveGroup || !props.disabledSortOnGroup)) {
        childs.sort(sortFields);
    }

    const items = childs?.filter((c) => !c.childs || Object.keys(c.childs).length > 0);
    const content = items?.length
        ? items.map((c, idx) => <PopoverItem {...props} key={"item" + c.key + idx} field={c} />)
        : <AppTextLabel component="div" className="message" i18n="fieldpicker.noresult" />;

    return <div className={"items" + (items?.length ? "" : " noresult")}>
        {content}
    </div>;
}

export interface IPopoverItemProps extends IPopoverItemsProps {
    field: IFieldPickerItem;
}
export class PopoverItem extends React.Component<IPopoverItemProps, any> {
    select = () => {
        this.props.onSelectColumn(this.props.field);
    };

    selectParent = () => {
        this.props.onSelectColumn(this.props.field);
    };

    render() {
        let label; let content; let click; let classes = 'label ';

        if (this.props.field.childs) {
            classes += 'haschild ';
            const isExcluded = this.props.excludeFields && this.props.excludeFields.indexOf(this.props.field.key) >= 0;

            const _value = this.props.field.field && this.props.field.field.value && this.props.field.field.value.toLowerCase();
            const hasRenderer = this.props.entityConfs
        && this.props.entityConfs[_value]
        && this.props.entityConfs[_value].cellRenderer
        && this.props.entityConfs[_value].cellRenderer.entity;

            if (hasRenderer && !isExcluded) {
                click = this.selectParent;
                classes += 'selectable-parent ';
            } else {
                click = null;
                classes += 'not-selectable-parent ';
            }
        } else {
            click = this.select;
        }

        if (this.props.field.tradkeyForEntityname) {
            label = <div
                className={classes}
                data-trad={this.props.field.tradkeyForEntityname}
                onClick={click}
            >{this.props.i18n.translate(this.props.field.tradkeyForEntityname)}</div>;
        } else if (this.props.field.tradkey) {
            label = <div className={classes} data-trad={this.props.field.tradkey} onClick={click}>{this.props.field.label}</div>;
        } else {
            label = <div className={classes} onClick={click}>{this.props.field.label}</div>;
        }

        if (this.props.field.childs) {
            content = <div className="child-items">
                <PopoverItemsList {...this.props} remainingFields={this.props.field} />
            </div>;
        }

        return <div className="item clickable">
            {label}
            {content}
        </div>;
    }
}

import * as React from 'react';
import { getOnboarding } from '../../api/backoffice/template';
import { OnBoardingDefinitions } from './definition';
import { OnBoarding } from './index';
import { getSetting, setSetting } from '../../services/usersettings';

export interface IOnboardingFeatureProps {
    onboardingkey: string;
    forceShow?: any;
    forceLoad?: any;
}

export interface IOnboardingFeatureState {
    config: IBlocTemplate;
    trigger: any;
}

export class OnboardingFeature extends React.Component<IOnboardingFeatureProps, IOnboardingFeatureState> {
    constructor(props) {
        super(props);
        this.state = {
            config: null,
            trigger: new Date()
        };
    }

    loadOnboarding(props: IOnboardingFeatureProps, force?: boolean) {
        return getSetting("onboarding-" + props.onboardingkey).then((onboardingVersion) => {
            return getOnboarding(props.onboardingkey, onboardingVersion, force).then((onboarding) => {
                let patch: Partial<IOnboardingFeatureState> = {
                    config: onboarding
                };

                if (!force && onboarding && onboarding.templateCategory === onboardingVersion) {
                    patch.config = null;
                }

                if (patch.config) {
                    patch.trigger = new Date();
                }

                this.setState(patch as any);
            });
        });
    }

    dismissed = () => {
        setSetting("onboarding-" + this.props.onboardingkey, this.state.config.templateCategory);
    }

    componentDidMount() {
        this.loadOnboarding(this.props);
    }

    componentDidUpdate(prevprops: IOnboardingFeatureProps) {
        if (prevprops.forceLoad !== this.props.forceLoad) {
            this.loadOnboarding(this.props, true);
        }
        if (prevprops.forceShow !== this.props.forceShow) {
            if (!this.state.config) {
                this.loadOnboarding(this.props, true);
            } else {
                this.setState({ trigger: new Date() });
            }
        }
    }

    render() {
        if (!inwink.config.isBetaEnv) {
            return null;
        }

        if (!this.state.config || !this.state.config.template) {
            return null;
        }

        return <OnBoarding
            onboardingkey={this.props.onboardingkey}
            config={this.state.config.template}
            trigger={this.state.trigger}
            onDismiss={this.dismissed} />;
    }
}

import * as React from 'react';
import type { IFileInputManager } from '@inwink/fileutils/fileapi';
import type { Entities } from '@inwink/entities/entities';
import type { ICMSContentTemplatesDatasource, IPictureAssetDataSource } from '../api/base/cmsdatasource';

import type { IEntityPageConfiguration, IConfigurationProviderArgs } from './entities/entitypagev3/entitypagebase.props';
import type { IDashboardDatasource } from './dashboard/definitions';

// eslint-disable-next-line import/no-cycle
// export { CMSContextWrapper } from './cmscontext.wrapper';

export interface ICMSContext {
    customerId?: string;
    tenantId?: string;
    parent: ICMSContext;
    relatedTo: string;
    relatedKind: string;
    languages: string[];
    displayLanguage: string;
    fileInputManager: IFileInputManager;
    eventdetail?: Entities.IEventDetail;
    pictures: IPictureAssetDataSource;
    // bad : on peut savoir si on a un Entities.IEventDetailConfiguration
    // ou un Entities.IRootWebsiteDetailConfiguration en faisant un CMSContext<T>
    configuration: any;
    contentTemplates: ICMSContentTemplatesDatasource;
    isTinyUrlAvailable: (application, state, type, tinyurl) => Promise<{ available: boolean }>;
    getEntityTemplate: (entityname: string, entityParentId?: string) => Promise<any>;
    getWorkTemplate: (entityname: string, entityParentId?: string) => Promise<Entities.IEntityTemplateV3>;
    getEntityConfiguration?: (entityname: string, args: IConfigurationProviderArgs) => Promise<IEntityPageConfiguration>;
    editConfiguration: (config) => Promise<void>;
    previewHost(): string;
    getDashboardDatasource: (moduleName: string) => IDashboardDatasource;
    getRouteTargetPage?: (link: string, context?: any, routeParams?: any, isFromBackOrga?: boolean) => string,
    getComponentConfiguration?: {
        appLinkInput?: (props: any) => any;
    }
}

export const CMSContextProvider = React.createContext<ICMSContext>(null);

export interface ICMSContextBase<TConfig> extends ICMSContext {
    configuration: TConfig;
}

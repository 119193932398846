import { logging } from '@inwink/logging';
import { States } from './services';
import type { ICommunityDetail } from '../data/communities/communitydata';
import { userData } from './userdata';
import { rightsActions } from './rightsservice';
import { realtimeActions } from './realtimeservice';
import { getCommunityDetail } from '../api/community/communitydetail';

const logger = logging.getLogger("data");

const INITIAL_STATE = {
    communityid: null,
    detail: null,
    displayLanguage: null
} as States.ICommunityState;

export function communityReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "COMMUNITY_SETID": {
            return Object.assign({}, state, {
                communityid: action.payload.communityid
            });
        }
        case "COMMUNITY_SET": {
            return Object.assign({}, state, {
                detail: action.payload.community,
                communityid: action.payload?.community?.id
            });
        }
        case "COMMUNITY_CLEAR": {
            return Object.assign({}, INITIAL_STATE);
        }
        case "COMMUNITY_SETDETAIL": {
            const community: ICommunityDetail = action.payload.detail;
            const communitycfg = community?.configuration?.global;

            return Object.assign({}, state, {
                detail: community,
                displayLanguage: action.payload.displayLanguage || (communitycfg && communitycfg.defaultLanguage),
            });
        }
        case "COMMUNITY_SETDISPLAYLANGUAGE": {
            return Object.assign({}, state, {
                displayLanguage: action.payload.displayLanguage,
            });
        }
        case "COMMUNITY_SETDATA": {
            return Object.assign({}, state, {
                data: action.payload
            });
        }
        default:
            return state;
    }
}

let pendingLoadCommunity;

export const communityActions = {
    setCommunityId(communityid: string) {
        return (dispatch) => {
            dispatch({ type: 'COMMUNITY_SETID', payload: { communityid: communityid } });
        };
    },

    refreshCommunityDetail() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            return getCommunityDetail(state.community.communityid).then((detail) => {
                dispatch({
                    type: 'COMMUNITY_SETDETAIL',
                    payload: {
                        detail: detail,
                        displayLanguage: communityActions.getCommunityDisplayLanguage(state, detail)
                    }
                });
                userData.set("community-" + state.community.communityid, detail);
                return detail;
            });
        };
    },

    checkCommunityDisplayLanguage() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (state.community && state.community.detail) {
                const language = communityActions.getCommunityDisplayLanguage(state, state.community.detail);
                communityActions.setCommunityDisplayLanguage(language)(dispatch);
            }
        };
    },

    setCommunityDisplayLanguage(language: string) {
        return (dispatch) => {
            dispatch({
                type: 'COMMUNITY_SETDISPLAYLANGUAGE',
                payload: {
                    displayLanguage: language
                }
            });
        };
    },

    getCommunityDisplayLanguage(state: States.IAppState, community: ICommunityDetail) {
        const communitycfg = community?.configuration?.global;
        const boLanguages = communitycfg?.supportedLanguages.filter((lng) => state.i18n.supported.some((bolng) => bolng === lng));
        if (boLanguages && boLanguages.length) {
            if (boLanguages.indexOf(state.i18n.currentLanguageCode) >= 0) {
                return state.i18n.currentLanguageCode;
            }
        }
        return communitycfg && communitycfg.defaultLanguage;
    },

    clearCurrentCommunity(): (dispatch, getState) => void {
        return (dispatch) => {
            dispatch({ type: "COMMUNITY_CLEAR" });
        };
    },

    setCurrentCommunity(communityid: string, customerid: string): (dispatch, getState) => Promise<any> {
        return (dispatch, getState: () => States.IAppState) => {
            if (pendingLoadCommunity) {
                return Promise.resolve();
            }

            pendingLoadCommunity = communityid;
            const state = getState();
            // const customerId = customerid || state.customer.customerid;

            dispatch({ type: 'COMMUNITY_SET', payload: { community: { id: communityid } } });
            const loadrights = rightsActions.communityRights(customerid, communityid)(dispatch, getState);
            realtimeActions.registerUser()(dispatch, getState);

            const fetchDetail = getCommunityDetail(communityid).then((communitydetail) => {
                dispatch({
                    type: 'COMMUNITY_SETDETAIL',
                    payload: {
                        detail: communitydetail,
                        displayLanguage: communityActions.getCommunityDisplayLanguage(state, communitydetail)
                    }
                });
                userData.set("community-" + communityid, communitydetail);
                console.log('set detail!!');
                return communitydetail;
            });

            return Promise.all([
                loadrights,
                userData.get("community-" + communityid).then((communitydetail: ICommunityDetail) => {
                    if (communitydetail) {
                        logger.debug("get community detail from cache");
                        return communitydetail;
                    }
                    return fetchDetail;
                }).then((communitydetail: ICommunityDetail) => {
                    dispatch({
                        type: 'COMMUNITY_SETDETAIL',
                        payload: {
                            detail: communitydetail,
                            displayLanguage: communityActions.getCommunityDisplayLanguage(state, communitydetail)
                        }
                    });
                    return communitydetail;
                }).catch((err) => {
                    pendingLoadCommunity = null;
                    return err;
                })
            ]).then((data) => {
                pendingLoadCommunity = null;
                return data;
            }).catch((err) => {
                pendingLoadCommunity = null;
                return err;
            });
        };
    }
};

import { IAPIProxy, getAPIProxy, defaultPostHeaderForJsonData } from '@inwink/apibase';
import type { IAssetDataSource, IAssetFileInputManager } from '@inwink/assetspicker';
import {
    getEntityDataSource,
    IDataSourceV3Options,
    IInWinkAdminEntityV3DataSource
} from '../base/datasource';

// export { IAPIProxy, getAPIProxy, defaultPostHeaderForJsonData } from '@inwink/apibase';

let _backOfficeAPIProxy: IAPIProxy = null;

export const getBackOfficeProxy = () => {
    if (!_backOfficeAPIProxy) {
        _backOfficeAPIProxy = getAPIProxy(inwink.config.backOfficeApiUrl);
    }

    return _backOfficeAPIProxy;
};

export function getBackOfficeEntityDataSource<T>(rootpath: string, entityRoute: string, entityName: string,
    options?: IDataSourceV3Options): IInWinkAdminEntityV3DataSource<T> {
    const proxy = getBackOfficeProxy();
    const booptions = Object.assign({}, options, {
        forceDirectMassDelete: true
    });

    return getEntityDataSource<T>(proxy, rootpath, entityRoute, entityName, null, booptions);
}

export function getBackOfficeAssetDataSource(start: string, name: string, type: string): IAssetDataSource {
    return {
        name,
        type,
        list(directory?: string) {
            let url = `${start}/${name}/listfiles`;
            if (directory) {
                url = url + "?directory=" + directory;
            }
            return getBackOfficeProxy().getJson(url);
        },
        createdirectory(directoryname) {
            return getBackOfficeProxy().postJson(`${start}/${name}/createdirectory?directory=` + directoryname);
        },
        send(filename, blob) {
            return getBackOfficeProxy().postJson(`${start}/${name}/sendfile?fileName=${encodeURIComponent(filename)}`, blob);
        },
        delete(filename) {
            return getBackOfficeProxy().postJson(`${start}/${name}/deletefile?fileName=${encodeURIComponent(filename)}`);
        },
        deletedirectory(directoryname) {
            return getBackOfficeProxy().postJson(`${start}/${name}/deletedirectory?directory=` + directoryname);
        },
        fileInputManager: backOfficeAssetsFileInputManager(start, name)
    } as IAssetDataSource;
}

function backOfficeAssetsFileInputManager(start: string, type: string) {
    const res: IAssetFileInputManager = {
        createTmpFile(model: {
            type: string,
            name: string
        }) {
            return getBackOfficeProxy().postJson(`${start}/${type}/upload`, JSON.stringify(model), defaultPostHeaderForJsonData);
        },
        renewTmpAccessFile(model: {
            name: string
        }) {
            return getBackOfficeProxy().postJson(`${start}/${type}/upload/${model.name}/renew`);
        },
        tryRenewTmpAccessFile(model: {
            sas: string,
            name: string
        }) {
            return getBackOfficeProxy().postJson(`${start}/${type}/upload/${model.name}/try-renew`,
                JSON.stringify({ sas: model.sas }), defaultPostHeaderForJsonData);
        },
        getFileAccess() {
            return Promise.reject(new Error("Not implemented"));
        },
        commitFile(tmpBlobName: string, path?: string) {
            return getBackOfficeProxy().postJson(`${start}/${type}/upload/${tmpBlobName}/commit`,
                JSON.stringify({ path: path }), defaultPostHeaderForJsonData);
        },
        copyToTmp(eventId: string, entityName: string, entityId: string, fieldKey: string) {
            const url = `${eventId}/${entityName}/file/${entityId}/${fieldKey}/copytotmp`;
            return getBackOfficeProxy().getJson(url);
        }
    };

    return res;
}

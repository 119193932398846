import { toUrlSlug } from '@inwink/utils/methods';
import * as debounce from 'lodash/debounce';
import { React, AppTextLabel } from '../../../commons';

import './urlinput.less';

interface IUrlInputBaseProps {
    canEditTrad: boolean;
    i18n: string;
    id?: string;
    style?: any;
    statusChange?: (valid: boolean, loading: boolean) => void;
    check: (val: string) => Promise<boolean>;
    maxLength?: number;
    disableToUrlSlug?: boolean;
    enableInitCheck?: boolean;
    autoComplete?: string;
    isForced?: boolean;
}

export interface IUrlInputProps extends IUrlInputBaseProps {
    value: string;
    onChange: (val: string) => void;
}
export class UrlInput extends React.PureComponent<IUrlInputProps, any> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkAvailability = (text) => {};

    constructor(props) {
        super(props);
        this.state = {
            text: (this.props.disableToUrlSlug ? props.value : toUrlSlug(props.value)),
            isvalid: true,
            loading: false
        };
        this.checkAvailability = debounce(this._checkAvailability, 500);
    }

    onChange = (arg: React.FormEvent<any>) => {
        const text = (this.props.disableToUrlSlug ? arg.currentTarget.value : toUrlSlug(arg.currentTarget.value));
        this.setState({ loading: true, text: text });
        if (this.props.statusChange) { this.props.statusChange(this.state.isvalid, true); }

        this.checkAvailability(text);
    };

    componentDidUpdate(prevProps: IUrlInputProps) {
        if (prevProps.value !== this.props.value) {
            const val = (this.props.disableToUrlSlug ? this.props.value : toUrlSlug(this.props.value));
            if (val !== this.state.text) {
                this.setState({ text: val });
                this.checkAvailability(this.props.value);
            }
        }

        if (prevProps.isForced !== this.props.isForced && typeof this.props.isForced !== 'undefined') {
            this.setState({ isvalid: this.props.isForced });
        }
    }

    _checkAvailability = (text): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!text) {
                this.setState({ isvalid: false, loading: false });
                if (this.props.statusChange) { this.props.statusChange(false, false); }
            }
            this.setState({ loading: true });
            if (this.props.statusChange) { this.props.statusChange(this.state.isvalid, true); }
            return this.props.check(text).then((res) => {
                this.setState({ isvalid: res, loading: false });
                if (res) {
                    this.props.onChange(text);
                }
                if (this.props.statusChange) { this.props.statusChange(res, false); }
                resolve();
            }, (err) => {
                if (this.props.statusChange) { this.props.statusChange(false, false); }
                this.setState({ loading: false });
                reject(err);
            });
        });
    };

    componentDidMount() {
        if (this.props.enableInitCheck) {
            this.setState({ loading: true }, () => {
                if (this.props.statusChange) { this.props.statusChange(this.state.isvalid, true); }
                this._checkAvailability(this.state.text);
            });
        }
    }

    render() {
        let status;
        if (this.state.loading) {
            status = <i className="status loading inwink-sync" />;
        } else if (this.state.isvalid) {
            status = <i className="status isvalid inwink-checked" />;
        } else {
            status = <i className="status isinvalid inwink-close-button" />;
        }

        return <div className="fieldbloc urlinput">
            <AppTextLabel component="label" i18n={this.props.i18n} />
            <AppTextLabel
                component="div"
                className="field-desc"
                i18n={this.props.i18n + ".desc"}
                defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""}
            />
            <div
                className={"urlinputwrapper"
                    + (this.state.loading ? " loading" : "")
                    + (this.state.isvalid ? " isvalid" : " isinvalid")}
            >
                <input
                    id={this.props.id}
                    type="text"
                    style={this.props.style}
                    value={this.state.text}
                    onChange={this.onChange}
                    maxLength={this.props.maxLength}
                    autoComplete={this.props.autoComplete}
                />
                {status}
            </div>
        </div>;
    }
}

import { React, TransitionGroup, CSSTransition } from '../../commons';
import { WizardStep, IWizardStep, IWizardStepManager } from './wizard.step';
import { WizardNavbar } from './wizard.navbar';

import './wizard.less';

// export { IWizardStep, IWizardStepManager } from './wizard.step';

interface IWizardProps {
    customProps?: any;
    allowNext?: boolean;
    steps: IWizardStep[];
    stepChanged?: (stepidx) => void;
    transitionName?: string;
    renderBntValidate?: () => void;
    displayCancel?: boolean;
    checkBeforeChangeStep?: (stepIdx?: number, transition?: string) => Promise<boolean>;
    disabledFooter?: boolean;
    fromAddBloc?: boolean;
    setAllowNext?: (allowNext: boolean) => void;
}

export interface IWizardState {
    currentStep: number;
    displayCancel?: boolean;
    allowNext: boolean;
    disabledFooter: boolean;
    currentTransition: string;
    stepmgr: IWizardStepManager;
    transitionName: string;
    renderBntValidate: () => void;
    canGoNext: () => boolean;
    disabledPrev?: boolean;
}

export class Wizard extends React.Component<IWizardProps, IWizardState> {
    constructor(props: IWizardProps) {
        super(props);
        // const wizard = this;
        const state = {
            transitionName: props.transitionName || "wizardtransition",
            currentStep: 0,
            displayCancel: props.displayCancel,
            allowNext: props.allowNext,
            disabledFooter: this.props.disabledFooter,
            currentTransition: "toleft",
            renderBntValidate: this.props.renderBntValidate,
            canGoNext: () => true,
            disabledPrev: false,
            stepmgr: {
                onAllowNext: (allow) => {
                    if (allow !== this.state.allowNext) {
                        this.setState({ allowNext: allow }, () => {
                            if (this.props.setAllowNext) { this.props.setAllowNext(this.state.allowNext); }
                        });
                    }
                },
                onAllowCancel: (displayCancel: boolean) => {
                    if (displayCancel !== this.state.displayCancel) {
                        this.setState({ displayCancel });
                    }
                },
                onDisableFooter: (disabled) => {
                    if (disabled !== this.state.disabledFooter) {
                        this.setState({ disabledFooter: disabled } as any);
                    }
                },
                next: () => {
                    this.navigateTo(this.state.currentStep + 1);
                },
                prev: () => {
                    this.navigateTo(this.state.currentStep - 1);
                },
                setBtnValidate: (renderBntValidate) => {
                    this.setState({ renderBntValidate });
                },
                onDisabledPrev: (disabledPrev) => {
                    this.setState({ disabledPrev });
                },
                setCanGoNext: (canGoNext) => {
                    this.setState({ canGoNext });
                }
            } as IWizardStepManager
        };

        this.state = state;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidUpdate(prevProps: Readonly<IWizardProps>, prevState: Readonly<IWizardState>, snapshot?: any): void {
        if (prevProps.allowNext !== this.props.allowNext) {
            this.setState({ allowNext: this.props.allowNext });
        }

        if (prevProps.disabledFooter !== this.props.disabledFooter) {
            this.setState({ disabledFooter: this.props.disabledFooter });
        }
    }

    navigateTo = (stepIdx: number) => {
        if (!this.state.canGoNext()) {
            return;
        }

        let stepIndex = stepIdx;

        if (stepIdx < 0) {
            stepIndex = 0;
        }

        if (stepIndex >= this.props.steps.length) {
            stepIndex = this.props.steps.length - 1;
        }

        let transition = 'toleft';
        if (this.state.currentStep > stepIndex) {
            transition = 'toright';
        }

        const doNavigate = () => {
            this.setState({ currentTransition: transition } as any, () => {
                this.setState({ currentStep: stepIndex, allowNext: true, canGoNext: () => true }, () => {
                    if (this.props.stepChanged) {
                        this.props.stepChanged(stepIndex);
                    }
                });
            });
        };

        if (this.props.checkBeforeChangeStep) {
            this.props.checkBeforeChangeStep(stepIndex, transition).then((res) => {
                if (!res) { return; }
                doNavigate();
            });

            return;
        }

        doNavigate();
    };

    render() {
        let stepCtrl;
        const stepItem = this.props.steps[this.state.currentStep];
        if (stepItem) {
            stepCtrl = <CSSTransition
                key={"step" + (stepItem.id || '') + this.state.currentStep}
                timeout={1000}
                classNames={this.state.transitionName + this.state.currentTransition}
            >
                <WizardStep
                    stepmgr={this.state.stepmgr}
                    currentStep={this.state.currentStep}
                    step={stepItem}
                    customProps={this.props.customProps}
                />
            </CSSTransition>;
        }

        return (
            <div className="wizardctrl">
                <section className="wizardsteps">
                    <TransitionGroup component="div" className="wizardstep-container">
                        {stepCtrl}
                    </TransitionGroup>
                </section>
                <WizardNavbar
                    steps={this.props.steps}
                    disabledFooter={this.state.disabledFooter}
                    allowNext={this.state.allowNext}
                    currentStep={this.state.currentStep}
                    displayCancel={this.state.displayCancel}
                    onChangeCurrentStep={this.navigateTo}
                    renderBntValidate={this.state.renderBntValidate}
                    disabledPrev={this.state.disabledPrev}
                    customProps={this.props.customProps}
                />
            </div>
        );
    }
}

import { React, AppTextLabel } from '../../../commons';
import { debounce } from '@inwink/react-utils/decorators';

interface ITextWithCheckInputBaseProps {
    canEditTrad: boolean;
    i18n: string;
    id?: string;
    style?: any;
    statusChange?: (valid: boolean, loading: boolean) => void;
    check: (val: string) => Promise<boolean>;
    maxLength?: number;
    disableToTextWithCheckSlug?: boolean;
    enableInitCheck?: boolean;
    hideStatusIfNull: boolean;
}

export interface ITextWithCheckInputProps extends ITextWithCheckInputBaseProps {
    value: string;
    onChange: (val: string) => void;
}

interface ITinyTextWithCheckCheckerPayload {
    action: Promise<any>;
    content: string;
}

export class TextWithCheckInput extends React.PureComponent<ITextWithCheckInputProps, any> {

    queue: ITinyTextWithCheckCheckerPayload[];

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            text: (this.props.disableToTextWithCheckSlug ? props.value : props.value),
            isvalid: true,
            loading: false
        };
        this.queue = [];
    }

    onChange(arg: React.FormEvent<any>) {
        this.setState({ loading: true, text: (this.props.disableToTextWithCheckSlug ? arg.currentTarget.value : arg.currentTarget.value), });
        if (this.props.statusChange) { this.props.statusChange(this.state.isvalid, true); }

        this.checkAvailability(arg.currentTarget.value);
    }

    @debounce(500)
    checkAvailability(text) {
        this.queue.push({
            action: this._checkAvailability(text),
            content: text
        });
    }

    componentDidUpdate(prevProps: ITextWithCheckInputProps) {
        if (prevProps.value !== this.props.value) {
            const val = (this.props.disableToTextWithCheckSlug ? this.props.value : this.props.value);
            if (val !== this.state.text) {
                this.setState({ text: val });
                this.checkAvailability(this.props.value);
            }
        }
    }

    isLastPromise = (text) => {
        let index = 0;
        this.queue.map((p, idx) => {
            if (p.content === text) { index = idx; }
        });
        return index === this.queue.length - 1;
    }

    _checkAvailability = (text): Promise<void> => {

        return new Promise((resolve, reject) => {
            if (!text) {
                this.setState({ isvalid: false, loading: false });
                if (this.props.statusChange) { this.props.statusChange(false, false); }
            }
            this.setState({ loading: true });
            if (this.props.statusChange) { this.props.statusChange(this.state.isvalid, true); }
            return this.props.check(text).then((res) => {
                if (!this.isLastPromise(text)) {
                    return;
                }
                this.setState({ isvalid: res, loading: false });
                if (res) {
                    this.props.onChange(text);
                }
                if (this.props.statusChange) { this.props.statusChange(res, false); }
                resolve();

            }, (err) => {
                if (this.props.statusChange) { this.props.statusChange(false, false); }
                this.setState({ loading: false });
                reject(err);
            });
        });
    }

    componentWillUnmount() {
        this.queue = [];
    }

    componentDidMount() {
        if (this.props.enableInitCheck) {
            this.setState({ loading: true }, () => {
                if (this.props.statusChange) { this.props.statusChange(this.state.isvalid, true); }
                this.checkAvailability(this.state.text);
            });
        }
    }

    render() {

        let hideStatus = false;
        let status;
        let inputClassName;

        if (this.props.hideStatusIfNull) {
            hideStatus = !this.state.text;
        }

        if (hideStatus === false) {
            inputClassName = "urlinputwrapper" + (this.state.loading ? " loading" : "") + (this.state.isvalid ? " isvalid" : " isinvalid");
            if (this.state.loading) {
                status = <i className="status loading inwink-sync"></i>;
            } else if (this.state.isvalid) {
                status = <i className="status isvalid inwink-checked"></i>;
            } else {
                status = <i className="status isinvalid inwink-close-button"></i>;
            }
        }

        return <div className="fieldbloc urlinput">
            <AppTextLabel component="label" i18n={this.props.i18n} />
            <AppTextLabel component="div" className="field-desc" i18n={this.props.i18n + ".desc"} defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""} />
            <div className={inputClassName}>
                <input id={this.props.id} type="text" style={this.props.style} value={this.state.text} onChange={this.onChange} maxLength={this.props.maxLength} />
                {status}
            </div>
        </div>;
    }
}

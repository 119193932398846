import type { Entities } from '@inwink/entities/entities';
import { React, AppTextLabel, Loader, usePrevious, toastError, toastSuccess } from '../../../../commons';
import type { IDataSourceV3 } from '../../../../api/base/datasource';
import { ModalContent } from "../../../ui/modals/modalcontent";
import { EntityPicker } from "../../entitypicker/index";
import { getEventEntityDataSource } from '../../../../api/events/index';

import './import.export.viewsmanager.less';

interface IViewsManagerProps {
    i18n: Entities.i18nService;
    entityName: string;
    allviews: Entities.IEntityView[];
    onCompleted: any;
}

export const ViewsManager = (props: IViewsManagerProps) => {
    const [views, setViews] = React.useState(null);
    const [selectedViews, setSelectedViews] = React.useState<string[]>([]);
    const [viewsToSave, setViewsToSave] = React.useState<Record<string, Record<string, Entities.IEntityView>>>({});

    const [selectedEvents, setSelectedEvents] = React.useState<Entities.IEvent[]>([]);
    const prevSelectedEvents = usePrevious(selectedEvents) || [];
    const [loadedEvents, setLoadedEvents] = React.useState<string[]>([]);
    const [eventsDatasource, setEventsDatasource] = React.useState<Record<string, IDataSourceV3<any>>>({});
    const [eventsAllViews, setEventsAllViews] = React.useState<Record<string, Entities.IEntityView[]>>({});
    const [eventsWorkTemplate, setEventsWorkTemplate] = React.useState<Record<string, Entities.IEntityTemplateV3>>({});

    const checkView = (eventId: string, view: Entities.IEntityView) => {
    // check view Title
        let title = view.title;
        let warningTitle = null;
        const allViews = eventsAllViews[eventId];
        for (const _view of allViews) {
            if (_view.title === view.title && _view.entityName === view.entityName) {
                title = `${view.title} - cc`;
                warningTitle = title;
                break;
            }
        }

        const template = eventsWorkTemplate[eventId];
        const tplKeys = Object.keys(template.fields).map((v) => v.toLowerCase());
        // check view.content.columnKeys
        const columnKeys = [];
        const warningColumnKeys = [];
        for (const i in view.content.columnKeys) {
            if (view.content.columnKeys[i] && tplKeys.indexOf(view.content.columnKeys[i].toLowerCase()) >= 0) {
                columnKeys.push(view.content.columnKeys[i]);
            } else {
                warningColumnKeys.push(view.content.columnKeys[i]);
            }
        }

        // check view.filters
        const filters = {};
        const warningFilters = {};
        for (const filterKey in view.content.filters) {
            if (view.content.filters[filterKey] && tplKeys.indexOf(filterKey.toLowerCase()) >= 0) {
                filters[filterKey] = view.content.filters[filterKey];
            } else {
                warningFilters[filterKey] = view.content.filters[filterKey];
            }
        }

        // check view.content.order
        let order = null;
        let orderWarning = false;
        if (view.content.order && view.content.order.by && tplKeys.indexOf(view.content.order.by.toLowerCase()) < 0) {
            orderWarning = true;
        } else {
            order = view.content.order;
        }

        const viewToSave = {
            eventId: eventId,
            entityName: view.entityName,
            title,
            isDefault: false,
            isShared: view.isShared,
            content: {
                columnKeys,
                order,
                filters,
                search: view.content && view.content.search || ""
            }
        };

        const _viewsToSave = {...viewsToSave};
        if (!_viewsToSave[view.id]) { _viewsToSave[view.id] = {}; }
        // @ts-ignore - filters is wrongly type
        _viewsToSave[view.id][eventId] = viewToSave;
        setViewsToSave(_viewsToSave);
        return { warningTitle, warningColumnKeys, warningFilters, orderWarning};
    };

    const renderEvents = (view: Entities.IEntityView) => {
        return selectedEvents.map((event) => {
            let content; let warningIcon;
            if (!eventsDatasource[event.id] || !eventsAllViews[event.id] || !eventsWorkTemplate[event.id]) {
                content = <Loader />;
            } else {
                const warnings = checkView(event.id, view);
                let hasWarning = false;

                let warningTitle; let warningOrder; const warningColumnKeys = []; const warningFilters = [];
                if (warnings.warningTitle) {
                    hasWarning = true;
                    warningTitle = <AppTextLabel className="warning title-change" component="div" inject={{ warningTitle: warnings.warningTitle}} i18n="export.views.warning.title.change" />;
                }
                if (warnings.orderWarning) {
                    hasWarning = true;
                    warningOrder = <AppTextLabel className="warning order-change" component="div" i18n="export.views.warning.order.change" />;
                }

                if (warnings.warningColumnKeys) {
                    for (const col of warnings.warningColumnKeys) {
                        hasWarning = true;
                        warningColumnKeys.push(<AppTextLabel key={col} className="warning column-delete" component="div" inject={{ column: col }} i18n="export.views.warning.column.delete" />);
                    }
                }

                for (const filter in warnings.warningFilters) {
                    if (warnings.warningFilters[filter]) {
                        hasWarning = true;
                        warningFilters.push(<AppTextLabel key={filter} className="warning filter-delete" component="div" inject={{ filter }} i18n="export.views.warning.filter.delete" />);
                    }
                }
                warningIcon = hasWarning ? <i className="inwink-warning" /> : null;
                content = <div className="warning-container">
                    {warningTitle}
                    {warningOrder}
                    {warningColumnKeys}
                    {warningFilters}
                </div>;
            }
            return <div className="event-container" key={event.id}>
                <h5><AppTextLabel component="span" i18n="page.eventconfig.event" />: {event.title} {warningIcon}</h5>
                {content}
            </div>;
        });
    };

    const onSelectView = (viewIndex, view) => {
        const sV = [...selectedViews];
        if (viewIndex >= 0) {
            sV.splice(viewIndex, 1);
        } else { sV.push(view.id); }

        setSelectedViews(sV);
    };

    const renderViews = () => props.allviews.map((view) => {
        const viewIndex = selectedViews.indexOf(view.id);

        let eventsRender;
        if (viewIndex >= 0) { // render events only if template is selected
            eventsRender = renderEvents(view);
        }

        return (
            <div key={view.id} className="view-content">
                <div className="view-title">
                    <h4><AppTextLabel component="span" i18n="view" />: {view.title}</h4>
                    <input className="checkbox" type="checkbox" checked={viewIndex >= 0} onChange={(e) => onSelectView(viewIndex, view)} />
                </div>
                <div className="view-event-container">
                    {eventsRender}
                </div>
            </div>
        );
    });

    // re-render everything when selected event or views are changed
    React.useMemo(() => {
        setViews(renderViews());
    }, [Object.keys(selectedViews).length, loadedEvents]);

    // when an event is load, load datasource then allViews then workTemplate
    React.useEffect(() => {
        const prevSelectedIds = prevSelectedEvents.map((v) => v.id);

        const _eventsDatasource = { ...eventsDatasource };
        const _eventsAllViews = { ...eventsAllViews };
        const _eventsWorkTemplate = { ...eventsWorkTemplate };
        const _loadedEvents = [...loadedEvents];
        const _viewsToSave = { ...viewsToSave };

        // je sais qu'un truc à changer - reste a savoir quoi
        for (const event of prevSelectedIds) {
            if (selectedEvents.indexOf(event) < 0) { // event supprimé
                delete _eventsDatasource[event.id];
                delete _eventsAllViews[event.id];
                delete _eventsWorkTemplate[event.id];

                for (const viewId in _viewsToSave) {
                    if (_viewsToSave[viewId]) {
                        for (const eventId in _viewsToSave[viewId]) {
                            if (_viewsToSave[viewId][eventId] && eventId === event) {
                                delete _viewsToSave[viewId][eventId];
                            }
                        }
                    }
                }

                _loadedEvents.splice(_loadedEvents.indexOf(event), 1);
            }
        }

        const promises = [];
        for (const event of selectedEvents) {
            if (loadedEvents.indexOf(event.id) < 0) { // new event
                if (!eventsDatasource.hasOwnProperty(event.id)) {
                    const datasource = getEventEntityDataSource<Entities.ISession>(event.id, props.entityName, props.entityName);
                    promises.push(
                        new Promise((resolve, reject) => {
                            _eventsDatasource[event.id] = datasource;
                            resolve(event.id);
                        }),
                        new Promise((resolve, reject) => {
                            datasource.getViews(props.entityName)
                                .then((res) => { _eventsAllViews[event.id] = res; resolve(); })
                                .catch((err) => { console.error(err); reject(err); });
                        }),
                        new Promise((resolve, reject) => {
                            datasource.worktemplate()
                                .then((res) => { _eventsWorkTemplate[event.id] = res; resolve(); })
                                .catch((err) => { console.error(err); reject(err); });
                        })
                    );
                }
            }
        }

        Promise.all(promises).then((res) => {
            setEventsDatasource(_eventsDatasource);
            setEventsAllViews(_eventsAllViews);
            setEventsWorkTemplate(_eventsWorkTemplate);
            setViewsToSave(_viewsToSave);

            if (res && res.length) { _loadedEvents.push(res[0]); }
            setLoadedEvents(_loadedEvents);
        });
    }, [selectedEvents]);

    const getActions = () => [
        {
            callback: null,
            i18n: null,
            direction: "right",
            show: () => {
                return selectedEvents.length > 0 && selectedViews.length > 0;
            },
            component: () => {
                return <AppTextLabel i18n="export.views.counters" inject={{ cntViews: selectedViews.length, cntEvents: selectedEvents.length }} />;
            }
        },
        {
            callback: (arg) => {
                const _todo = {};
                const promises = [];
                const component = this as any;
                for (const viewId in viewsToSave) {
                    // c'est pas beau mais je n'arrive pas à synchroniser les setState pour nettoyer cet objet quand on décoche une vue
                    if (viewsToSave[viewId] && selectedViews.indexOf(viewId) >= 0) {
                        _todo[viewId] = viewsToSave[viewId];
                        for (const event in viewsToSave[viewId]) {
                            if (viewsToSave[viewId][event] && eventsDatasource[event]) {
                                promises.push(eventsDatasource[event].setView(viewsToSave[viewId][event]));
                            }
                        }
                    }
                }

                Promise.all(promises).then((res: any) => {
                    toastSuccess(props.i18n, "export.views.success");
                    props.onCompleted();
                }).catch((res) => {
                    console.error('error ===>', res);
                    toastError(component.i18n, "export.views.error");
                });
            },
            direction: "right",
            isImportant: true,
            show: () => true,
            i18n: "actions.export",
        },
        {
            callback: props.onCompleted,
            direction: "left",
            isImportant: false,
            show: () => true,
            i18n: "actions.cancel",
        }
    ];

    return (
        <ModalContent title="export.views.title" actions={getActions()} onhide={props.onCompleted} className="io-views-manager">
            <div>
                <div>
                    <AppTextLabel component="h3" i18n="export.views.pickevent" />
                    <EntityPicker
                        key="evententitypicker"
                        entityName="Event"
                        multiselect={true}
                        selection={selectedEvents}
                        disableAddNew={true}
                        onChange={(selected, events) => { setSelectedEvents(events); }}
                    />
                </div>
                {selectedEvents.length ? (
                    <div style={{marginTop: "20px"}}>
                        <AppTextLabel component="h3" i18n="export.views.pickview" />
                        {selectedEvents.length ? views : null}
                    </div>
                ) : (null)}
            </div>
        </ModalContent>
    );
};

import { WithPopoverManager, IPopoverManager } from '@inwink/modals';
import { parse, stringify } from '@inwink/utils/querystring';
import { withRouter } from 'react-router-dom';
import { ReactNode } from 'react';
import { confirmModal } from "../modals/confirmmodal";
import { React } from '../../../commons';
import { SimpleTabsDesktop } from './desktop';
import { SimpleTabsSmartphone } from './smartphone';

import './simpletabs.less';

export interface ISimpleTabItemGroup {
    label?: string;
    items: ISimpleTabItem[];
}

export interface ISimpleTabItem {
    id: string;
    label: string;
    component: any;
    icon?: string;
    showIf?: (datacontext: any) => boolean;
    lang?: string;
    badge?: any;
    link?: {
        url: string,
        target: string
    }
}

export interface ISimpleTabItemBadgeProps {
    item: ISimpleTabItem;
    onBadgeUpdate: () => void;
}

export interface ISimpleTabsProps {
    forceDisplayTab?: boolean;
    desktopmode?: string;
    autocollapse?: boolean;
    selected?: string;
    selectedItem?: ISimpleTabItem;
    queryparams?: string;
    visualstates?: string[];
    visualstateSwitch?: string;
    groups: ISimpleTabItemGroup[];
    addTabIdToUrl?: boolean;
    getPanelProps: (item: ISimpleTabItem) => any;
    itemSelected?: (item: ISimpleTabItem) => void;
    popovermgr?: IPopoverManager;
    datacontext?: any;
    handleChangeTabs?: (selectedTabId: string) => void;
    panelsIdWithWarning?: string[];
    panelsWarningMessage?: string;
    customizeTab?: () => any;
    removeOffsetOnItems?: boolean;
    isTabDirty?: boolean;
    onContentRefresh?: (item: ISimpleTabItem) => void;
    history?: any;
    location?: any;
    match?: any;
    options?: ISimpleTabsOptionsProps[];
    children?: ReactNode;
    ref?: any;
    loadingPanelProps?: {
        noInheritanceAfterFirstLoad: boolean;
        noInheritance: boolean;
    }
}

interface ISimpleTabsOptionsProps {
    SimpleTabsContent: {
        className?: string[];
    };
}

export interface ISimpleTabsContentProps extends ISimpleTabsProps {
    tabRefresh: Record<string, any>;
}

export function SimpleTabs(props: ISimpleTabsProps) {
    return (
        <WithPopoverManager>
            {(popovermgr) => <SimpleTabsShell {...props} popovermgr={popovermgr} />}
        </WithPopoverManager>
    );
}

interface ISimpleTabsShellProps extends ISimpleTabsProps {
    history?: any;
    location?: any;
    match?: any;
}

class SimpleTabsShellControl extends React.Component<ISimpleTabsShellProps, any> {
    currentTabCtrl: React.RefObject<any>;

    constructor(props: ISimpleTabsProps) {
        super(props);
        this.currentTabCtrl = React.createRef<any>();
        this.itemSelected = this.itemSelected.bind(this);
        this.state = {
            selectedItem: this.getSelectedItem(props, true),
            tabRefresh: {}
        };
    }

    componentDidUpdate(prevProps: ISimpleTabsProps) {
        if (
            (prevProps.selectedItem !== this.props.selectedItem)
            || (prevProps.addTabIdToUrl !== this.props.addTabIdToUrl)
            || (JSON.stringify(prevProps.queryparams) !== JSON.stringify(this.props.queryparams))
            || (prevProps.selected !== this.props.selected)
        ) {
            // Switch tabs from external control:
            const selectedItem = this.getSelectedItem(this.props, true);
            if (selectedItem) { this.setState({ selectedItem }); }
        }
    }

    canClosePage(context?: any) {
        if (this.currentTabCtrl) {
            return this.currentTabCtrl.current.canClosePage(context);
        }
        return Promise.resolve(true);
    }

    getSelectedItem(props: ISimpleTabsProps, isFirstCheck?: boolean) {
        let selectedItem = this.state && this.state.selectedItem;

        if (props.selectedItem) {
            return props.selectedItem;
        }

        if (props.addTabIdToUrl && props.queryparams) {
            const query = parse(props.queryparams);
            if (query.tab) {
                props.groups.forEach((grp) => {
                    grp.items.forEach((i) => {
                        if (i.id === query.tab) {
                            selectedItem = i;
                        }
                    });
                });
            }
        } else if (isFirstCheck && props.selected) {
            props.groups.forEach((grp) => {
                grp.items.forEach((i) => {
                    if (i.id === props.selected) {
                        selectedItem = i;
                    }
                });
            });
        }

        if ((!selectedItem) && props.groups && props.groups.length) {
            const grp = props.groups[0];
            if (grp && grp.items && grp.items.length) {
                selectedItem = grp.items[0];
            }
        }

        return selectedItem;
    }

    doChangetab(item: ISimpleTabItem) {
        if (this.props.itemSelected) {
            this.props.itemSelected(item);
        }

        if (this.props.addTabIdToUrl && item.id) {
            let location = this.props.location;
            const query = parse(location.search);
            query.tab = item.id;

            location = Object.assign({}, location, {
                ignoreNavPrompt: true,
                search: stringify(query)
            });
            this.props.history.replace(location);
            return;
        }

        if (item.id && typeof this.props.handleChangeTabs === 'function') {
            this.props.handleChangeTabs(item.id);
        }

        this.setState({
            selectedItem: item
        });
    }

    itemSelected(item: ISimpleTabItem) {
        this.canClosePage({ from: "tap" }).then((canclose: boolean) => {
            if (this.props.isTabDirty) {
                return confirmModal(this.props.popovermgr, "modal.confirm.exit", "modal.confirm.changeTab").then((res) => {
                    if (res) {
                        this.doChangetab(item);
                    }
                });
            } if (!canclose) {
                return;
            }

            this.doChangetab(item);
        });
    }

    onContentRefreshed = (item: ISimpleTabItem) => {
        this.setState((prevstate) => {
            return {
                tabRefresh: Object.assign({}, prevstate.tabRefresh, {
                    [item.id || ""]: new Date()
                })
            };
        }, () => {
            if (this.props.onContentRefresh) {
                this.props.onContentRefresh(item);
            }
        });
    };

    render() {
        if (!this.props.visualstates || this.props.visualstates.indexOf(this.props.visualstateSwitch) >= 0) {
            return <SimpleTabsDesktop
                {...this.props}
                ref={this.currentTabCtrl}
                tabRefresh={this.state.tabRefresh}
                selectedItem={this.state.selectedItem}
                itemSelected={this.itemSelected}
                onContentRefresh={this.onContentRefreshed}
            />;
        }

        return <SimpleTabsSmartphone
            {...this.props}
            ref={this.currentTabCtrl}
            tabRefresh={this.state.tabRefresh}
            selectedItem={this.state.selectedItem}
            itemSelected={this.itemSelected}
            onContentRefresh={this.onContentRefreshed}
        />;
    }
}
export const SimpleTabsShell: React.ComponentClass<ISimpleTabsShellProps> = (withRouter<any>(SimpleTabsShellControl) as any);

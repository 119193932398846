import type { Entities } from '@inwink/entities/entities';
import { React } from '../../../commons';
import type { IExpressionDesignerProps } from './expressiondesigner';
import { AddExpression } from './expressions/addexpression';
import type { FieldFilterCallback } from '../definitions';
import type { IEntityPageConfiguration } from '../entitypagev3/entitypagebase.props';

interface IExpressionDesignerAddItemProps extends IExpressionDesignerProps {
    onAddExpression: (expr: Entities.Expression, removeNull?: number) => void;
    onRemoveAll?: (arg) => void;
    removeNull?: number;
    isFirst: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    element_separator?: string;
    showAddItem?: boolean;
    separator?: string;
    isMailTrigger?: boolean;
    fieldFilter?: FieldFilterCallback;
    entityconfig: IEntityPageConfiguration;
}

export class ExpressionDesignerAddItem extends React.Component<IExpressionDesignerAddItemProps, any> {
    addExpression = (_expression, entityname?: string) => {
        const expression = _expression;
        if (expression.or && expression.or.length > 0 && !this.props.isMailTrigger) {
            expression.or.map((res) => {
                if (this.props.isFieldEditor) {
                    res.name = 'entity.' + res.name;
                } else if (this.props.addEntityName && this.props.targetEntityName) {
                    res.name = entityname + "." + res.name;
                    if (res.not?.name) {
                        res.not.name = entityname + "." + res.not.name;
                    }
                }
                return null;
            });
        } else if (expression.and && expression.and.length > 0) {
            expression.and.map((res) => {
                if (this.props.isFieldEditor) {
                    res.name = 'entity.' + expression.name;
                } else if (this.props.addEntityName && this.props.targetEntityName) {
                    res.name = entityname + "." + expression.name;
                    if (res.not?.name) {
                        res.not.name = entityname + "." + expression.name;
                    }
                } else {
                    res.name = expression.name;
                }
                return null;
            });
        }

        if (this.props.isFieldEditor) {
            expression.name = 'entity.' + expression.name;
            // } else if (this.props.groups && Object.keys(this.props.groups).length > 0 && entityname) {
            //     expression.name = `${entityname}.${expression.name}`;
        } else if (this.props.addEntityName) {
            const prefix = entityname;
            if (prefix) {
                expression.name = prefix + "." + expression.name;
            }
        }

        expression.separator = this.props.element_separator;
        this.props.onAddExpression(expression, this.props.removeNull);
    };

    render() {
        return <div className={"expressiondesigner-additem" + ((this.props.showAddItem) ? ' showadditem' : ' hideadditem')}>
            <AddExpression
                cmscontext={this.props.cmscontext}
                groups={this.props.groups}
                displayConditionLabel={true}
                onAdd={this.addExpression}
                isFirst={this.props.isFirst}
                separator={this.props.separator}
                options={this.props.options}
                fieldFilter={this.props.fieldFilter}
                entityconfig={this.props.entityconfig}
                disabledSortOnGroup={this.props.disabledSortOnGroup}
            />
        </div>;
    }
}

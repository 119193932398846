import { Entities } from '@inwink/entities/entities';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { React, IPopoverManager, AppAsyncButton } from '../../commons';
import { i18NHelperContext } from '../../services/i18nservice';
import { ItemWebsite, IItemWebsiteProps } from './itemwebsite';

import './communityitem.less';

interface ICommunityItemProps {
    popovermgr: IPopoverManager;
    className?: string;
    communityitem: any;
    customerid: string;
    onRemove?: (communityitem: any) => any;
    style?: any;
    disableClick?: boolean;
    onClick?: (communityitem: any) => void;
    onAfterClick?: (communityitem: any) => void;
    children?: ReactNode;
}

export class CommunityItem extends React.Component<ICommunityItemProps, any> {
    eventClicked = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onClick(this.props.communityitem);
    };

    afterClick = () => {
        if (this.props.onAfterClick) {
            this.props.onAfterClick(this.props.communityitem);
        }
    };

    openPreview = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const props: IItemWebsiteProps = {
            item: this.props.communityitem,
            itemType: 'community'
        };

        this.props.popovermgr.popoverPortal(arg.currentTarget, ItemWebsite, props, "itemwebsitepopover");
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    renderContent(i18n: Entities.i18nHelper) {
        const community = this.props.communityitem;
        const actionsItems = [];
        let actions = null;
        if (community.communityKey) {
            actionsItems.push(<AppAsyncButton
                key="openpreview"
                title="actions.openpreview"
                className="openpreview"
                onClick={this.openPreview}
            ><i className="inwink-web" /></AppAsyncButton>);
        }

        if (actionsItems.length) {
            actions = <div className="actions">{actionsItems}</div>;
        }

        return <>
            <img alt="inwink" className="icon" src={(inwink.config.assetsUrl || "/") + "assets/images/inwink_logo_people.svg"} />
            <div className="communitydetail">
                <div className="communityname">
                    <div className="communityname-title">{community.name || community.title}</div>
                </div>
                {community?.authTenant?.name ? <div className="tenantname">
                    <div className="tenantname-title">{community.authTenant.name}</div>
                </div> : null}
            </div>
            {this.props.children ? <div className='childs'>{this.props.children}</div> : null}
            {actions}
        </>;
    }

    render() {
        const community = this.props.communityitem;

        if (this.props.disableClick) {
            return <div className={"community-item clickable " + (this.props.className || "")} style={this.props.style}>
                <i18NHelperContext.Consumer>
                    {(i18n) => this.renderContent(i18n)}
                </i18NHelperContext.Consumer>
            </div>;
        }

        if (this.props.onClick) {
            return <div
                className={"community-item clickable " + (this.props.className || "")}
                style={this.props.style}
                onClick={this.eventClicked}
            >
                <i18NHelperContext.Consumer>
                    {(i18n) => this.renderContent(i18n)}
                </i18NHelperContext.Consumer>
            </div>;
        }

        return <Link
            to={"/" + this.props.customerid + "/c/" + community.id}
            className={"community-item " + (this.props.className || "")}
            style={this.props.style}
            onClick={this.afterClick}
        >
            <i18NHelperContext.Consumer>
                {(i18n) => this.renderContent(i18n)}
            </i18NHelperContext.Consumer>
        </Link>;
    }
}

import { React, AppTextLabel, AppAsyncButton } from '../../commons';

import './buttonbar.less';

export interface IButtonBarAction {
    id?: string;
    callback: (arg) => void | Promise<any>;
    direction?: string;
    isImportant?: boolean;
    show?: (() => boolean) | boolean;
    disabled?: (() => boolean) | boolean;
    i18n: string | (() => string);
    title?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    i18n_inject?: any;
    className?: string;
    component?: any;
    icon?: string;
}

interface IButtonBarProps {
    component: any;
    className?: string;
    actions?: IButtonBarAction[];
}

export class ButtonBar extends React.Component<IButtonBarProps, any> {
    render() {
        const leftactions = [];
        const rightactions = [];
        if (this.props.actions && this.props.actions.length) {
            this.props.actions.forEach((action, k) => {
                let disabled;
                if (action.show) {
                    if (typeof action.show === "function") {
                        if (!action.show()) {
                            return;
                        }
                    } else if (!action.show) {
                        return;
                    }
                }
                if (action.disabled) {
                    if (typeof action.disabled === "function") {
                        disabled = action.disabled();
                    } else {
                        disabled = action.disabled;
                    }
                }
                let label: string;
                if (action.i18n) {
                    if (typeof action.i18n === "string") {
                        label = action.i18n;
                    } else {
                        label = action.i18n();
                    }
                }

                const commandName = action.id || ("command" + k);
                let actionElt = null;
                if (action.component) {
                    actionElt = React.createElement(action.component,
                        {...this.props, key: "action-" + action.direction + "-" + k, action: action });
                } else {
                    const icon = action.icon ? <i className={action.icon} /> : null;
                    actionElt = <AppAsyncButton
                        key={"action-" + action.direction + "-" + k}
                        commandName={commandName}
                        disabled={disabled}
                        onClick={(arg) => action.callback(arg) as any}
                        title={action.title || label}
                        className={
                            "buttonbarbutton" + ((action.direction === "left") ? " greybtn " : "") + ((action.isImportant) ? " important " : "") + " " + (action.className || "")
                                + " " + (icon ? " iconbtn" : "") + (disabled ? "disabled" : "")
                        }
                    >
                        {icon}
                        <AppTextLabel className="btnlabel" i18n={label} inject={action.i18n_inject} />
                    </AppAsyncButton>;
                }
                if (action.direction === "left") {
                    leftactions.push(actionElt);
                } else {
                    rightactions.push(actionElt);
                }
            });
        }

        const childs = [
            <div key="left" className="leftwrapper">{leftactions}</div>,
            <div key="right" className="rightwrapper">{rightactions}</div>
        ];

        if (this.props.children) {
            childs.push(this.props.children as any);
        }

        return React.createElement(this.props.component,
            { className: "buttonbar buttontoolbar " + (this.props.className || "") }, childs);
    }
}

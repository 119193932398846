import { React, AppTextLabel, Loader } from '../../../commons';
import { Swipeable } from '../swipeable';

import './selectableitem.less';

export interface ISelectableItemProps {
    className?: string;
    isSelected: boolean;
    swipeable?: boolean;
    onSwiped?: () => void;
    onClick?: (arg: React.MouseEvent<any>) => void;
}

export class SelectableItem extends React.Component<ISelectableItemProps, any> {
    swipeSelectIndicator = React.createRef<HTMLDivElement>();

    onSwiped = () => {
        if (this.props.onSwiped) {
            this.props.onSwiped();
        }
    }

    onSwipeProgress = (progress) => {
        if (this.swipeSelectIndicator.current) {
            let opacity = (progress / 100);
            opacity = opacity * opacity * opacity; // on fait ca pour faire un easing sur l'opacité
            this.swipeSelectIndicator.current.style.opacity = opacity + "";
        }
    }

    render() {
        let classNames = ["iw-selectableitem clickable"];
        if (this.props.isSelected) {
            classNames.push("selected");
        }
        if (this.props.className) {
            classNames.push(this.props.className);
        }

        let content = null;
        let bg = null;
        if (this.props.swipeable) {
            bg = <div className="swipeselectindicator" ref={this.swipeSelectIndicator}>
                <div className="progressindicator"><i className="inwink-selected"></i></div>
            </div>;
            content = <Swipeable className="iw-selectableitem-content"
                disableLeft={true}
                behavior="swipeselect"
                resistance={true}
                max={30}
                threshold={2}
                selectThresholdPx={48}
                onSwipeSelect={this.onSwiped}
                onSwipeSelectProgress={this.onSwipeProgress}>
                {this.props.children}
            </Swipeable>;
        } else {
            content = <div className="iw-selectableitem-content">
                {this.props.children}
            </div>;
        }

        return <div className={classNames.join(" ")} onClick={this.props.onClick}>
            {bg}
            {content}
            <div className="selectionborder"></div>
            <div className="selectionmarker"><i className="inwink-selected"></i></div>
        </div>;
    }
}

import { React, AppTextLabel } from '../../../commons';
import type { ISimpleTabsContentProps, ISimpleTabItem } from './index';
import { SimpleTabsContent } from './content';
import { SimpleTabsItems } from './items';

export class SimpleTabsSmartphone extends React.Component<ISimpleTabsContentProps, any> {
    currentTabCtrl = React.createRef<SimpleTabsContent>();

    constructor(props) {
        super(props);
        this.toggleItems = this.toggleItems.bind(this);
        this.itemSelected = this.itemSelected.bind(this);
        this.state = {
            showItems: false
        };
    }

    canClosePage(context?: any) {
        if (this.currentTabCtrl) {
            return this.currentTabCtrl.current.canClosePage(context);
        }
        return Promise.resolve(true);
    }

    toggleItems() {
        this.setState({ showItems: true });
    }

    itemSelected(item: ISimpleTabItem) {
        this.setState({ showItems: false });
        this.props.itemSelected(item);
    }

    render() {
        let label;
        if (this.props.selectedItem) {
            label = <AppTextLabel className="title" component="span" i18n={this.props.selectedItem.label} />;
        }

        return <div className="simpletabs simpletabs-smartphone">
            <div className="simpletabs-header clickable" onClick={this.toggleItems}>
                {label} <i className="inwink-chevron-down" /></div>
            <SimpleTabsContent {...this.props} ref={this.currentTabCtrl} />
            <SimpleTabsItems
                {...this.props}
                className={this.state.showItems ? "visible" : "collapsed"}
                itemSelected={this.itemSelected}
            />
        </div>;
    }
}

import { React, AppAsyncButton, Loader } from '../../../../commons';
import type { IEntityScreenProps } from '../entitypagebase';
import type { EntityPageAction } from '../entitypagebase.props';
import { showActionsMenu } from '../actions';
import './footer.less';

export interface IEntityScreenSmartphoneFooterProps extends IEntityScreenProps {
    isLoading: boolean;
}
interface IEntityScreenSmartphoneFooterState {
}
export class EntityScreenSmartphoneFooter
    extends React.Component<IEntityScreenSmartphoneFooterProps, IEntityScreenSmartphoneFooterState> {
    constructor(props: IEntityScreenSmartphoneFooterProps) {
        super(props);
        this.state = {
        };
    }

    showMoreActions = (arg: React.MouseEvent<any>) => {
        showActionsMenu(arg.currentTarget, {
            popovermgr: this.props.popovermgr,
            viewsState: this.props.viewsState,
            helpers: this.props.helpers,
            computedActions: this.props.computedActions,
            parentTarget: arg.currentTarget,
            entityconf: this.props.entityconfiguration
        });
    };

    actionClicked = (arg: React.MouseEvent<any>, action: EntityPageAction) => {
        return this.props.helpers.triggerAction(action, arg);
    };

    render() {
        if (this.props.runMode && this.props.runMode !== "entitypage") {
            return null;
        }

        const actions = this.props.computedActions?.primaryActions && this.props.computedActions.primaryActions.map((act) => {
            let label = null;
            if (act.label) {
                label = typeof act.label === "string"
                    ? this.props.i18nHelper.translate(act.label)
                    : act.label();
            }

            return <AppAsyncButton
                className="action"
                key={"footeraction-" + act.key}
                id={"footeraction-" + act.key}
                onClick={(arg) => this.actionClicked(arg, act)}
                title={this.props.i18nHelper.translateBag(label)}
                disabled={(act.scope.selection && !this.props.viewsState.selection.length)}
            >
                <i className={act.icon} />
            </AppAsyncButton>;
        });

        return <footer>
            <div className="actions">
                {actions}
            </div>
            <div className="moreactions">
                <div className={"loadingstate" + (this.props.isLoading ? " loading" : "")}>
                    <Loader message="" />
                </div>
                {this.props.computedActions.secondaryActions ? <AppAsyncButton className="action" onClick={this.showMoreActions}>
                    <i className="inwink-more-h" />
                </AppAsyncButton> : null}
            </div>
        </footer>;
    }
}

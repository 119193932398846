import { React, AppAsyncButton } from '../../../commons';
import type { IItemsPickerProps } from './index';

import './itemspicker.less';

export interface IItemsPickerItemProps extends IItemsPickerProps {
    item: any;
}

export class ItemsPickerItem extends React.Component<IItemsPickerItemProps, any> {
    removeItem = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        return this.props.onRemoveItem(this.props.item);
    };

    onUpdateItem = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        return this.props.onUpdateItem(this.props.item);
    };

    itemClicked = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onItemClicked(this.props.item);
    };

    render() {
        const content = this.props.itemsRenderer(this.props.item);

        return <div className={"iw-itemspicker-item" + (this.props.onItemClicked ? " itemclickable" : "")}>
            <div className="iw-itemspicker-item-content">{(this.props.onItemClicked
                ? <a onClick={this.itemClicked}>{content}</a>
                : content)}</div>
            {!this.props.readOnly && this.props.onUpdateItem
                ? <AppAsyncButton key="btnupdate" onClick={this.onUpdateItem}>
                    <i className="inwink-pencil" /></AppAsyncButton> : null}
            {!this.props.readOnly && this.props.onRemoveItem
                ? <AppAsyncButton onClick={this.removeItem}>
                    <i className="inwink-dialog-cancel" /></AppAsyncButton> : null}
        </div>;
    }
}

import { React } from '../../../commons';
import type { ISimpleTabsContentProps } from './index';
import { SimpleTabsContent } from './content';
import { SimpleTabsTopItems, SimpleTabsItems } from './items';

export class SimpleTabsDesktop extends React.Component<ISimpleTabsContentProps, any> {
    currentTabCtrl = React.createRef<SimpleTabsContent>();

    canClosePage(context?: any) {
        if (this.currentTabCtrl) {
            return this.currentTabCtrl.current.canClosePage(context);
        }
        return Promise.resolve(true);
    }

    render() {
        const desktopmode = this.props.desktopmode || "left";

        return <div className={"simpletabs simpletabs-desktop mode-" + desktopmode}>
            {desktopmode === "top" ? <SimpleTabsTopItems {...this.props} /> : <SimpleTabsItems {...this.props} />}
            <SimpleTabsContent {...this.props} ref={this.currentTabCtrl} />
        </div>;
    }
}

import { openDB, IDBPDatabase } from 'idb';

enum storageMode {
    idb,
    localstr,
    sessionstr
}

export function requestBrowserStorage(storageName: string): Promise<BrowserStorage> {
    return new Promise((resolve, reject) => {
        const processStores = () => {
            if (window && window.localStorage) {
                resolve(new BrowserStorage(storageName, storageMode.localstr));
            } else {
                resolve(new BrowserStorage(storageName, storageMode.sessionstr));
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(null);
        };

        if (window && window.indexedDB) {
            const test = indexedDB.open("test", 1);
            test.onsuccess = () => {
                resolve(new BrowserStorage(storageName, storageMode.idb));
            };
            test.onerror = () => {
                processStores();
            };
        } else {
            processStores();
        }
    });
}

export class BrowserStorage {
    dbPromise: Promise<IDBPDatabase<any>>;

    keystore: string;

    storageMode: storageMode;

    constructor(keystore: string, _storageMode: storageMode) {
        this.keystore = keystore;
        this.storageMode = _storageMode;
    }

    get(key): Promise<any> {
        switch (this.storageMode) {
            case storageMode.idb:
                return this.dbPromise.then((db) => {
                    return db.transaction(this.keystore)
                        .objectStore(this.keystore).get(key);
                }, (err) => {
                    console.error("Error getting data from indexed db", err);
                });
            case storageMode.localstr: {
                const value = localStorage.getItem(this.keystore + key);
                if (value) {
                    try {
                        return Promise.resolve(JSON.parse(value));
                    } catch (err) {
                        return Promise.resolve(null);
                    }
                }
                return Promise.resolve(null);
            }
            case storageMode.sessionstr:
                return new Promise((resolve) => {
                    return resolve(JSON.parse(sessionStorage.getItem(this.keystore + key)));
                });
            default:
                break;
        }
    }

    set(key, val) {
        switch (this.storageMode) {
            case storageMode.idb:
                return this.dbPromise.then((db) => {
                    const tx = db.transaction(this.keystore, 'readwrite');
                    tx.objectStore(this.keystore).put(val, key);
                    return tx.done;
                }, (err) => {
                    console.error("Error saving data to indexed db", err);
                });
            case storageMode.localstr:
                return new Promise((resolve) => {
                    localStorage.setItem(this.keystore + key, JSON.stringify(val));
                    return resolve();
                });
            case storageMode.sessionstr:
                return new Promise((resolve) => {
                    sessionStorage.setItem(this.keystore + key, JSON.stringify(val));
                    return resolve();
                });
            default:
                break;
        }
    }

    deleteStorage(key) {
        switch (this.storageMode) {
            case storageMode.idb:
                return this.dbPromise.then((db) => {
                    const tx = db.transaction(this.keystore, 'readwrite');
                    tx.objectStore(this.keystore).delete(key);
                    return tx.done;
                }, (err) => {
                    console.error("Error removing data from indexed db", err);
                });
            case storageMode.localstr:
                return new Promise((resolve) => {
                    localStorage.removeItem(this.keystore + key);
                    return resolve();
                });
            case storageMode.sessionstr:
                return new Promise((resolve) => {
                    sessionStorage.removeItem(this.keystore + key);
                    return resolve();
                });
            default:
                break;
        }
    }

    clear() {
        switch (this.storageMode) {
            case storageMode.idb:
                return this.dbPromise.then((db) => {
                    const tx = db.transaction(this.keystore, 'readwrite');
                    tx.objectStore(this.keystore).clear();
                    return tx.done;
                }, (err) => {
                    console.error("Error clearing data from indexed db", err);
                });
            case storageMode.localstr:
                return new Promise((resolve) => {
                    localStorage.clear();
                    return resolve();
                });
            case storageMode.sessionstr:
                return new Promise((resolve) => {
                    sessionStorage.clear();
                    return resolve();
                });
            default:
                break;
        }
    }

    keys() {
        switch (this.storageMode) {
            case storageMode.idb:
                return this.dbPromise.then((db) => {
                    const tx = db.transaction(this.keystore);
                    const keys = [];
                    const store = tx.objectStore(this.keystore) as any;
                    // debugger;
                    // This would be store.getAllKeys(), but it isn't supported by Edge or Safari.
                    // openKeyCursor isn't supported by Safari, so we fall back
                    (store.iterateKeyCursor || store.iterateCursor).call(store, (cursor) => {
                        if (!cursor) { return; }
                        keys.push(cursor.key);
                        cursor.continue();
                    });

                    return tx.done.then(() => keys);
                }, (err) => {
                    console.error("Error listing keys from indexed db", err);
                });
            default:
                break;
        }
    }

    openData(data, version) {
        switch (this.storageMode) {
            case storageMode.idb:
                this.dbPromise = openDB<any>(data, version, {
                    upgrade: (upgradeDB) => {
                        upgradeDB.createObjectStore(this.keystore);
                    }
                }).then((db) => {
                    return db;
                }, (err) => {
                    console.error("Error opening data from indexed db", err);
                });
                return this.dbPromise;
            default:
                break;
        }
    }
}

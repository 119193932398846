/* eslint-disable max-classes-per-file */
import type { Entities } from '@inwink/entities/entities';
import { connectwith } from '@inwink/react-utils';
import { IToolbarItem, Toolbar } from '@@components/ui/toolbar';
import {
    React, AppLabel, IPopoverManager
} from '../../../../commons';
import type { ICMSContext } from "../../../cmscontext";
import { openEntitySchema } from "../../../designers/entityschemadesigner";
import type { States } from '../../../../services/services';
import { ColumnsSelector } from '../columnselector';
import type { EntityGridColumns } from './entitygrid.columns';
import type { IEntityScreenHelpers, IEntityPageConfiguration, EntityPageRunMode } from '../entitypagebase.props';
import { fieldFilter } from '../fieldFilter';

import "./entitygrid.settings.less";

export interface IGridSettingsEditorProps {
    helpers: IEntityScreenHelpers;
    popovermgr: IPopoverManager;
    entityconfiguration: IEntityPageConfiguration;
    titlePage: string;
    columnsmgr: EntityGridColumns;
    // refreshGrid: (props: any) => void;
    template: Entities.IEntityTemplateV3;
    selectedColumns: string[];
    i18n: States.i18nState;
    i18nHelper: Entities.i18nHelper;
    entityRights?: InWinkBO.IEntityRights;
    cmscontext: ICMSContext;
    entityPageComponent?: any;
    filteredExportQueryOptions?: () => any;
    isOpen: boolean;
    onChange: (visible: boolean) => void;
    allowSetMandatory?: boolean;
    user?: States.IAppUserState;
    entityConfs: Record<string, IEntityPageConfiguration>;
    runMode?: EntityPageRunMode;
}

interface IGridSettingsEditorState {
    columnsDataStamp: any;
    selectedColumns: string[];
    fieldFilter?: (key: string, field: Entities.IEntityFieldTemplateV3,
        template: Entities.IEntityTemplateV3, entityname: string) => boolean;
}

function mapStateToProps(state: States.IAppState) {
    return {
        user: state.user
    };
}

@connectwith(mapStateToProps)
export class GridSettingsEditor extends React.Component<IGridSettingsEditorProps, IGridSettingsEditorState> {
    editorActions: IGridColumnEditorAction[] = [];

    constructor(props: IGridSettingsEditorProps) {
        super(props);
        this.state = {
            columnsDataStamp: new Date(),
            selectedColumns: this.props.selectedColumns || [],
            fieldFilter: (key: string, field: Entities.IEntityFieldTemplateV3) => {
                return fieldFilter(key, props.entityconfiguration, props.user, field, props.runMode === "removedentitypage");
            }
        };
        const conf = this.props.entityconfiguration;

        if (conf.entityName && !conf.disableEntityConfiguration && props.runMode === "entitypage") {
            this.editorActions.push({
                enabled: (this.props.entityRights && this.props.entityRights.canChangeSettings),
                callback: this.openEntitySchema,
                label: 'actions.configure',
                key: "configureentity"
            });
        }
    }

    componentDidMount() {
        (document.querySelector(".inner-editor") as HTMLElement).onscroll = () => {
            const container: HTMLElement = document.querySelector(".inner-editor");
            const header: HTMLElement = document.querySelector(".entitygrid-settings");
            if (container.scrollTop >= 37) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        };
    }

    componentDidUpdate(prevprops: IGridSettingsEditorProps) {
        if (!prevprops.isOpen && this.props.isOpen) {
            this.setState({
                columnsDataStamp: new Date(),
                selectedColumns: this.props.selectedColumns || []
            });
        }
    }

    toggleEditor = () => {
        this.props.onChange(!this.props.isOpen);
    };

    save = () => {
        this.toggleEditor();
        setTimeout(() => {
            this.props.columnsmgr.updateColumnsSelection(this.state.selectedColumns);
        }, 200);
    };

    /* Put everything back like it was and close tirette */
    cancel = () => {
        this.setState({
            columnsDataStamp: new Date(),
            selectedColumns: this.props.selectedColumns || []
        });
    };

    /* Configure entity fields and schema */
    openEntitySchema = () => {
        openEntitySchema(this.props.popovermgr,
            this.props.entityconfiguration.entityName,
            this.props.cmscontext,
            null,
            this.props.entityconfiguration.applyForValues,
            this.props.entityconfiguration.datasource,
            this.props.allowSetMandatory ? this.props.allowSetMandatory : false,
            this.props.entityconfiguration.excludedFields || [],
            this.props.entityconfiguration.schema)
            .then((res) => {
                if (res) {
                    this.props.helpers.reloadEntityTemplate();
                }
            });
    };

    selectedColumnsChanged = (columns: string[]) => {
        this.setState({ selectedColumns: columns });
    };

    render() {
        return (
            <div className={"gridcolumneditor editor-" + ((this.props.isOpen) ? 'on' : 'off')}>
                <div className="editor-panel">
                    <div className="inner-editor">
                        <GridColumnEditorAction
                            className="buttontoolbar dark"
                            i18n={this.props.i18n}
                            actions={this.editorActions}
                        />
                        <div className="inner-container">
                            <div className="entitygrid-settings">
                                <h2 className="configure">
                                    <AppLabel i18nService={this.props.i18n} i18n="entitygrid.configurecolumns.title" />
                                </h2>
                            </div>
                            <ColumnsSelector
                                cmscontext={this.props.cmscontext}
                                entityname={this.props.entityconfiguration.entityName}
                                excludedColumns={this.props.entityconfiguration.excludedFields}
                                i18n={this.props.i18n}
                                i18nHelper={this.props.i18nHelper}
                                datastamp={this.state.columnsDataStamp}
                                template={this.props.template}
                                selectedColumns={this.state.selectedColumns}
                                onChange={this.selectedColumnsChanged}
                                fieldFilter={this.state.fieldFilter}
                                entityConfs={this.props.entityConfs}
                            />
                        </div>
                    </div>
                </div>
                <div onClick={this.save} className="editor-overlay" />
            </div>
        );
    }
}

interface IGridColumnEditorAction {
    key: string;
    enabled: boolean;
    callback: (arg?: any) => void;
    label?: string;
    innerButtonElt?: () => any;
}

interface IGridColumnEditorActionProps {
    actions: IGridColumnEditorAction[];
    i18n: States.i18nState;
    className?: string;
    onCompleted?: (res?) => void;
}

class GridColumnEditorAction extends React.Component<IGridColumnEditorActionProps, any> {
    trigger = (arg, action) => {
        const res = action.callback(arg);
        if (res && res.then) {
            return res.then(() => {
                if (this.props.onCompleted) {
                    this.props.onCompleted();
                }
            });
        }
        if (this.props.onCompleted) {
            this.props.onCompleted();
        }
    };

    render() {
        return <Toolbar
            className={"dark " + this.props.className || ""}
            items={this.props.actions.filter((a) => a.enabled).map((action) => {
                return {
                    id: "action" + action.key,
                    displayMode: "text",
                    label: action.label,
                    itemClicked: (arg) => this.trigger(arg, action),
                    render: action.innerButtonElt ? () => action.innerButtonElt() : null
                } as IToolbarItem;
            })}
        />;
        // <div className={this.props.className || ""}>
        //     {this.props.actions.filter((a) => a.enabled).map((action) => {
        //         return (
        //             <AppAsyncButton id={"action" + action.key} key={action.key} onClick={(arg) => this.trigger(arg, action)}>
        //                 {action.innerButtonElt
        //                     ? action.innerButtonElt()
        //                     : <AppLabel i18nService={this.props.i18n} i18n={action.label} />}
        //             </AppAsyncButton>
        //         );
        //     })}
        // </div>;
    }
}

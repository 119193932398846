import { getCustomerEntityDataSource, getCustomerAPIProxy } from './index';
import type { IDataSourceV3Options } from '../base/datasource';

export function eventDataSource(customerId: string, options?: IDataSourceV3Options) {
    return getCustomerEntityDataSource<AuthTenant.ICustomerEvent>(customerId, "event", "Event", options);
}

export function computeEventUrl(customerId: string, eventId: string): Promise<{ eventUrl: string }> {
    return getCustomerAPIProxy().postJson(`${customerId}/event/${eventId}/computeurl`);
}

export function getComputeEventUrl(customerId: string, eventId: string): Promise<{ eventUrl: string }> {
    return getCustomerAPIProxy().getJson(`${customerId}/event/${eventId}/computeurl`);
}

import { React } from '../../../../../commons';
import { ExpressionDesigner } from '../../../expressiondesignerv3/expressiondesigner';
import type { IFiltersPanelProps } from './definitions';

import "./filters.less";
import './filteradvanced.less';

export class FilterAdvanced extends React.Component<IFiltersPanelProps, any> {
    onChange = (expression) => {
        const expr = expression ? { and: expression } : null;
        console.log('onChange expression ===>', expression, expr);
        this.props.addFilter(expr, "$expression");
    };

    render() {
        return <div className="filter-resume-panel items-panel">
            <ExpressionDesigner
                options={{
                    filterTechnical: true,
                    filterEntityIES: true
                }}
                cmscontext={this.props.cmscontext}
                label="formtemplatedesigner.field.showIf.title"
                groups={this.props.groups}
                onChange={(expr) => this.onChange(expr)}
                expression={this.props.filters && this.props.filters.$expression && this.props.filters.$expression.and}
            />
        </div>;
    }
}

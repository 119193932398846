import { AnimatedSwitch } from '@inwink/animatedswitch';
import { Route } from 'react-router-dom';
import { React, i18NHelperContext } from '../commons';
import { PageTransition } from "./transitions";
import type { IAppShellProps } from './appshell';
import { OpenContentPage } from '../routes/opencontent/opencontent';
import { loadablePageFor } from '../routes/appmodules';
import { NotFoundPage } from '../routes/common/notfound/notfound';

export interface IAppShellRoutesProps extends IAppShellProps {
    visualstate: string[];
}

export class AppShellRoutes extends React.Component<IAppShellRoutesProps, any> {
    RootShell: any;

    MyEventsShell: any;

    EventShell: any;

    CommunityShell: any;

    CustomerShell: any;

    constructor(props) {
        super(props);
        this.MyEventsShell = loadablePageFor(
            () => import(/* webpackChunkName: "shellmyevents" */ '../routes/myevents/myeventsshell')
                .then((mod) => mod.MyEventsShell),
            { noInheritanceAfterFirstLoad: true }
        );

        this.RootShell = loadablePageFor(
            () => import(/* webpackChunkName: "shelladmin" */ '../routes/admin/admin')
                .then((mod) => mod.RootAppShell),
            { noInheritanceAfterFirstLoad: true }
        );

        this.EventShell = loadablePageFor(
            () => import(/* webpackChunkName: "shellevent" */ '../routes/events/eventshell')
                .then((mod) => mod.AppEventShell),
            { noInheritanceAfterFirstLoad: true }
        );

        this.CommunityShell = loadablePageFor(
            () => import(/* webpackChunkName: "shellcommunity" */ '../routes/community/communityshell')
                .then((mod) => mod.AppCommunityShell),
            { noInheritanceAfterFirstLoad: true }
        );

        this.CustomerShell = loadablePageFor(
            () => import(/* webpackChunkName: "shellcustomer" */ '../routes/customers/customershell')
                .then((mod) => mod.AppCustomerShell),
            { noInheritanceAfterFirstLoad: true }
        );
    }

    renderEvent = (navprops) => {
        return <i18NHelperContext.Consumer>
            {(i18nHelper) => <this.EventShell
                {...this.props}
                i18nHelper={i18nHelper}
                navlocation={this.props.location}
                navmatch={this.props.match}
                location={navprops.location}
                match={navprops.match}
            />}
        </i18NHelperContext.Consumer>;
    };

    renderCommunity = (navprops) => {
        return <i18NHelperContext.Consumer>
            {(i18nHelper) => <this.CommunityShell
                {...this.props}
                i18nHelper={i18nHelper}
                navlocation={this.props.location}
                navmatch={this.props.match}
                location={navprops.location}
                match={navprops.match}
            />}
        </i18NHelperContext.Consumer>;
    };

    render() {
        return <i18NHelperContext.Consumer>
            {(i18nHelper) => <AnimatedSwitch
                location={this.props.location}
                match={this.props.match}
                transitionComponent={PageTransition}
            >
                <Route
                    path={this.props.match.path + "events/"}
                    render={(props) => <this.MyEventsShell
                        {...this.props}
                        i18nHelper={i18nHelper}
                        navlocation={this.props.location}
                        navmatch={this.props.match}
                        location={props.location}
                        match={props.match}
                    />}
                />
                <Route
                    path={this.props.match.path + "root/"}
                    render={(props) => <this.RootShell
                        {...this.props}
                        i18nHelper={i18nHelper}
                        navlocation={this.props.location}
                        navmatch={this.props.match}
                        location={props.location}
                        match={props.match}
                    />}
                />
                <Route
                    path={this.props.match.path + "opencontent"}
                    render={(props) => <OpenContentPage
                        {...this.props}
                        i18nHelper={i18nHelper}
                        navlocation={this.props.location}
                        navmatch={this.props.match}
                        location={props.location}
                        match={props.match}
                    />}
                />
                <Route
                    path={this.props.match.path + ":customerid/e/:eventid/"}
                    render={this.renderEvent}
                />
                <Route
                    path={this.props.match.path + ":customerid/c/:communityid/"}
                    render={this.renderCommunity}
                />
                <Route
                    path={this.props.match.path + ":customerid/"}
                    render={(props) => <this.CustomerShell
                        {...this.props}
                        i18nHelper={i18nHelper}
                        navlocation={this.props.location}
                        navmatch={this.props.match}
                        location={props.location}
                        match={props.match}
                    />}
                />
                <Route
                    path=""
                    exact
                    render={(props) => <this.MyEventsShell
                        {...this.props}
                        i18nHelper={i18nHelper}
                        navlocation={this.props.location}
                        navmatch={this.props.match}
                        location={props.location}
                        match={props.match}
                    />}
                />
                <Route
                    path=""
                    render={(props) => <NotFoundPage
                        {...this.props}
                        i18nHelper={i18nHelper}
                        location={props.location}
                        match={props.match}
                    />}
                />
            </AnimatedSwitch>}
        </i18NHelperContext.Consumer>;
    }
}

import { filesDataSourceV3 } from '../../api/files';
import { convertFromOldFormat } from '@inwink/expressions/converter';
import { Entities } from '@inwink/entities/entities';

// import './applinkinput.less';
// import { Entities } from '@inwink/entities/entities';

// interface IFilePickerProps {
//     hidePicker: () => void;
//     parent: HTMLElement;
//     eventid: string;
//     onChange: (file?) => void;
//     readOnly?: boolean;
//     popovermgr: IPopoverManager;
// }

// class FilePicker extends React.Component<IFilePickerProps, any> {
//     popovermgr: IPopoverManager;
//     searchFields: string[];
//     constructor(props) {
//         super(props);

//         this.state = {
//             showPicker: true,
//             pickerParent: null,
//             datasource: filesDataSource(this.props.eventid, {
//                 expands: [],
//                 queryOptions: {
//                     queryOptions: { order: [{ by: 'Title', desc: true }] }
//                 },
//             }),
//         };
//         this.searchFields = ["title"];
//         this.showPicker = this.showPicker.bind(this);
//         this.hidePicker = this.hidePicker.bind(this);
//         this.noItem = this.noItem.bind(this);
//         this.getLoadingItem = this.getLoadingItem.bind(this);
//         this.renderFile = this.renderFile.bind(this);
//         this.selectFile = this.selectFile.bind(this);
//         this.removeFile = this.removeFile.bind(this);
//         this.getFileItem = this.getFileItem.bind(this);
//         this.addNew = this.addNew.bind(this);
//     }

//     showPicker(arg: React.MouseEvent<any>) {
//         arg.stopPropagation();
//         arg.preventDefault();

//         if (this.props.readOnly) { return; }
//         this.setState({ showPicker: true, pickerParent: arg.target });
//     }

//     hidePicker() {
//         this.props.hidePicker();
//         this.setState({ showPicker: false });
//     }

//     preventLosingFocus(event) {
//         event.preventDefault();
//         event.currentTarget.click();

//     }

//     renderFile(file, onclick?) {
//         return <div onMouseDown={(event) => this.preventLosingFocus(event)} className={"remark pseudoinput item filepicker-item" + (onclick ? " clickable" : "")} onClick={onclick}>
//             <span className="file-picker-item">{file.title}</span>
//         </div>;
//     }

//     getFileItem(file, arg: ReactVirtualized.rowRenderedArgs) {
//         return this.renderFile(file, (_arg) => this.selectFile(file));
//     }

//     selectFile(file) {
//         this.props.onChange(file);
//         this.setState({
//             showPicker: false
//         });
//     }

//     removeFile() {
//         this.props.onChange(null);
//     }

//     noItem() {
//         return <div className="message-display">
//             <AppLabel i18n="display.noresult" />
//         </div>;
//     }

//     getLoadingItem(arg: ReactVirtualized.rowRenderedArgs) {
//         return <div className="message-display">
//             <AppLabel i18n="display.loading" />
//         </div>;
//     }

//     addNew() {
//         this.hidePicker();
//         addFile(this.props.popovermgr, "File", this.props.eventid).then((file) => {
//             if (file) {
//                 this.props.onChange(file);
//                 this.selectFile(file);
//             }
//         });
//     }

//     render() {
//         return <EntityPicker
//             datasource={this.state.datasource}
//             getItem={this.getFileItem}
//             getLoadingItem={this.getLoadingItem}
//             noItem={this.noItem}
//             onhide={this.hidePicker}
//             rowHeight={60}
//             i18nLabel="actions.create"
//             show={this.state.showPicker}
//             parent={this.props.parent}
//             onAddNew={this.addNew}
//             noSearchBarFocus={true}
//         />;
//     }
// }

// export function AppFilePickerModal(popovermgr: IPopoverManager, parent: HTMLElement, props) {
//     return popovermgr.popoverPortal(parent, FilePicker, props, "file-picker-modal");
// }

export function getFileName(eventid, fileUrl): Promise<any> {
    let expr;
    const ds = filesDataSourceV3(eventid);
    // const exprtmp = [{
    //     name: 'file',
    //     op: 'eq',
    //     val: fileUrl
    // }] as Entities.Expression[];

    // expr = convertFromOldFormat(exprtmp);
    return ds
        .query({
            filters: {
                $meta: {
                    path: "url",
                    $token: {
                        file: {
                            $equal: fileUrl
                        }
                    }
                }
            },
            selects: {
                $all: true,
                file: true
            },
        }, ["file"])
        .then((file) => {
            if (file && file.data && file.data.length) {
                return Promise.resolve(file.data[0].title);
            }
            return Promise.reject("File not found");
        }, (err) => {
            return Promise.reject(err);
        });
}

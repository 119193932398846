import { React, AppLabel } from '../../../../commons';
import "./filter.less";

interface IFilterProps {
    i18n: any;
}

export function noFilter(props: IFilterProps) {
    return <div className="filter-no filter-type">
        <header>
            <span><AppLabel i18nService={props.i18n} i18n="filter.nofilter" /></span>
        </header>
    </div>;
};

import * as assign from 'lodash/assign';
import { Entities } from '@inwink/entities/entities';
import { getMappings } from '../api/backoffice/bohelp';
import { requestBrowserStorage } from './browserstorage';

// eslint-disable-next-line import/no-mutable-exports
export let helpMappingData;

export function openHelpMapping(version) {
    return new Promise((resolve, reject) => {
        requestBrowserStorage("helpmapping").then((browserstorage) => {
            helpMappingData = browserstorage;
            helpMappingData.openData("helpmappings", version);
            resolve();
        }).catch((err) => {
            reject(err);
        });
    });
    // helpMappingData = new BrowserStorage("helpmapping");
    // helpMappingData.openData("helpmappings", version);
}

export function clearHelpData(languageCode: any) {
    helpMappingData.set(languageCode, null);
    helpMappingData.set("lastupdated-" + languageCode, null);
    helpMappingData.set(languageCode, null);
}

export function syncMappings(languageCode: any, _srcmappings: any, force = false) {
    return helpMappingData.get("lastupdated-" + languageCode).then((date: string) => {
        let lastupdated: string;
        let lastUpdatedDate: Date;
        if (!force && date) {
            lastupdated = date;
            lastUpdatedDate = new Date(date);
        }

        return getMappings(languageCode, lastupdated).then((mappings: Entities.IHelpMapping[]) => {
            const srcmappings = assign({}, _srcmappings);
            if (mappings && mappings.length) {
                lastUpdatedDate = lastUpdatedDate || new Date(2000, 1, 1);
                mappings.forEach((t) => {
                    srcmappings[t.featureCode] = t.tinyUrl;
                    const d = new Date(t.lastUpdate);
                    if (d > lastUpdatedDate) {
                        lastupdated = t.lastUpdate;
                        lastUpdatedDate = d;
                    }
                });
                helpMappingData.set(languageCode, JSON.stringify(srcmappings));
                helpMappingData.set("lastupdated-" + languageCode, lastupdated);
            }
            return srcmappings;
        }).then(null, (err) => {
            console.error(err);
            return {};
        });
    });
}

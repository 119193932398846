import { React, Loader, AppTextLabel } from '../../commons';
import { withI18nHelper } from '@inwink/i18n';
import './pageloader.less';
import { Entities } from '@inwink/entities/entities';

interface IPageLoaderProps {
    i18nHelper?: Entities.i18nHelper;
    display?: "ring" | "bubbles";
    data?: { message: string };
}

@withI18nHelper()
export class PageLoader extends React.Component<IPageLoaderProps, any> {
    render() {

        if (this.props.display === "ring") {
            let msg = this.props.i18nHelper.translate((this.props.data && this.props.data.message) || "page.loading");
            return <div className="pageloader">
                <Loader display={this.props.display || "bubbles" } message={ msg } />
            </div>;
        }
        return <div className="pageloader">
            <Loader display={this.props.display || "bubbles" } />
            <AppTextLabel className="pageloadermessage" i18n={(this.props.data && this.props.data.message) || "page.loading"} />
        </div>;
    }
}

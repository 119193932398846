import type { IPopoverManager } from '@inwink/modals';
import type { ICMSContext } from '../../cmscontext';

export function openEntitySchema(
    popovermgr: IPopoverManager, entityname, cmscontext: ICMSContext, schema = null, applyForValues = null, datasource = null,
    allowSetMandatory = false, excludedFields?: string[], entityConfigurationSchema?
) {
    return import(/* webpackChunkName: "entityschemadesigner" */ "./entityschema").then((mod) => {
        return mod.$openEntitySchema(popovermgr, entityname, cmscontext, schema, applyForValues, datasource,
            allowSetMandatory, excludedFields, entityConfigurationSchema);
    });
}

import { React, AppTextLabel, AppButton, connectwith } from '../../../../commons';
import type { ICMSContext } from "../../../cmscontext";
import { IEntityGroupDefinition, FieldFilterCallback } from '../../definitions';
import type { IExpressionDesignerOptions } from '../definitions';
import type { States } from '../../../../services/services';
import { AddExpressionValues } from './addexpression.values';
import type { IFieldPickerItem } from '../../fieldpickerv3/definitions';
import { FieldPicker } from '../../fieldpickerv3/index';
import type { IEntityPageConfiguration } from "../../entitypagev3/entitypagebase.props";

import './addexpression.less';

interface IAddExpressionProps {
    cmscontext: ICMSContext;
    onAdd: (expression: any, entityname?: string) => void;
    isFirst?: boolean;
    separator?: string;
    options?: IExpressionDesignerOptions;
    displayConditionLabel: boolean;
    groups: Record<string, IEntityGroupDefinition>;
    warningFilters?: boolean;
    handleFilters?: (expression: any) => void;
    fieldFilter?: FieldFilterCallback;
    entityconfig: IEntityPageConfiguration;
    focusFieldPicker?: boolean;
    disabledSortOnGroup?: boolean;
}

interface IAddExpressionState {
    fieldToAdd: IFieldPickerItem;
    operator: string;
    propvalue: any;
    allowSave: boolean;
    expression: any;
}

@connectwith((state: States.IAppState) => ({ event: state.event }))
export class AddExpression extends React.Component<IAddExpressionProps, IAddExpressionState> {
    fieldpicker = React.createRef<FieldPicker>();

    constructor(props) {
        super(props);
        this.state = {
            fieldToAdd: null,
            operator: "",
            propvalue: null,
            allowSave: false,
            expression: null
        };
    }

    componentDidMount() {
        if (this.props.focusFieldPicker) {
            setTimeout(() => {
                this.fieldpicker.current.focus();
            }, 500);
        }
    }

    save = () => {
        const entityname = this.state.fieldToAdd?.entityname;
        if (this.state.allowSave && this.state.fieldToAdd) {
            if (this.state.expression) {
                // Fix un peu naze, pour gérer a la fois #47222 et #43111
                const patch = this.state.fieldToAdd?.field.type === 'Entity' ? {} : {
                    name: this.state.fieldToAdd.key
                };
                const nv = Object.assign({}, this.state.expression, patch);
                this.props.onAdd(nv, entityname);
            } else {
                this.props.onAdd({
                    name: this.state.fieldToAdd.key,
                    op: this.state.operator,
                    val: (this.state.fieldToAdd.field.type.toLowerCase() === "multiselectlist")
                        ? this.state.propvalue.map((p) => p.key) : this.state.propvalue,
                }, entityname);
            }

            this.setState({
                fieldToAdd: null,
                allowSave: false,
                operator: "",
                propvalue: null,
                expression: null,
            }, () => {
                if (this.props.warningFilters) {
                    // ? ca sert à quoi ? fromPAM: ah ça, y'a des trucs par ici on se demande à quoi ça sert !
                    // this.props.handleFilters(assign({}, this.state.filtersEntered, {
                    //     handlingFilters : this.state.allowSave
                    // }));
                }
            });
        }
    };

    updateFieldToAdd = (fieldName: string, field: IFieldPickerItem) => {
        this.setState({ fieldToAdd: field });
    };

    update = (arg, cb = () => null) => {
        this.setState(arg, () => {
            if (!this.state.fieldToAdd) {
                this.setState({ allowSave: false });
            }
            cb();
        });
    };

    setExpression = (expression) => {
        this.setState({expression, allowSave: true});
    };

    setAllowSave = (allowSave, cb = () => null) => {
        this.setState({ allowSave: allowSave }, () => { cb(); });
    };

    render() {
        const field = this.state.fieldToAdd;
        const propname = <FieldPicker
            ref={this.fieldpicker}
            cmscontext={this.props.cmscontext}
            groups={this.props.groups}
            selectedField={field && field.key}
            onChange={this.updateFieldToAdd}
            fieldFilter={this.props.fieldFilter}
            entityConfs={null}
            disabledSortOnGroup={this.props.disabledSortOnGroup}
        />;

        const propvalue = <AddExpressionValues
            key={field && field.key}
            allowSave={this.state.allowSave}
            save={this.save}
            setAllowSave={this.setAllowSave}
            update={this.update}
            field={this.state.fieldToAdd}
            cmscontext={this.props.cmscontext}
            setExpression={this.setExpression}
            entityconfig={this.props.entityconfig}
            expressions={this.state.expression} // #36884 - why not pass expression there ??? Sorry if there was a good reason
            options={this.props.options}
        />;

        return <div className="addexpression">
            <AppTextLabel
                style={{ display: (!this.props.displayConditionLabel || this.props.isFirst) ? 'none' : 'inline-block' }}
                component="div"
                className="prop-separator"
                i18n={"expressiondesigner." + this.props.separator}
            />
            {propname}
            {propvalue}
            <AppButton disabled={!this.state.allowSave} className="important" onClick={this.save}>
                <AppTextLabel i18n="actions.addFilter" component="span" />
            </AppButton>
        </div>;
    }
}

import { ContainerQuery } from '@inwink/responsive/containerquery';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Konami } from '@inwink/konami';
import { React, bindActionCreators, connectwith, logging } from '../commons';
import { actions as userActions } from '../services/userservice';
import { rightsActions } from '../services/rightsservice';
import { eventActions } from '../services/eventservice';
import { customerActions } from '../services/customerservice';
import { I18NStateContext } from '../services/i18nservice';
import { AppMenu } from './appmenu';
import { AppShellHeader } from './header/appshellheader';
import { UserProfilePopin } from './header/appshelluser.popin';
import { Authentication } from './appshell.authentication';
import { TradsHandler } from './appshell.trads';
import type { States } from '../services/services';
//import { OnboardingContextControl } from './onboarding/context';
import { AppShellRoutes } from './appshell.routes';
import { AppVisualStateContext, AppShellMenuContext, IAppShellMenuState } from './appshellcontext';
import type { ILocationMatchProp, ILocationProp } from '../data/entities';
import { BoThemedModal } from "./ui/modals/bothemedmodal";
import { appthemeActions } from '../services/appthemeservice';
import { initEntities } from './entities/init';
import '../styles/transitions.less';
import '@inwink/entityform/entityform.less';
import '../styles/reset.less';
import '../styles/basestyles.less';
import './appshell.less';

const logger = logging.getLogger("UI");

initEntities();

const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

(global as any).moment = moment;
(global as any).momenttimezone = momenttimezone;

const mediaquery = {
    XXS: {
        minWidth: 0
    },
    XS: {
        minWidth: 468
    },
    S: {
        minWidth: 768,
    },
    M: {
        minWidth: 1024,
    },
    L: {
        minWidth: 1280,
    },
    XL: {
        minWidth: 1690
    }
};

const limitForFixedMenu = "L";
const limitForTooltipMenu = "M";

export interface IAppShellProps {
    event: States.IEventState;
    user: States.IAppUserState;
    i18n: States.i18nState;
    customer: States.ICustomerState;
    community: States.ICommunityState;
    rights: States.IAppUserRights;
    children?: any;
    location: ILocationProp;
    match: ILocationMatchProp;
    routeParams: any;
    eventActions?: typeof eventActions;
    customerActions?: typeof customerActions;
    userActions?: typeof userActions;
    rightsActions?: typeof rightsActions;
    appthemeActions?: typeof appthemeActions;
}

interface IAppShellState {
    visualstate: string[];
    lastvisualstate: string[];
    menu: IAppShellMenuState;
    shellMounted: boolean;
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        customer: state.customer,
        community: state.community,
        user: state.user,
        i18n: state.i18n,
        rights: state.rights,
        appcontext: state.apptheme
    };
}

function mapDispatchToProps(dispatch) {
    return {
        eventActions: bindActionCreators(eventActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        rightsActions: bindActionCreators(rightsActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        appthemeActions: bindActionCreators(appthemeActions, dispatch)
    };
}

@connectwith(mapStateToProps, mapDispatchToProps)
export class AppShell extends React.Component<IAppShellProps, IAppShellState> {
    constructor(props: IAppShellProps) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.konamiCodeActivated = this.konamiCodeActivated.bind(this);
        this.props.appthemeActions.setCurrentTheme("default");

        this.state = {
            // eslint-disable-next-line react/no-unused-state
            lastvisualstate: [],
            visualstate: [],
            menu: {
                allowSubmenuTooltip: false,
                allowFixed: false,
                collapsed: false,
                collapsedWhenLarge: false
            },
            shellMounted: false
        };
    }

    renderLogin() {
        return <I18NStateContext i18n={this.props.i18n}><Authentication {...this.props} /></I18NStateContext>;
    }

    // componentDidUpdate(prevProps, prevState) {
    //     this.ensureEvent();
    // }

    visualStateChanged(visualstate: string[]) {
        const state = { visualstate: visualstate } as any;
        const isLarge = (visualstate.indexOf(limitForFixedMenu) >= 0);
        const allowmenutooltip = (visualstate.indexOf(limitForTooltipMenu) >= 0);
        const menupatch: any = {};
        if (allowmenutooltip) {
            menupatch.allowSubmenuTooltip = true;
        } else {
            menupatch.allowSubmenuTooltip = false;
        }

        if (isLarge) {
            menupatch.allowFixed = true;
            menupatch.collapsed = this.state.menu.collapsedWhenLarge;
        } else if (allowmenutooltip) {
            menupatch.allowFixed = true;
            menupatch.collapsed = true;
        } else {
            menupatch.allowFixed = false;
            menupatch.collapsed = true;
        }

        if (!menupatch.allowFixed) {
            menupatch.allowSubmenuTooltip = true;
        }
        state.menu = {...this.state.menu, ...menupatch};
        state.expanded = null;

        this.setState(state as any);
    }

    toggleMenu() {
        const state = {} as any;
        state.collapsed = !this.state.menu.collapsed;

        const isLarge = (this.state.visualstate.indexOf(limitForFixedMenu) >= 0);
        if (isLarge) {
            state.collapsedWhenLarge = state.collapsed;
        }

        this.setState((prevState) => {
            return {
                menu: {...prevState.menu, ...state}
            } as any;
        });
    }

    currentEnsureEvent: any;

    componentDidMount() {
        // We should change class animation when the AppPage is loaded
        setTimeout(() => {
            this.setState({ shellMounted: true });
        }, 700);
    }

    konamiCodeActivated() {
        this.props.userActions.activateAdvancedMode();
    }

    render() {
        if (!this.props.user.currentUser) {
            return this.renderLogin();
        }

        logger.debug("render app shell");

        return (
            <DndProvider backend={HTML5Backend}>
                <ContainerQuery
                    forceEvaluateOnMount={true}
                    className={(this.state.shellMounted ? '' : 'appload') + " app-layout"
                    + (this.state.menu.collapsed ? " menu-collapsed" : " menu-expanded")
                    + (this.state.menu.allowFixed ? " menu-collapsible" : "")}
                    stateName="applayout"
                    query={mediaquery}
                    stateChanged={(state) => this.visualStateChanged(state)}
                >
                    <BoThemedModal>
                        <AppVisualStateContext.Provider value={this.state.visualstate}>
                            <AppShellMenuContext.Provider value={this.state.menu}>
                                {/* <OnboardingContextControl> */}
                                <Konami konami={konamiCode} easterEgg={this.konamiCodeActivated} />
                                <UserProfilePopin {...this.props}>
                                    <div className="app-content">
                                        <AppShellHeader
                                            {...this.props}
                                            toggleMenu={this.toggleMenu}
                                            visualstate={this.state.visualstate}
                                        />
                                        <AppShellRoutes {...this.props} visualstate={this.state.visualstate} />
                                    </div>
                                    <AppMenu
                                        {...this.props}
                                        allowFixed={this.state.menu.allowFixed}
                                        collapsed={this.state.menu.collapsed}
                                        allowSubmenuTooltip={this.state.menu.allowSubmenuTooltip && this.state.menu.collapsed}
                                        menu={this.state.menu}
                                        toggleMenu={this.toggleMenu}
                                        isMobile={!(this.state.visualstate.indexOf("M") >= 0)}
                                    />
                                </UserProfilePopin>
                                <TradsHandler i18n={this.props.i18n} user={this.props.user} rights={this.props.rights} />
                                {/* </OnboardingContextControl> */}
                            </AppShellMenuContext.Provider>
                        </AppVisualStateContext.Provider>
                    </BoThemedModal>
                </ContainerQuery>
            </DndProvider>
        );
    }
}

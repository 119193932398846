/* eslint-disable max-classes-per-file */
import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { React, AppTextLabel } from '../../commons';
import { BoolFieldEditor } from './booleditor';
import './datebooleditor.less';

interface IDateBoolEditorProps extends IDynamicFieldEditorProps {
    currentLanguage: string;
    entityFieldValue: any;
    applyFor?: string[];
    onChange: (fieldKey: string, fieldValue: any, isFile?: boolean) => void;
    forceReadOnly?: boolean;
    noLabel?: boolean;
    relatedDateFieldKey?: string;
}

function getDisplayDate(relatedDateFieldKey, entityFieldFormTemplate, entityValue, i18nhelper) {
    const key = entityFieldFormTemplate && entityFieldFormTemplate.key ? entityFieldFormTemplate.key : null;

    if (!key || !relatedDateFieldKey) {
        return;
    }

    const date = entityValue && entityValue[entityFieldFormTemplate.key] !== null ? entityValue[relatedDateFieldKey] : null;
    let displayDate = null;
    if (date) {
        const m = i18nhelper.getMoment(date).format("LLL");
        displayDate = <div className="date">({m})</div>;
    }
    return displayDate;
}

export function DateBoolEditor(props: IDateBoolEditorProps) {
    const displayDate = getDisplayDate(props.relatedDateFieldKey, props.entityFieldFormTemplate,
        props.entityValue, props.i18nHelper);

    return <div className="datebooleditor">
        <div className="flex-container">
            <BoolFieldEditor {...props} />
            {displayDate}
        </div>
    </div>;
}

export class DateNullableBoolEditor extends React.Component<IDateBoolEditorProps, any> {
    constructor(props: IDateBoolEditorProps) {
        super(props);
        this.onValuechangeEventHandler = this.onValuechangeEventHandler.bind(this);
        this.setNullable = this.setNullable.bind(this);
        const lbl = this.props.entityFieldFormTemplate?.key ? this.props.entityFieldFormTemplate.key.toLowerCase() : "";
        const entityName = this.props.entityName.toLowerCase();
        this.state = {
            entityName,
            fieldKey: lbl,
            values: [
                {
                    value: [true],
                    label: `${entityName}.${lbl}.true`
                },
                {
                    value: [false],
                    label: `${entityName}.${lbl}.false`
                },
                {
                    value: [null, undefined],
                    label: `${entityName}.${lbl}.null`
                }
            ]
        };
    }

    setNullable() {
        if (this.props.entityValue && typeof this.props.entityValue[this.props.entityFieldFormTemplate.key] === "undefined") {
            this.onValuechangeEventHandler(null);
        }
    }

    onValuechangeEventHandler(value) {
        if (this.props.forceReadOnly) {
            return;
        }
        this.props.onChange(this.props.entityFieldFormTemplate.key, value);
    }

    render() {
        const displayDate = getDisplayDate(this.props.relatedDateFieldKey, this.props.entityFieldFormTemplate,
            this.props.entityValue, this.props.i18nHelper);

        return <div className="datebooleditor nullable">
            {this.props.noLabel
                ? null : <AppTextLabel component="label" i18n={this.state.entityName + '.' + this.state.fieldKey} />}
            <div className="flex-container">
                {this.state.values.map((val, idx) => {
                    return <label className={"container" + (this.props.forceReadOnly ? " readonly" : "")} key={idx}>
                        <AppTextLabel component="span" className="label" i18n={val.label} />
                        <input
                            type="checkbox"
                            readOnly={this.props.forceReadOnly}
                            checked={val.value.some((v) => v === this.props.entityFieldValue)}
                            onChange={() => this.onValuechangeEventHandler(val.value[0])}
                        />
                        <span className="checkmark" />
                    </label>;
                })}
                {displayDate}
            </div>
        </div>;
    }
}

import { AppTextLabel, React } from '@@commons';
import { AppLinkInput, IAppLinkInputProps } from './index';

export interface IAppLinkInputModalProps extends IAppLinkInputProps {
    onCompleted?: (res?: any) => any;
    hidePicker?: () => void;
    deleteLink?: () => void;
}

export class AppLinkInputModal extends React.Component<IAppLinkInputModalProps, any> {
    constructor(props: IAppLinkInputModalProps) {
        super(props);
        this.state = {
            link: {}
        };
    }

    hidePicker = (val?: any) => {
        this.props.onCompleted(val);
    };

    deleteLink = () => {
        this.props.deleteLink();
        this.props.onCompleted();
    };

    render() {
        return <div className="link-picker-content">
            <section>
                <AppLinkInput
                    onChange={(link) => this.setState({ link })}
                    value={this.state.value}
                    noAnchor
                    allowEmail
                    {...this.props}
                />
            </section>
            <div className="actions">
                <button
                    type="button"
                    onClick={() => this.deleteLink()}
                >
                    <AppTextLabel i18n="actions.remove" /></button>
                <button
                    type="button"
                    className="important"
                    onClick={() => this.hidePicker(this.state.link)}
                >
                    <AppTextLabel i18n="actions.validate" />
                </button>
            </div>
        </div>;
    }
}

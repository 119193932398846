import { React, AppTextLabel } from '../../../commons';
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';

interface ILargeTextInputBaseProps {
    i18n: string;
    canEditTrad: boolean;
    id?: string;
    multiline?: boolean;
    style?: any;
    name?: string;
    readonly?: boolean;
}

export interface ILargeTextInputProps extends ILargeTextInputBaseProps {
    value: string;
    onChange: (val: string) => void;
}

export class LargeTextInput extends React.PureComponent<ILargeTextInputProps, any> {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(arg: React.FormEvent<HTMLTextAreaElement>) {
        this.props.onChange(arg.currentTarget.value);
    }

    render() {
        return <div className="fieldbloc">
            <AppTextLabel component="label" i18n={this.props.i18n} />
            <AppTextLabel component="div" className="field-desc" defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""} i18n={this.props.i18n + ".desc"} />
            <textarea value={this.props.value} onChange={this.onChange}></textarea>
        </div>;
    }
}


export interface ILargeTextInputPropertyProps extends ILargeTextInputBaseProps {
    data: any;
    path: string;
    onChange: (data: any) => void;
}

export class LargeTextInputProperty extends React.PureComponent<ILargeTextInputPropertyProps, any> {
    onChange = (txt) => {
        let data = immutable.set(this.props.data, this.props.path, txt);
        this.props.onChange(data);
    }

    render(){
        let val = getPropertyValue(this.props.data, this.props.path);
        return <LargeTextInput {...this.props}
            value={val}
            onChange={this.onChange} />
    }
}
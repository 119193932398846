import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import * as moment from 'moment';
import { ILogAppender, ILogger, logging, LogLevels } from '@inwink/logging';
import * as API from '@inwink/apibase';
import { getCookie } from '@inwink/utils/cookies';
import { BlankLoadingPanel } from '@inwink/loadable';
// import { setPopoverGlobalErrorHandler } from '@inwink/modals/popover';

import { logformatter } from '@inwink/logging/logging/basetypes';
import { initRealtimeService } from './services/realtimeservice';

import configureStore from './store';
import { actions as i18nActions, getBestMatchingLanguage } from './services/i18nservice';
import { actions as userActions } from './services/userservice';
import { tracker, registerTrackers } from './services/trackingservice';
import type { States } from './services/services';

import { registerI18nHelper } from './commons';
import { AppShell } from './components/appshell';
import { AppShellLanguage, AppShellLanguageHook } from './components/appshell.language';
import { loadablePageFor } from './routes/appmodules';
import { trackError } from './api/backoffice/tracking';
import * as itemtemplatetools from './components/itemtemplate';

itemtemplatetools.registerAll();

// if (__DEBUG__){
//     import(/* webpackChunkName: "modsnowfall" */ './components/ui/fun/snowfall')
//             .then((mod) => mod.setSnow())
// }

class ApiLogAppender implements ILogAppender {
    formatters: logformatter[];

    level: LogLevels;

    constructor(private store) {
    }

    clone() {
        throw new Error('Method not implemented.');
    }

    log(logger: ILogger, message: string, level: LogLevels, data: any) {
        // erreur 400 de l'API on log
        if (level === LogLevels.warn) {
            const state = this.store && this.store.getState() as States.IAppState;
            trackError({
                eventid: state && state.event.eventid,
                customerid: state && state.customer.customerid,
                level: 3
            }, {
                message: message,
                stack: data,
            });
        }
    }
}

export function appStartup(config: _InwinkConfig, usedHistory, initialState) {
    registerTrackers();
    const store = configureStore(usedHistory, initialState) as any;
    if (__DEV__) {
        (inwink as any).REDUX_STORE = store;
    }
    const history = usedHistory; // syncHistoryWithStore(usedHistory, store);
    initRealtimeService(config.realtime, store);
    inwink.history = usedHistory;
    // Si l'utilisateur courant se prend des 401, on force le délog
    API.setOnunauthenticated(() => {
        userActions.logout()(store.dispatch, store.getState).then(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }, () => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        });
    });

    registerTrackers();

    logging.debug("Init i18n");
    const cookieLang = getCookie("currentlang");
    let languages = (window as any).navigator.languages || (window as any).navigator.userLanguage || window.navigator.language;
    if (typeof languages === "string") {
        languages = [languages];
    }
    const lng = cookieLang || getBestMatchingLanguage(cookieLang, languages, "en", ["fr", "en"]).language;

    const apiLogging = logging.getLogger("API");
    apiLogging.addAppender(new ApiLogAppender(store));
    API.initConfig({
        authority: config.authority,
        authUrl: config.authUrl,
        clientConfig: config.clientConfig,
        lang: lng
    } as any);

    // var routes;
    // routes = createRoutes(store);

    document.body.classList.add("defaulttheme");
    document.body.classList.add("env-" + inwink.config.env.toLowerCase());

    const approot = document.getElementById("approot");
    logging.debug("BOOTSTRAP # rendering components");

    // Gestion des user agents
    const ua = navigator && navigator.userAgent ? navigator.userAgent.toLowerCase() : "";
    if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') === -1) {
            approot.className += "safari";
        }
    }

    const appsplash = document.getElementById("appsplash") as HTMLElement;
    const appsplashMessage = document.querySelector("#appsplash .loadermessage") as HTMLElement;
    let showSplash;
    let hideSplash;

    if (appsplash) {
        appsplash.style.transition = "opacity 1500ms ease-out";
        appsplash.style.opacity = "1";
        hideSplash = () => {
            return new Promise(() => {
                const appsplashElt = document.getElementById("appsplash") as HTMLElement;
                appsplashElt.style.opacity = "0";
                appsplashElt.style.pointerEvents = "none";
                setTimeout(() => {
                    appsplashElt.style.display = "none";
                    // const snow = document.querySelector("#appsplash #snow") as any;
                    // if (snow) {
                    //     if (snow.inwinkSnow) {
                    //         snow.inwinkSnow.stop();
                    //     }
                    // }
                }, 1550);
            });
        };
        // (window as any).hideSplash = hideSplash;
        showSplash = () => {
            return new Promise(() => {
                const appsplashElt = document.getElementById("appsplash") as HTMLElement;
                appsplashElt.style.display = "";
                appsplashElt.style.pointerEvents = "";
                setTimeout(() => {
                    appsplashElt.style.opacity = "1";
                }, 0);
            });
        };
        // (window as any).showSplash = showSplash;
    }

    const loadingStateChanged = (isLoading) => {
        if (!isLoading) {
            if (appsplash.style.opacity === "1") {
                hideSplash();
            }
        } else if (appsplash.style.opacity === "0") {
            showSplash();
        }
    };

    /*
    setPopoverGlobalErrorHandler((error) => {
        const state = store && store.getState() as States.IAppState;
        trackError({
            eventid: state && state.event.eventid,
            customerid: state && state.customer.customerid
        }, error);
    });
    */

    window.addEventListener("error", (err) => {
        if (err.error) {
            const state = store && store.getState() as States.IAppState;
            trackError({
                eventid: state && state.event.eventid,
                customerid: state && state.customer.customerid
            }, {
                message: err.error.message,
                stack: err.error.stack,
                filename: err.filename,
                lineno: err.lineno,
                colno: err.colno
            });
            // eslint-disable-next-line no-debugger
            debugger;
        }
    });

    let trads = null;
    const loadingProgress = (progress) => {
        if (appsplashMessage) {
            let msg = "";
            if (progress && progress.message) {
                msg = trads.translate(progress.message, null, progress.message);
            }
            appsplashMessage.innerText = msg;
        }
    };

    const previewShell = loadablePageFor(
        () => import(/* webpackChunkName: "modpreview" */ './routes/module.preview')
            .then((mod) => mod.AppPreviewShell)
    );

    i18nActions.init(lng)(store.dispatch).then(() => {
        ReactDOM.render(<Provider store={store}>
            <AppShellLanguage>
                <AppShellLanguageHook hook={(apptrads) => {
                    trads = apptrads;
                    registerI18nHelper(apptrads);
                }}
                >
                    <BlankLoadingPanel
                        name="appcontext"
                        noChildInheritanceAfterFirstLoad
                        stateChanged={loadingStateChanged}
                        onProgress={loadingProgress}
                        stateDelay={300}
                    >
                        <Router history={history}>
                            <Switch>
                                <Route path="/preview/w/:customerid/:tenantid/:websiteid/:page?" component={previewShell} />
                                <Route path="/preview/c/:tinyurlcom/:page?" component={previewShell} />
                                <Route path="/preview/:tinyurl/:page?" component={previewShell} />
                                {/* this PropType Error is fixed in > react-router-dom version but they have breaking changes */}
                                <Route path="/" component={AppShell} />
                            </Switch>
                        </Router>
                    </BlankLoadingPanel>
                </AppShellLanguageHook>
            </AppShellLanguage>
        </Provider>, approot);

        let previouslocation;

        history.listen((location) => {
            if (!previouslocation || location.pathname !== previouslocation.pathname) {
                tracker.trackPage(location.pathname);
            }
            previouslocation = location;
        });

        logging.debug("remove splash");
    });

    inwink.showBetaFeatures = (showBetaFeatures: boolean) => {
        localStorage.setItem("showBetaFeatures", showBetaFeatures as any);
        inwink.clearEntityView();
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    inwink.clearEntityView = (reload = false) => {
        localStorage.removeItem("inwink_entityview");
        if (reload) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    };

    inwink.properties = inwink.properties || ({} as any);
    inwink.properties.isInWebAppiOS = ((window.navigator as any).standalone === true);
    inwink.properties.isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);

    return {
        store: store,
        history: history
    };
}

(moment as any).defineLocale('fr', {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Aujourd\'hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
    },
    ordinalParse: /\d{1,2}(er|)/,
    ordinal: (number) => {
        return number + (number === 1 ? 'er' : '');
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
});

import * as moment from 'moment';
import { ICMSContentTemplatesDatasource } from './base/cmsdatasource';
import { expressionBuilder, IExpressionBuilderPredicates } from '@inwink/expressions/expressionbuilder';

export function getTemplate(datasource: ICMSContentTemplatesDatasource, application, state, type, tinyurl): Promise<any> {
    const filters: IExpressionBuilderPredicates = [];
    if (application) {
        filters.push((e) => e.WithString("application").Equals(application));
    }
    if (state) {
        filters.push((e) => e.WithString("state").Equals(state));
    }
    if (type) {
        filters.push((e) => e.WithString("contentType").Equals(type));
    }
    if (tinyurl) {
        filters.push((e) => e.WithString("tinyUrl").Equals(tinyurl));
    }
    const options = {
        filters: expressionBuilder((e) => e.And(filters))
    };
    return new Promise((resolve, reject) => {
        datasource.query(options)
            .then((res) => {
                if (res && res.data && res.data.length) {
                    resolve(res.data[0]);
                } else {
                    resolve(null);
                }
            }).catch((err) => reject(err));
    });
}

export function setDisabled(datasource: ICMSContentTemplatesDatasource, id: string, disabled: boolean) {
    const entity = {
        id,
        disabled
    };
    return datasource.update(entity);
}

export function listTemplates(datasource: ICMSContentTemplatesDatasource, application, state, lastSync?: Date) {
    const filters: IExpressionBuilderPredicates = [];
    if (lastSync && moment(lastSync).isValid()) {
        filters.push((e) => e.WithDate("validfrom").GreaterThanOrEqual(moment(lastSync)));
    }
    filters.push((e) => e.WithString("application").Equals(application));
    filters.push((e) => e.WithString("state").Equals(state));

    return datasource.query({
        filters: expressionBuilder((e) => e.And(filters)),
        selects: {
            $all: true
        }
    }).then((result) => result.data);
}

export function deleteTemplateById(datasource: ICMSContentTemplatesDatasource, templateid) {
    return datasource.delete([templateid]);
}

export function deleteTemplateByIds(datasource: ICMSContentTemplatesDatasource, templateIds: string[]) {
    return datasource.delete([...templateIds]);
}

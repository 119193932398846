/* eslint-disable max-classes-per-file */
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { CMSEditable } from '@inwink/cmstoolbar';
import type { States } from 'services/services';
import { connectwith } from '@inwink/react-utils/decorators';
import type { Entities } from '@inwink/entities/entities';
import type { ICMSContext } from 'components/cmscontext';
import { React, WithPopoverManager, IPopoverManager, CMSContextProvider, i18NHelperContext } from '../../commons';
import type { IVisualConfigurationStateManager } from '../../cms/editors/visualconfig';
import { editJsonModal } from '../jsoneditor';
import type { IAppLinkInputProps } from './applinkinput';
import { getFileName } from './toolbarfilepicker';
import { pickEntities, IEntityPickerModaleProps } from "../entities/entitypicker/entitypickermodale";
import { AppLinkInputModal } from './applinkinput/modal';
import { getTemplate } from '../../api/contenttemplate';

import './richtextinput.less';

export interface IRichTextInputProps {
    datastamp: any;
    className?: string;
    canEditTrad: boolean;
    multiline?: boolean;
    text: string;
    onChange: (text: string) => void;
    visualConfigurationState?: IVisualConfigurationStateManager;
    showMinRow?: number;
}
export class RichTextInput extends React.PureComponent<IRichTextInputProps, any> {
    render() {
        return <WithPopoverManager>
            {(popovermgr) => <CMSContextProvider.Consumer>
                { (cmscontext) => <RichTextInputContent {...this.props} popovermgr={popovermgr} cmscontext={cmscontext} /> }
            </CMSContextProvider.Consumer>}
        </WithPopoverManager>;
    }
}

interface IRichTextInputContentProps extends IRichTextInputProps {
    popovermgr: IPopoverManager;
    event?: States.IEventState;
    cmscontext?: ICMSContext;
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event
    };
}

@connectwith(mapStateToProps, null)
class RichTextInputContent extends React.PureComponent<IRichTextInputContentProps, any> {
    FileMgr: any;

    LinkMgr: any;

    constructor(props) {
        super(props);
        this.state = {
            colorPalette: []
            // ds: filesDataSource(this.props.event.eventid)
        };
        this.FileMgr = {
            addFile: function (parent, _props) {
                _props.popovermgr = this.props.popovermgr;
                _props.eventid = this.props.event.eventid;
                // AppFilePickerModal(this.props.popovermgr, parent, _props);
                pickEntities({
                    entityName: "File",
                    multiselect: false,
                    popovermgr: this.props.popovermgr,
                    entityArgs: {
                        ...this.props
                    }
                } as IEntityPickerModaleProps, false, parent).then((files: Entities.IFile[]) => {
                    return this.props.cmscontext.getEntityConfiguration('File', (this.props as any)).then((config) => {
                        return config.datasource.get(files?.length && files[0]?.id, {
                            selects: {
                                $all: true,
                            }
                        }).then((file) => {
                            _props.afterHide();
                            _props.onChange(file);
                        });
                    });
                });
            },
            getFileName: function (fileUrl) {
                return new Promise<any>((resolve, reject) => {
                    getFileName(this.props.event.eventid, fileUrl).then((fileName) => {
                        if (fileName) {
                            resolve(fileName);
                        }
                        resolve("");
                    }, (err) => {
                        console.log(err);
                        resolve("");
                    });
                });
            }
        };
        this.LinkMgr = {
            addLink: function (parent, _props) {
                _props.popovermgr = this.props.popovermgr;
                _props.eventid = this.props.event.eventid;
                return this.props.popovermgr.popoverPortal(parent, AppLinkInputModal, {
                    ..._props,
                    isCmsToolbar: true

                } as IAppLinkInputProps, "linkpicker-modal-content");
            }
        };

        this.FileMgr.addFile = this.FileMgr.addFile.bind(this);
        this.FileMgr.getFileName = this.FileMgr.getFileName.bind(this);
        this.LinkMgr.addLink = this.LinkMgr.addLink.bind(this);
    }

    editHTML = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        editJsonModal(this.props.popovermgr, this.props.text, null, "html").then((update) => {
            if (update != null) {
                this.props.onChange(update);
            }
        });
    };

    componentDidMount() {
        if (this.props.event && this.props.event.eventid) {
            getTemplate(this.props.cmscontext.contentTemplates, "event", "preview", "config", "visualconfig").then((contenttemplate) => {
                if (contenttemplate.config.global && contenttemplate.config.global.colorPalette) {
                    this.setState({ colorPalette: [...this.state.colorPalette, ...contenttemplate.config.global.colorPalette.map((color) => color.colorCode)] });
                }
            });
        }
    }

    render() {
        return <div className={"richtextinput " + (this.props.className || "") + (this.props.multiline ? " multiline" : "")}>
            <i18NHelperContext.Consumer>
                {(i18NHelper) => <CMSContextProvider.Consumer>
                    {(cmscontext) => <CMSEditable
                        className="pseudoinput"
                        component="div"
                        colorPalette={this.state.colorPalette}
                        i18nHelper={i18NHelper}
                        allowRichText={true}
                        text={this.props.text}
                        cmscontext={cmscontext}
                        event={this.props.event}
                        datastamp={this.props.datastamp}
                        ontheflyUpdate={true}
                        fileMgr={this.FileMgr}
                        linkMgr={this.LinkMgr}
                        onChange={this.props.onChange}
                        showMinRow={this.props.showMinRow}
                    />}
                </CMSContextProvider.Consumer>}
            </i18NHelperContext.Consumer>
            <button className="btnEditHTML greybtn" onClick={this.editHTML}><i className="inwink-pencil" /></button>
        </div>;
    }
}

export interface IRichi18nTextInputProps {
    className?: string;
    languages: string[];
    canEditTrad: boolean;
    multiline?: boolean;
    datastamp: any;
    text: VisualTheme.IAppLabel;
    onChange: (text: VisualTheme.IAppLabel) => void;
    visualConfigurationState?: IVisualConfigurationStateManager;
    showMinRow?: number;
}

// tslint:disable-next-line: max-classes-per-file
export class Richi18nTextInput extends React.PureComponent<IRichi18nTextInputProps, any> {
    render() {
        const showLanguage = this.props.languages.length > 1;
        return <div className={"richi18ntextinput " + (this.props.className || "")}>
            {this.props.languages && this.props.languages.map((l) => <LanguageInput {...this.props} key={l} language={l} showLanguage={showLanguage} />)}
        </div>;
    }
}

interface ILanguageInputProps extends IRichi18nTextInputProps {
    language: string;
    showLanguage: boolean;
}

// tslint:disable-next-line: max-classes-per-file
class LanguageInput extends React.PureComponent<ILanguageInputProps, any> {
    onChange = (txt) => {
        let text;
        // Check poour retirer les string non liées à une langue utilisée dans l'event ou non liées à une langue du tout #40501
        if (this.props.languages && this.props.languages.length) {
            this.props.languages.map((language) => {
                const currentLanguageText = this.props.text && this.props.text[language] ? this.props.text[language] : null;
                if (currentLanguageText) {
                    text = Object.assign({}, text, { [language]: currentLanguageText });
                }
            });
        }
        const res = Object.assign({}, text, { [this.props.language]: txt });
        this.props.onChange(res);
    };

    render() {
        return <div className="lnginput">
            {this.props.showLanguage ? <div className="languagelabel">{this.props.language}</div> : null}
            <i18NHelperContext.Consumer>
                {(i18nHelper) => <RichTextInput
                    className={"lng-" + this.props.language}
                    datastamp={this.props.datastamp}
                    canEditTrad={this.props.canEditTrad}
                    multiline={this.props.multiline}
                    visualConfigurationState={this.props.visualConfigurationState}
                    text={this.props.text && this.props.text[this.props.language]}
                    onChange={this.onChange}
                    showMinRow={this.props.showMinRow}
                />}
            </i18NHelperContext.Consumer>

        </div>;
    }
}

import { Tooltip } from "@inwink/modals/tooltip";
import { NavLink } from "react-router-dom";
import { React, AppTextLabel } from '../../../commons';
import type { ISimpleTabItem} from './index';
import type { ISimpleTabsItemsGroupProps } from './itemsgroup';

export interface ISimpleTabsItemProps extends ISimpleTabsItemsGroupProps {
    item: ISimpleTabItem;
    panelsIdWithWarning?: string[];
    panelsWarningMessage?: string;
}

export function SimpleTabsItemCtrl(props: ISimpleTabsItemProps) {
    const classes = ["simpletabs-item", "clickable"];
    if (props.selectedItem === props.item) {
        classes.push("selected");
    }

    let displayWarning = false;

    if (props.panelsIdWithWarning) {
        for (let i = 0; i < props.panelsIdWithWarning.length; i++) {
            if (props.panelsIdWithWarning[i] === props.item.lang) {
                displayWarning = true;
            }
        }
    }

    const content = <>
        {displayWarning && (
            <Tooltip content={props.panelsWarningMessage || 'Error'} component="span" className="tooltip">
                <i className="inwink-warning" />
            </Tooltip>
        )}
        <span className="label">
            {(props.item.icon) ? <i className={props.item.icon} /> : null}
            <AppTextLabel component="span" i18n={props.item.label} />
        </span>
        <i className="chevron inwink-chevron-right" />
    </>;

    let onclick = () => props.itemSelected(props.item);

    if (props.item.link) {
        onclick = null;
    }

    const btn = <button
        type="button"
        className={classes.join(" ")}
        data-tab={props.item.id}
        onClick={onclick}
    >
        {content}
    </button>;

    if (props.item.link) {
        return <NavLink
            className="simpletabs-item-link"
            exact
            target={props.item.link.target}
            to={props.item.link.url}
        >
            {btn}
        </NavLink>;
    }

    return btn;
}

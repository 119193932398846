/* eslint-disable max-classes-per-file */
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { ItemTemplate } from '@inwink/itemtemplate';
import type { Entities } from '@inwink/entities/entities';
import {
    React, translateEventLabel, AppAsyncButton, AppTextLabel
} from '../../../../commons';
import type { IEntityScreenProps } from '../entitypagebase';
import { Card } from "../../../ui/card";
import { SelectableItem } from "../../../ui/selectableitem";

import './item.less';

export interface IEntityScreenSmartphoneItemProps extends IEntityScreenProps {
    i18nHelper: Entities.i18nHelper;
    itemtemplate: VisualTheme.IItemTemplate;
    item: any;
    renderArgs: ReactVirtualized.rowRenderedArgs;
    onItemClicked: (arg: React.MouseEvent<any>, item) => void;
}
export class EntityScreenSmartphoneItem extends React.PureComponent<IEntityScreenSmartphoneItemProps, any> {
    itemClicked = (arg: React.MouseEvent<any>) => {
        this.props.onItemClicked(arg, this.props.item);
    };

    fieldTrad = (field: VisualTheme.IItemTemplateBlocField, val) => {
        if (val) {
            if (field.eventLabel) {
                return translateEventLabel(this.props.cmscontext.displayLanguage, this.props.i18n, val);
            }
            return this.props.i18nHelper.translateBag(val);
        }

        return "";
    };

    openItemDetail = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        const ctx = this.props.helpers.getActionContext(null, null);
        ctx.component = this;
        ctx.tools = {
            refresh: () => {
                // _this.datamgr.refreshGrid(_this.props);
            },
            setSelection: (selection) => {
                this.props.viewsManager.setSelection(selection);
            }
        };

        return this.props.entityconfiguration?.detailConfig?.disableUpdate ? null
            : this.props.helpers.openEntityDetail(this.props.item, false, false, ctx).then((res) => {
                // eslint-disable-next-line react/no-unused-state
                this.setState({ update: res });
            });
    };

    triggerAction = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        const action = this.props.entityactions?.entityActions && this.props.entityactions.entityActions[0];
        if (action) {
            action.action(arg, this.props.helpers.getActionContext(action.params, action as any), this.props.item);
        }
    };

    showItemActions = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.helpers.showEntityActions(arg, this.props.helpers.getActionContext(null, null), this.props.item);
    };

    onSwiped = () => {
        this.props.onItemClicked(null, this.props.item);
    };

    render() {
        const isSelected = !!this.props.viewsState.selection.filter((item) => item.id === this.props.item.id)[0];
        let actions = null;
        let swipeable = false;
        let onClick = this.itemClicked;

        if (!this.props.runMode || (this.props.runMode === "entitypage"
            || this.props.runMode === "entitydetailtab" || this.props.runMode === "removedentitypage")) {
            onClick = null;
            const actionbuttons = [];
            swipeable = true;
            if (!this.props.entityconfiguration.detailConfig.disableUpdate) {
                onClick = this.openItemDetail;
            }
            if (this.props.entityactions?.entityActions?.length) {
                if (this.props.entityactions.entityActions.length === 1) {
                    const action = this.props.entityactions.entityActions[0];
                    actionbuttons.push(
                        <AppAsyncButton key={action.id + "#action"} onClick={this.triggerAction} className="iconbtn">
                            <i className={action.icon} />
                        </AppAsyncButton>
                    );
                } else {
                    actionbuttons.push(<AppAsyncButton key="moreactions" onClick={this.showItemActions} className="iconbtn">
                        <i className="inwink-more-h" />
                    </AppAsyncButton>);
                }
            }

            if (actionbuttons.length) {
                actions = <div className="itemactions">
                    {actionbuttons}
                </div>;
            }
        }

        return <Card className={"listitem-card selectable" + (isSelected ? " selected" : "") + (swipeable ? " swipeable" : "")}>
            <SelectableItem isSelected={isSelected} swipeable={swipeable} onSwiped={this.onSwiped}>
                <div className="listitem-cardcontent">
                    <div className="card-wrapper" onClick={onClick}>
                        <ItemTemplate
                            className="card-contenttemplate"
                            template={this.props.itemtemplate}
                            data={this.props.item}
                            datacontext={this.props as any}
                            getFieldTrad={this.fieldTrad}
                        />
                    </div>
                    {actions}
                </div>
            </SelectableItem>
        </Card>;
    }
}

export interface IEntityScreenSmartphoneItemWrapperProps {
    itemProps: IEntityScreenSmartphoneItemProps;
    isScrolling: boolean;
}

export class EntityScreenSmartphoneItemWrapper extends React.PureComponent<IEntityScreenSmartphoneItemWrapperProps, any> {
    constructor(props: IEntityScreenSmartphoneItemWrapperProps) {
        super(props);
        this.state = {
            renderContent: (!props.isScrolling)
        };
    }

    componentDidMount() {
        if (!this.state.renderContent) {
            setTimeout(() => {
                this.setState({ renderContent: true });
            }, 0);
        }
    }

    render() {
        if (!this.state.renderContent) {
            return <Card className="listitem-content session"><AppTextLabel i18n="display.loading" />...</Card>;
        }

        return <EntityScreenSmartphoneItem {...this.props.itemProps} />;
    }
}

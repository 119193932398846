import {
    React, AppTextLabel
} from '../../../../commons';
import type { IEntityScreenProps } from '../entitypagebase';
import { EntityList } from "../../entitylist";
import { Card } from "../../../ui/card";
import {
    EntityScreenSmartphoneItemWrapper,
    IEntityScreenSmartphoneItemProps
} from './item';
import { EntityScreenSmartphoneHeader } from './header';
import { EntityScreenSmartphoneFooter } from './footer';

import './phone.less';

interface IEntityScreenState {
    update: Date;
    refresh: Date;
    firstLoad: boolean;
    expression: any;
    defaultOrderBy: any;
    datasource: any;
    listLoading?: boolean;
}

export class EntityScreenSmartphone extends React.Component<IEntityScreenProps, IEntityScreenState> {
    constructor(props: IEntityScreenProps) {
        super(props);

        const datasource = this.props.entityconfiguration.datasource;
        this.state = {
            update: new Date(),
            refresh: new Date(),
            firstLoad: true,
            expression: this.getExpression(props),
            defaultOrderBy: this.props.entityconfiguration.defaultOrderBy
                ? this.props.entityconfiguration.defaultOrderBy() : null,
            datasource: datasource
        };
    }

    itemClicked = (arg: React.MouseEvent<any>, item) => {
        const ctx = this.props.helpers.getActionContext(item, null);
        ctx.selection = this.props.viewsState.selection;
        ctx.tools = {
            refresh: () => null,
            setSelection: (_selection) => {
                this.props.viewsManager.setSelection(_selection);
            }
        };
        ctx.component = this;
        let multiselect = this.props.multiselect;
        if (multiselect === undefined && (!this.props.runMode || this.props.runMode === "entitypage")) {
            multiselect = true;
        }

        // if (this.props.runMode === "picker") {
        let selection = this.props.viewsState.selection ? [...this.props.viewsState.selection] : [];
        const selectedItem = selection.filter((selectionItem) => selectionItem.id === item.id)[0];
        const idx = selectedItem && selection.indexOf(selectedItem);
        if (multiselect) {
            if (idx >= 0) {
                selection.splice(idx, 1);
            } else {
                selection.push(item);
            }
        } else if (idx >= 0) {
            selection = [];
        } else {
            selection = [item];
        }

        this.props.viewsManager.setSelection(selection);
        this.setState({
            refresh: new Date()
        });
    };

    renderCustomItem = (entity) => {
        const props = Object.assign({}, this.props, {
            item: entity,
            onItemClicked: this.itemClicked
        });

        return React.createElement(this.props.entityconfiguration.list.itemComponent, props);
    };

    renderTemplatedItem = (entity, args: ReactVirtualized.rowRenderedArgs, selection: any, isScrolling) => {
        const props: IEntityScreenSmartphoneItemProps = {
            ...this.props,
            renderArgs: args,
            i18nHelper: this.props.i18nHelper,
            itemtemplate: this.props.entityconfiguration.list.itemTemplate,
            item: entity,
            onItemClicked: this.itemClicked
        };

        return <EntityScreenSmartphoneItemWrapper isScrolling={isScrolling} itemProps={props} />;
    };

    loadingItem = () => {
        return <Card className="listitem-content session"><AppTextLabel i18n="display.loading" />...</Card>;
    };

    noItem = () => {
        return <div className="no-result"><AppTextLabel i18n="display.noresult" />.</div>;
    };

    rowCountChanged = (count) => {
        this.props.viewsManager.setRowCount(count);
    };

    onLoading = (isLoading) => {
        if (this.state.firstLoad && this.props.onboardingcontext) {
            this.props.onboardingcontext.setOnboarding("phoneentitypage");
        }

        this.setState({ listLoading: isLoading, firstLoad: false });
    };

    componentDidUpdate(prevprops: IEntityScreenProps) {
        if (this.props.viewsState && this.props.viewsState.queryExpression !== prevprops.viewsState.queryExpression) {
            this.setState({ expression: this.getExpression(this.props) });
        }
    }

    getExpression(props: IEntityScreenProps) {
        let expression = props.viewsState && props.viewsState.queryExpression;

        if (props.expression) {
            if (expression) {
                expression = { and: [expression, props.expression] };
            } else {
                expression = props.expression;
            }
        }

        return expression;
    }

    render() {
        const hasTemplate = this.props.entityconfiguration.list
            && (this.props.entityconfiguration.list.itemComponent || this.props.entityconfiguration.list.itemTemplate);

        if (!hasTemplate) {
            return <div className="pagecontent smartphone notavailable">
                <AppTextLabel i18n="page.format.unavailable" component="div" className="message" />
            </div>;
        }

        const viewcontent = this.props.viewsState?.currentview?.content;

        let expands = this.props.entityconfiguration.list && this.props.entityconfiguration.list.expands;
        if (this.props.expands) {
            expands = expands || [];
            expands.push(...this.props.expands);
        }
        let renderItem = this.renderTemplatedItem;
        if (!this.props.entityconfiguration.list.itemTemplate) {
            renderItem = () => <div>please configure entity list</div>;
        }
        if (this.props.entityconfiguration.list.itemComponent) {
            renderItem = this.renderCustomItem;
        }

        return <div className="iw-entitypage pagecontent smartphone">
            <EntityScreenSmartphoneHeader {...this.props} />
            <section>
                <EntityList
                    onRowCountChanged={this.rowCountChanged}
                    expands={expands}
                    filters={viewcontent && viewcontent.filters}
                    expression={this.state.expression}
                    searchterm={viewcontent && viewcontent.search}
                    datasource={this.state.datasource as any}
                    rowHeight={this.props.entityconfiguration.list?.rowHeight || 72}
                    getItem={renderItem}
                    getLoadingItem={this.loadingItem}
                    noItem={this.noItem}
                    update={this.state.update}
                    refresh={this.state.refresh}
                    orderBy={this.state.defaultOrderBy}
                    items={this.props.viewsState && this.props.viewsState.showSelection ? this.props.viewsState.selection : null}
                    loadingStateChanged={this.onLoading}
                    useLoadingState={true}
                />
                {/* <div className="listloading"><Loader /></div> */}
                {/* <div className={"listloading " + (this.state.listLoading ? "visible" : "hidden")}><Loader /></div> */}
            </section>
            <EntityScreenSmartphoneFooter
                {...this.props}
                isLoading={this.state.listLoading}
                disableAddNew={this.props.disableAddNew || !this.props.entityconfiguration.list.allowAddNew}
            />
        </div>;
    }
}

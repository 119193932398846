import * as assign from 'lodash/assign';
import { getPropertyValue } from '@inwink/utils/methods';
import { States } from './services';
import { customerRights, eventRights, boRights, tenantRights, communityRights } from '../api/backoffice/me';
import type { IEntityPageConfiguration } from '../components/entities/entitypagev3/entitypagebase.props';

const INITIAL_STATE = {
    eventid: null,
} as States.IAppUserRights;

export function rightsReducer(state = INITIAL_STATE, action = { type: '', payload: null }): any {
    switch (action.type) {
        case "RIGHTS_CLEAR": {
            return { eventid: null, claims: null};
        }
        case "RIGHTS_SET": {
            return assign({}, state, {
                eventid: action.payload.eventid,
            });
        }
        case "RIGHTS_BO": {
            return assign({}, state, {
                apprights: action.payload && action.payload.rights
            });
        }
        case "RIGHTS_INWINKCUSTOMER": {
            return assign({}, state, {
                inwinkcustomer: action.payload
            });
        }
        case "RIGHTS_CUSTOMER": {
            return assign({}, state, {
                customer: action.payload
            });
        }
        case "RIGHTS_EVENT": {
            return assign({}, state, {
                event: action.payload
            });
        }
        case "RIGHTS_TENANT": {
            return assign({}, state, {
                tenant: action.payload
            });
        }
        case "RIGHTS_COMMUNITY": {
            return assign({}, state, {
                community: action.payload
            });
        }
        default:
            return state;
    }
}

export const rightsActions = {
    boRights() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState) => {
            return boRights().then((rights) => {
                dispatch({ type: 'RIGHTS_BO', payload: { rights: rights } });
            });
        };
    },

    customerRights(customerid: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState) => {
            if (!customerid) {
                dispatch({ type: 'RIGHTS_CUSTOMER', payload: null });
                return;
            }

            customerRights(customerid).then((rights) => {
                dispatch({ type: 'RIGHTS_CUSTOMER', payload: { customerId: customerid, rights: rights } });
            });
        };
    },

    inwinkRights() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState) => {
            customerRights("00000000-0000-0000-0000-000000000000").then((rights) => {
                dispatch({ type: 'RIGHTS_INWINKCUSTOMER', payload: { rights: rights } });
            });
        };
    },

    eventRights(customerid: string, eventid: string) {
        return (dispatch, getState) => {
            return eventRights(customerid, eventid).then((rights) => {
                dispatch({ type: 'RIGHTS_EVENT', payload: { eventId: eventid, rights: rights } });
            });
        };
    },

    communityRights(customerid: string, communityid: string) {
        return (dispatch, getState) => {
            return communityRights(customerid, communityid).then((rights) => {
                dispatch({ type: 'RIGHTS_COMMUNITY', payload: { communityId: communityid, rights: rights } });
            });
        };
    },

    tenantRights(customerid: string, tenantid: string) {
        return (dispatch, getState) => {
            return Promise.all([
                tenantRights(customerid, tenantid).then((rights) => {
                    dispatch({ type: 'RIGHTS_TENANT', payload: { tenantId: tenantid, rights: rights } });
                }),
                // rightsActions.loadUserRights(customerid, tenantid)(dispatch, getState)
            ]);
        };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearRights(eventid: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({ type: "RIGHTS_CLEAR" });
        };
    }
};

export interface IRightsCheckBag {
    boRights?: string[];
    customerRights?: string[];
    tenantRights?: string[];
    eventRights?: string[];
    communityRights?: string[];
}

export function getEntityPageRights(entityconfiguration: IEntityPageConfiguration,
    rights: States.IAppUserRights): InWinkBO.IEntityRights {
    if (entityconfiguration && entityconfiguration.rights) {
        return checkEntityRights(rights, entityconfiguration.rights, entityconfiguration.readOnly);
    }

    return {};
}

export function checkEntityRights(rightsstate: States.IAppUserRights,
    rightsToCheck: IRightsCheckBag, readOnly = false): InWinkBO.IEntityRights {
    const res: InWinkBO.IEntityRights = {};

    const append = (prop) => {
        if (prop.read) {
            res.canRead = true;
            res.canExport = true;
        }
        if (prop.edit) {
            res.canExport = true;
            res.canRead = true;
            res.canChangeSettings = true;
            if (!readOnly) {
                res.canCreate = true;
                res.canUpdate = true;
                res.canDelete = true;
                res.canImport = true;
            }
        }

        if (prop.create) {
            res.canRead = true;
            if (!readOnly) {
                res.canCreate = true;
            }
        }
    };

    if (!rightsToCheck) {
        return {};
    }

    if (rightsToCheck.boRights) {
        rightsToCheck.boRights.forEach((r) => {
            const right = getPropertyValue(rightsstate?.apprights, r);
            if (right) {
                append(right);
            }
        });
    }

    if (rightsToCheck.customerRights && rightsstate?.customer) {
        rightsToCheck.customerRights.forEach((r) => {
            const right = getPropertyValue(rightsstate.customer.rights, r);
            if (right) {
                append(right);
            }
        });
    }

    if (rightsToCheck.eventRights && rightsstate?.event) {
        rightsToCheck.eventRights.forEach((r) => {
            const right = getPropertyValue(rightsstate.event.rights, r);
            if (right) {
                append(right);
            }
        });
    }

    if (rightsToCheck.communityRights && rightsstate?.community) {
        rightsToCheck.communityRights.forEach((r) => {
            const right = getPropertyValue(rightsstate.community.rights, r);
            if (right) {
                append(right);
            }
        });
    }

    if (rightsToCheck.tenantRights && rightsstate?.tenant) {
        rightsToCheck.tenantRights.forEach((r) => {
            const right = getPropertyValue(rightsstate.tenant.rights, r);
            if (right) {
                append(right);
            }
        });
    }

    if (rightsToCheck.communityRights && rightsstate?.community) {
        rightsToCheck.communityRights.forEach((r) => {
            const right = getPropertyValue(rightsstate.community.rights, r);
            if (right) {
                append(right);
            }
        });
    }

    return res;
}

export function isAllowed(rightsstate: States.IAppUserRights, rightsToCheck: IRightsCheckBag) {
    if (!rightsToCheck) {
        return true;
    }

    if (rightsToCheck.boRights) {
        const allowed2 = rightsToCheck.boRights.some((r) => {
            // très bizarre mais apprights.righes ==> {edit: true } donc évidemment ça ne passe jamais
            const _pv = getPropertyValue(rightsstate.apprights, r);
            if (_pv && (_pv === true || _pv.read || _pv.edit)) {
                return true;
            }
            return false;
        });

        if (!allowed2) { return false; }
    }

    if (rightsToCheck.customerRights && rightsstate.customer) {
        const allowed3 = rightsToCheck.customerRights.some((r) => {
            const _pv = getPropertyValue(rightsstate.customer.rights, r);
            if (_pv && (_pv === true || _pv.read || _pv.edit)) {
                return true;
            }
            return false;
        });

        if (!allowed3) { return false; }
    }

    if (rightsToCheck.tenantRights && rightsstate.tenant) {
        const allowed = rightsToCheck.tenantRights.some((r) => {
            const _pv = getPropertyValue(rightsstate.tenant.rights, r);
            if (_pv && (_pv === true || _pv.read || _pv.edit)) {
                return true;
            }
            return false;
        });

        if (!allowed) { return false; }
    }

    if (rightsToCheck.eventRights && rightsstate.event) {
        const allowed = rightsToCheck.eventRights.some((r) => {
            const _pv = getPropertyValue(rightsstate.event.rights, r);
            if (_pv && (_pv === true || _pv.read || _pv.edit)) {
                return true;
            }
            return false;
        });

        if (!allowed) { return false; }
    }

    if (rightsToCheck.communityRights && rightsstate.community) {
        const allowed = rightsToCheck.communityRights.some((r) => {
            const _pv = getPropertyValue(rightsstate.community.rights, r);
            if (_pv && (_pv === true || _pv.read || _pv.edit)) {
                return true;
            }
            return false;
        });

        if (!allowed) { return false; }
    }

    return true;
}

/* eslint-disable max-classes-per-file */
import { CollapsiblePanel } from '@inwink/collapsiblepanel';
import { buildExpression } from '@inwink/expressions/expressionbuilder';
import { React, Loader, AppTextLabel, DynLabel, DisplayDate } from '../../commons';
import { changelogDataSource } from '../../api/backoffice/changelog';
import './appshellchangelog.less';

export class Changelog extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            items: [],
            page: 0
        };
    }

    fetch() {
        this.setState({ loading: true }, () => {
            const ds = changelogDataSource();
            ds.query({
                filters: buildExpression()
                    .WithString("forApplication")
                    .Equals("backorga")
                    .AsFilter(),
                order: [{
                    by: "published",
                    desc: true
                }],
                page: {
                    size: 3,
                    index: this.state.page,
                    returnTotalCount: true
                },
                totalCount: true
            }).then((items) => {
                this.setState((prevState) => {
                    const list = [...prevState.items];
                    list.push(...items.data);
                    return { loading: false, page: prevState.page + 1, items: list };
                });
            }, (err) => {
                console.error(err);
                this.setState({ loading: false });
            });
        });
    }

    componentDidMount() {
        this.fetch();
    }

    render() {
        let items;
        if (this.state.loading) {
            items = <Loader />;
        }
        if (this.state.items) {
            items = this.state.items.map((item) => {
                return <ChangelogItem key={item.id} item={item} />;
            });
        }
        return <div className="changelogpanel">
            <AppTextLabel i18n="app.name" component="h2" />
            <div className="items">
                {items}
            </div>
        </div>;
    }
}

export class ChangelogItem extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            item: props.item.details ? JSON.parse(props.item.details) : {}
        };
    }

    render() {
        return <div className="changelogitem">
            <header
                className="clickable"
                onClick={() => this.setState((prevState) => {
                    return { expanded: !prevState.expanded };
                })}
            >
                <h3>
                    <span className="version">Version {this.props.item.version}</span>
                    <DisplayDate className="published" date={this.props.item.published} />
                </h3>
            </header>
            <ChangelogContentItems item={this.state.item} expanded={this.state.expanded} />
        </div>;
    }
}

export function ChangelogContentItems(props: any) {
    let items; let
        description;
    if (props.item && props.item.items) {
        items = props.item.items.map((item, idx) => {
            return <div className="changelogcontentitem" key={"item" + idx}>
                <DynLabel i18n={item.title} component="h4" />
                <DynLabel i18n={item.description} className="description" component="p" />
            </div>;
        });
    }

    if (props.item && props.item.description) {
        description = <DynLabel component="div" className="description" i18n={props.item.description} />;
    }
    return <CollapsiblePanel expanded={props.expanded}>
        {description}
        <div className="changelogcontentitem-items">
            {items}
        </div>
    </CollapsiblePanel>;
}

import * as assign from 'lodash/assign';
import { Entities } from '@inwink/entities/entities';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { States } from '../../../services/services';
import { IColumn } from '../../entities/definitions';
import { IEntityPageConfiguration } from "../../entities/entitypagev3/entitypagebase.props";

export interface IExpressionDesignerDefinition {
    key: string;
    tradkey?: string;
    title: string;
    entityName?: string;
    defaultLabelRoot?: string;
    fields: Entities.IEntityFieldTemplateV3[];
    config?: IEntityPageConfiguration;
}

export function getGroupFromDefinition(definition: IExpressionDesignerDefinition[], fieldkey) {
    let group;

    const findKey = (d, initialKey = null) => {
        let fields;
        if (d.fields && d.fields.length) {
            fields = d.fields;
        } else if (d.template && d.template.fields) { fields = Object.values(d.template.fields); }

        if (fields && fields.length) {
            // eslint-disable-next-line no-restricted-syntax
            for (const f of fields) {
                if (f.type && f.template && f.template.fields && (f.type === "Entity" || f.type === "Entities")) {
                    return findKey(f, d.key);
                }
                let globalkey = (d.key ? d.key + "." + f.key : f.key);
                if (initialKey) {
                    globalkey = initialKey + "." + globalkey;
                }

                if (fieldkey === globalkey) {
                    return d;
                }
            }
        }
    };
    definition.forEach((d) => {
        group = findKey(d);
    });

    return group;
}

export function getFieldFromDefinition(definition: IExpressionDesignerDefinition[], fieldkey: string, columns?: IColumn[]) {
    let field;
    let currentDefinition;

    const findField = (d, initialKey = null) => {
        let fields;
        if (d.fields && d.fields.length) {
            fields = d.fields;
        } else if (d.template && d.template.fields) { fields = Object.values(d.template.fields); }

        if (fields && fields.length) {
            // eslint-disable-next-line no-restricted-syntax
            for (const f of fields) {
                if (f.type && f.template && f.template.fields && (f.type === "Entity" || f.type === "Entities")) {
                    const rec = findField(f, d.key);
                    if (rec) { return rec; }
                    // else continue
                } else {
                    let globalkey = (d.key ? d.key + "." + f.key : f.key);
                    if (initialKey) {
                        globalkey = initialKey + "." + globalkey;
                    }

                    if (fieldkey === globalkey) {
                        return f;
                    }
                }
            }
        }
    };

    for (let i = 0; i < definition.length; i++) {
        const element = definition[i];
        field = findField(element);
        if (field) {
            currentDefinition = element;
            break;
        }
    }

    // definition.forEach((d) => {
    //     field = findField(d);
    // });

    if (!field && columns) {
        const key = fieldkey.split('.')[1] ? fieldkey.split('.')[1] : fieldkey;
        columns.map((col) => {
            if (col.key === key) {
                field = col;
            }
            return null;
        });
    }

    return {
        field,
        definition: currentDefinition
    };
}

export function getFieldValueFor(field: Entities.IEntityFieldTemplate, val: string) {
    if (field && field.type && (field.type.toLowerCase() === "selectlist" || field.type.toLowerCase() === "multiselectlist")) {
        if (field.valuesList) {
            const tmp = getValueFor(field.valuesList, val);
            if (tmp) {
                return tmp;
            }
        }
    }
}

export function getValueFor(vals: Entities.IEntityFieldValue[], val: string): VisualTheme.IAppLabel {
    for (let i = 0, l = vals.length; i < l; ++i) {
        if (vals[i].key === val) {
            return vals[i].labels;
        }
        if (vals[i].valuesList) {
            const v = getValueFor(vals[i].valuesList, val);
            if (v) {
                return v;
            }
        }
    }
}

export function operatorLabel(i18n: States.i18nState, operator: string) {
    if (operator === "eq") {
        return "Is equal";
    }
    if (operator === "neq") {
        return "Is not";
    }
    if (operator === "lt") {
        return "Is lesser";
    }
    if (operator === "lte") {
        return "Is strictly lesser";
    }
    if (operator === "gt") {
        return "Is greater";
    }
    if (operator === "gte") {
        return "Is strictly greater";
    }

    return operator;
}

export function addEntityNameToExpression(entityname: string, expression) {
    if (!expression) {
        return null;
    }

    const e = [...expression].map((ex) => {
        if ('name' in ex) {
            return Object.assign({}, ex, { name: entityname + '.' + ex.name });
        }
        return Object.assign({}, ex, { name: entityname});
    }).map((ex) => {
        if (ex) {
            if ('or' in ex) {
                return Object.assign({}, ex, { or: addEntityNameToExpression(entityname, ex.or) });
            }

            if ('and' in ex) {
                return Object.assign({}, ex, { and: addEntityNameToExpression(entityname, ex.and) });
            }

            return ex;
        }
        return null;
    });

    return e;
}

export function removeEntityNameFromExpression(entityname: string, expression) {
    if (!expression) {
        return null;
    }
    return [...expression].map((ex) => {
        // if ('name' in ex && ex.name.indexOf('.') > -1) {
        //     return assign({}, ex, { name: ex.name.split('.')[1] });
        // }
        if ('name' in ex) {
            // const split: string[] = ex.name.split('.');
            // let name = entityname.toLowerCase();
            // if (split.length > 1) {
            //     const ix = split.indexOf(name);
            //     name = split[1];
            //     if (ix > -1 && (ix + 1) < split.length) {
            //         name = split[ix + 1];
            //     }
            //     return assign({}, ex, { name });
            // }
            const part = `${entityname}.`;
            if ((ex.name as string).startsWith(part)) {
                const name = (ex.name as string).substring(part.length, ex.name.length);
                return assign({}, ex, { name });
            }
            return ex;
        }
        return ex;
    }).map((ex) => {
        if ('or' in ex) {
            return assign({}, ex, { or: removeEntityNameFromExpression(entityname, ex.or) });
        }

        if ('and' in ex) {
            return assign({}, ex, { and: removeEntityNameFromExpression(entityname, ex.and) });
        }

        return ex;
    });
}

import { Entities } from '@inwink/entities/entities';
import { Loader } from '@inwink/loader';
import { sanitizeHtmlString } from '@inwink/utils/methods';
import * as find from 'lodash/find';
import * as moment from 'moment';
import { React, translateEventLabel } from '../../../../commons';
import type { ICMSContext } from "../../../cmscontext";
import type { IEntityPageConfiguration } from "../../entitypagev3/entitypagebase.props";
import { IExpressionItemMetadata, removeEntityNameFromExpression } from '../utils';
import { getExpressionFromProps } from './expressionmultiselectlist';

interface IResumeExpressionProps {
    cmscontext: ICMSContext;
    i18nHelper: Entities.i18nHelper;
    metadata: IExpressionItemMetadata;
    expression: any;
    isMultiStepRegistration?: boolean;
    onReady?: () => void;
}

interface IResumeExpressionState {
    metadata: IExpressionItemMetadata;
    trads: any;
    loading: boolean;
    conf?: IEntityPageConfiguration;
}

export class ResumeExpression extends React.Component<IResumeExpressionProps, IResumeExpressionState> {
    constructor(props: IResumeExpressionProps) {
        super(props);
        this.state = {
            trads: null, // this.computeLabel(this.props.metadata),
            metadata: this.props.metadata,
            loading: true
        };
    }

    componentDidMount() {
        const onError = () => {
            console.log("cannot get entityconfiguration");
            this.computeLabel(this.state.metadata).then((res) => this.setState({ trads: res, loading: false }));
        };
        
        if (this.props.metadata?.entityName) {
            this.props.cmscontext.getEntityConfiguration(this.props.metadata?.entityName, {
                cmscontext: this.props.cmscontext,
                i18nHelper: this.props.i18nHelper
            } as any).then((conf) => {
                this.setState({
                    conf
                }, () => {
                    this.computeLabel(this.state.metadata).then((res) => {
                        this.setState({ trads: res, loading: false }, () => {
                            if (this.props.onReady) {
                                this.props.onReady();
                            }
                        });                        
                    });
                });
            }, () => {
                onError();
            });
        } else {
            onError();
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.expression) !== JSON.stringify(this.props.expression)) {
            this.computeLabel(this.props.metadata).then((res) => this.setState({ trads: res, loading: false }));
        }
    }

    getSnapshotBeforeUpdate(prevProps: IResumeExpressionProps) {
        if (prevProps && this.props && (JSON.stringify(prevProps.metadata) !== JSON.stringify(this.props.metadata))) {
            // const trads = this.computeLabel(this.props.metadata);
            // this.setState({ trads });
            this.computeLabel(this.props.metadata).then((res) => this.setState({ trads: res, loading: false }));
        }
    }

    numberOrDate = (i18n: Entities.i18nHelper, type, value) => {
        const _v = (type.toLowerCase() === 'date')
            ? moment(value).locale(i18n.i18n.currentLanguageCode).format('lll') : "" + value;
        return _v;
    };

    getColumnName = (i18n: Entities.i18nHelper, metadata: IExpressionItemMetadata) => {
        let label;
        const languageCode = this.props.cmscontext ? this.props.cmscontext.displayLanguage : i18n.i18n.currentLanguageCode;
        const field: any = metadata && metadata.field;
        const group = metadata && metadata.group;
        let entityName = metadata && metadata.entityName;

        if (field && field.display && field.display.labels) {
            label = translateEventLabel(languageCode, this.props.i18nHelper.i18n, field.display.labels);
        } else if (field && field.labels) {
            label = translateEventLabel(languageCode, this.props.i18nHelper.i18n, field.labels);
        }
        if (!label && field) {
            let labelkey = ((entityName ? entityName + '.' : '') + field.key).toLowerCase();
            if (group && group.tradkey) {
                labelkey = group.tradkey + "." + field.key;
            }
            label = i18n.translate(labelkey.toLowerCase());
            if (!label && metadata.field && metadata.group && metadata.group.value) {
                label = i18n.translate(`${metadata.group.value.toLowerCase()}.${metadata.field.key}`);
            }

            if (!label) { label = labelkey; }
        }

        if (entityName) {
            if (metadata && metadata.parent) {
                entityName = `${metadata.parent.entityName}.${metadata.parent.field.key}`;
            }
            const entityNameLbl = i18n.translate(`${entityName.toLowerCase()}`, null, entityName);
            label += ` (${entityNameLbl})`;
        }

        return label;
    };

    getValueName = (metadata: IExpressionItemMetadata, i18n: Entities.i18nHelper, value: string) => {
        let label;
        const languageCode = this.props.cmscontext ? this.props.cmscontext.displayLanguage : i18n.i18n.currentLanguageCode;
        const field = metadata && metadata.field;
        if (!field) {
            return "";
        }

        if (field && field.valuesList) {
            const valueList = find(field.valuesList, { key: value });

            if (valueList && valueList.labels) {
                label = translateEventLabel(languageCode, this.props.i18nHelper.i18n, valueList.labels);
            } else if (this.props.metadata.entityName) {
                const tradkey = `${this.props.metadata.entityName}.${this.props.metadata.field.key}.${value}`.toLowerCase();
                label = i18n.translate(tradkey, null, tradkey);
            } else {
                const tradkey = `${this.props.metadata.field.key}.${value}`.toLowerCase();
                label = i18n.translate(tradkey, null, tradkey);
            }
        }

        return label || value;
    };

    ready = () => {        
        if (this.props.onReady) {
            setTimeout(() => {
                this.props.onReady();
            }, 50);
        }
    };

    computeLabel(metadata: IExpressionItemMetadata): Promise<string> {
        const i18n = this.props.i18nHelper;
        let trads;
        const field = metadata && metadata.field;
        if (i18n && field && field.type) {
            let expression = JSON.parse(JSON.stringify(this.props.expression));
            if (this.state.conf
                && this.state.conf.specificExpressionComputings
                && this.state.conf.specificExpressionComputings[field.key]
                && this.state.conf.specificExpressionComputings[field.key].expressionResume) {
                this.state.conf.specificExpressionComputings[field.key].expressionResume(field, expression, (newexpression) => {
                    expression = newexpression;
                });
            }
            const lowerType = field.type.toLowerCase();
            const orTrad = i18n.translate('or').toString().toUpperCase();
            if (lowerType === "selectlist" || lowerType === "multiselectlist") {
                const selected = getExpressionFromProps(expression, field.type);
                const blankfilter = (
                    find(expression.or, { or: [{ op: 'isnull' }] })
                    || find(expression.or, { op: 'count' })
                    || find(expression.or, { op: "size", val: 0 })
                );
                let value = "";

                if (typeof selected === 'string') {
                    value += '&#171;<span>' + this.getValueName(metadata, i18n, selected as string) + '</span>&#187;';
                } else if (selected) {
                    selected.forEach((select, selectIdx) => {
                        // if (select) { // #42280 - ignore "" expression so it's only dealt with blankFilter
                        value += `&#171;<span>${this.getValueName(metadata, i18n, select)}</span>&#187;${(
                            (selectIdx !== selected.length - 1) ? `<strong> ${orTrad} </strong>` : ``)}`;
                        // }
                    });
                }
                let translateStr = `resume.${lowerType}`;
                if (blankfilter) { translateStr += `.blank_filter`; }
                if (!selected.length) { translateStr += `_empty`; }
                trads = i18n.translate(translateStr, {
                    name: this.getColumnName(i18n, metadata),
                    value
                });
            } else if (lowerType === 'entities' || lowerType === 'array') {
                if (lowerType === 'entities') {
                    if (expression.any || expression.or) {
                        const expr = expression.any
                            || removeEntityNameFromExpression(field.value, [expression]);
                        const args = Object.assign({}, this.props, { expression: null }) as any;
                        return this.props.cmscontext.getEntityConfiguration(
                            field.value, args
                        ).then((conf: IEntityPageConfiguration) => {
                            if (conf && conf.cellRenderer) {
                                return conf.datasource.query(
                                    { expression: expr },
                                    conf.cellRenderer.entity.expands
                                ).then((res) => {
                                    this.ready();
                                    if (res && res.data) {
                                        return i18n.translate('resume.entities', {
                                            name: this.getColumnName(i18n, metadata),
                                            value: res.data.map((d) => `&#171;<span>${
                                                conf.cellRenderer.entity
                                                    .renderer(d, this.props.cmscontext, this.props.i18nHelper, args)
                                            }</span>&#187;`)
                                                .filter((p) => (!!p))
                                                .join(' <strong>' + orTrad + '</strong> ')
                                        });
                                    }
                                    return '';
                                });
                            }
                            console.warn('missing cellRender for this filter', field);
                            this.ready();
                        });
                    }
                } else {
                    let selected = this.props.expression.selected_values;
                    if (!selected && this.props.expression.val) {
                        if (Array.isArray(this.props.expression.val)) {
                            selected = this.props.expression.val;
                        } else {
                            selected = [this.props.expression.val];
                        }
                    }
                    let trad = '';

                    if (selected) {
                        selected.forEach((select, selectIdx) => {
                            trad += '&#171;<span>' + select + '</span>&#187; ' + ((selectIdx !== selected.length - 1)
                                ? ' <strong>' + orTrad + '</strong> ' : '');
                        });
                    }

                    if (this.props.expression.any) {
                        if (this.props.expression.name === "personScans" && this.props.expression.any.and) {
                            // hack qui pue
                            let gte = '';
                            let lte = '';
                            this.props.expression.any.and.forEach((item) => {
                                if (item.op === "gte") {
                                    gte = i18n.getMoment(item.val).format("lll") + " <= ";
                                } else if (item.op === "lte") {
                                    lte = " <= " + i18n.getMoment(item.val).format("lll");
                                }
                            });
                            trads = gte + this.getColumnName(i18n, metadata) + lte;
                        }
                    } else {
                        trads = i18n.translate((`${this.props.isMultiStepRegistration ? "multistepregistration." : ""}`)
                            + 'resume.entities', {
                            name: this.getColumnName(i18n, metadata),
                            value: trad
                        });
                    }
                }
            } else if (lowerType === 'entity') {
                let value = '';
                if (expression.val || expression.and) {
                    const args = Object.assign({}, this.props, { expression: null }) as any;
                    return this.props.cmscontext.getEntityConfiguration(
                        field.value, args
                    ).then((conf: IEntityPageConfiguration) => {
                        if (conf && conf.cellRenderer) {
                            const expr = removeEntityNameFromExpression(field.value, [expression]);
                            return conf.datasource.query(
                                { expression: expr, page: { index: 0, size: 1 } },
                                conf.cellRenderer.entity.expands
                            ).then((res) => {
                                this.ready();
                                if (res && res.data) {
                                    const entityValue = conf.cellRenderer.entity.renderer(res.data[0], this.props.cmscontext,
                                        this.props.i18nHelper, args);
                                    return i18n.translate('resume.entity', {
                                        name: this.getColumnName(i18n, metadata),
                                        value: `&#171;<span>${entityValue}</span>&#187;`
                                    });
                                }
                                return '';
                            });
                        }
                        this.ready();
                    });
                } if (!expression.or && expression.selected_value) {
                    value = `&#171;<span>${expression.selected_value}</span>&#187;`;
                } else if (expression.or) {
                    if (metadata.field.key === 'session') {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const i in expression.or) {
                            if (expression.or[i]) {
                                const title = expression.or[i].selected_value
                                    .title[this.props.i18nHelper.i18n.currentLanguageCode];
                                value += `&#171;<span>${title}</span>&#187;`;
                                if (parseInt(i, 10) !== expression.or.length - 1) {
                                    value += orTrad;
                                }
                            }
                        }
                    } else if (metadata.field.key === 'exhibitor') {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const i in expression.or) {
                            if (expression.or[i]) {
                                value += `&#171;<span>${expression.or[i].selected_value.name}</span>&#187;`;
                                if (parseInt(i, 10) !== expression.or.length - 1) {
                                    value += orTrad;
                                }
                            }
                        }
                    } else if (metadata.field.key === 'person') {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const i in expression.or) {
                            if (expression.or[i]) {
                                const firstName = expression.or[i].selected_value.firstname;
                                const lastName = expression.or[i].selected_value.lastname;
                                value += `&#171;<span>${firstName} ${lastName}</span>&#187;`;
                                if (parseInt(i, 10) !== expression.or.length - 1) {
                                    value += orTrad;
                                }
                            }
                        }
                    } else if (metadata.field.key === 'package') {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const i in expression.or) {
                            if (expression.or[i]) {
                                value += `&#171;<span>${expression.or[i].selected_value}</span>&#187;`;
                                if (parseInt(i, 10) !== expression.or.length - 1) {
                                    value += orTrad;
                                }
                            }
                        }
                    }
                }
                trads = i18n.translate('resume.entity', {
                    name: this.getColumnName(i18n, metadata),
                    value
                });
            } else if (lowerType === "number" || lowerType === "date") {
                if (expression.and && lowerType === "date") {
                    // On affiche la date comme si il s'agissait d'un opérateur 'eq'
                    const isEq = moment(expression.and[0].val).isSame(expression.and[1].val, 'minute');
                    if (isEq) {
                        trads = i18n.translate('resume.' + lowerType + '.eq', {
                            name: this.getColumnName(i18n, metadata),
                            valueOne: this.numberOrDate(i18n, field.type, expression.and[0].val)
                        });
                    } else {
                        trads = i18n.translate('resume.' + lowerType + '.' + ((expression.and)
                            ? 'between' : expression.op), {
                            name: this.getColumnName(i18n, metadata),
                            valueOne: (expression.and)
                                ? this.numberOrDate(i18n, field.type, expression.and[0].val)
                                : this.numberOrDate(i18n, field.type, expression.val),
                            valueTwo: (expression.and)
                                ? this.numberOrDate(i18n, field.type, expression.and[1].val) : null
                        });
                    }
                } else {
                    const valueOne = (expression.and)
                        ? this.numberOrDate(i18n, field.type, expression.and[0].val)
                        : this.numberOrDate(i18n, field.type, expression.val);

                    const valueTwo = (expression.and)
                        ? this.numberOrDate(i18n, field.type, expression.and[1].val) : null;

                    trads = i18n.translate('resume.' + lowerType + '.' + ((expression.and)
                        ? 'between' : expression.op), {
                        name: this.getColumnName(i18n, metadata),
                        valueOne,
                        valueTwo
                    });
                }
            } else if (lowerType === "bool" || lowerType === "nullablebool") {
                const isNullable = (lowerType === "nullablebool") || (field as any).canBeNull
                    || (field.metadata && field.metadata.canBeNull);
                let val = null;
                if (isNullable) {
                    switch (expression.val) {
                        case null:
                            val = i18n.translate('filter.null');
                            break;
                        case true:
                        case "true":
                            val = i18n.translate('filter.true');
                            break;
                        case false:
                        case "false":
                            val = i18n.translate('filter.false');
                            break;
                        default:
                            break;
                    }
                } else {
                    val = expression.val && expression.val.toString().toLowerCase() === 'true'
                        ? i18n.translate('filter.true') : i18n.translate('filter.false');
                }
                trads = i18n.translate((`${this.props.isMultiStepRegistration ? "multistepregistration." : ""}`)
                    + 'resume.bool.eq', { name: this.getColumnName(i18n, metadata), value: val });
            } else {
                let operator = expression.op;
                let value = expression.val;
                const notContainsOp = expression?.and && expression?.and[0]?.not;

                if (expression.or) {
                    operator = 'isnull';
                } else if (notContainsOp) {
                    operator = 'notcontains';
                } else if (expression.and) {
                    operator = 'isnotnull';
                }

                if (expression.or && expression.or.length
                    && expression.or[0].op === 'fileisnotnull') {
                    operator = 'isnotnull';
                } else if (expression.or && expression.or.length
                    && expression.or[0].op === 'fileisnull') {
                    operator = 'isnull';
                }
                if (!value && expression.val === null) {
                    value = this.props.i18nHelper.translate("resumeexpression.null");
                }

                if (notContainsOp && notContainsOp.val) {
                    value = notContainsOp.val;
                }
                trads = i18n.translate((`${this.props.isMultiStepRegistration ? "multistepregistration." : ""}`)
                    + ('resume.string.') + (operator), { name: this.getColumnName(i18n, metadata), value: value });
            }
        }

        return Promise.resolve(trads);
    }

    render() {
        if (this.state.loading) {
            return <Loader display="ring" message="" />;
        }
        return <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: this.state.trads ? sanitizeHtmlString(this.state.trads) : ''
            }}
            className="expression-filter"
        />;
    }
}

import { withRouter } from 'react-router-dom';
import { LoadingPanel, LoadingState } from '@inwink/loadable';
import { React, AppTextLabel } from '../../../commons';
import { ButtonBar, IButtonBarAction } from '../buttonbar';
import { PageLoader } from '../pageloader';
// export  { IButtonBarAction } from '../buttonbar';
import { trackError } from '../../../api/backoffice/tracking';

import './modalcontent.less';

// export interface confirmObject {
//     state: boolean;
//     promise: any;
//     setConfirm: (state: any) => void;
//     i18n_title: string;
// }

export interface IModalAction extends IButtonBarAction {
}

export interface IModalProps {
    title?: string;
    actions?: IButtonBarAction[];
    isFetching?: boolean;
    onhide?: (res?) => void;
    className?: string;
    sectionClassName?: string;
    injectTitle?: any;
    onCompleted?: (res?: any) => void;
    hideClose?: boolean;
    injectModalTitle?: any;
    // modalRef?: any;
}

interface IModalContentProps extends IModalProps {
    match: any;
}

class ModalContentControl extends React.Component<IModalContentProps, any> {
    unblock: () => void;

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.error("page error", error, info);

        trackError({
            eventid: this.props.match && this.props.match.params && this.props.match.params.eventid,
            customerid: this.props.match && this.props.match.params && this.props.match.params.customerid
        }, error, "page error");
    }

    closeModal = () => {
        // if(this.props.confirm)
        //     this.props.confirm();
        // else
        if (this.props.onhide) {
            this.props.onhide();
        } else if (this.props.onCompleted) {
            this.props.onCompleted(null);
        }
    };

    renderLoader = (data) => {
        return <PageLoader display="ring" data={data} />;
    };

    componentDidMount() {
        // sur mobile on souhaite fermer la modale courante sur le back
        if (typeof window !== "undefined" && window.innerWidth < 768) {
            this.unblock = inwink.history.block((location, action) => {
                if (action === "POP") {
                    setTimeout(() => {
                        this.props.onCompleted();
                    }, 0);
                    return false;
                }

                return true;
            });
        }
    }

    componentWillUnmount() {
        if (this.unblock) {
            this.unblock();
            this.unblock = null;
        }
    }

    render() {
        const classes = ["modalv2"];
        if (this.props.className) {
            classes.push(this.props.className);
        }

        let actions;
        if (this.props.actions && this.props.actions.length) {
            classes.push("withfooter");
            actions = <ButtonBar component="footer" className="footer-modal" actions={this.props.actions} />;
        }

        let title;
        let content;

        if (this.props.injectTitle) {
            title = <div className="title">{React.createElement(this.props.injectTitle, this.props)}</div>;
        } else if (this.props.injectModalTitle && this.props.title) {
            title = <AppTextLabel
                className="title"
                i18n={this.props.title}
                inject={this.props.injectModalTitle}
                component="span"
            />;
        } else {
            title = <AppTextLabel className="title" i18n={this.props.title} component="span" />;
        }

        if (this.state && this.state.hasError) {
            content = <div>Error</div>;
        } else {
            content = React.Children.toArray(this.props.children as any);
        }

        return <div className={classes.join(" ")}>
            <header className="header-modal">
                {title}
                {this.props.hideClose
                    ? null
                    : <button type="button" className="transparent btnclose" onClick={this.closeModal}>
                        <i className="inwink-dialog-cancel" /></button>}
            </header>
            <LoadingPanel
                fullSizeContent
                noInheritance
                component="section"
                loader={this.renderLoader}
                name="modalcontent"
                className={"section-modal" + (this.props.sectionClassName ? " " + this.props.sectionClassName : "")}
            >
                <LoadingState isLoading={this.props.isFetching}>{content}</LoadingState>
            </LoadingPanel>
            {actions}
        </div>;
    }
}

export const ModalContent: React.ComponentClass<IModalProps> = withRouter((ModalContentControl as any)) as any;

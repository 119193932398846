import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { validateField } from '@inwink/entityform/validation';
import type { EntityValidationError } from '@inwink/entityform';
import { AppTextLabel, IPopoverManager, WithPopoverManager, i18NHelperContext } from '@@commons';
import type { ICMSContext } from "@@components/cmscontext";
import type { States } from '@@services/services';
import type { IMenuItems } from '@@components/appmenu.menuitems';
import { onValidationUrlChange } from "../configinputs/urlInputRawWrapper";
import './applinkinput.less';

export interface IAppLinkInputProps {
    i18n: string;
    i18nHelper?: Entities.i18nHelper;
    cmscontext: ICMSContext;
    value: VisualTheme.IAppLink;
    user: States.IAppUserState;
    onChange: (link: VisualTheme.IAppLink, url?: string) => void;
    needGetUrlPreview?: boolean;
    updateValidation?: (isValid: boolean) => void;
    i18nState: States.i18nState;
    content?: VisualTheme.IBlocContentTemplateBase;
    itemTemplate?: any;
    isCmsToolbar?: boolean;
    languages?: string[];
    applicationType?: string;
    contentType?: string;
    allowEmail?: boolean;
    displayOnlyFields?: string[];
    readOnly?: boolean;
    isValid?: boolean;
    isSessionsPoll?: boolean;
    pageTemplate?: Entities.IContentTemplate;
    forceKind?: string;
    displayLinkforPreview?: any | boolean;
    onRefresh?: () => void;
    itemsList?: any[];
    itemActionsToRender?: any;
    getUrlAsPublished?: boolean; // 'preview' by default
    validations?: EntityValidationError[];
    addValidationError?: (fieldKey: string, err: EntityValidationError) => Promise<any>;
    removeValidationError?: (fieldKey: string, err: EntityValidationError, options?: any) => Promise<any>;
    checkValidations?: () => void;
    hiddenFields?: string[];
    noAnchor?: boolean;
    instanceId?: string;
    item?: IMenuItems;
}

export function AppLinkInput(props: IAppLinkInputProps) {
    return <WithPopoverManager>
        {(popovermgr) => <i18NHelperContext.Consumer>
            {(i18nHelper) => {
                return (
                    <AppLinkInputContent
                        readOnly={props.readOnly}
                        noAnchor={props.noAnchor}
                        {...props}
                        popovermgr={popovermgr}
                        i18nHelper={i18nHelper}
                    />
                );
            }}
        </i18NHelperContext.Consumer>}
    </WithPopoverManager>;
}

interface IAppLinkInputContentProps extends IAppLinkInputProps {
    popovermgr: IPopoverManager;
    i18nHelper: Entities.i18nHelper;
}

interface IAppLinkInputContentState {
    kind: string;
    applink: Record<string, any>;
    validations: EntityValidationError[];
    linkForPreview?: string;
    isDynamicUrl?: boolean;
}

interface IOnLinkChangeOption {
    needPrevState?: boolean;
    needGetUrlPreview?: boolean;
    needGetLinkForPreview?: boolean;
    url?: string;
}

class AppLinkInputContent extends React.Component<IAppLinkInputContentProps, IAppLinkInputContentState> {
    getPageUrl?: string;

    canSave?: boolean;

    itemsList: any[];

    contextConfiguration: any = {};

    constructor(props: IAppLinkInputContentProps) {
        super(props);

        const { cmscontext } = props;
        this.contextConfiguration = cmscontext?.getComponentConfiguration?.appLinkInput(props) || {};
        const { pageUrl, itemsList, kind: kindConf } = this.contextConfiguration;
        this.getPageUrl = pageUrl;
        this.itemsList = props.itemsList || itemsList;
        let kind;

        if (props.item?.items?.length) {
            kind = "dropdown";
            this.itemsList.push({
                key: 'dropdown',
                show: true,
                val: (
                    <AppTextLabel
                        i18n="applink.kind.dropdown"
                        defaultText="Dropdown menu"
                        component="option"
                        value="dropdown"
                    />
                )
            });
        } else {
            kind = kindConf;
        }

        this.canSave = true;
        const isDynamicUrl = this.itemsList.find((i) => i.key === kind)?.options?.dynamicUrl;

        this.state = {
            kind,
            applink: props.value || {},
            validations: null,
            linkForPreview: null,
            isDynamicUrl
        };
    }

    getPreview() {
        const { value, cmscontext, onRefresh } = this.props;
        const { kind, applink, isDynamicUrl } = this.state;

        this.contextConfiguration.getLinkForPreview(
            kind,
            applink || value,
            cmscontext.contentTemplates[this.getPageUrl],
            isDynamicUrl
        )
            .then((linkForPreview) => {
                this.setState({ linkForPreview }, () => {
                    if (onRefresh) { onRefresh(); }
                });
            });

        this.checkListPage();
    }

    componentDidMount() {
        this.getPreview();
    }

    componentDidUpdate(prevProps: IAppLinkInputContentProps) {
        if (
            (this.props.instanceId !== prevProps.instanceId)
        ) {
            setTimeout(() => {
                const kind = this.state.kind;
                const isDynamicUrl = this.itemsList.find((i) => i.key === kind)?.options?.dynamicUrl;

                this.setState({
                    applink: this.props.value,
                    kind,
                    validations: null,
                    linkForPreview: null,
                    isDynamicUrl
                }, () => this.getPreview());
            }, 200);
        }

        if (this.props.value !== prevProps.value) {
            this.setState({ applink: this.props.value });
        }
    }

    checkListPage = () => {
        if (this.state.applink) {
            const listPage = this.contextConfiguration.getListPage(this.state.applink.target);

            if (listPage) {
                const applink = Object.assign({}, this.state.applink, {
                    target: listPage
                });
                this.setState(() => {
                    return {applink};
                });
            }
        }
    };

    onMailChange = (key, value) => {
        const validations = validateField({ mail: value },
            { key: "mail", type: "mail", defaultDisplay: null },
            {} as any, null, null, null);

        this.setState({ applink: { mailto: value }, validations: validations }, () => {
            this.props.onChange(this.state.applink);
            this.getPreview();
        });
    };

    onKindChange = (kind: string) => {
        const isDynamicUrl = this.itemsList.find((i) => i.key === kind)?.options?.dynamicUrl;
        const canSave = kind !== 'url';
        const applink: any = this.contextConfiguration.getAppLink(kind) || {};

        this.contextConfiguration.getLinkForPreview(
            kind,
            applink,
            this.props.cmscontext.contentTemplates[this.getPageUrl],
            this.state.isDynamicUrl
        )
            .then((linkForPreview) => {
                this.setState({ linkForPreview, kind, applink, isDynamicUrl });
                this.props.onChange(this.state.applink, linkForPreview || '');
                this.onValidUrlChange(canSave);
            })
            .catch(() => {
                this.setState({ kind, applink, isDynamicUrl });
                this.props.onChange(this.state.applink);
                this.onValidUrlChange(canSave);
            });
    };

    onValidUrlChange = (canSave: boolean) => {
        if (this.canSave !== canSave) {
            if (!this.props.addValidationError && this.props.updateValidation) {
                this.props.updateValidation(canSave);
            } else if (this.props.addValidationError) {
                onValidationUrlChange(
                    canSave,
                    'link',
                    this.props.validations,
                    this.props.addValidationError,
                    this.props.removeValidationError,
                    this.props.checkValidations
                );
            }

            this.canSave = canSave;
        }
    };

    onLinkChange = (applink: any, options: IOnLinkChangeOption = {}) => {
        let nextState = { applink };

        if (options?.needPrevState) {
            nextState = { applink: {...this.state.applink, ...applink } };
        }

        this.setState(nextState, () => {
            this.props.onChange(this.state.applink);

            if (options?.needGetUrlPreview) {
                if (options?.url) {
                    this.props.onChange(this.state.applink, options?.url);
                } else {
                    this.props.onChange(this.state.applink, this.state.applink?.doc?.file?.url);
                }
            } else if (options?.needGetLinkForPreview) {
                this.contextConfiguration.getLinkForPreview(
                    this.state.kind, this.state?.applink
                    || this.props?.value,
                    this.props.cmscontext.contentTemplates[this.getPageUrl],
                    this.state.isDynamicUrl
                ).then((linkForPreview) => {
                    this.setState({ linkForPreview });

                    if (this.props.needGetUrlPreview) {
                        this.props.onChange(this.state.applink, linkForPreview || '');
                    }
                });
            } else {
                this.props.onChange(this.state.applink);
            }
        });
    };

    render() {
        const content: any = this.contextConfiguration.getInputLinkContent({
            props: this.props,
            state: this.state,
            itemsList: this.itemsList,
            onLinkChange: this.onLinkChange, // generic
            onMailChange: this.onMailChange // custom
        });

        const { displayOnlyFields, displayLinkforPreview, validations } = this.props;
        const { linkForPreview } = this.state;

        // We don't want have red border on select input when URL errors:
        const haveInvalidUrl = validations?.find((validation) => validation?.error === 'invalidurl');
        const style: any = {};

        if (haveInvalidUrl) { style.borderColor = '#ddd'; }

        return (
            <div className="fieldbloc applinkcontrol">
                <AppTextLabel component="label" i18n={this.props.i18n} />
                <div>
                    <select
                        style={style}
                        value={this.state.kind}
                        onChange={(arg: any) => {
                            if (!this.props.readOnly) { this.onKindChange(arg.currentTarget.value); }
                        }}
                        disabled={!!this.props.forceKind || this.state.kind === "dropdown"}
                    >
                        {
                            this.itemsList.map((item) => {
                                if (item.show && (!displayOnlyFields || displayOnlyFields?.indexOf(item.key) !== -1)) {
                                    return item.val;
                                }
                                return null;
                            })
                        }
                    </select>
                    {content}
                    {
                        (linkForPreview && displayLinkforPreview) && (
                            !displayLinkforPreview?.displayAsSpan ? (
                                <a
                                    className="link-preview"
                                    href={linkForPreview}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {linkForPreview}
                                </a>
                            ) : (
                                <span className="link-preview">{linkForPreview}</span>
                            )
                        )
                    }
                </div>
            </div>
        );
    }
}

import type { IEntityView } from '@inwink/apibase';
import { expressionBuilder } from '@inwink/expressions/expressionbuilder';
import { IInWinkAdminEntityV3DataSource } from './datasource';

export function userPreferences(userPreferenceDatasource: IInWinkAdminEntityV3DataSource<any>, entityName: string) {
    return (ds) => {
        const datasource = ds;
        datasource.getViews = (viewEntityName?: string) => {
            return userPreferenceDatasource.query({
                filters: expressionBuilder((e) => e
                    .WithString("entityname")
                    .Equals(viewEntityName ?? entityName)),
            }).then((res) => {
                return res.data;
            });
        };
        datasource.setView = (_view: IEntityView) => {
            const view = _view;
            // En V2 on stockait les filtres dans 'entityViewContent' en v3 dans 'content'
            if (view.entityViewContent && !view.content) {
                view.content = view.entityViewContent;
            }
            return userPreferenceDatasource.create(view);
        };
        datasource.updateView = (view: IEntityView) => {
            return userPreferenceDatasource.update(view);
        };
        datasource.deleteView = (entityviewid) => {
            return userPreferenceDatasource.delete([entityviewid]);
        };
    };
}

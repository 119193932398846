import { React, AppLabel, AppTextLabel } from '../../../../commons';
import type { IExpressionProps } from "../definitions";

export class ExpressionFile extends React.Component<IExpressionProps, any> {
    options = ['fileisnull', 'fileisnotnull'];

    constructor(props: IExpressionProps) {
        super(props);
        this.state = { value: '' };
    }

    componentDidMount() {
        this.setValue();
        this.props.setAllowSave(true);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.expressions) !== JSON.stringify(prevProps.expressions)) {
            this.setValue();
        }
    }

    setValue = () => {
        let value = '';
        if (this.props.expressions) {
            if (this.props.expressions.or && this.props.expressions.or.length) {
                if (this.props.expressions.or[0].op === 'fileisnotnull' && !this.props.expressions.or[0].val) {
                    value = 'fileisnotnull';
                } else if (this.props.expressions.or[0].op === 'fileisnull' && !this.props.expressions.or[0].val) {
                    value = 'fileisnull';
                }
            } else if (this.props.expressions.op === 'fileisnotnull' || this.props.expressions.op === 'fileisnull') {
                value = this.props.expressions.op;
            }
        }
        this.setState({ value });
    };

    handleChange = (arg, type) => {
        const _v = arg.target.value;
        if (type === 'operator') {
            let expressions;
            if (!_v) { // do nothing
            } else if (_v === 'fileisnull') { // Yep, it's reversed. No idea why
                expressions = {
                    name: this.props.fieldItem.key,
                    op: 'fileisnull',
                    val: null
                };
            } else if (_v === 'fileisnotnull') {
                expressions = {
                    name: this.props.fieldItem.key,
                    op: 'fileisnotnull',
                    val: null
                };
            }

            this.props.setExpression(expressions);
        }
    };

    onKeyPress = (e) => {
        if (e.charCode === 13) {
            this.props.save();
        }
    };

    renderInline = () => {
        return <div>
            <select value={this.state.value} onChange={(arg) => this.handleChange(arg, 'operator')}>
                <option value="" />
                {
                    this.options.map((option, i) => {
                        return <AppTextLabel key={i} component="option" value={option} i18n={"operators." + option} />;
                    })
                }
            </select>
        </div>;
    };

    render() {
        if (this.props.inline) {
            return this.renderInline();
        }

        return <div className="filter-file filter-type">
            <header>
                <span><AppLabel i18n="filter.indication" /></span>
                <select value={this.state.value} onChange={(arg) => this.handleChange(arg, 'operator')}>
                    <option value="" />
                    {
                        this.options.map((option, i) => {
                            return <AppTextLabel key={i} component="option" value={option} i18n={"operators." + option} />;
                        })
                    }
                </select>
            </header>
        </div>;
    }
}

import type { ICMSContext } from 'components/cmscontext';
import { toastError, toastInfo, toastSuccess } from '../../../../commons';
import type {
    IEntityPageActions, IEntityPageConfiguration, EntityPageAction, IEntityScreenHelpers,
    EntityPageActionCallBackArg, EntityPageRunMode, IEntityViewsState
} from '../entitypagebase.props';
import { smartconfirmModal } from "../../../ui/modals/confirmmodal";

export function getMoreActions(
    cmscontext: ICMSContext,
    entityRights: InWinkBO.IEntityRights,
    viewsState: IEntityViewsState,
    entityconfiguration: IEntityPageConfiguration,
    entityactions: IEntityPageActions,
    helpers: IEntityScreenHelpers,
    runMode: EntityPageRunMode
) {
    let moreactions: EntityPageAction[] = [];

    if (runMode && (runMode !== "entitypage" && runMode !== "entitydetailtab")) {
        return null;
    }

    if (entityactions && entityactions.actions && entityactions.actions.length) {
        moreactions = [...entityactions.actions];
    }

    // le disableMassUpdate ne change pas via une action utilisateur donc je l'ajoute dès le constructor
    const { detailConfig } = entityconfiguration;

    if (detailConfig && !detailConfig.disableMassUpdate) {
        moreactions.push({
            key: "updateall",
            icon: "inwink-send",
            label: "actions.updateallwithfilters",
            show: (ctx) => ctx.entityRights.canUpdate,
            scope: { currentView: true },
            callback: (_, entityargs) => {
                return helpers.updateall(_, entityargs).then(() => null).catch(() => {
                    toastError(entityargs.context.i18n, 'toast.'
                        + entityconfiguration.entityName.toLowerCase() + '.updateall.error');
                });
            }
        },
        {
            key: "updateselection",
            icon: "inwink-send",
            label: "actions.updateselection",
            show: (ctx) => ctx.entityRights.canUpdate,
            scope: { selection: true },
            callback: helpers.updateselection,
            toast: {
                success: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.updateselection.success',
                error: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.updateselection.error'
            }
        });
    }

    if (detailConfig?.enableMailAll && entityactions?.sendMailActionCb) {
        moreactions.push({
            key: "mailallwithfilters",
            icon: "inwink-send",
            label: "actions.mailallwithfilters",
            scope: { currentView: true },
            show: (ctx) => ctx.entityRights.canUpdate,
            callback: entityactions.sendMailActionCb,
            toast: {
                success: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.mailall.success',
                error: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.mailall.error'
            }
        });
    }
    if (detailConfig?.enableMailSelected && entityactions?.sendMailActionCb) {
        moreactions.push({
            key: "mailselected",
            icon: "inwink-send",
            label: "actions.mailselected",
            scope: { selection: true },
            callback: entityactions.sendMailActionCb,
            show: (ctx) => ctx.entityRights.canUpdate,
            toast: {
                success: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.mailselected.success',
                error: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.mailselected.error'
            }
        });
    }

    if (detailConfig?.enableNotifAll && entityactions?.sendNotificationActionCb) {
        moreactions.push({
            key: "notifallwithfilters",
            icon: "inwink-send",
            label: "actions.notifallwithfilters",
            show: (ctx) => ctx.entityRights.canUpdate,
            callback: entityactions.sendNotificationActionCb,
            scope: { currentView: true },
            toast: {
                // Not used
                success: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.notifall.success',
                error: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.notifall.error'
            }
        });
    }
    if (detailConfig?.enableNotifSelected && entityactions?.sendNotificationActionCb) {
        moreactions.push({
            key: "notifselected",
            icon: "inwink-send",
            label: "actions.notifselected",
            callback: entityactions.sendNotificationActionCb,
            show: (ctx) => ctx.entityRights.canUpdate,
            scope: { selection: true },
            toast: {
                // Not used
                success: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.notifselected.success',
                error: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.notifselected.error'
            }
        });
    }

    if (detailConfig && !detailConfig.disableDelete && !detailConfig.disableMassDelete) {
        moreactions.push(
            {
                key: "deleteview",
                icon: "inwink-trash",
                label: "actions.deleteallview",
                show: (ctx) => ctx.entityRights.canDelete,
                scope: { currentView: true },
                callback: (_, entityargs) => {
                    const deleteCall = (resolve, reject) => {
                        let queryOptions = {};
                        if (entityargs.viewState.queryExpression) {
                            queryOptions = {
                                expression: {and: [entityargs.viewState.queryExpression]}
                            };
                        }

                        return Promise.all([
                            entityconfiguration.datasource.massDelete(queryOptions),
                            new Promise((rd) => setTimeout(rd, 2000))
                        ]).then(() => {
                            toastInfo(entityargs.context.i18n, `toast.massdelete.inprogress`);
                            resolve();
                        }).catch(() => {
                            toastInfo(entityargs.props.i18n, `toast.massdelete.error`);
                            reject(null);
                        });
                    };
                    return new Promise((resolve, reject) => {
                        let dangerZone;
                        if (!entityargs.viewState.queryExpression) {
                            dangerZone = entityargs.context.i18nHelper.translate('confirm.danger.delete');
                        }
                        return smartconfirmModal(entityargs.popovermgr, {
                            title: "modal.confirm.exit",
                            message: "modal.confirm.smart.remove",
                            messageinject: {
                                rowCount: entityargs.viewState.rowCount
                            },
                            dangerZone
                        }).then((confirmation) => {
                            if (confirmation) {
                                return deleteCall(resolve, reject);
                            }
                            resolve();
                        });
                    });
                }
            }
        );
    }

    return moreactions; // .filter(moreActionFilter);
}

export function getNavActions(
    cmscontext: ICMSContext,
    entityRights: InWinkBO.IEntityRights,
    viewsState: IEntityViewsState,
    entityconfiguration: IEntityPageConfiguration,
    entityactions: IEntityPageActions,
    helpers: IEntityScreenHelpers,
    runMode: EntityPageRunMode
) {
    let navactions: EntityPageAction[] = [];

    if (runMode && runMode !== "entitypage") {
        return null;
    }

    if (entityactions && entityactions.navactions && entityactions.navactions.length) {
        navactions = [...entityactions.navactions];
    }

    return navactions;
}

export function getCustomActions(
    cmscontext: ICMSContext,
    entityRights: InWinkBO.IEntityRights,
    viewsState: IEntityViewsState,
    entityconfiguration: IEntityPageConfiguration,
    entityactions: IEntityPageActions,
    helpers: IEntityScreenHelpers,
    runMode: EntityPageRunMode
) {
    let customactions: EntityPageAction[] = [];

    if (runMode && runMode !== "entitypage") {
        return null;
    }

    if (entityactions && entityactions.customactions && entityactions.customactions.length) {
        customactions = [...entityactions.customactions];
    }

    return customactions;
}

export function getPrimaryActions(
    cmscontext: ICMSContext,
    entityRights: InWinkBO.IEntityRights,
    viewsState: IEntityViewsState,
    entityconfiguration: IEntityPageConfiguration,
    entityactions: IEntityPageActions,
    helpers: IEntityScreenHelpers,
    runMode: EntityPageRunMode
) {
    const _entityPageActions: EntityPageAction[] = [];
    let entityPageMode = false;

    if (runMode && runMode === "entitypage") {
        entityPageMode = true;
    }

    const { detailConfig } = entityconfiguration;
    if (entityPageMode && entityconfiguration && entityconfiguration.grid && entityconfiguration.grid.backAction) {
        _entityPageActions.push({
            key: "back",
            label: entityconfiguration.grid.backButtonLabel || "actions.back",
            scope: {},
            icon: "inwink-back",
            callback: () => {
                entityconfiguration.grid.backAction();
            }
        });
    }

    if (entityRights.canCreate && detailConfig && !detailConfig.disableCreate && runMode !== "dataimport") {
        if (entityconfiguration.entityactions && entityconfiguration.entityactions.addNew) {
            _entityPageActions.push(Object.assign({}, entityconfiguration.entityactions.addNew, {
                icon: "inwink-add-thin"
            }));
        } else {
            _entityPageActions.push({
                key: "add",
                icon: "inwink-add-thin",
                label: "actions.add",
                scope: {},
                callback: (arg: React.MouseEvent, entityargs: EntityPageActionCallBackArg) => {
                    arg.preventDefault();
                    arg.stopPropagation();
                    return helpers.openEntityDetail(null, true, false, entityargs);
                },
                toast: {
                    success: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.create.success',
                    error: 'toast.' + entityconfiguration.entityName.toLowerCase() + '.create.error'
                }
            });
        }
    }

    if (entityRights.canDelete && detailConfig && !detailConfig.disableDelete && runMode !== "dataimport") {
        if (entityconfiguration.entityactions && entityconfiguration.entityactions.delete) {
            _entityPageActions.push(Object.assign({}, entityconfiguration.entityactions.delete, {
                icon: "inwink-trash",
            }));
        } else {
            _entityPageActions.push({
                key: "delete",
                icon: "inwink-trash",
                scope: { selection: true },
                label: "actions.delete",
                callback: (arg: React.MouseEvent, entityargs: EntityPageActionCallBackArg) => {
                    let expression = null;
                    if (entityargs.selection && entityargs.selection.length) {
                        let matchKey = ["id"];
                        if (entityargs.entityconfiguration.idFields) {
                            matchKey = entityargs.entityconfiguration.idFields;
                        }
                        if (entityargs.selection.length === 1) {
                            if (matchKey.length === 1) {
                                expression = {
                                    name: matchKey[0],
                                    op: "eq",
                                    val: entityargs.selection[0][matchKey[0]]
                                };
                            } else {
                                expression = {and: [{}]};
                                expression = {
                                    and: matchKey.map((key) => ({
                                        name: key,
                                        op: "eq",
                                        val: entityargs.selection[0][key]
                                    }))
                                };
                            }
                        } else if (matchKey.length === 1) {
                            expression = {
                                or: entityargs.selection.map((s) => {
                                    return {
                                        name: matchKey[0],
                                        op: "eq",
                                        val: s[matchKey[0]]
                                    };
                                })
                            };
                        } else {
                            expression = {
                                or: entityargs.selection.map((s) => {
                                    return {
                                        and: matchKey.map((key) => ({
                                            name: key,
                                            op: "eq",
                                            val: s[key]
                                        }))
                                    };
                                })
                            };
                        }

                        return entityconfiguration.datasource.count({ expression }).then((resCount) => {
                            if (resCount) {
                                const smartOptions = {
                                    title: "modal.confirm.exit",
                                    message: "modal.confirm.smart.remove",
                                    messageinject: {
                                        rowCount: resCount
                                    }
                                };

                                const deleteCall = (resolve, reject) => {
                                    let promise;
                                    if (entityargs.entityconfiguration?.idFields) {
                                        const payload = entityargs.selection.map((entity) => {
                                            const _entity = {entity: {}};
                                            entityargs.entityconfiguration.idFields.forEach((id) => {
                                                _entity.entity[id] = entity[id];
                                            });
                                            return _entity;
                                        });
                                        promise = entityconfiguration.datasource.delete(null, false, payload);
                                    } else {
                                        promise = entityconfiguration.datasource.delete(entityargs.selection
                                            .map((entity) => entity.id));
                                    }

                                    return promise
                                        .then((res) => {
                                            if (res) {
                                                if (entityargs.selection.length === 1) {
                                                    toastSuccess(entityargs.context.i18n, `toast.entitypage.delete.success`);
                                                    entityargs.tools.refresh();
                                                }
                                                entityargs.tools.setSelection([]);
                                                resolve(true);
                                            }
                                        })
                                        .catch(() => {
                                            toastError(entityargs.context.i18n,
                                                `toast.${entityconfiguration.entityName.toLowerCase()}.delete.error`);
                                            reject(null);
                                        });
                                };
                                return new Promise((resolve, reject) => {
                                    return smartconfirmModal(entityargs.popovermgr, smartOptions)
                                        .then((confirmation) => {
                                            if (confirmation) {
                                                return detailConfig.configuration().then((resConfig) => {
                                                    if (resConfig && resConfig.onBeforeDelete) {
                                                        return resConfig.onBeforeDelete(entityargs.selection,
                                                            entityargs.context.cmscontext.relatedTo,
                                                            entityconfiguration.datasource)
                                                            .then((res) => {
                                                                if (res) {
                                                                    return deleteCall(resolve, reject);
                                                                }
                                                                return resolve();
                                                            })
                                                            .catch((err) => {
                                                                const en = entityconfiguration.entityName.toLowerCase();
                                                                toastError(entityargs.context.i18n,
                                                                    `toast.${en}.delete.error`);
                                                                return reject(err);
                                                            });
                                                    }
                                                    return deleteCall(resolve, reject);
                                                });
                                            }
                                            resolve();
                                        });
                                });
                            }
                        }, (err) => {
                            console.error(`error loading ${entityconfiguration.entityName} selection ${err}`);
                        });
                    }
                }
            });
        }
    }

    return _entityPageActions;
}

import { React, AppTextLabel } from '../../../commons';
import { AppTooltip } from "../apptooltip";

import { OnOffToggle } from '@inwink/toggles';
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';

interface IToggleInputBaseProps {
    canEditTrad: boolean;
    i18n: string;
    readonly?: boolean;
}

interface IToggleInputProps extends IToggleInputBaseProps {
    value: boolean;
    onChange: (val: boolean) => void;
    hideTooltip?: boolean;
}

export function ToggleInput(props: IToggleInputProps) {
    return <div className="fieldbloc">
        <div className="togglefield">
            <div className="label">
                <label>
                    <AppTextLabel i18n={props.i18n} />
                    {props.hideTooltip ? null : <AppTooltip i18n={props.i18n + ".desc"} canEditTrad={props.canEditTrad} />}
                </label>
            </div>
            <OnOffToggle value={props.value} onChange={props.onChange} readOnly={props.readonly} />
        </div>
    </div>;

}

export interface IToggleInputPropertyProps extends IToggleInputBaseProps {
    path: string;
    data: any;
    onChange: (data: any) => void;
}

export function ToggleInputProperty(props: IToggleInputPropertyProps) {
    const toggleChanged = (checked: boolean) => {
        let result = immutable.set(props.data, props.path, checked);
        props.onChange(result);
    };

    const val = getPropertyValue(props.data, props.path);

    return <ToggleInput
        i18n={props.i18n}
        value={val}
        canEditTrad={props.canEditTrad}
        onChange={toggleChanged}
    />;
}

/* eslint-disable max-classes-per-file */
import { React, AppTextLabel, bindActionCreators, connectwith, getPreviewUrl } from '../../commons';
import type { IAppShellHeaderPartProps } from './appshellheader';
import { actions as i18nActions } from '../../services/i18nservice';
import { setMappings } from '../../api/backoffice/bohelp';
import { AppShellHeaderActionButton } from './appshellheaderactionbutton';

interface IAppShellHelpProps extends IAppShellHeaderPartProps {
    i18nActions?: typeof i18nActions;
    afterClick: () => void;
    parent: any;
    display: "simple" | "advanced";
}

@connectwith(null, (dispatch) => ({ i18nActions: bindActionCreators(i18nActions, dispatch) }))
export class AppShellHelp extends React.Component<IAppShellHelpProps, any> {
    openTools = (arg: React.MouseEvent<any>) => {
        this.props.popovermgr.popoverPortal(arg.currentTarget, AppShellHelpTools, this.props, "bohelptoolspopover");
        this.props.afterClick();
    };

    openHelp = () => {
        const targetPage = this.props.i18n.helpmappings && this.props.featurecode
            && this.props.i18n.helpmappings[this.props.featurecode];
        let rooturl = inwink.config.onlineHelp;

        if (targetPage) {
            rooturl = rooturl + targetPage;
        }

        const params = [`bourl=${encodeURIComponent(window.location.href)}`];

        if (this.props.event?.eventdetail) {
            params.push(`purl=${encodeURIComponent(getPreviewUrl(this.props.event.eventdetail))}`);
        } else {
            params.push(`purl=${encodeURIComponent(`${inwink.config.companionPreviewUrl}/`)}`);
        }

        if (this.props.event?.eventdetail?.title) {
            params.push(`e=${encodeURIComponent(this.props.event.eventdetail.title)}`);
        }

        if (this.props.customer?.detail?.name) {
            params.push(`c=${encodeURIComponent(this.props.customer.detail.name)}`);
        }

        if (this.props.community?.detail?.name) {
            params.push(`cm=${encodeURIComponent(this.props.community.detail.name)}`);
        }

        window.open(rooturl + `?${params.join("&")}`, "_blank");

        this.props.afterClick();
    };

    render() {
        const targetPage = this.props.i18n?.helpmappings && this.props.featurecode
            && this.props.i18n.helpmappings[this.props.featurecode];

        return this.props.display === 'simple' ? <button
            type="button"
            onClick={this.props.user?.editTrads ? this.openTools : this.openHelp}
            className={"appshellhelp simple " + (!targetPage ? "nohelppage" : "")}
        ><AppTextLabel i18n="appheaderactions.showhelp" /></button> : <AppShellHeaderActionButton
            icon="inwink-help"
            title="appheaderactions.showhelp"
            className={"appshellhelp " + (!targetPage ? "nohelppage" : "")}
            onClick={this.props.user?.editTrads ? this.openTools : this.openHelp}
        />;
    }
}

interface IAppShellHelpToolsProps extends IAppShellHelpProps {
    onCompleted: (res?) => void;
}

class AppShellHelpTools extends React.Component<IAppShellHelpToolsProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            helppage: this.props.i18n.helpmappings && this.props.featurecode
                && this.props.i18n.helpmappings[this.props.featurecode]
        };
    }

    saveHelpPageMapping = () => {
        setMappings(this.props.i18n.currentLanguageCode, this.props.featurecode, this.state.helppage).then(() => {
            return this.props.i18nActions.syncCurrent();
        }).then(() => {
            this.props.onCompleted();
        });
    };

    openHelp = () => {
        let url = "/docs";
        if (this.state.helppage) {
            if (this.state.helppage && this.state.helppage[0] === "/") {
                url += this.state.helppage;
            } else { url += "/" + this.state.helppage; }
            window.open(url, "_blank");
        }
    };

    render() {
        return <div className="bohelp-tools formpage">
            <div>
                <AppTextLabel i18n="bohelp.pageurl" component="label" />
                <input
                    type="text"
                    value={this.state.helppage || ""}
                    onChange={(arg) => this.setState({ helppage: arg.currentTarget.value })}
                />
            </div>
            <div className="actions">
                <button
                    type="button"
                    onClick={this.openHelp}
                ><AppTextLabel i18n="actions.open" /></button>
                <button
                    type="button"
                    onClick={this.saveHelpPageMapping}
                ><AppTextLabel i18n="actions.save" /></button>
            </div>
        </div>;
    }
}

import { defaultPostHeaderForJsonData } from '@inwink/apibase';
import {
    getBackOfficeProxy
} from './index';

export function getMappings(lng, lastupdate?) {
    let url = "bohelp/mappings/" + lng;
    if (lastupdate) {
        url = url + "?lastupdated=" + lastupdate;
    }
    return getBackOfficeProxy().getJson(url);
}

export function setMappings(lng, feature, helppage) {
    const url = "bohelp/mappings/" + lng;
    return getBackOfficeProxy().postJson(url, JSON.stringify({
        featureCode: feature,
        pageUrl: helppage
    }), defaultPostHeaderForJsonData);
}

import { cleanLabels } from '@inwink/utils/methods';
import type { Entities } from '@inwink/entities/entities';
import { getPropertyValue } from '@inwink/utils';
import * as moment from 'moment';
import type { EntityGrid } from './index';
import type { IEntityGridColumn, ISlickGridColumn, ICellRendererCallback, ICustomCell } from '../definitions';
import { isNullableEntityField } from '../../entityhelpers.nullablefields';

import type { ICMSContext } from '../../../cmscontext';
import type { IEntityPageConfiguration } from '../entitypagebase.props';

function getAllValuesListColumn(_column: ISlickGridColumn): Entities.IEntityFieldValue[] {
    const column = _column;
    if (column.allValuesList) {
        return column.allValuesList;
    }

    const proc = (valuesList: Entities.IEntityFieldValue[]): Entities.IEntityFieldValue[] => {
        if (valuesList) {
            let all = [];
            valuesList.forEach((val) => {
                if (val) {
                    if (val.key) {
                        all.push(val);
                    } else {
                        all = all.concat(proc(val.valuesList));
                    }
                }
            });

            return all.filter((v) => v);
        }

        return null;
    };
    column.allValuesList = proc(column.valuesList);
    return column.allValuesList;
}

export function getColValue(rowvalue, columnDef: ISlickGridColumn, dataContext) {
    if (rowvalue) {
        return rowvalue;
    }

    if (columnDef.field) {
        return getPropertyValue(dataContext, columnDef.field);
    }

    return "";
}

function localizableDisplay(field: Entities.IEntityFieldTemplateV3, entitycolumn: IEntityGridColumn, cmscontext: ICMSContext) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);

        const eventLanguages: string[] = cmscontext.languages;
        const eventMainLanguage: string = cmscontext.configuration?.global?.defaultLanguage;
        const boMainLanguage: string = cmscontext.displayLanguage;

        let lang = 'en';
        if (eventLanguages && eventLanguages.indexOf(boMainLanguage) !== -1) {
            lang = boMainLanguage;
        } else if (eventMainLanguage) {
            lang = eventMainLanguage;
        }

        const localized = val && (val[lang] || val.en || val.fr);
        const content = cleanLabels(localized) || "";
        if (entitycolumn && entitycolumn.openChildEntity) {
            return `<div class="entity">${content}</div>`;
        } else if (entitycolumn && entitycolumn.allowOpenEntity) {
            return `<div class="clickable">${content}</div>`;
        }
        return content;
    };
}

function defaultDisplay(field: Entities.IEntityFieldTemplateV3, entitycolumn: IEntityGridColumn) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        let content = cleanLabels(getColValue(rowvalue, columnDef, dataContext));
        if (content === undefined || content === null) {
            content = '';
        }
        if (entitycolumn && entitycolumn.openChildEntity) {
            return `<div class="entity">${content}</div>`;
        } else if (entitycolumn && entitycolumn.allowOpenEntity) {
            return `<div class="clickable">${content}</div>`;
        }
        return content;
    };
}

function selectlistDisplay(key, field: Entities.IEntityFieldTemplateV3, entityName: string, i18nHelper: Entities.i18nHelper) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);
        let txt = "";
        if (field.metadata && field.metadata.isStatic) {
            const _trad = i18nHelper.translate(`${entityName}.${field.key}.${val}`.toLowerCase());
            txt = _trad;
            if (!txt) {
                const trad = i18nHelper.translate(`${key}.${val}`.toLowerCase());
                if (trad) {
                    txt = trad;
                } else { txt = val; }
            }
        } else {
            const valuesList = getAllValuesListColumn(columnDef);
            if (valuesList) {
                const value = valuesList.filter((vl) => vl.key === val)[0];
                if (value && value.labels) {
                    txt = (value && i18nHelper.translateBag(value.labels)) || "";
                } else {
                    txt = rowvalue;
                }
            }
        }
        return cleanLabels(txt);
    };
}

function multiselectlistDisplay(field: Entities.IEntityFieldTemplateV3, entityName: string, i18nHelper: Entities.i18nHelper) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);

        let txt = "";
        if (val && val.length) {
            if (field.metadata && field.metadata.isStatic) {
                txt = val.map((key) => {
                    const trad = `${entityName}.${field.key}.${key}`.toLowerCase();
                    return i18nHelper.translate(trad);
                }).filter((t) => t).join(", ");
            } else {
                const valuesList = getAllValuesListColumn(columnDef);
                if (valuesList) {
                    txt = val.map((v) => {
                        const value = valuesList.filter((vl) => vl.key === v)[0];
                        if (value && value.labels) {
                            return (value && i18nHelper.translateBag(value.labels)) || v;
                        }
                        return v;
                    }).join(", ");
                } else {
                    txt = val.join(", ");
                }
            }
        }
        return cleanLabels(txt);
    };
}

function dateDisplay(field: Entities.IEntityFieldTemplateV3, userLng: string) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);
        if (val && moment(val).isValid()) {
            return moment(val + (columnDef.id && columnDef.id.toLowerCase() === 'validfrom' ? '+00:00' : ''))
                .locale(userLng).format('lll');
        }

        return "";
    };
}

function nestedEntityDisplay(field: Entities.IEntityFieldTemplateV3, i18nHelper: Entities.i18nHelper) {
    return (row, cell, rowvalue) => {
        const length = rowvalue?.length || 0;
        return length === 0 ? `<span>${i18nHelper.translate('entityform.nestentity.collection.items.empty')}</span>`
            : `<span>${i18nHelper.translate('entityform.nestentity.collection.items', { itemsCount: length })}</span>`;
    };
}

function fileDisplay() {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);
        return (val && val.url)
            ? '<span class="btn-cell"><a><i class="inwink-import"></i></a></span>'
            : '';
    };
}

function localizableFileDisplay(field: Entities.IEntityFieldTemplateV3, cmscontext: ICMSContext) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);
        const localized = val && val[cmscontext.displayLanguage];
        return (localized && localized.url)
            ? '<span class="btn-cell"><a><i class="inwink-import"></i></a></span>'
            : '';
    };
}

function documentDisplay() {
    return () => {
        return '<span class="btn-cell"><a><i class="inwink-file"></i></a></span>';
    };
}

function pictureDisplay() {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);
        if (!val) {
            return '';
        }
        const url = val;
        // eslint-disable-next-line max-len
        return `<a href="${cleanLabels(val.url)}" target="_blank"><div class="img" style="background-image: url(${url}?t=${new Date().getTime()})" /></div></a>`;
    };
}

function imageDisplay(field: Entities.IEntityFieldTemplateV3) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);
        if (!val) {
            return '';
        }
        if (field.file.isPublicFile === true) {
            const url = val.thumbUrl ? cleanLabels(val.thumbUrl) : cleanLabels(val.url);
            // eslint-disable-next-line max-len
            return `<a href="${cleanLabels(val.url)}" target="_blank"><div class="img" style="background-image: url(${url}?t=${new Date().getTime()})" /></div></a>`;
        }
        return '<span class="btn-cell"><a><i class="inwink-file"></i></a></span>';
    };
}

function boolDisplay(field: Entities.IEntityFieldTemplateV3, entityname: string) {
    return (row, cell, rowvalue, columnDef: ISlickGridColumn, dataContext) => {
        const val = getColValue(rowvalue, columnDef, dataContext);
        const isNullableBoolean = isNullableEntityField(columnDef.id, entityname);
        if (isNullableBoolean && (val === undefined || val === null)) {
            return '<div class="null bool"></div>';
        }
        return (!val) ? '<div class="false bool"></div>' : '<div class="true bool"></div>';
    };
}

function getEntityDisplay(field: Entities.IEntityFieldTemplateV3, key: string, props: ICellFormatterArguments) {
    const entityName = field.value.toLowerCase();
    if (field.type === "Entity") {
        if (props.entityConfs && props.entityConfs[entityName] && props.entityConfs[entityName].cellRenderer
            && props.entityConfs[entityName].cellRenderer.entity) {
            return (row: number, cell: number, _rowValue: any, columnDef, dataContext: any) => {
                let rowValue = _rowValue;
                if (!rowValue) {
                    rowValue = getColValue(rowValue, columnDef, dataContext);
                }
                const val = props.entityConfs[entityName].cellRenderer.entity
                    .renderer(rowValue, props.cmscontext, props.i18nHelper, null);
                return `<div class="entity ${!rowValue ? " empty" : ""}">${val}</div>`;
            };
        }
    }

    if (field.type === "Entities") {
        if (props.entityConfs && props.entityConfs[entityName] && props.entityConfs[entityName].cellRenderer
            && props.entityConfs[entityName].cellRenderer.entities) {
            return (row: number, cell: number, _rowValue: any, columnDef, dataContext: any) => {
                let rowValue = _rowValue;
                if (!rowValue) {
                    rowValue = getColValue(rowValue, columnDef, dataContext);
                }
                const val = props.entityConfs[entityName].cellRenderer.entities
                    .renderer(rowValue, props.cmscontext, props.i18nHelper, null);
                return val ? `<div class="entities ${!rowValue ? " empty" : ""}">${val}</div>` : '';
            };
        }
    }

    return () => {
        return '';
    };
}

export interface ICellFormatterArguments {
    i18nHelper: Entities.i18nHelper;
    cmscontext: ICMSContext;
    entityConfs: Record<string, IEntityPageConfiguration>;
}

export function getCellFormatter(
    key: string,
    field: Entities.IEntityFieldTemplateV3,
    entitycolumn: IEntityGridColumn,
    entityname: string,
    props: ICellFormatterArguments
)
    : (row, cell, value, columnDef, dataContext) => any {
    const _key = key.toLowerCase();
    const type = field.type.toLowerCase();

    switch (type) {
        case "date":
            return dateDisplay(field, props.i18nHelper.i18n.currentLanguageCode);
        case "file":
            if (field.metadata && field.metadata.isLocalizable) {
                return localizableFileDisplay(field, props.cmscontext);
            }
            return fileDisplay();
        case "document":
            return documentDisplay();
        case "image":
            return imageDisplay(field);
        case "picture":
            return pictureDisplay();
        case "multiselectlist":
            return multiselectlistDisplay(field, entityname, props.i18nHelper);
        case "selectlist":
            return selectlistDisplay(_key, field, entityname, props.i18nHelper);
        case "bool":
        case "nullablebool":
            return boolDisplay(field, entityname);
        case "text":
        case "guid":
        case "multilinetext": {
            if (field.metadata && field.metadata.isLocalizable) {
                return localizableDisplay(field, entitycolumn, props.cmscontext);
            }
            return defaultDisplay(field, entitycolumn);
        }
        case "entity":
        case "entities": {
            return getEntityDisplay(field, _key, props);
        }
        case "nestedentity":
            return nestedEntityDisplay(field, props.i18nHelper);
        default:
            return defaultDisplay(field, entitycolumn);
    }
}

export class EntityGridDataFormat {
    constructor(public entitygrid: EntityGrid) {
    }

    getCellFormatter(key: string, field: Entities.IEntityFieldTemplateV3, entitycolumn: IEntityGridColumn):
    (row, cell, value, columnDef, dataContext) => any {
        const custom = entitycolumn.cellProcessor; // conf.grid && conf.grid.customColumn && conf.grid.customColumn[_key];
        if (custom) {
            if (typeof custom === "function") {
                const callback = custom as ICellRendererCallback;
                return (row, cell, value, columnDef, dataContext) => {
                    const realValue = value || (columnDef && getPropertyValue(dataContext, columnDef.key));
                    return callback(row, cell, realValue, columnDef, dataContext,
                        this.entitygrid.props.entityConfigurationArgs, entitycolumn.customData);
                };
            } if (typeof custom === "object") {
                const cellProcessor = custom as ICustomCell;
                if (cellProcessor.renderer) {
                    return (row, cell, value, columnDef, dataContext) => {
                        const realValue = value || (columnDef && getPropertyValue(dataContext, columnDef.key));
                        return cellProcessor.renderer(row, cell, realValue, columnDef, dataContext,
                            this.entitygrid.props.entityConfigurationArgs, entitycolumn.customData);
                    };
                }
            }
        }

        return getCellFormatter(
            key,
            field,
            entitycolumn,
            this.entitygrid.props.entityconfiguration.entityName,
            this.entitygrid.props
        );
    }
}

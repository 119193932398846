import * as assign from 'lodash/assign';
import { logging } from '@inwink/logging';
import { translateFromLabel } from '@inwink/i18n';
import { Entities } from '@inwink/entities/entities';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { realtimeActions } from './realtimeservice';
import { rightsActions } from './rightsservice';

import { detail as getEventDetail } from '../api/event';
// import { push } from 'react-router-redux';
import { userData } from './userdata';
// import { registerWebPushNotification } from '../api/adminuser';
import { States } from './services';

const logger = logging.getLogger("data");

const INITIAL_STATE = {
    detail: null,
    eventdetail: null,
    tinyUrl: null,
    eventid: null
} as States.IEventState;

// const __g = global as any;

export function eventReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "EVENT_SETID": {
            return assign({}, state, {
                eventid: action.payload.eventid
            });
        }
        case "EVENT_SET": {
            return assign({}, state, {
                detail: action.payload.event,
                eventdetail: null,
                data: action.payload.data,
                eventid: action.payload.event ? action.payload.event.id : null,
                tinyUrl: action.payload.event ? action.payload.event.tinyUrl : null
            });
        }
        case "EVENT_CLEAR": {
            return assign({}, state, {
                detail: null,
                eventdetail: null,
                data: null,
                eventid: null,
                tinyUrl: null
            });
        }
        case "EVENT_SETDETAIL": {
            const event: Entities.IEventDetail = action.payload.eventdetail;
            const eventcfg = event && event.configuration && event.configuration.global;

            return assign({}, state, {
                eventdetail: event,
                displayLanguage: action.payload.displayLanguage || (eventcfg && eventcfg.defaultLanguage),
            });
        }
        case "EVENT_SETDISPLAYLANGUAGE": {
            return assign({}, state, {
                displayLanguage: action.payload.displayLanguage,
            });
        }
        // case "EVENT_MYEVENTS": {
        //     return assign({}, state, {
        //         myevents: assign({}, state.myevents, action.payload)
        //     });
        // }
        case "EVENT_SETDATA": {
            return assign({}, state, {
                data: action.payload
            });
        }
        default:
            return state;
    }
}

let pendingLoadEvent;

export const eventActions = {
    setEventId(eventid: string) {
        return (dispatch) => {
            dispatch({ type: 'EVENT_SETID', payload: { eventid: eventid } });
        };
    },

    refreshEventDetail() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            return getEventDetail(state.event.eventid, state.customer.customerid).then((detail) => {
                dispatch({
                    type: 'EVENT_SETDETAIL',
                    payload: {
                        eventdetail: detail,
                        displayLanguage: eventActions.getEventDisplayLanguage(state, detail)
                    }
                });
                userData.set("event-" + state.event.eventid, detail);
                return detail;
            });
        };
    },

    checkEventDisplayLanguage() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (state.event && state.event.detail) {
                const language = eventActions.getEventDisplayLanguage(state, state.event.detail as any);
                eventActions.setEventDisplayLanguage(language)(dispatch);
            }
        };
    },

    setEventDisplayLanguage(language: string) {
        return (dispatch) => {
            dispatch({
                type: 'EVENT_SETDISPLAYLANGUAGE',
                payload: {
                    displayLanguage: language
                }
            });
        };
    },

    getEventDisplayLanguage(state: States.IAppState, event: Entities.IEventDetail) {
        const eventcfg = event?.configuration?.global;
        const boLanguages = eventcfg?.supportedLanguages.filter((lng) => state.i18n.supported.some((bolng) => bolng === lng));
        if (boLanguages && boLanguages.length) {
            if (boLanguages.indexOf(state.i18n.currentLanguageCode) >= 0) {
                return state.i18n.currentLanguageCode;
            }
        }
        return eventcfg && eventcfg.defaultLanguage;
    },

    clearCurrentEvent(): (dispatch, getState) => void {
        return (dispatch) => {
            dispatch({ type: "EVENT_CLEAR" });
        };
    },

    setCurrentEvent(eventid: string, customerid: string): (dispatch, getState) => Promise<any> {
        return (dispatch, getState: () => States.IAppState) => {
            if (pendingLoadEvent) {
                return Promise.resolve();
            }

            pendingLoadEvent = eventid;
            const state = getState();
            const customerId = customerid || state.customer.customerid;

            dispatch({ type: 'EVENT_SET', payload: { event: { id: eventid } } });
            const loadrights = rightsActions.eventRights(customerid, eventid)(dispatch, getState);
            realtimeActions.registerEvent()(dispatch, getState);

            const fetchDetail = getEventDetail(eventid, customerId).then((eventdetail) => {
                dispatch({
                    type: 'EVENT_SETDETAIL',
                    payload: {
                        eventdetail: eventdetail,
                        displayLanguage: eventActions.getEventDisplayLanguage(state, eventdetail)
                    }
                });
                userData.set("event-" + eventid, eventdetail);
                console.log('set detail!!');
                return eventdetail;
            });

            return Promise.all([
                loadrights,
                userData.get("event-" + eventid).then((eventdetail) => {
                    if (eventdetail) {
                        logger.debug("get event detail from cache");
                        return eventdetail;
                    }
                    return fetchDetail;
                }).then((eventdetail) => {
                    dispatch({
                        type: 'EVENT_SETDETAIL',
                        payload: {
                            eventdetail: eventdetail,
                            displayLanguage: eventActions.getEventDisplayLanguage(state, eventdetail)
                        }
                    });
                    return eventdetail;
                }).catch((err) => {
                    pendingLoadEvent = null;
                    return err;
                })
            ]).then((data) => {
                pendingLoadEvent = null;
                return data;
            }).catch((err) => {
                pendingLoadEvent = null;
                return err;
            });
        };
    },

    ensureEvent() {
        return () => {
            return true;
        };
    },
};

export function translateEventLabel(displayLanguage: string, i18n: Entities.i18nService, label: VisualTheme.IAppLabel) {
    let trad = translateFromLabel(i18n.currentLanguageCode, label);
    if (trad) {
        return trad;
    }

    trad = translateFromLabel(displayLanguage, label);
    if (trad) {
        return trad;
    }

    if (label) {
        const keys = Object.keys(label);
        return label[keys[0]];
    }

    return "";
}

/* eslint-disable max-classes-per-file */
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';
import { React, AppTextLabel } from '../../../commons';

interface INumberRangeInputBaseProps {
    min?: number;
    max?: number;
    canEditTrad: boolean;
    i18n: string;
    step?: number;
}

interface INumberRangeInputProps extends INumberRangeInputBaseProps {
    value: number;
    onChange: (val: number) => void;
}

export class NumberRangeInput extends React.Component<INumberRangeInputProps, any> {
    onChange = (arg: React.FormEvent<HTMLInputElement>) => {
        let nb = parseFloat(arg.currentTarget.value);
        if (this.props.min !== undefined && nb < this.props.min) { nb = this.props.min; }
        if (this.props.max !== undefined && nb > this.props.max) { nb = this.props.max; }
        this.props.onChange(nb);
    };

    render() {
        return <div className=" fieldbloc numberrangeinput">
            <AppTextLabel component="label" i18n={this.props.i18n} />
            <AppTextLabel
                component="div"
                className="field-desc"
                i18n={this.props.i18n + ".desc"}
                defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""}
            />
            <div className="inputs">
                <input
                    step={this.props.step ? this.props.step : 1}
                    onChange={this.onChange}
                    type="range"
                    min={this.props.min ? this.props.min : 0}
                    max={this.props.max ? this.props.max : 100}
                    value={this.props.value}
                    className="slider"
                />
                <div className="number-display">
                    <input
                        step={this.props.step ? this.props.step : 1}
                        className="number-input"
                        onChange={this.onChange}
                        value={this.props.value}
                        type="number"
                    />
                </div>
            </div>
        </div>;
    }
}

interface INumberRangeInputPropertyProps extends INumberRangeInputBaseProps {
    data: any;
    path: string;
    onChange: (data: any) => void;
}

export class NumberRangeInputProperty extends React.Component<INumberRangeInputPropertyProps, any> {
    onChange = (txt) => {
        const data = immutable.set(this.props.data, this.props.path, txt);
        this.props.onChange(data);
    };

    render() {
        const val = getPropertyValue(this.props.data, this.props.path);
        return <NumberRangeInput
            {...this.props}
            value={val}
            onChange={this.onChange}
        />;
    }
}

import { React, AppTextLabel } from '../../commons';
import "./appshellheaderactionbutton.less";

export interface IAppShellHeaderActionButtonProps {
    icon: string;
    title: string;
    className?: string;
    badge?: any;
    onClick: (arg: React.MouseEvent<any>) => void;
}

export const AppShellHeaderActionButton = React.forwardRef((props: IAppShellHeaderActionButtonProps, ref: any) => {
    return <button type="button" ref={ref} className={"clickable action-btn " + (props.className || "")} onClick={props.onClick}>
        <div className="icon"><i className={props.icon}></i></div>
        <AppTextLabel className="action-label" i18n={props.title} />
        {props.badge ? <div className="badge">{props.badge}</div> : null}
    </button>;
});

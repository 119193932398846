import * as _React from 'react';
import * as _PropTypes from 'prop-types';
import * as _ReactDom from 'react-dom';
import * as _assign from 'lodash/assign';
import * as _omit from 'lodash/omit';
import * as _find from 'lodash/find';
import * as _findIndex from 'lodash/findIndex';
import { bindActionCreators as _bindActionCreators } from 'redux';
import {
    connectwith as _connectwith
} from '@inwink/react-utils/decorators';
import { logging as _logging } from '@inwink/logging';
import { loadAssets as _loadAssets } from '@inwink/react-utils/loadassets';
import { renderToast, ToastType } from '@inwink/toaster';
import { Entities } from '@inwink/entities/entities';
import { ICommunityDetail } from '@@data/communities/communitydata';
import { InwinkLoader as _InwinkLoader } from './components/ui/inwinkloader';
import { translateEventLabel as _translateEventLabel } from './services/eventservice';
import { EventLabel as _EventLabel } from './components/ui/eventlabel';
import * as _buttons from './components/ui/buttons';
import * as _i18nservice from './services/i18nservice';

export { TransitionGroup, CSSTransition } from 'react-transition-group';
export { GridLoader } from './components/ui/gridloader';
export { LoadingState, LoadingProgress } from '@inwink/loadable';
export { IPopoverManager, PopoverManager, WithPopoverManager } from '@inwink/modals/popovermgr';
export { CMSContextProvider } from './components/cmscontext';
export { withI18nHelper } from '@inwink/i18n/reactcontext';

export const PropTypes = _PropTypes;
// export const AppTooltip = _AppTooltip;
export const loadAssets = _loadAssets;
export const React = _React;
export const Component = _React.Component;
export const ReactDom = _ReactDom;
export const bindActionCreators = _bindActionCreators;
export const connectwith = _connectwith;
export const assign = _assign;
export const find = _find;
export const omit = _omit;
export const findIndex = _findIndex;
export const translate = _i18nservice.translate;
export const translateBag = _i18nservice.translateBag;
export const I18NResourcesContext = _i18nservice.I18NResourcesContext;
export const i18NHelperContext = _i18nservice.i18NHelperContext;
export const AppLabel = _i18nservice.AppLabel;
export const DisplayDate = _i18nservice.DisplayDate;
export const DynLabel = _i18nservice.DynLabel;
export const AppTextLabel = _i18nservice.AppTextLabel;
export const logging = _logging;
// export const getEntityRights = _rightsservice.getEntityRights;
export const AppAsyncButton = _buttons.AppAsyncButton;
export const AppButton = _buttons.AppButton;
export const Loader = _InwinkLoader;
export const EventLabel = _EventLabel;
export const translateEventLabel = _translateEventLabel;

export function injecti18nresource(trads: any) {
    return function a(
        _target: any // The class the decorator is declared on
    ) {
        const target = _target;
        // eslint-disable-next-line react/forbid-prop-types
        (target as any).contextTypes = { ...(target as any).contextTypes, i18nresource: PropTypes.object };
        const res = (props) => React.createElement(_i18nservice.I18NResourcesContext,
            { resources: trads }, React.createElement(target as any, props));
        return res as any;
    };
}

function toast(key: string, type: ToastType, service: Entities.i18nService, inject?: any) {
    let label = key;
    if (defaultI18nHelper) {
        label = defaultI18nHelper.translate(key, inject);
    } else if (service) {
        label = translate(service, key, inject);
    }
    if (!label) {
        if (type === "success") {
            label = "Success.";
        } else if (type === "error") {
            label = "An error has occured.";
        }
    }
    return renderToast(label, type, null, false);
}

let defaultI18nHelper: Entities.i18nHelper;
export function registerI18nHelper(i18n: Entities.i18nHelper) {
    defaultI18nHelper = i18n;
}

export function toastSuccess(service: Entities.i18nService, key: string, inject?: any) {
    return toast(key, 'success', service, inject);
}

export function toastError(service: Entities.i18nService, label: string, inject?: any) {
    return toast(label, 'error', service, inject);
}

export function toastWarning(service: Entities.i18nService, label: string, inject?: any) {
    return toast(label, 'warning', service, inject);
}

export function toastInfo(service: Entities.i18nService, label: string, inject?: any) {
    return toast(label, 'info', service, inject);
}

export function previewHost(customHost: string) {
    let host = inwink.config.companionPreviewUrl;
    if (customHost) {
        host = customHost;
    }

    if (!host.startsWith("https://")) {
        host = "https://" + host;
    }

    if (!host.endsWith("/")) {
        host = host + "/";
    }
    return host;
}

export function getPreviewUrl(detail: Entities.IEventDetail | ICommunityDetail) {
    const tinyUrl = (detail as any).eventKey || (detail as any).tinyUrl || (detail as any).communityKey;
    return previewHost(detail.previewHost) + tinyUrl;
}

export const dateWithoutTimezone = "YYYY[-]MM[-]DD[T]HH[:]mm[:]ss";

export function waitForReady() {
    return ((
        target: any
    ) => waitForReadyHOC(target)) as any;
}

export function waitForReadyHOC(_component: any) {
    const component = _component;
    component.waitForReady = true;
    return component;
}

export function withcontext(_component: any, context: any) {
    const component = _component;
    component.contextTypes = context;
    return component;
}

export function showBetaFeatures() {
    if (inwink.config.isBetaEnv) {
        return true;
    }

    const overrideShowBeta = localStorage.getItem("showBetaFeatures");
    if (overrideShowBeta) {
        return overrideShowBeta === "true";
    }
    return inwink.config.showBetaFeatures;
}

export type Nullable<T> = T | null;

// howto: https://usehooks.com/usePrevious/
export const usePrevious = (value: any) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = React.useRef();
    // Store current value in ref
    React.useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
};

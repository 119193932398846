/* eslint-disable no-restricted-syntax */
import * as assign from 'lodash/assign';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { IEntityGroupDefinition } from '../definitions';

export interface IExpressionItemMetadata {
    field: Entities.IEntityFieldTemplateV3;
    template: Entities.IEntityTemplateV3;
    entityName: string;
    group: IEntityGroupDefinition;
    parent?: IExpressionItemMetadata;
}

function getFieldKey(key: string, template: Entities.IEntityTemplateV3) {
    if (!template) {
        return;
    }
    const lowerKey = key.toLowerCase();
    const fieldKeys = template && template.fields && Object.keys(template.fields);
    if (fieldKeys) {
        return fieldKeys.filter((k) => k.toLowerCase() === lowerKey)[0];
    }
}

export function getItemMetadata(_fieldkey: string, groups: Record<string, IEntityGroupDefinition>,
    isFieldEditor?: boolean): IExpressionItemMetadata {
    if (!groups || !_fieldkey) {
        return null;
    }

    const fieldkey = _fieldkey; // .replace('timeslotView', 'timeslot');

    // Mettre point d'arrêt ici
    const groupKeys = Object.keys(groups);
    const tokens = fieldkey.split('.');

    let token = tokens[0];

    if (tokens.length === 2 && groups && Object.keys(groups).length > 0 && !isFieldEditor) {
        const entityName = tokens[0];
        let haveMatch = false;
        for (const keyObj in groups) {
            if (Object.prototype.hasOwnProperty.call(groups, keyObj)) {
                if (groups[keyObj]?.entityName?.toLowerCase() === entityName?.toLowerCase()) {
                    haveMatch = true;
                    break;
                }
            }
        }

        if (haveMatch) {
            token = tokens[1];
        }
    } else if (tokens.length === 2 && isFieldEditor && tokens[0] === "entity") {
        token = tokens[1];
    }

    for (const groupkey of groupKeys) {
        const group = groups[groupkey];
        let isTargetGroup = false;
        if (!group.path) {
            const existingKey = getFieldKey(token, group.template);
            if (existingKey && group.template.fields[existingKey]) {
                isTargetGroup = true;
            }
        } else if (group.path === token) {
            isTargetGroup = true;
        }

        if (isTargetGroup) {
            return getGroupItemMetadata(fieldkey, group, isFieldEditor);
        }
    }

    return null;
}

export function getGroupItemMetadata(fieldkey: string, group: IEntityGroupDefinition,
    isFieldEditor?: boolean): IExpressionItemMetadata {
    let tokens = fieldkey.split('.');
    let token = tokens[0];
    if (tokens[0] === group.path) {
        tokens = tokens.slice(1);
    }

    if (tokens.length === 2 && group.entityName && tokens[0].toLowerCase() === group.entityName.toLowerCase()) {
        token = tokens[1];
    } else if (tokens.length === 2 && isFieldEditor && tokens[0] === "entity") {
        token = tokens[1];
    }
    let entityName = group.entityName;
    let tokenIdx = 0;
    let fieldKey = getFieldKey(token, group.template);
    let field = group.template && group.template.fields && group.template.fields[fieldKey];
    let template = group.template;
    let parent: IExpressionItemMetadata = null;

    while (field && tokenIdx < tokens.length - 1) {
        tokenIdx++;
        if (field && field.template) {
            parent = {
                parent: parent,
                group: group,
                field: field,
                template: template,
                entityName: entityName
            };
            entityName = field.value;
            template = field.template;
            fieldKey = getFieldKey(tokens[tokenIdx], field.template);
            field = field.template.fields[fieldKey];
        } else if (field && !field.template) {
            parent = null;
            entityName = group.entityName;
            template = null;
            // fieldKey = fieldKey;
            // field = field;
        } else {
            entityName = null;
            field = null;
        }
    }

    return {
        group: group,
        entityName: entityName,
        field: field,
        template: template,
        parent: parent
    };
}

export function getFieldValueFor(field: Entities.IEntityFieldTemplateV3, val: string) {
    if (field && (field.type === "selectlist" || field.type === "multiselectlist")) {
        if (field.valuesList) {
            const tmp = getValueFor(field.valuesList, val);
            if (tmp) {
                return tmp;
            }
        }
    }
}

export function getValueFor(vals: Entities.IEntityFieldValue[], val: string): VisualTheme.IAppLabel {
    for (let i = 0, l = vals.length; i < l; ++i) {
        if (vals[i].key === val) {
            return vals[i].labels;
        }
        if (vals[i].valuesList) {
            const v = getValueFor(vals[i].valuesList, val);
            if (v) {
                return v;
            }
        }
    }
}

export function addEntityNameToExpression(entityname: string, expression) {
    if (!expression) {
        return null;
    }

    return [...expression].map((ex) => {
        if ('name' in ex) {
            return assign({}, ex, { name: entityname + '.' + ex.name });
        }

        if ('or' in ex) {
            return assign({}, ex, { or: addEntityNameToExpression(entityname, ex.or) });
        }

        if ('and' in ex) {
            return assign({}, ex, { and: addEntityNameToExpression(entityname, ex.and) });
        }
        return ex;
    });
}

export function removeEntityNameFromExpression(entityname: string, expression) {
    if (!expression) {
        return null;
    }
    return [...expression].map((ex) => {
        // I know this will break at some point but what else do you want to do....
        if (ex.any) {
            return ex.any;
        }

        if ('name' in ex) {
            const split: string[] = ex.name.split('.');
            let name = entityname.toLowerCase();
            if (split.length > 1) {
                const ix = split.indexOf(name);
                name = split[1];
                if (ix > -1 && (ix + 1) < split.length) {
                    name = split[ix + 1];
                }
                return assign({}, ex, { name });
            }
            return ex;
        }

        if ('or' in ex) {
            return assign({}, ex, { or: removeEntityNameFromExpression(entityname, ex.or) });
        }

        if ('and' in ex) {
            return assign({}, ex, { and: removeEntityNameFromExpression(entityname, ex.and) });
        }

        return ex;
    });
}

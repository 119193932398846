import { React, AppTextLabel } from '../../../commons';
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';

interface IHiddenInputBaseProps {
    i18n: string;
    canEditTrad: boolean;
    id?: string;
    multiline?: boolean;
    style?: any;
    name?: string;
    readonly?: boolean;
}

export interface IHiddenInputProps extends IHiddenInputBaseProps {
    value: string;
    onChange: (val: string) => void;
}

export class HiddenInput extends React.PureComponent<IHiddenInputProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        var content = <input id={this.props.id} type="hidden" value={this.props.value || ""} name={this.props.name} />

        return <div>
            {content}
        </div>;
    }
}

export interface IHiddenInputPropertyProps extends IHiddenInputBaseProps {
    data: any;
    path: string;
    onChange: (data: any) => void;
}

export class HiddenInputProperty extends React.PureComponent<IHiddenInputPropertyProps, any> {
    onChange = (txt) => {
        let data = immutable.set(this.props.data, this.props.path, txt);
        this.props.onChange(data);
    }

    render(){
        let val = getPropertyValue(this.props.data, this.props.path);
        return <HiddenInput {...this.props}
            value={val}
            onChange={this.onChange} />;
    }
}
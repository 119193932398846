import * as React from 'react';
import { connect } from 'react-redux';
import { AsyncButton } from '@inwink/buttons';
import { withI18nHelper } from '@inwink/i18n';
import { isAllowed, IRightsCheckBag } from '../../services/rightsservice';
import type {States} from '../../services/services';
import type { Entities } from '@inwink/entities/entities';

export interface IAppButtonProps {
    i18nHelper?: Entities.i18nHelper;
    requiredRights?: IRightsCheckBag;
    className?: string;
    id?: string;
    title?: string;
    style?: any;
    disabled?: boolean;
    onClick?: (arg: React.MouseEvent<any>) => void;
    rights?: States.IAppUserRights;
    commandName?: string;
}

@withI18nHelper()
class AppButtonComponent extends React.Component<IAppButtonProps, any> {
    render() {
        let title;
        if (this.props.title) {
            title = this.props.i18nHelper.translate(this.props.title) || this.props.title;
        }
        if (isAllowed(this.props.rights, this.props.requiredRights)) {
            if (this.props.title) {
                return <button
                    title={title}
                    id={this.props.id}
                    data-command={this.props.commandName}
                    data-trad={this.props.title}
                    className={this.props.className}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    onClick={this.props.onClick}>
                        {this.props.children}
                </button>;
            }
            return <button
                id={this.props.id}
                title={title}
                data-command={this.props.commandName}
                className={this.props.className}
                disabled={this.props.disabled}
                style={this.props.style}
                onClick={this.props.onClick}>
                    {this.props.children}
            </button>;
        } else {
            return <span className="empty"></span>;
        }
    }
}

function mapBtnStateToProps(state: States.IAppState) {
    return {
        rights: state.rights
    };
}

export let AppButton: new (any) => React.Component<IAppButtonProps, any> = connect(mapBtnStateToProps)(AppButtonComponent as any) as any;

export interface IAppAsyncButtonProps {
    i18nHelper?: Entities.i18nHelper;
    requiredRights?: IRightsCheckBag;
    className?: string;
    title?: string;
    id?: string;
    style?: any;
    disabled?: boolean;
    commandName?: string;
    onClick?: (arg: React.MouseEvent<any>) => void | Promise<any>;
    rights?: States.IAppUserRights;
}

@withI18nHelper()
class AppAsyncButtonComponent extends React.Component<IAppAsyncButtonProps, any> {
    render() {
        let title;
        if (this.props.title) {
            title = this.props.i18nHelper.translate(this.props.title) || this.props.title;
        }

        if (isAllowed(this.props.rights, this.props.requiredRights)) {
            return <AsyncButton
                id={this.props.id}
                commandName={this.props.commandName}
                datatrad={this.props.title}
                className={this.props.className}
                title={title}
                style={this.props.style}
                disabled={this.props.disabled}
                onClick={this.props.onClick}>{this.props.children}</AsyncButton>;
        } else {
            return <span className="empty"></span>;
        }
    }
}

function mapAsyncBtnStateToProps(state: States.IAppState) {
    return {
        rights: state.rights
    };
}

export let AppAsyncButton: new (any) => React.Component<IAppAsyncButtonProps, any> = connect(mapAsyncBtnStateToProps)(AppAsyncButtonComponent as any) as any;

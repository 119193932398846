import { States } from './services';

const INITIAL_STATE: States.IAppMenuState = {
    component: null
};

export function appmenuReducer(state = INITIAL_STATE, action = { type: '', payload: null }): any {
    switch (action.type) {
        case "APPMENU_SETCURRENT": {
            return action.payload;
        }
        default:
            return state;
    }
}

export const appmenuActions = {
    setCurrentAppMenu(component) {
        return (dispatch) => {
            dispatch({ type: 'APPMENU_SETCURRENT', payload: { component: component } });
        };
    }
};

/* eslint-disable max-classes-per-file */
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';
import { React, AppTextLabel } from '../../../commons';
import { AppTooltip } from '../apptooltip';
import './textinput.less';

interface ITextInputBaseProps {
    i18n: string;
    canEditTrad: boolean;
    id?: string;
    multiline?: boolean;
    style?: any;
    name?: string;
    readonly?: boolean;
    autoComplete?: string;
    isPassword?: boolean;
    isMail?: boolean;
    withToolTip?: boolean | string;
}

export interface ITextInputProps extends ITextInputBaseProps {
    value: string;
    onChange: (val: string) => void;
}

export class TextInput extends React.PureComponent<ITextInputProps, any> {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(arg: React.FormEvent<any>) {
        this.props.onChange(arg.currentTarget.value);
    }

    render() {
        let type = "text";
        if (this.props.isPassword) {
            type = "password";
        } else if (this.props.isMail) {
            type = "mail";
        }

        let content;
        if (this.props.multiline) {
            content = <textarea
                readOnly={this.props.readonly || false}
                id={this.props.id}
                style={this.props.style}
                value={this.props.value || ""}
                autoComplete={this.props.autoComplete}
                onChange={this.onChange}
            />;
        } else {
            content = <input
                readOnly={this.props.readonly || false}
                id={this.props.id}
                type={type}
                style={this.props.style}
                value={this.props.value || ""}
                autoComplete={this.props.autoComplete}
                onChange={this.onChange}
            />;
        }

        let descriptionNode;
        if (this.props.withToolTip) {
            if (typeof this.props.withToolTip === 'string') {
                descriptionNode = (
                    <AppTooltip
                        canEditTrad={this.props.canEditTrad}
                        text={this.props.withToolTip}
                        renderContent
                    >
                        <i className="inwinkbo-log-info" />
                    </AppTooltip>
                );
            } else {
                descriptionNode = (
                    <AppTooltip
                        canEditTrad={this.props.canEditTrad}
                        i18n={this.props.i18n + ".desc"}
                    />
                );
            }
        } else {
            descriptionNode = (
                <AppTextLabel
                    component="div"
                    className="field-desc"
                    i18n={this.props.i18n + ".desc"}
                    defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""}
                />
            );
        }

        return <div className="fieldbloc text-input">
            <div className={`title ${this.props.withToolTip ? 'tooltip' : ''}`}>
                <AppTextLabel component="label" i18n={this.props.i18n} />
                {descriptionNode}
            </div>
            {content}
        </div>;
    }
}

export interface ITextInputPropertyProps extends ITextInputBaseProps {
    data: any;
    path: string;
    onChange: (data: any) => void;
}

export class TextInputProperty extends React.PureComponent<ITextInputPropertyProps, any> {
    onChange = (txt) => {
        const data = immutable.set(this.props.data, this.props.path, txt);
        this.props.onChange(data);
    };

    render() {
        const val = getPropertyValue(this.props.data, this.props.path);
        return <TextInput
            {...this.props}
            value={val}
            onChange={this.onChange}
        />;
    }
}

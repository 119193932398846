import * as React from 'react';
import { AppPage } from '../../../components/apppage';
import { AppLabel } from '../../../services/i18nservice';
import {IDefaultPageProps} from '../../../data/entities';
import { logging } from '@inwink/logging';
const logger = logging.getLogger("UI");

interface INotFoundPageProps  extends IDefaultPageProps {
}

export class NotFoundPage extends React.Component<INotFoundPageProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            stats : null
        };
    }

    render() {
        logger.debug("render not found page");
        return <AppPage featurecode="pagenotfound" title="page.notfound.title" className="eventhomepage" i18n={this.props.i18n}>
            <AppLabel i18nService={this.props.i18n} i18n="page.notfound.description" />
        </AppPage>;
    }
}

import { defaultPostHeaderForJsonData } from '@inwink/apibase';
import { getBackOfficeProxy } from './index';

export function getTrads(lng, lastupdate?) {
    let url = "botrads/list/" + lng;
    if (lastupdate) {
        url = url + "?lastupdated=" + lastupdate;
    }
    return getBackOfficeProxy().getJson(url);
}

export function getTradsByKey(tradkey) {
    const url = "botrads/getbykey";
    // return getBackOfficeProxy().getJson(url);
    return getBackOfficeProxy().postJson(url, JSON.stringify({
        key: tradkey
    }), defaultPostHeaderForJsonData);
}

export function setTradsByKey(tradkey, trads) {
    const url = "botrads/setbykey";
    return getBackOfficeProxy().postJson(url, JSON.stringify({
        key: tradkey,
        trads: trads
    }), defaultPostHeaderForJsonData);
}

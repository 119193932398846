import { React, AppTextLabel } from '../../../../commons';
import type { IExpressionProps } from "../definitions";

export class ExpressionNumber extends React.Component<IExpressionProps, any> {

    constructor(props: IExpressionProps) {
        super(props);
        this.state = {
            value_first: (props.expressions) ? (props.expressions.and) ? props.expressions.and[0].val : props.expressions.val : '',
            value_second: (props.expressions && props.expressions.and && props.expressions.and.length > 1) ? props.expressions.and[1].val : '',
            operator: (props.expressions) ? (props.expressions.and && props.expressions.and.length > 1) ? 'between' : props.expressions.op : ''
        };
    }

    componentDidUpdate(prevProps: IExpressionProps) {
        if (this.props.fieldItem.key !== prevProps.fieldItem.key && ((!this.props.expressions && !prevProps.inline)
            || prevProps.inline)) {
            this.setState({
                value_first: '',
                value_second: '',
                operator: ''
            });
        }
    }

    componentDidMount() {
        const allowSave = this.allowSaveValue();
        if (allowSave !== this.props.allowSave) {
            this.props.setAllowSave(allowSave);
        }
    }

    formatExpression = () => {
        let and = [{
            name: this.props.fieldItem.key,
            op: this.state.operator,
            val: (this.state.operator === 'isnull' || this.state.operator === 'isnotnull') ? '' : +this.state.value_first
        }];
        if (this.state.operator === 'between') {
            and[0].op = 'gt';
            and.push({
                name: this.props.fieldItem.key,
                op: 'lt',
                val: +this.state.value_second
            });
        }
        this.props.setExpression((and.length === 1) ? and[0] : { and });
    }

    allowSaveValue = () => {
        if (this.state.operator === 'between') {
            return ((this.state.value_second !== '' && this.state.value_first !== '') ? true : false);
        } else {
            if ((this.state.value_first !== '' && this.state.operator !== '') || (this.state.operator === 'isnull' || this.state.operator === 'isnotnull')) {
                return true;
            } else {
                return false;
            }
        }
    }

    handleChange = (arg, type) => {
        let val = arg.target.value;

        let reset = {};
        if (type === 'operator') {
            if (arg.target.value === '') {
                reset = {
                    value_first: '',
                    value_second: ''
                };
            }
        } else {
            val = null;
            if (arg.target.value) {
                try {
                    val = parseFloat(arg.target.value);
                } catch (e) {
                    console.warn(e);
                }
            }
        }

        this.setState(Object.assign({}, { [type]: val }, reset), () => {
            const allowSave = this.allowSaveValue();
            if (allowSave !== this.props.allowSave) {
                this.props.setAllowSave(allowSave);
            }
            if (this.props.inline) {
                this.props.updateInline({
                    operator: this.state.operator,
                    propvalue: (this.state.operator === 'isnull' || this.state.operator === 'isnotnull') ? '' : this.state.value_first
                });
            } else {
                if (allowSave) {
                    this.formatExpression();
                }
            }
        });
    }

    onKeyPress = (e) => {
        if (e.charCode === 13) {
            this.props.save();
        }
    }

    renderInline = (options) => {
        return <div>
            <select id="operators" value={this.state.operator || ""} onChange={(arg) => this.handleChange(arg, 'operator')}>
                <AppTextLabel component="option" value="" i18n="" />
                {
                    options.map((op, i) => {
                        return <AppTextLabel key={i} component="option" value={op} i18n={"operators." + op} />;
                    })
                }
            </select>
            <div id="propvalue">
                <input
                    onKeyPress={this.onKeyPress}
                    disabled={((this.state.operator === '' || this.state.operator === 'isnull' || this.state.operator === 'isnotnull') ? true : false)}
                    type={'number'}
                    value={this.state.value_first || ""}
                    onChange={(arg) => this.handleChange(arg, 'value_first')}
                />
            </div>
        </div>;
    }

    render() {
        let options = ["between", "eq", "neq", "gt", "gte", "lt", "lte"];

        let field = this.props.entityField;

        if (field && field.metadata && field.metadata.canBeNull) {
            options = [...options, ...['isnull', 'isnotnull']];
        }

        if (this.props.inline) {
            options.shift();
            return this.renderInline(options);
        }

        return <div className="filter-date filter-string filter-number filter-type">
            <header>
                <span><AppTextLabel i18n="filter.indication" /></span>
                <select value={this.state.operator} onChange={(arg) => this.handleChange(arg, 'operator')}>
                    <option value=""></option>
                    {
                        options.map((option, i) => {
                            return <AppTextLabel key={i} component="option" value={option} i18n={"operators." + option} />;
                        })
                    }
                </select>
            </header>
            <section>
                <div className={"date-section" + ((this.state.operator === '' || this.state.operator === 'isnull' || this.state.operator === 'isnotnull') ? ' disabled' : '')}
                    style={{ marginBottom: 10 }}>
                    <label><AppTextLabel i18n="filter.first_number" /></label>
                    <div className="date-wrapper">
                        <input onKeyPress={this.onKeyPress}
                            disabled={((this.state.operator === '' || this.state.operator === 'isnull' || this.state.operator === 'isnotnull') ? true : false)}
                            type="number"
                            value={this.state.value_first}
                            onChange={(arg) => this.handleChange(arg, 'value_first')} />
                    </div>
                </div>
                <div className={"date-section" + ((this.state.operator !== 'between') ? ' disabled' : '')}>
                    <label><AppTextLabel i18n="filter.second_number" /></label>
                    <div className="date-wrapper">
                        <input onKeyPress={this.onKeyPress}
                            disabled={((this.state.operator !== 'between') ? true : false)}
                            type="number" value={this.state.value_second} onChange={(arg) => this.handleChange(arg, 'value_second')} />
                    </div>
                </div>
            </section>
        </div>;
    }
}

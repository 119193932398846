import { React, connectwith, I18NResourcesContext } from '../commons';
import { States } from '../services/services';
import { I18NStateContext, i18NHelperContext } from '../services/i18nservice';
const apptradspatch = require("../trads/app.json");
const tradspatch = require("../trads/patch.json");

function mapStateToProps(state: States.IAppState) {
    return {
        i18n: state.i18n
    };
}

@connectwith(mapStateToProps, null)
export class AppShellLanguage extends React.Component<any, any> {
    render() {
        return <I18NStateContext i18n={this.props.i18n}>
            <I18NResourcesContext resources={apptradspatch}>
                <I18NResourcesContext resources={tradspatch}>
                    <I18NResourcesContext resources={this.props.i18n.currentLanguage}>
                        {this.props.children}
                    </I18NResourcesContext>
                </I18NResourcesContext>
            </I18NResourcesContext>
        </I18NStateContext>;
    }
}

export class AppShellLanguageHook extends React.Component<any, any> {
    render() {
        return <i18NHelperContext.Consumer>
            {(i18nresource) => {
                if (this.props.hook) {
                    this.props.hook(i18nresource);
                }
                return this.props.children;
            }}
        </i18NHelperContext.Consumer>;
    }
}

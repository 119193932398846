import { React, ReactDom, AppTextLabel } from '../../../commons';
import type { ISimpleTabsProps, ISimpleTabItem, ISimpleTabItemGroup } from './index';
import { SimpleTabsItemCtrl } from './item';

import './itemsgroup.less';

export interface ISimpleTabsItemsGroupProps extends ISimpleTabsProps {
    group: ISimpleTabItemGroup;
}

export class SimpleTabsItemsGroup extends React.Component<ISimpleTabsItemsGroupProps, any> {
    itemsContainer = React.createRef<HTMLDivElement>();

    constructor(props: ISimpleTabsItemsGroupProps) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.checkSize();
        if (this.props.desktopmode === "top" && this.props.autocollapse) {
            window.addEventListener("resize", this.onresize);
        }
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.onresize);
    }

    onresize = () => {
        this.checkSize();
    };

    checkSize() {
        if (this.props.desktopmode === "top" && this.props.autocollapse) {
            // eslint-disable-next-line react/no-find-dom-node
            const node = ReactDom.findDOMNode(this.itemsContainer.current) as HTMLElement;
            if (node) {
                let isTooSmall = false;
                const items = node.querySelectorAll(".simpletabs-item");
                items.forEach((item: HTMLElement) => {
                    if (item.offsetLeft + item.clientWidth > node.clientWidth) {
                        isTooSmall = true;
                    }
                });
                this.setState({ small: isTooSmall });
            }
        }
    }

    showItems = (arg: React.MouseEvent<any>) => {
        this.props.popovermgr.popoverPortal(arg.currentTarget, (props) => {
            return this.renderItems(null, (item) => {
                props.onCompleted();
                this.props.itemSelected(item);
            });
        }, this.props, "simpletabsitempickermodal");
    };

    renderCollapsedItems() {
        return <div className="simpletabs-itemsgroup-items collapseditems" onClick={this.showItems}>
            {this.props.selectedItem ? <SimpleTabsItemCtrl key="item" {...this.props} item={this.props.selectedItem} /> : null}
        </div>;
    }

    renderItems(refObj, itemselected?: (item: ISimpleTabItem) => void) {
        let items;
        const props = this.props;

        if (props.group.items) {
            const ctrlprops = {...props};
            if (itemselected) {
                ctrlprops.itemSelected = itemselected;
            }
            items = props.group.items.filter((item) => {
                if (item.showIf) {
                    return item.showIf(props.datacontext);
                }

                return true;
            }).map((i, idx) => {
                return <SimpleTabsItemCtrl key={"item" + idx} {...ctrlprops} item={i} />;
            });
        }

        let customComponentRendering: any = null;
        if (typeof props.customizeTab === 'function') {
            const customComponent = props.customizeTab();
            customComponentRendering = customComponent.component
                && React.createElement(customComponent.component, customComponent);
        }

        return <div ref={refObj} className="simpletabs-itemsgroup-items simpleitems">
            {items} {customComponentRendering}
        </div>;
    }

    render() {
        let title;
        if (this.props.group.label) {
            title = <div className="grouptitle"><AppTextLabel i18n={this.props.group.label} component="h4" /></div>;
        }

        return <div className="simpletabs-itemsgroup">
            {title}
            {this.renderItems(this.itemsContainer)}
            {this.state.small ? this.renderCollapsedItems() : null }
        </div>;
    }
}

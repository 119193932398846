import type { IPopoverManager } from '../../../commons';
import type { IEntityPageConfiguration } from "../entitypagev3/entitypagebase.props";
// import { IEventEntityGridArgs } from '../../../entities/event/gridconfiguration';

export interface IEntityPickerModaleProps {
    popovermgr: IPopoverManager;
    entityName: string;
    entityArgs?: any;
    multiselect?: boolean;
    disableAutoSelect?: boolean;
    selection?: any[];
    title?: string | ((props?: any) => any);
    excludes?: string[];
    entityConfiguration?: IEntityPageConfiguration;
    disableAddNew?: boolean;
    expands?: string[];
    forceDefaultView?: boolean;
}

export function pickEntities<T>(props: IEntityPickerModaleProps, showGrid: boolean,
    elt: HTMLElement, className?: string, options?: any): Promise<T[]> {
    return import(/* webpackChunkName: "iwentitypickerpopover" */ "./entitypickermodale.popover").then((mod) => {
        return props.popovermgr.popoverPortal(elt, mod.EntityPickerPopover, props, "iwentitypickerpopover", options);
    });
}

import * as React from 'react';
import { Tooltip } from '@inwink/modals/tooltip';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n';

import './apptooltip.less';

interface IAppTooltipProps {
    i18nHelper?: Entities.i18nHelper;
    canEditTrad: boolean;
    renderContent?: boolean;
    i18n?: string;
    text?: string;
    big?: boolean;
}

@withI18nHelper()
export class AppTooltip extends React.PureComponent<IAppTooltipProps, any> {
    render() {
        let classNames = ["apptooltip"];
        let text = this.props.text;
        if (this.props.i18n) {
            let i18n = this.props.i18nHelper;
            text = i18n.translate(this.props.i18n, null, null);
        }
        if (!text && !this.props.canEditTrad) {
            return null;
        }

        if (!text) {
            classNames.push("emptytooltip");
        }

        if (this.props.big) {
            classNames.push("big");
        }

        if (this.props.renderContent) {
            return <Tooltip content={text} component="span" className={classNames.join(" ")}>
                <span className="tooltip" data-trad={this.props.i18n}>{this.props.children}</span>
            </Tooltip>;
        } else {
            classNames.push("helptooltip");
        }

        return <Tooltip content={text} component="span" className={classNames.join(" ")}>
            <span className="tooltip" data-trad={this.props.i18n}><i className="inwink-help"></i></span>
        </Tooltip>;
    }
}

import { getBackOfficeEntityDataSource, getBackOfficeProxy } from './index';
import { IInwinkEntityDataSourceOptions } from '../base/datasource';

export function templateDataSource(options?: IInwinkEntityDataSourceOptions) {
    return getBackOfficeEntityDataSource<IBlocTemplate>("", "botemplate", "BoTemplate", options);
}

const onboardingCache: Record<string, Promise<IBlocTemplate>> = {};

export function getOnboarding(onboardingKey: string, version: string, force = false): Promise<IBlocTemplate> {
    if (force) {
        const ds = templateDataSource();

        return ds.query({
            where: {
                templateType: "boonboarding",
                templateTarget: onboardingKey
            }
        }).then((res) => {
            if (res && res.data && res.data.length) {
                return res.data[0];
            }
        });
    }
    if (onboardingCache[onboardingKey]) {
        return onboardingCache[onboardingKey];
    }

    const proxy = getBackOfficeProxy();
    const res = proxy.getJson(`onboarding/${onboardingKey}/${version}`).then((res2) => {
        if (res2 && !res2.template) {
            return null;
        }

        return res2;
    });

    onboardingCache[onboardingKey] = res.then(null, (err) => {
        onboardingCache[onboardingKey] = null;
        console.error("error getting onboarding for " + onboardingKey);
        return Promise.reject(err);
    });

    return res;
}

import { IAPIProxy, getAPIProxy } from '@inwink/apibase';
import { userPreferences } from '../base/userPreferences';
import { communityEntityViewDataSource } from '../backoffice/communityentityview';
import { IDataSourceV3Options, IDataSourceV3, getEntityDataSource } from '../base/datasource';

let _communityAPIProxy: IAPIProxy = null;

export const getCommunityAPIProxy = () => {
    if (!_communityAPIProxy) { _communityAPIProxy = getAPIProxy(inwink.config.communityApiUrl); }

    return _communityAPIProxy;
};

export function getCommunityEntityDataSource<T>(
    communityId: string,
    entityRoute: string,
    entityName: string,
    options?: IDataSourceV3Options
): IDataSourceV3<T> {
    const proxy = getCommunityAPIProxy();
    const userPreferencesDatasource = communityEntityViewDataSource(communityId);
    const userpreferencesProvider = userPreferences(userPreferencesDatasource, entityName);
    return getEntityDataSource<T>(proxy, communityId + "/", entityRoute, entityName, userpreferencesProvider, options);
}

import * as React from 'react';
import { Loader, LoaderProps } from '@inwink/loader';
import './inwinkloader.less';

const fun = [
    "/assets/images/loader/licornes.gif",
    "/assets/images/loader/programming-humor-software-development.jpg",
    "/assets/images/loader/dave.jpg",
    "/assets/images/loader/cynthia.jpg",
    "/assets/images/loader/testing.jpg",
    "/assets/images/loader/workonmymachine2.jpg",
    "/assets/images/loader/workonmymachine3.jpg",
    "/assets/images/loader/release.jpg",
    "/assets/images/loader/unexpected.png",
    "/assets/images/loader/prod.jpg",
    "/assets/images/loader/backend.jpg",
    "/assets/images/loader/commit-messages.jpg",
    "/assets/images/loader/feature.jpg",
    "/assets/images/loader/programmer.png",
    "/assets/images/loader/webdev.jpeg",
    "/assets/images/loader/checkin.jpg",
    "/assets/images/loader/frontend.jpg",
    "/assets/images/loader/estimates.png"
];

export interface IInwinkLoaderProps extends LoaderProps {

}

export class InwinkLoader extends React.Component<IInwinkLoaderProps, any> {
    constructor(props: IInwinkLoaderProps) {
        super(props);
        if (inwink.config.env == "dev"){
            this.state = {
                picture : fun[Math.floor(Math.random() * fun.length)]
            };
        }
    }

    render() {
        if (this.props.display !== "bubbles") {
            return <Loader {...this.props} />;
        }

        // if (inwink.config.env == "dev"){
        //     return <div className="funloader">
        //         <img src={this.state.picture} />
        //     </div>
        // }

        return <div className="inwinkloader">
            <img className="people-head" src={(inwink.config.assetsUrl || "/") + "assets/images/inwink_logo_people_head.svg"} />
            <img
                className="people-body01"
                src={(inwink.config.assetsUrl || "/") + "assets/images/inwink_logo_people_triangle.svg"}
            />
            <img
                className="people-body02"
                src={(inwink.config.assetsUrl || "/") + "assets/images/inwink_logo_people_triangle2.svg"}
            />
        </div>;
    }
}

import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';

import {getRootReducer} from '../services';

function configureStore(routerHistory, initialState) {
    const middlewares = applyMiddleware(..._getMiddleware()) as any;
    const enhancer = compose(middlewares);

    const store = createStore(getRootReducer(),
        initialState || {},
        enhancer);

    _enableHotLoader(store);
    return store;
}

function _getMiddleware() {
    const middleware = <any>[
    // routerMiddleware(routerHistory),
        thunk,
    ];

    return middleware;
}

function _enableHotLoader(store) {
    // if ((global as any).__DEV__ && module.hot) {
    //     module.hot.accept('../services', () => {
    //         const nextRootReducer = require('../services');
    //         store.replaceReducer(nextRootReducer);
    //     });
    // }
}

export default configureStore;

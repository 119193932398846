import { React, Component, bindActionCreators, connectwith, Loader, logging } from '../commons';
import { actions as userActions } from '../services/userservice';
import { LoadingState, LoadingProgress } from '@inwink/loadable';
import { OidcView } from '@inwink/oidcview';
import { States } from '../services/services';
import './appshell.authentication.less';
interface IAuthenticationProps {
    event: States.IEventState;
    userActions?: typeof userActions;
    i18n: States.i18nState;
}

interface IAuthenticationState {
    userchecked: boolean;
    progress: { message: string };
}

@connectwith(null, (dispatch) => ({ userActions: bindActionCreators(userActions, dispatch) }))
export class Authentication extends Component<IAuthenticationProps, IAuthenticationState> {
    unmounted: boolean;
    constructor(props: IAuthenticationProps) {
        super(props);
        this.state = {
            userchecked: false,
            progress: {
                message: "appinit.checkauthentication"
            }
        };
    }

    authenticated = (arg) => {
        return this.props.userActions.checkCurrentUser();
    }

    failed = () => {
        return;
    }

    componentDidMount() {
        let action = this.props.userActions.checkCurrentUser() as any as Promise<any>;
        action.then(null, (err) => {
            console.warn(err);
        }).then(() => {
            if (!this.unmounted) {
                this.setState({ userchecked: true, progress: { message: "" } });
            }
        });
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    renderLoader(data) {
        return <Loader message={data.message} />;
    }

    render() {
        let rooturl = location.href;
        if (this.state.userchecked === true) {
            return <LoadingState isLoading={!this.state.userchecked}>
                <OidcView
                    eventid={this.props.event ? this.props.event.eventid : undefined}
                    rooturl={rooturl}
                    onLoading={this.renderLoader}
                    onAuthentication={this.authenticated}
                    onAuthenticationFailed={this.failed}
                    displayLanguageCode={this.props.i18n.currentLanguageCode} />
            </LoadingState>;
        }

        return <LoadingState isLoading={!this.state.userchecked}>
            <LoadingProgress progress={this.state.progress}>
                <div className="oidcview">
                    <div className="panel loading"><Loader className="big" display="bubbles" /></div>
                </div>
            </LoadingProgress>
        </LoadingState>;
    }
}

import { React } from '../../commons';
import type { IPopinContentProps } from './appshelluser.popin';
import { getBackOfficeEntityDataSource } from '../../api/backoffice';
import { ContentQuickSearch } from '../../routes/common/contentquicksearch';

export class UserProfilePopinContentSearch extends React.Component<IPopinContentProps, any> {
    constructor(props: IPopinContentProps) {
        super(props);
        const routingEventDatasource = getBackOfficeEntityDataSource('', 'RoutingEvent', '');
        const fakeMyEventDatasource = getBackOfficeEntityDataSource('', 'me/events', '');
        routingEventDatasource.query = fakeMyEventDatasource.query;
        routingEventDatasource.count = fakeMyEventDatasource.count;
        const routingCommunityDatasource = getBackOfficeEntityDataSource('', 'RoutingCommunity', '');
        const fakeMyCommunityDatasource = getBackOfficeEntityDataSource('', 'me/communities', '');
        routingCommunityDatasource.query = fakeMyCommunityDatasource.query;
        routingCommunityDatasource.count = fakeMyCommunityDatasource.count;

        this.state = {
            eventDatasource: (routingEventDatasource as any),
            communityDatasource: (routingCommunityDatasource as any)
        };
    }

    contentClicked = () => {
        this.props.userpopinContext.togglePopin();
    };

    render() {
        return <ContentQuickSearch
            eventDatasource={this.state.eventDatasource}
            communityDatasource={this.state.communityDatasource}
            popovermgr={this.props.popovermgr}
            onContentClicked={this.contentClicked}
        />;
    }
}

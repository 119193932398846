/* eslint-disable max-classes-per-file */
import type { Entities } from '@inwink/entities/entities';
import { AppLabel } from "@inwink/i18n";
import * as React from 'react';
import type { IPopoverManager, IPopoverDismissManager } from "@inwink/modals";
import { ICMSContext, CMSContextProvider } from '../cmscontext';
import { ModalContent } from '../ui/modals/modalcontent';
import type { IDataSourceV3 } from '../../api/base/datasource';
import { entityToSaveFormatv3 } from "./entityhelpers.save";
import { Wizard } from "../wizard/wizard";
import type { IWizardStep } from "../wizard/wizard.step";
import { EntityUpdateAllPageStep1 } from "./entityupdateallpage.step1";
import type { ISavePayloadEntityFormater } from "./formater/PayloadEntityFormaters";
import { EntityUpdateAllPageConfirm } from "./entityupdateallpage.confirm";
import { confirmModal } from "../ui/modals/confirmmodal";
import type { States } from '../../services/services';

import './entityupdateallpage.less';

export interface IEntityUpdateAllModalProps {
    numberItemSelected?: number;
    onCompleted?: (result?) => void;
    cmscontext?: ICMSContext;
    datasource: IDataSourceV3<any>;
    entityname: string;
    savePayloadFormatter?: ISavePayloadEntityFormater;
    popovermgr: IPopoverManager;
    popover?: IPopoverDismissManager;
    user?: States.IAppUserState;
}

interface IEntityUpdateAllModalState {
    template: any;
    steps: IWizardStep[];
    currentStep: number;
    customProps: any;
    hideFields?: string[];
    isDirty?: boolean;
}

export function EntityUpdateAllModal(props: IEntityUpdateAllModalProps) {
    return (
        <CMSContextProvider.Consumer>
            {(cmscontext) => <EntityUpdateAllModalComponent {...props} cmscontext={cmscontext} />}
        </CMSContextProvider.Consumer>
    );
}

class EntityUpdateAllModalComponent extends React.Component<IEntityUpdateAllModalProps, IEntityUpdateAllModalState> {
    constructor(props: IEntityUpdateAllModalProps) {
        super(props);

        const steps: IWizardStep[] = [firststep];

        this.state = {
            template: {},
            steps,
            currentStep: 0,
            hideFields: [],
            isDirty: false,
            customProps: {
                numberItemSelected: props.numberItemSelected,
                isvalid: false,
                popovermgr: props.popovermgr,
                cmscontext: props.cmscontext,
                entity: {},
                entitytemplate: null,
                populateWithMergeArrayEnabled: false,
                selectedFields: [],
                currenttemplate: { fields: [], scopes: {}, languages: [], oldKeys: [] },
                customFormComponent: null,
                datasource: props.datasource,
                entityname: props.entityname,
                fileInputManager: (props as any).fileInputManager,
                onEntityChanged: this.onEntityChanged,
                onValidationChanged: this.onValidationChanged,
                onFieldChanged: this.onFieldChanged,
                onChangePopulateWithMergeArray: this.onChangePopulateWithMergeArray,
                user: props.user
            }
        };
    }

    componentDidMount() {
        const { popover, cmscontext, datasource, entityname } = this.props;
        const { customProps, hideFields } = this.state;

        // When close modal:
        popover.addCanCloseHandler(() => {
            if (!this.state.isDirty) { return Promise.resolve(true); }

            return confirmModal(this.props.popovermgr, "modal.confirm.exit", "modal.confirm.exit.message",
                false, false, null, { disableOverlayDismiss: true }).then((confirmRes) => {
                return !!confirmRes;
            });
        });

        if (!customProps.entitytemplate) {
            const args: any = { ...this.props };
            cmscontext.getEntityConfiguration(entityname, args).then((entityconf) => {
                entityconf.detailConfig.configuration(args).then((entitydetailconf) => {
                    this.setState((prevState) => {
                        return {
                            customProps: {
                                ...prevState.customProps,
                                customFormComponent: entitydetailconf.customFormComponents,
                                hideFields: entitydetailconf.updateAll?.hideFields || []
                            },
                            hideFields: entitydetailconf.updateAll?.hideFields || []
                        };
                    }, () => {
                        datasource.entitytemplate(true).then((_entitytemplate: Entities.IEntityTemplate) => {
                            const entitytemplate = _entitytemplate;
                            this.setState((prevState) => {
                                if (entityname.toLowerCase() === 'person') {
                                    entitytemplate.fields = entitytemplate.fields
                                        .filter((f) => (f.key.toLowerCase() === 'kinds' || f.type.toLowerCase() !== 'entities')
                                        && (!hideFields
                                            || !hideFields.filter((h) => h.toLowerCase() === f.key.toLowerCase())[0]));
                                } else {
                                    entitytemplate.fields = entitytemplate.fields
                                        .filter((f) => f.type.toLowerCase() !== 'entities'
                                            && (!hideFields || !hideFields
                                                .filter((h) => h.toLowerCase() === f.key.toLowerCase())[0]));
                                }

                                return { ...prevState, customProps: { ...prevState.customProps, entitytemplate } };
                            });
                        }, (err) => {
                            console.error(err);
                        });
                    });
                });
            });
        }
    }

    onFieldChanged = (fields: string[]) => {
        const currenttemplate = {
            fields: this.state.customProps.entitytemplate.fields.filter((f) => fields.indexOf(f.key) >= 0)
        };

        this.setState((prevState) => {
            return {
                customProps: {
                    ...prevState.customProps,
                    currenttemplate,
                    selectedFields: fields
                },
                template: currenttemplate,
                isDirty: true
            };
        });
    };

    onEntityChanged = (entity: any, isvalid: boolean) => {
        this.setState((prevState) => {
            return {
                customProps: {
                    ...prevState.customProps,
                    entity,
                    isvalid
                },
                isDirty: true
            };
        });
    };

    onChangePopulateWithMergeArray = (value: boolean) => {
        this.setState((prevState) => {
            return {
                customProps: {
                    ...prevState.customProps,
                    populateWithMergeArrayEnabled: value
                },
                isDirty: true
            };
        });
    };

    onValidationChanged = (isvalid) => {
        this.setState((prevState) => {
            return {
                customProps: {
                    ...prevState.customProps,
                    isvalid
                }
            };
        });
    };

    stepChanged = (currentStep) => {
        this.setState({ currentStep });
    };

    openConfirmModal = () => {
        const props = {
            isvalid: this.state.customProps.isvalid,
            numberItemSelected: this.props.numberItemSelected
        };

        this.props.popovermgr.modalPortal(EntityUpdateAllPageConfirm, props).then((onSave) => {
            if (onSave) {
                const { customProps: { entity, populateWithMergeArrayEnabled }, template } = this.state;
                const cleanEntity: any = {};
                if (template && template.fields) {
                    template.fields.forEach((field) => {
                        cleanEntity[field.key] = entity[field.key];
                    });
                }
                const res = {
                    entity: entityToSaveFormatv3(cleanEntity, template, null, this.props.entityname, null),
                    populateWithMergeArray: populateWithMergeArrayEnabled
                };
                this.props.onCompleted(res);
            }
        });
    };

    render() {
        return (
            <ModalContent className="update-all-entity-modal" title="updateallmodal.title" onhide={this.props.onCompleted}>
                <Wizard
                    steps={this.state.steps}
                    stepChanged={this.stepChanged}
                    customProps={this.state.customProps}
                    renderBntValidate={() => {
                        return <button
                            type="button"
                            disabled={!this.state.customProps.isvalid}
                            className="important"
                            onClick={this.openConfirmModal}
                        ><AppLabel i18n="actions.validate" /></button>;
                    }}
                />
            </ModalContent>
        );
    }
}

const firststep = {
    id: 'start',
    label: 'addcontent.picktype',
    component: EntityUpdateAllPageStep1
};

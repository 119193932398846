import type { EntityPageAction, IEntityPageComputedActions, IEntityPageActionContext } from './entitypagebase.props';
import { getPrimaryActions, getMoreActions, getNavActions, getCustomActions } from './actions/utils';

export function computeEntityActions(context: IEntityPageActionContext): IEntityPageComputedActions {
    const { cmscontext, entityRights, viewsState, entityconfiguration, entityactions, helpers, runMode } = context;
    const res: IEntityPageComputedActions = {
        primaryActions: getPrimaryActions(cmscontext, entityRights, viewsState,
            entityconfiguration, entityactions, helpers, runMode)
            .filter((v) => {
                if (v && v.show) {
                    return v.show(context);
                }
                return true;
            })
    };

    let allActions = getMoreActions(cmscontext, entityRights, viewsState,
        entityconfiguration, entityactions, helpers, runMode) || [];
    if (allActions && allActions.length) {
        allActions = allActions.filter((action) => {
            if (action.show && !action.show(context)) {
                return false;
            }
            return true;
        });
    }

    let navActions = getNavActions(cmscontext, entityRights, viewsState, entityconfiguration, entityactions, helpers, runMode);
    if (navActions) {
        navActions = navActions.filter((action) => {
            if (action.show && !action.show(context)) {
                return false;
            }
            return true;
        });
    }

    let customActions = getCustomActions(cmscontext, entityRights, viewsState,
        entityconfiguration, entityactions, helpers, runMode);
    if (customActions) {
        customActions = customActions.filter((action) => {
            if (action.show && !action.show(context)) {
                return false;
            }
            return true;
        });
    }

    let selectionActions: EntityPageAction[] = [];
    let selectionSecondaryActions: EntityPageAction[] = [];
    let baseActions: EntityPageAction[] = [];
    let currentViewActions: EntityPageAction[] = [];

    if (allActions) {
        baseActions = allActions.filter((act) => {
            if (act.scope?.none) {
                return true;
            }
            return false;
        });

        currentViewActions = allActions.filter((act) => {
            if (act.scope.currentView && viewsState && viewsState.rowCount > 0) {
                return true;
            }
            return false;
        }).map((action) => {
            const a = { ...action };
            a.triggerFrom = "view";
            return a;
        });
    }

    const currentViewSecondaryActions: EntityPageAction[] = [];
    if (currentViewActions && currentViewActions.length) {
        currentViewSecondaryActions.push({
            key: "openfilters",
            label: "actions.filter",
            scope: { selection: true },
            callback: () => {
                return helpers.openFilters();
            }
        });
    }

    if (viewsState && viewsState.selection.length) {
        selectionActions = allActions && allActions.filter((act) => {
            if (act.scope.selection) {
                return true;
            }

            if (act.scope.selectionOnce && viewsState.selection.length === 1) {
                return true;
            }

            return false;
        }).map((action) => {
            const a = {...action};
            a.triggerFrom = "selection";
            return a;
        });
        selectionSecondaryActions = [];

        if (viewsState && viewsState.showSelection) {
            selectionSecondaryActions.push({
                key: "hideselection",
                label: "entitypage.hideselection",
                scope: { selection: true },
                callback: (arg, ctx) => {
                    ctx.viewManager.showSelection(false);
                }
            });
        } else {
            selectionSecondaryActions.push({
                key: "showselection",
                label: "entitypage.showselection",
                scope: { selection: true },
                callback: (arg, ctx) => {
                    ctx.viewManager.showSelection(true);
                }
            });
        }

        selectionSecondaryActions.push({
            key: "clearselection",
            label: "entitypage.clearselection",
            scope: { selection: true },
            callback: (arg, ctx) => {
                ctx.viewManager.setSelection([]);
            }
        });
    }

    if ((navActions && navActions.length) || selectionActions.length || selectionSecondaryActions.length
    || baseActions.length || currentViewActions.length || customActions?.length) {
        res.secondaryActions = {
            navActions: navActions,
            customActions,
            selectionActions: selectionActions,
            selectionSecondaryActions: selectionSecondaryActions,
            currentViewActions: currentViewActions,
            currentViewSecondaryActions: currentViewSecondaryActions
        };
    }

    return res;
}

/* eslint-disable @typescript-eslint/naming-convention */
import { React, AppLabel, AppTextLabel } from '../../../../commons';
import { DatePicker } from "../../../ui/configinputs";
import type { IExpressionProps } from "../definitions";

import * as moment from 'moment';

export class ExpressionDate extends React.Component<IExpressionProps, any> {

    constructor(props: IExpressionProps) {
        super(props);
        const entityName = props.fieldItem && props.fieldItem.entityname && props.fieldItem.entityname.toLowerCase();
        const entityFieldKey = props.entityField && props.entityField.key && props.entityField.key.toLowerCase();
        let vf = '';
        if (props.expressions) {
            vf = (props.expressions.and) ? props.expressions.and[0].val : props.expressions.val;
        }
        this.state = {
            value_first: vf,
            value_second: (props.expressions?.and && props.expressions.and.length > 1) ? props.expressions.and[1].val : '',
            operator: this.getOperator(props),
            removeTZmark: (entityName === 'timeslot'
                || entityName === 'sessiontimeslotview' || entityName === 'meetingtimeslotview') &&
                (entityFieldKey === 'startdate' || entityFieldKey === 'enddate') ? true : false
        };
    }

    componentDidMount() {
        const allowSave = this.allowSaveValue();
        if (allowSave !== this.props.allowSave) {
            this.props.setAllowSave(allowSave);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.expressions && !this.props.expressions && prevProps.inline && !this.props.inline)
            || (!prevProps.inline && this.props.inline && prevProps.fieldItem.key !== this.props.fieldItem.key)
        ) {
            this.setState({
                value_first: '',
                value_second: '',
                operator: this.getOperator(this.props)
            });
        }
        // le but est de set value_second a null si on a pas de $and dans l'expression
        if (prevProps.expressions && prevProps.expressions.and && this.props.expressions && !this.props.expressions.and) {
            this.setState({ value_second: null });
        }
    }

    getOperator = (props: IExpressionProps) => {
        if (this.props.entityconfig && this.props.entityconfig.grid && this.props.entityconfig.grid.filters
            && this.props.entityconfig.grid.filters[this.props.entityField.key]
            && this.props.entityconfig.grid.filters[this.props.entityField.key].operator
        ) {
            return this.props.entityconfig.grid.filters[this.props.entityField.key].operator;
        } else if (props.expressions) {
            if (props.expressions.and && props.expressions.and.length > 1) {
                // On verifie ici que les deux valeurs sont dans le même minute
                // pour que l'editeur face la diff entre en "eq" et un "between"
                const isEq = moment(props.expressions.and[0].val).isSame(props.expressions.and[1].val, 'minute');
                if (isEq) {
                    return 'eq';
                } else {
                    return 'between';
                }
            } else {
                return props.expressions.op;
            }
        } else {
            return 'between';
        }
    };

    formatExpression = () => {
        const and = [{
            name: this.props.fieldItem.key,
            op: this.state.operator,
            val: (this.state.operator === 'isnull' || this.state.operator === 'isnotnull')
                ? '' : this.formatDate(moment(this.state.value_first).startOf('minute'))
        }];
        if (this.state.operator === 'between') {
            and[0].op = 'gt';
            and.push({
                name: this.props.fieldItem.key,
                op: 'lt',
                val: this.state.value_second
            });
            // Dans le cas d'un 'eq', on fait une fourchette entre le debut de la minute et la fin de la minute #36020
        } else if (this.state.operator === "eq") {
            and[0].op = 'gte';
            and[0].val = this.formatDate(moment(this.state.value_first).startOf('minute'));
            and.push({
                name: this.props.fieldItem.key,
                op: 'lte',
                val: this.formatDate(moment(this.state.value_first).endOf('minute'), '59')
            });
        }

        this.props.setExpression((and.length === 1) ? and[0] : { and });
    };

    formatInlineExpression() {
        const and = [{
            name: this.props.fieldItem.key,
            op: this.state.operator,
            val: (this.state.operator === 'isnull' || this.state.operator === 'isnotnull')
                ? '' : this.formatDate(moment(this.state.value_first).startOf('minute'))
        }];

        if (this.state.operator === 'between') {
            and[0].op = 'gt';
            and.push({
                name: this.props.fieldItem.key,
                op: 'lt',
                val: this.state.value_second
            });
            // Dans le cas d'un 'eq', on fait une fourchette entre le debut de la minute et la fin de la minute #36020
        } else if (this.state.operator === "eq") {
            and[0].op = 'gte';
            and[0].val = this.formatDate(moment(this.state.value_first).startOf('minute'));
            and.push({
                name: this.props.fieldItem.key,
                op: 'lte',
                val: this.formatDate(moment(this.state.value_first).endOf('minute'), '59')
            });
        }

        // // Formatage de l'expression de type 'eq' pour le rendu en ligne
        // if (this.state.operator === "eq") {
        //     and[0].op = 'gt';
        //     and[0].val = moment(this.state.value_first).startOf('minute').toISOString();
        //     and.push({
        //         name: this.props.fieldItem.key,
        //         op: 'lt',
        //         val: moment(this.state.value_first).endOf('minute').toISOString()
        //     });
        //     return { and };
        // }
        return (and.length === 1) ? and[0] : { and };
    }

    formatDate = (date: moment.Moment, seconds = '00'): string => {
        return this.state.removeTZmark ? date.format(`YYYY[-]MM[-]DD[T]HH[:]mm[:]${seconds}`) : date.toISOString();
    };

    allowSaveValue = () => {
        // TODO: Condition a revoir ....
        return ((this.state.operator === 'between') ?
            (this.state.value_second !== '' && this.state.value_first !== '') ?
                true : false : ((this.state.value_first !== '' && this.state.operator !== '')
                    || (this.state.operator === 'isnull' || this.state.operator === 'isnotnull')) ? true : false);
    };

    handleChange = (arg, type) => {
        let reset = {};
        if (type === 'operator' && arg.target.value === '') {
            reset = {
                value_first: '',
                value_second: '',
            };
        }

        this.setState(Object.assign({}, { [type]: (type === 'operator') ? arg.target.value : arg }, reset), () => {
            const allowSave = this.allowSaveValue();
            if (allowSave !== this.props.allowSave) {
                this.props.setAllowSave(allowSave);
            }
            if (this.props.inline) {
                const expression = this.formatInlineExpression();
                this.props.updateInline({ expression });
                // if (this.state.operator === 'eq') {
                //     let expression = this.formatInlineExpression();
                //     this.props.updateInline({ expression });
                // } else {
                //     this.props.updateInline({
                //         operator: this.state.operator,
                //         propvalue: (this.state.operator === 'isnull'
                //              || this.state.operator === 'isnotnull') ? '' : this.state.value_first
                //     });
                // }
            } else {
                if (allowSave) {
                    this.formatExpression();
                }
            }
        });
    };

    renderInline = (options) => {
        const dpStart = <DatePicker
            className="reactdatepicker"
            disabled={((this.state.operator === ''
                || this.state.operator === 'isnull' || this.state.operator === 'isnotnull') ? true : false)}
            onChange={(dt?) => { this.handleChange((dt && dt.isValid()) ? this.formatDate(dt) : '', 'value_first'); }}
            selected={this.state.value_first ? moment(this.state.value_first) : null}
            minDate={moment(this.state.value_first)}
            maxDate={moment(this.state.value_second)}
            type="datetime"
            giveFocus={!this.state.value_first}
        />;
        const dpEnd = (this.state.operator !== 'between') ? null : <DatePicker
            className="reactdatepicker"
            onChange={(dt?) => { this.handleChange((dt && dt.isValid()) ? this.formatDate(dt) : '', 'value_second'); }}
            selected={this.state.value_second ? moment(this.state.value_second) : null}
            minDate={moment(this.state.value_first)}
            maxDate={moment(this.state.value_second)}
            type="datetime"
            giveFocus={!!this.state.value_first}
        />;
        return <div>
            <select id="operators" value={this.state.operator || ""} onChange={(arg) => this.handleChange(arg, 'operator')}>
                <AppTextLabel component="option" value="" i18n="" />
                {
                    options.map((op, i) => {
                        return <AppTextLabel key={i} component="option" value={op} i18n={"operators.date." + op} />;
                    })
                }
            </select>
            <div id="propvalue">
                {dpStart}
                {dpEnd}
            </div>
        </div>;
    };

    render() {
        let options = ["between", "eq", "neq", "gt", "gte", "lt", "lte"];

        const field = this.props.entityField;
        if (field && field.metadata && field.metadata.canBeNull) {
            options = [...options, ...['isnull', 'isnotnull']];
        }

        if (this.props.inline) {
            // options.shift();
            return this.renderInline(options);
        }

        return <div className="filter-date filter-string filter-type">
            <header>
                <span><AppLabel i18n="filter.indication" /></span>
                <select value={this.state.operator} onChange={(arg) => this.handleChange(arg, 'operator')}>
                    <option value=""></option>
                    {
                        options.map((option, i) => {
                            return <AppTextLabel key={i} component="option" value={option} i18n={"operators.date." + option} />;
                        })
                    }
                </select>
            </header>
            <section>
                <div
                    className={"date-section" +
                        ((this.state.operator === '' || this.state.operator === 'isnull' || this.state.operator === 'isnotnull')
                            ? ' disabled' : '')}
                    style={{ marginBottom: 10 }}>
                    <label><AppLabel i18n="filter.first_date" /></label>
                    <div className="date-wrapper">
                        <DatePicker
                            className="reactdatepicker"
                            disabled={((this.state.operator === ''
                                || this.state.operator === 'isnull' || this.state.operator === 'isnotnull') ? true : false)}
                            onChange={(dt?) => {
                                this.handleChange((dt && dt.isValid()) ? this.formatDate(dt) : '', 'value_first');
                            }}
                            selected={this.state.value_first ? moment(this.state.value_first) : null}
                            // On a pas besoin de la minDate sur le picker qui determine cette minDate
                            // minDate={moment(this.state.value_first)}
                            maxDate={moment(this.state.value_second)}
                            type="datetime"
                            giveFocus={!this.state.value_first}
                        />
                    </div>
                </div>
                <div className={"date-section" + ((this.state.operator !== 'between') ? ' disabled' : '')}>
                    <label><AppLabel i18n="filter.second_date" /></label>
                    <div className="date-wrapper">
                        <DatePicker
                            className="reactdatepicker"
                            disabled={((this.state.operator !== 'between') ? true : false)}
                            onChange={(dt?) => {
                                this.handleChange((dt && dt.isValid()) ? this.formatDate(dt) : '', 'value_second');
                            }}
                            selected={this.state.value_second ? moment(this.state.value_second) : null}
                            minDate={moment(this.state.value_first)}
                            // Idem pour la maxDate
                            // maxDate={moment(this.state.value_second)}
                            type="datetime"
                            giveFocus={!!this.state.value_first}
                        />
                    </div>
                </div>
            </section>
        </div>;
    }
}

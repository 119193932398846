import * as React from 'react';
import {InwinkLoader} from './inwinkloader';
import {TransitionGroup } from 'react-transition-group';
import { LoadingState } from '@inwink/loadable';
import { FadeTransition } from "../../components/transitions";

interface IGridLoaderProps {
    isFetching: boolean;
}

export class GridLoader extends React.Component<IGridLoaderProps, any> {
    render() {
        if (this.props.isFetching){
            return <LoadingState isLoading={this.props.isFetching}>
            </LoadingState>
        }

        return null;
    }
}
import React, { useEffect, useState } from 'react';
import { AppTextLabel } from "../../../commons";

interface IUrlInputRaw {
    readOnly?: boolean;
    value: string;
    onUrlChange?: (value: string) => any;
    onValidUrlChange?: (isValid: boolean) => any;
    needCheckUrl?: boolean;
    instanceId?: string;
}

export const UrlInputRaw = ({ readOnly, value, onUrlChange, onValidUrlChange, needCheckUrl }: IUrlInputRaw) => {
    const [urlTyping, setUrlTyping] = useState('');
    const [warning, setWarning] = useState(false);

    const style: any = {};
    if (warning) { style.border = '1px solid red'; }

    const handleValidation = (url: string) => {
        let isValid = true;

        if (needCheckUrl) {
            // eslint-disable-next-line max-len
            const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
            const regex = new RegExp(expression);
            isValid = !!url.match(regex);

            setWarning(url === '' ? false : !isValid);
        }

        return isValid;
    };

    useEffect(() => {
        setUrlTyping(value);
        handleValidation(value);
    }, [value]);

    return (
        <div>
            <input
                style={style}
                type="url"
                value={urlTyping || value}
                readOnly={readOnly}
                onChange={(arg) => {
                    const url: string = arg.currentTarget.value;
                    const isValid = handleValidation(url);

                    setUrlTyping(url);
                    if (onUrlChange) { onUrlChange(url); }
                    if (onValidUrlChange) { onValidUrlChange(url === '' ? true : isValid); }
                }}
            />

            { warning && (
                <div className="entityform">
                    <div className="fielderror">
                        <AppTextLabel component="div" className="msg" i18n={"urlinput.warning"} />
                    </div>
                </div>
            ) }
        </div>
    );
};

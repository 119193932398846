/* eslint-disable max-classes-per-file */
import { Popover, IPopoverManager } from '@inwink/modals';
import type { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n';
import type { ICMSContext } from "../cmscontext";
import { React, AppTextLabel } from '../../commons';

import './entityfields.less';

export interface IEntityFieldsListProps {
    i18nHelper?: Entities.i18nHelper;
    cmscontext: ICMSContext;
    selectedfields?: string[];
    fieldschanged?: (fields: string[]) => void;
    fieldsLoaded?: (template: Entities.IEntityTemplate) => void;
    entitytemplate?: Entities.IEntityTemplate;
    entityPropertyName?: string;
    onlyStaticFields?: boolean;
    onlyDynamicFields?: boolean;
    allowedScopes?: string[];
    includeTypes?: string[];
    excludeTypes?: string[];
    includeFields?: string[];
    additionalFields?: string[];
    excludeFields?: string[];
    entityname: string;
    multiselect?: boolean;
}

@withI18nHelper()
export class EntityFieldsList extends React.Component<IEntityFieldsListProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            entitytemplate: props.entitytemplate,
            filter: ""
        };
    }

    componentDidMount() {
        if (!this.state.entitytemplate) {
            this.props.cmscontext.getEntityTemplate(this.props.entityname).then((entitytemplate) => {
                this.setState({ entitytemplate: entitytemplate });
                if (this.props.fieldsLoaded) {
                    this.props.fieldsLoaded(entitytemplate);
                }
            }, (err) => {
                console.error(err);
            });
        }
    }

    filterChanged = (arg: React.FormEvent<any>) => {
        this.setState({ filter: arg.currentTarget.value });
    };

    fieldClicked = (arg, field: Entities.IEntityFieldTemplate) => {
        const fields = this.props.selectedfields || [];
        let newlist = [...fields];

        const idx = newlist.indexOf(field.key);
        if (idx >= 0) {
            newlist.splice(idx, 1);
        } else if (this.props.multiselect) {
            newlist.push(field.key);
        } else {
            newlist = [field.key];
        }
        this.props.fieldschanged(newlist);
    };

    render() {
        let content;
        if (this.state.entitytemplate) {
            const template: Entities.IEntityTemplate = this.state.entitytemplate;
            let entityfields = template.fields;

            if (this.props.includeFields?.length || this.props.excludeFields?.length
                || this.props.includeTypes?.length || this.props.excludeTypes?.length) {
                const lowerFilter = this.state.filter && this.state.filter.toLowerCase();
                entityfields = entityfields.filter((f) => {
                    if (lowerFilter && this.props.cmscontext.displayLanguage) {
                        let txt = null;
                        if (f.labels) {
                            txt = f.labels[this.props.cmscontext.displayLanguage];
                        } else {
                            txt = this.props.i18nHelper.translate((this.props.entityname + "." + f.key).toLowerCase());
                        }

                        if (!txt) {
                            txt = (this.props.entityname + "." + f.key).toLowerCase();
                        }

                        if (!(txt.toLowerCase().indexOf(lowerFilter) >= 0)) {
                            return false;
                        }
                    }
                    if (this.props.allowedScopes && !(this.props.allowedScopes.some((sc) => {
                        return template.scopes[f.key] && template.scopes[f.key].length
                            && template.scopes[f.key].some((s) => s === sc);
                    }))) {
                        return false;
                    }
                    if (this.props.includeFields && !(this.props.includeFields.indexOf(f.key.toLowerCase()) >= 0)) {
                        return false;
                    }

                    if (this.props.excludeFields && (this.props.excludeFields.indexOf(f.key.toLowerCase()) >= 0)) {
                        return false;
                    }

                    if (this.props.includeTypes && !(this.props.includeTypes.indexOf(f.type.toLowerCase()) >= 0)) {
                        return false;
                    }

                    if (this.props.excludeTypes && (this.props.excludeTypes.indexOf(f.type.toLowerCase()) >= 0)) {
                        return false;
                    }
                    if (this.props.onlyStaticFields && !f.isStatic) {
                        return false;
                    }
                    if (this.props.onlyDynamicFields && f.isStatic) {
                        return false;
                    }

                    return true;
                });
            }

            if (entityfields && entityfields.length) {
                if (this.props.additionalFields) {
                    (this.props.additionalFields).forEach((e) => {
                        const t: any = { key: e };

                        entityfields.push(t as Entities.IEntityFieldTemplate);
                    });
                }

                const fields = entityfields.map((f) => {
                    const selected = this.props.selectedfields && this.props.selectedfields.indexOf(f.key) >= 0;
                    let txt: any;
                    if (f.labels && this.props.cmscontext.displayLanguage) {
                        const currentLanguage = this.props.i18nHelper?.i18n?.currentLanguageCode
                            || this.props.cmscontext?.displayLanguage;
                        txt = f.labels[currentLanguage];
                    }

                    if (!txt) {
                        txt = <AppTextLabel
                            i18n={this.props.entityname.toLowerCase() + "." + f.key.toLowerCase()}
                            defaultText={f.key}
                        />;
                    }

                    return <div
                        key={f.key}
                        data-code={f.key}
                        className={"field item clickable " + (selected ? "selected" : "")}
                        onClick={(arg) => this.fieldClicked(arg, f)}
                    >
                        {/* <div className="marker"><i className="inwink-checked"></i></div> */}
                        <div className="label">{txt}</div>
                    </div>;
                });

                content = <div className="entityfieldslist-items">
                    <div className="entityfieldslist-items-filter">
                        <input type="search" value={this.state.filter} onChange={this.filterChanged} />
                    </div>
                    <div className="entityfieldslist-items-wrapper">
                        {fields}
                    </div>
                </div>;
            } else {
                content = <div className="entityfieldslist-items">
                    <div className="entityfieldslist-items-filter">
                        <input type="search" value={this.state.filter} onChange={this.filterChanged} />
                    </div>
                    <div className="message">
                        <AppTextLabel i18n="entityfieldspicker.nofieldavailable" />
                    </div>
                </div>;
            }
        } else {
            content = <div className="message">
                <AppTextLabel i18n="entityfieldspicker.nofieldavailable" />
            </div>;
        }
        return <div className="entityfieldslist">{content}</div>;
    }
}

export interface IEntityFieldsPickerProps {
    cmscontext: ICMSContext;
    entityname: string;
    fields: string[];
    classNames?: string[];
    includeTypes?: string[];
    excludeTypes?: string[];
    includeFields?: string[];
    excludeFields?: string[];
    onlyStaticFields?: boolean;
    onlyDynamicFields?: boolean;
    onChange: (fields: string[]) => void;
    entityPropertyName?: string;
    entitytemplate?: Entities.IEntityTemplate;
    multiselect?: boolean;
    readonly?: boolean;
    placeholder?: string;
    additionalFields?: string[];
}

export class EntityFieldsPicker extends React.Component<IEntityFieldsPickerProps, any> {
    constructor(props: IEntityFieldsPickerProps) {
        super(props);
        this.state = {
            showpicker: false,
            pickerparent: null,
            entitytemplate: props.entitytemplate,
            fields: props.fields
        };
    }

    pickFields = (arg: React.MouseEvent<HTMLElement>) => {
        this.setState({ showpicker: true, pickerparent: arg.target });
    };

    componentDidMount() {
        if (!this.state.entitytemplate) {
            this.loadTemplate();
        }
    }

    loadTemplate = () => {
        this.props.cmscontext.getEntityTemplate(this.props.entityname).then((entitytemplate) => {
            this.setState({ entitytemplate: entitytemplate });
        }, (err) => {
            console.error(err);
        });
    };

    componentDidUpdate(prevProps: IEntityFieldsPickerProps) {
        if (prevProps.fields !== this.props.fields) {
            this.setState({ fields: this.props.fields });
        }
        if (prevProps.entityname !== this.props.entityname
            || prevProps.entitytemplate !== this.props.entitytemplate) {
            this.setState({ entitytemplate: this.props.entitytemplate }, () => {
                if (!this.state.entitytemplate) {
                    this.loadTemplate();
                }
            });
        }
    }

    fieldChanged = (fields: string[]) => {
        this.setState({ fields: fields }, () => {
            this.props.onChange(fields);
            if (!this.props.multiselect) {
                this.setState({ showpicker: false });
            }
        });
    };

    remove = (arg, key: string) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.setState((prevState) => {
            return { fields: prevState.fields.filter((f) => f !== key) };
        }, () => {
            this.props.onChange(this.state.fields);
            if (!this.props.multiselect) {
                this.setState({ showpicker: false });
            }
        });
    };

    render() {
        let content;
        let popover;
        if (this.state.fields && this.state.fields.length) {
            content = <div>
                {this.state.fields.map((fieldcode) => {
                    let label: any = fieldcode;
                    if (this.state.entitytemplate) {
                        let field;
                        let propertyNameFieldcode;
                        if (this.props.entityPropertyName) {
                            propertyNameFieldcode = fieldcode.replace(this.props.entityPropertyName + '.', '');
                            field = this.state.entitytemplate.fields.filter((f) => f.key === propertyNameFieldcode)[0];
                        } else {
                            field = this.state.entitytemplate.fields.filter((f) => f.key === fieldcode)[0];
                        }

                        if (field) {
                            let tmp;
                            if (field.labels && this.props.cmscontext && this.props.cmscontext.displayLanguage) {
                                tmp = field.labels[this.props.cmscontext.displayLanguage];
                            }

                            if (tmp) {
                                label = tmp;
                            } else if (this.props.entityPropertyName) {
                                label = <AppTextLabel
                                    i18n={this.props.entityname.toLowerCase() + "." + propertyNameFieldcode.toLowerCase()}
                                    defaultText={propertyNameFieldcode}
                                />;
                            } else {
                                label = <AppTextLabel
                                    i18n={this.props.entityname.toLowerCase() + "." + fieldcode.toLowerCase()}
                                    defaultText={fieldcode}
                                />;
                            }
                        } else {
                            label = <AppTextLabel i18n={`${this.props.entityname?.toLowerCase()}.${fieldcode.toLowerCase()}`} />;
                        }
                    }
                    let action;
                    if (!this.props.readonly) {
                        action = <i onClick={(arg) => { this.remove(arg, fieldcode); }} className="inwink-cancel" />;
                    }
                    return <div
                        onClick={!this.props.readonly && !this.props.multiselect ? this.pickFields : null}
                        key={fieldcode}
                        data-code={fieldcode}
                        className="field item"
                    >{label}{action}</div>;
                })}
                {
                    !this.props.multiselect ? ""
                        : <div className="field item select" onClick={!this.props.readonly ? this.pickFields : null}>
                            <AppTextLabel i18n="picker.clicktoselect" /></div>
                }

                <div style={{ clear: 'both' }} />
            </div>;
        } else {
            content = <div>
                <div
                    className="field item select"
                    onClick={!this.props.readonly ? this.pickFields : null}
                ><AppTextLabel i18n="picker.clicktoselect" /></div>
                <div style={{ clear: 'both' }} />
            </div>;
        }

        if (!this.props.readonly) {
            const classNames = ["entityfields-popover", "popover-dropdownmultiple"];
            if (this.props.classNames && this.props.classNames.length >= 1) {
                classNames.push(...this.props.classNames);
            }
            popover = <Popover
                className={classNames.join(" ")}
                show={this.state.showpicker}
                parent={this.state.pickerparent}
                onhide={() => this.setState({ showpicker: false })}
            >
                <div className="popover-dropdownmultiple-content">
                    <EntityFieldsList
                        cmscontext={this.props.cmscontext}
                        additionalFields={this.props.additionalFields}
                        multiselect={this.props.multiselect}
                        entityname={this.props.entityname}
                        entityPropertyName={this.props.entityPropertyName}
                        selectedfields={this.state.fields}
                        includeTypes={this.props.includeTypes}
                        excludeTypes={this.props.excludeTypes}
                        includeFields={this.props.includeFields}
                        excludeFields={this.props.excludeFields}
                        onlyStaticFields={this.props.onlyStaticFields}
                        onlyDynamicFields={this.props.onlyDynamicFields}
                        fieldschanged={this.fieldChanged}
                        entitytemplate={this.state.entitytemplate}
                    />
                    <div style={{ clear: 'both' }} />
                </div>
            </Popover>;
        }

        return <div
            className={"entityfieldspicker" + (this.props.readonly ? " readonly" : " clickable")
                + (this.props.multiselect ? " multiselect" : "")}
        >
            <div className={(this.props.readonly ? "" : "pseudoinput") + " items"}>
                {content}
            </div>

            {popover}
        </div>;
    }
}

export interface IEntityFieldsPopoverProps extends IEntityFieldsListProps {
    onCompleted?: (res?) => void;
    selectedfields?: any;
}

// tslint:disable-next-line: max-classes-per-file
export class EntityFieldsPopover extends React.Component<IEntityFieldsPopoverProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            selectedfields: props.selectedfields || []
        };
    }

    fieldChanged = (fields: string[]) => {
        this.setState({ selectedfields: fields }, () => {
            if (!this.props.multiselect) {
                this.props.onCompleted(fields);
            }
            if (this.props.fieldschanged) {
                this.props.fieldschanged(fields);
            }
        });
    };

    render() {
        return <div className="popover-dropdownmultiple-content">
            <EntityFieldsList
                cmscontext={this.props.cmscontext}
                additionalFields={this.props.additionalFields}
                multiselect={this.props.multiselect}
                entityname={this.props.entityname}
                entityPropertyName={this.props.entityPropertyName}
                selectedfields={this.state.selectedfields}
                allowedScopes={this.props.allowedScopes}
                includeTypes={this.props.includeTypes}
                excludeTypes={this.props.excludeTypes}
                includeFields={this.props.includeFields}
                excludeFields={this.props.excludeFields}
                onlyStaticFields={this.props.onlyStaticFields}
                onlyDynamicFields={this.props.onlyDynamicFields}
                fieldschanged={this.fieldChanged}
                entitytemplate={this.props.entitytemplate}
            />
            <div style={{ clear: 'both' }} />
        </div>;
    }
}

export function pickField(popovermgr: IPopoverManager, target: any, props: IEntityFieldsPopoverProps) {
    return popovermgr.popoverPortal(target, EntityFieldsPopover, props, "entityfields-popover popover-dropdownmultiple");
}

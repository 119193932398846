import type { IPopoverDismissManager } from '@inwink/modals';
import { React, loadAssets, IPopoverManager } from '../commons';
import { ModalContent, IModalAction } from "./ui/modals/modalcontent";
import { CodeEditor } from './codeeditor/codeeditor';
import { smartconfirmModal } from './ui/modals/confirmmodal';

import './jsoneditormodal.less';

interface IJsonEditorModalProps {
    popover?: IPopoverDismissManager;
    popovermgr: IPopoverManager;
    obj: any;
    schema?: string;
    language?: string;
    onCompleted?: (res) => void;
    readOnly?: boolean;
    modalTitle?: string;
}

export class JsonEditorModal extends React.Component<IJsonEditorModalProps, any> {
    releasePopoverDismiss: () => void;

    constructor(props: IJsonEditorModalProps) {
        super(props);

        const srctext = (this.props.obj && typeof this.props.obj !== "string"
            && JSON.stringify(this.props.obj, null, '\t')) || this.props.obj || "";
        const show = (typeof this.props.readOnly !== 'undefined') ? !this.props.readOnly : true;
        this.state = {
            obj: this.props.obj,
            txt: srctext,
            srctext: srctext,
            actions: [{
                i18n: "actions.save",
                direction: "right",
                isImportant: true,
                callback: () => {
                    if (this.releasePopoverDismiss) {
                        this.releasePopoverDismiss();
                    }

                    if (this.props.onCompleted) {
                        this.props.onCompleted(this.state.obj);
                    }
                },
                show: () => show
            }] as IModalAction[],
        };
        loadAssets([
            { url: (inwink.config.assetsUrl || "/") + "assets/styles/jsoneditor.css", type: "css" },
        ]);
    }

    componentDidMount() {
        if (this.props.popover) {
            this.releasePopoverDismiss = this.props.popover.addCanCloseHandler(this.canClosePage);
        }
    }

    componentWillUnmount() {
        if (this.releasePopoverDismiss) {
            this.releasePopoverDismiss();
        }
    }

    canClosePage = () => {
        if (this.state.txt !== this.state.srctext) {
            const trad = "actions.confirmleavepage";
            const title = trad + ".title";
            const message = trad + ".message";

            return smartconfirmModal(this.props.popovermgr, {
                title: title,
                message: message
            }).then((confirmed) => {
                return confirmed;
            });
        }

        return Promise.resolve(true);
    };

    hideCatalog = () => {
        if (this.props.onCompleted) {
            this.props.onCompleted(null);
        }
    };

    render() {
        return <ModalContent
            title={this.props.modalTitle || "JSON Editor"}
            className="jsoneditormodalcontent"
            onhide={this.hideCatalog}
            actions={this.state.actions}
        >
            <CodeEditor
                language={this.props.language || "json"}
                content={this.state.txt}
                schema={this.props.schema}
                readonly={this.props.readOnly}
                onChange={(val) => {
                    if (!this.props.language || this.props.language === "json") {
                        try {
                            const json = JSON.parse(val);
                            this.setState({ obj: Object.assign({}, json), txt: val });
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        this.setState({ obj: val, txt: val });
                    }
                }}
            />
        </ModalContent>;
    }
}

export function editJsonModal(popovermgr: IPopoverManager, obj, schema, language?,
    readOnly?: boolean, className?: string, modalTitle?: string) {
    return popovermgr.modalPortal(JsonEditorModal, {
        popovermgr,
        obj: obj,
        schema: schema,
        language: language,
        readOnly,
        modalTitle
    }, "jsoneditormodal " + className, { disableOverlayDismiss: true });
}

export function editJsModal(popovermgr: IPopoverManager, obj, schema, language?, readOnly?: boolean) {
    return popovermgr.modalPortal(JsonEditorModal, {
        popovermgr,
        obj: obj,
        schema: schema,
        language: 'javascript',
        readOnly
    }, "jsoneditormodal", { disableOverlayDismiss: true });
}

export function editCSSModal(popovermgr: IPopoverManager, obj, schema, language?, readOnly?: boolean) {
    return popovermgr.modalPortal(JsonEditorModal, {
        popovermgr,
        obj: obj,
        schema: schema,
        language: 'css',
        readOnly
    }, "jsoneditormodal", { disableOverlayDismiss: true });
}

import type { Entities } from '@inwink/entities/entities';
import { i18NHelperContext } from '@inwink/i18n';
import { parse } from '@inwink/utils/querystring';
import { IPopoverManager, WithPopoverManager } from '@inwink/modals';
import type { States } from '../../../services/services';
import type { ILocationProp } from '../../../data/entities';
import { AppTextLabel, connectwith, LoadingState, React } from '../../../commons';
import { CMSContextProvider, ICMSContext } from "../../cmscontext";
import { IOnboardingContext, OnboardingContext } from "../../onboarding/context";
import { VisualStateSwitch } from "../../ui/visualstateswitch";
import { getEntityPageRights } from '../../../services/rightsservice';
import { loadEntityDetailModule } from '../entitydetailv3/openEntityDetail';
import { computeEntityActions } from './computeactions';
import { EntityScreenDesktop } from './entitypagebase.desktop';
import type {
    IEntityPageBaseProps,
    IEntityPageComputedActions,
    IEntityPageConfiguration,
    IEntityScreenHelpers,
    IEntityViewsState
} from './entitypagebase.props';
import { EntityViewsManager } from './entitypagebase.viewmgr';
import { getHelpers } from './entitypagehelpers';
import { EntityScreenSmartphone } from './phone';

import './entitypage.less';

export interface IEntityPageState {
    templateLoading: boolean;
    searchLoader: boolean;
    entityRights: InWinkBO.IEntityRights;
    entityconfiguration: IEntityPageConfiguration;
    entityConfs: Record<string, IEntityPageConfiguration>;
    entityconfigurationArgs: any;
    isLoading: boolean;
    template: Entities.IEntityTemplateV3;
    hasError?: boolean;
    errorMessage?: string;
    viewsState?: IEntityViewsState;
    helpers: IEntityScreenHelpers;
    computedActions: IEntityPageComputedActions;
}

export function EntityPage(props: IEntityPageBaseProps) {
    return <OnboardingContext.Consumer>
        {(onboardingcontext) => <i18NHelperContext.Consumer>
            {(i18nHelper) => <WithPopoverManager>
                {(popovermgr) => <CMSContextProvider.Consumer>
                    {(cmscontext) => <EntityPageContent
                        {...props}
                        popovermgr={popovermgr}
                        cmscontext={cmscontext}
                        i18nHelper={i18nHelper}
                        onboardingcontext={onboardingcontext}
                    />}
                </CMSContextProvider.Consumer>}
            </WithPopoverManager>}
        </i18NHelperContext.Consumer>}
    </OnboardingContext.Consumer>;
}

export interface IEntityPageContentProps extends IEntityPageBaseProps {
    cmscontext: ICMSContext;
    onboardingcontext: IOnboardingContext;
    popovermgr: IPopoverManager;
    i18n?: States.i18nState;
    i18nHelper: Entities.i18nHelper;
    customer?: States.ICustomerState;
    location?: ILocationProp;
}

@connectwith((state: States.IAppState) => {
    return {
        i18n: state.i18n,
        customer: state.customer,
        user: state.user
    };
})
export class EntityPageContent extends React.Component<IEntityPageContentProps, IEntityPageState> {
    viewManager: EntityViewsManager;

    constructor(props: IEntityPageContentProps) {
        super(props);

        this.state = {
            templateLoading: true,
            template: null,
            entityconfiguration: null,
            searchLoader: false,
            entityconfigurationArgs: this.buildArgs(props),
            entityRights: null,
            entityConfs: null,
            isLoading: this.props.configurationProvider && !this.props.entityconfiguration,
            computedActions: null,
            helpers: {
                ...getHelpers(this),
                // onRowCountChanged: this.onRowCountChanged,
                reloadEntityTemplate: this.loadEntityTemplate,
                setLoadingState: this.setLoadingState
            }
        };
    }

    initViewsManager(conf: IEntityPageConfiguration) {
        if (conf) {
            let query;
            const startFilters = {
                filters: Object.assign({}, conf.datasourcedefaultexpr),
                search: null,
                initialStartFilters: null
            };
            if (this.props.location && this.props.location.search) {
                query = parse(this.props.location.search);
                if (query && query.filter) {
                    const filters = Object.assign({}, startFilters.filters, JSON.parse(query.filter));
                    startFilters.filters = filters;
                }
                if (query && query.search) {
                    startFilters.search = query.search;
                }
            }

            // if (this.props.forceDefaultView === true) {
            //     startFilters = { filters: null, search: null, initialStartFilters: null };
            // }

            if (this.props.startFilters) {
                startFilters.initialStartFilters = Object.assign({}, this.props.startFilters);
            }

            const supportedLanguages = this.props.cmscontext
                && this.props.cmscontext.configuration
                && this.props.cmscontext.configuration.global
                && this.props.cmscontext.configuration.global.supportedLanguages;

            this.viewManager = new EntityViewsManager(
                (this.props.cmscontext && this.props.cmscontext.relatedTo) || "unknown",
                conf, this.props.i18n,
                supportedLanguages,
                this.props.startViewId,
                startFilters,
                this.props.initialSelection,
                this.props.runMode,
                this.props.forceDefaultView || false,
                this.props.configurationArgs
            );
            this.viewManager.stateChanged = this.viewManagerStateChanged;
        }
    }

    viewManagerStateChanged = (state: IEntityViewsState, prevState: IEntityViewsState) => {
        this.setState({ viewsState: state }, () => {
            if (state.searchExpression !== prevState.searchExpression) {
                if (this.props.expressionSearchChanged) {
                    this.props.expressionSearchChanged(state.queryExpression, state.currentview.content.search);
                }
            }
        });
    };

    loadEntityTemplate = () => {
        const entityconfiguration = this.state.entityconfiguration || this.props.entityconfiguration;
        if (entityconfiguration) {
            return entityconfiguration.datasource.worktemplate(true,
                this.props.runMode === 'removedentitypage').then((template) => {
                this.setState({
                    templateLoading: false,
                    template: template
                });

                this.loadRelatedEntitiesConfiguration(template);

                return template;
            }, (err) => {
                this.setState({
                    templateLoading: false,
                });
                console.error(err);
            });
        }
    };

    loadRelatedEntitiesConfiguration(template: Entities.IEntityTemplateV3) {
        const entityConfs = {};
        const promises = [];

        const recursiveEntityLoading = (fields: Record<string, Entities.IEntityFieldTemplateV3>) => {
            const fieldKeys = Object.keys(fields);
            fieldKeys.forEach((fieldKey) => {
                const currentField = fields[fieldKey];
                if (currentField?.template && (currentField.type === 'Entity' || currentField.type === 'Entities')) {
                    const entityName = currentField.value.toLowerCase();
                    if (!entityConfs[entityName]) {
                        const promise = this.props.cmscontext.getEntityConfiguration(entityName, {
                            ...this.state.entityconfigurationArgs,
                            isLinkedEntity: true
                        }).then((conf) => {
                            entityConfs[entityName] = conf;
                        }, (err) => {
                            entityConfs[entityName] = null;
                            console.warn('Unable to load Entity conf ' + entityName, err);
                        });
                        entityConfs[entityName] = promise;
                        promises.push(promise);
                    }

                    if (currentField.template?.fields) {
                        recursiveEntityLoading(currentField.template.fields);
                    }
                }
            });
        };

        recursiveEntityLoading(template.fields);

        Promise.all(promises)
            .then(() => {
                this.setState({ entityConfs });
            })
            .catch((err) => {
                console.warn('Unable to load Entity conf configuration', err);
            });
    }

    buildArgs(props: IEntityPageContentProps, conf = null) {
        return Object.assign({}, this.props.configurationArgs, {
            i18nHelper: this.props.i18nHelper,
            cmscontext: props.cmscontext,
            user: props.user,
            i18n: props.i18n,
            rights: props.rights,
            visualstate: props.pagevisualstate,
            customData: conf && conf.customData,
            viewMode: this.props.viewMode,
            runMode: this.props.runMode,
            popovermgr: this.props.popovermgr
        });
    }

    componentDidMount() {
        const entityconfiguration = this.state.entityconfiguration || this.props.entityconfiguration;
        this.initViewsManager(this.props.entityconfiguration);

        if (!this.props.entityconfiguration) {
            this.props.cmscontext.getEntityConfiguration(this.props.entityName,
                this.state.entityconfigurationArgs).then((res) => {
                return res;
            }, () => {
                return this.props.configurationProvider(this.props.entityName, this.state.entityconfigurationArgs);
            }).then((_conf) => {
                const conf = _conf;
                if (this.props.runMode === 'removedentitypage') {
                    // on désactive des fonctionnalités
                    conf.disableExport = true;
                    conf.disableImport = true;
                    conf.disableEntityConfiguration = true;
                    conf.detailConfig.disableCreate = true;
                    conf.detailConfig.disableDelete = true;
                    conf.detailConfig.disableEventImport = true;
                    conf.detailConfig.disableFileExport = true;
                    conf.detailConfig.disableMassUpdate = true;
                    conf.detailConfig.readOnly = true;
                    const oldConfig = conf.detailConfig.configuration;
                    conf.detailConfig.configuration = (args) => {
                        return oldConfig(args).then((_res) => {
                            const res = Object.assign({}, _res);
                            res.disableOtherTabs = true;
                            // res.disableHistoryTab = true;
                            const actions = res.customFormActions || [];
                            actions.push({
                                component: function cmpt(props: any) {
                                    const date = props.i18nHelper.getMoment(props.entity.validFrom + 'Z');
                                    return <AppTextLabel
                                        component="h4"
                                        className="removedentityinfo"
                                        i18n={props.entity.modifiedByDisplayName
                                            ? 'entity.history.lastitem' : 'entity.history.lastitem.anonymous'}
                                        inject={{
                                            date: date.format("LLLL"),
                                            modifiedByDisplayName: props.entity.modifiedByDisplayName
                                        }}
                                    />;
                                },
                                callback: () => Promise.resolve(),
                                i18n: ''
                            });
                            res.customFormActions = actions;
                            return res;
                        });
                    };

                    // on modifie les endpoints query et count pour qu'ils pointent sur ceux de removed
                    conf.datasource.query = conf.datasource.queryRemoved;
                    conf.datasource.count = conf.datasource.countRemoved;
                }
                this.initViewsManager(conf);
                this.setState({
                    isLoading: false,
                    entityconfiguration: conf,
                    entityconfigurationArgs: this.buildArgs(this.props, conf)
                }, () => {
                    this.loadEntityTemplate();
                });

                if (this.props.runMode === "entitypage") {
                    // on preload les éléments pour afficher le détail
                    conf.detailConfig.configuration().then(() => {
                        return loadEntityDetailModule();
                    }, () => {
                        console.error("Error loading entity details");
                    });
                }
            }).then(() => {
                if (this.props.onConfigurationReady) {
                    this.props.onConfigurationReady(this.state.entityconfiguration, this.state.entityRights);
                }
                if (this.props.startEntityId) {
                    const ctx = this.state.helpers.getActionContext(null, null);
                    this.state.helpers.openEntityDetail({ id: this.props.startEntityId }, false, false, ctx);
                }
            }, (err) => {
                console.error("error loading entity configuration", err);
                this.setState({
                    isLoading: false
                });
            });
        }

        if (entityconfiguration) {
            this.setState((prevState) => {
                const props = this.props;
                const rights = this.getRights(props);
                const actions = computeEntityActions({
                    cmscontext: props.cmscontext,
                    entityRights: rights,
                    viewsState: prevState.viewsState,
                    entityconfiguration: entityconfiguration,
                    entityactions: entityconfiguration.entityactions || props.entityactions,
                    helpers: prevState.helpers,
                    runMode: props.runMode
                });
                return {
                    entityRights: rights,
                    computedActions: actions
                };
            }, () => {
                this.loadEntityTemplate().then(() => {
                    if (this.props.onConfigurationReady) {
                        this.props.onConfigurationReady(entityconfiguration, this.state.entityRights);
                    }
                    if (this.props.startEntityId) {
                        const ctx = this.state.helpers.getActionContext(null, null);
                        this.state.helpers.openEntityDetail({ id: this.props.startEntityId }, false, false, ctx);
                    }
                });
            });
        }
    }

    showStartupEntity() {
        // TODO
    }

    componentDidUpdate(prevProps: IEntityPageContentProps, prevState: IEntityPageState) {
        let recalcActions = false;
        let rights = this.state.entityRights;

        // if (this.props.patchParent) {
        let sendPatch = false;
        const patch: any = {};

        const currentViewContent = this.state.viewsState?.currentview?.content;
        const previousViewContent = prevState.viewsState?.currentview?.content;
        // compare from null to something first to save perf - ORDER
        if (currentViewContent !== previousViewContent) {
            if (!previousViewContent || currentViewContent.order !== previousViewContent.order) {
                sendPatch = true;
                patch.order = currentViewContent.order;
            }

            if (!previousViewContent || currentViewContent.columnKeys !== previousViewContent.columnKeys) {
                sendPatch = true;
                patch.selectedColumns = currentViewContent.columnKeys;
            }
        }

        if (prevState.viewsState?.selection?.length !== this.state.viewsState?.selection?.length) {
            sendPatch = true;
            recalcActions = true;
            patch.selection = this.state.viewsState.selection;
        }

        const currentExpr = this.state.viewsState && this.state.viewsState.queryExpression;
        const previousExpr = prevState.viewsState && prevState.viewsState.queryExpression;

        if (currentExpr !== previousExpr) {
            sendPatch = true;
            patch.expression = currentExpr;
            recalcActions = true;
        }

        if (this.state.viewsState && (!prevState.viewsState
            || prevState.viewsState.rowCount !== this.state.viewsState.rowCount)) {
            sendPatch = true;
            recalcActions = true;
            patch.rowsLength = this.state.viewsState.rowCount;
        }

        const currentFilters = this.state.viewsState?.currentview?.content?.filters;
        const previousFilters = prevState.viewsState?.currentview?.content?.filters;

        if (currentFilters !== previousFilters) {
            sendPatch = true;
            patch.filters = currentFilters;
        }

        if (this.props.patchParent && sendPatch) {
            this.props.patchParent(patch);
        }
        // }

        if (prevProps.entityRights !== this.props.entityRights
            || prevProps.rights !== this.props.rights
            || (!prevState.entityconfiguration && this.state.entityconfiguration)) {
            rights = this.getRights(this.props);
            recalcActions = true;
            this.setState({
                entityRights: rights
            });
        }

        if (this.props.selectionChanged && this.state.viewsState && prevState?.viewsState
            && (prevState?.viewsState?.selection !== this.state.viewsState.selection)) {
            this.props.selectionChanged(this.state.viewsState.selection);
        }

        if (this.state.viewsState && (prevState?.viewsState?.selection !== this.state.viewsState.selection)) {
            recalcActions = true;
        }

        if (recalcActions) {
            const entityconfiguration = this.props.entityconfiguration || this.state.entityconfiguration;
            if (entityconfiguration) {
                this.setState((ps) => {
                    // const entityconfiguration = this.props.entityconfiguration || ps.entityconfiguration;
                    const actions = computeEntityActions(
                        {
                            cmscontext: this.props.cmscontext,
                            entityRights: rights,
                            viewsState: ps.viewsState,
                            entityconfiguration: entityconfiguration,
                            entityactions: entityconfiguration?.entityactions || this.props.entityactions,
                            helpers: ps.helpers,
                            runMode: this.props.runMode
                        }
                    );
                    return {
                        computedActions: actions
                    };
                });
            }
        }
    }

    getRights = (props: IEntityPageContentProps): InWinkBO.IEntityRights => {
        if (props.entityRights) {
            return props.entityRights;
        }

        const entityconfiguration = this.state.entityconfiguration || props.entityconfiguration;

        return getEntityPageRights(entityconfiguration, props.rights);
    };

    setLoadingState = (isUpdating: boolean) => {
        this.setState({ searchLoader: isUpdating });
    };

    // onRowCountChanged = (rowsLength) => {
    //     if (rowsLength !== this.state.rowsLength) {
    //         this.setState({ rowsLength });
    //     }
    // }

    searchtermChanged = (search: string) => {
        this.viewManager.updateCurrentViewContent({ search: search });
    };

    renderDesktop() {
        const entityconfiguration = this.state.entityconfiguration || this.props.entityconfiguration;

        return <EntityScreenDesktop
            {...this.props}
            computedActions={this.state.computedActions}
            entityactions={entityconfiguration.entityactions || this.props.entityactions}
            viewsManager={this.viewManager}
            viewsState={this.viewManager && this.viewManager.state}
            helpers={this.state.helpers}
            template={this.state.template}
            entityconfiguration={entityconfiguration}
            entityConfs={this.state.entityConfs}
            configurationArgs={this.state.entityconfigurationArgs}
            entityPageComponent={this}
            entityRights={this.state.entityRights}
            dashboardItems={this.props.children}
            searchLoader={this.state.searchLoader}
            onItemSelected={this.props.onItemSelected || entityconfiguration.onItemSelected}
        />;
    }

    renderSmartphone() {
        const entityconfiguration = this.state.entityconfiguration || this.props.entityconfiguration;
        return <EntityScreenSmartphone
            {...this.props}
            computedActions={this.state.computedActions}
            entityactions={entityconfiguration.entityactions || this.props.entityactions}
            viewsManager={this.viewManager}
            viewsState={this.viewManager && this.viewManager.state}
            helpers={this.state.helpers}
            template={this.state.template}
            entityconfiguration={entityconfiguration}
            entityConfs={this.state.entityConfs}
            configurationArgs={this.state.entityconfigurationArgs}
            entityRights={this.state.entityRights}
            entityPageComponent={this}
            dashboardItems={this.props.children}
            popovermgr={this.props.popovermgr}
            onItemSelected={this.props.onItemSelected || entityconfiguration.onItemSelected}
        />;
    }

    render() {
        const isLoading = (!(this.viewManager && this.viewManager.state.currentview)
            || !this.state.entityConfs || this.state.isLoading || this.state.templateLoading);
        const entityconfiguration = this.state.entityconfiguration || this.props.entityconfiguration;
        let content = null;
        const hasMobileTemplate = entityconfiguration?.list
            && (entityconfiguration.list.itemComponent || entityconfiguration.list.itemTemplate);

        if (this.viewManager?.state?.currentview && entityconfiguration && this.state.template && this.state.entityConfs) {
            if (this.props.viewMode === "grid") {
                content = this.renderDesktop();
            } else if (this.props.viewMode === "list") {
                content = this.renderSmartphone();
            } else if (hasMobileTemplate) {
                content = <VisualStateSwitch visualstate={this.props.pagevisualstate} visualLimit="M">
                    {this.renderDesktop()}
                    {this.renderSmartphone()}
                </VisualStateSwitch>;
            } else {
                content = this.renderDesktop();
            }
        }

        return <LoadingState isLoading={isLoading}>
            {content}
        </LoadingState>;
    }
}

export interface IEntityScreenProps extends IEntityPageContentProps {
    viewsManager: EntityViewsManager;
    viewsState: IEntityViewsState;
    template: Entities.IEntityTemplateV3;
    dashboardItems: any;
    entityPageComponent: any;
    sidebar?: any;
    searchLoader?: boolean;
    entityRights?: InWinkBO.IEntityRights;
    helpers: IEntityScreenHelpers;
    entityConfs: Record<string, IEntityPageConfiguration>;
    computedActions: IEntityPageComputedActions;
}

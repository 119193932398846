import { React } from '../../../../commons';
import type { IEntityScreenProps } from '../entitypagebase';

import "./selectiontoggle.less";

export interface ISelectionToggleProps extends IEntityScreenProps {
    onToggled?: (showSelection: boolean) => void;
}

export class SelectionToggle extends React.Component<ISelectionToggleProps, any> {
    toggleShowSelection = () => {
        const showSelection = !this.props.viewsState.showSelection;
        this.props.viewsManager.showSelection(showSelection);
        if (this.props.onToggled) {
            this.props.onToggled(showSelection);
        }
    };

    clearSelection = () => {
        this.props.viewsManager.setSelection([]);
        if (this.props.onToggled) {
            this.props.onToggled(this.props.viewsState.showSelection);
        }
    };

    render() {
        const selectionLength = this.props.viewsState?.selection?.length;
        return <span
            className="entitypage-selection-toggle"
            style={{ visibility: this.props.viewsState.showSelection } as any /* TS at it's finest */}
        >
            <button
                type="button"
                title={this.props.i18nHelper.translate(this.props.viewsState.showSelection
                    ? "entitypage.hideselection" : "entitypage.showselection")}
                className={`toggle-selection ${(this.props.viewsState?.showSelection) ? "show" : null}`}
                onClick={this.toggleShowSelection}
            >
                <i className="inwink-selected" />
                <span
                    className={`selection-counter ${(this.props.viewsState?.showSelection) ? "show" : null}`}
                >{`(${selectionLength})`}</span>
            </button>
            <button
                type="button"
                className="clearselection"
                title={this.props.i18nHelper.translate("entitypage.clearselection")}
                onClick={this.clearSelection}
            >
                <i className="inwink-dialog-cancel" />
            </button>
        </span>;
    }
}

import { React, AppTextLabel } from '../../../../commons';
import type { IExpressionProps } from "../definitions";

export class ExpressionString extends React.Component<IExpressionProps, any> {
    input = React.createRef<HTMLInputElement>();

    constructor(props: IExpressionProps) {
        super(props);

        const notContainsOp = props.expressions?.and && props.expressions?.and[0]?.not;
        let value = props.expressions?.val || '';

        if (notContainsOp && notContainsOp.val) {
            value = notContainsOp.val;
        }

        this.state = {
            value,
            operator: this.getOperator()
        };
    }

    componentDidUpdate(prevProps: IExpressionProps) {
        if (
            (prevProps.expressions && !this.props.expressions && prevProps.inline && !this.props.inline)
            || (!prevProps.inline && this.props.inline && prevProps.fieldItem.key !== this.props.fieldItem.key)
        ) {
            this.setState({
                value: '',
                operator: this.getOperator()
            }, () => this.input.current && this.input.current.focus());
        }
    }

    componentDidMount() {
        const allowSave = (this.state.value !== '' && this.state.operator !== '')
        || (this.state.operator === 'isnull') || (this.state.operator === 'isnotnull');
        if (allowSave !== this.props.allowSave) {
            this.props.setAllowSave(allowSave);
        }
    }

    getOperator = () => {
        let operator = "contains";
        if (this.props.expressions) {
            if (this.props.expressions.or) {
                operator = 'isnull';
            } else if (this.props.expressions?.and && this.props.expressions?.and[0]?.not) {
                operator = 'notcontains';
            } else if (this.props.expressions.and) {
                operator = 'isnotnull';
            } else {
                operator = this.props.expressions.op;
            }
        }
        return operator;
    };

    handleChange = (arg, type) => {
        this.setState({ [type]: arg.target.value }, () => {
            const allowSave = (this.state.value !== '' && this.state.operator !== '')
            || (this.state.operator === 'isnull') || (this.state.operator === 'isnotnull');
            if (allowSave !== this.props.allowSave) {
                this.props.setAllowSave(allowSave);
            }
            let e;
            const key = this.props.fieldItem.key;
            if (this.state.operator === 'notcontains') {
                e = {
                    and: [
                        {
                            name: key,
                            not: {
                                name: key,
                                op: 'contains',
                                val: this.state.value
                            }
                        }
                    ]
                };
                if (this.props.inline) {
                    e = { expression: e };
                }
            } else if (this.state.operator === 'isnull' || this.state.operator === 'isnotnull') {
                e = this.state.operator === 'isnull' ? {
                    or: [
                        {
                            name: key,
                            op: 'eq',
                            val: null
                        },
                        {
                            name: key,
                            op: 'eq',
                            val: ''
                        }
                    ]
                } : {
                    and: [
                        {
                            name: key,
                            op: 'neq',
                            val: null
                        },
                        {
                            name: key,
                            op: 'neq',
                            val: ''
                        }
                    ]
                };

                if (this.props.inline) {
                    e = { expression: e };
                }
            } else if (this.props.inline) {
                e = {
                    operator: this.state.operator,
                    propvalue: this.state.value
                };
            } else {
                e = {
                    name: key,
                    op: this.state.operator,
                    val: this.state.value
                };
            }
            if (this.props.inline) {
                this.props.updateInline(e);
            } else if (allowSave) {
                this.props.setExpression(e);
            }
        });
    };

    onKeyPress = (e) => {
        if (e.charCode === 13) {
            this.props.save();
        }
    };

    renderInline = (options) => {
        return <div>
            <select id="operators" value={this.state.operator || ""} onChange={(arg) => this.handleChange(arg, 'operator')}>
                <AppTextLabel component="option" value="" i18n="" />
                {
                    options.map((op, i) => {
                        return <AppTextLabel key={i} component="option" value={op} i18n={"operators." + op} />;
                    })
                }
            </select>
            <div id="propvalue">
                <input
                    onKeyPress={this.onKeyPress}
                    disabled={(this.state.operator === ''
                        || this.state.operator === 'isnull'
                        || this.state.operator === 'isnotnull')}
                    type="text"
                    value={this.state.value || ""}
                    onChange={(arg) => this.handleChange(arg, 'value')}
                />
            </div>
        </div>;
    };

    render() {
        let options = ["contains", "notcontains", "eq", "neq"];
        const field = this.props.entityField;

        if (field && field.metadata && field.metadata.canBeNull) {
            options = [...options, ...['isnull', 'isnotnull']];
        }

        if (this.props.inline) {
            return this.renderInline(options);
        }

        return <div className="filter-string filter-type">
            <header>
                <span><AppTextLabel i18n="filter.indication" /></span>
                <select value={this.state.operator} onChange={(arg) => this.handleChange(arg, 'operator')}>
                    <option value="" />
                    {
                        options.map((option, i) => {
                            return <AppTextLabel key={i} component="option" value={option} i18n={"operators." + option} />;
                        })
                    }
                </select>
            </header>
            <section>
                <div style={{ display: ((this.state.operator === ''
                || this.state.operator === 'isnull' || this.state.operator === 'isnotnull')) ? 'none' : 'block' }}
                >
                    <input
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={true}
                        ref={this.input}
                        onKeyPress={this.onKeyPress}
                        disabled={(this.state.operator === '' || this.state.operator === 'isnull'
                        || this.state.operator === 'isnotnull')}
                        type="text"
                        value={this.state.value}
                        onChange={(arg) => this.handleChange(arg, 'value')}
                    />
                </div>
            </section>
        </div>;
    }
}

import { Entities } from '@inwink/entities/entities';
import { getEventDetail, getCustomerEventDetail, getParentNames } from './events/eventdetail';
import { getEventEntityDataSource } from "./events/index";

export function detail(eventid: string, customerid: string) {
    // todo refacto
    const queries = [
        getEventDetail(eventid),
        getCustomerEventDetail(eventid, customerid),
        getParentNames(eventid).then((res) => res, () => null)
    ];
    return Promise.all(queries).then((res) => {
        return Object.assign({}, res[0], res[1], {
            parentNames: res[2]
        });
    });
}

export function entitytemplate(eventid, entity, includeStaticFields: boolean) {
    if (!eventid) {
        throw new Error("you must provide eventid");
    }

    const ds = getEventEntityDataSource<Entities.IEntityTemplate>(eventid, entity, entity);
    return ds.entitytemplate(includeStaticFields);
}

import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Entities } from '@inwink/entities/entities';
import { ReactNode } from "react";
import { React, bindActionCreators, AppLabel, AppTextLabel, LoadingState } from '../commons';
import { pageActions } from '../services/pageservice';
import { States } from '../services/services';
import { i18NHelperContext } from '../services/i18nservice';
import { IOnboardingContext, OnboardingContext } from "./onboarding/context";
import { ILocationMatchProp, ILocationProp } from '../data/entities';
import { trackError } from '../api/backoffice/tracking';
import { IRightsCheckBag, isAllowed } from '../services/rightsservice';

import './apppage.less';
import './apppage.transition.less';

export { AsyncPage, loadablePage, loadablePageFor } from './apppage.async';

export interface IAppPageProps extends IRightsCheckBag {
    i18n: States.i18nState;
    pageicon?: string;
    title?: string;
    titlePrefix?: string;
    onboarding?: string;
    subtitle?: string;
    featurecode?: string;
    noPadding?: boolean;
    isLoading?: boolean;
    className?: string;
    location?: ILocationProp;
    match?: ILocationMatchProp;
    pageActions?: typeof pageActions;
    disableSetTitleAndSubtitle?: boolean;
    userRights?: States.IAppUserRights;
    accessRight?: IRightsCheckBag;
    children?: ReactNode;
}

interface IAppPageContentProps extends IAppPageProps {
    onboardingcontext: IOnboardingContext;
    i18nHelper: Entities.i18nHelper;
}
export function AppPage(props: IAppPageProps) {
    return <i18NHelperContext.Consumer>
        {(i18nHelper) => <OnboardingContext.Consumer>
            {(onboarding) => <AppPageContent {...props} onboardingcontext={onboarding} i18nHelper={i18nHelper} />}
        </OnboardingContext.Consumer>}
    </i18NHelperContext.Consumer>;
}

class AppPageContentCtrl extends React.Component<IAppPageContentProps, any> {
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.error("page error", error, info);
        trackError({
            eventid: this.props.match && this.props.match.params && this.props.match.params.eventid,
            customerid: this.props.match && this.props.match.params && this.props.match.params.customerid
        }, error, "page error");
    }

    componentDidMount() {
        if (!this.props.disableSetTitleAndSubtitle) {
            this.props.pageActions.setTitle(this.props.title || "inwink", this.props.titlePrefix,
                this.props.featurecode, this.props.pageicon);
            this.props.pageActions.setSubtitle(this.props.subtitle || null);
        }

        if (!this.props.isLoading) {
            if (this.props.onboardingcontext) {
                this.props.onboardingcontext.setOnboarding(this.props.onboarding);
            }
        } else if (this.props.onboardingcontext) {
            this.props.onboardingcontext.setOnboarding(null);
        }
    }

    componentDidUpdate(prevProps: IAppPageProps) {
        if (!this.props.disableSetTitleAndSubtitle) {
            if (prevProps.subtitle !== this.props.subtitle) {
                this.props.pageActions.setSubtitle(this.props.subtitle || null);
            }
            if (prevProps.title !== this.props.title || prevProps.titlePrefix !== this.props.titlePrefix) {
                this.props.pageActions.setTitle(this.props.title || 'inwink', this.props.titlePrefix,
                    this.props.featurecode, this.props.pageicon);
            }
        }
        if (prevProps.isLoading && !this.props.isLoading) {
            if (this.props.onboardingcontext) {
                this.props.onboardingcontext.setOnboarding(this.props.onboarding);
            }
        }
    }

    shouldComponentUpdate(nextprops: IAppPageProps) {
        if (this.props.location) {
            const location = nextprops.location;
            if (location && location.pathname !== this.props.location.pathname) {
                // la page est en train de sortir, pas la peine de rafraichir son contenu
                // console.log("skip rendering " + this.props.location.pathname);
                return false;
            }
        }

        return true;
    }

    render() {
        const isAllow = isAllowed(this.props.userRights, this.props.accessRight);

        const classes = ["app-page"];
        if (this.props.noPadding) {
            classes.push("nopadding");
        }

        if (this.props.className) {
            classes.push(this.props.className);
        }

        const title = this.props.title ? this.props.i18nHelper.translate(this.props.title) : "inwink";
        let content;
        if (this.state && this.state.hasError) {
            content = <div className="pageerror"><AppLabel i18n="page.error" /></div>;
        } else if (isAllow) {
            content = this.props.children;
        } else {
            content = <div className="no-access pageerror">
                <i className="inwink-icon inwink-warning" />
                <AppTextLabel i18n="apppage-no-access" />
            </div>;
        }

        return <LoadingState isLoading={this.props.isLoading}><div className={classes.join(" ")}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {content}
        </div>
        </LoadingState>;
    }
}

const AppPageContent: React.ComponentClass<IAppPageContentProps, any> = withRouter(
    connect(null, (dispatch) => ({ pageActions: bindActionCreators(pageActions, dispatch) }))(AppPageContentCtrl as any) as any
) as any;

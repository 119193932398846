import { LoadingState, LoadingProgress } from '@inwink/loadable';
import { React } from '../../../commons';
import type { IEntityScreenProps } from './entitypagebase';
import { EntityGrid } from './entitygrid';
import { EntityGridToolbar } from './entitygrid/entitygrid.toolbar';
import type { EntityPageAction, IEntityScreenHelpers } from './entitypagebase.props';

interface IEntityScreenDesktopProps extends IEntityScreenProps {
}

export interface IEntityScreenDesktopState {
    refreshGrid: Date;
    tinyLoader: boolean;
    showSettings: boolean;
    loadingProgress?: { message: string };
    gridIsReady: boolean;
}

export class EntityScreenDesktop extends React.Component<IEntityScreenDesktopProps, IEntityScreenDesktopState> {
    gridRef = React.createRef<EntityGrid>();

    constructor(props: IEntityScreenDesktopProps) {
        super(props);

        this.state = {
            gridIsReady: false,
            refreshGrid: new Date(),
            tinyLoader: false,
            showSettings: false
        };

        this.props.viewsManager.setRefreshGrid(this.refreshGrid);
    }

    setTinyLoader = (loader) => {
        this.setState({ tinyLoader: loader });
    };

    refreshGrid = () => {
        // (this.refs as any).grid.refreshGrid();
        this.setState({ refreshGrid: new Date() });
    };

    setRefreshGrid = (refresh = false) => {
        if (refresh) {
            this.setState({ refreshGrid: new Date() });
        }
    };

    convertFilters(srcFilters) {
        if (!srcFilters) {
            return null;
        }

        const newFilters = {};
        Object.keys(srcFilters).forEach((k) => {
            if (this.props.entityconfiguration.convertFilters && this.props.entityconfiguration.convertFilters[k]) {
                const callback = this.props.entityconfiguration.convertFilters[k];
                newFilters[k] = callback(srcFilters[k]);
            } else {
                newFilters[k] = srcFilters[k];
            }
        });

        return newFilters;
    }

    showSettings = (show: boolean) => {
        this.setState({ showSettings: show });
    };

    gridReady = () => {
        // Si la page est chargée de facon asynchrone, le loader peut attendre le chargement avant de se cacher
        // le loader permet d'éviter le clignotement de plusieurs loaders successifs (page => entitypage => grille)
        // qui apparaissent et disparaissent

        this.setState({ gridIsReady: true }, () => {
            if (this.props.onboardingcontext) {
                this.props.onboardingcontext.setOnboarding("desktopentitypage");
            }
        });
    };

    getActionContext = (options, action: EntityPageAction) => {
        const ctx = this.props.helpers.getActionContext(options, action);
        ctx.component = this;
        if (this.gridRef && this.gridRef.current) {
            ctx.tools = Object.assign({}, ctx.tools, {
                refresh: () => {
                    this.gridRef.current.datamgr.refreshGrid(null);
                    if (this.props.onRefreshed) {
                        this.props.onRefreshed();
                    }
                }
            });
        }

        return ctx;
    };

    render() {
        const i18nHelper = this.props.i18nHelper;
        const helpers: IEntityScreenHelpers = {...this.props.helpers, getActionContext: this.getActionContext };

        return <LoadingState isLoading={!this.state.gridIsReady}>
            <LoadingProgress progress={this.state.loadingProgress}>
                <div className={"iw-entitypage pagecontent desktop" + (this.props.sidebar ? " withsidebar" : "")}>
                    <section>
                        <EntityGridToolbar
                            {...this.props}
                            helpers={helpers}
                            viewsManager={this.props.viewsManager}
                            viewsState={this.props.viewsState}
                            i18nHelper={i18nHelper}
                            refreshGrid={this.state.refreshGrid}
                            showSettings={this.state.showSettings}
                            tinyLoader={this.state.tinyLoader}
                            setTinyLoader={this.setTinyLoader}
                            setShowSettings={this.showSettings}
                            entityConfs={this.props.entityConfs}
                        />
                        <EntityGrid
                            {...this.props}
                            ref={this.gridRef}
                            helpers={helpers}
                            viewsManager={this.props.viewsManager}
                            viewsState={this.props.viewsState}
                            entityConfigurationArgs={this.props.configurationArgs}
                            i18nHelper={i18nHelper}
                            title={this.props.pagetitle}
                            refreshGrid={this.state.refreshGrid}
                            setRefreshGrid={this.setRefreshGrid}
                            setTinyLoader={this.setTinyLoader}
                            template={this.props.template}
                            showSettings={this.state.showSettings}
                            onShowSettings={this.showSettings}
                            onGridReady={this.gridReady}
                            expression={this.props.expression}
                        />
                    </section>
                </div>
            </LoadingProgress>
        </LoadingState>;
    }
}

import { React, i18NHelperContext } from '../../commons';

export interface IWizardStepManager {
    onAllowNext: (allow: boolean) => void;
    onDisableFooter: (disable: boolean) => void;
    next();
    prev();
    setBtnValidate: (callback: () => void) => void;
    onDisabledPrev: (disabledPrev: boolean) => void;
    setCanGoNext?: (callback: () => boolean) => void;
}

export interface IWizardStep {
    id: string;
    label: string;
    component: any;
    customProps?: any;
    showIf?: any;
    // canGoNext : boolean;
}

export interface IWizardStepProps {
    step: IWizardStep;
    currentStep: number;
    customProps: any;
    stepmgr: IWizardStepManager;
}

export class WizardStep extends React.Component<IWizardStepProps, any> {
    constructor(props: IWizardStepProps) {
        super(props);
        this.state = {
            index: props.currentStep
        };
    }

    renderContent() {
        if (this.props.step && this.props.step.component) {
            return <i18NHelperContext.Consumer>{(i18nHelper) => React.createElement(
                this.props.step.component,
                Object.assign({}, this.props.customProps, this.props.step.customProps, {
                    stepmgr: this.props.stepmgr,
                    customProps: this.props.customProps,
                    i18nHelper: i18nHelper
                })
            )}</i18NHelperContext.Consumer>;
        }
        return "no component";
    }

    render() {
        return <article className={"wizardstep " + (this.state.index === this.props.currentStep ? "current" : "")}>
            <div className="wizardstep-content">
                {this.renderContent()}
            </div>
        </article>;
    }
}

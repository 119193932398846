import { requestBrowserStorage, BrowserStorage } from './browserstorage';

// eslint-disable-next-line import/no-mutable-exports
export let userData: BrowserStorage;

export function openUserData(mail, version) {
    return new Promise((resolve, reject) => {
        requestBrowserStorage("usercache").then((browserstorage) => {
            userData = browserstorage;
            userData.openData(inwink.config.env + "-" + mail, version);
            resolve();
        }).catch((err) => {
            reject(err);
        });
    });
}

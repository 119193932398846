import { dp } from '@inwink/datepicker/datepicker';
import { immutable } from '@inwink/utils/immutable';
import { getPropertyValue } from '@inwink/utils/methods';
import { AppTextLabel, React } from '../../../commons';
import { DatePicker } from './datepicker';
import * as moment from 'moment';

interface IDateRangeInputBaseProps {
    canEditTrad: boolean;
    i18n: string;
    minDate?: moment.Moment;
}

interface IDateRangeInputProps extends IDateRangeInputBaseProps {
    startValue: moment.Moment;
    endValue: moment.Moment;
    onChange: (start: moment.Moment, end: moment.Moment) => void;
    type?: dp.DatePickerType;
}

export class DateRangeInput extends React.PureComponent<IDateRangeInputProps, any> {
    onStartChange = (date?: moment.Moment) => {
        this.props.onChange(date, this.props.endValue || this.props.endValue >= date ? this.props.endValue : date);
    }

    onEndChange = (date?: moment.Moment) => {
        this.props.onChange(this.props.startValue ? this.props.startValue : date, date);
    }

    render() {
        const type = this.props.type;
        return <div className="fieldbloc">
            <AppTextLabel component="label" i18n={this.props.i18n} />
            <AppTextLabel component="div" className="field-desc" defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""} i18n={this.props.i18n + ".desc"} />
            <div className="daterange">
                <AppTextLabel component="span" i18n="daterange.from" />
                <DatePicker selected={this.props.startValue} minDate={this.props.minDate} onChange={this.onStartChange} type={type ? type : "date"} />
                <AppTextLabel component="span" i18n="daterange.to" />
                <DatePicker selected={this.props.endValue} minDate={this.props.startValue || this.props.minDate} onChange={this.onEndChange} type={type ? type : "date"} />
            </div>
        </div>;
    }
}

interface IDateRangeInputPropertyProps extends IDateRangeInputBaseProps {
    data: any;
    startValuePath: string;
    endValuePath: string;
    onChange: (data) => void;
}

export class DateRangeInputProperty extends React.PureComponent<IDateRangeInputPropertyProps, any> {
    onChange = (start: moment.Moment, end: moment.Moment) => {
        let data = immutable.set(this.props.data, this.props.startValuePath, start);
        data = immutable.set(data, this.props.endValuePath, end);
        this.props.onChange(data);
    }

    render() {
        let startValue = getPropertyValue(this.props.data, this.props.startValuePath);
        let endValue = getPropertyValue(this.props.data, this.props.endValuePath);

        return <DateRangeInput
            {...this.props}
            startValue={startValue}
            endValue={endValue}
        />;
    }
}

import * as React from 'react';
import { DynLabel, DynLabelProps } from '@inwink/i18n';
import type {States} from '../../services/services';

export interface IEventLabelProps extends DynLabelProps {
    event?: States.IEventState;
    displayLanguage? : string;
}

export function EventLabel(props: IEventLabelProps) {
    if (!props.i18n) {
        return null;
    }
    if (typeof props.i18n === "string") {
        return <span>{props.i18n}</span>;
    }
    return <DynLabel {...props} displayLanguage={props.displayLanguage || (props.event && props.event.displayLanguage)} />;
}

export function displayFieldLabel(event: States.IEventState, value) {
    if (typeof value === "string") {
        return <span>{value}</span>;
    }
    return <DynLabel displayLanguage={event.displayLanguage} i18n={value} />;
}

import type { Entities } from '@inwink/entities/entities';
import type { ISavePayloadEntityFormater } from './formater/PayloadEntityFormaters';
import { isNullableEntityField } from './entityhelpers.nullablefields';

export function entityToSaveFormatv3(entity: Entities.IEntity, template, originalEntity: Entities.IEntity,
    entityName?: string, formater?: ISavePayloadEntityFormater) {
    const entityToSave = JSON.parse(JSON.stringify(entity));
    const templateKeys = [];
    template.fields.forEach((field) => {
        // const field = template.fields[i];
        templateKeys.push(field && field.key);
        if (Object.prototype.hasOwnProperty.call(entity, field.key) === false) {
            return;
        }

        const fieldType = field.type.toLowerCase();
        if (fieldType === "bool") {
            if (!(entityToSave[field.key] === null && isNullableEntityField(field.key, entityName))) {
                entityToSave[field.key] = (typeof (entityToSave[field.key]) !== "boolean") ? false : entityToSave[field.key];
            }
        } else if (fieldType === "number") {
            let nb = entity[field.key];
            if (entity[field.key]) {
                const nvalue = Number(entity[field.key]);
                nb = Number.isNaN(nvalue) ? null : nvalue;
            }
            entityToSave[field.key] = nb;
        } else if (fieldType === "entities") {
            if (entity[field.key]) {
                if (formater && formater[field.key]) {
                    entityToSave[field.key] = formater[field.key](entityToSave[field.key], entity, originalEntity);
                } else {
                    entityToSave[field.key] = entity[field.key].filter((item) => !!item).map((item) => ({ id: item.id }));
                }
            }
        } else if (field.key.toLowerCase() === "timeslotid") {
            entityToSave[field.key] = (!entity[field.key]) ? null : entity[field.key][0] && entity[field.key][0].id;
        } else if (fieldType === "entity") {
            if (entity[field.key] && entity[field.key].id) {
                entityToSave[field.key + "Id"] = entity[field.key].id;
                delete entityToSave[field.key];
            } else {
                // entityToSave[field.key] = (entity[field.key] && entity[field.key].id) ? entity[field.key].id : null;
                entityToSave[field.key] = entity[field.key] && entity[field.key].id ? { id: entity[field.key].id } : undefined;
            }
        } else if (fieldType === "image" || fieldType === "file") {
            if (field.isLocalizable) {
                // eslint-disable-next-line no-restricted-syntax
                for (const lang in entityToSave[field.key]) {
                    if (entityToSave[field.key][lang] && typeof entityToSave[field.key][lang] !== "string") {
                        delete entityToSave[field.key][lang];
                    }
                }
            }
        }
    });

    // Dans le cas où un champ est caché dans la configuration
    // mais que nous en avons quand même besoin à l'enregistrement. cf #37216
    if (formater) {
        const savedFields = Object.keys(entityToSave);
        if (savedFields && savedFields.length) {
            savedFields.map((fieldKey) => {
                if (formater[fieldKey] && !templateKeys.includes(fieldKey)) {
                    entityToSave[fieldKey] = formater[fieldKey](entityToSave[fieldKey], entity, originalEntity);
                }
                return null;
            });
        }
    }

    return entityToSave;
}

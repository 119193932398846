import { React } from '../../../commons';
import type { ISimpleTabsContentProps } from './index';
import { SimpleTabsItemsGroup } from './itemsgroup';
import { Toolbar, IToolbarItem } from '../toolbar';

export interface ISimpleTabsItemsProps extends ISimpleTabsContentProps {
    className?: string;
}

export function SimpleTabsItems(props: ISimpleTabsItemsProps) {
    const groups = props.groups.map((g, idx) => {
        return <SimpleTabsItemsGroup key={"group" + idx} {...props} group={g} />;
    });

    return <div className={"simpletabs-items " + (props.className || "")}>
        {groups}
    </div>;
}

export class SimpleTabsTopItems extends React.Component<ISimpleTabsItemsProps, any> {
    constructor(props: ISimpleTabsItemsProps) {
        super(props);
        this.state = {
            items: this.getItems()
        };
    }

    componentDidUpdate(prevprops: ISimpleTabsItemsProps) {
        if (prevprops.groups !== this.props.groups || prevprops.selected !== this.props.selected) {
            this.setState({
                items: this.getItems()
            });
        }
    }

    getItems() {
        const res: IToolbarItem[] = [];
        if (this.props.groups && this.props.groups[0] && this.props.groups[0].items) {
            this.props.groups[0].items.forEach((item) => {
                const toolbaritem: IToolbarItem = {
                    id: item.id,
                    icon: item.icon,
                    label: item.label,
                    badgeControl: item.badge ? (_props) => {
                        return React.createElement(item.badge, { ..._props, ...this.props.getPanelProps(item) });
                    } : undefined,
                    show: item.showIf ? () => item.showIf(null) : null
                };
                (toolbaritem as any).$rawdata = item;
                res.push(toolbaritem);
            });
        }
        return res;
    }

    itemClicked = (item) => {
        this.props.itemSelected(item.$rawdata);
    };

    render() {
        if (this.props.groups) {
            if ((!this.props.groups[0].items || this.props.groups[0].items.length === 1) && !this.props.forceDisplayTab) {
                return null;
            }
        }

        if (this.props.desktopmode === "top") {
            let customComponentRendering: any = null;
            if (typeof this.props.customizeTab === 'function') {
                const customComponent = this.props.customizeTab();
                customComponentRendering = customComponent.component
                    && React.createElement(customComponent.component, customComponent);
            }

            return <div className={"simpletabs-items " + (this.props.className || "")}>
                <Toolbar
                    className="dark"
                    items={this.state.items}
                    selected={this.props.selectedItem && this.props.selectedItem.id}
                    onItemClicked={this.itemClicked}
                    panelsIdWithWarning={this.props.panelsIdWithWarning}
                    panelsWarningMessage={this.props.panelsWarningMessage}
                    itemRefresh={this.props.tabRefresh}
                />
                {customComponentRendering}
            </div>;
        }

        const groups = this.props.groups.map((g, idx) => {
            return <SimpleTabsItemsGroup key={"group" + idx} {...this.props} group={g} />;
        });

        return <div className={"simpletabs-items " + (this.props.className || "")}>
            {groups}
        </div>;
    }
}

import { withI18nHelper } from '@inwink/i18n';
import { React, AppTextLabel} from '../../../commons';
import { ResumeExpression } from './expressions/expressionresume';
import type { IExpressionDesignerItemProps } from './expressiondesigner.item';
import { getItemMetadata } from './utils';

interface IExpressionDesignerValueItemState {
}

@withI18nHelper()
export class ExpressionDesignerValueItem extends
    React.Component<IExpressionDesignerItemProps, IExpressionDesignerValueItemState> {
    removeItem = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onRemoveItem(this.props.item);
    };

    render() {
        const propvalue = this.props.item.val;

        let btnAnd;
        let btnOr;

        if (this.props.item.name
            && (propvalue !== undefined
                || this.props.item.not || this.props.item.or || this.props.item.any || this.props.item.and)) {
            if (!this.props.isReadOnly && !(this.props.separator === 'and' || this.props.isAlone)) {
                btnAnd = <button
                    type="button"
                    className="conditional-btn"
                    onClick={() => this.props.itemTransformCondition(this.props.item, 'and')}
                >
                    <AppTextLabel component="span" i18n="expressiondesigner.kind.and" />
                </button>;
            }

            if (!this.props.isReadOnly && this.props.separator !== 'or') {
                btnOr = <button
                    type="button"
                    className="conditional-btn"
                    onClick={() => this.props.itemTransformCondition(this.props.item, 'or')}
                >
                    <AppTextLabel component="span" i18n="expressiondesigner.kind.or" />
                </button>;
            }

            const expr = JSON.parse(JSON.stringify(this.props.item));

            return <div className="expressiondesigner-item">
                <div className="detail" style={{ paddingTop: ((this.props.isFirst) ? 2 : 0) }}>
                    <div className="content">
                        <ResumeExpression
                            cmscontext={this.props.cmscontext}
                            i18nHelper={this.props.i18nHelper}
                            isMultiStepRegistration={this.props.isMultiStepRegistration}
                            metadata={getItemMetadata(this.props.item.name, this.props.groups, this.props.isFieldEditor)}
                            expression={Object.assign({}, expr, { val: propvalue })}
                        />

                        {btnAnd}
                        {btnOr}
                    </div>
                    <div className="actions">
                        {this.props.isReadOnly ? null : <span className="btnremove" onClick={this.removeItem}>+</span>}
                    </div>
                </div>
            </div>;
        }
        return <div>???</div>;
    }
}

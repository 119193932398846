/* eslint-disable max-classes-per-file */
import * as React from 'react';
import type { IPopoverManager } from '@inwink/modals';
import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { DynamicFieldWrapper } from '@inwink/entityform/dynamicfieldwrapper';
import type { Entities } from '@inwink/entities/entities';
import { ExpressionEditor, IExpressionEditorProps } from '../components/entityform/expressioneditor';
import type { ICMSContext } from '../components/cmscontext';
import type { IEntityPageConfiguration } from '../components/entities/entitypagev3/entitypagebase.props';
import type { IFormFieldsDataContext, IEntityDetailConfiguration } from '../components/entities/entitydetailv3';
import { openEntityDetail } from '../components/entities/entitydetailv3/openEntityDetail';
import { AppTextLabel } from '../services/i18nservice';

export class NotificationModesEditor extends React.Component<IDynamicFieldEditorProps, any> {
    setMail = (arg: React.FormEvent<HTMLInputElement>) => {
        let modes = this.props.entityFieldValue ? this.props.entityFieldValue.split(',') : [];
        if (arg.currentTarget.checked) {
            if (modes.indexOf("mail") < 0) {
                modes.push("mail");
            }
        } else {
            modes = modes.filter((m) => m !== "mail");
        }
        this.props.onChange(this.props.entityFieldFormTemplate.key, modes.length > 0 ? modes.join(",") : null);
    };

    setBatching = (arg: React.FormEvent<HTMLSelectElement>) => {
        this.props.onChange("batching", arg.currentTarget.value);
    };

    render() {
        const modes = this.props.entityFieldValue ? this.props.entityFieldValue.split(',') : [];
        const hasMail = modes.indexOf("mail") >= 0;
        let batching;
        if (hasMail) {
            batching = <div>
                <select value={this.props.entityValue.batching || ""} onChange={this.setBatching}>
                    <AppTextLabel i18n="mailtrigger.batching.none" value="" component="option" />
                    <AppTextLabel i18n="mailtrigger.batching.oneday" value="1:d" component="option" />
                </select>
            </div>;
        }
        return <DynamicFieldWrapper {...this.props}>
            <div>
                <div>
                    <input type="checkbox" id="receivemail" checked={hasMail} onChange={this.setMail} />
                    <AppTextLabel
                        component="label"
                        className="forcheckbox"
                        htmlFor="receivemail"
                        i18n="mailtrigger.notificationmodes.email"
                    />
                </div>
                {batching}
            </div>
        </DynamicFieldWrapper>;
    }
}

export class EntityOperationsEditor extends React.Component<IDynamicFieldEditorProps, any> {
    renderOp(modes, op) {
        return <div>
            <input
                type="checkbox"
                id={"receivemail" + op}
                checked={modes.indexOf(op) >= 0}
                onChange={(arg) => this.setOp(arg, op)}
            />
            <AppTextLabel
                component="label"
                className="forcheckbox"
                htmlFor={"receivemail" + op}
                i18n={"mailtrigger.entityoperations." + op}
            />
        </div>;
    }

    setAll = () => {
        this.props.onChange(this.props.entityFieldFormTemplate.key, null);
    };

    setOp = (arg: React.FormEvent<HTMLInputElement>, op: string) => {
        let modes = this.props.entityFieldValue ? this.props.entityFieldValue.split(',') : [];
        if (arg.currentTarget.checked) {
            if (modes.indexOf(op) < 0) {
                modes.push(op);
            }
        } else {
            modes = modes.filter((m) => m !== op);
        }
        this.props.onChange(this.props.entityFieldFormTemplate.key, modes.length > 0 ? modes.join(",") : null);
    };

    render() {
        const modes = this.props.entityFieldValue ? this.props.entityFieldValue.split(',') : [];
        return <DynamicFieldWrapper {...this.props}>
            <div>
                <input type="checkbox" id="receivemailall" checked={modes.length === 0} onChange={this.setAll} />
                <AppTextLabel
                    component="label"
                    className="forcheckbox"
                    htmlFor="receivemailall"
                    i18n="mailtrigger.entityoperations.all"
                />
            </div>
            {this.renderOp(modes, "create")}
            {this.renderOp(modes, "update")}
            {this.renderOp(modes, "delete")}
        </DynamicFieldWrapper>;
    }
}

const noop = () => null;

export function editUserNotification(
    props, popovermgr: IPopoverManager, cmscontext: ICMSContext, 
    eventid: string, usernotif, entityconfiguration: IEntityPageConfiguration
) {
    return cmscontext.getEntityConfiguration(
        "MailTrigger",
        { ...props, forNotification: true}
    ).then((mailTriggerConfiguration) => {
        const baseGetEntityTemplate = mailTriggerConfiguration.datasource.entitytemplate;
        mailTriggerConfiguration.datasource.entitytemplate = (includeStatic) => {
            return baseGetEntityTemplate(includeStatic).then((template) => {
                if (!template.fields.filter((field) => field.key === "notificationModes")[0]) {
                    template.fields.push({
                        key: "notificationModes",
                        type: "Text"
                    });
                }

                if (!template.fields.filter((field) => field.key === "entityOperations")[0]) {
                    template.fields.push({
                        key: "entityOperations",
                        type: "Text"
                    });
                }

                return template;
            });
        };

        const entitydatasource = entityconfiguration.datasource;

        return Promise.all([
            mailTriggerConfiguration.detailConfig.configuration(),
            entitydatasource.columns()
        ]).then((res) => {
            const columns = [];
            columns.push(...res[1]);
            const customFormComponent = {
                targetentityname: noop,
                targetentityid: noop,
                targetentityparentid: noop,
                mailtemplate: noop,
                notificationmodes: (_props: IExpressionEditorProps) => {
                    return <NotificationModesEditor {..._props} />;
                },
                entityoperations: (_props: IExpressionEditorProps) => {
                    return <EntityOperationsEditor {..._props} />;
                },
                expression: (_props: IExpressionEditorProps) => {
                    return <ExpressionEditor
                        {..._props}
                        entityconfiguration={entityconfiguration}
                        wrapExpressionWithAnd={true}
                        isMailTrigger={true}
                        showExpressionPreview={true}
                        toV3={inwink.config.env === "dev" || inwink.config.env === "sdb" || inwink.config.env === "local"}
                        targetEntityName={_props.entityValue.targetEntityName} // #39750 - fix
                        targetEntityParent={_props.entityValue && _props.entityValue.targetEntityParentId}
                        options={{
                            filterEntityIES: true,
                            filterLocalizable: false
                        }}
                    />;
                }
            };
            const detailconf = res[0];

            const dfc = Object.assign({}, detailconf.customFormComponents, customFormComponent);
            const create = !usernotif.id;
            if (create) {
                const hideFields = detailconf.create.hideFields({ cmscontext });
                hideFields.push('mailTemplate');
                if (hideFields && hideFields.indexOf("notificationmodes") > -1) {
                    hideFields.splice(hideFields.indexOf("notificationmodes"), 1);
                }
                if (hideFields && hideFields.indexOf("entityoperations") > -1) {
                    hideFields.splice(hideFields.indexOf("entityoperations"), 1);
                }
                detailconf.create.hideFields = () => hideFields;

                const dv = detailconf.create.defaultValues();
                detailconf.create.defaultValues = () => Object.assign({}, dv, usernotif);
                detailconf.create.title = "modal.title.addmailtrigger.fromgrid";
            } else {
                const baseUpdateHideFields = detailconf.update.hideFields;
                detailconf.update.title = "modal.title.updatemailtrigger.fromgrid";
                detailconf.update.hideFields = (dataContext: IFormFieldsDataContext, op?: IEntityDetailConfiguration,
                    entityTemplate?: Entities.IEntityTemplate, entity?: any) => {
                    const hideFields = baseUpdateHideFields(dataContext, op, entityTemplate, entity);
                    hideFields.push('mailTemplate');
                    if (hideFields && hideFields.indexOf("notificationmodes") > -1) {
                        hideFields.splice(hideFields.indexOf("notificationmodes"), 1);
                    }
                    if (hideFields && hideFields.indexOf("entityoperations") > -1) {
                        hideFields.splice(hideFields.indexOf("entityoperations"), 1);
                    }
                    return hideFields;
                };
            }

            const dc = Object.assign({}, detailconf, { customFormComponents: dfc});
            return openEntityDetail(
                popovermgr,
                cmscontext,
                dc,
                mailTriggerConfiguration.datasource,
                {
                    isCreate: create,
                    canEdit: true,
                    isDuplicate: false
                },
                usernotif
            );
        });
    });
}

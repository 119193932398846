/* eslint-disable max-classes-per-file */
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { I18nInput } from '@inwink/i18ninput';
import { React, AppTextLabel, IPopoverManager } from '../../../commons';
import { IVisualConfigurationStateManager } from '../../../cms/editors/visualconfig';
import { Richi18nTextInput } from '../richtextinput';
import { editText } from '../richtexteditor/richtexteditor';

import './i18ninput.less';

interface IIi18nTextInputBaseProps {
    canEditTrad: boolean;
    i18n: string;
    languages: string[];
    multiline?: boolean;
    richtext?: boolean;
    maxLength?: number;
    minLength?: number;
    visualConfigurationState?: IVisualConfigurationStateManager;
    readOnly?: boolean;
    stamp?: any;
}

interface IIi18nTextInputProps extends IIi18nTextInputBaseProps {
    value: VisualTheme.IAppLabel;
    onChange: (val: VisualTheme.IAppLabel) => void;
    showMinRow?: number;
    required?: boolean;
    htmlFor?: string;
}

export class I18nTextInput extends React.PureComponent<IIi18nTextInputProps, any> {
    popovermgr: IPopoverManager;

    constructor(props) {
        super(props);
        this.state = {
            hasError: this.hasError()
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.required !== this.props.required
            || JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)
        ) {
            this.setState({hasError: this.hasError()});
        }
    }

    hasError = () => {
        let hasError = false;
        if (this.props.required) {
            // eslint-disable-next-line no-restricted-syntax
            for (const lang of this.props.languages) {
                if (!this.props.value || !this.props.value[lang]) {
                    hasError = true;
                }
            }
        }
        return hasError;
    };

    onEdit = (txt) => {
        return editText(this.popovermgr, txt, this.props.visualConfigurationState);
    };

    render() {
        if (this.props.richtext && !this.props.readOnly) {
            return <div className="fieldbloc">
                <AppTextLabel component="label" i18n={this.props.i18n} />
                <AppTextLabel
                    component="div"
                    className="field-desc"
                    defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""}
                    i18n={this.props.i18n + ".desc"}
                />
                <Richi18nTextInput
                    multiline={this.props.multiline}
                    languages={this.props.languages}
                    canEditTrad={this.props.canEditTrad}
                    text={this.props.value}
                    datastamp={this.props.stamp}
                    onChange={this.props.onChange}
                    showMinRow={this.props.showMinRow}
                />
            </div>;
        }

        return <div className={`i18ninput fieldbloc ${this.state.hasError ? 'has-error' : null}`}>
            {/* Why? <PopoverManager ref={(mgr) => this.popovermgr = mgr && mgr.ctx} /> */}
            <div className="fieldwrapper">
                <AppTextLabel component="label" i18n={this.props.i18n} />
                {this.props.required ? <span className="requiredmark">*</span> : null}
            </div>
            <AppTextLabel
                component="div"
                className="field-desc"
                defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""}
                i18n={this.props.i18n + ".desc"}
            />
            <I18nInput
                stamp={this.props.stamp}
                readOnly={this.props.readOnly}
                languages={this.props.languages}
                multiline={this.props.multiline}
                richtext={this.props.richtext}
                value={this.props.value}
                maxLength={this.props.maxLength}
                minLength={this.props.minLength}
                onChange={this.props.onChange}
                onEdit={this.onEdit}
                htmlFor={this.props.htmlFor}
            />
            {this.state.hasError ? (
                <div className="fielderror">
                    <div className="msg">
                        <AppTextLabel i18n="fielvalidation.requiredvalue" />
                    </div>
                </div>
            ) : null}
        </div>;
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface Ii18nTextInputPropertyProps extends IIi18nTextInputBaseProps {
    data: any;
    path: string;
    onChange: (data: any) => void;
}

export class I18nTextInputProperty extends React.PureComponent<Ii18nTextInputPropertyProps, any> {
    onChange = (txt) => {
        const data = immutable.set(this.props.data, this.props.path, txt);
        this.props.onChange(data);
    };

    render() {
        const val = getPropertyValue(this.props.data, this.props.path);
        return <I18nTextInput
            {...this.props}
            value={val}
            onChange={this.onChange}
        />;
    }
}

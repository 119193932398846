import { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData} from '@inwink/apibase';
import { getBackOfficeProxy } from '@@api/backoffice/index';
import { getEventAPIProxy } from './index';
import { getCustomerAPIProxy } from '../customers/index';
import { eventDataSource } from '../customers/event';

export function getEventKeyAvailability(key: string, excludeEventId?: string, excludeCommunityId?: string) {
    return getBackOfficeProxy().postJson('tinyurl/isavailable', JSON.stringify({
        tinyUrl: key,
        eventId: excludeEventId,
        communityId: excludeCommunityId
    }), defaultPostHeaderForJsonData);
}

export function getEventDetail(eventId: string) {
    return getEventAPIProxy().getJson(`${eventId}/eventdetail/${eventId}`);
}

export interface IEventParentNames {
    tenantId: string;
    tenantName: string;
    customerId: string;
    customerName: string;
}

export function getParentNames(eventId: string) : Promise<IEventParentNames> {
    return getEventAPIProxy().getJson(`${eventId}/parents-details`);
}

export function getAvailableLanguages(eventId: string) : Promise<{ supportedLanguages: string[] }> {
    return getEventAPIProxy().getJson(`${eventId}/available-languages`);
}

export function getCustomerEventDetail(eventId: string, customerId: string): Promise<Entities.IMyEvent[]> {
    return getCustomerAPIProxy().getJson(`${customerId}/event/${eventId}`);
}

export function customerEvents(customerId: string) {
    const ds = eventDataSource(customerId);
    // return getCustomerAPIProxy().postJson(`${customerId}/event/query`, JSON.stringify({}), defaultPostHeaderForJsonData)
    return ds.query()
        .then((_res) => _res.data.map((e) => {
            const res: Entities.IMyEvent = {
                id: e.id,
                title: e.title,
                customerId: e.customerId,
                tinyUrl: e.eventKey,
                tenantId: e.tenantId,
                roles: [],
                date: {
                    start: e.startDate as any,
                    end: e.endDate as any
                }
            };
            return res;
        }));
}

import { IPopoverManager } from '@inwink/modals';
import { PopoverAdvancedOptions } from "@inwink/modals/popover";
import { React, AppLabel } from '../../../commons';
import { ModalContent, IModalAction } from './modalcontent';
import './confirmmodal.less';

export interface IConfirmModalProps {
    title: string;
    titleinject?: any;
    message: string;
    messageinject?: any;
    cancelOption?: boolean;
    mandatory?: boolean;
    actionsCustomTrad?: string;
    onCompleted?: (res) => void;
    onConfirm?: () => Promise<any>;
    onDecline?: () => Promise<any>;
    dangerZone?: string;
    reverseButtons?: boolean;
    multipleModals?: {
        onMount: (onCompleted?: (res?) => void) => any,
        index?: number
    };
}

export class ConfirmModal extends React.Component<IConfirmModalProps, any> {
    modalActions: IModalAction[];

    constructor(props: IConfirmModalProps) {
        super(props);

        this.modalActions = [
            {
                i18n: `${this.props.actionsCustomTrad || "actions"}.yes`,
                id: 'yes',
                direction: "right",
                isImportant: true,
                disabled: () => {
                    if (this.props.dangerZone && this.state.dangerZoneMatch === false) {
                        return true;
                    }
                    return false;
                },
                callback: () => {
                    if (this.props.onConfirm) {
                        return this.props.onConfirm().then(() => {
                            if (this.props.multipleModals) {
                                this.props.onCompleted({ confirm: true, index: this.props.multipleModals?.index });
                            } else {
                                this.props.onCompleted(true);
                            }
                        });
                    }

                    if (this.props.multipleModals) {
                        this.props.onCompleted({ confirm: true, index: this.props.multipleModals?.index });
                    } else {
                        this.props.onCompleted(true);
                    }
                }
            }
        ];

        if (!this.props.mandatory) {
            this.modalActions.push({
                i18n: `${this.props.actionsCustomTrad || "actions"}.no`,
                id: 'no',
                direction: "right",
                isImportant: false,
                callback: () => {
                    if (this.props.onDecline) {
                        return this.props.onDecline().then(() => {
                            if (this.props.multipleModals) {
                                this.props.onCompleted({ confirm: false, index: this.props.multipleModals?.index });
                            } else {
                                this.props.onCompleted(false);
                            }
                        });
                    }

                    if (this.props.multipleModals) {
                        this.props.onCompleted({ confirm: false, index: this.props.multipleModals?.index });
                    } else {
                        this.props.onCompleted(false);
                    }
                }
            });
        } else {
            this.modalActions[0].i18n = "actions.ok";
        }

        if (this.props.reverseButtons) {
            const indexSource = this.modalActions.findIndex((action) => action.id === 'yes');
            const indexTarget = this.modalActions.findIndex((action) => action.id === 'no');

            if (indexSource > -1 && indexTarget > -1) {
                const elementToMove = this.modalActions[indexSource];

                // Delete source:
                this.modalActions.splice(indexSource, 1);

                // Add at target:
                this.modalActions.splice(indexTarget, 0, elementToMove);
            }
        }

        if (this.props.cancelOption) {
            this.modalActions.push({
                i18n: "actions.cancel",
                direction: "right",
                isImportant: false,
                callback: () => {
                    if (this.props.multipleModals) {
                        this.props.onCompleted({ confirm: null, index: this.props.multipleModals?.index });
                    } else {
                        this.props.onCompleted(null);
                    }
                }
            });
        }

        this.state = {
            dangerZoneMatch: false
        };
    }

    componentDidMount() {
        const { multipleModals, onCompleted } = this.props;
        if (multipleModals?.onMount) { multipleModals.onMount(onCompleted); }
    }

    confirmDangerZone = (e) => {
        this.setState({dangerZoneMatch: this.props.dangerZone === e.target.value});
    };

    render() {
        return <ModalContent
            className="iwconfirmmodal"
            actions={this.modalActions}
            title={this.props.title}
            injectTitle={this.props.titleinject}
            onhide={this.props.onCompleted}
            hideClose={this.props.mandatory}
        >
            <div className="confirm-message">
                <AppLabel i18n={this.props.message} inject={this.props.messageinject} />
            </div>
            <br />
            {this.props.dangerZone ? (
                <div className="danger-message">
                    <div className="fieldwrapper">
                        <AppLabel
                            htmlFor="fld-danger"
                            component="label"
                            i18n="confirm.danger.modal.body"
                            inject={{dangerZone: this.props.dangerZone}}
                        />
                        <div className="i18ninput-bloc">
                            <div className="input-wrapper">
                                <input type="text" id="fld-danger" onChange={this.confirmDangerZone} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}

        </ModalContent>;
    }
}

export function confirmModal(
    popovermgr: IPopoverManager,
    title: string,
    message: string,
    mandatory?: boolean,
    cancelOption?: boolean,
    actionsCustomTrad?: string,
    options?: PopoverAdvancedOptions,
    messageinject?: any,
    multipleModals?: any,
    reverseButtons?: boolean,
): Promise<boolean | any> {
    return popovermgr.modalPortal<boolean | any>(ConfirmModal, {
        title,
        message,
        cancelOption,
        mandatory,
        actionsCustomTrad,
        messageinject,
        multipleModals,
        reverseButtons
    } as IConfirmModalProps,
    "app-iwconfirmmodal", options);
}

export function smartconfirmModal(popovermgr: IPopoverManager, options: IConfirmModalProps): Promise<boolean> {
    return popovermgr.modalPortal<boolean>(ConfirmModal, options, "app-iwconfirmmodal");
}

import { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n';
import { React, connectwith } from '../../../../commons';
import { DrowdownMultipleFormEditor } from '../../../../components/entityform/dropdownmultiple';
import { States } from '../../../../services/services';

export interface IExhibitorAccountRolesEditorProps extends IDynamicFieldEditorProps {
    event?: States.IEventState;
    i18n?: States.i18nState;
    noLabel?: boolean;
}

interface IExhibitorAccountRolesEditorState {
    loading: boolean;
    entityFieldFormTemplate: Entities.IExtendedFieldsFormFieldTemplate;
}

export const exhibitorAccountRoles = ["ADMIN", "SALESCONTACT", "NOACCESS", "WITHBADGE"];

@connectwith((state: States.IAppState) => ({ event: state.event, i18n: state.i18n }))
@withI18nHelper()
export class ExhibitorAccountRolesEditor
    extends React.Component<IExhibitorAccountRolesEditorProps, IExhibitorAccountRolesEditorState> {
    constructor(props: IExhibitorAccountRolesEditorProps) {
        super(props);

        this.state = {
            entityFieldFormTemplate: null,
            loading: true
        };
    }

    componentDidMount() {
        const i18n = this.props.i18nHelper;
        const rs = exhibitorAccountRoles.map((kind) => {
            const lng = i18n.i18n.currentLanguageCode;
            const val = {};
            val[lng] = i18n.translate("exhibitoraccount.roles." + kind.toLowerCase());
            return {
                key: kind,
                labels: val
            };
        });
        this.setState({
            entityFieldFormTemplate: Object.assign({}, this.props.entityFieldFormTemplate, { valuesList: rs }),
            loading: false
        });
    }

    render() {
        const i18n = this.props.i18nHelper;

        const content = (this.state.loading) ? <div>{i18n.translate("display.loading")}</div> : <div>
            <DrowdownMultipleFormEditor
                {...this.props}
                noLabel={this.props.noLabel}
                entityFieldFormTemplate={this.state.entityFieldFormTemplate}
            />
        </div>;

        return <div>{content}</div>;
    }
}

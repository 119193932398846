import type { Entities } from '@inwink/entities/entities';
import type { States } from '../../../services/services';
import type { IEntityPageConfiguration } from './entitypagebase.props';

export function fieldFilter(key: string, entityconfiguration: IEntityPageConfiguration,
    user: States.IAppUserState, field: Entities.IEntityFieldTemplateV3, historyMode?: boolean): boolean {
    let fieldKey = key;
    const lowerFieldKey = fieldKey && fieldKey.toLowerCase();
    const customColumns = entityconfiguration && entityconfiguration.grid && entityconfiguration.grid.customColumn;
    const showColumns = entityconfiguration && entityconfiguration.grid && entityconfiguration.grid.showColumns;
    const showTechnicalFields = user
        && user.userPrefs
        && user.userPrefs.preferences
        && user.userPrefs.preferences.advancedUser
        && user.userPrefs.preferences.advancedUser.showTechnicalFields;

    if (key.indexOf('.') > -1) {
        const parts = key.split('.');
        fieldKey = parts[parts.length - 1];
    }

    if (entityconfiguration.excludedFields && entityconfiguration.excludedFields.indexOf(key) >= 0) {
        return false;
    }

    if (historyMode) {
        // en mode historique on affiche pas les entités liés + on affiche le modifiedby
        if (field.metadata?.isTechnical && lowerFieldKey === "modifiedbydisplayname") {
            return true;
        }
        if (field.type.toLowerCase() === "entity" || field.type.toLowerCase() === "entities") {
            return false;
        }
    }

    if ((field.metadata && field.metadata.isTechnical) || field.type.toLowerCase() === "guid") {
        if (showTechnicalFields || (lowerFieldKey === "validfrom")) {
            return true;
        }
        return false;
    } if (field.type && field.type.toLowerCase() === "entities") {
        const hasCustomColumns = customColumns && customColumns[lowerFieldKey];
        const doShowColumns = showColumns && (showColumns.indexOf(lowerFieldKey) >= 0);
        if (hasCustomColumns || doShowColumns) {
            return true;
        }
        return false;
    }
    return true;
}

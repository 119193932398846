import * as assign from 'lodash/assign';
import * as debounce from 'lodash/debounce';
import * as moment from 'moment';
import { stringify } from '@inwink/utils/querystring';
import {
    adminNotificationDataSource,
    dismissAll,
    dismissForMail
} from '../api/backoffice/adminnotifications';
import { States } from './services';

const INITIAL_STATE = {
    webpushChannel: null,
    notifications: null,
    showNotifs: null
} as States.IAppNotifications;

export function notificationsReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "APP_SETWEBPUSHCHANNEL": {
            return assign({}, state, {
                webpushChannel: action.payload
            });
        }
        case "APP_SETUSERNOTIF": {
            return assign({}, state, action.payload);
        }
        default:
            return state;
    }
}
const popForRelativeKinds = ["MassUpdate", "MassDelete", "DataExport"];
function isAllowedToPop(notif) {
    if (notif.relatedToKind.indexOf("deportedcommand-") === 0) {
        return true;
    }
    if (popForRelativeKinds.indexOf(notif.relatedToKind) >= 0) {
        return true;
    }

    return false;
}

export const notificationsActions = {
    setPushChannel() {
        return () => {
        };
    },

    debouncedCheckUnread() {
        return () => {
            return Promise.resolve();
        };
    },

    checkUnread(init = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const notifdata = adminNotificationDataSource();
            const query = {
                order: [{ by: 'messageDate', desc: true }],
                filters: {
                    dismissed: { $neq: true },
                    messageDate: { $gte: moment().add(-7, "days").toISOString()}
                }
            };

            return Promise.all([
                notifdata.count(query),
                notifdata.query(Object.assign({}, query, {
                    page: { returnTotalCount: true, index: 0, size: 20 }
                }))
            ]).then((queryResult) => {
                const count = queryResult[0];
                const notifs = queryResult[1];
                const state = getState();

                if (!init) {
                    const oldnotifs = state.notifications.notifications;
                    const newnotifs = notifs.data.filter((n) => {
                        const existing = oldnotifs.filter((on) => on.id === n.id)[0];
                        return !existing || existing.state !== n.state;
                    });

                    if (newnotifs.some((n) => isAllowedToPop(n))) {
                        setTimeout(() => {
                            dispatch({ type: "APP_SETUSERNOTIF", payload: { showNotifs: new Date() } });
                        }, 0);
                    }
                }
                dispatch({ type: "APP_SETUSERNOTIF", payload: { notifications: notifs.data, notificationsCount: count } });
            }, (err) => {
                console.error("error getting user notifications", err);
            });
        };
    },

    dismiss(notifid: string, delay?: Promise<any>) {
        return (dispatch, getState: () => States.IAppState) => {
            const notifdata = adminNotificationDataSource();
            return notifdata.delete([notifid], true).then(() => {
                if (delay) {
                    return delay;
                }
            }).then(() => {
                notificationsActions.checkUnread()(dispatch, getState);
            });
        };
    },

    dismissForMail(mailid: string) {
        return (dispatch, getState: () => States.IAppState) => {
            return dismissForMail(mailid).then(() => {
                notificationsActions.checkUnread()(dispatch, getState);
            });
        };
    },

    dismissAll() {
        return (dispatch, getState: () => States.IAppState) => {
            return dismissAll().then(() => {
                notificationsActions.checkUnread()(dispatch, getState);
            });
        };
    }
};

notificationsActions.debouncedCheckUnread = debounce((init, dispatch,
    getState) => notificationsActions.checkUnread(init)(dispatch, getState), 60000);

export function initNotifications(_inwink) {
    const inwink = _inwink;
    inwink.registerWebPush = () => {
        notificationsActions.setPushChannel();
    };
}

export function getLocation(customerid: string, authTenantid: string,
    eventid: string, communityId, _kind: string, id: string, parentid: string) {
    const kind = _kind.toLowerCase();
    let newlocation: any;
    if (eventid) {
        newlocation = { pathname: "/" + customerid + "/e/" + eventid + "/" };
        if (kind === "person") {
            newlocation = { pathname: `/${customerid}/e/${eventid}/registrations`,
                search: (id ? stringify({ showdetail: id }) : "") };
        } else if (kind === "session") {
            newlocation = { pathname: `/${customerid}/e/${eventid}/sessions`, search: (id ? stringify({ showdetail: id }) : "") };
        } else if (kind === "sessionperson") {
            newlocation = { pathname: `/${customerid}/e/${eventid}/sessionpersons`,
                search: (id ? stringify({ showdetail: id }) : "") };
        } else if (kind === "exhibitor") {
            newlocation = { pathname: `/${customerid}/e/${eventid}/exhibitors`,
                search: (id ? stringify({ showdetail: id }) : "") };
        } else if (kind === "order") {
            newlocation = { pathname: `/${customerid}/e/${eventid}/orders`, search: (id ? stringify({ showdetail: id }) : "") };
        } else if (kind === "surveyanswer" && parentid) {
            newlocation = { pathname: `/${customerid}/e/${eventid}/surveys/${parentid}/answers`,
                search: (id ? stringify({ showdetail: id }) : "") };
        } else if ((kind === "exhibitortask" || kind === "exhibitortaskanswer") && parentid) {
            newlocation = { pathname: `/${customerid}/e/${eventid}/exhibitors/tasks/${parentid}`,
                search: (id ? stringify({ showdetail: id }) : "") };
        } else if (kind === "exhibitorinvitation") {
            newlocation = { pathname: `/${customerid}/e/${eventid}/exhibitors/invitations`,
                search: (id ? stringify({ showdetail: id }) : "") };
        }
    } else if (communityId) {
        newlocation = { pathname: "/" + customerid + "/c/" + communityId + "/" };
    } else if (authTenantid) {
        newlocation = { pathname: "/" + customerid + "/t/" + authTenantid + "/" };
    }

    return newlocation;
}

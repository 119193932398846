import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

export const SimpleFadeTransition = (props) => (
  <CSSTransition
    {...props}
    classNames="simplefadetransition"
    timeout={props.timeout ? props.timeout : { enter: 500, exit: 500 }}
  />
);

export const FadeTransition = (props) => (
  <CSSTransition
    {...props}
    classNames="fadetransition"
    timeout={props.timeout ? props.timeout : { enter: 500, exit: 500 }}
  />
);

export const SlowFadeTransition = (props) => (
  <CSSTransition
    {...props}
    classNames="slowfadetransition"
    timeout={props.timeout ? props.timeout : { enter: 800, exit: 800 }}
  />
);

export const ItemTransition = (props) => (
  <CSSTransition
    {...props}
    classNames="itemtransition"
    appear={props.appear}
    timeout={props.timeout ? props.timeout : { enter: 200, exit: 200 }}
  />
);

export const PageTransition = (props) => (
  <CSSTransition
    {...props}
    classNames="pagestransition"
    timeout={1000}
  />
);

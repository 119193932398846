import type { Entities } from '@inwink/entities/entities';
import * as React from 'react';
import type { ICMSContext } from "../../../cmscontext";
import type { IGridSettingsColumn } from "./index";
import { FieldPicker } from "../../fieldpickerv3";
import type { IEntityPageConfiguration } from '../entitypagebase.props';

import './columnitem.less';

export interface IColumnItemProps {
    i18n: Entities.i18nHelper;
    displayLanguage: string;
    column: IGridSettingsColumn;
    isLocked: boolean;
    template: Entities.IEntityTemplateV3;
    entityname: string;
    allColumns: Record<string, IGridSettingsColumn>;
    remainingColumns: IGridSettingsColumn;
    excludedColumns: string[];
    selectedColumns: string[];
    focusedColumn: IGridSettingsColumn;
    cmscontext: ICMSContext;
    updateColumn: (column: IGridSettingsColumn, newcolumn: IGridSettingsColumn, field: string) => void;
    removeColumn: (column: IGridSettingsColumn) => void;
    onFocus: (column: IGridSettingsColumn) => void;
    fieldFilter: (key: string, field: Entities.IEntityFieldTemplateV3,
        template: Entities.IEntityTemplateV3, entityname: string) => boolean;
    entityConfs: Record<string, IEntityPageConfiguration>;
}

export class ColumnItem extends React.Component<IColumnItemProps, any> {
    fieldpicker = React.createRef<FieldPicker>();

    updateColumn = (field: string) => {
        const selectedColumn = this.props.allColumns[field];
        this.props.updateColumn(this.props.column, selectedColumn, selectedColumn.key);
    };

    remove = () => {
        this.props.removeColumn(this.props.column);
    };

    componentDidMount() {
        if (!this.props.column.field) {
            setTimeout(() => {
                this.fieldpicker.current.focus();
            }, 50);
        }
    }

    render() {
    // const columns = this.props.columns;
        const column = this.props.column;

        const dragger = <i className="inwink-drag_drop can-handle" />;
        const deleteBtn = <div onClick={this.remove} className="clickable remove-column">
            <i className="inwink-dialog-cancel" /></div>;
        let locker = null;
        if (this.props.isLocked) {
            locker = <div className="is-locked" />;
        }
        return <li data-key={this.props.column.key}>
            {locker}
            <div className="column-detail">
                {dragger}
                <FieldPicker
                    ref={this.fieldpicker}
                    cmscontext={this.props.cmscontext}
                    entityname={this.props.entityname}
                    excludeFields={[].concat(this.props.selectedColumns).concat(this.props.excludedColumns)}
                    template={this.props.template}
                    selectedField={column.key}
                    onChange={this.updateColumn}
                    fieldFilter={this.props.fieldFilter}
                    entityConfs={this.props.entityConfs}
                />
                {deleteBtn}
            </div>
        </li>;
    }
}

import type { Entities } from '@inwink/entities/entities';
import * as debounce from 'lodash/debounce';
import { IToolbarItem, ToolbarHeaderWrapper } from "@@ui/toolbar";
import { React, Loader, AppTextLabel } from '../../../../commons';
// import { IToolbarItem, ToolbarHeaderWrapper } from "../../../ui/toolbar";
import type { IEntityScreenProps } from '../entitypagebase';
import { EntityViewSelector } from '../tools/viewselector';
import { showActionsMenu } from '../actions';
import { EntityViewsManager } from '../entitypagebase.viewmgr';
import { SelectionToggle } from '../tools/selectiontoggle';
import type { IEntityPageConfiguration, IEntityViewsState } from '../entitypagebase.props';

import './entitygrid.toolbar.less';

export interface IEntityGridToolbarProps extends IEntityScreenProps {
    viewsManager: EntityViewsManager;
    viewsState: IEntityViewsState;
    tinyLoader: boolean;
    refreshGrid: Date;
    showSettings: boolean;
    i18nHelper: Entities.i18nHelper;
    setTinyLoader: (showTinyLoader: boolean) => void;
    setShowSettings: (show: boolean) => void;
    entityConfs: Record<string, IEntityPageConfiguration>;
}

export function EntityGridToolbar(props: IEntityGridToolbarProps) {
    return <EntityGridToolbarContent {...props} />;
}

interface IEntityGridToolbarContentProps extends IEntityGridToolbarProps {
}

interface IEntityGridToolbarState {
    searchTerm: string;
    leftItems: IToolbarItem[];
    rightItems: IToolbarItem[];
}

class EntityGridToolbarContent extends React.Component<IEntityGridToolbarContentProps, IEntityGridToolbarState> {
    constructor(props: IEntityGridToolbarContentProps) {
        super(props);

        this.setSearch = debounce(this.setSearch, 1000);

        this.state = {
            searchTerm: "",
            leftItems: this.getLeftItems(),
            rightItems: this.getRightItems(props)
        };
    }

    getLeftItems() {
        const toolbarItems: IToolbarItem[] = [];

        if (this.props.computedActions) {
            if (this.props.computedActions.primaryActions) {
                this.props.computedActions.primaryActions.forEach((act) => {
                    toolbarItems.push({
                        icon: act.icon,
                        id: act.key,
                        label: act.label as string,
                        displayMode: "icon",
                        disabled: () => {
                            return ((act.scope.selection && !this.props.viewsState.selection.length)
                                || (act.scope.selectionOnce && this.props.viewsState.selection.length !== 1));
                        },
                        itemClicked: (arg) => {
                            return this.props.helpers.triggerAction(act, arg);
                        }
                    });
                });
            }

            if (this.props.computedActions.secondaryActions) {
                toolbarItems.push({
                    icon: "inwink-more-h",
                    label: "entitypage.moreactions",
                    displayMode: "icon",
                    id: "showmore",
                    itemClicked: (arg) => {
                        return this.showMoreActions(arg);
                    }
                });
            }
        }

        toolbarItems.push({
            id: "selection",
            className: "selectiontoggle",
            show: () => this.props.viewsState.selection.length > 0,
            render: () => {
                return <SelectionToggle {...this.props} />;
            }
        });

        if (this.props.runMode === 'entitydetailtab'
            || this.props.runMode === 'dataimport') {
            toolbarItems.push({
                id: "rowcount",
                className: "rowcount",
                render: () => {
                    return <div className="itemscount">
                        <AppTextLabel
                            i18n="entitypage.rowcount"
                            inject={{ rowCount: ((this.props.viewsState && this.props.viewsState.rowCount) || "0") }}
                        />
                    </div>;
                }
            });
        }

        return toolbarItems;
    }

    showMoreActions = (arg: React.MouseEvent<any>) => {
        showActionsMenu(arg.currentTarget, {
            popovermgr: this.props.popovermgr,
            viewsState: this.props.viewsState,
            helpers: this.props.helpers,
            computedActions: this.props.computedActions,
            parentTarget: arg.currentTarget,
            entityconf: this.props.entityconfiguration
        });
    };

    getRightItems(props: IEntityGridToolbarContentProps): IToolbarItem[] {
        const res: IToolbarItem[] = [];
        const isEntityPage = this.props.runMode === "entitypage";
        res.push({
            id: "loader",
            className: "loader",
            render: () => {
                return <Loader
                    className={"fetchloader " + ((this.props.tinyLoader || this.props.searchLoader) ? 'showloader' : '')}
                    message=""
                />;
            }
        });

        if (props.entityconfiguration?.searchFields?.length) {
            res.push({
                id: "searchbox",
                render: () => {
                    return <div className="searchbox">
                        <input
                            autoComplete="off"
                            spellCheck={false}
                            placeholder={this.props.i18nHelper.translate("display.search")}
                            className="searchbar"
                            type="search"
                            key="inputsearch"
                            name="inputsearch"
                            value={this.state.searchTerm}
                            onChange={this.searchChanged}
                        />
                        <i className="inwink-search" />
                    </div>;
                }
            });
        }

        res.push({
            id: "refresh",
            icon: "inwink-sync",
            label: "actions.refresh",
            displayMode: "icon",
            itemClicked: () => {
                return this.props.viewsManager.reload();
            }
        });

        if (this.props.entityconfiguration?.datasource?.getViews
            && (this.props.runMode !== 'entitydetailtab'
                && this.props.runMode !== "removedentitypage"
                && this.props.runMode !== "dataimport")) {
            res.push({
                id: "viewselector",
                render: () => {
                    return <EntityViewSelector
                        entityName={this.props.entityconfiguration.entityName}
                        popovermgr={this.props.popovermgr}
                        i18n={this.props.i18n}
                        viewsState={this.props.viewsState}
                        viewsManager={this.props.viewsManager}
                    />;
                }
            });

            if (this.props.entityconfiguration.pinViewsToDashboards) {
                res.push({
                    id: "pinview",
                    icon: "inwink-push-pin",
                    label: "actions.pintodashboard",
                    displayMode: "icon",
                    show: () => {
                        return this.props.viewsState?.currentview && !!this.props.viewsState.currentview.id;
                    },
                    itemClicked: (arg) => {
                        return this.props.helpers.pinCurrentView(arg, this.props.helpers.getActionContext(null, null));
                    }
                });
            }
        }

        res.push({
            id: "filters",
            icon: "inwink-filter",
            className: "filter",
            label: "actions.filter",
            displayMode: "icon",
            badge: () => {
                const currentViewContent = this.props.viewsState?.currentview?.content;
                return currentViewContent && currentViewContent.filters && Object.keys(currentViewContent.filters).length;
            },
            itemClicked: () => {
                return this.props.helpers.openFilters();
            }
        });

        if (this.props.runMode !== 'dataimport') {
            if (this.props.entityconfiguration.notif && isEntityPage) {
                res.push({
                    id: "notifications",
                    icon: "inwink-bell",
                    label: "entitypage.notifications",
                    displayMode: "icon",
                    itemClicked: (arg) => {
                        return this.props.helpers.showNotifs(arg, this.props.helpers.getActionContext(null, null));
                    }
                });
            }

            if (
                (this.props.entityRights && this.props.entityRights.canImport && !this.props.entityconfiguration.disableImport)
                || (this.props.entityRights && this.props.entityRights.canExport && !this.props.entityconfiguration.disableExport)
            ) {
                res.push({
                    id: "importexport",
                    icon: "inwink-importexport",
                    label: "entitypage.importexport",
                    displayMode: "icon",
                    itemClicked: (arg) => {
                        return this.props.helpers.openDataModal(arg, this.props.helpers.getActionContext(null, null));
                    }
                });
            }
        }

        if (!this.props.entityconfiguration.disableTirette)
            res.push({
                id: "settings",
                icon: "inwink-settings-icon",
                label: "entitypage.showsettings",
                displayMode: "icon",
                itemClicked: () => {
                    return this.props.setShowSettings(!this.props.showSettings);
                }
            });

        return res;
    }

    searchChanged = (arg: React.FormEvent<any>) => {
        this.setState({ searchTerm: arg.currentTarget.value }, () => {
            this.setSearch();
        });
    };

    setSearch = () => {
        this.props.viewsManager.updateCurrentViewContent({ search: this.state.searchTerm });
    };

    componentDidUpdate(prevprops: IEntityGridToolbarContentProps) {
        if (prevprops.computedActions !== this.props.computedActions) {
            this.setState({
                leftItems: this.getLeftItems()
            });
        }
    }

    render() {
        return <ToolbarHeaderWrapper
            className="buttontoolbar gridtoolbar"
            leftToolbar={{
                items: this.state.leftItems
            }}
            rightToolbar={{
                items: this.state.rightItems
            }}
        />;
    }
}

import { guid } from '@inwink/utils/methods';
import type { IDashboardTiretteAddBoardProps } from "../../dashboard/dashboard.tirette";
import { EntityUpdateAllModal, IEntityUpdateAllModalProps } from "../entityupdateallpage";
import { openEntityImportv3 } from "../../import/openimportV3";
import type { IEntityPageAction } from "..";
import { openEntityDetail } from '../entitydetailv3/openEntityDetail';
import type { EntityPageContent } from './entitypagebase';
import type { IEntityScreenHelpers, EntityPageActionCallBackArg } from './entitypagebase.props';
import { openFilter } from "./filters/filter";
import { showNotifications } from "./tools/entitynotifs";
import { openFilters } from "./filters/modal/filters";
import type { ISlickGridColumn, IGetHelpersCallback } from './definitions';
import {
    React,
    AppTextLabel,
    AppAsyncButton,
    toastSuccess,
    toastError,
    omit,
    toastInfo
} from '../../../commons';
import type { IEntityGridToolbarProps } from './entitygrid/entitygrid.toolbar';
import { openExport, openFileExport, IOpenExport } from './export';
import MailEntityPicker from "../../designers/mailtemplatedesigner/mailTemplateV2/MailEntityPicker";
import { setGetHelpers } from './entitypagehelpers';

export const getHelpers: IGetHelpersCallback = (entityPage: EntityPageContent) => {
    const helpers: IEntityScreenHelpers = {
        triggerAction(action: IEntityPageAction, arg: React.MouseEvent<any>) {
            const ctx = this.getActionContext(action.params, action);
            const res = action.callback(arg, ctx);
            if (res && res.then) {
                return res.then((_res: any) => {
                    if (_res && action.toast && action.toast.success) {
                        toastSuccess(ctx.i18n, action.toast.success);
                    }
                }, (err) => {
                    if (action.toast && action.toast.error) {
                        toastError(ctx.i18n, action.toast.error);
                    }
                    return Promise.reject(err);
                });
            }
        },

        getActionContext(options, action: IEntityPageAction) {
            const res: EntityPageActionCallBackArg = {
                entityconfiguration: entityPage.state.entityconfiguration || entityPage.props.entityconfiguration,
                context: entityPage.state.entityconfigurationArgs,
                rights: entityPage.state.entityRights,
                popovermgr: entityPage.props.popovermgr,
                viewManager: entityPage.viewManager,
                viewState: entityPage.viewManager.state,
                component: entityPage,
                options: options,
                action: action,
                selection: entityPage?.viewManager?.state?.selection,
                expression: helpers.getFilterExpression(),
                props: { viewsState: entityPage?.viewManager?.state } as IEntityGridToolbarProps,
                tools: {
                    refresh: () => {
                        if (entityPage.props.onRefreshed) {
                            entityPage.props.onRefreshed();
                        }
                    },
                    setSelection: (selection) => {
                        entityPage.viewManager.setSelection(selection);
                    }
                },
                triggerFrom: action?.triggerFrom || undefined
            };

            return res;
        },

        openFilters() {
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            const viewcontent = entityPage.viewManager.state?.currentview?.content;
            openFilters(
                entityPage.props.popovermgr,
                entityPage.props.cmscontext,
                viewcontent && viewcontent.filters,
                entityPage.props.i18n,
                entityPage.props.user,
                entityconfig,
                null,
                entityPage.props.personnalizedFiltersEditor || (entityconfig.personnalizedFiltersEditor)
            )
                .then((res: any) => {
                    if (res) {
                        if (res === "reset") {
                            entityPage.viewManager.updateCurrentViewContent({
                                filters: [],
                                search: ""
                            });
                        } else {
                            entityPage.viewManager.updateCurrentViewContent({
                                filters: res
                            });
                        }
                    }
                });
        },

        openFilter(column: ISlickGridColumn, position) {
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            const currentViewContent = entityPage.viewManager?.state?.currentview?.content;

            const viewfilters = currentViewContent && currentViewContent.filters;

            const expression = viewfilters && viewfilters[column.key];

            openFilter(entityPage.props.popovermgr,
                entityPage.props.cmscontext,
                entityconfig,
                column,
                expression,
                position,
                entityPage.props.i18n).then((res: any) => {
                const filters = Object.assign({}, {}, viewfilters);
                const filter = filters[column.entityColumn.key];
                if (filter !== res) {
                    const filtersNew = ((!res)
                        ? omit(filters, column.entityColumn.key)
                        : Object.assign(filters, { [column.entityColumn.key]: res }) as any);
                    entityPage.viewManager.updateCurrentViewContent({
                        filters: filtersNew
                    });
                }
            });
        },

        openEntityDetail(item: any, isCreate: boolean, isDuplicate: boolean, ctx: EntityPageActionCallBackArg) {
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            const handleOpenEntityDetail = (entityPreValues, config, canEdit, relationEntityId?: string) => {
                return openEntityDetail(entityPage.props.popovermgr,
                    entityPage.props.cmscontext,
                    config,
                    entityconfig.datasource,
                    {
                        isCreate,
                        canEdit,
                        isDuplicate,
                        isRemove: entityPage.props.runMode === 'removedentitypage'
                    },
                    item,
                    entityPreValues,
                    relationEntityId).then((res) => {
                    if (res) {
                        ctx.tools.refresh();
                    }
                });
            };

            return entityconfig.detailConfig.configuration(entityPage.props.configurationArgs).then((config) => {
                let canEdit = false;
                if (isCreate) {
                    const disableCreate = entityconfig.detailConfig
                        && (entityconfig.detailConfig.disableCreate || entityconfig.detailConfig.readOnly);
                    canEdit = !disableCreate && entityPage.state.entityRights.canCreate;
                } else {
                    const disableUpdate = entityconfig.detailConfig
                        && (entityconfig.detailConfig.disableUpdate || entityconfig.detailConfig.readOnly);
                    canEdit = !disableUpdate && entityPage.state.entityRights.canUpdate;
                }

                if (config.onBeforeOpenEntityDetail) {
                    return config.onBeforeOpenEntityDetail({
                        isCreate,
                        canEdit,
                        itemSelected: item,
                        user: ctx.context.user,
                        cmscontext: ctx.context.cmscontext,
                        eventid: ctx.context.cmscontext.relatedTo,
                        popovermgr: ctx.popovermgr,
                        defaultValues: config.create && config.create.defaultValues(),
                        languages: ctx.context.cmscontext.configuration?.global?.supportedLanguages,
                        defaultLanguage: ctx.context.cmscontext.configuration?.global?.defaultLanguage,
                    }).then((epv: any) => {
                        const entityPreValues = epv;
                        if (entityPreValues) {
                            const propsEntityPicker: any = {
                                entityName: 'survey',
                                title: ctx.context.i18nHelper?.translate('mailtemplate.entitypickermodal.title'),
                                popovermgr: ctx.popovermgr
                            };
                            switch (entityPreValues?.relationEntityName) {
                                case 'surveyAnswer':
                                    return ctx.popovermgr?.modalPortal(
                                        MailEntityPicker,
                                        propsEntityPicker,
                                        'mailtemplate-entity-picker-modal',
                                        { disableOverlayDismiss: true }
                                    ).then((relationEntityId: string) => {
                                        entityPreValues.configuration.relationEntityId = relationEntityId;
                                        return relationEntityId
                                            ? handleOpenEntityDetail(entityPreValues, config, canEdit, relationEntityId) : null;
                                    });
                                default:
                                    return handleOpenEntityDetail(entityPreValues, config, canEdit);
                            }
                        }
                    });
                }

                return openEntityDetail(entityPage.props.popovermgr,
                    entityPage.props.cmscontext,
                    config,
                    entityconfig.datasource,
                    {
                        isCreate: isCreate,
                        canEdit: canEdit,
                        isDuplicate: isDuplicate,
                        isRemove: entityPage.props.runMode === 'removedentitypage'
                    },
                    item).then((res) => {
                    if (res) {
                        ctx.tools.refresh();
                    }
                });
            });
        },

        getFilterExpression() {
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            let expr = entityPage.viewManager && entityPage.viewManager.state && entityPage.viewManager.state.queryExpression;
            if (entityconfig.datasourcedefaultexpr) {
                if (expr) {
                    expr = { and: [entityconfig.datasourcedefaultexpr, expr] };
                } else {
                    expr = entityconfig.datasourcedefaultexpr;
                }
            } else if (entityPage.props.datasourcedefaultexpr) {
                if (expr) {
                    expr = { and: [entityPage.props.datasourcedefaultexpr, expr] };
                } else {
                    expr = entityPage.props.datasourcedefaultexpr;
                }
            }

            return expr;
        },

        showNotifs(arg: React.MouseEvent<any>) {
            arg.preventDefault();
            arg.stopPropagation();
            showNotifications(entityPage.props.popovermgr, arg.currentTarget, {
                entityconfiguration: entityPage.state.entityconfiguration || entityPage.props.entityconfiguration,
                cmscontext: entityPage.props.cmscontext,
                viewsState: entityPage.viewManager && entityPage.viewManager.state
            });
        },

        openImport(arg: React.MouseEvent<any>, ctx: EntityPageActionCallBackArg) {
            arg.preventDefault();
            arg.stopPropagation();
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;

            const p = (
                entityconfig && entityconfig.detailConfig && entityconfig.detailConfig.configuration
                && entityconfig.detailConfig.configuration(entityPage.props.configurationArgs)
            ) || Promise.resolve(null);

            return p.then(() => {
                return openEntityImportv3(entityPage.props.popovermgr, {
                    entityconfiguration: entityconfig,
                    cmscontext: entityPage.props.cmscontext,
                    excludedColumns: entityconfig.excludedFields,
                    popovermgr: entityPage.props.popovermgr,
                    entityPageProps: ctx.context,
                    entityGridArgs: entityPage.props.configurationArgs
                }).then(() => {
                    setTimeout(() => {
                        if (ctx.tools && ctx.tools.refresh) {
                            ctx.tools.refresh();
                        }
                    }, 100);
                }).catch((err) => console.error(err));
            });
        },

        openDataModal(arg: React.MouseEvent<any>, ctx: EntityPageActionCallBackArg) {
            arg.preventDefault();
            arg.stopPropagation();

            const content = (props) => {
                const exportItems = [];

                if (ctx.rights.canImport && !ctx.entityconfiguration.disableImport) {
                    exportItems.push(
                        <AppAsyncButton
                            key="open-import"
                            className="important"
                            onClick={() => { helpers.openImport(arg, ctx); props.onCompleted(); }}
                        >
                            <AppTextLabel i18n="actions.dataimport" component="span" />
                        </AppAsyncButton>
                    );
                }

                if (ctx.rights && ctx.rights.canExport && !ctx.entityconfiguration.disableExport) {
                    exportItems.push(
                        <AppAsyncButton
                            key="open-export"
                            className="important"
                            onClick={() => { helpers.openExport(arg, ctx); props.onCompleted(); }}
                        >
                            <AppTextLabel i18n="actions.dataexport" component="span" />
                        </AppAsyncButton>,
                        <AppAsyncButton
                            key="open-file-export"
                            className="important"
                            onClick={() => { helpers.openFileExport(arg, ctx); props.onCompleted(); }}
                        >
                            <AppTextLabel i18n="actions.fileexport" component="span" />
                        </AppAsyncButton>
                    );
                }
                return <div {...props} className="moreactions">{exportItems}</div>;
            };

            return entityPage.props.popovermgr.popoverPortal(arg.currentTarget, content, null, "popovermoreactions");
        },

        openExport(arg: React.MouseEvent<any>, ctx: EntityPageActionCallBackArg) {
            const viewsState = entityPage.viewManager && entityPage.viewManager.state;
            const currentViewContent = viewsState?.currentview?.content;
            const exportArgs: IOpenExport = {
                popovermgr: entityPage.props.popovermgr,
                i18n: entityPage.props.i18n,
                user: entityPage.props.user,
                entityName: entityPage.props.entityName,
                entityArgs: ctx.context,
                entityConfs: entityPage.state.entityConfs,
                expression: viewsState && viewsState.queryExpression,
                order: currentViewContent && currentViewContent.order,
                selectedColumns: currentViewContent && currentViewContent.columnKeys,
            };
            return openExport(exportArgs);
        },

        openFileExport(arg: React.MouseEvent<any>, ctx: EntityPageActionCallBackArg) {
            const viewsState = entityPage.viewManager && entityPage.viewManager.state;
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            const entityDetailConf = entityconfig && entityconfig.detailConfig;
            const p = (entityDetailConf.configuration && entityDetailConf.configuration(ctx.context)) || Promise.resolve(null);
            return p.then(() => {
                const currentViewContent = viewsState?.currentview?.content;

                return openFileExport(
                    entityPage.props.popovermgr,
                    entityPage.props.i18n,
                    entityPage.props.i18nHelper,
                    entityPage.props.user,
                    entityPage.props.cmscontext,
                    entityPage.props.entityName,
                    ctx.context,
                    entityPage.state.entityConfs,
                    viewsState && viewsState.queryExpression,
                    currentViewContent && currentViewContent.order,
                    currentViewContent && currentViewContent.columnKeys,
                );
            }).then((res) => {
                if (res) {
                    toastSuccess(entityPage.props.i18n, "toast.fileexport.success");
                }
            }).catch(() => {
                toastError(entityPage.props.i18n, "toast.fileexport.error");
            });
        },

        updateselection(arg: React.MouseEvent, ctx: EntityPageActionCallBackArg) {
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            const props: IEntityUpdateAllModalProps = {
                numberItemSelected: (ctx.selection && ctx.selection.length) || 0,
                datasource: entityconfig.datasource,
                cmscontext: entityPage.props.cmscontext,
                entityname: entityconfig.entityName,
                popovermgr: ctx.popovermgr,
                savePayloadFormatter: entityconfig.datasource
                    && entityconfig.datasource.options
                    && entityconfig.datasource.options.savePayloadFormater,
                user: entityPage.props.user
            };

            return ctx.popovermgr.modalPortal<any>(EntityUpdateAllModal,
                props,
                "allregistrationspage-updateall-modal entitydetailpage-modal")
                .then((res) => {
                    if (res && res !== {} && res.entity && res.entity !== {}) {
                        // datasourcedefaultexpr was only used in v2 so I deprecate it here and I'm using pre-filtered datasource
                        const massFilters = {
                            expression: { name: "id", op: "in", val: ctx.selection.map((entity) => entity.id) }
                        };

                        return entityconfig.datasource.massUpdate(res.entity, massFilters, false,
                            res.populateWithMergeArray).then(() => {
                            ctx.tools.refresh();
                            toastSuccess(entityPage.props.i18n, "toast.registrations.updateallselection.success");
                        }).catch(() => {
                            toastInfo(entityPage.props.i18n, `toast.massupdate.error`);
                        });
                    }
                });
        },

        updateall(arg: React.MouseEvent, ctx: EntityPageActionCallBackArg) {
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            const props = {
                numberItemSelected: (ctx.viewState && ctx.viewState.rowCount) || 0,
                datasource: entityconfig.datasource,
                cmscontext: entityPage.props.cmscontext,
                entityname: entityconfig.entityName,
                popovermgr: ctx.popovermgr,
                savePayloadFormatter: entityconfig.datasource
                    && entityconfig.datasource.options
                    && entityconfig.datasource.options.savePayloadFormater,
                user: entityPage.props.user
            } as IEntityUpdateAllModalProps;
            return ctx.popovermgr.modalPortal<any>(EntityUpdateAllModal,
                props,
                "allregistrationspage-updateall-modal entitydetailpage-modal")
                .then((res) => {
                    if (res && res !== {} && res.entity && res.entity !== {}) {
                        // datasourcedefaultexpr was only used in v2 so I deprecate it here and I'm using pre-filtered datasource
                        const massFilters = {
                            expression: entityPage.viewManager?.state?.queryExpression
                        };
                        return entityconfig.datasource.massUpdate(res.entity, massFilters, false,
                            res.populateWithMergeArray).then(() => {
                            ctx.tools.refresh();
                        });
                    }
                });
        },

        pinCurrentView(arg: React.MouseEvent, ctx: EntityPageActionCallBackArg) {
            const elt = arg.currentTarget;
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            return import(/* webpackChunkName: "dashboardpicker" */ '../../pickers/dashboardpicker')
                .then((dashboardPickerModule) => {
                    const dashboardDs = entityPage.props.cmscontext.getDashboardDatasource(entityconfig.pinViewsToDashboards[0]);
                    const addDashboardProps: IDashboardTiretteAddBoardProps = {
                        user: ctx.context.user,
                        allboards: null,
                        cmscontext: ctx.context.cmscontext,
                        i18n: ctx.context.i18n,
                        datasource: dashboardDs,
                        from: "toolbar"
                    };
                    return dashboardPickerModule.pickDashboard(entityPage.props.popovermgr, elt, dashboardDs.datasource as any,
                        entityconfig.pinViewsToDashboards, addDashboardProps).then((res: any) => {
                        if (res) {
                            const d = res.dashboard;
                            d.dashboardContent = d.dashboardContent || {};
                            d.dashboardContent.items = d.dashboardContent.items || [];
                            d.dashboardContent.items.push({
                                id: guid(),
                                name: "entityview",
                                settings: {
                                    entityname: entityconfig.entityName,
                                    title: entityPage.state.viewsState.currentview.title,
                                    entityViewId: entityPage.state.viewsState.currentview.entityViewId
                                        || entityPage.state.viewsState.currentview.id,
                                    isV3: true
                                }
                            });

                            return res.save(d).then(() => {
                                dashboardDs.goToBoard(entityPage.props.cmscontext, d.id);
                            });
                        }
                    });
                });
        },

        showEntityActions(arg: React.MouseEvent<any>, ctx: EntityPageActionCallBackArg, entity) {
            const entityconfig = entityPage.state.entityconfiguration || entityPage.props.entityconfiguration;
            const entityactions = entityconfig.entityactions || entityPage.props.entityactions;
            const itemactions = entityactions
                && entityactions.entityActions
                && entityactions.entityActions.filter((ea) => ea.icon || ea.title);

            const component = (props) => {
                const items = itemactions.map((action, idx) => {
                    return <div
                        className="itemaction clickable"
                        key={action.id + "#" + idx}
                        onClick={(_arg) => {
                            _arg.preventDefault();
                            _arg.stopPropagation();
                            props.onCompleted();
                            const _ctx = entityPage.state.helpers.getActionContext(action.params, action);

                            action.action(_arg, _ctx, entity);
                        }}
                    >
                        <i className={action.icon} /><span className="title"><AppTextLabel i18n={action.title} /></span>
                    </div>;
                });

                return <div className="itemactions">
                    {items}
                </div>;
            };

            return entityPage.props.popovermgr.popoverPortal(arg.target as HTMLElement
                || arg.currentTarget, component, {}, "entityactionspopover");
        }
    };

    return helpers;
};

export function initEntityPageHelpers() {
    setGetHelpers(getHelpers);
}

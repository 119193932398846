import { IPopoverManager} from '@inwink/modals';

export function editJsonModal(popovermgr: IPopoverManager, obj, schema?: string, language: string = "json", readOnly = false) {
    return import(/* webpackChunkName: "jsoneditor" */ './jsoneditormodal').then((mod) => {
        return mod.editJsonModal(popovermgr, obj, schema, language, readOnly).then((res) => res as any);
    });
}

export function editJsModal(popovermgr: IPopoverManager, obj, schema?: string, language?: string, readOnly = false) {
    return import(/* webpackChunkName: "jsoneditor" */ './jsoneditormodal').then((mod) => {
        return mod.editJsModal(popovermgr, obj, schema, language, readOnly).then((res) => res as any);
    });
}

export function editCSSModal(popovermgr: IPopoverManager, obj, schema?: string, language?: string, readOnly = false) {
    return import(/* webpackChunkName: "jsoneditor" */ './jsoneditormodal').then((mod) => {
        return mod.editCSSModal(popovermgr, obj, schema, language, readOnly).then((res) => res as any);
    });
}

/* eslint-disable max-classes-per-file */
import { Popover } from '@inwink/modals';
import { removeDiacritics } from '@inwink/utils/methods';
import * as debounce from 'lodash/debounce';
import type { Entities } from '@inwink/entities/entities';
import { i18NHelperContext } from '@inwink/i18n';
import { PopoverItemsList } from './popoveritem';
import { IFieldPickerItem, IFieldsDefinitions, getFields, getGroupFields } from "./definitions";
import type { ICMSContext } from "../../cmscontext";
import { React, AppTextLabel } from '../../../commons';
import type { IEntityGroupDefinition, FieldFilterCallback } from '../definitions';
import type { IEntityPageConfiguration } from '../entitypagev3/entitypagebase.props';

import "./fieldpicker.less";

export interface IFieldPickerProps {
    datastamp?: any;
    entityname?: string;
    template?: Entities.IEntityTemplateV3;
    groups?: Record<string, IEntityGroupDefinition>;
    excludeFields?: string[];
    cmscontext: ICMSContext;
    selectedField: string;
    onChange: (selected: string, field?: IFieldPickerItem) => void;
    fieldFilter?: FieldFilterCallback;
    entityConfs?: Record<string, IEntityPageConfiguration>;
    canClear?: boolean;
    disabledSortOnGroup?: boolean;
}

export interface IFieldPickerState {
    showPopover: boolean;
    hasError: boolean;
    currentInput: string;
    allFields: Record<string, IFieldPickerItem>;
    currentField: IFieldPickerItem;
    remainingFields: IFieldPickerItem;
    remainingFieldsFiltered: IFieldPickerItem;
    focused: boolean;
    entityConfs: Record<string, IEntityPageConfiguration>;
    template?: Entities.IEntityTemplateV3;
}
export class FieldPicker extends React.Component<IFieldPickerProps, any> {
    picker = React.createRef<FieldPickerContent>();

    focus() {
        if (this.picker && this.picker.current) {
            this.picker.current.focus();
        }
    }

    render() {
        return <i18NHelperContext.Consumer>
            {(i18nHelper) => <FieldPickerContent {...this.props} ref={this.picker} i18n={i18nHelper} />}
        </i18NHelperContext.Consumer>;
    }
}

interface IFieldPickerContentProps extends IFieldPickerProps {
    i18n: Entities.i18nHelper;
}
class FieldPickerContent extends React.Component<IFieldPickerContentProps, IFieldPickerState> {
    inputwrapper = React.createRef<HTMLDivElement>();

    input = React.createRef<HTMLInputElement>();

    unmounted = false;

    constructor(props: IFieldPickerContentProps) {
        super(props);

        this.debounceInputTyping = debounce(this.inputTyping.bind(this), 400);

        const fields = this.getFields(props, props.template);

        this.state = {
            showPopover: false,
            currentInput: null,
            hasError: false,
            allFields: fields && fields.allFields,
            currentField: fields && fields.currentField,
            remainingFields: fields && fields.remainingFields,
            remainingFieldsFiltered: null,
            focused: false,
            entityConfs: props.entityConfs || {},
            template: props.template
        };
    }

    getFields(props: IFieldPickerContentProps, template: Entities.IEntityTemplateV3) {
        let fields: IFieldsDefinitions;
        if (props.groups) {
            fields = getGroupFields(props.i18n, props.cmscontext?.displayLanguage || props.i18n?.i18n?.currentLanguageCode,
                props.excludeFields, props.groups, props.fieldFilter);
        } else if (template) {
            fields = getFields(props.i18n, props.entityname,
                props.cmscontext?.displayLanguage || props.i18n?.i18n?.currentLanguageCode,
                props.excludeFields, template, props.fieldFilter);
        }

        return {
            allFields: fields && fields.all,
            currentField: fields && this.props.selectedField && fields.all && fields.all[this.props.selectedField],
            remainingFields: fields && fields.remainingColumns,
        };
    }

    componentDidMount() {
        if (!this.state.template && this.props.entityname && this.props.cmscontext?.getWorkTemplate) {
            this.props.cmscontext.getWorkTemplate(this.props.entityname).then((template) => {
                if (template) {
                    const patch = Object.assign({
                        template: template
                    }, this.getFields(this.props, template));
                    this.setState(patch);
                }
            });
        }
    }

    componentDidUpdate(prevProps: IFieldPickerContentProps, prevState: IFieldPickerState) {
        if (this.state.currentField != null && prevProps && prevProps.selectedField && this.props && !this.props.selectedField) {
            this.setState({ currentField: null });
        }

        if (!prevState.allFields || prevProps.i18n !== this.props.i18n
            || (prevProps.groups !== this.props.groups && this.props.groups)) {
            const fields = this.getFields(this.props, this.state.template);
            if (fields.allFields !== this.state.allFields) {
                this.setState(fields);
            }
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    showPopover = () => {
        if (!this.unmounted) {
            this.setState({ showPopover: true });
        }
    };

    hidePopover = () => {
        if (!this.unmounted) {
            this.setState({ showPopover: false });
        }
    };

    inputChanged = (arg: React.FormEvent<HTMLInputElement>) => {
        if (!this.unmounted) {
            const currentText = arg.currentTarget.value;
            this.setState({ currentInput: currentText });
            this.inputTyping(currentText);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debounceInputTyping(value) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const e = "leave empty";
    }

    inputTyping(value) {
        if (!this.unmounted) {
            const inputValue = value.trim().toLowerCase();

            if (inputValue) {
                this.setState((prevState) => {
                    const searchInput = removeDiacritics(inputValue);
                    const filterColumns = this.filter(searchInput, prevState.remainingFields);

                    return {
                        remainingFieldsFiltered: filterColumns,
                        showPopover: true
                    };
                });
            } else {
                this.setState({ remainingFieldsFiltered: null, hasError: false, showPopover: false });
            }
        }
    }

    filter(searchterm: string, element: IFieldPickerItem): IFieldPickerItem {
        const newelement = { ...element, childs: null };

        if (element.childs) {
            const fields = Object.keys(element.childs).map((fieldkey) => {
                const field = element.childs[fieldkey];
                if (field.childs) {
                    return this.filter(searchterm, field);
                }
                return field;
            }).filter((_field) => {
                const field = _field;
                const original = element.childs[field.key];
                if (original.childs) { // Le champ est une entité liée
                    if (field.childs && Object.keys(field.childs).length >= 0) {
                        // Il y a des champs qui match on affiche les champs
                        return true;
                    }
                    if (field.filterlabel && field.filterlabel.indexOf(searchterm) >= 0) {
                        // Le nom de ce champ match avec la recherche
                        field.childs = original.childs; // On affiche tous les champs
                        return true;
                    }
                    return false;
                }
                if ((field.childs || field.filterlabel) && field.filterlabel.indexOf(searchterm) >= 0) {
                    return true;
                }

                return false;
            });

            if (fields.length) {
                newelement.childs = {};
                fields.forEach((f) => {
                    newelement.childs[f.key] = f;
                });
            }
        }
        return newelement;
    }

    onInputFocus = (event) => {
        if (!this.unmounted) {
            event.target.select();

            // if (this.props.column.isFake){
            this.setState({ showPopover: true });
            // }

            if (!this.state.focused) {
                this.setState({ focused: true });
            }
        }
    };

    onInputBlur = () => {
        setTimeout(() => {
            if (!this.unmounted) {
                this.setState({
                    currentInput: null,
                    hasError: false,
                    remainingFieldsFiltered: null,
                    focused: false
                });
            }
        }, 500);
    };

    selectField = (field: IFieldPickerItem) => {
        if (!this.unmounted) {
            this.setState((prevState) => {
                return {
                    currentInput: null,
                    showPopover: false,
                    hasError: false,
                    focused: false,
                    currentField: prevState.allFields && prevState.allFields[field.key]
                };
            });

            this.props.onChange(field.key, field);
        }
    };

    focus = () => {
        this.input.current.focus();
    };

    clearField = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.props.onChange(null, null);
    };

    render() {
    // const columns = this.props.columns;
        const field = this.state.currentField;
        const i18n = this.props.i18n;

        let deploy;

        if (
            this.state.remainingFields
      && this.state.remainingFields.childs
        ) {
            deploy = (
                <div className="chevron-container" onClick={this.showPopover}>
                    <i className="chevron inwink-chevronsmall-down" />
                </div>
            );
        }

        const label = field && field.label;

        let inputValue = label || "";
        if (this.state.focused && this.state.currentInput != null) {
            inputValue = this.state.currentInput;
        }

        let errorInput;
        if (this.state.hasError && this.state.focused) {
            errorInput = <AppTextLabel className="error-input" component="div" i18n="tirette.has.a.column.error" />;
        }

        let parent = null;
        if (field && field.parent && field.parent.label) {
            parent = <div className="parent-column">
                {field.parent.label}
            </div>;
        }

        return <div className="fieldpickerv3" ref={this.inputwrapper}>
            <div className="column-input">
                {parent}
                <input
                    ref={this.input}
                    type="text"
                    className="column"
                    onFocus={this.onInputFocus}
                    onChange={this.inputChanged}
                    onBlur={this.onInputBlur}
                    value={inputValue}
                />

            </div>
            {deploy}
            {this.props.canClear
                ? <div className="clearfield clickable" onClick={this.clearField}>
                    <i className="inwink-dialog-cancel" /></div> : null}
            {errorInput}
            <Popover
                className="tirette-column-fieldselectorpopover"
                show={this.state.showPopover}
                parent={this.inputwrapper.current}
                onhide={this.hidePopover}
                minWidth={300}
                autofocus={false}
            >
                <div className="fieldselector">
                    <PopoverItemsList
                        i18n={i18n}
                        displayLanguage={this.props.cmscontext?.displayLanguage || i18n?.i18n?.currentLanguageCode}
                        entityname={this.props.entityname}
                        onSelectColumn={this.selectField}
                        remainingFields={this.state.remainingFieldsFiltered || this.state.remainingFields}
                        template={this.state.template}
                        excludeFields={this.props.excludeFields}
                        entityConfs={this.state.entityConfs}
                        disabledSortOnGroup={this.props.disabledSortOnGroup}
                    />
                    <div style={{ clear: 'both' }} />
                </div>
            </Popover>
        </div>;
    }
}

import { authtenantEntityViewDataSource } from '../../backoffice/authtenantentityview';
import { userPreferences } from '../../base/userPreferences';
import { getEntityDataSource, IDataSourceV3Options, IDataSourceV3 } from '../../base/datasource';
import { getCustomerAPIProxy } from '../index';

export function getAuthTenantEntityDataSource<T>(customerId: string, tenantId: string, entityRoute: string,
    entityName: string, options?: IDataSourceV3Options): IDataSourceV3<T> {
    const proxy = getCustomerAPIProxy();
    const userPreferencesDatasource = authtenantEntityViewDataSource(customerId, tenantId);
    const userpreferencesProvider = userPreferences(userPreferencesDatasource, entityName);
    return getEntityDataSource<T>(
        proxy,
        `${customerId}/audience/${tenantId}/`,
        entityRoute,
        entityName,
        userpreferencesProvider,
        options
    );
}

import type { Entities } from '@inwink/entities/entities';
import { React } from '../../../commons';
import type { IExpressionDesignerProps } from './expressiondesigner';
import { ExpressionDesignerValueItem } from './expressiondesigner.item.value';
import { ExpressionDesignerValueNotItem } from './expressiondesigner.item.not';
import type { IEntityPageConfiguration } from '../entitypagev3/entitypagebase.props';

export interface IExpressionDesignerItemProps extends IExpressionDesignerProps {
    designerList: any;
    item: Entities.Expression;
    onItemChange: (item: Entities.Expression) => void;
    onRemoveItem: (expression: Entities.Expression) => void;
    itemTransformCondition: (expression: Entities.Expression, condition: string) => void;
    isFirst?: boolean;
    isAlone?: boolean;
    separator?: string;
    isReadOnly?: boolean;
    i18nHelper?: Entities.i18nHelper;
    isMultiStepRegistration?: boolean;
    entityconfig: IEntityPageConfiguration;
}

export class ExpressionDesignerItem extends React.Component<IExpressionDesignerItemProps, any> {
    onOrChange = (expression: Entities.Expression[]) => {
        this.props.item.or = expression;
        this.props.onItemChange(this.props.item);
    };

    onAndChange = (expression: Entities.Expression[]) => {
        this.props.item.and = expression;
        this.props.onItemChange(this.props.item);
    };

    onRemoveItem = () => {
        if (this.props.onRemoveItem) {
            this.props.onRemoveItem(this.props.item);
        }
    };

    render() {
        if (!this.props.item) {
            return this.props.designerList({
                ...this.props,
                onChange: this.onAndChange,
                separator: "and",
                expression: [],
                onRemoveAll: this.onRemoveItem,
                groups: this.props.groups,
                label: this.props.label
            });
        } if (this.props.item.or) {
            return <div className="expressiondesigner-or">
                {this.props.designerList({
                    ...this.props,
                    onChange: this.onOrChange,
                    separator: "or",
                    expression: this.props.item.or,
                    onRemoveAll: this.onRemoveItem,
                    groups: this.props.groups,
                    label: this.props.label
                })}
                <div className="link" />
            </div>;
        } if (this.props.item.name) {
            return <ExpressionDesignerValueItem
                {...this.props as any}
                separator={this.props.separator}
                isFirst={this.props.isFirst}
                itemTransformCondition={this.props.itemTransformCondition}
                onRemoveItem={this.onRemoveItem}
            />;
        } if (this.props.item.and) {
            return <div className="expressiondesigner-and">
                {this.props.designerList({
                    ...this.props,
                    onChange: this.onAndChange,
                    separator: "and",
                    expression: this.props.item.and,
                    onRemoveAll: this.onRemoveItem,
                    groups: this.props.groups,
                    label: this.props.label
                })}
                <div className="link" />
            </div>;
        } if (this.props.item.not || this.props.item.not == null) {
            return <ExpressionDesignerValueNotItem
                {...this.props as any}
                item={this.props.item}
                onRemoveItem={this.onRemoveItem}
            />;
        }
        return <div>?</div>;
    }
}

import { Link } from "react-router-dom";
import { React, connectwith, TransitionGroup } from '../commons';
import { States } from '../services/services';
import { BurgerMenu } from './ui/burgermenu';
import { SlowFadeTransition } from './transitions';
import { Swipeable } from './ui/swipeable';

import './appmenu.less';

export interface IAppMenuProps {
    appmenu?: States.IAppMenuState;
    customer: States.ICustomerState;
    event: States.IEventState;
    community?: States.ICommunityState;
    user: States.IAppUserState;
    i18n: States.i18nState;
    rights: States.IAppUserRights;
    toggleMenu: () => void;
    menuItemClicked?: () => void;
    collapsed: boolean;
    allowFixed: boolean;
    allowSubmenuTooltip: boolean;
    isMobile: boolean;
    menu: any;
}

interface IAppMenuState {
}

function mapStateToProps(state: States.IAppState) {
    return {
        community: state.community,
        event: state.event,
        user: state.user,
        customer: state.customer,
        appmenu: state.appmenu
    };
}

@connectwith(mapStateToProps, null)
export class AppMenu extends React.PureComponent<IAppMenuProps, IAppMenuState> {
    toggleMenu = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.toggleMenu();
    };

    menuItemClicked = () => {
        if (!this.props.allowFixed && !this.props.collapsed) {
            this.props.toggleMenu();
        }
    };

    onSwiped = () => {
        this.props.toggleMenu();
    };

    render() {
        let content;
        if (this.props.appmenu && this.props.appmenu.component) {
            content = [<SlowFadeTransition key={this.props.appmenu.component.name}><div className="appmenu-content">
                {React.createElement(this.props.appmenu.component, Object.assign({}, this.props, {
                    menuItemClicked: this.menuItemClicked
                }))}
            </div>
            </SlowFadeTransition>];
        }

        const menucontent = <div className="appmenu-content">
            <header>
                <Link to="/">
                    <img alt="inwink" src={(inwink.config.assetsUrl || "/") + "assets/images/logo-blanc.png"} />
                    {inwink.config.env !== "prod" ? <span className="env">{inwink.config.env}</span> : ""}
                </Link>
                <BurgerMenu collapsed={true} allowAnimation={true} onClick={this.toggleMenu} />
            </header>
            <TransitionGroup component="section">
                {content}
            </TransitionGroup>
        </div>;

        if (this.props.isMobile) {
            return <>
                <div className="appmenu-overlay" onClick={this.toggleMenu} />
                <div className="appmenu">
                    <Swipeable
                        className="appmenu-swipe"
                        behavior="slide"
                        disableRight
                        selectThresholdPx={60}
                        onChangeIndex={this.onSwiped}
                    >
                        {menucontent}
                    </Swipeable>
                </div>
            </>;
        }
        return <>
            <div className="appmenu-overlay" onClick={this.toggleMenu} />
            <div className="appmenu">
                {menucontent}
            </div>
        </>;
    }
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-syntax */
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n';
import { Popover } from '@inwink/modals';
import * as find from 'lodash/find';
import { connectwith, React, translateEventLabel } from '../../../../commons';
import { AppTextLabel } from '../../../../services/i18nservice';
import type { States } from '../../../../services/services';
import type { IExpressionProps } from "../definitions";
import './expressionmultiselectlist.less';

export function getExpressionFromProps(expressions, type: string) {
    let selected = [];
    const tp = type.toLowerCase();
    if (expressions.or) {
        expressions.or.map((e) => {
            if (tp === "multiselectlist" && e.op !== 'isnull' && !e.or && e.val) {
                selected = [...e.val];
            } else if (tp === "selectlist") {
                if (e.or) {
                    if (!find(e.or, { op: 'isnull' })) {
                        selected = [...e.or.map((select) => (select.val))];
                    }
                } else if (e.op === "eq") {
                    selected.push(e.val);
                }
            }
            return null;
        });
    } else if (expressions.val) {
        if (tp === 'multiselectlist' && typeof expressions.val !== 'string') {
            selected = [...expressions.val];
        } else {
            selected.push(expressions.val);
        }
    }
    return selected;
}

interface IExpressionMultiSelectListState {
    search: string;
    canBeNull: boolean;
    selected: any[];
    blankFilter: boolean;
    allValues: Entities.IEntityFieldValue[];
    showPicker: boolean;
    pickerParent?: EventTarget;
}

/* props.inline is true when modal filterS opened (all column filters),
false when modal filter (filter on a specific grid column) */
@connectwith((state: States.IAppState) => ({ event: state.event }))
@withI18nHelper()
export class ExpressionMultiSelectList extends React.Component<IExpressionProps, IExpressionMultiSelectListState> {
    constructor(props: IExpressionProps) {
        super(props);

        const field = props.entityField;
        const fieldType = field && field.type;
        let blankFilter = false;
        if (props.expressions && props.expressions.or) {
            if (fieldType.toLowerCase() === "multiselectlist") {
                for (const filter of props.expressions.or) {
                    if (filter.name === props.fieldItem.key && filter.op === "size" && filter.val === 0) {
                        blankFilter = true;
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                }
            } else if (fieldType.toLowerCase() === "selectlist") {
                for (const filter of props.expressions.or) {
                    if (
                        (filter.name === props.fieldItem.key && filter.op === "eq" && filter.val === "")
                        || (filter.name === props.fieldItem.key && filter.op === "isnull" && filter.val === null)
                    ) {
                        blankFilter = true;
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                }
            }
        }

        this.state = {
            showPicker: false,
            search: '',
            canBeNull: field && field.metadata && field.metadata.canBeNull,
            selected: (!props.expressions) ? [] : [...getExpressionFromProps(props.expressions, fieldType)],
            blankFilter,
            allValues: this.getAllValuesList(field ? field.valuesList : null)
        };
    }

    getAllValuesList(valuesList: Entities.IEntityFieldValue[]): Entities.IEntityFieldValue[] {
        if (valuesList) {
            let all = [];
            valuesList.forEach((val) => {
                if (val) {
                    if (val.key) {
                        all.push(val);
                    } else {
                        all = all.concat(this.getAllValuesList(val.valuesList));
                    }
                }
            });

            return all;
        }

        return null;
    }

    handleChange = (arg) => {
        this.setState({ search: arg.target.value });
    };

    formatExpression = () => {
        let expression = null;
        const blankFilter = this.state.blankFilter;
        const selected = [...this.state.selected];

        if (selected.length || blankFilter) {
            expression = { or: [] };
        }

        const field = this.props.entityField;
        const coltype = field && field.type && field.type.toLowerCase();

        if (selected.length) {
            if (coltype === "multiselectlist") {
                expression.or.push({
                    name: this.props.fieldItem.key,
                    op: 'some',
                    val: selected
                });
            } else if (coltype === "selectlist") {
                expression.or.push(...selected.filter((v) => v !== "").map((select) => {
                    return {
                        name: this.props.fieldItem.key,
                        op: 'eq',
                        val: select
                    };
                }));
            }
        }

        if (this.state.canBeNull && blankFilter) {
            if (coltype === "multiselectlist") {
                expression.or.push({
                    name: this.props.fieldItem.key,
                    op: 'size',
                    val: 0
                });
            } else if (coltype === "selectlist") {
                expression.or.push({
                    name: this.props.fieldItem.key,
                    op: 'isnull',
                    val: null
                },
                {
                    name: this.props.fieldItem.key,
                    op: 'eq',
                    val: ''
                });
            }
        }

        if (this.props.inline) {
            this.props.setExpression(expression);
        } else {
            /* setAllowSave(true and false) callback are used to make sure we don't apply unformatted filters
            it's probably useless but we had a bug about strange behaviour
            */
            this.props.setAllowSave(false, () => this.props.setExpression(expression, () => this.props.setAllowSave(true)));
        }
    };

    select = (key) => {
        this.setState((prevState) => {
            const type = this.props.entityField.type.toLowerCase();
            let selected = [...prevState.selected];
            let _key = key;
            let blankFilter = prevState.blankFilter;

            if (key === 'blankFilter') {
                _key = "";
                blankFilter = !blankFilter;

                // #36370
                if (blankFilter && type === "multiselectlist") { selected = []; }
            } else if (type === "multiselectlist") { // #36370
                selected = selected.filter((v) => v !== "");
                blankFilter = false;
            }

            const keySelect = selected.indexOf(_key);
            if (keySelect !== -1) { // je supprime la clé
                selected.splice(keySelect, 1);
            } else { // je l'ajoute
                selected.push(_key);
            }

            return { selected, blankFilter };
        }, () => this.formatExpression());
    };

    selectAll = () => {
        if (this.state.allValues) {
            if (this.state.selected.length) {
                this.setState({ selected: [], blankFilter: false }, () => this.formatExpression());
            } else {
                this.setState((prevState) => {
                    const selected = prevState.allValues.map((v) => v.key);
                    if (prevState.canBeNull) { selected.push(""); }
                    return {
                        selected,
                        blankFilter: true
                    };
                }, () => this.formatExpression());
            }
        }
    };

    getLabel(i18n: Entities.i18nHelper, key: string, labels) {
        let label = key;
        if (labels) {
            label = translateEventLabel(this.props.cmscontext
                ? this.props.cmscontext.displayLanguage : i18n.i18n.currentLanguageCode, i18n.i18n, labels);
        } else if (this.props.entityconfig && this.props.entityconfig.entityName) {
            const tradkey = `${this.props.entityconfig.entityName}.${this.props.entityField.key}.${key}`.toLowerCase();
            label = i18n.translate(tradkey, null, tradkey);
        } else if (this.props.fieldItem && this.props.fieldItem.tradkey) {
            const tradkey = `${this.props.fieldItem.tradkey}.${key}`.toLowerCase();
            label = i18n.translate(tradkey, null, tradkey);
        } else {
            const tradkey = `${this.props.entityField.key}.${key}`.toLowerCase();
            label = i18n.translate(tradkey, null, tradkey);
        }
        return label;
    }

    renderlist(items, currentItems, allselected) {
        const i18n = this.props.i18nHelper;

        return <div className="filter-multiselectlist filter-type">
            <header>
                <input
                    type="search"
                    placeholder={i18n.translate('display.search')}
                    value={this.state.search}
                    onChange={this.handleChange}
                />
                <i className="inwink-search" />
            </header>
            <section>
                <ul>
                    <li className={(allselected) ? 'selected' : ''} onClick={this.selectAll}>
                        <span className="selection" />
                        {(allselected ? i18n.translate('filter.unselect_all') : i18n.translate('filter.select_all'))}
                    </li>
                    {items}
                    <li
                        className={((this.state.blankFilter) ? 'selected' : '')
                            + " blank_filter" + ((this.state.canBeNull) ? '' : ' hide-selected')}
                        onClick={() => this.select('blankFilter')}
                    >
                        <span className="selection" />
                        ({i18n.translate('filter.blank_filter')})
                    </li>
                </ul>
            </section>
        </div>;
    }

    renderinline(items, currentItems, allselected) {
        const i18n = this.props.i18nHelper;
        const popover = <Popover
            className="filter-popover popover-expressionentitites"
            show={this.state.showPicker}
            parent={this.state.pickerParent}
            onhide={() => this.setState({ showPicker: false })}
        >
            <div className="filter">
                <div className="filter-multiselectlist filter-type">
                    <header>
                        <input
                            type="search"
                            placeholder={i18n.translate('display.search')}
                            value={this.state.search}
                            onChange={this.handleChange}
                        />
                        <i className="inwink-search" />
                    </header>
                    <section>
                        <ul>
                            <li className={(allselected) ? 'selected' : ''} onClick={this.selectAll}>
                                <span className="selection" />
                                {(allselected ? i18n.translate('filter.unselect_all') : i18n.translate('filter.select_all'))}
                            </li>
                            {items}
                            <li
                                className={((this.state.blankFilter) ? 'selected' : '')
                                    + " blank_filter" + ((this.state.canBeNull) ? '' : ' hide-selected')}
                                onClick={() => this.select('blankFilter')}
                            >
                                <span className="selection" />
                                ({i18n.translate('filter.blank_filter')})
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </Popover>;

        return <div>
            <div
                className="items clickable"
                onClick={(arg) => { this.setState({ showPicker: true, pickerParent: arg.target }); }}
            >
                {currentItems}
                <div style={{ clear: 'both' }} />
            </div>
            {popover}
        </div>;
    }

    render() {
        const i18n = this.props.i18nHelper;

        let items = null;
        let allselected = false;

        if (this.state.allValues) {
            items = this.state.allValues
                .filter((v) => {
                    const search = this.state.search.toUpperCase();
                    if (search === "") {
                        return true;
                    }
                    const value = this.getLabel(i18n, v.key, v.labels);
                    return value.toUpperCase().indexOf(search) > -1;
                })
                .map((v) => {
                    const value = this.getLabel(i18n, v.key, v.labels);
                    let val = value;
                    // Fix pour retirer le html si il est présent dans une des réponses dans le cas d'un survey
                    if (this.props.entityconfig?.entityName?.toLowerCase() === "surveyanswer") {
                        val = value.replace(/<(.|\n)*?>/g, '');
                    }
                    const _selected = this.state.selected.indexOf(v.key) !== -1 ? 'selected' : '';
                    return <li key={v.key} className={_selected} onClick={() => this.select(v.key)}>
                        <span className="selection" /> <span className="filter-list-item">{val}</span>
                    </li>;
                });

            if (
                this.state.selected.filter((v) => v !== "").length === this.state.allValues.length
                && (this.state.canBeNull && this.state.blankFilter)
            ) {
                allselected = true;
            }
        }
        const currentItems = [];
        if (this.state.selected && this.state.selected.length) {
            this.state.selected.forEach((item) => {
                let label = item;
                for (let i = 0; i < this.state.allValues.length; i++) {
                    if (this.state.allValues[i].key === item) {
                        label = this.getLabel(i18n, this.state.allValues[i].key, this.state.allValues[i].labels);
                        break;
                    }
                }

                currentItems.push(<div className="item">{label || i18n.translate('filter.blank_filter')}</div>);
            });
        } else {
            currentItems.push(<div key="remark" className="remark">
                <AppTextLabel i18n="expressionmultiselect.clicktoselect" /></div>);
        }

        return this.props.inline
            ? this.renderinline(items, currentItems, allselected)
            : this.renderlist(items, currentItems, allselected);
    }
}

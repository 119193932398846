import { defaultPostHeaderForJsonData } from '@inwink/apibase';
import { getBackOfficeProxy } from './index';

export interface ILogData {
    eventid? : string;
    customerid? : string;
    level?: number;
}
export function getErrorData(data: ILogData, error, msg?: string) {
    const deviceinfos : any = {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        cookieEnabled: navigator.cookieEnabled,
        language: navigator.language,
        platform: navigator.platform,
        languages: navigator.languages && JSON.stringify(navigator.languages),
        product: navigator.product,
        productSub: navigator.productSub,
        userAgent: navigator.userAgent
    };

    const message: any = {
        now: new Date().toString(),
        message: msg,
        exception: {}
    };

    if (error.name) message.exception.name = error.name;
    if (error.stack) message.exception.stack = error.stack;

    if (error.message) message.exception.message = error.message;
    else if (error.description) message.exception.message = error.description;

    message.asString = error.toString();

    return {
        level: data?.level || 4,
        eventId: data?.eventid,
        customerId: data?.customerid,
        device: deviceinfos,
        version: inwink.config.version,
        location: window.location.href,
        message: JSON.stringify(message)
    };
}

export function trackError(data:ILogData, error, msg?: string): Promise<any> {
    const errordata = getErrorData(data, error, msg);

    return trackErrorInternal(errordata);
}

export function trackErrorInternal(data): Promise<any> {
    const proxy = getBackOfficeProxy();
    return proxy.postJson("track/exception", JSON.stringify(data), defaultPostHeaderForJsonData).then(null, (err) => {
        console.warn("cannot send exception tracking", err);
    });
}

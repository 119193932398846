import { connect } from 'react-redux';
import { LoadingPanel } from '@inwink/loadable';
import { React, Loader, AppLabel } from '../../../../commons';
import { States } from '../../../../services/services';
import type { IExpressionProps } from "../definitions";
import { EntityPage } from "../../entitypagev3/entitypagebase";
import { EntityPicker } from "../../entitypicker/index";
import type { IEntityPageConfiguration } from "../../entitypagev3/entitypagebase.props";

import './expressionentity.less';

interface IExpressionEntityProps extends IExpressionProps {
    event: States.IEventState;
    i18n: States.i18nState;
    user: States.IAppUserState;
    rights: States.IAppUserRights;
    idFields: string[];
    entityArgs?: any;
}

interface IExpressionEntityComponentV3State {
    selection: any[];
    idFields: string[];
    notfound: boolean;
    loading: boolean;
}

class ExpressionEntityComponentV3 extends React.Component<IExpressionEntityProps, IExpressionEntityComponentV3State> {
    constructor(props: IExpressionEntityProps) {
        super(props);
        this.state = {
            selection: null,
            idFields: props.idFields || null,
            notfound: false,
            loading: true
        };
    }

    componentDidMount() {
        this.props.cmscontext.getEntityConfiguration(this.props.entityField.value, this.props as any).then((conf) => {
            const idFields = this.state.idFields || conf.idFields || ['id'];
            return this.getSelectionFromProps(conf, this.props.expressions, idFields).then((selection) => {
                this.setState({ idFields, selection: selection, loading: false });
            });
        }, () => {
            this.setState({ notfound: true });
            console.log("cannot get entityconfiguration");
        });
    }

    getSelectionFromProps = (entityConfiguration: IEntityPageConfiguration, expressions, idFields: string[]) => {
        let exprs = null;
        if (idFields.length === 1) {
            if (expressions && expressions.val) {
                exprs = {
                    name: idFields[0],
                    op: "eq",
                    val: expressions.val
                };
            }
        } else if (expressions && expressions.and && expressions.and.length) {
            exprs = {
                and: idFields.forEach((id) => {
                    return {
                        name: id,
                        op: "eq",
                        val: expressions.and.filter((e) => e.name.toLowerCase().endsWith(`.${id}`)[0].val)
                    };
                })
            };
        }

        if (exprs == null) {
            return Promise.resolve(null);
        }

        const expands = entityConfiguration && entityConfiguration.display && entityConfiguration.display.expands;
        return entityConfiguration.datasource.query({
            expression: exprs,
            page: {
                index: 0,
                size: 1
            }
        }, expands).then((res) => {
            return res.data;
        }, (err) => {
            console.error("error loading selection", err);
        });
    };

    selectionChanged = (selection: any[]) => {
        let expression;
        if (selection && selection.length === 1 && this.state.idFields) {
            if (this.state.idFields.length === 1) {
                expression = {
                    name: `${this.props.fieldItem?.key || this.props.entityField.key}.${this.state.idFields[0]}`,
                    op: 'eq',
                    val: selection[0][this.state.idFields[0]]
                };
            } else {
                expression = { and: [] };
                this.state.idFields.forEach((id) => {
                    expression.and.push({
                        name: `${this.props.fieldItem?.key || this.props.entityField.key}.${id}`,
                        op: 'eq',
                        val: selection[0][id]
                    });
                });
            }
        }
        if (this.props.inline) {
            this.props.updateInline({ expression: expression });
        } else {
            this.props.setExpression(expression);
        }

        const allowSave = true;
        if (allowSave !== this.props.allowSave) { this.props.setAllowSave(allowSave); }
    };

    renderInline() {
        return <EntityPicker
            multiselect={false}
            entityName={this.props.entityField.value}
            entityArgs={this.props.entityArgs}
            selection={this.state.selection}
            readOnly={false}
            disableAddNew={true}
            onChange={(selectedIds, selected) => {
                this.selectionChanged(selected);
            }}
        />;
    }

    render() {
        if (this.state.notfound) {
            return <div className="filter-entityv3 notfound">
                <AppLabel i18nService={this.props.i18n} i18n="filter.nofilter" />
            </div>;
        }
        if (!this.state.idFields || this.state.loading) {
            return <div className="filter-entityv3"><Loader /></div>;
        }
        if (this.props.inline) {
            return this.renderInline();
        }
        return <div className="filter-entityv3">
            <LoadingPanel loader={Loader as any} noInheritance={true} stateDelay={300}>
                <EntityPage
                    user={this.props.user}
                    rights={this.props.rights}
                    entityName={this.props.entityField.value}
                    configurationArgs={null}
                    entityconfiguration={null}
                    configurationProvider={this.props.cmscontext.getEntityConfiguration}
                    className={null}
                    entityactions={null}
                    pagetitle={null}
                    multiselect={false}
                    initialSelection={this.state.selection}
                    selectionChanged={this.selectionChanged}
                    runMode="picker"
                    viewMode="list"
                    disableAddNew={true}
                />
            </LoadingPanel>
        </div>;
    }
}

function mapStateToPropsV3(state: States.IAppState) {
    return {
        event: state.event,
        i18n: state.i18n,
        user: state.user,
        rights: state.rights
    };
}

function mapDispatchToPropsV3() {
    return {

    };
}

export const ExpressionEntityV3: new (any) => React.Component<IExpressionProps, any> = connect(
    mapStateToPropsV3,
    mapDispatchToPropsV3
)(ExpressionEntityComponentV3 as any) as any;

import { React, AppTextLabel } from '../../../commons';
import { Popover } from '@inwink/modals';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n';

export const regex = [
    {
        key: "mail",
        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    },
    {
        key: "text",
        value: /.*/

    },
    {
        key: "number",
        value: /^[0-9]*$/
    }
];

export interface IMultiTextInputRecommandationsItem {
    value: string;
    trad?: string;
}

export interface IMultiTextInputProps {
    i18n: string;
    i18nHelper?: Entities.i18nHelper;
    onChange: (val: any[]) => void;
    value: string | string[];
    canEditTrad: boolean;
    type?: string;
    recommandations?: IMultiTextInputRecommandationsItem[];
    readonly?: boolean;
}

@withI18nHelper()
export class MultiTextInput extends React.Component<IMultiTextInputProps, any> {
    regex: any;
    inputwrapper = React.createRef<HTMLDivElement>();
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.addString = this.addString.bind(this);

        this.state = {
            currentValue: "",
            values: this.props.value,
            isInvalid: false
        };

    }

    componentDidUpdate(prevProps: IMultiTextInputProps, prevState) {
        if ((!prevProps.value || !prevProps.value.length) && this.props.value && this.props.value.length) {
            this.setState({
                values: this.props.value
            });
        }
    }

    check(value) {
        if (this.props.type) {
            let rule;
            regex.map((r) => {
                if (r.key === this.props.type) {
                    rule = r.value;
                }
            });

            if (rule) {
                return rule.test(value);
            } else {
                return true;
            }
        }
    }

    removeValue(index) {
        let values = this.state.values && this.state.values.length ? this.state.values : null;
        if (values) {
            values.splice(index, 1);
            this.setState({ values });
            this.props.onChange(values);

        }
    }

    addString(arg: React.MouseEvent<any>, value?: string) {
        arg.preventDefault();
        arg.stopPropagation();

        let values = this.state.values && this.state.values.length ? this.state.values : [];
        if (this.state.currentValue || value) {
            const isCorrect = this.props.type ? this.check(value ? value : this.state.currentValue) : true;
            if (isCorrect) {
                values.push(value ? value : this.state.currentValue);
                this.setState({
                    values,
                    currentValue: ""
                });
                this.props.onChange(values);
            } else {
                this.setState({ isInvalid: true });
            }
        }
    }

    onChange(val) {
        this.setState({ currentValue: val });
    }

    onRecommandationsChange(arg: React.MouseEvent<any>, value: string) {
        this.setState({ showRecommandations: false });
        this.addString(arg, value);
    }

    getValueLabel = (value: string, i18nHelper: Entities.i18nHelper) => {
        if (this.props.recommandations && this.props.recommandations.length) {
            let tradKey;
            this.props.recommandations.map((recommandation) => {
                if (recommandation && recommandation.value === value) {
                    tradKey = recommandation.trad;
                }
            });
            if (tradKey && i18nHelper) {
                return i18nHelper.translate(tradKey);
            }
        }
    }

    render() {
        let values;
        let i18nHelper = this.props.i18nHelper;
        const hasRecommandations = this.props.recommandations && this.props.recommandations.length;
        if (this.state.values && this.state.values.length && Array.isArray(this.state.values)) {
            values = this.state.values.map((value, index) => {
                const label = this.getValueLabel(value, i18nHelper);
                return <div className="value" key={index}>
                    <span>{label ? `${label} (${value})` : value}</span>
                    <div onClick={() => this.removeValue(index)} className="btnremove clickable inwink-cancel"></div>
                </div>;
            });
        } else {
            values = "";
        }

        return <form className="fieldbloc multitextinput" onSubmit={this.addString}>
            <AppTextLabel component="label" i18n={this.props.i18n} />
            <AppTextLabel component="div" className="field-desc" i18n={this.props.i18n + ".desc"} defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""} />
            <div className="add-field" ref={this.inputwrapper}>
                <input
                    onClick={hasRecommandations ? () => this.setState({ showRecommandations: true }) : null}
                    type={this.props.type || "text"}
                    readOnly={this.props.readonly || false}
                    className={this.state.isInvalid ? "invalid" : ""}
                    onChange={(arg) => this.onChange(arg.currentTarget.value)}
                    value={this.state.currentValue} />
                <div onClick={this.addString} className="add-picto">+</div>
            </div>
            {hasRecommandations ?
                <Popover
                    className="multitextinput-recommandations-modal"
                    show={this.state.showRecommandations}
                    parent={this.inputwrapper.current}
                    minWidth={300}
                    onhide={() => this.setState({ showRecommandations: false })}
                    autofocus={false}
                >
                    <MultiTextInputRecommandationsList
                        onChange={(arg, value) => this.onRecommandationsChange(arg, value)}
                        recommandations={this.props.recommandations}
                        i18nHelper={i18nHelper} />
                </Popover> : null}
            <div className="values">
                {values}
            </div>
        </form>;
    }
}

interface IMultiTextInputRecommandationsListProps {
    recommandations: IMultiTextInputRecommandationsItem[];
    onChange: (arg: React.MouseEvent<any>, res: string) => void;
    i18nHelper: Entities.i18nHelper;
}

class MultiTextInputRecommandationsList extends React.Component<IMultiTextInputRecommandationsListProps, any> {
    render() {
        let items;
        if (this.props.recommandations && this.props.recommandations.length) {
            items = this.props.recommandations.map((recommandation, idx) => {
                const label = recommandation.trad ? this.props.i18nHelper.translate(recommandation.trad) : null;
                return <li onClick={(arg) => this.props.onChange(arg, recommandation.value)} key={idx}>
                    {label ? `${label} (${recommandation.value})` : recommandation.value}
                </li>;
            });
        }
        return <ul className="recommandations">{items}</ul>;
    }
}

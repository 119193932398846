import { Entities } from '@inwink/entities/entities';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { React, AppAsyncButton, IPopoverManager, DisplayDate } from '../../commons';
import { i18NHelperContext } from '../../services/i18nservice';
import { ItemWebsite, IItemWebsiteProps } from './itemwebsite';

import './eventitem.less';

interface IEventItemProps {
    popovermgr: IPopoverManager;
    className?: string;
    eventitem: any;
    customerid: string;
    onRemove?: (eventitem: any) => any;
    style?: any;
    disableClick?: boolean;
    onClick?: (eventitem: any) => void;
    onAfterClick?: (eventitem: any) => void;
    children?: ReactNode;
}

export class EventItem extends React.Component<IEventItemProps, any> {
    removeItem: (arg: React.MouseEvent<any>) => any;

    constructor(props) {
        super(props);
        if (props.onRemove) {
            this.removeItem = (arg) => {
                arg.preventDefault();
                arg.stopPropagation();
                return this.props.onRemove(this.props.eventitem);
            };
        }
    }

    eventClicked = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onClick(this.props.eventitem);
    };

    afterClick = () => {
        if (this.props.onAfterClick) {
            this.props.onAfterClick(this.props.eventitem);
        }
    };

    openPreview = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const props: IItemWebsiteProps = {
            item: this.props.eventitem,
            itemType: 'event'            
        };
        this.props.popovermgr.popoverPortal(arg.currentTarget, ItemWebsite, props, "itemwebsitepopover");
    };

    renderStats(i18n: Entities.i18nHelper) {
        if (this.props.eventitem.stat) {
            return <div className="eventstat">
                {this.props.eventitem.stat.invited
                    ? <span className="invited" title={i18n.translate("eventitem.invited")}>
                        <i className="inwink-logout" /> {this.props.eventitem.stat.invited}</span>
                    : null}
                {this.props.eventitem.stat.registered
                    ? <span className="registered" title={i18n.translate("eventitem.registered")}>
                        <i className="inwink-group" /> {this.props.eventitem.stat.registered}</span>
                    : null}
                {this.props.eventitem.stat.attended
                    ? <span className="attended" title={i18n.translate("eventitem.attended")}>
                        <i className="inwink-event" /> {this.props.eventitem.stat.attended}</span>
                    : null}
            </div>;
        }

        return null;
    }

    renderDates() {
        const event = this.props.eventitem;
        let startDate = event.startDate;
        if (!startDate) {
            startDate = (event.date ? event.date.start : null);
        }
        let endDate = event.endDate;
        if (!endDate) {
            endDate = (event.date ? event.date.end : null);
        }
        if (startDate && endDate) {
            if (moment(startDate).isSame(moment(endDate), 'day')) {
                return <div className="eventdate">
                    <DisplayDate format="L" date={startDate} />
                </div>;
            }

            return <div className="eventdate">
                <DisplayDate format="L" date={startDate} />
                <span className="sep accent-2">
                    <i className="inwink-arrow-right" />
                </span>
                <DisplayDate format="L" date={endDate} />
            </div>;
        }

        return null;
    }

    renderContent(i18n: Entities.i18nHelper) {
        const event = this.props.eventitem;
        const actionsItems = [];

        let actions = null;
        if (this.props.onRemove) {
            actionsItems.push(
                <AppAsyncButton key={actionsItems.length} onClick={this.removeItem}>
                    <i className="inwink-trash" />
                </AppAsyncButton>
            );
        }

        if (event.eventKey) {
            actionsItems.push(<AppAsyncButton
                key="openpreview"
                title="actions.openpreview"
                className="openpreview"
                onClick={this.openPreview}
            ><i className="inwink-web" /></AppAsyncButton>);
        }

        if (actionsItems.length) {
            actions = <div className="actions">{actionsItems}</div>;
        }

        return <>
            <img alt="inwink" className="icon" src={(inwink.config.assetsUrl || "/") + "assets/images/inwink_logo_people.svg"} />
            <div className="eventdetail">
                <div className="title">
                    <div className="eventname">
                        <div className="eventname-title">{event.title}</div>
                    </div>
                    {event?.authTenant?.name ? <div className="tenantname">
                        <div className="tenantname-title">{event.authTenant.name}</div>
                    </div> : null}
                    {this.renderDates()}
                </div>
                {this.renderStats(i18n)}
            </div>
            {this.props.children ? <div className='childs'>{this.props.children}</div> : null}
            {actions}
        </>;
    }

    render() {
        const event = this.props.eventitem;

        if (this.props.disableClick) {
            return <div className={"event-item clickable " + (this.props.className || "")} style={this.props.style}>
                <i18NHelperContext.Consumer>
                    {(i18n) => this.renderContent(i18n)}
                </i18NHelperContext.Consumer>
            </div>;
        }

        if (this.props.onClick) {
            return <div
                className={"event-item clickable " + (this.props.className || "")}
                style={this.props.style}
                onClick={this.eventClicked}
            >
                <i18NHelperContext.Consumer>
                    {(i18n) => this.renderContent(i18n)}
                </i18NHelperContext.Consumer>
            </div>;
        }

        return <Link
            to={"/" + event.customerId + "/e/" + event.id}
            className={"event-item " + (this.props.className || "")}
            style={this.props.style}
            onClick={this.afterClick}
        >
            <i18NHelperContext.Consumer>
                {(i18n) => this.renderContent(i18n)}
            </i18NHelperContext.Consumer>
        </Link>;
    }
}

/* eslint-disable max-classes-per-file */
import type { IPopoverDismissManager } from "@inwink/modals";
import type { Entities } from "@inwink/entities/entities";
import { React, AppTextLabel, IPopoverManager, i18NHelperContext } from '../../../../commons';
import { IModalAction, ModalContent } from '../../../ui/modals/modalcontent';
import './viewselector.confirmclonemodal.less';

interface IEntityViewSelectorConfirmCloneModalProps {
    popovermgr: IPopoverManager;
    popover?: IPopoverDismissManager;
    onCompleted?: (res: any) => void;
    i18nHelper?: Entities.i18nHelper;
}

interface IEntityViewSelectorConfirmCloneModalState {
    viewName: string;
}

export function EntityViewSelectorConfirmCloneModal(props: IEntityViewSelectorConfirmCloneModalProps) {
    return (
        <i18NHelperContext.Consumer>
            {(i18nHelper) => <EntityViewSelectorConfirmCloneModalComponent {...props} i18nHelper={i18nHelper} />}
        </i18NHelperContext.Consumer>
    );
}

class EntityViewSelectorConfirmCloneModalComponent
    extends React.Component<IEntityViewSelectorConfirmCloneModalProps, IEntityViewSelectorConfirmCloneModalState> {
    constructor(props: IEntityViewSelectorConfirmCloneModalProps) {
        super(props);

        this.state = {
            viewName: null
        };
    }

    render() {
        const { onCompleted, i18nHelper } = this.props;
        const { viewName } = this.state;

        const actions = [
            {
                i18n: "actions.cancel",
                direction: "left",
                callback: () => onCompleted(null)
            },
            {
                i18n: "actions.save",
                direction: "right",
                className: "bordered",
                disabled: !viewName,
                callback: () => onCompleted({ viewName })
            }
        ] as IModalAction[];

        return (
            <ModalContent onhide={onCompleted} title="entityviewselector.clone.modal.title" actions={actions}>
                <form className="entityform">
                    <div className="entityfield">
                        <label htmlFor="viewname">
                            <AppTextLabel i18n="entityviewselector.clone.label.viewname" />
                        </label>

                        <input
                            type="text"
                            id="viewname"
                            value={viewName}
                            max={40}
                            placeholder={i18nHelper.translate('entityviewselector.viewname.placeholder')}
                            onChange={(e) => { this.setState({ viewName: e.currentTarget.value }); }}
                        />
                    </div>
                </form>
            </ModalContent>
        );
    }
}

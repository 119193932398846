import * as React from 'react';
import { OnBoardingDefinitions } from './definition';
import { StepState} from './stepstate';
import { Entities } from '@inwink/entities/entities';

import "./onboarding.less";

export interface IOnBoardingBackgroundProps {
    i18nHelper: Entities.i18nHelper;
    config: OnBoardingDefinitions.OnBoardingConfiguration;
    currentStep: OnBoardingDefinitions.OnBoardingStep;
    stepstate: StepState;
}

export interface IOnBoardingBackgroundState {
}

export class OnBoardingBackground extends React.Component<IOnBoardingBackgroundProps, IOnBoardingBackgroundState> {
    unmounted: boolean;

    constructor(props) {
        super(props);
        this.state = {
            isStale : true,
            clips : null
        };
    }

    render() {
        let w = document.body.clientWidth;
        let h = document.body.clientHeight;

        let clips = [];
        if (this.props.stepstate && this.props.stepstate.elements && this.props.stepstate.elements.length) {
            this.props.stepstate.elements.forEach((elt, elIdx) => {
                if (elt.rects && elt.rects.length && elt.element.behavior === "clip") {
                    let offset = elt.element.offset || 0;
                    elt.rects.forEach((rect, eltrectIdx) => {
                        clips.push(<rect
                            key={"clip-" + elIdx + "-" + eltrectIdx } width={rect.width + 2 * offset }
                            height={rect.height + 2 * offset}
                            x={rect.left - offset}
                            y={rect.top - offset}
                            className="cliprect"
                            fill="black"/>);
                    });
                }

            });
        }

        return <div className="onboarding-background">
            <svg width="100%" height="100%" viewBox={"0 0 " + w + " " + h}>
                <defs>
                    <mask id="myMask" x="0" y="0" width={w} height={h}>
                        <rect width={w} height={h} fill="white" />
                        {clips}
                    </mask>

                </defs>
                <rect width={w} height={h} id="overlay" mask="url(#myMask)" />
            </svg>
        </div>;
    }
}

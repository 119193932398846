/* eslint-disable import/no-mutable-exports */
import type { IGetHelpersCallback } from './definitions';

import './tools/moreactions.less';

export let getHelpers: IGetHelpersCallback = () => null;

export function setGetHelpers(fn: IGetHelpersCallback) {
    getHelpers = fn;
}

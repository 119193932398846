import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import { BoolFieldEditor } from './booleditor';
import { PictureEditor } from './asseteditor';
import { DateNullableBoolEditor } from './datebooleditor';
import { FileVideoEditor } from './filevideoeditor';
import { GenericEntitiesEditor, GenericEntityEditor } from './genericentityfield';
import type { IEntityDetailConfiguration } from '../entities/entitydetailv3';
import { DrowdownMultipleFormEditor, SelectFormEditor } from './dropdownmultiple';

export function customEditors(
    fieldtemplate: Entities.IEntityFieldTemplate | Entities.IEntityFieldTemplateV3,
    formtemplate: Entities.IExtendedFieldsFormFieldTemplate,
    entityName: string
) {
    if (!fieldtemplate) {
        return;
    }

    const fieldtype = fieldtemplate.type.toLowerCase();

    if (fieldtype.toLowerCase() === "multiselectlist" && entityName && entityName.toLowerCase() !== "defaultvalues") {
        return DrowdownMultipleFormEditor;
    }
    if (fieldtype && fieldtype.toLowerCase() === "selectlist") {
        return SelectFormEditor;
    }
    if (fieldtype && fieldtype.toLowerCase() === "bool") {
        return BoolFieldEditor;
    }
    if (fieldtype && fieldtype.toLowerCase() === "picture") {
        return PictureEditor;
    }
    if (fieldtype && fieldtype.toLowerCase() === "nullablebool") {
        return DateNullableBoolEditor;
    }
    if (fieldtype && fieldtype.toLowerCase() === "filevideo") {
        return FileVideoEditor;
    }
}

export function customEntityEditors(
    entityDetailConf: IEntityDetailConfiguration,
    props: any,
    fieldtemplate: Entities.IEntityFieldTemplate | Entities.IEntityFieldTemplateV3,
    formtemplate: Entities.IExtendedFieldsFormFieldTemplate,
    entityName: string
) {
    const component = customEditors(fieldtemplate, formtemplate, entityName);
    let propsPatch = null;
    if (entityDetailConf
        && entityDetailConf.customFormComponentsArgs
        && entityDetailConf.customFormComponentsArgs[fieldtemplate.key]) {
        propsPatch = entityDetailConf.customFormComponentsArgs[fieldtemplate.key](props);
    }

    if (!component) {
        const fieldtype = fieldtemplate.type.toLowerCase();
        if (fieldtype === "entity" && fieldtemplate.value) {
            return function cmpt(_props) {
                return React.createElement(GenericEntityEditor, Object.assign({}, _props, propsPatch));
            };
        }
        if (fieldtype === "entities" && fieldtemplate.value) {
            return function cmpt(_props) {
                return React.createElement(GenericEntitiesEditor, Object.assign({}, _props, propsPatch));
            };
        }
    } else if (propsPatch) {
        return function cmpt(_props) {
            return React.createElement(component, Object.assign({}, _props, propsPatch));
        };
    }

    return component;
}

import { connect } from 'react-redux';
import { LoadingPanel } from '@inwink/loadable';
import { React, Loader, AppLabel } from '../../../../commons';
import type { States } from '../../../../services/services';
import type { IExpressionProps } from "../definitions";
import { EntityPage } from "../../entitypagev3/entitypagebase";
import type { IEntityPageConfiguration } from "../../entitypagev3/entitypagebase.props";
import { EntityPicker } from "../../entitypicker/index";
import './expressionentity.less';

interface IExpressionEntitiesProps extends IExpressionProps {
    event: States.IEventState;
    i18n: States.i18nState;
    user: States.IAppUserState;
    rights: States.IAppUserRights;
    idFields: string[];
}

interface IExpressionEntitiesComponentV3State {
    selection: any[];
    idFields: string[];
    notfound: boolean;
    loading: boolean;
}

class ExpressionEntitiesComponentV3 extends React.Component<IExpressionEntitiesProps, IExpressionEntitiesComponentV3State> {
    constructor(props: IExpressionEntitiesProps) {
        super(props);
        this.state = {
            selection: null,
            idFields: props.idFields || null,
            notfound: false,
            loading: true
        };
    }

    componentDidMount() {
        this.props.cmscontext.getEntityConfiguration(this.props.entityField.value, this.props as any).then((conf) => {
            const idFields = this.state.idFields || conf.idFields || ['id'];
            return this.getSelectionFromProps(conf, this.props.expressions, idFields).then((selection) => {
                this.setState({ idFields, selection: selection, loading: false });
            });
        }, () => {
            this.setState({ notfound: true });
            console.log("cannot get entityconfiguration");
        });
    }

    getSelectionFromProps = (entityConfiguration: IEntityPageConfiguration, expressions, idFields: string[]) => {
        let exprs = null;
        if (idFields.length === 1) {
            if (expressions && expressions.any && expressions.any.val && expressions.any.val.length) {
                exprs = {
                    or: expressions.any.val.map((id) => {
                        return {
                            name: idFields[0],
                            op: "eq",
                            val: id
                        };
                    })
                };
            }
        } else if (expressions && expressions.and && expressions.and.length) {
            exprs = {
                or: expressions.and.map((expression) => {
                    return {
                        and: idFields.forEach((id) => {
                            return {
                                name: id,
                                op: "eq",
                                val: expression.or.filter((e) => e.name.toLowerCase().endsWith(`.${id}`)[0].val)
                            };
                        })
                    };
                })
            };
        }

        if (exprs == null) {
            return Promise.resolve(null);
        }

        const expands = entityConfiguration && entityConfiguration.display && entityConfiguration.display.expands;
        return entityConfiguration.datasource.query({
            expression: exprs
        }, expands).then((res) => {
            return res.data;
        }, (err) => {
            console.error("error loading selection", err);
        });
    };

    selectionChanged = (selection: any[]) => {
        let expression;
        if (selection && selection.length && this.state.idFields) {
            if (this.state.idFields.length === 1) {
                expression = {
                    name: this.props.entityField.key,
                    any: { name: this.state.idFields[0], op: "in", val: selection.map((s) => s[this.state.idFields[0]]) } };
            } else {
                expression = {
                    and: selection.map((s) => {
                        return {
                            or: this.state.idFields.map((id) => {
                                return { name: `${this.props.entityField.key}.${id}`, op: 'eq', val: s[id] };
                            })
                        };
                    })
                };
            }
        }
        if (this.props.inline) {
            this.props.updateInline({ expression: expression });
        } else {
            this.props.setExpression(expression);
        }

        const allowSave = true;
        if (allowSave !== this.props.allowSave) { this.props.setAllowSave(allowSave); }
    };

    renderInline() {
        return <EntityPicker
            multiselect={true}
            entityName={this.props.entityField.value}
            entityArgs={null}
            selection={this.state.selection}
            readOnly={false}
            disableAddNew={true}
            onChange={(selectedIds, selected) => {
                this.selectionChanged(selected);
            }}
        />;
    }

    render() {
        if (this.state.notfound) {
            return <div className="filter-entitiesv3 notfound">
                <AppLabel i18nService={this.props.i18n} i18n="filter.nofilter" />
            </div>;
        }
        if (!this.state.idFields || this.state.loading) {
            return <div className="filter-entitiesv3"><Loader /></div>;
        }
        if (this.props.inline) {
            return this.renderInline();
        }
        return <div className="filter-entitiesv3">
            <LoadingPanel loader={Loader as any} noInheritance={true} stateDelay={300}>
                <EntityPage
                    user={this.props.user}
                    rights={this.props.rights}
                    entityName={this.props.entityField.value}
                    configurationArgs={null}
                    entityconfiguration={null}
                    configurationProvider={this.props.cmscontext.getEntityConfiguration}
                    className={null}
                    entityactions={null}
                    pagetitle={null}
                    multiselect={true}
                    initialSelection={this.state.selection}
                    selectionChanged={this.selectionChanged}
                    runMode="picker"
                    viewMode="list"
                    disableAddNew={true}
                />
            </LoadingPanel>
        </div>;
    }
}

function mapStateToPropsV3(state: States.IAppState) {
    return {
        event: state.event,
        i18n: state.i18n,
        user: state.user,
        rights: state.rights
    };
}

function mapDispatchToPropsV3() {
    return {

    };
}

export const ExpressionEntitiesV3: new (any) => React.Component<IExpressionEntitiesProps, any> = connect(
    mapStateToPropsV3,
    mapDispatchToPropsV3
)(ExpressionEntitiesComponentV3 as any) as any;

import { IDataSourceV3 } from '@@api/base/datasource';
import { Entities } from '@inwink/entities/entities';
import { Popover } from '@inwink/modals';
import * as debounce from 'lodash/debounce';
import { React, IPopoverManager, Loader, AppTextLabel, i18NHelperContext } from '../../../commons';
import { CommunityItem } from '../communityitem';
import { EventItem } from '../eventitem';

import "./contentquicksearch.less";

export interface IContentQuickSearchProps {
    placement?: string;
    onContentClicked?: (content: IContentQuickSearchElement) => void;
    popovermgr: IPopoverManager;
    eventDatasource: IDataSourceV3<Entities.IEntity>;
    communityDatasource: IDataSourceV3<Entities.IEntity>;
    i18nHelper?: Entities.i18nHelper;
}

interface IContentQuickSearchComponentState {
    search: string;
    elements?: IContentQuickSearchElement[];
    searching?: boolean;
    showResults?: boolean;
}

export interface IContentQuickSearchElement { type: 'event' | 'community', element: any }

export function ContentQuickSearch(props: IContentQuickSearchProps) {
    return <i18NHelperContext.Consumer>
        {(i18nhelper) => <ContentQuickSearchComponent {...props} i18nHelper={i18nhelper} />}
    </i18NHelperContext.Consumer>;
}

class ContentQuickSearchComponent
    extends React.Component<IContentQuickSearchProps, IContentQuickSearchComponentState> {
    container = React.createRef<HTMLDivElement>();

    popover = React.createRef<Popover>();

    currentSearch: any = null;

    constructor(props) {
        super(props);
        this.debouncedSearch = debounce(this.search.bind(this), 400);
        this.state = {
            search: ""
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debouncedSearch(txt: string) {

    }

    search(txt: string) {
        if (!txt) {
            this.setState({ elements: null });
            return;
        }

        this.setState({ searching: true, showResults: true });
        this.popover.current.refresh();
        const currentSearch = Promise.all([
            this.props.eventDatasource.query({
                selects: {
                    $all: true,
                    stat: {
                        $all: true
                    },
                    // authTenant: {
                    //     name: true
                    // }
                },
                filters: {
                    title: {$contains: txt}
                },
                order: [{ by: 'startDate', desc: true }],
                page: {
                    index: 0,
                    size: 10
                }
            }).then((res) => res, () => null),
            this.props.communityDatasource.query({
                selects: {
                    $all: true,
                    // authTenant: {
                    //     name: true
                    // }
                },
                filters: {
                    name: { $contains: txt }
                },
                order: [{ by: "name", desc: true }],
                page: {
                    index: 0,
                    size: 10
                }
            }).then((res) => res, () => null),
        ]).then((res) => {
            if (currentSearch === this.currentSearch) {
                const events = res[0]?.data;
                const communities = res[1]?.data;
                let elements: IContentQuickSearchElement[] = [];
                if (events) {
                    elements.push(...events.map((e) => {
                        return { type: 'event', element: e } as IContentQuickSearchElement;
                    }));
                }
                if (communities) {
                    elements.push(...communities?.map((e) => {
                        return { type: 'community', element: e } as IContentQuickSearchElement;
                    }));
                }
                elements = elements.filter((e) => (!!e));
                elements.sort((a, b) => {
                    const aSortValue: string = a.type === 'event'
                        ? (a.element as any).title
                        : (a.element as any).name;
                    const bSortValue: string = b.type === 'event'
                        ? (b.element as any).title
                        : (b.element as any).name;
                    return aSortValue.localeCompare(bSortValue);
                });
                this.setState({ searching: false, elements: elements.slice(0, 10) }, () => {
                    this.popover.current.refresh();
                });
            }
        }, (err) => {
            console.error(err);
            this.setState({ searching: false, elements: null });
        });
        this.currentSearch = currentSearch;
    }

    hideResult = () => {
        this.setState({ showResults: false });
    };

    searchChanged = (arg: React.FormEvent<HTMLInputElement>) => {
        this.setState({ search: arg.currentTarget.value });
        this.debouncedSearch(arg.currentTarget.value);
    };

    inputFocus = () => {
        if (this.state.elements && !this.state.showResults) {
            setTimeout(() => {
                this.setState({ showResults: true });
            }, 100);
        }
    };

    elementClicked = (item) => {
        this.setState({ showResults: false });
        if (this.props.onContentClicked) {
            this.props.onContentClicked(item);
        }
    };

    render() {
        let content = null;

        if (this.state.searching) {
            content = <Loader />;
        } else if (this.state.elements && this.state.elements.length) {
            content = <div>{this.state.elements.map((elmnt) => {
                return elmnt.type === 'event' ? <EventItem
                    key={elmnt.element.id}
                    customerid={elmnt.element.customerId}
                    popovermgr={this.props.popovermgr}
                    eventitem={elmnt.element}
                    onAfterClick={() => this.elementClicked(elmnt)}
                /> : <CommunityItem
                    key={elmnt.element.id}
                    customerid={elmnt.element.customerId}
                    popovermgr={this.props.popovermgr}
                    communityitem={elmnt.element}
                    onAfterClick={() => this.elementClicked(elmnt)}
                />;
            })}</div>;
        } else {
            content = <AppTextLabel i18n="contentquicksearch.noresult" className="noresult" />;
        }

        return <div className="contentquicksearch" ref={this.container}>
            <input
                type="search"
                placeholder={this.props.i18nHelper.translate("contentquicksearch.placeholder")}
                value={this.state.search}
                onChange={this.searchChanged}
                onFocus={this.inputFocus}
            />
            <i className="inwink-search" />
            <Popover
                ref={this.popover}
                className="contentquicksearchpopover"
                show={this.state.showResults}
                parent={this.container.current}
                onhide={this.hideResult}
                placement={this.props.placement}
                autofocus={false}
            >
                <section className="content">
                    {content}
                </section>
            </Popover>
        </div>;
    }
}

import * as moment from "moment";

export function licenceExpiration(customer): {
    isExpired: boolean,
    i18n: string,
    secondsLeft: number,
    inject: any } {
    const licenceExpired = customer?.detail
    && ((Object.prototype.hasOwnProperty.call(customer.detail, "licenseIsActive")
        && customer.detail.licenseIsActive === false)
    || (customer.detail?.licenseEndDate
        ? moment().isAfter(moment(customer?.detail?.licenseEndDate)) : false));
    const today = moment();
    const daysRemaining = customer?.detail?.licenseEndDate
        ? moment(customer?.detail?.licenseEndDate).diff(today, "seconds") : null;
    const fromNow = moment(customer?.detail?.licenseEndDate).fromNow();
    return {
        isExpired: licenceExpired,
        secondsLeft: daysRemaining,
        i18n: "customer.warning.licensewillexpire.morethanoneday",
        inject: {
            days: fromNow
        }
    };
}

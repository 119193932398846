import * as React from 'react';
import type { ICommunityDetail } from '@@data/communities/communitydata';
import type { Entities } from '@inwink/entities/entities';
import { AppTextLabel } from '../../commons';
import { openCommunityPreview } from '@@data/communities/openpreview';
import { openEventPreview } from '@@data/events/openpreview';

import './itemwebsite.less';

export interface IItemWebsiteProps {
    itemType: 'community' | 'event';
    item: Entities.IEventDetail | ICommunityDetail;
    onCompleted?: (res?) => void;
}
export class ItemWebsite extends React.Component<IItemWebsiteProps, any> {
    openPreview = (arg: React.MouseEvent<any>) => {
        const item = this.props.item;        
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onCompleted();
        if (this.props.itemType === 'community') {
            openCommunityPreview(item as any);
        } else if (this.props.itemType === "event") {
            openEventPreview(item as any);
        }
    };

    openPublished = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.onCompleted();
        window.open(this.props.item.computedUrl, "_blank");
    };

    render() {
        return <div className="itemwebsite">
            <button type="button" onClick={this.openPreview}><AppTextLabel i18n="eventitem.website.preview" /></button>
            {this.props.item.computedUrl
                ? <button type="button" onClick={this.openPublished}>
                    <AppTextLabel i18n="eventitem.website.prod" /></button> : null}
        </div>;
    }
}

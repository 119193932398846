import { Link } from 'react-router-dom';
import { withI18nHelper } from '@inwink/i18n';
import { AppTextLabel, React, getPreviewUrl } from '../../commons';
import { AppShellHelp } from './appshellhelp';
import { AppShellUser } from './appshelluser';
import { AppShellOnBoarding } from './appshellonboarding';
import type { IAppShellHeaderPartProps } from './appshellheader';
import { licenceExpiration } from "../../routes/customers/home/licencewarning";
import './appshellheader.side.less';

@withI18nHelper()
export class AppShellHeaderSide extends React.Component<IAppShellHeaderPartProps, any> {
    popoverparent = React.createRef<HTMLButtonElement>();

    modal: any;

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderContent(parent?: any) {
        let licenseWarning = null;
        if (this.props.customer?.detail) {
            const licenseInfo = licenceExpiration(this.props.customer);
            if (licenseInfo.secondsLeft !== null) {
                if (licenseInfo.secondsLeft <= 0) {
                    licenseWarning = <div
                        className="customer-warning"
                        title={this.props.i18nHelper.translate("customer.warning.licenseexpired")}
                    >
                        <i className="inwink-warning" />
                    </div>;
                } else if (licenseInfo.secondsLeft <= 5184000 && licenseInfo.secondsLeft > 0) {
                    licenseWarning = <div
                        className="customer-warning"
                        title={this.props.i18nHelper.translate(licenseInfo.i18n, {days: licenseInfo.inject.days})}
                    >
                        <i className="inwink-warning" />
                    </div>;
                }
            }
        }
        let onBoarding = null;
        if (inwink.config.env !== "prod") {
            onBoarding = <AppShellOnBoarding {...this.props} afterClick={this.hide} parent={parent} />;
        }
        const desktopRender = this.props.visualstate.indexOf("M") >= 0;
        let actions;
        if (desktopRender) {
            let tinyUrl;
            let previewUrl;
            let computedUrl;
            if (this.props.event?.eventdetail) {
                if (this.props.rights?.event?.rights?.bo?.websitecms?.access) {
                    tinyUrl = this.props.event.eventdetail.eventKey || this.props.event.eventdetail.tinyUrl
                        || this.props.event.detail?.tinyUrl;
                }
                computedUrl = this.props.event.eventdetail.computedUrl;
                previewUrl = getPreviewUrl(this.props.event.eventdetail);
            } else if (this.props.community?.detail) {
                // TODO Community rights
                tinyUrl = this.props.community.detail.communityKey;
                computedUrl = this.props.community.detail.computedUrl;
                previewUrl = getPreviewUrl(this.props.community.detail);
            }
            actions = <div className="websiteactions">
                {tinyUrl ? <Link
                    role="button"
                    to={`/preview/${tinyUrl}`}
                    target="_blank"
                ><AppTextLabel i18n="appheaderactions.opencms" /></Link>
                    : null}
                {previewUrl
                    ? <a role="button" href={previewUrl} target="_blank" rel="noreferrer">
                        <AppTextLabel i18n="appheaderactions.openpreview" /></a>
                    : null}
                {computedUrl
                    ? <a role="button" href={computedUrl} target="_blank" rel="noreferrer">
                        <AppTextLabel i18n="appheaderactions.openwebsitepublished" /></a>
                    : null}
            </div>;
        }
        return <div className="app-header-side">
            {licenseWarning}
            {actions}
            <AppShellHelp
                {...this.props}
                afterClick={this.hide}
                parent={parent}
                display={desktopRender ? 'simple' : 'advanced'}
            />
            {onBoarding}
            <AppShellUser {...this.props} afterClick={this.hide} parent={parent} />
        </div>;
    }

    hide = () => {
        if (this.modal) {
            this.modal.close();
            this.modal = null;
        }
    };

    showMore = (arg: React.MouseEvent<any>) => {
        arg.stopPropagation();
        arg.preventDefault();
        const elt = arg.currentTarget;
        this.modal = this.props.popovermgr.popoverPortal(elt, () => this.renderContent(elt),
            this.props, "app-header-side-popover", {
                closeOnResize: true
            });

        this.modal.then(() => {
            this.modal = null;
        });
    };

    render() {
        if (this.props.visualstate.indexOf("M") >= 0) {
            return this.renderContent();
        }

        return <div className="app-header-side">
            <button type="button" className="showmore" ref={this.popoverparent} onClick={this.showMore}>
                <i className="inwink-more-v" /></button>
        </div>;
    }
}

import { defaultPostHeaderForJsonData } from '@inwink/apibase';
import { getBackOfficeProxy } from './index';

export function getUserSettings(lastupdate?) {
    let url = "me/preferencekeys";
    if (lastupdate) {
        url = url + "?from=" + lastupdate;
    }
    return getBackOfficeProxy().getJson(url);
}

export function setUserSettings(settingkey: string, setting: string) {
    const url = "me/preferencekeys";
    return getBackOfficeProxy().postJson(url, JSON.stringify({
        preferenceKey: settingkey,
        preference: setting
    }), defaultPostHeaderForJsonData);
}

import { io, Socket } from 'socket.io-client';
import { Store } from 'redux';
import { oidcmgr } from '@inwink/apibase/auth/oidc-manager';
import { logging } from '@inwink/logging';
import { LogLevels } from '@inwink/logging/logging/basetypes';
import { notificationsActions } from './notificationservice';
import { States } from './services';

const logger = logging.getLogger("realtime");
logger.level = LogLevels.debug;

let userio: Socket;
let _realtimeHost: string;

export function realtimeHost() {
    return _realtimeHost;
}

export function initRealtimeService(host: string, store: Store<States.IAppState>) {
    _realtimeHost = host;
    if (host) {
        logger.info("starting realtime on " + host);
        // userio = ioclient("http://localhost:3000", { transports : ['websocket', 'polling'], reconnectionDelayMax  : 60000 });
        userio = io(host, { transports: ['websocket'], reconnectionDelayMax: 60000 });
        (userio as any).nsp = "/backoffice";

        userio.on("eventnotification", (event) => {
            try {
                logger.debug("event notification ", event);
                sendEvent("inwink.eventnotif." + event.eventId, event);
            } catch (exception) {
                logger.error("error processing live session state", exception);
            }
        });

        userio.on("eventmessagesnotification", (state) => {
            try {
                logger.debug("[Realtime] eventmessages notification", state);
                sendEvent("inwink.eventmessages." + state.eventId, state);
            } catch (exception) {
                logger.error("error processing eventmessages state", exception);
            }
        });

        userio.on("eventsessionmessagesnotification", (state) => {
            try {
                logger.debug("[Realtime] sessionmessages notification", state);
                sendEvent("inwink.sessionmessages." + state.eventId + "." + state.sessionId, state);
            } catch (exception) {
                logger.error("error processing sessionmessages state", exception);
            }
        });

        userio.on("eventdiscussionthreadmessagesnotification", (state) => {
            try {
                logger.debug("[Realtime] discussionthreadmessages notification", state);
                sendEvent("inwink.discussionthreadmessages." + state.eventId, state);
            } catch (exception) {
                logger.error("error processing sessionmessages state", exception);
            }
        });

        userio.on("usernotification", (event) => {
            logger.debug("user notification ", event);

            if (event && event.detail && event.detail.noDelay) {
                notificationsActions.checkUnread()(store.dispatch, store.getState);
                const relatedId = event.detail.eventId || event.detail.communityId
                    || event.detail.authTenantId || event.detail.customerId;
                if (event.detail.relatedToKind === "MassDelete" && event.detail.operation === "end") {
                    const raw = JSON.parse(event.detail.rawData);
                    sendEvent(`inwink.massDelete.${relatedId}.${raw.entityName.toLowerCase()}`, {count: raw.Count});
                } else if (event.detail.relatedToKind === "MassUpdate" && event.detail.operation === "end") {
                    const raw = JSON.parse(event.detail.rawData);
                    sendEvent(`inwink.massUpdate.${relatedId}.${raw.entityName.toLowerCase()}`, {count: raw.Count});
                }
            } else {
                notificationsActions.debouncedCheckUnread();
            }
            // if (state.event && state.event.eventid){ }
        });

        const connected = () => {
            const state = store.getState();
            if (state.event && state.event.eventid) {
                realtimeActions.registerEvent()(store.dispatch, store.getState);
            }

            if (state.user && state.user.currentUser) {
                realtimeActions.registerUser()(store.dispatch, store.getState);
            }
        };

        userio.on("connect", (event) => {
            logger.debug("connect socket", event);
            connected();
        });

        userio.on("reconnect", (event) => {
            logger.debug("reconnect socket", event);
            connected();
        });

        userio.on("connect_error", (event) => {
            logger.error("connect error", event);
        });

        userio.on("reconnect_error", (event) => {
            logger.error("reconnect error", event);
        });
    } else {
        logger.debug("realtime host is not defined");
    }
}

function sendEvent(eventname, data) {
    try {
        if (typeof (window as any).CustomEvent === "function") {
            const domevent = new CustomEvent(eventname, { detail: data, bubbles: true, cancelable: true });
            document.body.dispatchEvent(domevent);
        } else if (document.createEvent) {
            const evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(eventname, true, true, data);
        }
    } catch (ex) {
        logger.info("cannot dispatch event " + eventname);
    }
}

export const realtimeActions = {
    registerEvent() {
        return (dispatch, getState: () => States.IAppState) => realtimeActions.registerUser()(dispatch, getState);
    },

    registerUser() {
        return (dispatch, getState: () => States.IAppState) => {
            if (userio) {
                const state = getState();
                logger.debug("login for user realtime");

                if ((oidcmgr as any)._impl) {
                    (oidcmgr as any)._impl.getToken().then((token) => {
                        userio.emit("login", {
                            idtoken: token,
                            communityid: state.community && state.community.communityid,
                            eventid: state.event && state.event.eventid,
                            userid: state.user && state.user.currentUser && state.user.currentUser.id
                        });
                    }, (err) => {
                        console.error("error getting access token", err);
                        userio.emit("login", {
                            communityid: state.community && state.community.communityid,
                            eventid: state.event && state.event.eventid,
                            userid: state.user && state.user.currentUser && state.user.currentUser.id
                        });
                    });
                } else {
                    console.error("error init realtime");
                    userio.emit("login", {
                        communityid: state.community && state.community.communityid,
                        eventid: state.event && state.event.eventid,
                        userid: state.user && state.user.currentUser && state.user.currentUser.id
                    });
                }
            }
        };
    }
};

import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { VideoFileFieldEditor } from '@inwink/entityform/typededitors/videofilefieldeditor';
import { languages } from '@inwink/i18n/languages';
import { LoadingState } from '@inwink/loadable';
import type { IEventCMSContext } from '../../data/events/eventcmscontext';
import { BoolFieldEditor } from './booleditor';
import { DrowdownMultipleFormEditor } from './dropdownmultiple';
import { confirmModal } from '../ui/modals/confirmmodal';
import { IPopoverManager, PopoverManager, toastError, toastSuccess } from '../../commons';
import { editJsonModal } from '../jsoneditormodal';

import './filevideoeditor.less';

interface IFileVideoEditorState {
    transcriptLangs: string[];
    transcriptsEnabled: boolean;
    loading: boolean;
}

export class FileVideoEditor extends React.Component<IDynamicFieldEditorProps, IFileVideoEditorState> {
    popovermgr: IPopoverManager;

    constructor(props) {
        super(props);
        const file: Entities.IEntityFieldVideoContent = this.props.entityFieldValue;
        let transcriptsEnabled;
        let transcriptLangs;
        if (file && file.jobsOutput && file.jobsOutput.public) {
            transcriptsEnabled = file && file.jobsOutput && file.jobsOutput.public && file.jobsOutput.public.transcriptsEnabled;
            transcriptLangs = file && file.jobsOutput && file.jobsOutput.public && file.jobsOutput.public.transcriptLangs;
        }
        this.state = {
            transcriptLangs: transcriptLangs || [],
            transcriptsEnabled: transcriptsEnabled || false,
            loading: false
        };
    }

    render() {
        let optionsContent;
        const file: Entities.IEntityFieldVideoContent = this.props.entityFieldValue;
        if (file && file.jobsOutput) {
            const metadata = file.jobsOutput;
            const isReady = metadata.public && metadata.public.isReady === true;
            if (isReady) {
                const cmsContext: IEventCMSContext = this.props.datacontext.cmscontext;

                let languageSelector;
                if (this.state.transcriptsEnabled) {
                    const list = languages;
                    const currentLg = this.props.datacontext.i18n.currentLanguageCode;
                    list.sort((a, b) => {
                        if (a.labels[currentLg] < b.labels[currentLg]) {
                            return -1;
                        }
                        if (a.labels[currentLg] > b.labels[currentLg]) {
                            return 1;
                        }
                        return 0;
                    });

                    languageSelector = <DrowdownMultipleFormEditor
                        i18nHelper={this.props.i18nHelper}
                        onChange={(key, val: string[]) => {
                            const action = () => {
                                this.setState({ loading: true }, () => {
                                    this.props.fileinputmanager.setFileVideoTranscriptLangs(this.props.entityName,
                                        this.props.entityFieldFormTemplate.key, this.props.entityValue.id, val)
                                        .then(() => {
                                            this.setState({ transcriptLangs: val || [], loading: false });
                                        }, () => {
                                            this.setState({ loading: false });
                                        });
                                });
                            };

                            if (this.state.transcriptLangs.length) {
                                const langRemoved = this.state.transcriptLangs.filter((l) => !val.includes(l));
                                if (langRemoved.length > 0) {
                                    confirmModal(this.popovermgr, "videofilefieldeditor.subtitles.removetranscriptlangs.confirm",
                                        "videofilefieldeditor.subtitles.removetranscriptlangs.confirm.message").then((res) => {
                                        if (res) {
                                            action();
                                        }
                                    });
                                } else {
                                    action();
                                }
                            } else {
                                action();
                            }
                        }}
                        eventid={cmsContext.relatedTo}
                        entityName="videofilefieldeditor.subtitles"
                        forceReadOnly={this.props.forceReadOnly}
                        displayLanguage={this.props.datacontext.i18n.currentLanguageCode}
                        languages={cmsContext.eventdetail
                            && cmsContext.eventdetail.configuration
                            && cmsContext.eventdetail.configuration.global
                            && cmsContext.eventdetail.configuration.global.supportedLanguages}
                        isValid={true}
                        onComputing={null}
                        entityFieldValue={this.state.transcriptLangs}
                        onUpdateItem={(item: any) => {
                            return new Promise<any>((resolve, reject) => {
                                return cmsContext.fileInputManager.getFileAccess(this.props.entityName,
                                    this.props.entityFieldFormTemplate.key, this.props.entityValue.id,
                                    this.props.datacontext.i18n.currentLanguageCode).then((res: any) => {
                                    if (res.transcripts) {
                                        const transcriptFile = res.transcripts[item.key];
                                        if (transcriptFile) {
                                            return fetch(transcriptFile.url + transcriptFile.sas).then((response) => {
                                                return response.text().then((transcriptFileContent) => {
                                                    return editJsonModal(this.popovermgr, transcriptFileContent,
                                                        null, "plaintext", null, "transcriptmodal",
                                                        "videofilefieldeditor.subtitles.editmodal")
                                                        .then((newTranscriptFileContent: string) => {
                                                            if (newTranscriptFileContent) {
                                                                return this.props.fileinputmanager
                                                                    .setFileVideoTranscriptLangContent(this.props.entityName,
                                                                        this.props.entityFieldFormTemplate.key,
                                                                        this.props.entityValue.id, item.key,
                                                                        newTranscriptFileContent).then(() => {
                                                                        toastSuccess(this.props.i18nHelper.i18n, "data.saved");
                                                                        resolve("transcript saved");
                                                                    }, (err) => {
                                                                        toastError(this.props.i18nHelper.i18n, "data.notsaved");
                                                                        reject(err);
                                                                    });
                                                            }

                                                            resolve("transcript unchanged");
                                                        });
                                                }, (err) => reject(err));
                                            }, (err) => reject(err));
                                        }
                                    }

                                    reject("cannot find transcript file");
                                }, (err) => reject(err));
                            });
                        }}
                        entityFieldFormTemplate={{
                            key: "langs", valuesList: list
                        } as Entities.IExtendedFieldsFormFieldTemplate}
                    />;
                }

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                optionsContent = <div className="video-options">
                    <LoadingState isLoading={this.state.loading}>
                        <PopoverManager ref={(mgr) => this.popovermgr = mgr && mgr.ctx} />
                        <div className="entityfield type-checkbox">
                            <BoolFieldEditor
                                {...this.props}
                                entityName="videofilefieldeditor.subtitles"
                                entityFieldValue={this.state.transcriptsEnabled}
                                entityFieldFormTemplate={{
                                    key: "enabled",
                                    type: "bool"
                                }}
                                onChange={(key, value) => {
                                    this.setState({ loading: true }, () => {
                                        this.props.fileinputmanager.enabledFileVideoTranscript(this.props.entityName,
                                            this.props.entityFieldFormTemplate.key, this.props.entityValue.id, value)
                                            .then(() => {
                                                this.setState({ transcriptsEnabled: value, loading: false });
                                            }, () => {
                                                this.setState({ loading: false });
                                            });
                                    });
                                }}
                            />
                        </div>
                        {languageSelector}
                    </LoadingState>
                </div>;
            }
        }
        return <div className="fileinput-filevideoeditor">
            <VideoFileFieldEditor {...this.props} />
        </div>;
    }
}

import * as React from 'react';
import { OnBoardingDefinitions } from './definition';
import { OnBoardingBackground } from './background';
import { OnBoardingStep } from './step';
import { StepState} from './stepstate';
import { Entities } from '@inwink/entities/entities';
import "./onboarding.less";

export interface IOnBoardingContentProps {
    i18nHelper: Entities.i18nHelper;
    config: OnBoardingDefinitions.OnBoardingConfiguration;
    onDismiss: () => void;
}

export interface IOnBoardingContentState {
    loading: boolean;
    previousStepNumber: number;
    currentStepNumber: number;
    currentStepState: StepState;
    currentStep: OnBoardingDefinitions.OnBoardingStep;
}

export class OnBoardingContent extends React.Component<IOnBoardingContentProps, IOnBoardingContentState> {
    constructor(props: IOnBoardingContentProps) {
        super(props);

        this.state = {
            loading: false,
            currentStep: null,
            currentStepNumber : -1,
            previousStepNumber: -1,
            currentStepState : null
        };
    }

    componentDidMount() {
        this.setStep(1);
    }

    stepstatechanged = (state: StepState) => {
        if (state.failed) {
            if (this.state.previousStepNumber <= this.state.currentStepNumber) {
                this.next();
            }else{
                this.prev();
            }
        } else {
            this.setState({});
        }
    }

    loadStep(stepNumber: number, increment: number): Promise<{ stepNumber: number, step: OnBoardingDefinitions.OnBoardingStep, stepState: StepState}> {
        console.log("loading step " + stepNumber);
        return new Promise((resolve, reject) => {
            let current = this.props.config.steps[stepNumber];
            let currentState = new StepState(current, increment);
            currentState.init().then(() => {
                console.log("step " + stepNumber + " loaded");
                currentState.next = this.next;
                currentState.previous = this.prev;

                resolve({
                    stepNumber: stepNumber,
                    step: current,
                    stepState : currentState
                });
            }, (err) => {
                console.warn("loading step "  + stepNumber + " failed", err);
                let newStepNumber = stepNumber + increment;
                if (newStepNumber < 0 || newStepNumber >= this.props.config.steps.length) {
                    resolve(null);
                    return;
                }

                return this.loadStep(newStepNumber, increment).then(resolve, reject);
            });
        });
    }

    setStep(increment: number) {
        if (this.state.loading) {
            return;
        }

        this.setState((prevstate) => {
            let stepNumber = prevstate.currentStepNumber + increment;
            if (stepNumber < 0) {
                stepNumber = 0;
            }

            if (stepNumber >= this.props.config.steps.length) {
                this.dismiss();
                return;
            }

            this.loadStep(stepNumber, increment).then((res) => {
                if (res) {
                    res.stepState.onchange = this.stepstatechanged;
                    if (this.state.currentStepState){
                        this.state.currentStepState.dispose();
                    }

                    if (res.step.skipAfter) {
                        this.setState({
                            loading: false,
                            currentStepNumber: res.stepNumber
                        }, () => {
                            this.setStep(increment);
                        });
                    } else {
                        this.setState({
                            loading: false,
                            currentStepNumber: res.stepNumber,
                            currentStep: res.step,
                            currentStepState: res.stepState
                        });
                    }

                    return;
                }

                this.dismiss();
            });
            return { loading: true };
        });

        // console.log("set step to " + step + "(" + this.state.currentStepNumber + ")");
        // if (step !== this.state.currentStepNumber) {
        //     this.setState((prevstate) => {
        //         if (prevstate.currentStepState) {
        //             prevstate.currentStepState.dispose();
        //         }
        //         let current = this.props.config.steps[step];
        //         let currentState = new StepState(current, this.stepstatechanged);
        //         return {
        //             currentStepNumber : step,
        //             currentStep : current,
        //             previousStepNumber : prevstate.currentStepNumber,
        //             currentStepState: currentState
        //         };
        //     }, () => {
        //         //debugger;
        //         this.state.currentStepState.init();
        //     });
        // }
    }

    componentWillUnmount() {
        if (this.state.currentStepState) {
            this.state.currentStepState.dispose();
        }
    }

    next = () => {
        this.setStep(1);
    }

    prev = () => {
        this.setStep(-1);
    }

    dismiss = () => {
        this.props.onDismiss();
    }

    render() {
        return <div className="onboarding-container">
            <OnBoardingBackground {...this.props}
                stepstate={this.state.currentStepState}
                currentStep={this.state.currentStep} />
            <OnBoardingStep {...this.props}
                loading={this.state.loading}
                stepstate={this.state.currentStepState}
                currentStep={this.state.currentStep}
                currentStepNumber={this.state.currentStepNumber}
                onDismiss={this.dismiss}
                onPrev={this.prev}
                onNext={this.next} />
        </div>;
    }
}

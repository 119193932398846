import { React, AppTextLabel } from '../../commons';
import type { States } from '../../services/services';

import './card.less';

export interface ICardProps {
    noPadding?: boolean;
    dark?: boolean;
    accent?: boolean;
    accent2?: boolean;
    className?: string;
    style?: any;
    onClick?: (arg: React.MouseEvent<any>) => void;
}

// eslint-disable-next-line react/prefer-stateless-function
export class Card extends React.Component<ICardProps, any> {
    render() {
        const classes = ["card"];
        if (this.props.noPadding) {
            classes.push("nopadding");
        }
        if (this.props.dark) {
            classes.push("dark");
        }
        if (this.props.accent) {
            classes.push("accent");
        }
        if (this.props.accent2) {
            classes.push("accent2");
        }
        if (this.props.onClick) {
            classes.push('clickable');
        }

        if (this.props.className) classes.push(this.props.className);

        return <div className={classes.join(" ")} onClick={this.props.onClick} style={this.props.style}>
            {this.props.children}
        </div>;
    }
}

export interface ICardTitleProps {
    i18n: string;
    user: States.IAppUserState;
    className?: string;
    inject?: any;
}

export function CardTitle(props: ICardTitleProps) {
    let tooltip;
    return <h2 className={"card-title "
        + (props.className ? props.className + " " : "")}
    >
        <AppTextLabel className="text" i18n={props.i18n} inject={props.inject} />
        {tooltip}
    </h2>;
}

import { OnBoardingDefinitions } from './definition';

export class StepState {
    loading: boolean;
    failed: boolean;
    elements: StepStateElement[];
    defaultElement: StepStateElement;
    disposed: boolean = false;
    onchange: (state: StepState) => void;
    next: () => void;
    previous: () => void;

    constructor(public step: OnBoardingDefinitions.OnBoardingStep, increment: number) {
        this.loading = true;

        let elements = step && step.elements;
        if (increment < 0 && step.reverse){
            elements = step.reverse;
        }
        
        if (elements && elements.length) {
            this.elements = elements.map((el) => {
                let res = new StepStateElement(this, el);
                if (el.isDefault) {
                    this.defaultElement = res;
                }
                return res;
            });

            if (!this.defaultElement) {
                this.defaultElement = this.elements[0];
            }
        } else {
            this.loading = false;
        }
    }

    init() {
        this.loading = true;
        let start: Promise<any> = Promise.resolve();
        
        if (this.elements && this.elements.length) {
            return this.elements.reduce((promise, el, idx) => {
                return promise.then(() => {
                    return el.init();
                });
            }, start);
        }

        return start;
    }

    elementUpdate(elt: StepStateElement) {
        if (!this.disposed) {
            this.loading = this.elements && this.elements.some((e) => e.loading);
            if (this.onchange) {
                this.onchange(this);
            }
        }
    }

    fail() {
        this.failed = true;
        if (this.onchange) {
            this.onchange(this);
        }
        this.dispose();
    }

    dispose() {
        this.disposed = true;
        if (this.elements) {
            this.elements.forEach((el) => el.dispose());
        }
        this.onchange = null;
    }
}

export interface IElementRect {
    left: number;
    top: number;
    width: number;
    height: number;
}

export class StepStateElement {
    rects: IElementRect[];
    refreshTimeout: any;
    loading: boolean = true;
    disposed: boolean = false;

    constructor(public stepstate: StepState, public element: OnBoardingDefinitions.OnBoardingStepElement) {
        //this.checkPosition();
    }

    init() {
        return Promise.resolve().then(() => {
            if (this.element.waitBefore) {
                return new Promise((resolve) => setTimeout(resolve, this.element.waitBefore));
            }
        }).then(() => {
            return this.checkPosition();
        }).then(() => {
            if (this.element.waitfor) {
                let waitfor = this.element.waitfor;
                return this.getElement(waitfor.selector, waitfor.count, waitfor.delay);
            }
        }).then(() => {
            if (this.element.waitAfter) {
                return new Promise((resolve) => setTimeout(resolve, this.element.waitAfter))
            }
        });
    }

    getElement(selector: string, retryCount?: number, retryDelay?: number): Promise<NodeListOf<Element>> {
        let retries = 0;
        return new Promise((resolve, reject) => {
            let checkGetElement = () => {
                let items = document.querySelectorAll(this.element.selector);
                if (items && items.length) {
                    console.log("items found", items);
                    resolve(items);
                } else {
                    if (!retryCount || retries > retryCount) {
                        console.warn("element not found for " + selector);
                        reject({ message: "element not found for " + selector });
                        return;
                    }

                    retries++;
                    this.refreshTimeout = setTimeout(checkGetElement, retryDelay || 100);
                }
            };

            checkGetElement();
        });
    }

    getElementsRects(items: NodeListOf<Element>): Promise<IElementRect[]> {
        let rectsStamp = null;
        return new Promise((resolve, reject) => {
            let calcPosition = () => {
                let rects: IElementRect[] = [];
                for (let i = 0; i < items.length; i++) {
                    let target = items[i] as HTMLElement;
                    let rect = target.getBoundingClientRect();
                    rects.push({
                        left: rect.left,
                        top: rect.top,
                        width: rect.width,
                        height: rect.height
                    });
                }

                let stamp = JSON.stringify(this.rects);
                if (stamp !== rectsStamp) {
                    rectsStamp = stamp;

                    if (!this.disposed) {
                        //La position calculée est différente, on relance un calcul pour être sûr que la position soit stabilisée
                        this.refreshTimeout = setTimeout(calcPosition, 50);
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(rects);
                }
            };

            calcPosition();
        });
    }

    checkPosition() {
        if (this.disposed) {
            this.loading = false;
            return Promise.resolve();
        }

        if (this.element.selector) {
            return this.getElement(this.element.selector, this.element.retry && this.element.retry.count, this.element.retry && this.element.retry.delay).then((items) => {
                if (items && items.length) {
                    if (this.element.behavior === "clip" || this.element.behavior === "outline") {
                        return this.getElementsRects(items).then((rects) => {
                            this.rects = rects;
                            this.loading = false;
                            this.stepstate.elementUpdate(this);
                        });
                    } else if (this.element.behavior === "click") {
                        items.forEach((e) => {
                            console.log("clicking item", e);
                            (e as HTMLElement).click();
                        });
                    }
                }
            });
        }

        this.loading = false;
        return Promise.resolve();
    }

    dispose() {
        this.disposed = true;
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }
    }
}

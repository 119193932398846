/* eslint-disable max-classes-per-file */
import type { Entities } from '@inwink/entities/entities';
import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { DynamicFieldWrapper } from '@inwink/entityform/dynamicfieldwrapper';
import { convertFromOldFormat } from '@inwink/expressions/converter';
import { AppLabel, connectwith, IPopoverManager, Loader, PopoverManager, React } from '../../commons';
import type { ICMSContext } from "../cmscontext";
import type { IEntityGroupDefinition, IColumn } from "../entities/definitions";
import { ExpressionDesigner } from "../entities/expressiondesignerv3/expressiondesigner";
import { ExpressionDesignerItemsList } from "../entities/expressiondesignerv3/expressiondesigner.list";
import { addEntityNameToExpression, removeEntityNameFromExpression } from "../designers/expressiondesigner/utils";
import type { IExpressionDesignerOptions } from "../entities/expressiondesignerv3/definitions";
import { ModalContent } from "../ui/modals/modalcontent";
import type { IEntityPageConfiguration } from "../entities/entitypagev3/entitypagebase.props";
import type { States } from '../../services/services';
import type { IDataSourceV3 } from '@@api/base/datasource';

import '../designers/expressiondesigner/expressiondesigner.less';
import './expressioneditor.less';

export interface IExpressionEditorProps extends IDynamicFieldEditorProps {
    event: States.IEventState;
    i18n: States.i18nState;
    targetEntityName: string;
    targetEntityParent: string;
    options?: IExpressionDesignerOptions; // IExpressionDesignerOptions;
    wrapExpressionWithAnd?: boolean;
    isMailTrigger?: boolean;
    displayGroups?: boolean;
    columns?: IColumn[];
    toV3?: boolean;
    showExpressionPreview?: boolean;
    entityconfiguration: IEntityPageConfiguration;
    definitions?: Promise<IEntityGroupDefinition[]>; // this will override most of the other props ! be careful :)
}

@connectwith((state: States.IAppState) => ({ event: state.event, i18n: state.i18n }))
export class ExpressionEditor extends React.Component<IExpressionEditorProps, any> {
    popovermgr: IPopoverManager;

    constructor(props: IExpressionEditorProps) {
        super(props);
        this.state = {
            expressions: null,
            definitions: [],
            isLoading: true,
            entityconfiguration: this.props.entityconfiguration
        };
    }

    getExpressions(fieldValue) {
        let expressions = [];
        if (fieldValue) {
            let value = fieldValue;
            if (typeof fieldValue === 'string') {
                value = JSON.parse(fieldValue);
            }
            if (value && value.and) {
                expressions = value.and;
            } else if (value && value.v2 && value.v2.and) {
                expressions = value.v2.and;
            }
            if (expressions && expressions.length) {
                expressions = this.props.options?.disablePatchEntityNameOnExpression
                    ? expressions : addEntityNameToExpression(this.props.targetEntityName, expressions);
            }
        }
        return expressions;
    }

    componentDidMount() {
        const expressions = this.getExpressions(this.props.entityFieldValue);
        if (this.props.targetEntityName) {
            this.getDefinition(this.props.targetEntityName)
                .then((definitions) => {
                    this.setState({ expressions, definitions, isLoading: false });
                })
                .catch(() => {
                    this.setState({ expressions, isLoading: false });
                });
        } else {
            this.setState({ expressions, isLoading: false });
        }
    }

    componentDidUpdate(prevProps: IExpressionEditorProps) {
        if (prevProps && prevProps.entityFieldValue !== this.props.entityFieldValue) {
            const expressions = this.getExpressions(this.props.entityFieldValue);
            this.setState({ expressions });
        }

        if (prevProps.targetEntityName !== this.props.targetEntityName
            || prevProps.targetEntityParent !== this.props.targetEntityParent) {
            this.setState({ isLoading: true, entityconfiguration: null }, () => {
                this.props.datacontext.cmscontext.getEntityConfiguration(this.props.targetEntityName, this.props.datacontext)
                    .then((entityconfiguration) => {
                        // ça semble logique de reset state.expressions
                        this.props.onChange(this.props.entityFieldFormTemplate.key, null);
                        this.setState({
                            entityconfiguration,
                            expressions: null,
                            definitions: []
                        }, () => {
                            this.getDefinition(this.props.targetEntityName).then((definitions) => {
                                this.setState({ definitions, isLoading: false });
                            });
                        });
                    });
            });
        }
    }

    getDefinition(targetEntityName: string) {
        let definitions;
        // if (this.props.definitions) {
        //     return this.props.definitions.then((_definitions ) => {
        //         return _definitions;
        //     });
        // }

        if (this.state.entityconfiguration && this.state.entityconfiguration.datasource) {
            return this.state.entityconfiguration.datasource.worktemplate().then((res) => {
                if (res) {
                    if (this.props.definitions) {
                        return this.props.definitions.then((_definitions) => {
                            if (_definitions) {
                                const fields = res.fields;
                                for (const d of _definitions) {
                                    if (d.template.fields) {
                                        for (const _f in d.template.fields) {
                                            if (d.template.fields[_f]) {
                                                fields[_f] = d.template.fields[_f];
                                            }
                                        }
                                    }
                                }
                                definitions = [{
                                    key: targetEntityName,
                                    title: targetEntityName,
                                    template: {
                                        fields
                                    }
                                }];

                                return definitions;
                            }
                        });
                    }
                    definitions = [{
                        key: targetEntityName,
                        title: targetEntityName,
                        template: res
                    }];

                    return definitions;
                }
            }, () => {
                return null;
            });
        }
        // pour moi ce code ne sert a rien mais a priori il sert quand même, je verrai ça plus tard
        return this.props.datacontext.cmscontext.getEntityConfiguration(targetEntityName, this.props.datacontext)
            .then((entityconfiguration) => {
                if (entityconfiguration) {
                    this.setState({ entityconfiguration});
                    return (entityconfiguration.datasource as IDataSourceV3<any>)
                        .worktemplate(null, this.props.options?.disableExpandWorkTemplates).then((res) => {
                            if (res) {
                                if (this.props.definitions) {
                                    return this.props.definitions.then((_definitions) => {
                                        if (_definitions) {
                                            const fields = res.fields;
                                            for (const d of _definitions) {
                                                if (d.template.fields) {
                                                    for (const _f in d.template.fields) {
                                                        if (d.template.fields[_f]) {
                                                            fields[_f] = d.template.fields[_f];
                                                        }
                                                    }
                                                }
                                            }
                                            definitions = [{
                                                key: targetEntityName,
                                                title: targetEntityName,
                                                template: {
                                                    fields
                                                }
                                            }];

                                            return definitions;
                                        }
                                    });
                                }
                                definitions = [{
                                    key: targetEntityName,
                                    title: targetEntityName,
                                    template: res
                                }];

                                return definitions;
                            }
                        }, () => {
                            return null;
                        });
                }
            });
    }

    openEditor = (arg: React.MouseEvent<HTMLElement>) => {
        arg.stopPropagation();
        arg.preventDefault();

        const value = (typeof this.props.entityFieldValue === 'string')
            ? JSON.parse(this.props.entityFieldValue) : this.props.entityFieldValue;
        const expressions = (value && value.and) ? value.and : (value && value.v2 && value.v2.and) ? value.v2.and : [];
        openExpressionEditorModal(
            this.popovermgr,
            this.props.event,
            expressions,
            this.props.targetEntityName,
            this.props.targetEntityParent,
            this.props.options,
            this.state.entityconfiguration,
            this.state.definitions,
            this.props.isMailTrigger,
            this.props.datacontext.cmscontext,
            this.props.displayGroups
        ).then((_res: any) => {
            let res = _res;
            if (typeof _res !== "undefined") {
                if (_res && _res.length) {
                    res = this.props.options?.disablePatchEntityNameOnExpression
                        ? _res : removeEntityNameFromExpression(this.props.targetEntityName, _res);

                    if (this.props.toV3) {
                        res = { v2: res, v3: convertFromOldFormat(res) };
                    }

                    if (this.props.wrapExpressionWithAnd) {
                        if (this.props.toV3) {
                            res = Object.assign({}, res, { v2: { and: res.v2 } });
                        } else {
                            res = { and: res };
                        }
                    }
                }
                this.setState({expressions: _res});
                this.props.onChange(this.props.entityFieldFormTemplate.key, res ? JSON.stringify(res) : null);
            }
        });
    };

    render() {
        let expl;
        let expressionsResume;
        if (this.state.isLoading) {
            expressionsResume = <Loader />;
        } else if (this.state.expressions && this.state.expressions.length) {
            const groups: any = {entity: {}};
            for (const d of this.state.definitions) {
                const _d = {...d};
                _d.entityName = d.key;
                groups.entity = _d;
            }

            expressionsResume = <div className="expressiondesigner">
                <ExpressionDesignerItemsList
                    isMailTrigger={this.props.isMailTrigger}
                    onChange={() => {
                        console.warn("if you're seeing this, you probably have an issue!");
                        return null;
                    }}
                    isReadOnly={true}
                    separator="and"
                    targetEntityName={this.props.targetEntityName}
                    cmscontext={this.props.datacontext && this.props.datacontext.cmscontext}
                    expression={this.state.expressions}
                    groups={groups}
                    entityconfig={this.state.entityconfiguration}
                    // definition={this.state.definitions}
                />
            </div>;
        }
        if (!this.props.targetEntityName) {
            expl = <div><AppLabel i18n="entityform.expressioneditor.notargetentitynameselected" /></div>;
        }

        let content = null;
        if (
            this.props.entityValue.targetEntityName
            && (
                this.state.entityconfiguration
                || this.state.definitions && this.state.definitions.length
            )
        ) {
            content = (
                <>
                    {expl}
                    <button
                        disabled={!this.props.targetEntityName || this.props.forceReadOnly}
                        className={this.props.forceReadOnly ? "read-only" : ""}
                        onClick={!this.props.forceReadOnly ? this.openEditor : null}
                    >
                        <AppLabel i18n="entityform.expressioneditor.open" />
                    </button>
                    <PopoverManager ref={(mgr) => this.popovermgr = mgr && mgr.ctx} />
                    {this.props.showExpressionPreview ? expressionsResume : null}
                </>
            );
        } else if (this.props.entityValue.targetEntityName && !this.state.entityconfiguration
            && (this.state.definitions && !this.state.definitions.length)
        ) {
            content = <Loader />;
        }

        return <DynamicFieldWrapper className="expression-editor" {...this.props}>
            {content}
        </DynamicFieldWrapper>;
    }
}

@connectwith((state: States.IAppState) => ({ event: state.event, i18n: state.i18n }))
export class InlineExpressionEditor extends React.Component<IExpressionEditorProps, any> {
    constructor(props: IExpressionEditorProps) {
        super(props);
        this.state = {
            definitions: [],
            isFetching: true
        };
    }

    onChange = (expressions) => {
        let res = this.props.options?.disablePatchEntityNameOnExpression
            ? expressions : removeEntityNameFromExpression(this.props.targetEntityName, expressions);
        if (this.props.wrapExpressionWithAnd) {
            res = { and: res };
        }
        this.props.onChange(this.props.entityFieldFormTemplate.key, JSON.stringify(res));

        this.setState({
            expressions: expressions
        });
    };

    componentDidMount() {
        if (this.props.targetEntityName) {
            return this.props.entityconfiguration.datasource.worktemplate().then((res) => {
                if (res) {
                    const expr = (typeof this.props.entityFieldValue === 'string')
                        ? JSON.parse(this.props.entityFieldValue) : this.props.entityFieldValue;
                    let expression = (expr && expr.and) ? expr.and : [];
                    expression = this.props.options?.disablePatchEntityNameOnExpression
                        ? expression : addEntityNameToExpression(this.props.targetEntityName, expression);
                    this.setState({
                        definitions: [...this.state.definitions, {
                            // key: 'entity',
                            // title: 'entity',
                            key: this.props.targetEntityName,
                            title: this.props.targetEntityName,
                            fields: [...Object.values(res.fields)]
                        }],
                        groups: {
                            entity: {
                                entityName: this.props.targetEntityName,
                                title: this.props.targetEntityName,
                                template: res
                            }
                        },
                        expressions: expression,
                        isFetching: false
                    });
                }
            }, (err) => {
                this.setState({ isFetching: false });
                console.error(err);
            });
        }
        this.setState({ isFetching: false });
    }

    render() {
        let content;
        if (this.state.isFetching) {
            content = <Loader />;
        } else if (this.state.definitions) {
            content = <ExpressionDesigner
                cmscontext={this.props.datacontext.cmscontext}
                expression={this.state.expressions}
                definitions={this.props.displayGroups ? null : this.state.definitions}
                groups={this.state.groups}
                onChange={(expr) => this.onChange(expr)}
                targetEntityName={this.props.targetEntityName}
                options={this.props.options}
                isMailTrigger={this.props.isMailTrigger}
                // columns={this.props.columns}
            />;
        }
        return <DynamicFieldWrapper {...this.props}>
            {content}
        </DynamicFieldWrapper>;
    }
}

interface IExpressionEditorModalProps {
    event: States.IEventState;
    expressions: Entities.Expression[];
    targetEntityName: string;
    targetEntityParent: string;
    options: IExpressionDesignerOptions;
    isMailTrigger?: boolean;
    displayGroups?: boolean;
    onCompleted?: (res?) => void;
    entityconfiguration: IEntityPageConfiguration;
    definitions?: IEntityGroupDefinition[];
    cmscontext: ICMSContext;
}

// tslint:disable-next-line: max-classes-per-file
class ExpressionEditorModal extends React.Component<IExpressionEditorModalProps, any> {
    constructor(props: IExpressionEditorModalProps) {
        super(props);

        let expr = null;
        if (this.props.expressions) {
            expr = this.props.options?.disablePatchEntityNameOnExpression
                ? this.props.expressions : addEntityNameToExpression(this.props.targetEntityName, this.props.expressions);
        }

        this.state = {
            expressions: expr,
            definitions: [...this.props.definitions],
            groups: null,
            isFetching: true
        };
    }

    componentDidMount() {
        return this.props.entityconfiguration.datasource.worktemplate().then((res) => {
            if (res) {
                this.setState({
                    definitions: [...this.state.definitions, {
                        // key: 'entity',
                        // title: 'entity',
                        key: this.props.targetEntityName,
                        title: this.props.targetEntityName,
                        template: res
                    }],
                    groups: {
                        entity: {
                            entityName: this.props.targetEntityName,
                            title: this.props.targetEntityName,
                            template: res
                        }
                    },
                    isFetching: false
                });
            }
        }, (err) => {
            this.setState({ isFetching: false });
            console.error(err);
        });
    }

    save = () => {
        this.props.onCompleted(this.state.expressions);
    };

    onChange = (expressions) => {
        this.setState({
            expressions: JSON.parse(JSON.stringify(expressions))
        });
    };

    render() {
        const actions = [{
            i18n: "actions.save",
            direction: "right",
            callback: () => { this.save(); },
            isImportant: true
        },
        {
            i18n: "actions.close",
            direction: "left",
            callback: () => { this.props.onCompleted(); },
            isImportant: false
        }];

        return <ModalContent
            isFetching={this.state.isFetching}
            onhide={this.props.onCompleted}
            actions={actions}
            title="actions.filter"
        >
            <div>
                {(!this.state.isFetching) ? <ExpressionDesigner
                    cmscontext={this.props.cmscontext}
                    expression={this.state.expressions}
                    definitions={this.props.displayGroups ? null : this.state.definitions}
                    groups={this.state.groups}
                    onChange={(expr) => this.onChange(expr)}
                    targetEntityName={this.props.displayGroups ? null : this.props.targetEntityName}
                    targetEntityParent={this.props.displayGroups ? null : this.props.targetEntityParent}
                    options={this.props.options}
                    isMailTrigger={this.props.isMailTrigger}
                /> : null}
            </div>
        </ModalContent>;
    }
}

const openExpressionEditorModal = function (
    popovermgr: IPopoverManager,
    event: States.IEventState, expressions: Entities.Expression[],
    targetEntityName: string, targetEntityParent: string,
    options: IExpressionDesignerOptions,
    entityconfiguration: IEntityPageConfiguration,
    definitions: IEntityGroupDefinition[],
    isMailTrigger: boolean,
    cmscontext: ICMSContext,
    displayGroups?: boolean
) {
    const props: IExpressionEditorModalProps = { event, expressions, targetEntityName, targetEntityParent, options,
        isMailTrigger, entityconfiguration, definitions, cmscontext, displayGroups };
    return popovermgr.modalPortal(ExpressionEditorModal, props, 'expressioneditor-modal', { closeOnResize: true });
};

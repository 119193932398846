import React, { useEffect, useState } from 'react';
import { IPopoverManager, IPopoverDismissManager } from "@inwink/modals";
import { IModalAction, ModalContent } from '../../../ui/modals/modalcontent';
import { confirmModal } from "../../../ui/modals/confirmmodal";
import { EntityPicker } from "../../../entities/entitypicker/index";
import { AppTextLabel } from "../../../../commons";
import './mailEntityPicker.less';

interface IMailEntityPicker {
    popover: IPopoverDismissManager;
    popovermgr: IPopoverManager;
    entityName: string;
    title: string;
    onCompleted: (entityParentId: string) => void;
}

export default function MailEntityPicker({ popover, popovermgr, onCompleted, entityName, title }: IMailEntityPicker) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        popover?.addCanCloseHandler(() => {
            if (!isDirty) { return Promise.resolve(true); }

            return confirmModal(
                popovermgr,
                'modal.confirm.exit',
                'modal.confirm.exit.message',
                false,
                false,
                null,
                { disableOverlayDismiss: true }
                ).then((confirmRes) => !!confirmRes);
        });
    }, [isDirty]);

    let actions: IModalAction[] = [
        {
            i18n: 'actions.next',
            direction: 'right',
            callback: () => onCompleted(selectedItems[0]),
            disabled: () => !!!selectedItems?.length,
            isImportant: true
        },
        {
            i18n: 'actions.cancel',
            direction: 'left',
            callback: () => onCompleted(null),
            isImportant: false
        }
    ];

    const onPickerChange = (ids) => {
        setIsDirty(true);
        setSelectedItems(ids);
    };

    return (
        <ModalContent isFetching={false} onhide={() => onCompleted(null)} actions={actions} title={title}>
            <div className="entityform">
                <div className="entityfield">
                    <AppTextLabel component="label" i18n='mailtemplate.entitypickermodal.description'/>
                    <EntityPicker
                        entityName={entityName}
                        multiselect={false}
                        selection={selectedItems}
                        onChange={onPickerChange}
                        readOnly={false}
                    />
                </div>
            </div>
        </ModalContent>
    );
};

import * as moment from 'moment';
import * as assign from 'lodash/assign';
import * as i18nHelpers from '@inwink/i18n';
import { logging } from '@inwink/logging';
import { i18nData, openI18nData, syncLanguage, cleari18nData } from './i18ndata';
import { helpMappingData, openHelpMapping, syncMappings, clearHelpData } from './helpmappingdata';
import type {States} from './services';
import { setPreferredLanguage } from '../api/backoffice/me';
import { trackError } from '../api/backoffice/tracking';

export { i18NHelperContext, i18NServiceContext } from '@inwink/i18n';
const logger = logging.getLogger("Data");

export const translate = i18nHelpers.translate;
export const translateFrom = i18nHelpers.translateFrom;
export const translateBag = i18nHelpers.translateBag;
export const getMoment = i18nHelpers.getMoment;
export const I18NStateContext = i18nHelpers.I18NStateContext;
export const AppLabel = i18nHelpers.AppLabel;
export const AppTextLabel = i18nHelpers.AppTextLabel;
export const DynLabel = i18nHelpers.DynLabel;
export const I18NResourcesContext = i18nHelpers.I18NResourcesContext;
export const DisplayDate = i18nHelpers.DisplayDate;

const INITIAL_STATE = {
    defaultlng: "en",
    supported: ["fr", "en"],
    currentLanguage: null,
    currentLanguageCode: "en",
    isReady: false,
    translate: translate,
    helpmappings: null,
    currentDateDisplayLanguageCode: "en"
} as States.i18nState;

export function i18nReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "I18N_SET": {
            return assign({}, state, {
                currentDateDisplayLanguageCode: action.payload.code,
                currentLanguageCode: action.payload.code,
                currentLanguage: action.payload.translations,
                helpmappings: action.payload.helpmappings,
                isReady: true
            });
        }

        default:
            return state;
    }
}

export function getBestMatchingLanguage(lngcode: string, userLanguages: string[], _defaultLanguage: string,
    supportedLanguages: string[]): { language: string, dateDisplayLanguage: string } {
    let defaultLanguage = _defaultLanguage;
    if (supportedLanguages?.length && (!defaultLanguage || !(supportedLanguages.indexOf(defaultLanguage) >= 0))) {
        defaultLanguage = supportedLanguages[0];
    }
    let currentLanguage = defaultLanguage || "en";
    let currentDateDisplayLanguage = defaultLanguage || "en";
    if (lngcode && supportedLanguages) {
        if (supportedLanguages.indexOf(lngcode) >= 0) {
            return { language: lngcode, dateDisplayLanguage: lngcode };
        }
    }

    if (userLanguages && supportedLanguages) {
        for (let i = 0; i < userLanguages.length; i++) {
            const lng = userLanguages[i] && userLanguages[i].toLowerCase();
            let idx = supportedLanguages.indexOf(lng);
            if (idx >= 0) {
                currentLanguage = lng;
                currentDateDisplayLanguage = lng;
                logger.debug(`found language ${currentLanguage} from ${userLanguages[i]}`, supportedLanguages, userLanguages);
                break;
            } else if (lng) {
                const tokens = lng.split("_");
                idx = supportedLanguages.indexOf(tokens[0]);
                if (idx >= 0) {
                    currentLanguage = tokens[0];
                    currentDateDisplayLanguage = lng;
                    logger.debug(`found language ${currentLanguage} from ${userLanguages[i]}`, supportedLanguages, userLanguages);
                    break;
                } else {
                    const _tokens = lng.split("-");
                    idx = supportedLanguages.indexOf(_tokens[0]);
                    if (idx >= 0) {
                        currentLanguage = _tokens[0];
                        currentDateDisplayLanguage = lng;
                        logger.debug(`found language ${currentLanguage} from ${userLanguages[i]}`,
                            supportedLanguages, userLanguages);
                        break;
                    }
                }
            }
        }
    }
    logger.debug("found language " + currentLanguage, supportedLanguages, userLanguages);
    return { language: currentLanguage, dateDisplayLanguage: currentDateDisplayLanguage };
}

export const actions = {
    init(languageCode) {
        return (dispatch) => {
            return new Promise((resolve, reject) => {
                Promise.all([
                    openI18nData(1),
                    openHelpMapping(1)
                ]).then(() => {
                    return i18nData.get(languageCode).then((strtrads: string) => {
                        if (strtrads) {
                            try {
                                const tmptrads = JSON.parse(strtrads);
                                dispatch({
                                    type: 'I18N_SET',
                                    payload: {
                                        code: languageCode,
                                        translations: { [languageCode]: tmptrads },
                                    }
                                });
                            } catch (ex) {
                                logger.error("error initializing i18n", ex, strtrads);
                                trackError({}, ex, "error initializing i18n from " + strtrads);
                                dispatch({
                                    type: 'I18N_SET',
                                    payload: {
                                        code: languageCode,
                                        translations: {},
                                    }
                                });
                                cleari18nData(languageCode);
                                clearHelpData(languageCode);
                                // setTimeout(() => {
                                //     actions.syncCurrent(true)(dispatch, getState);
                                // }, 200);
                            }
                        } else {
                            dispatch({
                                type: 'I18N_SET',
                                payload: {
                                    code: languageCode,
                                    translations: {},
                                }
                            });
                        }
                    });
                }).then(() => {
                    // // return (dispatch) => {
                    //     dispatch({
                    //         type: 'I18N_SET',
                    //         payload: {
                    //             code: languageCode,
                    //             translations: {},
                    //         }
                    //     });
                    //     //return actions.loadLanguage(languageCode)(dispatch);
                    //     resolve();
                    // // }
                    resolve();
                }).catch((err) => {
                    reject(err);
                });
            });
        };
    },

    syncCurrent(force = false) {
        return (dispatch, getState) => {
            const state: States.IAppState = getState();
            return syncLanguage(state.i18n.currentLanguageCode,
                state.i18n.currentLanguage[state.i18n.currentLanguageCode], force).then((trads) => {
                return syncMappings(state.i18n.currentLanguageCode, state.i18n.helpmappings, force).then((mappings) => {
                    const apptrads = {};
                    apptrads[state.i18n.currentLanguageCode] = trads;
                    dispatch({
                        type: 'I18N_SET',
                        payload: {
                            code: state.i18n.currentLanguageCode,
                            translations: apptrads,
                            helpmappings: mappings
                        }
                    });
                });
            }).then(null, (err) => {
                console.error("error syncing data", err);
                trackError({}, err, "error syncing bo init data");
                return Promise.reject(err);
            });
        };
    },

    saveLanguagePreferences() {
        return (dispatch, getState) => {
            const state: States.IAppState = getState();
            return setPreferredLanguage(state.i18n.currentLanguageCode);
        };
    },

    loadLanguage(languageCode) {
        return (dispatch) => {
            moment.locale(languageCode);
            const setlng = (trads, mappings) => {
                const apptrads = {};
                apptrads[languageCode] = trads;

                dispatch({
                    type: 'I18N_SET',
                    payload: {
                        code: languageCode,
                        translations: apptrads,
                        helpmappings: mappings
                    }
                });
            };

            return helpMappingData.get(languageCode).then((strmappings: string) => {
                if (strmappings) {
                    const tmpmappings = JSON.parse(strmappings);
                    return syncMappings(languageCode, tmpmappings);
                }
                return syncMappings(languageCode, null);
            }).then((mappings) => {
                return i18nData.get(languageCode).then((strtrads: string) => {
                    if (strtrads) {
                        const tmptrads = JSON.parse(strtrads);
                        setlng(tmptrads, mappings);
                        syncLanguage(languageCode, tmptrads).then((trads) => {
                            if (trads !== tmptrads) {
                                setlng(trads, mappings);
                            }
                        });
                        return;
                    }

                    return syncLanguage(languageCode, null).then((trads) => {
                        setlng(trads, mappings);
                    });
                });
            });
        };
    }
};

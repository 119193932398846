/* eslint-disable max-classes-per-file */
import { Link } from 'react-router-dom';
import { Entities } from '@inwink/entities/entities';
import { AppTextLabel, connectwith, IPopoverManager, React, WithPopoverManager } from '../../commons';
import { eventActions } from '../../services/eventservice';
import { States } from '../../services/services';
import { AppShellHeaderSide } from './appshellheader.side';
import './appshellheader.less';

export interface IAppShellHeaderProps {
    event: States.IEventState;
    user: States.IAppUserState;
    customer: States.ICustomerState;
    community: States.ICommunityState;
    i18n: States.i18nState;
    rights: States.IAppUserRights;
    sectiontitle?: string;
    pagetitle?: string;
    pagetitlePrefix?: string;
    pagesubtitle?: string;
    pageicon?: string;
    featurecode?: string;
    eventActions?: typeof eventActions;
    switchEvent?: any;
    toggleMenu: () => void;
    visualstate: string[];
    breadcrumb?: States.IBreadcrumbWrapper[];
}

export interface IAppShellHeaderPartProps extends IAppShellHeaderProps {
    popovermgr: IPopoverManager;
    i18nHelper?: Entities.i18nHelper;
}

@connectwith((state: States.IAppState) => ({
    pagetitle: state.pages.title,
    pagetitlePrefix: state.pages.titlePrefix,
    pagesubtitle: state.pages.subtitle,
    pageicon: state.pages.pageicon,
    featurecode: state.pages.featurecode,
    breadcrumb: state.pages.breadcrumb
}))
export class AppShellHeader extends React.Component<IAppShellHeaderProps, any> {
    render() {
        return <WithPopoverManager>
            {(popovermgr) => <AppShellHeaderContent {...this.props} popovermgr={popovermgr} />}
        </WithPopoverManager>;
    }
}

export class AppShellHeaderContent extends React.Component<IAppShellHeaderPartProps, any> {
    toggleMenu = () => {
        this.props.toggleMenu();
    };

    render() {
        let title;
        let sectionTitle;
        if (this.props.breadcrumb && this.props.breadcrumb.length) {
            const lastbc = this.props.breadcrumb[this.props.breadcrumb.length - 1];
            if (lastbc.items.length) {
                const breadcrumbItem = [];
                lastbc.items.forEach((item, idx) => {
                    let itemName = item.name;
                    if (item.eventName) {
                        itemName = this.props.event?.detail?.title || this.props.event?.eventdetail?.title;
                    } else if (item.communityName) {
                        itemName = this.props.community?.detail?.name;
                    }
                    const itemElt = item.link
                        ? <Link key={"item" + idx} to={item.link}>{itemName}</Link>
                        : <span key={"item" + idx}>{itemName}</span>;
                    breadcrumbItem.push(itemElt);
                    breadcrumbItem.push(<span key={"separator" + idx} className="separator">&gt;</span>);
                });
                breadcrumbItem.pop();
                sectionTitle = <div className="breadcrumb">{breadcrumbItem}</div>;
            }
        }

        if (this.props.pagetitle) {
            title = <div key={this.props.pagetitle} className="pagetitle-text">
                <div className="pagetitle">{this.props.pagetitlePrefix || ""}
                    <AppTextLabel i18n={this.props.pagetitle} />
                </div>
                {
                    (this.props.pagesubtitle)
                        ? <div className="pagesubtitle">{this.props.pagesubtitle}</div>
                        : null
                }
            </div>;
        } else {
            title = <div key="empty" className="pagetitle-text"><div className="pagetitle">&nbsp;</div></div>;
        }
        // const sectionTitle = <div className="eventName">
        //     {customerNav}
        //     {tenantNav}
        //     {sectionName}
        // </div>;

        let icon;
        return <div className="app-header">
            <div className="burger-menu" onClick={this.toggleMenu}>
                <div className="burger">
                    <div className="split1" />
                    <div className="split2" />
                    <div className="split3" />
                </div>
            </div>
            {icon}
            <div className="title">
                {sectionTitle}
                <div className="titlecontainer">
                    {title}
                </div>
            </div>
            <AppShellHeaderSide {...this.props} />
        </div>;
    }
}

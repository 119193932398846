import { React } from '../../../commons';

interface RadioInputProps {
    canEditTrad: boolean;
    i18n: string;
    value: any;
    currValue: any;
    onChange: (val: string) => void;
}

export class RadioInput extends React.PureComponent<RadioInputProps, any> {
    constructor(props: RadioInputProps) {
        super(props);
        this.state = {
            fieldX: "16",
            fieldY: "9"
        }
    }

    onChange(val: any) {
        this.props.onChange(val.currentTarget.value);

    }

    onCustomChangeX(val: any) {
        let ratio = val.currentTarget.value / this.state.fieldY;
        this.setState({ fieldX: val.currentTarget.value });
        this.props.onChange(ratio.toString());
    }

    onCustomChangeY(val: any) {
        let ratio = this.state.fieldX / val.currentTarget.value;
        this.setState({ fieldY: val.currentTarget.value });
        this.props.onChange(ratio.toString());
    }


    render() {
        let i = 0;
        let content = [];
        let customClass = "";
        let customField;
        let imagePreview = <div style={{ width: "100%", margin: "15px 0 0 0" }}>
            <img src={(inwink.config.assetsUrl || "/") + "assets/images/contentschema.jpg"}
                width={200 * this.props.currValue ? 200 * this.props.currValue : "auto"} height={200} />
        </div>

        this.props.value.forEach( element => {
            if (element.title == "Custom") {
                customClass = "custom";
                customField = 
                    <div className="customField">
                        <input type="text" name="fieldX" onChange={(val) => this.onCustomChangeX(val)} value={this.state.fieldX} />
                        <div><p>:</p></div>
                        <input type="text" name="fieldY" onChange={(val) => this.onCustomChangeY(val)} value={this.state.fieldY} />
                    </div>;
            } else {
                customClass = "";
                customField = "";
            }

            if (this.props.currValue == element.value || (element.title == "Custom" && !(this.props.currValue == "undefined" || this.props.currValue == 1.77 || this.props.currValue == 1.33 || this.props.currValue == 1.6))) {
                content.push(
                    <div key={element.title + element.value}>
                        <input type="radio" key={i} name="radioinput" className={customClass} value={element.value} id={"optionNbr-" + i} checked={true} onChange={(val) => this.onChange(val)} />
                        <label htmlFor={"optionNbr-" + i}>{element.title}</label>
                        {customField}
                    </div>);
            }
            else
                content.push(
                    <div key={element.title + element.value}>
                        <input type="radio" key={i} name="radioinput" className={customClass} value={element.value} id={"optionNbr-" + i} onChange={(val) => this.onChange(val)} />
                        <label htmlFor={"optionNbr-" + i}>{element.title}</label>
                        {customField}
                    </div>);
            i++;
        });

        return <div className="radioinput">
            {content}
            {imagePreview}
        </div>
    }
}
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { OnBoardingDefinitions } from './definition';
import { OnBoardingContent } from './content';
import { i18NHelperContext } from '../../services/i18nservice';
import { getSetting, setSetting } from '../../services/usersettings';

export interface IOnBoardingProps {
    onboardingkey?: string;
    config: OnBoardingDefinitions.OnBoardingConfiguration;
    disabled?: boolean;
    trigger?: any;
    onDismiss?: () => void;
}

export interface IOnBoardingState {
    disabled: boolean;
}

export class OnBoarding extends React.PureComponent<IOnBoardingProps, IOnBoardingState> {
    parentElement: HTMLDivElement;

    constructor(props: IOnBoardingProps) {
        super(props);
        
        //let disabled = props.disabled !== null && props.disabled !== undefined ? props.disabled : false;

        // if (this.props.onboardingkey) {
        //     let lastview = localStorage.getItem("onboarding-" + this.props.onboardingkey);
        //     if (lastview === this.props.config.version) {
        //         disabled = true;
        //     }
        // }
        this.parentElement = document.createElement("DIV") as HTMLDivElement;
        this.parentElement.className = "onboardingcontainer";
        document.body.appendChild(this.parentElement);

        this.state = {
            disabled: false,
            //triggered: null
        };
    }

    // componentDidMount() {
    //     this.checkStatus(this.props.onboardingkey, this.props.config);
    // }

    // checkStatus(onboardingkey: string, config: OnBoardingDefinitions.OnBoardingConfiguration) {
    //     console.log("onboarding check status " + onboardingkey);
    //     if (!onboardingkey || !config) {
    //         return Promise.resolve(null);
    //     }

    //     return getSetting("onboarding-" + onboardingkey).then((onboardingStatusStr) => {
    //         console.log("onboarding " + onboardingkey + " " + onboardingStatusStr + "/" + config.version);

    //         if (config && config.version && (config.version !== onboardingStatusStr || this.state.triggered === onboardingkey)) {
    //             if (this.props.onboardingkey === onboardingkey) {
    //                 this.setState({ disabled : false });
    //             }
    //         } else if (this.props.onboardingkey === onboardingkey) {
    //             this.setState({ disabled : true });
    //         }

    //         return onboardingStatusStr;
    //     }, (err) => {
    //         console.error("error getting onboarding status " + onboardingkey, err);
    //     });
    // }

    componentDidUpdate(prevProps: IOnBoardingProps) {
        if (prevProps.trigger !== this.props.trigger) {
            this.setState({ disabled : false });
        }
    }

    componentWillUnmount() {
        let elt = this.parentElement;
        this.parentElement = null;
        if (elt) {
            setTimeout(() => {
                document.body.removeChild(elt);
            }, 500);
        }
    }

    dismiss = () => {
        // if (this.props.onboardingkey) {
        //     setSetting("onboarding-" + this.props.onboardingkey, this.props.config.version);
        // }
        this.setState({
            disabled : true,
            //triggered: null
        });

        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
    }

    render() {
        let content = null;
        let disabled = this.state.disabled || this.props.disabled;
        if (this.props.config && this.props.config.steps && !disabled) {
            content = <i18NHelperContext.Consumer>
                {(i18nHelper) => <OnBoardingContent
                    {...this.props}
                    i18nHelper={i18nHelper}
                    onDismiss={this.dismiss} />}
            </i18NHelperContext.Consumer>;
        } else {
            content = <div className="empty"></div>;
        }

        return ReactDOM.createPortal(content, this.parentElement);
    }
}

import type { Entities } from '@inwink/entities/entities';
import { React, AppTextLabel } from '../../../../../commons';
import { AddExpression } from '../../../expressiondesignerv3/expressions/addexpression';
import { ResumeExpression } from '../../../expressiondesignerv3/expressions/expressionresume';
import { getItemMetadata } from '../../../expressiondesignerv3/utils';
import type { IFiltersPanelProps } from './definitions';
import { fieldFilter } from '../../fieldFilter';

import "./filters.less";
import "./filterresume.less";

export class FilterResume extends React.Component<IFiltersPanelProps, any> {
    addExpression = (expression) => {
        this.props.addFilter(expression, expression.name);
    };

    allowedFields = (key: string, field: Entities.IEntityFieldTemplateV3) => {
        return fieldFilter(key, this.props.entityconfiguration, this.props.user, field);
    };

    render() {
        const filterKeys = (this.props.filters && Object.keys(this.props.filters).filter((k) => k[0] !== "$")) || [];

        return <div className="filter-resume-panel items-panel">
            {
                (filterKeys.length) ? <ul>
                    {
                        filterKeys.map((k) => {
                            return <li key={k}>
                                <div>
                                    <ResumeExpression
                                        cmscontext={this.props.cmscontext}
                                        i18nHelper={this.props.i18nHelper}
                                        metadata={getItemMetadata(k, this.props.groups)}
                                        expression={this.props.filters[k]}
                                    />
                                    <i onClick={() => this.props.removeFilter(k)} className="inwink-cancel" />
                                </div>
                            </li>;
                        })
                    }
                </ul> : <div className="nofiltertoshow"><AppTextLabel i18n="filter.modal.nofiltertoshow" /></div>
            }
            <div className="addexpression-resume">
                <AppTextLabel component="label" i18n="addfilter" />
                <AddExpression
                    cmscontext={this.props.cmscontext}
                    groups={this.props.groups}
                    displayConditionLabel={false}
                    onAdd={this.addExpression}
                    fieldFilter={this.allowedFields}
                    entityconfig={this.props.entityconfiguration}
                    focusFieldPicker={true}
                />
            </div>
        </div>;
    }
}

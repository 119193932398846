import type { Entities } from '@inwink/entities/entities';
import { removeDiacritics } from '@inwink/utils/methods';
import type { IEntityGroupDefinition, FieldFilterCallback } from '../definitions';

export interface IFieldPickerItem {
    field: Entities.IEntityFieldTemplateV3;
    parent?: IFieldPickerItem;
    label?: string;
    filterlabel?: string;
    tradkey?: string;
    tradkeyForEntityname?: string;
    entityname: string;
    isFake?: boolean;
    key: string;
    // value?: string;
    childs?: Record<string, IFieldPickerItem>;
}

export function sortFields(a: IFieldPickerItem, b: IFieldPickerItem) {
    const la = a.label && a.label.toLowerCase();
    const lb = b.label && b.label.toLowerCase();
    if (la < lb) {
        return -1;
    } if (la > lb) {
        return 1;
    }
    return 0;
}
export interface IFieldsDefinitions {
    all: Record<string, IFieldPickerItem>;
    excludedColumns: IFieldPickerItem[];
    remainingColumns: IFieldPickerItem;
}

export function getFields(
    i18n: Entities.i18nHelper,
    entityname: string,
    displayLanguage: string,
    excluded: string[],
    entity: Entities.IEntityTemplateV3,
    fieldFilter?: FieldFilterCallback
): IFieldsDefinitions {
    const fieldKeys = entity && entity.fields && Object.keys(entity.fields);
    const conformedExcluded = excluded && excluded.map((e) => {
        const existingKey = fieldKeys && fieldKeys.filter((k) => k.toLowerCase() === (e && e.toLowerCase()))[0];
        if (existingKey) {
            return existingKey;
        } return e;
    });

    const columns: Record<string, IFieldPickerItem> = {};
    const remainingColumns: IFieldPickerItem = { key: "$$root", label: null, field: null, entityname: entityname, parent: null };
    getEntityFields(i18n, entityname, displayLanguage, conformedExcluded, columns, remainingColumns, entity, "", fieldFilter);

    const excludedColumns = conformedExcluded && conformedExcluded.map((colKey) => {
        return columns[colKey];
    }).filter((c) => !!c);

    return {
        all: columns,
        excludedColumns,
        remainingColumns
    };
}

export function getGroupFields(
    i18n: Entities.i18nHelper,
    displayLanguage: string,
    excluded: string[],
    groups: Record<string, IEntityGroupDefinition>,
    fieldFilter?: FieldFilterCallback
): IFieldsDefinitions {
    const columns: Record<string, IFieldPickerItem> = {};
    const remainingColumns: IFieldPickerItem = { key: "$$root", label: null, field: null, entityname: null, parent: null };
    const groupKeys = Object.keys(groups);
    const allConformedExcluded = [];

    groupKeys.forEach((key) => {
        const currentGroup = groups[key];
        let groupFieldItem = remainingColumns;

        const fieldKeys = currentGroup.template && Object.keys(currentGroup.template.fields);
        const conformedExcluded = excluded && excluded.map((e) => {
            const existingKey = fieldKeys && fieldKeys.filter((k) => k.toLowerCase() === (e && e.toLowerCase()))[0];
            if (existingKey) {
                return existingKey;
            }

            return e;
        });

        if (conformedExcluded) {
            conformedExcluded.forEach((k) => {
                if (!(allConformedExcluded.indexOf(k) >= 0)) {
                    allConformedExcluded.push(k);
                }
            });
        }

        if (groupKeys.length > 1) {
            groupFieldItem = {
                key: key,
                entityname: currentGroup.entityName,
                label: currentGroup.title,
                filterlabel: currentGroup.title && removeDiacritics(currentGroup.title),
                tradkey: currentGroup.title,
                tradkeyForEntityname: null,
                field: null,
                parent: remainingColumns
            };

            // For the moment I translate only these entities, for the rest, we display the title from the field 'label':
            switch (currentGroup.entityName) {
                case 'Person':
                case 'Exhibitor':
                case 'Order':
                case 'Session':
                case 'Meeting':
                    groupFieldItem.tradkeyForEntityname = `entityname.${currentGroup.entityName.toLowerCase()}`;
                    break;
                default:
                    break;
            }

            columns[key] = groupFieldItem;
            remainingColumns.childs = remainingColumns.childs || {};
            remainingColumns.childs[key] = groupFieldItem;
        }

        getEntityFields(i18n, currentGroup.entityName, displayLanguage, conformedExcluded,
            columns, groupFieldItem, currentGroup.template, currentGroup.path ? currentGroup.path + "." : "", fieldFilter);
    });

    const excludedColumns = allConformedExcluded && allConformedExcluded.length && allConformedExcluded.map((colKey) => {
        return columns[colKey];
    }).filter((c) => !!c);

    return {
        all: columns,
        excludedColumns,
        remainingColumns
    };
}

function getEntityFields(
    i18n: Entities.i18nHelper,
    entityname: string,
    displayLanguage: string,
    excluded: string[],
    _displaycolumns: Record<string, IFieldPickerItem>,
    _parent: IFieldPickerItem,
    entity: Entities.IEntityTemplateV3,
    rootkey: string,
    fieldFilter: (key: string, field: Entities.IEntityFieldTemplateV3, template: Entities.IEntityTemplateV3, entityname: string
    ) => boolean
) {
    const displaycolumns = _displaycolumns;
    const parent = _parent;
    const fieldKeys = (entity && entity.fields && Object.keys(entity.fields)) || [];
    fieldKeys.forEach((fieldKey) => {
        const colkey = rootkey + fieldKey;
        // we need any because form field doesn't respect the template and I'll deal with it another day !
        const field: any = entity.fields[fieldKey];

        let isAllowed = true;
        if (fieldFilter) {
            isAllowed = fieldFilter(colkey, field, entity, entityname);
        }

        if (isAllowed) {
            const isExcluded = excluded && excluded.map((v) => v && v.toLowerCase()).indexOf(colkey.toLowerCase()) >= 0;

            let label;
            const tradkey = (entityname + "." + fieldKey).toLowerCase();
            if (field.display && field.display.labels && Object.keys(field.display.labels)?.length) {
                label = i18n.translateBag(field.display.labels);
            } else if (field.labels && field.labels[displayLanguage]) {
                label = field.labels[displayLanguage];
            } else {
                label = i18n.translate(tradkey) || tradkey;
            }

            const column: IFieldPickerItem = {
                key: colkey,
                entityname: entityname,
                label: label,
                filterlabel: label && removeDiacritics(label),
                tradkey: tradkey,
                field: field,
                parent: parent
            };

            if (field.type !== "Entities" && field.template?.fields) {
                column.childs = {};
                getEntityFields(i18n, field.value, displayLanguage, excluded, displaycolumns,
                    column, field.template, colkey + ".", fieldFilter);
            }

            displaycolumns[colkey] = column;
            if (!isExcluded) {
                parent.childs = parent.childs || {};
                parent.childs[colkey] = column;
            }
        }
    });
}

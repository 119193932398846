import { Entities } from '@inwink/entities/entities';
import { React, AppTextLabel } from '../../../commons';
import type { IExpressionDesignerProps } from './expressiondesigner';
import { ExpressionDesignerAddItem } from './expressiondesigner.add';
import { ExpressionDesignerItem } from './expressiondesigner.item';
import type { IEntityPageConfiguration } from '../entitypagev3/entitypagebase.props';

export interface IExpressionDesignerItemsListProps extends IExpressionDesignerProps {
    separator: string;
    onRemoveAll?: (arg) => void;
    isReadOnly?: boolean;
    entityconfig: IEntityPageConfiguration;
}

export class ExpressionDesignerItemsList extends React.Component<IExpressionDesignerItemsListProps, any> {
    constructor(props: IExpressionDesignerItemsListProps) {
        super(props);
        this.state = {
            showAddItem: false
        };
    }

    addExpression = (expr: Entities.Expression, removeNull?) => {
        this.setState({ showAddItem: false }, () => {
            const expressions = this.props.expression.slice(0, this.props.expression.length);
            expressions.push(expr);
            if (removeNull) {
                expressions.splice(removeNull, 1);
            }
            if (this.props.onChange) {
                this.props.onChange(expressions);
            }
        });
    };

    onRemoveItem = (expression: Entities.Expression) => {
        const expressions = this.props.expression.slice(0, this.props.expression.length);
        const idx = expressions.indexOf(expression);
        expressions.splice(idx, 1);
        if (this.props.onChange) {
            this.props.onChange(expressions);
        }
    };

    itemChanged = () => {
        let expressions = this.props.expression.slice(0, this.props.expression.length);
        expressions = expressions.filter((e) => {
            return !(((e.or && !e.or.length) || (e.and && !e.and.length)));
        });
        if (this.props.onChange) {
            this.props.onChange(expressions);
        }
    };

    itemTransformCondition = (item, condition) => {
        const expressions = this.props.expression.slice(0, this.props.expression.length);
        const idx = expressions.indexOf(item);
        expressions[idx] = {[condition]: [expressions[idx]]};
        if (this.props.onChange) {
            this.props.onChange(expressions);
        }
    };

    toggleShowAddItem = (isFirst) => {
        if (isFirst) {
            return;
        }
        this.setState((prevState) => {
            return { showAddItem: !prevState.showAddItem };
        });
    };

    render() {
        let items;
        if (this.props.expression && this.props.expression.map) {
            items = this.props.expression.map((e, idx) => {
                let separator;
                if (idx === 0) {
                    separator = <div className="separator-bullet" />;
                } else {
                    separator = <AppTextLabel
                        component="div"
                        className={"separator separator-" + this.props.separator}
                        i18n={"expressiondesigner." + this.props.separator}
                    />;
                }
                return <div className="expressiondesigner-itemwrapper" key={"expr" + idx}>
                    <div className="expressiondesigner-itemwrappercontent">
                        <ExpressionDesignerItem
                            {...this.props}
                            designerList={(props) => <ExpressionDesignerItemsList {...props} />}
                            isAlone={(this.props.expression.length === 1)}
                            itemTransformCondition={this.itemTransformCondition}
                            isFirst={!!((idx === 0 || this.props.expression.length <= 1))}
                            onItemChange={this.itemChanged}
                            item={e}
                            onRemoveItem={this.onRemoveItem}
                        />
                    </div>
                    <div className="border" />
                    {separator}
                </div>;
            });
        }

        const isFirst = !this.props.expression.length;
        const exp = this.props.expression as any;

        let addContent;
        if (!this.props.isReadOnly) {
            addContent = <div
                style={{ display: ((exp?.map && (exp.indexOf('or') !== -1 || exp.indexOf('and') !== -1))
                    ? 'none' : 'block'),
                float: 'left' }}
                className="expressiondesigner-itemwrapper"
            >
                <div className="expressiondesigner-itemwrappercontent">
                    <ExpressionDesignerAddItem
                        {...this.props}
                        showAddItem={!!((isFirst || this.state.showAddItem))}
                        isFirst={!!(isFirst)}
                        onAddExpression={this.addExpression}
                        onRemoveAll={this.props.onRemoveAll}
                    />
                </div>
                <div className="border" style={{ display: ((isFirst) ? 'none' : 'block') }} />
                <div
                    className={((isFirst) ? "separator-bullet" : "separator-plus" + ((!this.state.showAddItem)
                        ? ' separator-plus-show' : ' separator-plus-hide'))}
                    onClick={() => this.toggleShowAddItem(isFirst)}
                >
                    {(isFirst) ? '' : '+'}
                </div>
            </div>;
        }

        return <div className="expressiondesigner-itemslist">
            <div className="expressiondesigner-itemslist-items expressiondesigner-itemadditem">
                {items}
                {addContent}
            </div>
        </div>;
    }
}

import { React, WithPopoverManager, IPopoverManager } from '../../commons';
import { actions as userActions } from '../../services/userservice';
import { actions as i18nActions } from '../../services/i18nservice';
import { States } from '../../services/services';
import { IUserPopinContext, UserPopinContext } from './userpopincontext';
import './appshelluser.popin.less';
import { UserProfilePopinContent } from './appshelluser.popin.content';

export interface IPopinProps {
    event: States.IEventState;
    community: States.ICommunityState;
    user: States.IAppUserState;
    i18n: States.i18nState;
    rights: States.IAppUserRights;
    userActions?: typeof userActions;
    i18nActions?: typeof i18nActions;
}

export interface IPopinContentProps extends IPopinProps {
    popovermgr: IPopoverManager;
    userpopinContext: IUserPopinContext;
}

export class UserProfilePopin extends React.Component<IPopinProps, IUserPopinContext> {
    constructor(props: IPopinProps) {
        super(props);
        this.state = {
            showPopin: false,
            // eslint-disable-next-line react/no-unused-state
            togglePopin: () => {
                this.setState((prevstate) => {
                    return { showPopin: !prevstate.showPopin };
                });
            }
        };
    }

    render() {
        return <UserPopinContext.Provider value={this.state}>
            {this.props.children}
            <WithPopoverManager>
                {(popovermgr) => <UserProfilePopinContent
                    {...this.props}
                    userpopinContext={this.state}
                    popovermgr={popovermgr}
                />}
            </WithPopoverManager>
        </UserPopinContext.Provider>;
    }
}

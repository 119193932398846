import { CanCloseHandler } from '@inwink/modals';
import { React, TransitionGroup, assign } from '../../../commons';
import { SimpleFadeTransition } from "../../transitions";
import type { ISimpleTabsContentProps } from './index';

export class SimpleTabsContentPanel extends React.Component<ISimpleTabsContentProps, any> {
    currentTabCtrl: any; // = React.createRef<CanCloseHandler>();

    constructor(props) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }

    canClosePage(context?: any) {
        if (this.currentTabCtrl && this.currentTabCtrl.canClose) {
            return this.currentTabCtrl.canClose(context);
        }
        // if (this.currentTabCtrl && this.currentTabCtrl.current && this.currentTabCtrl.current.canClose){
        //     return this.currentTabCtrl.current.canClose(context);
        // }
        return Promise.resolve(true);
    }

    setRef(key, ref) {
        const selectedItemKey = this.props.selectedItem.id + this.props.selectedItem.label;
        if (key === selectedItemKey) {
            this.currentTabCtrl = ref;
        }
    }

    contentRefreshed = () => {
        if (this.props.onContentRefresh) {
            this.props.onContentRefresh(this.props.selectedItem);
        }
    };

    render() {
        /* This render is triggered 5 times in a row, would be worth it to find why so much */
        let content;
        if (this.props.selectedItem) {
            if (this.props.selectedItem.component) {
                const selectedItemKey = this.props.selectedItem.id + this.props.selectedItem.label;
                const props = assign({}, this.props.getPanelProps(this.props.selectedItem), {
                    popovermgr: this.props.popovermgr,
                    selectedItem: this.props.selectedItem,
                    onContentRefreshed: this.contentRefreshed
                });

                content = <SimpleFadeTransition key={selectedItemKey}>
                    <CanCloseHandler ref={(refElm) => this.setRef(selectedItemKey, refElm)}>
                        <div className="simpletabs-contentpanel">
                            {React.createElement(this.props.selectedItem.component, props)}
                        </div>
                    </CanCloseHandler>
                </SimpleFadeTransition>;
            } else {
                content = <SimpleFadeTransition key="loading">
                    <div className="simpletabs-contentpanel">
                        ??? {this.props.selectedItem.label}
                    </div>
                </SimpleFadeTransition>;
            }
        }
        return <TransitionGroup component="div" className="simpletabs-contentwrapper">
            {content}
        </TransitionGroup>;
    }
}

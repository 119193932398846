import * as React from 'react';

export interface EntityIconProps {
    entityName? : string;
    defaultIcon? : string;
}

export class EntityIcon extends React.PureComponent<EntityIconProps, any> {
     render() {
        var icon = this.props.entityName && getEntityIcon(this.props.entityName);
        if (!icon) icon = this.props.defaultIcon || "";
        return <i className={icon}></i>
    }
}

function getEntityIcon(entityName:string):string {
    entityName = entityName.toLowerCase();

    if (entityName == "person"){
        return "inwink-user"
    }
    else if (entityName == "session"){
        return "inwink-session"
    }
    else if (entityName == "speaker"){
        return "inwink-speaker"
    }
    else if (entityName == "exhibitor"){
        return "inwink-exhibitor"
    }
    else if (entityName == "exhibitortask"){
        return "inwink-task"
    }
    else if (entityName == "survey"){
        return "inwink-survey"
    }
    else if (entityName == "surveyanswer"){
        return "inwink-survey"
    }
    else if (entityName == "order"){
        return "inwink-tickets"
    }

    return null;
}
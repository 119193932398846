import { IPopoverManager, IPopoverDismissManager } from '@inwink/modals';
import type { IEntityDataSource } from "@inwink/apibase/datasource";

import type { IDataSourceV3, IInwinkEntityV3QueryOptions } from 'api/base/datasource';
import type { IFileInputManager } from '@inwink/fileutils/fileapi';
import type { Entities } from '@inwink/entities/entities';
import type { ICMSContext } from '../components/cmscontext';
import type { IConfigurationProviderArgs } from '../components/entities/entitypagev3/entitypagebase.props';
import { openEntityDetail as _openEntityDetailV3 } from '../components/entities/entitydetailv3/openEntityDetail';
import { States } from '../services/services';
import { IEntityDetailConfiguration } from '@@components/entities/entitydetailv3';

export interface IEntityDetailTabDefinitionProps {
    id: string;
    i18n: string;
    component: any;
    showIf?: (args) => boolean;
}

export interface IEntityDetailPageProps {
    popover?: IPopoverDismissManager;
    popovermgr: IPopoverManager;
    cmscontext: ICMSContext;
    rights: States.IAppUserRights;
    datasource: IEntityDataSource<any> | IDataSourceV3<any>;
    entityName: string;
    itemid: any;
    event: States.IEventState;
    customer: States.ICustomerState;
    i18n: States.i18nState;
    onhide: (res?) => void;
    getHeader?: (entity: any) => string;
    onFileChanged?: any;
    confirm: any;
    setConfirm: (confirm: any) => void;
    setDirtiness: (isDirty: boolean) => void;
    isDirty: boolean;
    setSaveEntity: (saveEntity: boolean) => void;
    saveEntity: any;
    hideFormField?: string[];
    canEdit?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    i18n_title?: string;
    customDetailComponent?: any;
    customFormComponent?: any;
    customActions?: any[];
    applyFor?: string[];
    user: States.IAppUserState;
    defaultEntityValue?: () => any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    tabs?: Function | IEntityDetailTabDefinitionProps[];
    languages?: string[];
    sendPatch?: boolean;
    fileInputManager?: IFileInputManager;
}

export interface IEntityDetailTabProps {
    popovermgr: IPopoverManager;
    cmscontext: ICMSContext;
    event: States.IEventState;
    customer: States.ICustomerState;
    i18n: States.i18nState;
    i18nHelper?: Entities.i18nHelper;
    user: States.IAppUserState;
    entity: any;
    entitytemplate: any;
    entityformtemplate: any;
    rights: States.IAppUserRights;
    datasource: IEntityDataSource<any> | IDataSourceV3<any>;
    entityName: string;
    itemid: any;
    canEdit?: boolean;
    customFormComponent?: any;
    customActions?: any[];
    applyFor?: string[];
    defaultEntityValue?: () => any;
    onhide: (res?) => void;
    isCreate: boolean;
    isRemove: boolean;
    updateEntity?: (entity) => void;
    languages?: string[];
    sendPatch?: boolean;
    fileInputManager?: IFileInputManager;
    onContentRefreshed?: () => void;
    conf: IEntityDetailConfiguration;
}

export interface IEntityDetailPortalProps {
    cmscontext?: ICMSContext;
    eventid?: string;
    entityid: string;
    entityName: string;
    datasource: IEntityDataSource<any> | IDataSourceV3<any>;
    getHeader?: (entity: any) => string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    i18n_title?: string;
    /**
     * Attention !
     * Le nom de la propriété doit être en minuscule
     */
    customFormComponent?: Record<string, any>;
    hideFormField?: string[];
    canEdit: boolean;
    applyFor?: string[];
    customDetailComponent?: any;
    defaultEntityValue?: () => any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    tabs?: Function | IEntityDetailTabDefinitionProps[];
    popovermgr: IPopoverManager;
    languages?: string[];
    customActions?: any[];
    sendPatch?: boolean;
    onCompleted?: (res?) => void;
    fileInputManager?: IFileInputManager;
}

export function openEntityDetailV3(
    popovermgr: IPopoverManager,
    cmscontext: ICMSContext,
    entityName: string,
    args: IConfigurationProviderArgs,
    isCreate: boolean,
    canEdit: boolean,
    isDuplicate: boolean,
    item: Entities.IEntity,
    queryOptions?: IInwinkEntityV3QueryOptions,
    isRemove?: boolean
) {
    return cmscontext.getEntityConfiguration(entityName,
        Object.assign({}, args, { customData: { item: item } } as IConfigurationProviderArgs))
        .then((entityconf) => {
            return entityconf.detailConfig.configuration().then((detailconf) => {
                return _openEntityDetailV3(
                    popovermgr,
                    cmscontext,
                    detailconf,
                    entityconf.datasource,
                    {
                        isCreate: isCreate,
                        canEdit: canEdit,
                        isDuplicate: isDuplicate,
                        queryOptions,
                        isRemove: isRemove
                    },
                    item
                );
            });
        });
}

import type { Entities } from '@inwink/entities/entities';
import { React, AppTextLabel } from '../../../commons';
import { ExpressionDesignerAddItem } from './expressiondesigner.add';
import { IExpressionDesignerItemProps, ExpressionDesignerItem } from './expressiondesigner.item';

export class ExpressionDesignerValueNotItem extends React.Component<IExpressionDesignerItemProps, any> {
    constructor(props: IExpressionDesignerItemProps) {
        super(props);
        this.addContent = this.addContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.removeContent = this.removeContent.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onItemChange(this.props.item);
        }
    }

    addContent(item: Entities.Expression) {
        this.props.item.not = item;
        this.onChange();
    }

    removeContent() {
        this.props.item.not = null;
        this.onChange();
    }

    removeItem() {
        if (this.props.onRemoveItem) {
            this.props.onRemoveItem(this.props.item);
        }
    }

    render() {
        let content;
        if (this.props.item.not) {
            content = <ExpressionDesignerItem
                {...this.props}
                onItemChange={this.onChange}
                onRemoveItem={this.removeContent}
                item={this.props.item.not}
            />;
        } else {
            content = <ExpressionDesignerAddItem
                {...this.props}
                isFirst={!this.props.expression.length}
                onAddExpression={this.addContent}
                onRemoveAll={this.removeItem}
            />;
        }

        return <div className="expressiondesigner-not">
            <div className="expressiondesigner-itemslist">
                <div className="expressiondesigner-itemwrapper">
                    <div className="expressiondesigner-itemwrappercontent">
                        {content}
                    </div>
                    <div className="border" />
                    <AppTextLabel component="div" className="separator" i18n="expressiondesigner.not" />
                </div>
            </div>
            <div className="link" />
        </div>;
    }
}

import { React, AppTextLabel } from '../../../../commons';
import type { IExpressionProps } from "../definitions";

export class ExpressionBool extends React.Component<IExpressionProps, any> {
    constructor(props: IExpressionProps) {
        super(props);
        const field = props.entityField;
        const canBeNull = field && field.metadata && field.metadata.canBeNull;
        const isNullable = canBeNull || (field && field.type.toLowerCase() === "nullablebool");
        let value = null;
        if (props.expressions && !(isNullable && props.expressions.val === null)) {
            if (props.expressions.val) {
                value = true;
            } else {
                value = (!(props.expressions.or || props.expressions.val === false));
            }
        }
        const state = {
            value: value,
            isNullable
        };

        if (props.expressions == null && isNullable) {
            state.value = undefined;
        }

        this.state = state;
    }

    componentDidUpdate(prevProps: IExpressionProps, prevState) {
        if (prevState.value
            && !this.props.expressions
        ) {
            this.setState((_prevState) => {
                return {
                    value: _prevState.isNullable ? undefined : null,
                };
            });
        }
    }

    componentDidMount() {
        const allowSave = !!(((this.state.value !== null && !this.state.isNullable)
            || (this.state.isNullable && this.state.value !== undefined)));
        if (allowSave !== this.props.allowSave) {
            this.props.setAllowSave(allowSave);
        }
        if (this.state.value === null && this.state.isNullable) {
            this.handleChange(null, "null");
        }
    }

    handleChange = (arg, override?) => {
        let value = null;
        const testVal = arg ? arg.target.value : override;
        switch (testVal) {
            case "true": value = true; break;
            case "false": value = false; break;
            case "null": value = null; break;
            default: break;
        }
        this.setState({ value }, () => {
            const allowSave = !!(((this.state.value !== null) || this.state.isNullable));
            if (allowSave !== this.props.allowSave) {
                this.props.setAllowSave(allowSave);
            }
            const key = this.props.fieldItem.key;
            let expression;
            if (!this.state.isNullable && (this.state.value === false || this.state.value === null)) {
                expression = {
                    or: [
                        {
                            name: key,
                            op: 'eq',
                            val: this.state.value
                        }, {
                            name: key,
                            op: 'eq',
                            val: null
                        }
                    ]
                };
            } else {
                expression = {
                    name: key,
                    op: 'eq',
                    val: this.state.value
                };
            }

            if (this.props.inline) {
                this.props.updateInline({ expression: expression });
            } else if (allowSave) {
                this.props.setExpression(expression);
            }
        });
    };

    renderInline = () => {
        return <div>
            <div className="customRadio-wrapper">
                <div className="customRadio">
                    <input
                        id="filterBool-true"
                        type="checkbox"
                        checked={this.state.value === true}
                        onChange={this.handleChange}
                        value="true"
                    />
                    <label className="label-radio" htmlFor="filterBool-true" />
                </div>
                <label className="label-visible" htmlFor="filterBool-true"><AppTextLabel i18n="filter.true" /></label>
            </div>
            <div className="customRadio-wrapper">
                <div className="customRadio">
                    <input
                        id="filterBool-false"
                        type="checkbox"
                        checked={this.state.value === false}
                        onChange={this.handleChange}
                        value="false"
                    />
                    <label className="label-radio" htmlFor="filterBool-false" />
                </div>
                <label className="label-visible" htmlFor="filterBool-false"><AppTextLabel i18n="filter.false" /></label>
            </div>
            {this.state.isNullable ? <div className="customRadio-wrapper">
                <div className="customRadio">
                    <input
                        id="filterBool-null"
                        type="checkbox"
                        checked={this.state.value === null}
                        onChange={this.handleChange}
                        value="null"
                    />
                    <label className="label-radio" htmlFor="filterBool-null" />
                </div>
                <label className="label-visible" htmlFor="filterBool-null"><AppTextLabel i18n="filter.null" /></label>
            </div> : null}
        </div>;
    };

    render() {
        if (this.props.inline) {
            return this.renderInline();
        }

        return <div className="filter-bool filter-type">
            <header>
                <span><AppTextLabel i18n="filter.indication" /></span>
            </header>
            <section>
                <div>
                    <div className="customRadio">
                        <input
                            id="filterBool-true"
                            type="checkbox"
                            checked={this.state.value === true}
                            onChange={this.handleChange}
                            value="true"
                        />
                        <label className="label-radio" htmlFor="filterBool-true" />
                    </div>
                    <label className="label-visible" htmlFor="filterBool-true"><AppTextLabel i18n="filter.true" /></label>
                </div>
                <div>
                    <div className="customRadio">
                        <input
                            id="filterBool-false"
                            type="checkbox"
                            checked={this.state.value === false}
                            onChange={this.handleChange}
                            value="false"
                        />
                        <label className="label-radio" htmlFor="filterBool-false" />
                    </div>
                    <label className="label-visible" htmlFor="filterBool-false"><AppTextLabel i18n="filter.false" /></label>
                </div>
                {this.state.isNullable ? <div>
                    <div className="customRadio">
                        <input
                            id="filterBool-null"
                            type="checkbox"
                            checked={this.state.value === null}
                            onChange={this.handleChange}
                            value="null"
                        />
                        <label className="label-radio" htmlFor="filterBool-null" />
                    </div>
                    <label className="label-visible" htmlFor="filterBool-null"><AppTextLabel i18n="filter.null.value" /></label>
                </div> : null}
            </section>
        </div>;
    }
}

import * as assign from 'lodash/assign';
import { States } from './services';

const INITIAL_STATE: States.IPagesState = {
    pageicon: null,
    featurecode: null,
    title: "",
    titlePrefix: null,
    breadcrumb: [],
    subtitle: null
};

export function pageReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "PAGE_SETTITLE": {
            return assign({}, state, action.payload);
        }
        case "PAGE_SETSUBTITLE": {
            return assign({}, state, action.payload);
        }
        case "PAGE_ADDBREADCRUMB": {
            const breadcrumb = [...state.breadcrumb];
            breadcrumb.push(action.payload.breadcrumb);
            console.log("Add breadcrumb", breadcrumb);
            return assign({}, state, {
                breadcrumb: breadcrumb
            });
        }
        case "PAGE_REMOVEBREADCRUMB": {
            const breadcrumb = [...state.breadcrumb];
            const removedbreadcrumb = breadcrumb.filter((b) => b.id === action.payload.id)[0];
            if (removedbreadcrumb) {
                const removedbreadcrumbIx = breadcrumb.indexOf(removedbreadcrumb);
                if (removedbreadcrumbIx > -1) {
                    breadcrumb.splice(removedbreadcrumbIx, 1);
                    console.log("Remove breadcrumb", breadcrumb);
                    return assign({}, state, {
                        breadcrumb: breadcrumb
                    });
                }
            }
            return state;
        }
        default:
            return state;
    }
}

export const pageActions = {
    setTitle(title: string, titlePrefix: string, featurecode: string, pageicon: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({ type: "PAGE_SETTITLE",
                payload: {
                    title: title,
                    titlePrefix: titlePrefix,
                    featurecode: featurecode,
                    pageicon: pageicon
                } });
        };
    },
    addBreadCrumb(breadcrumb: States.IBreadcrumbWrapper) {
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({ type: "PAGE_ADDBREADCRUMB", payload: { breadcrumb: breadcrumb } });
            return getState().pages.breadcrumb.length;
        };
    },
    removeBreadCrumb(id: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({ type: "PAGE_REMOVEBREADCRUMB", payload: { id } });
        };
    },
    setSubtitle(subtitle: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({ type: "PAGE_SETSUBTITLE", payload: { subtitle: subtitle } });
        };
    }
};

export const availableFilters = ([
    'Mail',
    'Text',
    'MultiLineText',
    'String',
    'NullableBool',
    'Bool',
    'File',
    'Picture',
    'Image',
    'MultiSelectList',
    'SelectList',
    'Number',
    'Date',
    'Entities',
    'Entity',
    'Guid',
    'Array'
] as any);

import { logging } from '@inwink/logging';
import { sessionStoragePersistence } from '@inwink/logging/logging/persistence.sessionstorage';

import { createBrowserHistory } from 'history';

import { oidcmgr } from '@inwink/apibase/auth/oidc-manager';
import { impl } from '@inwink/apibase/auth/oidc-manager-browser';
import { appStartup } from './client';
import { initNotifications } from './services/notificationservice';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string;
const inwink = (global as any).inwink || { config: null };
if (inwink && inwink.config && inwink.config.assetsUrl) {
    let assetsPath = inwink.config.assetsUrl;
    if (!(assetsPath[assetsPath.length - 1] === "/")) {
        assetsPath = assetsPath + "/";
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __webpack_public_path__ = assetsPath;
}
logging.level = logging.Levels.warn;
logging.setPersistence(sessionStoragePersistence);
logging.addAppender("Console");

oidcmgr.__impl = impl;

const initialState = {};

const usedHistory = createBrowserHistory();
appStartup(inwink.config, usedHistory, initialState);

initNotifications(inwink);

(inwink as any).logging = logging;

import type { IAsset } from '@inwink/react-utils/loadassets';

declare const Slick;

export function getGridAssets(env: string) {
    // pour le dev ou le debug, on peut mettre ça a true pour charger la version non minifiée
    const loadUnminified = env === "dev" || env === "sdb" || env === "stg" || env === "local";

    const suffix = loadUnminified ? '' : '-min';
    const path = loadUnminified ? '' : 'dist/';
    const slickVersion = '2.4.14';
    const gridAssets: IAsset[] = [
        {
            url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}slick.core${suffix}.js`,
            type: `js`,
            readyCheck: () => !!Slick,
            childs: [{
                url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}slick.grid${suffix}.js`,
                type: `js`,
                readyCheck: () => !!Slick && !!Slick.Grid,
                childs: [
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.autotooltips${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.cellcopymanager${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.cellexternalcopymanager${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.cellrangedecorator${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.cellrangeselector${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.cellselectionmodel${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.checkboxselectcolumn${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.headerbuttons${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.headermenu${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}plugins/slick.rowselectionmodel${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}slick.formatters${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}slick.editors${suffix}.js`, type: `js` },
                    { url: `${(inwink.config.assetsUrl || "/")}assets/scripts/slickgrid${slickVersion}/${path}slick.dataview${suffix}.js`, type: `js` },
                ]
            }]
        },
        { url: `${(inwink.config.assetsUrl || "/")}assets/styles/slickgrid${slickVersion}/react-slick.css`, type: `css` },
        { url: `${(inwink.config.assetsUrl || "/")}assets/styles/slickgrid${slickVersion}/slick.grid.css`, type: `css` },
        { url: `${(inwink.config.assetsUrl || "/")}assets/styles/slickgrid${slickVersion}/slick-default-theme.css`, type: `css` },
        { url: `${(inwink.config.assetsUrl || "/")}assets/styles/slickgrid${slickVersion}/slick.headerbuttons.css`, type: `css` },
        { url: `${(inwink.config.assetsUrl || "/")}assets/styles/slickgrid${slickVersion}/slick.headermenu.css`, type: `css` },

    ];

    return gridAssets;
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, AppTextLabel } from '../../../../commons';
import type { IExpressionProps } from "../definitions";

export class ExpressionGuid extends React.Component<IExpressionProps, any> {
    constructor(props: IExpressionProps) {
        super(props);
        let value = null;
        if (props.expressions && props.expressions.op) {
            value = props.expressions.op === 'isnotnull' ? 'true' : 'false';
        }
        this.state = {
            value: value
        };
    }

    componentDidUpdate(prevProps: IExpressionProps) {
        if ((!this.props.expressions && !this.props.inline)
            || (this.props.inline && prevProps.fieldItem.key !== this.props.fieldItem.key)) {
            this.setState({
                value: null,
            });
        }
    }

    componentDidMount() {
        const allowSave = (this.state.value !== null);
        if (allowSave !== this.props.allowSave) {
            this.props.setAllowSave(allowSave);
        }
    }

    handleChange = (arg) => {
        this.setState({ value: arg.target.value }, () => {
            const allowSave = (this.state.value !== null);
            if (allowSave !== this.props.allowSave) {
                this.props.setAllowSave(allowSave);
            }
            if (this.props.inline) {
                this.props.updateInline({
                    operator: this.state.value.toString() === 'true' ? 'isnotnull' : 'isnull',
                    propvalue: ''
                });
            } else if (allowSave) {
                this.props.setExpression({
                    name: this.props.fieldItem.key,
                    op: this.state.value.toString() === 'true' ? 'isnotnull' : 'isnull',
                    val: ''
                });
            }
        });
    };

    renderInline = () => {
        return <div>
            <div className="customRadio-wrapper">
                <div className="customRadio">
                    <input
                        id="filterBool-true"
                        type="checkbox"
                        checked={this.state.value === 'true'}
                        onChange={this.handleChange}
                        value="true"
                    />
                    <label className="label-radio" htmlFor="filterBool-true" />
                </div>
                <label className="label-visible" htmlFor="filterBool-true"><AppTextLabel i18n="filter.true" /></label>
            </div>
            <div className="customRadio-wrapper">
                <div className="customRadio">
                    <input
                        id="filterBool-false"
                        type="checkbox"
                        checked={this.state.value === 'false'}
                        onChange={this.handleChange}
                        value="false"
                    />
                    <label className="label-radio" htmlFor="filterBool-false" />
                </div>
                <label className="label-visible" htmlFor="filterBool-false"><AppTextLabel i18n="filter.false" /></label>
            </div>
        </div>;
    };

    render() {
        if (this.props.inline) {
            return this.renderInline();
        }

        return <div className="filter-bool filter-type">
            <header>
                <span><AppTextLabel i18n="filter.indication" /></span>
            </header>
            <section>
                <div>
                    <div className="customRadio">
                        <input
                            id="filterBool-true"
                            type="checkbox"
                            checked={this.state.value === 'true'}
                            onChange={this.handleChange}
                            value="true"
                        />
                        <label className="label-radio" htmlFor="filterBool-true" />
                    </div>
                    <label className="label-visible" htmlFor="filterBool-true"><AppTextLabel i18n="filter.true" /></label>
                </div>
                <div>
                    <div className="customRadio">
                        <input
                            id="filterBool-false"
                            type="checkbox"
                            checked={this.state.value === 'false'}
                            onChange={this.handleChange}
                            value="false"
                        />
                        <label className="label-radio" htmlFor="filterBool-false" />
                    </div>
                    <label className="label-visible" htmlFor="filterBool-false"><AppTextLabel i18n="filter.false" /></label>
                </div>
            </section>
        </div>;
    }
}

import { buildExpression } from '@inwink/expressions/expressionbuilder';
import { toastError } from '../../../../commons';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/base/datasource';
import type { EntityGrid, IEntityGridProps } from './index';

export class EntityGridData {
    currentFetch: Promise<any> = Promise.resolve();

    constructor(public entitygrid: EntityGrid) {
    }

    setGridListeners() {
        this.entitygrid.gridData.onRowCountChanged.subscribe(() => {
            this.entitygrid.grid.updateRowCount();
            this.entitygrid.grid.render();
        });

        this.entitygrid.gridData.onRowsChanged.subscribe((e, args) => {
            this.entitygrid.grid.invalidateRows(args.rows);
            this.entitygrid.grid.render();
        });

        this.entitygrid.grid.onSort.subscribe((e, args) => {
            this.entitygrid.props.viewsManager.updateCurrentViewContent({ order: { by: args.sortCol.id, desc: !args.sortAsc } });
            // this.entitygrid.props.orderChanged({ by: args.sortCol.id, desc: !args.sortAsc });
        });
    }

    getExpand: () => string[] = () => {
        const gridexpands = [];
        const props = this.entitygrid.props;

        if (props.expands) {
            gridexpands.push(...props.expands);
        }

        if (this.entitygrid.columnsmgr.displayColumns) {
            this.entitygrid.columnsmgr.displayColumns.forEach((column) => {
                if (column && column.id && !column.isTechnical) {
                    if (column.expands && column.expands.length) {
                        gridexpands.push(...column.expands);
                    } else {
                        // I've put this code in an ELSE to avoid pushing entity field like "exhibitor"
                        // which are not readable by the API (exhibitor.$all, exhibitor.name etc...)
                        const gridKey = props.entityconfiguration?.expands?.grid
                            ? Object.keys(props.entityconfiguration.expands.grid)
                                .filter((key) => key.toLowerCase() === column.id.toLowerCase())[0] : null;
                        if (gridKey) {
                            const expand = props.entityconfiguration.expands.grid[gridKey];
                            if (Array.isArray(expand)) {
                                expand.forEach((e) => gridexpands.push(e));
                            } else {
                                gridexpands.push(expand);
                            }
                        } else {
                            gridexpands.push(column.id);
                        }
                    }
                }
            });
        }
        return gridexpands;
    };

    initData = (props: IEntityGridProps) => {
        const viewContent = props.viewsState && props.viewsState.currentview && props.viewsState.currentview.content;
        const options = this.buildFetchDataOptions(this.entitygrid.state.pageSize,
            props.viewsState.queryExpression, viewContent && viewContent.order);
        return this.fetchData(options, true).then((res: any) => {
            if (this.entitygrid.unmounted) {
                return;
            }

            let rows = [];
            let totalCount = 0;
            if (res) {
                totalCount = res.totalCount;
                rows = this.removeDouble(res.page || res.data);
            }
            this.entitygrid.props.viewsManager.setRowCount(totalCount);
            this.setGridRows(rows, totalCount, { isFetching: false, offset: 0, selection: props.viewsState.selection });
            // this needs to be @ the end or the grid will mess up selected rows
            this.entitygrid.props.helpers.setLoadingState(false);
        }).catch((err) => {
            console.error("fetch data error", err);
            this.setGridRows([], 0, { isFetching: false, offset: 0 });
        });
        // pourquoi rappeler loadGridScript ? On l'a déjà fait dans le parent...
        /* return this.entitygrid.loadGridScriptsPromise.then(() => {
            // this.entitygrid.initGrid();
            let options = this.buildFetchDataOptions(this.entitygrid.state.pageSize, props.expression, props.order, true);

            return this.fetchData(options).then((res: any) => {
                if (this.entitygrid.unmounted) {
                    return;
                }

                let rows = [];
                let totalCount = 0;
                if (res) {
                    totalCount = res.totalCount;
                    rows = this.removeDouble(res.page || res.data);
                }
                this.entitygrid.props.onRowCountChanged(totalCount);
                this.setGridRows(rows, totalCount, { isFetching: false, offset: 0, selection: props.selection });
                // this needs to be @ the end or the grid will mess up selected rows
                this.entitygrid.props.updateGrid(false);
            });
        }).then(null, (err) => {
            console.error("fetch data error", err);
            this.setGridRows([], 0, { isFetching: false, offset: 0 });
        }); */
    };

    refreshGrid = (_props: IEntityGridProps) => {
        const props = _props || this.entitygrid.props;
        if (this.entitygrid.unmounted) {
            return;
        }

        if (!this.entitygrid.grid) {
            return;
        }

        this.entitygrid.setState({ isFetchingFilter: true });
        const viewContent = this.entitygrid.props.viewsState && this.entitygrid.props.viewsState.currentview
            && this.entitygrid.props.viewsState.currentview.content;

        if (viewContent && viewContent.order && viewContent.order.by) {
            this.entitygrid.grid.setSortColumn(viewContent.order.by, !viewContent.order.desc);
        } else {
            this.entitygrid.grid.setSortColumn(null, null);
        }

        const order = viewContent && viewContent.order;

        if (props.viewsState.showSelection === true && props.viewsState.selection.length) {
            this.setGridRows(props.viewsState.selection, props.viewsState.selection.length,
                {
                    isFetchingFilter: false,
                    offset: 0,
                    selection: props.viewsState.selection,
                });
        } else {
            const options = this.buildFetchDataOptions(this.entitygrid.state.pageSize, props.viewsState.queryExpression, order);
            this.fetchData(options, true).then((res: any) => {
                if (this.entitygrid.unmounted) { return; }
                if (res) {
                    const newRows = res.page || res.data; // this.entitygrid.formatmgr.formatRows(res.page || res.data);

                    if (props.viewsState.selection.length) {
                        // need to regenerate selections data in case it has changed
                        this.entitygrid.props.entityconfiguration.datasource.query({
                            filters: buildExpression().And([(e) => e.WithString("id")
                                .In(props.viewsState.selection.map((v) => v.id))]).AsFilter(),
                            selects: { $all: true }
                        }).then((_res) => {
                            props.viewsManager.setSelection(_res.data);
                            this.setGridRows(newRows, res.totalCount, {
                                isFetchingFilter: false,
                                offset: 0,
                                selection: _res.data,
                            });
                        });
                    } else {
                        this.setGridRows(newRows, res.totalCount,
                            {
                                isFetchingFilter: false,
                                offset: 0,
                                selection: props.viewsState.selection,
                                showSelection: false // we're generating a new dataset so it's easier to force back to normal view
                            });
                    }
                    this.entitygrid.props.viewsManager.setRowCount(res.totalCount);
                }
            }, () => {
                this.setGridRows([], 0, { isFetchingFilter: false, offset: 0 });
            });
        }
    };

    setGridRows(rows, totalcount, patch) {
        const { grid, gridData } = this.entitygrid;
        if (gridData) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const statePatch = Object.assign({}, patch, { count_rows: totalcount, rows: rows });
            this.entitygrid.setState(statePatch, () => {
                gridData.setItems(rows);
                let selectedRows = [];
                if (patch.selection && patch.selection.length) {
                    rows.forEach((v, i) => {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const _i in patch.selection) {
                            if (v.id && patch.selection[_i].id && v.id === patch.selection[_i].id) {
                                selectedRows.push(i);
                                // eslint-disable-next-line no-continue
                                continue;
                            }
                        }
                    });
                }

                // solve bug in grid render
                if (!selectedRows.length) { selectedRows = [-1]; }
                // Ceci va déclencher le subscribe onSelectedRowsChanged -> j'aurai désactivé le callback quand toggleShowSelected
                this.entitygrid.toggleEnableSelectionSub(false);
                grid.setSelectedRows(selectedRows);
                grid.invalidate();
                grid.render();

                this.entitygrid.toggleEnableSelectionSub(true);
            });
        } else {
            // eslint-disable-next-line no-debugger
            debugger; // Anormal, à identifier
        }
    }

    buildFetchDataOptions = (pageSize, _expression, _order): IInwinkEntityV3QueryOptions => {
        let order = _order;
        let expression = _expression;
        if (!order || !order.by) {
            order = null;
            if (this.entitygrid.props.entityconfiguration.defaultOrderBy) {
                order = this.entitygrid.props.entityconfiguration.defaultOrderBy();
            }
        } else {
            order = [order];
        }

        if (this.entitygrid.props.expression) {
            if (expression) {
                expression = { and: [expression, this.entitygrid.props.expression] };
            } else {
                expression = this.entitygrid.props.expression;
            }
        }

        return {
            page: {
                index: 0,
                size: pageSize,
                // returnTotalCount: totalCount
            },
            // totalCount: totalCount,
            // search: this.entitygrid.props.searchterm,
            expression: expression,
            order: order,
            queryString: this.entitygrid.props.entityconfiguration?.datasource?.options?.query?.queryString
        };
    };

    removeDouble = (rows) => {
        const distinctRows = [];
        rows.forEach((row, idx) => {
            const existing = distinctRows.filter((drow) => drow.id === row.id)[0];
            if (!existing) {
                distinctRows.push(row);
            } else {
                const existingidx = distinctRows.indexOf(existing);
                console.warn("duplicate row for " + existing.id, existing, rows[idx]);
                distinctRows[existingidx] = rows[idx];
            }
        });

        return distinctRows;
    };

    fetchData = (fetchOptions, totalCount: boolean) => {
        this.entitygrid.props.helpers.setLoadingState(true);

        const promise = this.currentFetch.then(() => {
            const fetchCountOptions = Object.assign({}, fetchOptions, { page: undefined, order: undefined });
            const promises: Array<Promise<any>> = [];
            promises.push(this.entitygrid.props.entityconfiguration.datasource.query(fetchOptions, this.getExpand()));
            if (totalCount) {
                promises.unshift(this.entitygrid.props.entityconfiguration.datasource.count(fetchCountOptions));
            }
            return Promise.all(promises).then((res) => {
                const data = { data: null, totalCount: null };
                if (totalCount) {
                    data.totalCount = res[0];
                    data.data = res[1].data;
                } else {
                    data.data = res[0].data;
                }

                this.entitygrid.faildTime = 0;
                let items = data.data;

                if (items && this.entitygrid.props.entityconfiguration.idFields
                    && this.entitygrid.props.entityconfiguration.idFields.length > 1) {
                    items = items.map((_item) => {
                        const item = _item;
                        if (!item.id) {
                            let fakeId = '';
                            this.entitygrid.props.entityconfiguration.idFields.forEach((idField) => {
                                fakeId += item[idField];
                            });
                            item.id = fakeId; // hack car cette entité n'a pas d'id unique et slick grid en a besoin
                        }

                        return item;
                    });
                }

                if (
                    (items && !items.length)
                    || (items && items.length && items.length < fetchOptions.page.size)
                ) {
                    this.entitygrid.noMoreRowToLoad = true;
                }
                this.entitygrid.noMoreRowToLoad = (!(items && items.length));
                this.entitygrid.props.helpers.setLoadingState(false);
                return data;
            }).catch((e) => {
                console.error(e);
                toastError(this.entitygrid.props.i18n, 'entity.failed_fetch');
                return Promise.reject(e);
            });
        });

        const fetchPromise = promise.then(() => null, () => null).then(() => {
            if (this.currentFetch === fetchPromise) {
                this.currentFetch = Promise.resolve(); // cleanup promise stack
            }
        });
        this.currentFetch = fetchPromise;
        return promise;
    };

    fetchNextPage = () => {
        const { offset, rows } = this.entitygrid.state;

        const newOffset = offset + 1;
        if (!this.entitygrid.fetching) {
            this.entitygrid.fetching = true;
            this.entitygrid.props.setTinyLoader(true);
            const viewContent = this.entitygrid.props.viewsState?.currentview?.content;
            const fetchOptions = this.buildFetchDataOptions(this.entitygrid.state.pageSize,
                this.entitygrid.props.viewsState.queryExpression, viewContent && viewContent.order);
            fetchOptions.page.index = newOffset;
            const promise = this.fetchData(fetchOptions, false).then((res: any) => {
                if (this.entitygrid.unmounted) { return; }
                if (res) {
                    const newdata = res.page || res.data;
                    let newRows = [...rows].concat(newdata); // [...rows].concat(this.entitygrid.formatmgr.formatRows(newdata));
                    newRows = this.removeDouble(newRows);
                    return new Promise((resolve) => {
                        this.entitygrid.setState({ rows: newRows, offset: newOffset }, () => {
                            this.entitygrid.props.setTinyLoader(false);
                            this.setGridRows(newRows, this.entitygrid.state.count_rows,
                                { selection: this.entitygrid.props.viewsState && this.entitygrid.props.viewsState.selection });
                            this.entitygrid.fetching = false;
                            resolve();
                        });
                    });
                }
            }, (err) => {
                this.entitygrid.fetching = false;
                this.entitygrid.props.setTinyLoader(false);
                console.error("error fetching data", err);
            });

            const fetchPromise = promise.then(() => null, () => null).then(() => {
                if (this.currentFetch === fetchPromise) {
                    this.currentFetch = Promise.resolve(); // cleanup promise stack
                }
            });
            this.currentFetch = fetchPromise;

            return promise;
        }
    };

    updateEntity = (entity,) => {
        if (entity) {
            const id = entity.id;
            const options = this.buildFetchDataOptions(1, null, null);
            this.entitygrid.props.entityconfiguration.datasource.get(id, options, null, this.getExpand()).then((dataitem) => {
                const data = this.entitygrid.gridData;
                data.deleteItem(id);
                if (dataitem) {
                    const oldIdx = data.getIdxById(id);
                    data.insertItem(oldIdx, dataitem);
                }
                this.entitygrid.grid.invalidate();
                this.entitygrid.grid.render();
            }, (err) => {
                console.error(err);
            });
        }
    };
}

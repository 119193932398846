import { React, AppLabel } from '../../../commons';
import { ThemedModalContext, IThemedModalContext, IThemedModalPartProps } from '@inwink/modals-theme';

import './bothemedmodal.less';

export interface IBoThemedModalProps {

}
interface IBoThemedModalState {
    themedmodal: IThemedModalContext;
}
export class BoThemedModal extends React.Component<IBoThemedModalProps, any> {
    constructor(props:IBoThemedModalProps) {
        super(props);
        
        let theme: IThemedModalContext = {
            name: "default",
            theme: {
                modalClassName: "bothemedmodal",
                modalHeaderWrapper: (headerprops: IThemedModalPartProps) => {
                    return <div className="bothemeheader">
                        <div className="bothemeheader-title">
                            {(headerprops as any).children}
                        </div>
                        <button className="btnclose" onClick={(arg) => {
                            arg.preventDefault();
                            if (headerprops.dismissmgr) {
                                headerprops.dismissmgr.close();
                            }
                        }}>
                            <i className="inwink-dialog-cancel"></i>
                        </button>
                    </div>;
                }
            } as any
        };

        this.state = {
            themedmodal: theme
        };
    }

    render() {
        return <ThemedModalContext.Provider value={this.state.themedmodal}>{ this.props.children}</ThemedModalContext.Provider>;
    }
}

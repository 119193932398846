import { parse } from '@inwink/utils/querystring';
import { React, bindActionCreators, connectwith, LoadingState } from '../../commons';
import { notificationsActions, getLocation } from '../../services/notificationservice';
import { AppPage } from '../../components/apppage';
import { IDefaultPageProps } from '../../data/entities';

import './opencontent.less';

export interface IOpenContentPageProps extends IDefaultPageProps {
    notificationsActions?: typeof notificationsActions;
}

@connectwith(null, (dispatch) => ({ notificationsActions: bindActionCreators(notificationsActions, dispatch) }))
export class OpenContentPage extends React.Component<IOpenContentPageProps, any> {
    componentDidMount() {
        if (this.props.location && this.props.location.search) {
            const query = parse(this.props.location.search);
            if (query.contentkind) {
                const kind = query.contentkind.toLowerCase();
                const customerid = query.customerid.toLowerCase();
                const authtenantid = query.authtenantid?.toLowerCase();
                const eventid = query.eventid?.toLowerCase();
                const communityid = query.communityid?.toLwerCase();

                const newlocation = getLocation(customerid, authtenantid, eventid, communityid,
                    kind, query.contentid, query.parentid);

                inwink.history.replace(newlocation);

                if (query.notifid) {
                    this.props.notificationsActions.dismiss(query.notifid);
                }
                if (query.mailid) {
                    this.props.notificationsActions.dismissForMail(query.mailid);
                }
            }
        }
    }

    render() {
        return <LoadingState isLoading={true}>
            <AppPage
                title="page.opencontent.title"
                className="opencontentpage formpage"
                i18n={this.props.i18n}
                noPadding={true}
            />
        </LoadingState>;
    }
}

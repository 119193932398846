/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import type { Entities } from '@inwink/entities/entities';
import * as cloneDeep from 'lodash/cloneDeep';
import { React, AppTextLabel, Loader } from '../../../commons';
import type { ICMSContext } from '../../cmscontext';
import type { IEntityPageConfiguration } from '../entitypagev3/entitypagebase.props';
import { ExpressionDesignerItemsList } from './expressiondesigner.list';
import { IExpressionDesignerOptions } from './definitions';
import type { IEntityGroupDefinition } from '../definitions';
import type { IEntityDetailConfiguration } from '../entitydetailv3';
import type { States } from '../../../services/services';

import './expressiondesigner.less';

export interface IExpressionDesignerProps {
    cmscontext: ICMSContext;
    label?: string;
    options?: IExpressionDesignerOptions;
    disabledSortOnGroup?: boolean;
    expression: Entities.Expression[];
    onChange: (expression: Entities.Expression[]) => void;
    definitions?: IEntityGroupDefinition[];
    groups?: Record<string, IEntityGroupDefinition>;
    targetEntityName?: string;
    targetEntityParent?: string;
    isMailTrigger?: boolean;
    isFieldEditor?: boolean;
    addEntityName?: boolean;
    // use entityRequirements ONLY to avoid show of <Loader /> - otherwise it'll be more problems than solution
    entityRequirements?: {
        // entityConf: IEntityPageConfiguration;
        entityDetailConfiguration: IEntityDetailConfiguration;
        worktemplate: Entities.IEntityTemplateV3;
    };
    isReadOnly?: boolean;
    user?: States.IAppUserState;
    // fieldFilter?: fieldFilterCallback; // not used but should - or used in uder components ?
}

interface IExpressionDesignerState {
    isFetching: boolean;
    groups: Record<string, IEntityGroupDefinition>;
    entityConf: IEntityPageConfiguration;
    entityDetailConfiguration?: IEntityDetailConfiguration;
    expression: Entities.Expression[];
}
export class ExpressionDesigner extends React.Component<IExpressionDesignerProps, IExpressionDesignerState> {
    constructor(props: IExpressionDesignerProps) {
        super(props);
        this.onChange = this.onChange.bind(this);

        const groups = cloneDeep(props.groups);
        if (groups && Object.keys(groups).length > 0 && (props.options?.filterEntityIESNotemplate !== true)) {
            for (const keyGroup in groups) {
                if (groups.hasOwnProperty(keyGroup)) {
                    const template = groups[keyGroup]?.template;
                    if (template?.fields) {
                        for (const keyField in template.fields) {
                            if (template.fields.hasOwnProperty(keyField)) {
                                if (template.fields[keyField]?.template) {
                                    delete template.fields[keyField];
                                }
                            }
                        }
                    }
                }
            }
        }

        this.state = {
            isFetching: !this.props.entityRequirements,
            groups: groups || null,
            entityConf: null,
            expression: props.expression ? JSON.parse(JSON.stringify(props.expression)) : []
        };
    }

    componentDidMount() {
        this.fetchDetail();
    }

    componentDidUpdate(prevProps) {
        if (this.props.targetEntityName && prevProps.targetEntityName !== this.props.targetEntityName) {
            this.setState({ isFetching: true }, this.fetchDetailConf);
        }

        if (prevProps.groups !== this.props.groups && this.props.groups && Object.keys(this.props.groups).length > 0) {
            if (this.props.targetEntityName) {
                this.setState({ isFetching: true }, () => { this.fetchDetail(this.props.groups); });
            } else {
                this.fetchDetail(this.props.groups);
            }
        }

        if (prevProps.expression !== this.props.expression) {
            this.setState({
                expression: this.props.expression ? JSON.parse(JSON.stringify(this.props.expression)) : []
            });
        }
    }

    // when props.targetEntityName -> fetch entityConf, worktemplate, detailConfiguration
    fetchDetail = (updatedGroup?: Record<string, IEntityGroupDefinition>) => {
        if (this.props.targetEntityName && !this.props.entityRequirements) {
            this.props.cmscontext.getEntityConfiguration(this.props.targetEntityName, {
                cmscontext: this.props.cmscontext,
                user: this.props.user,
                rights: null,
                visualstate: null,
                i18n: null
            }).then((entityConf) => {
                this.setState({ entityConf }, () => {
                    Promise.all([
                        entityConf.datasource.worktemplate(),
                        entityConf.detailConfig.configuration()
                    ])
                        .then((res) => {
                            if (!this.state.groups) {
                                const groups = {
                                    entity: {
                                        entityName: this.props.targetEntityName,
                                        template: res[0]
                                    }
                                };
                                this.setState({ groups }, () => this.filterGroups(groups, res[1]));
                            } else {
                                this.filterGroups(this.state.groups, res[1]);
                            }
                        });
                });
            });
        } else if (this.props.entityRequirements) {
            if (!this.state.groups) {
                const groups = {
                    entity: {
                        entityName: this.props.targetEntityName,
                        template: this.props.entityRequirements.worktemplate
                    }
                };
                this.setState({ groups },
                    () => this.filterGroups(groups, this.props.entityRequirements.entityDetailConfiguration));
            } else {
                this.filterGroups(this.state.groups, this.props.entityRequirements.entityDetailConfiguration);
            }
        } else if (this.state.groups) {
            this.filterGroups(this.state.groups, null);
        } else if (updatedGroup) {
            this.filterGroups(updatedGroup, null);
        }
    };

    // load configuration && detailConf to trigger filterGroups
    fetchDetailConf = () => {
        if (this.state.groups?.entity?.template?.fields) {
            this.props.cmscontext.getEntityConfiguration(this.props.targetEntityName, {
                cmscontext: this.props.cmscontext,
                user: null,
                rights: null,
                visualstate: null,
                i18n: null
            }).then((entityConf) => {
                entityConf.detailConfig.configuration()
                    .then((detailConf) => {
                        this.filterGroups(this.state.groups, detailConf);
                    });
            });
        }
    };

    filterFields = (fields, detailConf, entityName) => {
        const _fields = { ...fields };
        if (this.props.definitions) {
            for (const d of this.props.definitions) {
                if (d.key.toLowerCase() === entityName) {
                    for (const _f in d.template.fields) {
                        if (d.template.fields[_f] && !fields.hasOwnProperty(d.template.fields[_f].key)) {
                            _fields[d.template.fields[_f].key] = d.template.fields[_f];
                        }
                    }
                }
            }
        }
        for (const field in fields) {
            if (fields.hasOwnProperty(field)) {
                if (!this.props.isMailTrigger) {
                    // if (detailConf && detailConf.create && detailConf.create.hideFields) {
                    //     const hiddenFields = detailConf.create.hideFields({ cmscontext: this.props.cmscontext });
                    //     if (entityGroup.template.fields[field] && hiddenFields.indexOf(field.toLowerCase()) >= 0) {
                    //         delete fields[field];
                    //     }
                    // }
                }
                if (this.props.options) {
                    if (detailConf?.companionHiddenFormFields) {
                        if (this.props.options.filterFormComponents) {
                            const companionHiddenFormFields = detailConf
                                .companionHiddenFormFields({ cmscontext: this.props.cmscontext }) || [];
                            if (fields[field] && companionHiddenFormFields.indexOf(field.toLowerCase()) >= 0) {
                                delete _fields[field];
                            }
                        }
                    }

                    if (Object.prototype.hasOwnProperty.call(this.props.options, 'forceKey') === false
                        || this.props.options.forceKey.indexOf(field) < 0
                    ) {
                        if (this.props.options.filterGuid) {
                            if (fields[field].type === "Guid") { delete _fields[field]; }
                        }

                        if (this.props.options.filterEntityIES) {
                            if (["Entities", "Entity"].indexOf(fields[field].type) >= 0) { delete _fields[field]; }
                        }

                        if (this.props.options.filterEntityIESNotemplate) {
                            if (["Entities", "Entity"].indexOf(fields[field].type) >= 0
                                && !fields[field].template) { delete _fields[field]; }
                        }

                        if (this.props.options.filterFieldType && this.props.options.filterFieldType.length) {
                            if (this.props.options.filterFieldType.indexOf(fields[field].type) >= 0) { delete _fields[field]; }
                        }

                        if (fields[field].metadata && fields[field].metadata.isTechnical) {
                            if (this.props.options.hasOwnProperty('filterTechnical')
                                && this.props.options.filterTechnical !== true) {
                                // do nothing
                            } else {
                                delete _fields[field];
                            }
                        }

                        if (fields[field].metadata && fields[field].metadata.isLocalizable) {
                            if (this.props.options.hasOwnProperty('filterLocalizable')
                                && this.props.options.filterLocalizable !== true) {
                                // do nothing
                            } else {
                                delete _fields[field];
                            }
                        }
                    }
                }

                // un peu redondant mais ça permet de garder une cohérence dans le nom des options
                if ((this.props.options && this.props.options.filterTechnical === true)
                    && fields[field].metadata && fields[field].metadata.isTechnical
                ) {
                    if (Object.prototype.hasOwnProperty.call(this.props.options, 'forceKey') === false
                        || this.props.options.forceKey.indexOf(field) < 0
                    ) {
                        delete _fields[field];
                    }
                }

                if (_fields[field] && _fields[field].template) {
                    _fields[field].template.fields = this.filterFields(_fields[field].template.fields, detailConf, null);
                }
            }
        }

        return _fields;
    };

    filterGroups = (groups, detailConf) => {
        for (const key in groups) {
            if (groups.hasOwnProperty(key)) {
                const entityGroup = groups[key];
                entityGroup.template.fields = this.filterFields(
                    entityGroup.template.fields, detailConf, entityGroup?.entityName?.toLowerCase()
                );
            }
        }

        this.setState({ groups, isFetching: false });
    };

    onChange(expressions: Entities.Expression[]) {
        this.setState({ expression: expressions });
        if (expressions && !expressions.length) {
            this.props.onChange(null);
        } else {
            this.props.onChange(expressions);
        }
    }

    render() {
        if (this.state.isFetching) { return <Loader />; }
        let mainlabel;
        if (this.props.label) {
            mainlabel = <AppTextLabel component="div" className="mainlabel" i18n={this.props.label} />;
        }
        return <div className="expressiondesignerv3 expressiondesigner-and">
            {mainlabel}
            <ExpressionDesignerItemsList
                {...this.props}
                groups={this.state.groups}
                expression={this.state.expression}
                separator="and"
                onChange={this.onChange}
                entityconfig={this.state.entityConf}
            />
            <div className="clear" />
        </div>;
    }
}

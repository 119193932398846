import { React, Loader} from '../../commons';
import { OnBoardingDefinitions } from './definition';
import { StepState} from './stepstate';
import { position as calcPosition } from '@inwink/modals/popover.positioning';
import { DynLabel, AppTextLabel } from '@inwink/i18n';
import { Entities } from '@inwink/entities/entities';

import "./onboarding.less";
import { syncLanguage } from 'services/i18ndata';

export interface IOnBoardingStepProps {
    i18nHelper: Entities.i18nHelper;
    config: OnBoardingDefinitions.OnBoardingConfiguration;
    currentStep: OnBoardingDefinitions.OnBoardingStep;
    currentStepNumber: number;
    stepstate: StepState;
    loading: boolean;
    onNext: () => void;
    onPrev: () => void;
    onDismiss: () => void;
}

export interface IOnBoardingStepState {
    position: any;
}

export class OnBoardingStep extends React.Component<IOnBoardingStepProps, IOnBoardingStepState> {
    detailpanel = React.createRef<HTMLDivElement>();
    state = {
        position : null
    };

    componentDidMount() {
        this.checkPosition();
    }
    componentDidUpdate() {
        this.checkPosition();
    }

    checkPosition() {
        if (this.props.stepstate) {
            if (this.props.stepstate.defaultElement && this.props.stepstate.defaultElement.rects && this.props.stepstate.defaultElement.rects.length) {
                let elt = this.props.stepstate.defaultElement;
                // var rect= this.detailpanel.current.getBoundingClientRect();
                let position = calcPosition(this.detailpanel.current, elt.rects[0] as any, {
                    borderOffset : 8,
                    offset: [(elt.element.offset || 0) + 8, (elt.element.offset || 0) + 8],
                    placement: ['top', 'center'],
                    targetPlacement: ['bottom', 'center']
                }, true);

                if (!this.state.position || !position || position.left !== this.state.position.left || position.top !== this.state.position.top) {
                    this.setState({ position : position });
                }
            } else {
                let position = {
                    left: (window.innerWidth - this.detailpanel.current.clientWidth) / 2,
                    top: (window.innerHeight - this.detailpanel.current.clientHeight) / 2,
                };

                if (!this.state.position || !position || position.left !== this.state.position.left || position.top !== this.state.position.top) {
                    this.setState({ position : position });
                }
            }
        }
    }

    render() {
        let style = {} as any;
        if (this.state.position) {
            Object.assign(style, this.state.position, {position : "absolute"});
        }

        let detail = null;
        if (this.props.currentStep) {
            detail = <div className="detail">
                <DynLabel component="h3" className="accent" i18n={this.props.currentStep && this.props.currentStep.label} />
                <DynLabel component="div" className="description" i18n={this.props.currentStep && this.props.currentStep.description} />
            </div>;
        }

        return <div className={"onboarding-step" + (this.props.loading ? " loading" : "")} key={"step-" + this.props.currentStepNumber}>
            <div className="onboarding-step-detail"  ref={this.detailpanel} style={style}>
                {detail}
                <div className="actions">
                    <div>
                        <button onClick={this.props.onDismiss}><AppTextLabel i18n="onboarding.dismiss" /></button>
                    </div>
                    <div>
                        <button disabled={ this.props.currentStepNumber === 0 } onClick={this.props.onPrev}>
                            <i className="inwink-chevron-left"></i> <AppTextLabel i18n="onboarding.prev" />
                        </button>
                    </div>
                    <div>
                        <button disabled={ this.props.currentStepNumber >= this.props.config.steps.length - 1 } onClick={this.props.onNext}>
                            <AppTextLabel i18n="onboarding.next" /> <i className="inwink-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div className="loadingstate">
                    <Loader />
                </div>
            </div>
        </div>;
    }
}

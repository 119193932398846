import { Loadable } from '@inwink/loadable';
import { React, AppLabel } from '../commons';
import { trackError } from '../api/backoffice/tracking';
import { PageLoader } from './ui/pageloader';

export interface IAsyncPageProps {
    componentProps: any;
    getComponent?: any;
    provider?: () => Promise<any>;
    title: string;
    subtitle: string;
    locationAware?: boolean;
    noInheritance?: boolean;
    noChildInheritance?: boolean;
    noInheritanceAfterFirstLoad?: boolean;
    noChildInheritanceAfterFirstLoad?: boolean;
}

export class AsyncPage extends React.Component<IAsyncPageProps, any> {
    pageLocation: string;

    constructor(props: IAsyncPageProps) {
        super(props);

        this.state = {
            component: this.props.provider ? this.props.provider : () => new Promise(this.props.getComponent)
        };
        this.pageLocation = props.componentProps && props.componentProps.location && props.componentProps.location.pathname;
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: info
        });
        console.error("page error", error, info);
        trackError({
            eventid: this.props.componentProps?.match?.params?.eventid,
            customerid: this.props.componentProps?.match?.params?.customerid
        }, error, "async page error");
    }

    shouldComponentUpdate(nextprops: IAsyncPageProps) {
        if (nextprops.locationAware) {
            const oldpath = this.props.componentProps?.location?.pathname;
            const nextpath = nextprops.componentProps?.navlocation?.pathname;
            if (oldpath !== nextpath) {
                // console.log("skip rendering " + oldpath + " (" + nextpath + ")");
                return false;
            }
        }

        return true;
    }

    renderLoader = (data) => {
        return <PageLoader data={data} />;
    };

    render() {
        if (this.state && this.state.hasError) {
            return <div className="pageerror"><AppLabel i18n="page.error" /></div>;
        }

        return <Loadable
            fullSizeContent
            name={this.props.componentProps && this.props.componentProps.location && this.props.componentProps.location.pathname}
            className="app-page nopadding"
            minDelay={200}
            loader={this.renderLoader}
            componentProps={this.props.componentProps}
            component={this.state.component}
            noInheritance={this.props.noInheritance}
            noInheritanceAfterFirstLoad={this.props.noInheritanceAfterFirstLoad}
            noChildInheritance={this.props.noChildInheritance}
            noChildInheritanceAfterFirstLoad={this.props.noChildInheritanceAfterFirstLoad}
        />;
    }
}

export function loadablePage(getComponent, title?, subtitle?, options?: Partial<IAsyncPageProps>) {
    return function AsyncComponent(props) {
        return React.createElement(AsyncPage, {
            ...options,
            getComponent: getComponent,
            componentProps: props,
            title: title,
            subtitle: subtitle
        });
    };
}

export function loadablePageFor(
    provider: () => Promise<any>,
    options?: Partial<IAsyncPageProps>,
    title?: string,
    subtitle?: string
) {
    return function AsyncComponent(props) {
        return React.createElement(AsyncPage, {
            ...options,
            provider: provider,
            componentProps: props,
            title: title,
            subtitle: subtitle
        });
    };
}

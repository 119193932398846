/// <reference path="../../../node_modules/monaco-editor/monaco.d.ts" />

import * as React from 'react';
import * as assign from 'lodash/assign';
import { loadAssets, IAsset } from '@inwink/react-utils/loadassets';
import { LoadingState } from '@inwink/loadable';
import './codeeditor.less';
import { guid } from '@inwink/utils/methods';

interface ICodeEditorProps {
    id?: string;
    className?: string;
    language: string;
    content: string;
    onChange: (content: string) => void;
    onEditorInit?: (editor: monaco.editor.IStandaloneCodeEditor) => void;
    readonly?: boolean;
    options?: monaco.editor.IEditorConstructionOptions;
    schema?: string;
    currentLangTab?: string;
    needReInitMonaco?: boolean;
    disableLoadingState?: boolean;
}

declare const $;

const monacoPath = "assets/scripts/monaco-0-33-0/vs";

export class CodeEditor extends React.Component<ICodeEditorProps, any> {
    unmounted: boolean;

    editorNode = React.createRef<HTMLDivElement>();

    editor: monaco.editor.IStandaloneCodeEditor;

    constructor(props: ICodeEditorProps) {
        super(props);
        this.initMonaco = this.initMonaco.bind(this);
        this.getJsonSchema = this.getJsonSchema.bind(this);
        this.state = { loading: true };
    }

    componentDidMount() {
        loadAssets([{
            url: (inwink.config.assetsUrl || "/") + monacoPath + "/loader.js",
            type: "js"
        }] as IAsset[]).then(this.getJsonSchema).then(this.initMonaco).then(() => {
            this.setState({ loading: false });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.editor && prevProps.content != this.props.content) {
            this.editor.getModel().setValue(this.props.content || "");
        }

        if (prevProps.language !== this.props.language
            && this.editor && this.editor.getModel && monaco.editor && monaco.editor.setModelLanguage) {
            const model = this.editor.getModel();
            monaco.editor.setModelLanguage(model, this.props.language);
        }

        if (prevProps.currentLangTab !== this.props.currentLangTab && this.props.needReInitMonaco) {
            if (this.editor) {
                this.editor.setModel(null);
                this.editor.dispose();
                this.editor = null;
            }

            loadAssets([{
                url: (inwink.config.assetsUrl || "/") + monacoPath + "/loader.js",
                type: "js"
            }] as IAsset[]).then(this.getJsonSchema).then(this.initMonaco).then(() => this.setState({ loading: false }));
        }
    }

    getJsonSchema(): Promise<void> {
        return new Promise((resolve) => {
            if (this.props.language !== "json") {
                resolve();
            }
            if (this.props.schema) {
                $.get((inwink.config.assetsUrl || "/") + "/assets/schemas/" + this.props.schema + ".json")
                    .done((data) => {
                        if (data) {
                            this.setState({
                                schema: data
                            }, () => {
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    }).fail((err) => {
                        if (err.status === 404) {
                            console.log("json schema not found");
                        }
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    }

    initMonaco() {
        return new Promise((resolve) => {
            if (window && (window as any).require && (window as any).require.config) {
                (window as any).require.config({ paths: { vs: (inwink.config.assetsUrl || "/") + monacoPath } });
                (window as any).require(['vs/editor/editor.main'], () => {
                    if (!this.unmounted && typeof monaco !== "undefined") {
                        const baseOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
                            language: this.props.language,
                            folding: true,
                            readOnly: this.props.readonly ? this.props.readonly : false
                        };
                        let editorOptions: monaco.editor.IStandaloneEditorConstructionOptions = assign({},
                            baseOptions, this.props.options);

                        if (this.state.schema) {
                            const id = guid();
                            monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                                schemas: [{
                                    fileMatch: [id],
                                    schema: this.state.schema
                                }]
                            } as any);
                            // peu importe l'URI, il faut juste qu'il y ait un identifiant unique entre filematch et l'URI...
                            const model = monaco.editor.createModel(this.props.content || "", 'json',
                                `internal://inwinkbackorga/${id}` as any);

                            this.editor = monaco.editor.create(this.editorNode.current, {
                                model,
                                ...editorOptions
                            });
                        } else {
                            editorOptions = assign({}, editorOptions, {
                                value: this.props.content || ""
                            });
                            this.editor = monaco.editor.create(this.editorNode.current, editorOptions);
                        }

                        this.editor.getModel().updateOptions({ tabSize: 2 });
                        this.editor.onDidChangeModelContent(() => {
                            this.props.onChange(this.editor.getValue());
                        });
                        if (this.props.onEditorInit) {
                            this.props.onEditorInit(this.editor);
                        }
                        resolve();
                    } else {
                        console.log("can't load monaco editor");
                    }
                });
            }
        });
    }

    componentWillUnmount() {
        this.unmounted = true;

        if (this.editor) {
            this.editor.setModel(null);
            this.editor.dispose();
            this.editor = null;
        }
    }

    render() {
        return <LoadingState isLoading={!this.props.disableLoadingState && this.state.loading}>
            <div className={"code-editor-wrapper" + (this.state.loading ? " loading" : "")}>
                <div className={"code-editor" + (this.props.className ? " " + this.props.className : "")}>
                    <div
                        style={(this.state.loading) ? { border: 'none' } : undefined}
                        className="code-editor-component"
                        id={this.props.id}
                        ref={this.editorNode}
                    />
                </div>
            </div>
        </LoadingState>;
    }
}

import * as React from 'react';
import './burgermenu.less';

export interface IBurgerMenuProps {
    allowAnimation?: boolean;
    collapsed: boolean;
    style? : any;
    onClick: (arg) => void;
}

export class BurgerMenu extends React.Component<IBurgerMenuProps, any> {
    menuClicked(arg) {
        this.props.onClick(arg);
    }

    render() {
        if ((global as any).document) { // for server-side rendering
            if (!this.props.collapsed) {
                document.body.classList.add("burger-on");
            } else {
                document.body.classList.remove("burger-on");
            }
        }

        return <div
            className={'burger-menu' + (!this.props.collapsed ? ' expanded' : '')
                + (this.props.allowAnimation ? " allowanimation" : "")}
            onClick={this.menuClicked.bind(this)}
            style={this.props.style}
        >
            <div className="burger">
                <div className="split1" />
                <div className="split2" />
                <div className="split3" />
            </div>
        </div>;
    }
}

import type { States } from 'services/services';
import type { Entities } from '@inwink/entities/entities';
import { Popover } from '@inwink/modals';
import { OnOffToggle } from "@inwink/toggles";
import { AsyncButton } from '@inwink/buttons';
import { React, IPopoverManager, i18NHelperContext } from '../../../../commons';
import { AppTextLabel } from '../../../../services/i18nservice';
import { AppButton } from "../../../ui/buttons";
import type { IEntityViewsState } from '../entitypagebase.props';
import type { EntityViewsManager } from '../entitypagebase.viewmgr';
import { ViewsManager } from './import.export.viewsmanager';

import './viewselector.less';

interface IEntityViewSelectorProps {
    entityName: string;
    popovermgr: IPopoverManager;
    i18n: States.i18nState;
    viewsManager: EntityViewsManager;
    viewsState: IEntityViewsState;
    onCompleted?: () => void;
    i18nHelper?: Entities.i18nHelper;
}

export function EntityViewSelector(props: IEntityViewSelectorProps) {
    return (
        <i18NHelperContext.Consumer>
            {(i18nHelper) => <EntityViewSelectorComponent {...props} i18nHelper={i18nHelper} />}
        </i18NHelperContext.Consumer>
    );
}

class EntityViewSelectorComponent extends React.Component<IEntityViewSelectorProps, any> {
    constructor(props: IEntityViewSelectorProps) {
        super(props);
        this.state = {
            showviews: false,
            currentViewToSave: false
        };
    }

    componentDidUpdate(prevProps: IEntityViewSelectorProps) {
        const currentViewChanged = prevProps.viewsState && prevProps.viewsState.currentview
            && this.props.viewsState && this.props.viewsState.currentview
            && JSON.stringify(prevProps.viewsState.currentview) !== JSON.stringify(this.props.viewsState.currentview);

        if (currentViewChanged && this.props.viewsState && this.props.viewsState.allviews) { // the currentView changed
            // we make sure it's not just a rollback from previous
            this.props.viewsState.allviews.forEach((view) => {
                if (view.id === this.props.viewsState.currentview.id) {
                    // en commun j'ai : columnKeys, filters, order
                    if (JSON.stringify({
                        ck: view.content.columnKeys,
                        f: view.content.filters,
                        o: view.content.order,
                        t: view.title
                    }) !== JSON.stringify({
                        ck: this.props.viewsState.currentview.content.columnKeys,
                        f: this.props.viewsState.currentview.content.filters,
                        o: this.props.viewsState.currentview.content.order,
                        t: this.props.viewsState.currentview.title
                    })) {
                        this.setState({ currentViewToSave: true });
                    } else {
                        this.setState({ currentViewToSave: false });
                    }
                }
            });
        }
    }

    viewSelected = (view: Entities.IEntityView) => {
        this.props.viewsManager.setCurrentView(view);
        this.setState({ showviews: false }, () => {
            if (this.props.onCompleted) {
                this.props.onCompleted();
            }
        });
    };

    cloneView = () => {
        return this.props.viewsManager.cloneCurrentView(this.props.popovermgr).then((res) => {
            if (res) {
                this.setState({ showviews: false });
            }
        });
    };

    saveView = () => {
        return this.props.viewsManager.saveCurrentView(this.props.popovermgr).then((res) => {
            if (res) {
                this.setState({/* showviews: false, */currentViewToSave: false});
            }
        });
    };

    deleteView = (e, view: Entities.IEntityView) => {
        e?.preventDefault();
        e?.stopPropagation();

        return this.props.viewsManager.deleteView(this.props.popovermgr, view).then(() => {
            // this.setState({ showviews: false });
        });
    };

    manageIOViews = () => {
        const props = {
            i18n: this.props.i18n,
            entityName: this.props.entityName,
            allviews: this.props.viewsState.allviews
        };

        this.props.popovermgr.modalPortal(ViewsManager, props, "modal-io-manageviews").then(() => null);
    };

    changeViewTitle = (arg: React.FormEvent<any>) => {
        this.props.viewsManager.updateCurrentView({ title: arg.currentTarget.value });
    };

    changeViewIsShared = (isShared: boolean) => {
        this.props.viewsManager.updateCurrentView({isShared});
    };

    setDefaultView = () => {
        this.viewSelected(this.props.viewsManager.getDefaultView());
    };

    render() {
        const { i18nHelper, viewsState } = this.props;
        const { parentviews, showviews, currentViewToSave } = this.state;
        const hasCurrentView = viewsState?.currentview?.id;
        const viewDisabled = hasCurrentView && !viewsState.currentview.title;
        const currentTitle = (viewsState?.currentview?.title) || '';
        let content;
        let title = '';

        if (hasCurrentView) {
            title = 'entityviewselector.edit.title';
            content = <span className="viewtitle clickable">
                {this.props.viewsState.currentview.title} <i className="chevron inwink-chevron-down" />
            </span>;
        } else {
            title = 'entityviewselector.addnew.title';
            content = <span className="viewtitle clickable">
                <AppTextLabel i18n="entityview.default" /> <i className="chevron inwink-chevron-down" />
            </span>;
        }

        const views = viewsState.allviews
            ? viewsState.allviews
                ?.filter((b) => (viewsState.currentview ? b.id !== viewsState.currentview.id : true))
                ?.sort((a, b) => {
                    const aTitle = a?.title?.trim()?.toLowerCase();
                    const bTitle = b?.title?.trim()?.toLowerCase();

                    if (aTitle < bTitle) {
                        return -1;
                    }

                    if (aTitle > bTitle) {
                        return 1;
                    }

                    return 0;
                })
            : [];

        return <AppButton
            commandName="entityviewselector"
            className="entityviewselector"
            onClick={(arg) => { this.setState({ showviews: true, parentviews: arg.target }); }}
        >
            <div className="title">{content}</div>
            <Popover
                show={showviews}
                className="popover-entityviewselector"
                parent={parentviews}
                onhide={() => this.setState({ showviews: false })}
            >
                <div className="entityviewselector-viewselection">
                    <div className="formpage">
                        <div className="addnew-container padding">
                            <AppTextLabel component="h2" i18n={title} />

                            <div className="fieldbloc titleinput">
                                <input
                                    type="text"
                                    id="viewname"
                                    value={currentTitle}
                                    onChange={this.changeViewTitle}
                                    max={40}
                                    placeholder={i18nHelper.translate('entityviewselector.viewname.placeholder')}
                                />
                            </div>

                            {
                                hasCurrentView ? (
                                    <div className="currentview-actions">
                                        <button
                                            type="button"
                                            id="save-btn"
                                            key="updateView"
                                            className={`save-btn bordered ${!currentViewToSave ? 'disabled' : ''}`}
                                            disabled={!currentViewToSave}
                                            onClick={currentViewToSave ? this.saveView : null}
                                            title={i18nHelper.translate('actions.updateentityview')}
                                        >
                                            <i className="chevron inwink-save" />
                                        </button>

                                        <button
                                            type="button"
                                            id="clone-btn"
                                            key="clone"
                                            className="clone-btn bordered"
                                            onClick={this.cloneView}
                                            title={i18nHelper.translate('actions.new')}
                                        >
                                            <i className="chevron inwink-add" />
                                        </button>

                                        {inwink.config.isBetaEnv && (
                                            <button
                                                type="button"
                                                id="export-views"
                                                key="ioviews"
                                                className="export-btn bordered"
                                                onClick={this.manageIOViews}
                                                title={i18nHelper.translate('actions.exportentityview')}
                                            >
                                                <i className="inwink-importexport" />
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    <div className="save">
                                        <div className="checkbox-field">
                                            <OnOffToggle
                                                value={viewsState?.currentview?.isShared}
                                                onChange={this.changeViewIsShared}
                                            />
                                            <AppTextLabel component="label" i18n="actions.shareview" className="lite" />
                                        </div>
                                        <AsyncButton
                                            type="button"
                                            className={`addnew lightbtn bordered ${viewDisabled ? 'disabled' : ''}`}
                                            disabled={viewDisabled}
                                            onClick={this.saveView}
                                        >
                                            <AppTextLabel i18n="entityviewselector.action.add" />
                                        </AsyncButton>
                                    </div>
                                )
                            }
                        </div>

                        <div className="or padding"><AppTextLabel component="label" i18n="entityviewselector.or" /></div>

                        <div className="replace-container">
                            <div className="title-container padding">
                                <AppTextLabel component="h2" i18n="entityviewselector.replace.title" />
                            </div>

                            <div className="view-items">
                                {
                                    views?.length ? (
                                        views?.map((view: Entities.IEntityView, index: number) => {
                                            return (
                                                <div
                                                    key={`${view.id}#${index}`}
                                                    className="item"
                                                    onClick={() => this.viewSelected(view)}
                                                >
                                                    <div className="title padding">{view.title}</div>
                                                    <AsyncButton
                                                        type="button"
                                                        className="btnremove"
                                                        onClick={(e) => this.deleteView(e, view)}
                                                        title={i18nHelper.translate('actions.delete')}
                                                    >
                                                        <i className="inwink-trash" />
                                                    </AsyncButton>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <AppTextLabel className="no-item padding" key="text" i18n="entityview.text" />
                                    )
                                }
                            </div>

                            {
                                hasCurrentView && (
                                    <div className="default-item padding">
                                        <button
                                            type="button"
                                            className="default"
                                            onClick={this.setDefaultView}
                                        >
                                            <AppTextLabel i18n="entityviewselector.action.default" />
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Popover>
        </AppButton>;
    }
}

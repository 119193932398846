/* eslint-disable max-classes-per-file */
import { React, withI18nHelper, WithPopoverManager, IPopoverManager } from '../../../commons';
import { ItemsPicker } from '../itemspicker';
import { DrowdownEditorPopover } from './popover';

import './dropdowneditor.less';

export interface IDrowdownEditorProps {
    modaleTitle?: string | ((props?: any) => any);
    selection: IDrowdownEditorItem[];
    allItems: IDrowdownEditorItem[];
    onChange: (selection: IDrowdownEditorItem[]) => void;
    onUpdateItem?: (item: any) => void | Promise<void>;
    multiselect?: boolean;
    readOnly?: boolean;
}

interface IDrowdownEditorState {
}

export interface IDrowdownEditorItem {
    label: string;
    key: string;
    dataTrad?: string;
}

@withI18nHelper()
export class DropdownEditor extends React.Component<IDrowdownEditorProps, any> {
    render() {
        return <WithPopoverManager>
            {(popovermgr) => <DropdownEditorContent {...this.props} popovermgr={popovermgr} />}
        </WithPopoverManager>;
    }
}

export interface IDropdownEditorContentProps extends IDrowdownEditorProps {
    popovermgr: IPopoverManager;
}

class DropdownEditorContent extends React.Component<IDropdownEditorContentProps, IDrowdownEditorState> {
    popover: any;

    componentDidUpdate() {
        if (this.popover) {
            this.popover.setProps(this.props);
        }
    }

    renderItem(item: IDrowdownEditorItem) {
        if (item.dataTrad) {
            return <span data-trad={item.dataTrad} className="dropdowneditor-selecteditem">{item.label}</span>;
        }
        return <span className="dropdowneditor-selecteditem">{item.label}</span>;
    }

    pick = (arg: React.MouseEvent<any>) => {
        const popover = this.props.popovermgr.popoverPortal(arg.currentTarget,
            DrowdownEditorPopover, this.props, "iw-dropdowneditor-popover");
        this.popover = popover;
        popover.then(() => {
            this.popover = null;
            // if (res) {
            //    this.props.onChange(res);
            // }
        });
    };

    removeItem = (item) => {
        const selection = this.props.selection.filter((s) => s.key !== item.key);
        this.props.onChange(selection);
    };

    render() {
        return <ItemsPicker
            itemsRenderer={this.renderItem}
            onPick={this.pick}
            onUpdateItem={this.props.onUpdateItem}
            onRemoveItem={this.removeItem}
            selection={this.props.selection}
            readOnly={this.props.readOnly}
        />;
    }
}

export function sortItems(a: IDrowdownEditorItem, b: IDrowdownEditorItem) {
    if (b.label.toLowerCase() < a.label.toLowerCase()) {
        return 1;
    }
    if (b.label.toLowerCase() > a.label.toLowerCase()) {
        return -1;
    }
    return 0;
}

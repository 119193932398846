import { loadablePageFor, IAsyncPageProps } from '../components/apppage.async';

export { loadablePageFor, loadablePage } from '../components/apppage.async';

const defaultModuleOptions: Partial<IAsyncPageProps> = {
    locationAware: true
};

export function adminModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modadmin" */ './module.admin').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function companionModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modcompanion" */ './module.companion').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function communityModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modcommunity" */ './module.community').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function traductionsModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modtraductions" */ './module.traductions').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function exhibitorsModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modexhibitors" */ './module.exhibitors').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function speakersModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modspeakers" */ './module.speakers').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function contentsModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modcontents" */ './module.contents').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function onsiteModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modonsite" */ './module.onsite').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function interactivityModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modinteractivity" */ './module.interactivity').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function networkingModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modnetworking" */ './module.networking').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function customerModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modcustomer" */ './module.customer').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function customerWebsiteModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modcustomerwebsite" */ './module.customerwebsite').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function customerTenantModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modcustomertenant" */ './module.customertenant').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function mailingModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modmailing" */ './module.mailing').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function rootAdminModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modrootadmin" */ './module.rootadmin').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function rightsModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modrights" */ './module.rights').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function liveSessionModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modlivesession" */ './module.livesession').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

export function roomingModuleComponent(component: string, title?, subtitle?) {
    return loadablePageFor(
        () => import(/* webpackChunkName: "modrooming" */ './module.rooming').then((md) => md[component]),
        defaultModuleOptions, title, subtitle
    );
}

/* eslint-disable max-classes-per-file */
import type { IDynamicFieldEditorProps } from '@inwink/entityform/dynamicfieldeditor';
import { DynamicFieldWrapper } from '@inwink/entityform/dynamicfieldwrapper';
import { DynamicFieldLabel } from '@inwink/entityform/dynamicfieldlabel';
import { React, connectwith } from '../../commons';
import type { States } from '../../services/services';
import { EntityPicker } from "../entities/entitypicker";

export interface IGenericEntityEditorProps extends IDynamicFieldEditorProps {
    event?: States.IEventState;
    entityArgs?: any;
    disableAddNew?: boolean;
    type?: string;
    matchKey?: string;
    specifiEntityIdKey?: string;
}

function mapStateToProps(state: States.IAppState) {
    return { event: state.event };
}

@connectwith(mapStateToProps)
export class GenericEntityEditor extends React.Component<IGenericEntityEditorProps, any> {
    onChange = (ids, items) => {
        if (this.props.onChangeMultiple) {
            this.props.onChangeMultiple([
                {
                    key: this.props.entityFieldFormTemplate.key,
                    value: (items && items[0]) || null
                },
                {
                    key: this.props.specifiEntityIdKey || this.props.entityFieldFormTemplate.key + "Id",
                    value: (items && items[0] && items[0].id) || null
                }
            ]);
        }
    };

    render() {
        const currentVal = this.props.entityFieldValue ? [this.props.entityFieldValue] : null;
        const entityname = this.props.entityFieldFormTemplate.value || this.props.type;

        return <DynamicFieldWrapper {...this.props} className={"genericentitypicker entity-" + entityname.toLowerCase()}>
            <EntityPicker
                entityName={entityname}
                matchKey={this.props.matchKey}
                disableAddNew={this.props.disableAddNew}
                selection={currentVal}
                readOnly={this.props.forceReadOnly}
                onChange={this.onChange}
                modaleTitle={() => <DynamicFieldLabel {...this.props} />}
                entityArgs={this.props.entityArgs}
            />
        </DynamicFieldWrapper>;
    }
}

@connectwith(mapStateToProps)
export class GenericEntitiesEditor extends React.Component<IGenericEntityEditorProps, any> {
    onChange = (ids, items) => {
        this.props.onChange(this.props.entityFieldFormTemplate.key, items || []);
    };

    render() {
        const entityname = this.props.entityFieldFormTemplate.value;
        return <DynamicFieldWrapper {...this.props} className={"genericentitiespicker entity-" + entityname.toLowerCase()}>
            <EntityPicker
                entityName={entityname}
                disableAddNew={this.props.disableAddNew}
                multiselect={true}
                matchKey={this.props.matchKey}
                selection={this.props.entityFieldValue}
                readOnly={this.props.forceReadOnly}
                onChange={this.onChange}
                modaleTitle={() => <DynamicFieldLabel {...this.props} />}
                entityArgs={this.props.entityArgs}
            />
        </DynamicFieldWrapper>;
    }
}

import { States } from './services';

const INITIAL_STATE: States.IAppThemeState = {
    theme: 'default'
};

export function appthemeReducer(state = INITIAL_STATE, action = { type: '', payload: null }): any {
    switch (action.type) {
        case "APPTHEME_SETTHEME": {
            return action.payload;
        }
        default:
            return state;
    }
}

export const appthemeActions = {
    setCurrentTheme(theme: 'customer' | 'community' | 'event' | 'default') {
        return (dispatch, getState: () => States.IAppState) => {
            const oldTheme = getState().apptheme?.theme;
            document.body.className = document.body.className.replace(oldTheme + "theme", theme + "theme");
            dispatch({ type: 'APPTHEME_SETTHEME', payload: { theme: theme } });
        };
    }
};

import type { IPopoverManager } from '@inwink/modals';
import { React, i18NHelperContext } from '../../commons';
import type { ICMSContext } from '../cmscontext';
import type {
    IConfigurationProviderArgs,
    IEntityPageConfiguration
} from "../entities/entitypagev3/entitypagebase.props";

export interface IEntityImportModalProps {
    cmscontext: ICMSContext;
    entityconfiguration: IEntityPageConfiguration;
    excludedColumns: string[];
    popovermgr: IPopoverManager;
    entityPageProps: IConfigurationProviderArgs;
    entityGridArgs: IConfigurationProviderArgs;
    // cancelEscKey: boolean;
}

export function openEntityImportv3(popovermgr: IPopoverManager, props: IEntityImportModalProps) {
    return import(/* webpackChunkName: "mod-importv3" */ './importV3modal').then((mod) => {
        return popovermgr.modalPortal((_props) => <i18NHelperContext.Consumer>
                {(i18nHelper) => React.createElement(mod.EntityImportModalv3, {..._props, i18nHelper: i18nHelper})}
            </i18NHelperContext.Consumer>, props);
    });
}

/* eslint-disable max-classes-per-file */
import type { IPopoverManager, IPopoverDismissManager } from '@inwink/modals';
import { Tooltip, DefaultTooltipManager } from '@inwink/modals/tooltip';
import {
    React, AppTextLabel, AppAsyncButton, connectwith
} from '../../../../commons';
import type { States } from "../../../../services/services";
import type { IEntityScreenHelpers, IEntityPageConfiguration,
    EntityPageAction, IEntityPageComputedActions, IEntityViewsState } from '../entitypagebase.props';

import './entityactionsmenu.less';

export function showActionsMenu(target: HTMLElement, props: IEntityActionsMenuProps) {
    props.popovermgr.popoverPortal(target, EntityActionsMenu, props, "popoverentityactionsmenu", {
        closeOnResize: true
    });
}

export interface IEntityActionsMenuProps {
    popover?: IPopoverDismissManager;
    popovermgr: IPopoverManager;
    parentTarget?: HTMLElement;
    computedActions: IEntityPageComputedActions;
    // entityRights: InWinkBO.IEntityRights;
    helpers: IEntityScreenHelpers;
    onCompleted?: (res?) => void;
    viewsState: IEntityViewsState;
    entityconf: IEntityPageConfiguration;
    // entityactions: IEntityPageActions;
    item?: any;
    fromContextMenu?: boolean;
    event?: States.IEventState;
    // runMode: EntityPageRunMode;
}

interface IEntityActionsMenuState {
    // currentViewActions: EntityPageAction[];
    // selectionActions: EntityPageAction[];
    // navActions: EntityPageAction[];
    // noScopeActions: EntityPageAction[];
}

@connectwith((state: States.IAppState) => {
    return {
        event: state.event
    };
})
export class EntityActionsMenu extends React.Component<IEntityActionsMenuProps, IEntityActionsMenuState> {
    tooltipMgr: DefaultTooltipManager;

    container = React.createRef<HTMLDivElement>();

    constructor(props: IEntityActionsMenuProps) {
        super(props);
        this.tooltipMgr = new DefaultTooltipManager();

        this.state = {
            // ...this.getActions(props)
        };
    }

    componentDidMount() {
        if (this.props.popover) {
            this.props.popover.addCanCloseHandler(this.canClose);
        }
    }

    componentWillUnmount() {
        if (this.props.popover) {
            this.props.popover.removeCanCloseHandler(this.canClose);
        }
    }

    canClose = () => {
        if (this.tooltipMgr) {
            this.tooltipMgr.hideCurrent();
        }

        return Promise.resolve(true);
    };

    renderItems = (props, actions: EntityPageAction[], title: any, subactions: EntityPageAction[]) => {
        return <EntityActionsMenuItems
            {...this.props}
            title={title}
            actions={actions}
            subactions={subactions}
            menuItemsCompleted={props.onHide}
        />;
    };

    renderSelectionItems(props) {
        const selectionActions = this.props.computedActions.secondaryActions?.selectionActions;
        const subactions = this.props.computedActions.secondaryActions?.selectionSecondaryActions;

        return this.renderItems(
            props,
            selectionActions, () => <>
                <AppTextLabel i18n="entitypage.actions.selection" className="title" />
                <span className="badge">{this.props.viewsState.selection.length}</span>
            </>, subactions
        );
    }

    renderSelection() {
        const selectionActionsCount = this.props.computedActions.secondaryActions && (
            ((this.props.computedActions.secondaryActions.selectionActions
                && this.props.computedActions.secondaryActions.selectionActions.length) || 0)
            + ((this.props.computedActions.secondaryActions.selectionSecondaryActions
                && this.props.computedActions.secondaryActions.selectionSecondaryActions.length) || 0)
        );

        if (!selectionActionsCount) {
            return null;
        }

        return <Tooltip
            tooltipmgr={this.tooltipMgr}
            className="menuitem clickable"
            component="div"
            content={(props) => this.renderSelectionItems(props)}
            placement="right"
            parent={this.container.current}
            popoverClassName="popoverentityactionsmenuitems"
            openOnClick={true}
        >
            <AppTextLabel i18n="entitypage.actions.selection" className="title" />
            <span className="badge">{this.props.viewsState.selection.length}</span>
            <i className="inwink-chevronsmall-right" />
        </Tooltip>;
    }

    renderViewItems(props) {
        const viewActions = this.props.computedActions.secondaryActions?.currentViewActions;
        const subactions = this.props.computedActions.secondaryActions?.currentViewSecondaryActions;

        let viewTitle = null;
        if (this.props.viewsState.currentview && this.props.viewsState.currentview.title) {
            viewTitle = <span className="title">{this.props.viewsState.currentview.title}</span>;
        } else {
            viewTitle = <AppTextLabel i18n="entitypage.actions.currentview" className="title" />;
        }
        return this.renderItems(props, viewActions, () => <>
            {viewTitle}
            <span className="badge">{this.props.viewsState.rowCount}</span>
        </>, subactions);
    }

    renderView() {
        if (!this.props.computedActions.secondaryActions
            || !this.props.computedActions.secondaryActions.currentViewActions
            || !this.props.computedActions.secondaryActions.currentViewActions.length) {
            return null;
        }

        return <Tooltip
            tooltipmgr={this.tooltipMgr}
            className="menuitem clickable"
            component="div"
            content={(props) => this.renderViewItems(props)}
            placement="right"
            parent={this.container.current}
            popoverClassName="popoverentityactionsmenuitems"
            openOnClick={true}
        >
            <AppTextLabel i18n="entitypage.actions.currentview" className="title" />
            <span className="badge">{this.props.viewsState.rowCount}</span>
            <i className="inwink-chevronsmall-right" />
        </Tooltip>;
    }

    renderNavActions() {
        if (!this.props.computedActions.secondaryActions
            || !this.props.computedActions.secondaryActions.navActions
            || !this.props.computedActions.secondaryActions.navActions.length) {
            return null;
        }

        const actions = this.props.computedActions.secondaryActions.navActions;

        if (!actions.length) {
            return null;
        }

        return <Tooltip
            tooltipmgr={this.tooltipMgr}
            className="menuitem clickable"
            component="div"
            content={(props) => this.renderItems(props, actions, null, null)}
            placement="right"
            parent={this.container.current}
            popoverClassName="popoverentityactionsmenuitems"
            openOnClick={true}
        >
            <AppTextLabel i18n="entitypage.navactions" className="title" />
            <i className="inwink-chevronsmall-right" />
        </Tooltip>;
    }

    renderCustomActions() {
        if (!this.props.computedActions.secondaryActions
            || !this.props.computedActions.secondaryActions.customActions
            || !this.props.computedActions.secondaryActions.customActions.length) {
            return null;
        }

        const actions = this.props.computedActions.secondaryActions.customActions;

        if (!actions.length) {
            return null;
        }

        return <Tooltip
            tooltipmgr={this.tooltipMgr}
            className="menuitem clickable"
            component="div"
            content={(props) => this.renderItems(props, actions, null, null)}
            placement="right"
            parent={this.container.current}
            popoverClassName="popoverentityactionsmenuitems"
            openOnClick={true}
        >
            <AppTextLabel i18n="entitypage.customactions" className="title" />
            <i className="inwink-chevronsmall-right" />
        </Tooltip>;
    }

    renderBaseActions() {
        if (!this.props.computedActions.secondaryActions
            || !this.props.computedActions.secondaryActions.baseActions
            || !this.props.computedActions.secondaryActions.baseActions.length) {
            return null;
        }

        const actions = this.props.computedActions.secondaryActions.baseActions.map((act, idx) => {
            return <AppAsyncButton key={"baseaction" + idx} className="menuitem" onClick={(arg) => this.actionCallback(arg, act)}>
                <AppTextLabel i18n={act.label as any} />
            </AppAsyncButton>;
        });

        return <div className="baseactions">
            {actions}
        </div>;
    }

    actionCallback = (arg: React.MouseEvent<any>, action: EntityPageAction) => {
        arg.preventDefault();
        arg.stopPropagation();

        const res = this.props.helpers.triggerAction(action, arg);
        if (res && res.then) {
            return res.then(() => {
                this.props.onCompleted();
            });
        }
        this.props.onCompleted();
    };

    render() {
        return <div ref={this.container}>
            {this.renderBaseActions()}
            {this.renderSelection()}
            {this.renderView()}
            {this.renderCustomActions()}
            {this.renderNavActions()}
        </div>;
    }
}

interface IEntityActionsMenuItemsProps extends IEntityActionsMenuProps {
    actions: EntityPageAction[];
    subactions?: EntityPageAction[];
    title?: string | any;
    menuItemsCompleted: (res?) => void;
}

class EntityActionsMenuItems extends React.Component<IEntityActionsMenuItemsProps, any> {
    actionCallback = (arg: React.MouseEvent<any>, action: EntityPageAction) => {
        arg.preventDefault();
        arg.stopPropagation();

        let clickArg = arg;
        if (this.props.parentTarget) {
            clickArg = { ...arg, target: this.props.parentTarget, currentTarget: this.props.parentTarget };
        }

        const res = this.props.helpers.triggerAction(action, clickArg);
        if (res && res.then) {
            return res.then(() => {
                this.props.menuItemsCompleted();
                this.props.onCompleted();
            });
        }
        this.props.menuItemsCompleted();
        this.props.onCompleted();
    };

    render() {
        let actions = null;
        let subactions = null;
        if (this.props.actions && this.props.actions.length) {
            actions = this.props.actions && this.props.actions.map((act: EntityPageAction) => {
                return <AppAsyncButton key={act.key} onClick={(arg) => this.actionCallback(arg, act)}>
                    <AppTextLabel i18n={act.label as string} />
                </AppAsyncButton>;
            });
        }

        if (this.props.subactions && this.props.subactions.length) {
            const subactionsButtons = this.props.subactions && this.props.subactions.map((act: EntityPageAction) => {
                return <AppAsyncButton key={act.key} onClick={(arg) => this.actionCallback(arg, act)}>
                    <AppTextLabel i18n={act.label as string} />
                </AppAsyncButton>;
            });

            subactions = <div className="subactions">
                {subactionsButtons}
            </div>;
        }
        let title = null;
        if (this.props.title) {
            if (typeof this.props.title === "string") {
                title = <AppTextLabel i18n={this.props.title} />;
            } else {
                title = React.createElement(this.props.title, this.props);
            }
        }

        return <div className="entityactionsmenuitems">
            {title ? <header>
                {title}
            </header> : null}
            <section>
                {actions}
                {subactions}
            </section>
        </div>;
    }
}

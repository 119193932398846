import * as React from 'react';
import * as assign from 'lodash/assign';
import { Children} from 'react';
import {Motion, spring} from '@inwink/react-motion';

const styles = {
    root: {
        overflowX: 'hidden',
        touchAction: 'pan-y',
        // minHeight: '100%',
    },
    container: {
        willChange: 'transform',
    },
    slide: {
    },
};

const RESISTANCE_COEF = 0.7;
const UNCERTAINTY_THRESHOLD = 5; // px

export interface ISwipeableProps {
    disableLeft?: boolean;
    disableRight?: boolean;
    /*
    * If `false`, changes to the index prop will not cause an animated transition.
    */
    animateTransitions?: boolean;
    behavior: 'slide' | 'swipeselect';
    /**
     * Use this property to provide your slides.
     */
    children?: any;
    /**
     * This is the inlined style that will be applied
     * to each slide container.
     */
    containerStyle?: any;
    /**
     * If `true`, it will disable touch events.
     * This is useful when you want to prohibit the user from changing slides.
     */
    disabled?: boolean;
    /**
     * This is callback prop. It's call by the
     * component when the shown slide change after a swipe made by the user.
     * This is useful when you have tabs linked to each slide.
     *
     * @param {integer} index This is the current index of the slide.
     * @param {integer} fromIndex This is the oldest index of the slide.
     */
    onChangeIndex?: (index: number, reset?: () => Promise<any>) => void;
    onSwiped?: (direction: string, translate: number, reset?: () => Promise<any>) => void;
    onSwipeSelect?: () => void;
    onSwipeSelectProgress?: (_) => void;
    /**
     * This is callback prop. It's called by the
     * component when the slide switching.
     * This is useful when you want to implement something corresponding to the current slide position.
     *
     * @param {integer} index This is the current index of the slide.
     * @param {string} type Can be either `move` or `end`.
     */
    onSwitching?: (index: number, action: string, reset?: () => Promise<any>) => void;
    /**
     * @ignore
     */
    onTouchEnd?: (_) => void;
    /**
     * @ignore
     */
    onTouchStart?: (_) => void;
    /**
     * If `true`, it will add bounds effect on the edges.
     */
    resistance?: boolean;
    /**
     * This is the inlined style that will be applied
     * on the slide component.
     */
    slideStyle?: any;
    /**
     * This is the config given to react-motion for the spring.
     * This is useful to change the dynamic of the transition.
     */
    springConfig?: any;
    /**
     * This is the inlined style that will be applied
     * on the root component.
     */
    style?: any;
    /**
     * This is the threshold used for detecting a quick swipe.
     * If the computed speed is above this value, the index change.
     */
    threshold?: number;
    selectThreshold?: number;
    selectThresholdPx?: number;
    className?: string;
    max?: number;
}

export class Swipeable extends React.Component<ISwipeableProps, any> {
    constructor(props: ISwipeableProps) {
        super(props);
        this.state = {
            indexCurrent: 0,
            indexLatest: 0,
            isDragging: false,
            isFirstRender: true,
            heightLatest: 0,
        };
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
    }

    static defaultProps = {
        animateTransitions: true,
        index: 0,
        threshold: 5,
        resistance: false,
        disabled: false,
        springConfig: {
            stiffness: 300,
            damping: 30,
            precision: 2
        },
    };

    componentDidMount() {
        this.setState({
            isFirstRender: false,
        });

        if (this.wrapper.current) {
            let node = this.wrapper.current;
            if (node.ontouchstart !== undefined) {
                node.addEventListener("touchstart", this.handleTouchStart);
                node.addEventListener("touchmove", this.handleTouchMove);
                node.addEventListener("touchend", this.handleTouchEnd);
            } else if (node.onpointerdown !== undefined) {
                node.addEventListener("pointerdown", this.handleTouchStart);
                node.addEventListener("pointermove", this.handleTouchMove);
                node.addEventListener("pointerup", this.handleTouchEnd);
            }
        }
    }

    componentWillUnmount() {
        if (this.wrapper.current) {
            let node = this.wrapper.current;
            if (node.ontouchstart !== undefined) {
                node.removeEventListener("touchstart", this.handleTouchStart);
                node.removeEventListener("touchmove", this.handleTouchMove);
                node.removeEventListener("touchend", this.handleTouchEnd);
            } else if (node.onpointerdown !== undefined) {
                node.removeEventListener("pointerdown", this.handleTouchStart);
                node.removeEventListener("pointermove", this.handleTouchMove);
                node.removeEventListener("pointerup", this.handleTouchEnd);
            }
        }
    }

    componentDidUpdate(nextProps) {
       /* KO - will trigger infinite loop and I can't figure what it does... this.translationCurrent = 0;
        this.setState({
            indexCurrent: 0,
            indexLatest: 0,
        }); */
    }

    currentSwipeProgress = 0;
    startWidth = 0;
    startX = 0;
    lastX = 0;
    vx = 0;
    startY = 0;
    isSwiping = undefined;
    started = false;
    isDragging = false;
    nextFrame = null;
    translationCurrent = null;
    translationLatest = null;
    lastApplyedTranslate?: number = null;
    wrapper = React.createRef<HTMLDivElement>();
    container = React.createRef<HTMLDivElement>();

    handleTouchStart = (event: any) => {
        if (event.pointerType && event.pointerType !== "touch") {
            return;
        }

        if (this.props.onTouchStart) {
            this.props.onTouchStart(event);
        }
        let node = this.wrapper.current;

        let touch = event as any;
        if (event.touches) {
            touch = event.touches[0];
        }

        this.startWidth = node && node.getBoundingClientRect().width;
        this.startX = touch.pageX;
        this.lastX = touch.pageX;
        this.vx = 0;
        this.startY = touch.pageY;
        this.isSwiping = undefined;
        this.started = true;
    }

    handleTouchMove = (event) => {
        // console.log("touch move started:" + this.started + " swiping:" + this.isSwiping);
        if (event.pointerType && event.pointerType !== "touch") {
            return;
        }

        // The touch start event can be cancel.
        // Makes sure we set a starting point.
        if (!this.started) {
            this.handleTouchStart(event);
            return;
        }

        if (this.isSwiping === false) {
            return;
        }

        let touch = event as any;
        if (event.touches) {
            touch = event.touches[0];
        }

        // We don't know yet.
        if (this.isSwiping === undefined) {
            if (event.defaultPrevented) {
                this.isSwiping = false;
                return;
            } else {
                const dx = Math.abs(this.startX - touch.pageX);
                const dy = Math.abs(this.startY - touch.pageY);

                const isSwiping = dx > dy && dx > UNCERTAINTY_THRESHOLD;

                if (isSwiping === true || dx > UNCERTAINTY_THRESHOLD || dy > UNCERTAINTY_THRESHOLD) {
                    this.isSwiping = isSwiping;
                    this.startX = touch.pageX; // Shift the starting point.
                }
            }
        }

        if (this.isSwiping !== true) {
            return;
        }

        let node = this.wrapper.current;
        if (node && event.pointerId && event.target.setPointerCapture) {
            node.setPointerCapture(event.pointerId);
        }

        // Prevent native scrolling
        event.preventDefault();

        cancelAnimationFrame(this.nextFrame);
        this.nextFrame = requestAnimationFrame(() => {
            if (this.props.selectThresholdPx > 0 && this.container.current) {
                let translationPx = this.startX - touch.pageX;
                let allowed = true;
                if (this.props.disableLeft && translationPx > 0) {
                    allowed = false;
                }
                if (this.props.disableRight && translationPx < 0) {
                    allowed = false;
                }
                if (allowed) {
                    translationPx = Math.abs(translationPx);
                    let currentProgress = Math.round(translationPx * 100 / this.props.selectThresholdPx);
                    if (currentProgress < 0) {
                        currentProgress = 0;
                    }
                    if (currentProgress > 100) {
                        currentProgress = 100;
                    }

                    if (this.currentSwipeProgress !== currentProgress && this.props.onSwipeSelectProgress) {
                        this.props.onSwipeSelectProgress(currentProgress);
                    }
                    this.currentSwipeProgress = currentProgress;
                }
            }
            this.vx = this.vx * 0.5 + (touch.pageX - this.lastX) * 0.5;
            this.lastX = touch.pageX;

            const indexMax = Children.count(this.props.children) - 1;

            let index = this.state.indexLatest + (this.startX - touch.pageX) / this.startWidth;
            // console.log("index " + index);
            if (index < 0 && this.props.disableRight) {
                index = (Math.exp(index * RESISTANCE_COEF) - 1) / 4;
            } else if (index > 0 && this.props.disableLeft) {
                index = (1 - Math.exp((-index) * RESISTANCE_COEF)) / 4;
            }

            this.translationCurrent = index;
            let swipecontainer = this.container.current;
            if (swipecontainer) {
                let translation = this.getTranslate(-(index * 100));

                this.lastApplyedTranslate = translation;
                swipecontainer.style.webkitTransform = `translate3d(${translation}%, 0, 0)`;
                swipecontainer.style.transform = `translate3d(${translation}%, 0, 0)`;
                this.isDragging = true;
                if (this.props.onSwitching) {
                    this.props.onSwitching(index, 'move', this.reset);
                }
            }
        });
    }

    handleTouchEnd = (event) => {
        cancelAnimationFrame(this.nextFrame);
        if (event.pointerType && event.pointerType !== "touch") {
            return;
        }

        if (this.props.onTouchEnd) {
            this.props.onTouchEnd(event);
        }

        let node = this.wrapper.current;
        if (node && event.pointerId && event.target.releasePointerCapture) {
            node.releasePointerCapture(event.pointerId);
        }

        // The touch start event can be cancel.
        // Makes sure that a starting point is set.
        if (!this.started) {
            return;
        }

        this.started = false;

        if (this.isSwiping !== true) {
            return;
        }

        const indexLatest = this.translationLatest; // this.state.indexLatest;
        const indexCurrent = this.translationCurrent; // this.state.indexCurrent;

        let indexNew;

        // Quick movement
        if (Math.abs(this.vx) > this.props.threshold) {
            if (this.vx > 0) {
                indexNew = Math.floor(indexCurrent);
            } else {
                indexNew = Math.ceil(indexCurrent);
            }
        } else {
            // Some hysteresis with indexLatest
            if (Math.abs(indexLatest - indexCurrent) > 0.6) {
                indexNew = Math.round(indexCurrent) * 1.05;
            } else {
                indexNew = indexLatest;
            }
        }

        if (indexNew < 0 && this.props.disableRight) {
            indexNew = 0;
        } else if (indexNew > 0 && this.props.disableLeft) {
            indexNew = 0;
        }

        if (indexNew !== 0) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        // console.log("moving to " + indexNew);

        this.setState({
            indexCurrent: this.translationCurrent
        }, () => {
            this.translationLatest = indexNew;
            this.translationCurrent = indexNew;
            this.isDragging = false;

            if (this.props.behavior !== 'swipeselect') {
                this.setState({
                    indexCurrent: indexNew,
                    indexLatest: indexNew,
                    isDragging: false,
                }, () => {
                    let shouldTrigger = true;
                    if (this.props.selectThresholdPx) {
                        shouldTrigger = this.currentSwipeProgress >= 100;
                    }

                    if (shouldTrigger) {
                        if (this.props.onSwitching) {
                            this.props.onSwitching(indexNew, 'end', this.reset);
                        }

                        // console.log("swipe from " + indexLatest + " to " + indexNew);
                        if (this.props.onChangeIndex) {
                            if (indexNew) {
                                this.props.onChangeIndex(indexNew, this.reset);
                            } else {
                                this.props.onChangeIndex(null, this.reset);
                            }
                        }
                    } else {
                        this.reset();
                    }
                });
            } else {
                let shouldTrigger = false;
                if (this.props.selectThresholdPx && this.currentSwipeProgress >= 100) {
                    shouldTrigger = true;
                }
                if (this.props.selectThreshold && this.props.selectThreshold < this.lastApplyedTranslate) {
                    shouldTrigger = true;
                }
                this.reset().then(() => {
                    if (shouldTrigger && this.props.onSwipeSelect) {
                        this.props.onSwipeSelect();
                    }
                });
            }
        });
    }

    reset = () => {
        return new Promise((resolve) => {
            this.translationCurrent = 0;
            this.currentSwipeProgress = 0;
            this.setState({
                indexCurrent: 0,
                indexLatest: 0,
                isDragging: false,
            }, resolve);
        });
    }

    updateHeight(node, index) {
        if (node !== null && index === this.state.indexLatest) {
            const child = node.children[0];
            if (child !== undefined && child.offsetHeight !== undefined &&
                this.state.heightLatest !== child.offsetHeight) {
                this.setState({
                    heightLatest: child.offsetHeight,
                });
            }
        }
    }

    notified: boolean;

    getTranslate(translate: number) {
        if (this.props.max) {
            if (translate < 0 && translate < -this.props.max) {
                return -this.props.max;
            }
            if (translate > 0 && translate > this.props.max) {
                return this.props.max;
            }
        }

        return translate;
    }

    renderContainer(interpolatedStyle, /*updateHeight,*/ childrenToRender) {
        const {
            containerStyle,
        } = this.props;

        let translate = -interpolatedStyle.translate;
        // console.log("translating to " + translate);
        if (translate <= -100 && !this.notified) {
            this.notified = true;
            if (this.props.onSwiped) { this.props.onSwiped("left", translate, this.reset); }
        } else if (translate >= 100 && !this.notified) {
            this.notified = true;
            if (this.props.onSwiped) { this.props.onSwiped("right", translate, this.reset); }
        }
        translate = this.getTranslate(translate);

        if (this.props.max) {
            if (translate < 0 && translate < -this.props.max) {
                translate = -this.props.max;
            }
            if (translate > 0 && translate > this.props.max) {
                translate = this.props.max;
            }
        }

        const styleNew = {
            WebkitTransform: `translate3d(${translate}%, 0, 0)`,
            transform: `translate3d(${translate}%, 0, 0)`,
            // height: null,
        };

        // if (updateHeight) {
        //     styleNew.height = interpolatedStyle.height;
        // }

        return (
            <div className="swipeableviews-container" ref={this.container} style={assign({}, styleNew, styles.container, containerStyle) }>
                {childrenToRender}
            </div>
        );
    }

    render() {
        const {
            /* eslint-disable no-unused-vars */
            onChangeIndex,
            onSwitching,
            resistance,
            threshold,
            /* eslint-enable no-unused-vars */
            animateTransitions,
            children,
            containerStyle,
            slideStyle,
            disabled,
            springConfig,
            style,

        } = this.props;

        const translate = this.translationCurrent * 100;
        // const height = heightLatest;

        const motionStyle = (this.isDragging || !animateTransitions) ? {
            translate: translate,
            // height: height,
        } : {
                translate: spring(translate, springConfig),
                // height: height !== 0 ? spring(height, springConfig) : 0,
            };

        const slideStyleObj = assign({}, styles.slide, slideStyle);

        return (
            <div
                ref={this.wrapper}
                className={ this.props.className || 'swipeable' }
                style={assign({}, styles.root, style) }
                >
                <Motion className="motionwrapper" style={motionStyle}>
                    {(interpolatedStyle) => this.renderContainer(interpolatedStyle, /*updateHeight,*/ children) }
                </Motion>
            </div>
        );
    }
}

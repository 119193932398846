import {
    getBackOfficeEntityDataSource,
    getBackOfficeProxy
} from './index';
import type { IInwinkEntityDataSourceOptions } from '../base/datasource';

export function adminNotificationDataSource(options?: IInwinkEntityDataSourceOptions) {
    return getBackOfficeEntityDataSource<any>("", "adminnotification", "AdminNotification", options);
}

export function dismissAll() {
    return getBackOfficeProxy().postJson("adminnotification/dismissall");
}

export function dismissForMail(mailid) {
    return getBackOfficeProxy().postJson("adminnotification/dismissbymail/" + mailid);
}

import { Popover } from "@inwink/modals";
import { IPopoverManager, React, Loader } from '../../../../commons';
import type { ICMSContext } from "../../../cmscontext";
import { AppLabel } from '../../../../services/i18nservice';
import type { States } from '../../../../services/services';
import type { IExpressionProps } from '../../expressiondesignerv3/definitions';
import { ExpressionBool } from '../../expressiondesignerv3/expressions/expressionbool';
import { ExpressionDate } from '../../expressiondesignerv3/expressions/expressiondate';
import { ExpressionFile } from '../../expressiondesignerv3/expressions/expressionfile';
import { ExpressionGuid } from '../../expressiondesignerv3/expressions/expressionguid';
import { ExpressionMultiSelectList } from '../../expressiondesignerv3/expressions/expressionmultiselectlist';
import { ExpressionNumber } from '../../expressiondesignerv3/expressions/expressionnumber';
import { ExpressionString } from '../../expressiondesignerv3/expressions/expressionstring';
import type { ISlickGridColumn } from "../definitions";
import { ExpressionEntityV3 } from '../../expressiondesignerv3/expressions/expressionentityv3';
import { ExpressionEntitiesV3 } from '../../expressiondesignerv3/expressions/expressionentitiesv3';
import { noFilter } from './nofilter';
import type { IEntityPageConfiguration } from '../entitypagebase.props';
import { availableFilters } from './availableFilters';

import "./filter.less";

export interface IFiltersProps {
    cmscontext: ICMSContext;
    entityconfig: IEntityPageConfiguration;
    column: ISlickGridColumn;
    position: any;
    expression: any;
    i18n: States.i18nState;
    onCompleted?: (res?) => void;
}

export class Filter extends React.Component<IFiltersProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            expressions: props.expression,
            allowSave: false,
            waitingForState: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.expression && (prevProps.expression !== this.props.expression)) {
            this.setState({
                expressions: this.props.expression
            });
        }
    }

    setExpression = (expressions: any, cb = () => null) => {
        this.setState({ expressions}, cb);
    };

    save = () => {
        if (
            (this.state.expressions && this.state.expressions.or && !this.state.expressions.or.length)
            || (
                this.state.expressions && this.state.expressions.or
                && this.state.expressions.or[0].any
                && this.state.expressions.or[0].any.val
                && !this.state.expressions.or[0].any.val.length
            )
        ) {
            this.props.onCompleted(null);
        } else {
            this.setState({ waitingForState: true }, () => {
                this.props.onCompleted(this.state.expressions);
                this.setState({ waitingForState: false });
            });
        }
    };

    setAllowSave = (allowSave: boolean, cb = () => null) => {
        this.setState({ allowSave }, () => {
            cb();
        });
    };

    getType(column: ISlickGridColumn) {
        let elt;
        let props: IExpressionProps;
        if (column.filterable) {
            const eColumn = column.entityColumn;
            props = {
                cmscontext: this.props.cmscontext,
                entityField: eColumn.field,
                fieldItem: {
                    key: column.key,
                    entityname: eColumn.entityname,
                    field: eColumn.field
                },
                entityconfig: this.props.entityconfig,
                setExpression: this.setExpression,
                expressions: this.state.expressions,
                setAllowSave: this.setAllowSave,
                allowSave: this.state.allowSave,
                save: this.save
            };
            if (this.props.entityconfig.specificExpressionComputings
                && this.props.entityconfig.specificExpressionComputings[eColumn.field.key]
                && this.props.entityconfig.specificExpressionComputings[eColumn.field.key].before) {
                this.props.entityconfig.specificExpressionComputings[eColumn.field.key].before(eColumn, props,
                    this.state.expressions, this.setExpression);
            }
            switch (eColumn.field.type) {
                case 'String':
                case 'MultiLineText':
                case 'Text':
                case 'Mail':
                    elt = ExpressionString;
                    break;
                case 'Bool':
                case 'NullableBool':
                    elt = ExpressionBool;
                    break;
                case 'SelectList':
                    elt = ExpressionMultiSelectList;
                    break;
                case 'Number':
                    elt = ExpressionNumber;
                    break;
                case 'Entity':
                    elt = ExpressionEntityV3;
                    break;
                case 'Entities':
                    elt = ExpressionEntitiesV3;
                    break;
                case 'MultiSelectList':
                    elt = ExpressionMultiSelectList;
                    break;
                case 'Date':
                    elt = ExpressionDate;
                    break;
                case 'File':
                case 'Image':
                case 'Picture':
                    elt = ExpressionFile;
                    break;
                case 'Guid':
                    elt = ExpressionGuid;
                    break;
                default:
                    elt = noFilter;
                    break;
            }
        } else {
            elt = noFilter;
        }

        return React.createElement(elt, props);
    }

    clear() {
        this.props.onCompleted(null);
    }

    render() {
        let btnContent = <AppLabel i18n="actions.apply" i18nService={this.props.i18n} />;
        if (this.state.waitingForState) {
            btnContent = <Loader />;
        }
        return <Popover
            autoHeight={true}
            className="filter-popover filters-popover"
            show={true}
            placement="left"
            offset={[0, 45]}
            parent={this.props.position}
            onhide={() => { this.save(); }}
        >
            <div className="filter" style={{ width: (parseInt(this.props.position.style.width, 2) + 20) + 'px' }}>
                {this.getType(this.props.column)}
                <footer
                    className={(availableFilters.indexOf(this.props.column.entityColumn.field.type) === -1) ? 'nofooter' : ''}
                >
                    <button
                        type="button"
                        onClick={() => this.clear()}
                    >
                        <AppLabel
                            i18n={this.props.expression ? "actions.clear" : "actions.cancel"}
                            i18nService={this.props.i18n}
                        />
                    </button>
                    <button disabled={!this.state.allowSave} type="button" onClick={() => this.save()} className="important">
                        {btnContent}
                    </button>
                </footer>
            </div>
        </Popover>;
    }
}

export function openFilter(
    popovermgr: IPopoverManager,
    cmscontext: ICMSContext,
    entityconfig: IEntityPageConfiguration,
    column: ISlickGridColumn,
    expression: any,
    position: any,
    i18n: States.i18nState
) {
    const props: IFiltersProps = {
        cmscontext,
        entityconfig,
        column,
        position,
        expression,
        i18n
    };

    return popovermgr.modalPortal(Filter, props);
}

import React from 'react';
import { AppTextLabel } from "../../../commons";
import { UrlInputRaw } from "./urlInputRaw";
import { EntityValidationError } from "@inwink/entityform";

interface IUrlInputRawWrapper {
    fieldName?: string;
    onChange: (fieldKey: string, fieldValue: any, fieldPatch?: any) => void;
    onValidChange?: (isValid: boolean) => void;
    validations?: EntityValidationError[];
    addValidationError?: (fieldKey: string, err: EntityValidationError) => Promise<any>;
    removeValidationError?: (fieldKey: string, err: EntityValidationError, options?: any) => Promise<any>;
    checkValidations?: () => void;
    entityName?: string;
    value: string;
    showLabel?: boolean;
    readOnly?: boolean;
    instanceId?: string;
}

export const onValidationUrlChange = (isValid: boolean, fieldName, validations, addValidationError, removeValidationError, checkValidations) => {
    const fieldValidationError = { field: fieldName || 'link', error: 'invalidurl' };

    const alreadyErrorsValidation = validations?.filter((validation) => {
        return validation.field === fieldValidationError?.field && validation.error === fieldValidationError?.error;
    });

    if (!isValid && !alreadyErrorsValidation?.length) {
        if (addValidationError) {
            addValidationError(fieldValidationError?.field, fieldValidationError).then(() => {
                if (checkValidations)
                    checkValidations();
            });
        }
    } else if (isValid && alreadyErrorsValidation?.length) {
        if (removeValidationError) {
            removeValidationError(fieldValidationError?.field, fieldValidationError, { findIndexByObject: true }).then(() => {
                if (checkValidations)
                    checkValidations();
            });
        }
    }
};

export const UrlInputRawWrapper = ({
    fieldName,
    onChange,
    onValidChange,
    validations,
    addValidationError,
    removeValidationError,
    checkValidations,
    entityName,
    value,
    showLabel,
    readOnly,
    instanceId
}: IUrlInputRawWrapper) => {
    const onUrlChange = (url: string) => onChange(fieldName, url);

    const onValidUrlChange = (isValid: boolean) => {
        // custom valid :
        if (onValidChange) { return onValidChange(isValid); }

        // generic valid:
        onValidationUrlChange(isValid, fieldName, validations, addValidationError, removeValidationError, checkValidations);
    };

    return (
        <div>
            { showLabel && <AppTextLabel component="label" i18n={`${entityName?.toLowerCase()}.${fieldName?.toLowerCase()}`} /> }

            <UrlInputRaw
                onUrlChange={onUrlChange}
                onValidUrlChange={onValidUrlChange}
                needCheckUrl
                value={value}
                readOnly={readOnly}
                instanceId={instanceId}
            />
        </div>
    );
};

import {React } from '../../commons';
import type {IAppShellHeaderPartProps} from './appshellheader';
import { OnboardingContext, IOnboardingContext } from '../onboarding/context';
import {AppShellHeaderActionButton} from './appshellheaderactionbutton';

interface IAppShellOnBoardingProps extends IAppShellHeaderPartProps {
    afterClick: () => void;
    parent: any;
}

export function AppShellOnBoarding(props: IAppShellOnBoardingProps) {
    return <OnboardingContext.Consumer>
        {(onboardingcontext) => <AppShellOnBoardingContent {...props} onboardingcontext={onboardingcontext} />}
    </OnboardingContext.Consumer>;
}

interface IAppShellOnBoardingContentProps extends IAppShellOnBoardingProps {
    onboardingcontext: IOnboardingContext;
}

class AppShellOnBoardingContent extends React.Component<IAppShellOnBoardingContentProps, any> {
    showOnBoarding = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        if (this.props.user.advancedMode) {
            this.props.onboardingcontext.reload();
        } else {
            this.props.onboardingcontext.showCurrent();
        }
        this.props.afterClick();
    };

    render() {
        if (!this.props.onboardingcontext || !this.props.onboardingcontext.currentKey) {
            return null;
        }

        return <AppShellHeaderActionButton
            className="appshellonboarding"
            onClick={this.showOnBoarding}
            icon="inwink-idea"
            title="appheaderactions.showonboarding"
        />;
    }
}

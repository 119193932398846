import type { Entities } from '@inwink/entities/entities';
import { guid } from '@inwink/utils/methods';
import type {
    IEntityPageConfiguration,
    EntityPageRunMode,
    IEntityViewsState
} from './entitypagebase.props';
import {
    toastSuccess,
    toastError,
    IPopoverManager
} from '../../../commons';
import { confirmModal } from "../../ui/modals/confirmmodal";
import type { States } from '../../../services/services';
import { EntityViewSelectorConfirmCloneModal } from './tools/viewselector.confirmclonemodal';

export class EntityViewsManager {
    state: IEntityViewsState = { selection: [], viewEntityName: null, runMode: null, rowCount: null, forceDefaultView: false };

    stateChanged: (state: IEntityViewsState, prevState: IEntityViewsState) => void;

    triggerRefreshGrid: () => void;

    constructor(
        public mainId: string,
        public entityconfiguration: IEntityPageConfiguration,
        public i18n: States.i18nState,
        public supportedLanguages: string[],
        initviewId: string,
        public initfilters: any,
        selection: any[],
        runMode: EntityPageRunMode,
        forceDefaultView: boolean,
        public entityconfigurationArgs: any
    ) {
        this.state.forceDefaultView = forceDefaultView || false;
        this.state.runMode = runMode;
        this.state.selection = selection || [];
        this.state.viewEntityName = (this.entityconfiguration.entityName || '')
            + (this.entityconfiguration.storeKey || '')
            + (this.entityconfiguration.entityNameAdditionalName || '');

        this.initViewManager(initviewId);
    }

    private setState = (statePatch: Partial<IEntityViewsState>, callback?) => {
        const prevState = this.state;
        this.state = Object.assign({}, this.state, statePatch);

        if (this.stateChanged) {
            this.stateChanged(this.state, prevState);
        }

        if (callback) {
            callback();
        }
    };

    setRowCount(count?: number) {
        this.setState({ rowCount: count });
    }

    setSelection(selection: any[]) {
        const patch: Partial<IEntityViewsState> = { selection: selection };
        if (!selection || !selection.length) {
            patch.showSelection = false;
        }

        this.setState(patch);
    }

    setRefreshGrid = (refreshGrid) => {
        this.triggerRefreshGrid = refreshGrid;
    };

    showSelection(show: boolean) {
        const patch: Partial<IEntityViewsState> = { showSelection: show };
        this.setState(patch);
    }

    updateCurrentViewContent(viewContentPatch: Partial<Entities.IEntityViewContent>) {
        const viewPatch: Partial<Entities.IEntityView> = {
            content: Object.assign({}, this.state.currentview.content, viewContentPatch)
        };

        this.updateCurrentView(viewPatch);
    }

    reload() {
        this.updateCurrentViewContent({
            columnKeys: this.state.currentview?.content?.columnKeys && [...this.state.currentview.content.columnKeys]
        });
        this.triggerRefreshGrid();
    }

    setCurrentView(view: Entities.IEntityView) {
        const statePatch: Partial<IEntityViewsState> = {
            currentview: view,
            viewisdirty: false
        };

        const expressions = this.searchField(view.content.search, view.content.filters);
        Object.assign(statePatch, expressions);

        this.setState(statePatch);

        this.setViewToStorage(view);
    }

    updateCurrentView(viewPatch: Partial<Entities.IEntityView>) {
        const viewContentPatch = viewPatch.content;
        const statePatch: Partial<IEntityViewsState> = {
            viewisdirty: true,
            currentview: Object.assign({}, this.state.currentview, viewPatch)
        };

        if (viewContentPatch
            && ((viewContentPatch.search != null && viewContentPatch.search !== undefined) || viewContentPatch.filters)) {
            const search = (viewContentPatch.search != null && viewContentPatch.search !== undefined)
                ? viewContentPatch.search : this.state.currentview.content.search;
            const filters = viewContentPatch.filters ? viewContentPatch.filters : this.state.currentview.content.filters;
            const expressions = this.searchField(search, filters);
            Object.assign(statePatch, expressions);
        }

        this.setState(statePatch);
        // #38551- on n'enregistre pas la search dans la vue en cours
        if (viewContentPatch && !viewContentPatch.search && viewContentPatch.filters) {
            this.setViewToStorage(this.state.currentview);
        }
    }

    // set only the view we're using
    setViewToStorage(view: Entities.IEntityView) {
        if (view) { // if (this.state.runMode === 'entitypage' && view) {
            if (view.id || view.title === "") {
                localStorage.setItem(`${this.mainId}#${this.state.viewEntityName}`, JSON.stringify(view));
            } else {
                localStorage.removeItem(`${this.mainId}#${this.state.viewEntityName}`);
            }
        }
    }

    /* If we don't find a view with API, we try localStorage */
    getViewFromStorage = (): Entities.IEntityView => {
        const viewStr = localStorage.getItem(`${this.mainId}#${this.state.viewEntityName}`);
        if (viewStr && this.state.forceDefaultView !== true) {
            return JSON.parse(viewStr);
        }

        return null;
    };

    getDefaultView() {
        let defaultview: Entities.IEntityView = this.getViewFromStorage();
        if (
            !defaultview
            || (defaultview && defaultview.title)
        ) {
            const configurationArgsClean = {};
            if (this.entityconfigurationArgs) {
                Object.keys(this.entityconfigurationArgs).forEach((key) => {
                    const typ = typeof this.entityconfigurationArgs[key];
                    if (typ === "string" || typ === "boolean" || typ === "number" || typ === "bigint") {
                        configurationArgsClean[key] = this.entityconfigurationArgs[key];
                    }
                });
            }
            const filters = this.initfilters?.initialStartFilters || [];
            defaultview = {
                isShared: true,
                title: "",
                entityName: this.state.viewEntityName,
                content: Object.assign({
                    defaultExpr: this.initfilters && this.initfilters.filters,
                    filters,
                    columnKeys: this.entityconfiguration.defaultColumns || [],
                    configurationArgs: configurationArgsClean
                })
            };
        }
        return defaultview;
    }

    initViewManager(initviewId: string) {
        this.refreshViews().then((allviews) => {
            let currentview: Entities.IEntityView = null;
            if (initviewId) {
                currentview = allviews.filter((v) => v.id === initviewId)[0];
            }

            if (!currentview) {
                currentview = this.getViewFromStorage();
            }

            if (currentview == null) {
                currentview = this.getDefaultView();
            }

            const expressions = this.searchField(currentview.content.search, currentview.content.filters);

            currentview.content = currentview.content || {};
            let columnKeys = currentview.content.columnKeys;
            if (!columnKeys || !columnKeys.length) {
                columnKeys = this.entityconfiguration && this.entityconfiguration.defaultColumns;
            }
            currentview.content.columnKeys = columnKeys;

            this.setState({
                ...expressions,
                viewisdirty: false,
                currentview: currentview
            });
        });
    }

    refreshViews(): Promise<Entities.IEntityView[]> {
        if (!this.entityconfiguration.datasource.getViews) {
            return Promise.resolve([]);
        }

        return this.entityconfiguration.datasource.getViews(this.state.viewEntityName).then((res) => {
            this.setState({
                allviews: res
            });

            return res;
        }, (err) => console.error(err));
    }

    cloneCurrentView = (popovermgr) => {
        const viewToSave = { ...this.state.currentview };
        delete viewToSave.id;
        viewToSave.entityViewId = guid();
        viewToSave.entityName = this.state.viewEntityName;

        return popovermgr?.modalPortal(EntityViewSelectorConfirmCloneModal,
            null, "viewselector-confirmclone-modal", null).then((res) => {
            if (res?.viewName) {
                viewToSave.title = res.viewName;
                return this.entityconfiguration.datasource.setView(viewToSave)
                    .then((_res: any) => {
                        toastSuccess(this.i18n, "actions.updateentityview.success");
                        this.setCurrentView(_res);
                        this.refreshViews();
                        return Promise.resolve(true);
                    })
                    .catch(() => { toastError(this.i18n, "actions.updateentityview.error"); });
            }
        });
    };

    saveCurrentView = (popovermgr) => {
        if (this.state.currentview.id) {
            return confirmModal(popovermgr, "modal.confirm.exit", "entityviewselector.save.confirm.desc").then((res) => {
                if (res) {
                    return this.entityconfiguration.datasource.updateView(this.state.currentview).then(() => {
                        toastSuccess(this.i18n, "actions.updateentityview.success");
                        this.refreshViews();
                        return Promise.resolve(true);
                    }).catch(() => {
                        toastError(this.i18n, "actions.updateentityview.error");
                        return Promise.resolve(false);
                    });
                }
            });
        }
        const viewToSave = { ...this.state.currentview };
        viewToSave.entityName = this.state.viewEntityName;
        return this.entityconfiguration.datasource.setView(this.state.currentview).then((res: any) => {
            toastSuccess(this.i18n, "actions.updateentityview.success");
            this.setCurrentView(res);
            this.refreshViews();
            return Promise.resolve(true);
        }).catch(() => {
            toastError(this.i18n, "actions.updateentityview.error");
            return Promise.resolve(false);
        });
    };

    deleteView = (popovermgr: IPopoverManager, view: Entities.IEntityView): Promise<any> => {
        return confirmModal(popovermgr, "modal.confirm.exit", "modal.confirm.remove").then((res) => {
            if (res) {
                return this.entityconfiguration.datasource.deleteView(view.id).then(() => {
                    toastSuccess(this.i18n, "entityviewselector.delete.success");
                    this.refreshViews();
                }, (err) => {
                    toastError(this.i18n, "entityviewselector.delete.failed");
                    console.error("deleteView failed", err);
                });
            }
        });
    };

    filtersToExpressionQuery(filters, searchexpression) {
        const filterKeys = Object.keys(filters);

        const expressions = filterKeys.map((k) => filters[k]);
        if (searchexpression) {
            expressions.push(searchexpression);
        }

        if (expressions.length === 0) {
            return null;
        }
        if (expressions.length === 1) {
            return expressions[0];
        }

        return { and: expressions };
    }

    searchField = (searchTerm, filters) => {
        let searchExpression = null;
        if (searchTerm) {
            searchExpression = { and: [] };
            const searchtokens = searchTerm.split(' ');
            const entityconf = this.entityconfiguration;
            searchtokens.forEach((token) => {
                const tokenExpression = { or: [] };
                if (entityconf.searchFields && entityconf.searchFields.length) {
                    entityconf.searchFields.forEach((searchField) => {
                        if (typeof searchField !== "string" && searchField.localizable && this.supportedLanguages) {
                            tokenExpression.or.push({
                                name: searchField.key,
                                op: "contains",
                                val: token,
                                lng: this.supportedLanguages
                            });
                        } else {
                            tokenExpression.or.push({ name: searchField, op: "contains", val: token });
                        }
                    });
                } else {
                    toastError(this.i18n, "grid.search.error.nosearchfield");
                    return null;
                }

                if (tokenExpression.or.length === 1) {
                    searchExpression.and.push(tokenExpression.or[0]);
                } else {
                    searchExpression.and.push(tokenExpression);
                }
            });

            if (searchExpression.and.length === 1) {
                searchExpression = searchExpression.and[0];
            }
        }

        const res: Partial<IEntityViewsState> = {
            searchExpression: searchExpression,
            queryExpression: this.filtersToExpressionQuery(filters, searchExpression)
        };

        return res;
    };
}

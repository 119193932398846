import type { Entities } from '@inwink/entities/entities';
import { React, ReactDom, AppTextLabel } from '../../../../commons';
import type { ICMSContext } from "../../../cmscontext";
import type { States } from '../../../../services/services';
import { ColumnItem } from './columnitem';
import { IFieldPickerItem, getFields } from "../../fieldpickerv3/definitions";
import type { IEntityPageConfiguration } from '../entitypagebase.props';

import "./columnselector.less";

declare const $;

interface IColumnsSelectorProps {
    i18nHelper: Entities.i18nHelper;
    datastamp: any;
    entityname: string;
    template: Entities.IEntityTemplateV3;
    excludedColumns: string[];
    selectedColumns: string[];
    i18n: States.i18nState;
    cmscontext: ICMSContext;
    fieldFilter?: (key: string, field: Entities.IEntityFieldTemplateV3,
        template: Entities.IEntityTemplateV3, entityname: string) => boolean;
    onChange: (selectedColumns: string[]) => void;
    entityConfs: Record<string, IEntityPageConfiguration>;
    lockedColumns?: string[];
}

export interface IGridSettingsColumn extends IFieldPickerItem {
    _?: any;
}

interface IColumnsSelectorState {
    testSelected?: string;
    columns: IGridSettingsColumn[];
    // selectedColumns: string[];
    all?: Record<string, IGridSettingsColumn>;
    focusedColumn?: IGridSettingsColumn;
    remainingColumns: IGridSettingsColumn;
}

export class ColumnsSelector extends React.Component<IColumnsSelectorProps, IColumnsSelectorState> {
    constructor(props: IColumnsSelectorProps) {
        super(props);

        const columns = getFields(props.i18nHelper, props.entityname, props.cmscontext.displayLanguage,
            props.selectedColumns, props.template, props.fieldFilter);

        this.state = {
            all: columns.all,
            columns: columns.excludedColumns,
            remainingColumns: columns.remainingColumns
        };
    }

    componentDidMount() {
        this.generateColumnsHandler();
    }

    componentDidUpdate(prevprops: IColumnsSelectorProps) {
        if (prevprops.datastamp !== this.props.datastamp) {
            this.updateColumnsSelection(this.props.selectedColumns, this.props.template);
        }

        if (prevprops.template !== this.props.template || prevprops.i18n !== this.props.i18n) {
            const columns = getFields(this.props.i18nHelper, this.props.entityname, this.props.cmscontext.displayLanguage,
                this.props.selectedColumns, this.props.template, this.props.fieldFilter);
            this.setState({
                all: columns.all,
                columns: columns.excludedColumns,
                remainingColumns: columns.remainingColumns
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(columns.excludedColumns.map((k) => k.key));
                }
            });
        }
    }

    updateColumnsSelection(selectedColumns: string[], entity: Entities.IEntityTemplateV3) {
        const props = this.props;
        const columns = getFields(props.i18nHelper, props.entityname, props.cmscontext.displayLanguage,
            selectedColumns, entity, props.fieldFilter);

        this.setState({
            columns: columns.excludedColumns,
            remainingColumns: columns.remainingColumns,
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(columns.excludedColumns.map((k) => k.key));
            }
        });
    }

    componentWillUnmount() {
        // eslint-disable-next-line react/no-find-dom-node
        $('ul.columnselector-dnd', ReactDom.findDOMNode(this)).sortable("destroy");
    }

    generateColumnsHandler() {
        // eslint-disable-next-line react/no-find-dom-node
        $('ul.columnselector-dnd', ReactDom.findDOMNode(this)).sortable({
            update: this.updateColumnsOrder,
            handle: '.can-handle',
            items: 'li',
            axis: "y"
        });
    }

    updateColumnsOrder = () => {
        const newSelectedOrder: string[] = [];
        // eslint-disable-next-line react/no-find-dom-node
        $('ul.columnselector-dnd li', ReactDom.findDOMNode(this)).each((newIndex, columnElement) => {
            const key = $(columnElement).attr('data-key');
            if (key) {
                newSelectedOrder.push(key);
            }
        });

        this.updateColumnsSelection(newSelectedOrder, this.props.template);
    };

    addEmptyColumn = () => {
        this.setState((prevState) => {
            const selected = [...prevState.columns];
            selected.push({
                // isFake: true,
                key: `newColumn-${selected.length}`,
                entityname: prevState.remainingColumns.entityname,
                parent: prevState.remainingColumns,
                label: null,
                field: null
            });
            return { columns: selected };
        });
    };

    updateColumn = (column: IGridSettingsColumn, newcolumn: IGridSettingsColumn, field: string) => {
        if (column.key !== field) {
            const newselected = [...this.state.columns];

            const selectedColumnIdx = newselected.indexOf(column);
            if (selectedColumnIdx >= 0) {
                newselected[selectedColumnIdx] = newcolumn;
                this.update(newselected);
            }
        }
    };

    update(newselected: IGridSettingsColumn[]) {
        const selection = newselected
            // .filter((s) => !s.isFake)
            .map((s) => s.key);
        this.updateColumnsSelection(selection, this.props.template);
    }

    removeColumn = (column: IGridSettingsColumn) => {
        const newselected = [...this.state.columns];
        const selectedIdx = newselected.indexOf(column);
        if (selectedIdx >= 0) {
            newselected.splice(selectedIdx, 1);
            this.update(newselected);
        }
    };

    onFocus = (column: IGridSettingsColumn) => {
        this.setState({ focusedColumn: column });
    };

    render() {
        let addButton = null;
        if (this.state.remainingColumns.childs) {
            addButton = (
                <button type="button" className="add-column" onClick={this.addEmptyColumn}>
                    <i className="inwink-add" /> <AppTextLabel i18n="actions.addcolumn" />
                </button>
            );
        }

        const columnsItems = [];
        if (this.state.columns) {
            this.state.columns.forEach((col) => {
                const isLocked = (this.props.lockedColumns && this.props.lockedColumns.indexOf(col.key) > -1) || false;
                columnsItems.push(
                    <ColumnItem
                        key={col.key}
                        column={col}
                        isLocked={isLocked}
                        cmscontext={this.props.cmscontext}
                        i18n={this.props.i18nHelper}
                        entityname={this.props.entityname}
                        displayLanguage={this.props.cmscontext.displayLanguage}
                        template={this.props.template}
                        allColumns={this.state.all}
                        remainingColumns={this.state.remainingColumns}
                        selectedColumns={this.props.selectedColumns}
                        focusedColumn={this.state.focusedColumn}
                        updateColumn={this.updateColumn}
                        removeColumn={this.removeColumn}
                        onFocus={this.onFocus}
                        fieldFilter={this.props.fieldFilter}
                        excludedColumns={this.props.excludedColumns}
                        entityConfs={this.props.entityConfs}
                    />
                );
            });
        }

        return <div className="columnselector">
            <ul className="columnselector-dnd">
                {columnsItems}
            </ul>
            {addButton}
        </div>;
    }
}

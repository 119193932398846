/* eslint-disable max-classes-per-file */
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';
import type { ICMSContext } from '@@components/cmscontext';
import { React, AppTextLabel } from '../../../commons';
import { EntityPicker } from '@@components/entities/entitypicker';
import { CollapsiblePanel } from '@inwink/collapsiblepanel';

interface IUserStatusInputBaseProps {
    readOnly?: boolean;
    cmscontext: ICMSContext;
}

interface IUserStatusValue {
    requireConnexion?: boolean;
    requireRegistration?: boolean;
    requireMembership?: boolean;
    membershipActive?: boolean;
    membershipLevelIds?: string[];
}

export interface IUserStatusInputProps extends IUserStatusInputBaseProps {
    value?: IUserStatusValue;
    onChange: (arg: IUserStatusValue) => void;
}

export class UserStatusInput extends React.PureComponent<IUserStatusInputProps, any> {
    setVal = (arg: React.FormEvent<HTMLSelectElement>) => {
        if (!this.props.readOnly) {
            const val = arg.currentTarget.value;
            if (val === "0") {
                this.props.onChange({ requireConnexion: null,
                    requireRegistration: null,
                    requireMembership: null,
                    membershipActive: null });
                return;
            }
            if (val === "1") {
                this.props.onChange({ requireConnexion: false,
                    requireRegistration: null,
                    requireMembership: null,
                    membershipActive: null });
                return;
            }
            if (val === "2") {
                this.props.onChange({ requireConnexion: true,
                    requireRegistration: null,
                    requireMembership: null,
                    membershipActive: null });
                return;
            }
            // event values
            if (val === "3") {
                this.props.onChange({ requireConnexion: true,
                    requireRegistration: true,
                    requireMembership: null,
                    membershipActive: null });
                return;
            }
            if (val === "4") {
                this.props.onChange({ requireConnexion: true,
                    requireRegistration: false,
                    requireMembership: null,
                    membershipActive: null });
                return;
            }
            if (val === "5") {
                this.props.onChange({ requireConnexion: null,
                    requireRegistration: false,
                    requireMembership: null,
                    membershipActive: null });
                return;
            }
            // community values
            if (val === "6") {
                this.props.onChange({ requireConnexion: true,
                    requireRegistration: null,
                    requireMembership: true,
                    membershipActive: true });
                return;
            }
            if (val === "7") {
                this.props.onChange({ requireConnexion: true,
                    requireRegistration: null,
                    requireMembership: true,
                    membershipActive: false });
                return;
            }
            if (val === "8") {
                this.props.onChange({ requireConnexion: true,
                    requireRegistration: null,
                    requireMembership: false,
                    membershipActive: null });
                return;
            }
        }
    };

    getValue() {
        if (this.props.value != null && this.props.value !== undefined) {
            if (this.props.value.requireConnexion === true) {
                if (this.props.cmscontext.relatedKind === "Event") {
                    if (this.props.value.requireRegistration === true) {
                        return "3";
                    }
                    return this.props.value.requireRegistration === false ? "4" : "2";
                }
                if (this.props.cmscontext.relatedKind === "Community") {
                    if (this.props.value.requireRegistration == null && 
                        this.props.value.membershipActive == null && 
                        this.props.value.requireMembership == null){
                        return "2";
                    }
                    if (this.props.value.requireMembership === true
                        && this.props.value.membershipActive === true) {
                        return "6";
                    }
                    if (this.props.value.requireMembership === true
                        && this.props.value.membershipActive === false) {
                        return "7";
                    }
                    if (this.props.value.requireMembership === false) {
                        return "8";
                    }
                }
            } if (this.props.value.requireConnexion === false) {
                return "1";
            } if (this.props.value.requireRegistration === false) {
                return "5";
            }
        }

        return "0";
    }

    getOptions = () => {
        const options = [
            <AppTextLabel key="0" component="option" value="0" i18n="edit.userstatus.none" />,
            <AppTextLabel key="1" component="option" value="1" i18n="edit.userstatus.requirenotconnexion" />,
            <AppTextLabel key="2" component="option" value="2" i18n="edit.userstatus.requireconnexion" />,
        ];
        if (this.props.cmscontext.relatedKind === "Event") {
            options.push(
                <AppTextLabel key="3" component="option" value="5" i18n="edit.userstatus.requirenotregistration" />,
                <AppTextLabel
                    key="4"
                    component="option"
                    value="3"
                    i18n="edit.userstatus.requireconnexionandrequireregistration"
                />,
                <AppTextLabel
                    key="5"
                    component="option"
                    value="4"
                    i18n="edit.userstatus.requireconnexionandrequirenotregistration"
                />
            );
        } else if (this.props.cmscontext.relatedKind === "Community") {
            options.push(
                <AppTextLabel key="3" component="option" value="6" i18n="edit.userstatus.requirememberactive" />,
                <AppTextLabel key="4" component="option" value="7" i18n="edit.userstatus.requirememberinactive" />,
                <AppTextLabel key="5" component="option" value="8" i18n="edit.userstatus.requirenotmember" />,
            );
        }

        return options;
    };

    userStatusOptionalParameterRender = () => {
        const val = this.getValue();
        if (val === "6"
            || val === "7") {
            return <CollapsiblePanel
                className="fieldbloc child"
                expanded
            >
                <AppTextLabel component="label" i18n="edit.userstatus.membershippicker" />
                <EntityPicker
                    entityName="MembershipLevel"
                    disableAddNew
                    multiselect
                    selection={(this.props.value?.membershipLevelIds?.length
                        ? this.props.value.membershipLevelIds.map((id) => { return { id: id }; }) : [])}
                    onChange={(membershipIds) => {
                        const value = Object.assign({}, this.props.value, {
                            membershipLevelIds: membershipIds
                        });
                        this.props.onChange(value);
                    }}
                />
            </CollapsiblePanel>;
        }

        return null;
    };

    render() {
        const val = this.getValue();
        return <>
            <div className="fieldbloc">
                <AppTextLabel component="label" i18n="edit.userstatus" />
                <select
                    value={val}
                    onChange={this.setVal}
                >
                    {this.getOptions()}
                </select>
            </div>
            {this.userStatusOptionalParameterRender()}
        </>;
    }
}

export interface IUserStatusInputPropertyProps extends IUserStatusInputBaseProps {
    data: any;
    path: string;
    onChange: (data: any) => void;
}

export class UserStatusPropertyInput extends React.PureComponent<IUserStatusInputPropertyProps, any> {
    onChange = (txt) => {
        const data = immutable.set(this.props.data, this.props.path, txt);
        this.props.onChange(data);
    };

    render() {
        const val = getPropertyValue(this.props.data, this.props.path);
        return <UserStatusInput
            {...this.props}
            value={val}
            onChange={this.onChange}
        />;
    }
}

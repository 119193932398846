import * as React from 'react';

export interface VisualStateSwitchProps {
    visualstate: string[];
    visualLimit: string;
}

export class VisualStateSwitch extends React.Component<VisualStateSwitchProps, any> {
    render() {
        if (!this.props.visualstate || !this.props.visualstate.length) {
            return <div></div>;
        }
        var isInState = (this.props.visualstate.indexOf(this.props.visualLimit) >= 0);
        if (isInState) {
            return this.props.children[0];
        } else {
            return this.props.children[1];
        }
    }
}
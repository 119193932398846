import { combineReducers } from 'redux';

import { i18nReducer } from './i18nservice';
import { eventReducer } from './eventservice';
import { communityReducer } from './communityservice';
import { userReducer } from './userservice';
import { customerReducer } from './customerservice';
import { rightsReducer } from './rightsservice';
import { notificationsReducer } from './notificationservice';
import { pageReducer } from './pageservice';
import { appmenuReducer } from './appmenuservice';
import { appthemeReducer } from './appthemeservice';

export const getRootReducer = () => {
    return combineReducers({
        i18n: i18nReducer,
        event: eventReducer,
        community: communityReducer,
        customer: customerReducer,
        user: userReducer,
        rights: rightsReducer,
        pages: pageReducer,
        notifications: notificationsReducer,
        appmenu: appmenuReducer,
        apptheme: appthemeReducer
    } as any);
};

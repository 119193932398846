/* eslint-disable max-classes-per-file */
import { React, Component, AppTextLabel, Loader, bindActionCreators,
    connectwith, WithPopoverManager, IPopoverManager, AppAsyncButton } from '../commons';
import { actions as i18nActions } from '../services/i18nservice';
import { I18nTextInput } from "./ui/configinputs";
import { getTradsByKey, setTradsByKey } from '../api/backoffice/botrads';
import type {States} from '../services/services';

export interface ITradsHandlerProps {
    i18n : States.i18nState;
    user: States.IAppUserState;
    rights: States.IAppUserRights;
    i18nActions? : typeof i18nActions;
}

@connectwith(null, (dispatch) => { return { i18nActions: bindActionCreators(i18nActions, dispatch) }; })
export class TradsHandler extends React.PureComponent<ITradsHandlerProps, any> {
    render() {
        return <WithPopoverManager>
            {(popovermgr) => <TradsHandlerContent {...this.props} popovermgr={popovermgr} />}
        </WithPopoverManager>;
    }
}

interface ITradsHandlerContentProps extends ITradsHandlerProps {
    popovermgr: IPopoverManager;
}
class TradsHandlerContent extends React.PureComponent<ITradsHandlerContentProps, any> {
    registered : boolean;

    componentDidMount() {
        this.checkHandler();
    }

    componentDidUpdate() {
        this.checkHandler();
    }

    checkHandler() {
        if (this.props.rights && this.props.rights.apprights && this.props.rights.apprights
            && this.props.rights.apprights.trads && this.props.rights.apprights.trads.edit) {
            if (this.props.user.editTrads) {
                if (!this.registered) {
                    document.body.addEventListener('contextmenu', this.contextmenu as any);
                    this.registered = true;
                }
            } else {
                document.body.removeEventListener('contextmenu', this.contextmenu as any);
                this.registered = false;
            }
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('contextmenu', this.contextmenu as any);
    }

    contextmenu = (arg:React.MouseEvent<any>) => {
        let elt = arg.target as HTMLElement;
        let count = 0;
        while (count < 5 && !elt.dataset.trad && elt.parentElement) {
            elt = elt.parentElement;
            count++;
        }
        if (elt.dataset.trad) {
            arg.preventDefault();
            openTradPopover(this.props.popovermgr, elt, {
                i18n: this.props.i18n,
                i18nActions: this.props.i18nActions,
                tradcode: elt.dataset.trad
            });
            console.log("trad", elt.dataset.trad);
        }
    };

    render() {
        return null;
    }
}

function openTradPopover(popovermgr: IPopoverManager, elt, props:IBoTradPopoverProps) {
    return popovermgr.popoverPortal(elt, BoTradPopover, props, "botradpopover");
}

interface IBoTradPopoverProps{
    tradcode : string;
    i18n : States.i18nState;
    i18nActions : typeof i18nActions;
    hidetradCodeInput? : boolean;
    onCompleted?: (res?) => void;
}
export class BoTradPopover extends Component<IBoTradPopoverProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            datastamp: new Date(),
            loading: true,
            tradcode: this.props.tradcode
        };
    }

    componentDidMount() {
        this.loadTrad();
    }

    loadTrad() {
        this.setState({ trads: null, loading: true });
        getTradsByKey(this.state.tradcode).then((trads) => {
            const tradsbag = {};
            trads.forEach((t) => {
                tradsbag[t.language] = t.text;
            });
            this.setState({ datastamp: new Date(), trads: tradsbag, loading: false });
        }, (err) => {
            console.error(err);
            this.setState({ trads: null, loading: false });
        });
    }

    validateTrads = (arg:React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        return setTradsByKey(this.state.tradcode, this.state.trads).then(() => {
            return this.props.i18nActions.syncCurrent();
        }).then(() => {
            this.props.onCompleted();
        });
    };

    reload = (arg:React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        this.loadTrad();
    };

    codeChanged = (arg: React.FormEvent<HTMLInputElement>) => {
        this.setState({ tradcode: arg.currentTarget.value });
    };

    render() {
        let loader;
        if (this.state.loading) {
            loader = <div className="botradload">
                <Loader />
            </div>;
        }
        return <div className="botrad">
            <div className="botradform">
                <div className=" formpage">
                    {this.props.hidetradCodeInput ? null
                        : <div className="tradcode">
                            <input type="text" value={this.state.tradcode} onChange={this.codeChanged} />
                            <button type="button" onClick={this.reload}><i className="inwink-sync" /></button></div>}
                    <I18nTextInput
                        i18n=""
                        stamp={this.state.datastamp}
                        multiline={true}
                        canEditTrad={false}
                        languages={this.props.i18n.supported}
                        value={this.state.trads}
                        onChange={(trads) => this.setState({ trads: trads})}
                    />
                </div>
                <div className="actions">
                    <AppAsyncButton className="important" onClick={this.validateTrads}>
                        <AppTextLabel i18n="actions.save" /></AppAsyncButton>
                </div>
            </div>
            {loader}
        </div>;
    }
}

import { getCommunityAPIProxy } from './community/index';
import { getCustomerAPIProxy } from './customers';
import { getEventAPIProxy } from './events/index';

export function getExportFileAccess(eventId?: string, communityId?: string, authTenantId?: string, filename?: string) {
    if (eventId) {
        return getEventAPIProxy().getJson(`${eventId}/file/exportaccess?filename=${encodeURIComponent(filename)}`);
    }
    if (communityId) {
        return getCommunityAPIProxy().getJson(`${communityId}/file/exportaccess?filename=${encodeURIComponent(filename)}`);
    }
    if (authTenantId) {
        return getCustomerAPIProxy()
            .getJson(`audience/${authTenantId}/file/exportaccess?filename=${encodeURIComponent(filename)}`);
    }

    throw new Error("Not implemented");
}

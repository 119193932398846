import type { Entities } from '@inwink/entities/entities';
import { cleanLabels, replaceAll, removeDiacritics } from '@inwink/utils/methods';
import type { EntityGrid } from './index';
import type { IEntityGridColumn, ISlickGridColumn } from '../definitions';
import { availableFilters } from '../filters/availableFilters';
import type { IEntityPageConfiguration, IEntityPageActionContext } from '../entitypagebase.props';

const defaultColumnSize = 140;
const defaultColumnSizes = {
    string: 350,
    text: 350,
    selectList: 200,
    multiselectlist: 200,
    entity: 200,
    entities: 200,
    bool: 200,
    date: 150,
    file: 80,
    document: 80,
    number: 150
};

export class EntityGridColumns {
    allColumns: Record<string, IEntityGridColumn>;

    displayColumns: ISlickGridColumn[];

    columnsWidth: Record<string, number>;

    entityConfs: Record<string, IEntityPageConfiguration>;

    constructor(public entitygrid: EntityGrid, public currentPage: string) {
        let tmp = localStorage.getItem('gridcolumns-' + this.currentPage);
        if (tmp) {
            this.columnsWidth = JSON.parse(tmp);
        } else {
            tmp = localStorage.getItem("inwink_column_width");
            if (tmp) {
                const allColumnWidth = JSON.parse(localStorage.getItem("inwink_column_width"));
                if (allColumnWidth && allColumnWidth[this.currentPage]) {
                    this.columnsWidth = allColumnWidth[this.currentPage];
                }
            }
        }

        if (!this.columnsWidth) {
            this.columnsWidth = {};
        }
    }

    setGridListeners() {
        this.entitygrid.grid.onColumnsReordered.subscribe(() => {
            const selected = this.entitygrid.grid.getColumns().map((column) => column.key).filter((column) => !!column);
            this.updateColumnsSelection(selected);
        });

        this.entitygrid.grid.onColumnsResized.subscribe(() => {
            for (let i = 0, totI = this.entitygrid.grid.getColumns().length; i < totI; i++) {
                const column = this.entitygrid.grid.getColumns()[i];
                if (column.width !== column.previousWidth) {
                    this.setColumnWidth(column.field, column.width);
                }
            }
        });
    }

    private getColumnWidth(id, columnType: string) {
        if (!id) {
            return null;
        }

        let tmp = this.columnsWidth[id];
        if (!tmp) {
            tmp = this.entitygrid.props.entityconfiguration
                && this.entitygrid.props.entityconfiguration.grid
                && this.entitygrid.props.entityconfiguration.grid.customColumnWidth
                && this.entitygrid.props.entityconfiguration.grid.customColumnWidth[id];
            if (!tmp && columnType) {
                tmp = defaultColumnSizes[columnType.toLowerCase()];
            }
        }

        return tmp || defaultColumnSize;
    }

    setColumnWidth = (id, width) => {
        this.columnsWidth[id] = width;
        localStorage.setItem('gridcolumns-' + this.currentPage, JSON.stringify(this.columnsWidth));
    };

    private setGridColumnsToDisplay(columns: IEntityGridColumn[]) {
        const editColumn: ISlickGridColumn = {
            isTechnical: true,
            cssClass: 'cell-action',
            headerCssClass: 'cell-fixed hide-hover',
            id: "edit",
            name: "",
            field: "edit",
            formatter: () => {
                const editTitle = this.entitygrid.props.i18nHelper.translate('entity.actions.edit');
                return `<span class="btn-cell" data-commmand="edit" title="${editTitle}"><i class="inwink-pencil"></i></span>`;
            },
            width: 40,
            filters: false,
            resizable: false,
            toolTip: ''
        };

        const pageActionContext: IEntityPageActionContext = {
            cmscontext: this.entitygrid.props.cmscontext,
            entityRights: this.entitygrid.props.entityRights,
            viewsState: this.entitygrid.props.viewsState,
            entityconfiguration: this.entitygrid.props.entityconfiguration,
            entityactions: this.entitygrid.props.entityactions,
            helpers: this.entitygrid.props.helpers,
            runMode: this.entitygrid.props.runMode
        };

        const entityactions = this.entitygrid.props.entityactions || this.entitygrid.props.entityconfiguration.entityactions;
        const namedactions = entityactions?.entityActions && entityactions.entityActions.filter((ea) => !!ea.name);

        const itemactions = entityactions && entityactions.entityActions && entityactions.entityActions
            .filter((action) => {
                if (
                    namedactions.indexOf(action) === -1
                    && (action.icon || action.title)
                    && (Object.prototype.hasOwnProperty.call(action, 'show') === false || action.show(pageActionContext) === true)
                ) {
                    return true;
                }
                return false;
            });

        let itemActionsColumns = [];
        let entityActionsColumns = [];

        if (namedactions) {
            entityActionsColumns = namedactions.map((action) => {
                const nameO = action.name(null);
                const name = this.entitygrid.props.i18nHelper.translate(nameO, null, nameO);
                return {
                    isTechnical: true,
                    cssClass: 'cell-action action-' + action.id,
                    headerCssClass: 'cell-fixed action-' + action.id,
                    id: action.id,
                    name: name,
                    field: action.id,
                    formatter: (row, cell, value) => {
                        if (action.title || action.icon) {
                            return `<span class="btn-cell" title="${this.entitygrid.props.i18nHelper.translate(action.title)}">
                                <i class="${action.icon}"></i>
                            </span>`;
                        }
                        return value;
                    },
                    width: this.getColumnWidth(action.id, null) || action.width || 40,
                    filters: false,
                    resizable: false
                };
            });
        }

        if (!this.entitygrid.props.runMode
            || (this.entitygrid.props.runMode !== 'dataimport'
            && this.entitygrid.props.runMode !== 'removedentitypage')) {
            if (itemactions && itemactions.length === 1) {
                itemActionsColumns = [
                    {
                        isTechnical: true,
                        cssClass: 'cell-action',
                        headerCssClass: 'cell-fixed',
                        name: "",
                        field: "_itemactions",
                        formatter: () => {
                            const title = this.entitygrid.props.i18nHelper.translate(itemactions[0].title);
                            return `<span class="btn-cell" title="${title}">
                                    <i class="${itemactions[0].icon}"></i>
                                </span>`;
                        },
                        width: 40,
                        filters: false,
                        resizable: false
                    }
                ];
            } else if (itemactions && itemactions.length >= 1) {
                itemActionsColumns = [
                    {
                        isTechnical: true,
                        cssClass: 'cell-action',
                        headerCssClass: 'cell-fixed',
                        id: "_itemactions",
                        name: "",
                        field: "_itemactions",
                        formatter: () => {
                            return `<span class="btn-cell" data-command="more"
                                    title="${this.entitygrid.props.i18nHelper.translate('entity.actions.more')}"
                                >
                                   <i class="inwink-more-h"></i>
                                </span>`;
                        },
                        width: 40,
                        filters: false,
                        resizable: false
                    }
                ];
            }
        }

        const selectColumn = this.entitygrid.checkboxSelector ? this.entitygrid.checkboxSelector.getColumnDefinition() : null;
        if (selectColumn) {
            selectColumn.cssClass = 'checkbox-inwink';
            selectColumn.headerCssClass = 'checkbox-inwink-header hide-hover';
            selectColumn.width = 45;
            selectColumn.isTechnical = true;
            selectColumn.toolTip = '';

            const editAction = [];
            if (this.entitygrid.props.onItemSelected
                || (this.entitygrid.props.entityconfiguration.detailConfig
                    && !this.entitygrid.props.entityconfiguration.detailConfig.disableUpdate)) {
                editAction.push(editColumn);
            }

            const columnsToDisplay = [selectColumn, ...editAction, ...entityActionsColumns, ...itemActionsColumns]
                .concat(columns.map((c) => c.gridColumn));

            this.displayColumns = columnsToDisplay;
            this.entitygrid.grid.setColumns(columnsToDisplay);
            // test frozencolumn
            // this.entitygrid.grid.setOptions(Object.assign({}, this.entitygrid.optionsGrid, { frozenColumn: 1 }));
            // this.entitygrid.grid.invalidate();
        }
    }

    updateColumnsSelection(columnsKey: string[], templateChanged = true) {
        if (templateChanged) {
            this.buildColumns(this.entityConfs);
        }

        const selection = columnsKey.map((c) => this.allColumns[c.toLowerCase()]).filter((c) => !!c);

        this.setGridColumnsToDisplay(selection);

        this.entitygrid.props.viewsManager.updateCurrentViewContent({ columnKeys: columnsKey });
    }

    private buildColumns(entityConfs: Record<string, IEntityPageConfiguration> = null) {
        const i18n = this.entitygrid.props.i18nHelper;
        const columns: Record<string, IEntityGridColumn> = {};
        this.buildEntityColumns(
            this.entitygrid.props.entityconfiguration,
            i18n,
            this.entitygrid.props.cmscontext.displayLanguage,
            columns,
            this.entitygrid.props.entityconfiguration.entityName,
            this.entitygrid.props.template, null, "",
            entityConfs
        );
        this.allColumns = columns;
    }

    private buildEntityColumns(
        entityconf: IEntityPageConfiguration,
        i18n: Entities.i18nHelper,
        displayLanguage: string,
        _columns: Record<string, IEntityGridColumn>,
        entityname: string,
        entity: Entities.IEntityTemplateV3,
        _parent: IEntityGridColumn,
        rootkey: string,
        entityConfs: Record<string, IEntityPageConfiguration>
    ) {
        const columns = _columns;
        const parent = _parent;
        if (entity.fields) {
            const fieldKeys = Object.keys(entity.fields);
            fieldKeys.forEach((fieldKey) => {
                const colkey = rootkey ? rootkey + '.' + fieldKey : fieldKey;
                if (this.entitygrid.props
                    && this.entitygrid.props.entityconfiguration
                    && this.entitygrid.props.entityconfiguration.excludedFields) {
                    const res = this.entitygrid.props.entityconfiguration.excludedFields
                        .filter((_c) => _c.toLowerCase() === colkey.toLowerCase()).length > 0;
                    if (res) {
                        return;
                    }
                }

                const field = entity.fields[fieldKey];
                let label;
                const tradkey = (entityname + "." + fieldKey).toLowerCase();
                if (field.display && field.display.labels && field.display.labels[displayLanguage]) {
                    label = field.display.labels[displayLanguage];
                } else {
                    label = i18n.translate(tradkey) || tradkey;
                }

                const fieldname = field.key.toLowerCase();
                const gridconf = entityconf && entityconf.grid;
                const cellProcessor = gridconf && gridconf.customColumn && gridconf.customColumn[fieldname];
                const allowOpenEntity = gridconf?.openDetailForColumns?.some((key) => key === fieldname);
                const openChildEntity = allowOpenEntity && !!parent  && rootkey;

                const column: IEntityGridColumn = {
                    key: colkey,
                    entityname: entityname,
                    label: label,
                    filterlabel: label && removeDiacritics(label),
                    tradkey: tradkey,
                    field: field,
                    parent: parent,
                    customData: entityconf && entityconf.customData,
                    gridColumn: null,
                    cellProcessor: cellProcessor,
                    allowOpenEntity: allowOpenEntity,
                    openChildEntity: openChildEntity,
                    defaultWidth: entityconf?.grid?.customColumnWidth
                        && entityconf.grid.customColumnWidth[field.key.toLowerCase()]
                };

                const viewContent = this.entitygrid.props.viewsState?.currentview?.content;
                column.gridColumn = this.fieldToSlickGridColumn(colkey, label, field, column, {
                    filters: !!((viewContent && viewContent.filters[colkey]))
                });

                columns[colkey.toLowerCase()] = column;
                if (parent) {
                    parent.childs = parent.childs || [];
                    parent.childs.push(column);
                }

                if (field.type === "Entity" || field.type === "Entities") {
                    if (field.template) {
                        const entityConf = entityConfs && entityConfs[field.value.toLowerCase()];
                        this.buildEntityColumns(entityConf, i18n, displayLanguage, columns,
                            field.value, field.template, column, colkey, entityConfs);
                    }
                }
            });
        }
    }

    initColumns(columnKeys: string[], entityConfs: Record<string, IEntityPageConfiguration>) {
        // if (!columnKeys) {
        //     debugger;
        // }
        const tryGetColumn = (columnKey?: string): IEntityGridColumn => {
            if (!columnKey) {
                return null;
            }
            let ret = null;
            Object.keys(this.allColumns).map((col) => {
                if (col.toLowerCase() === columnKey.toLowerCase()) {
                    ret = this.allColumns[col];
                }
                return null;
            });
            return ret;
        };

        this.buildColumns(entityConfs);
        this.entityConfs = entityConfs;
        const selection = columnKeys.map(tryGetColumn);
        this.setGridColumnsToDisplay(selection
            .filter((c) => !!c));
    }

    fieldToSlickGridColumn(key: string, name: string, field: Entities.IEntityFieldTemplateV3,
        entitycolumn: IEntityGridColumn, patch: Partial<ISlickGridColumn> = null) {
        let expands;
        if (field.type === 'Entity') {
            //
            const conf = this.entitygrid.props.entityConfs && this.entitygrid.props.entityConfs[field.value.toLowerCase()];
            if (conf && conf.cellRenderer && conf.cellRenderer.entity) {
                expands = expands || [];
                expands.push(...conf.cellRenderer.entity.expands.map((e) => key + "." + e));
            }
        } else if (field.type === 'Entities') {
            const conf = this.entitygrid.props.entityConfs && this.entitygrid.props.entityConfs[field.value.toLowerCase()];
            if (conf && conf.cellRenderer && conf.cellRenderer.entities) {
                expands = expands || [];
                expands.push(...conf.cellRenderer.entities.expands.map((e) => key + "." + e));
            }
        } else if (entitycolumn.parent) {
            const conf = this.entitygrid.props.entityConfs
                && this.entitygrid.props.entityConfs[entitycolumn.parent.field.value.toLowerCase()];
            if (conf && conf.idFields) {
                expands = [key, ...conf.idFields.map((f) => entitycolumn.parent.key + "." + f)];
            }
        }

        const viewContent = this.entitygrid.props.viewsState?.currentview?.content;
        const filters = !!((viewContent && viewContent.filters[key]));
        const filterable = fieldIsFilterable(field, entitycolumn.entityname);
        const canBeNull = fieldCanBeNull(field);

        let colname = null;
        const label = cleanLabels(name);
        const title = replaceAll(label, "\"", "\"\"");
        if (entitycolumn && entitycolumn.parent) {
            colname = `<span class="col-name withparent">
                <span class="col-parentlabel">${cleanLabels(entitycolumn.parent.label)}</span>
                <span class="col-label" title="${title}">${label}</span></span>`;
        } else {
            colname = `<span class="col-name" title="${title}">${cleanLabels(name)}</span>`;
        }

        const i18n = this.entitygrid.props.i18nHelper;
        const tooltipTrad = i18n.translate("grid.tooltip");

        const slickcolumn: ISlickGridColumn = {
            id: key,
            key: key,
            name: colname,
            expands,
            width: entitycolumn.defaultWidth || this.getColumnWidth(key, field.type),
            entityColumn: entitycolumn,
            canBeNull: canBeNull,
            cssClass: ("col-" + key) + " " + ((field.type === "File") ? 'cell-action' : ''),
            sortable: fieldIsSortable(field, entitycolumn.entityname),
            filterable: filterable,
            field: key,
            formatter: this.entitygrid.formatmgr.getCellFormatter(key, field, entitycolumn),
            headerCssClass: ("col-" + key) + " " + (((availableFilters.indexOf(field.type) === -1))
                ? ' header-nofilter-available' : ''),
            filters: filters,
            valuesList: field.valuesList,
            type: field.type,
            header: (!filterable || availableFilters.indexOf(field.type) === -1) ? {} : {
                buttons: [{
                    cssClass: ((filters) ? "inwink-filter_on" : "inwink-filter"),
                    tooltip: tooltipTrad,
                    command: "filters",
                }]
            }
        };

        return Object.assign(slickcolumn, patch);
    }
}

export function fieldIsSortable(field: Entities.IEntityFieldTemplateV3, entityname: string): boolean {
    if (field.key === 'id') {
        return false;
    }

    if (field.type === "Entity" || field.type === "Entities") {
        return false;
    }

    if (field.type === "MultiSelectList") {
        return false;
    }

    if (field.type === "Array") {
        return false;
    }

    if (field.type === "Object") {
        return false;
    }

    if (field.key === 'badge' && entityname === "Person") {
        return false;
    }

    if (field.type === "File" || field.type === "Image") {
        return false;
    }

    return true;
}

export function fieldIsFilterable(field: Entities.IEntityFieldTemplateV3, entityname: string): boolean {
    if ((field?.metadata as any)?.cannotBeFilterable) {
        return false;
    }
    if (field.key === 'badge' && entityname === "Person") {
        return false;
    }

    return field.key !== 'id';
}

export function fieldCanBeNull(field: Entities.IEntityFieldTemplateV3): boolean {
    return field && field.metadata && field.metadata.canBeNull;
}

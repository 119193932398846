import * as moment from 'moment';
import { RecentContents } from '../../routes/common/recentcontents';
import { React, AppTextLabel, connectwith, bindActionCreators, showBetaFeatures, toastError, Loader } from '../../commons';
import { actions as userActions } from '../../services/userservice';
import { actions as i18nActions } from '../../services/i18nservice';
import { Changelog } from './appshellchangelog';
import { UserProfilePopinContentSearch } from './appshelluser.popin.contentsearch';
import type { IPopinContentProps } from './appshelluser.popin';

@connectwith(null, (dispatch) => ({
    i18nActions: bindActionCreators(i18nActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
}))
export class UserProfilePopinContent extends React.Component<IPopinContentProps, any> {
    constructor(props: IPopinContentProps) {
        super(props);
        this.state = {
            currentLang: props.i18n.currentLanguageCode,
            showBetaFeatures: showBetaFeatures(),
            logoutLoading: false
        };
    }

    componentDidMount() {
        const profile = this.props.user.currentUser && this.props.user.currentUserProfile;
        const showPoping = !profile || !profile.firstname || !profile.lastname || !profile.company;
        if (showPoping) {
            setTimeout(() => this.updateProfile(null), 1000);
        }
    }

    signout = (arg) => {
        this.setState({
            logoutLoading: true
        }, () => {
            (this.props.userActions.logout() as any).then(() => {
            // nothing, the page will be reload
                this.togglePopin(arg);
            })
                .catch((err) => {
                    this.setState({logoutLoading: false});
                    console.error('LogoutError', err);
                    toastError(this.props.i18n, "auth.logout.err");
                });
        });
    };

    changeEvent = (arg) => {
        inwink.history.push('/');
        this.togglePopin(arg);
    };

    getCookieExpireDate() {
        const cookieTimeout = 33696000000;
        const date = new Date();
        date.setTime(date.getTime() + cookieTimeout);
        const expires = "; expires=" + date.toUTCString();
        return expires;
    }

    changeLng(l) {
        if ((l !== this.state.currentLang)) {
            this.setState({ currentLang: l }, () => {
                document.cookie = 'currentlang=' + l + '; ' + this.getCookieExpireDate() + ' ; path=/';
                const act = this.props.i18nActions.loadLanguage(l) as any;
                act.then(() => {
                    this.props.i18nActions.saveLanguagePreferences();
                });
                moment.locale(l);
            });
        }
    }

    about = (arg) => {
        this.togglePopin(arg);
        this.props.popovermgr.sidemodalPortal(Changelog, {
            event: this.props.event
        }, "changelogmodal");
    };

    selectContent(content, arg: React.MouseEvent<any>) {
        this.togglePopin(arg);
        inwink.history.push(`/${content.element.customerId}/${content.type === "event" ? "e" : "c"}/${content.element.id}`);
    }

    toggleEditTrads = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        this.props.userActions.editTrads(!this.props.user.editTrads);
    };

    updateProfile = (arg: React.MouseEvent<any>) => {
        if (arg) {
            arg.preventDefault();
            arg.stopPropagation();
        }
        return import('../userprofile/index').then((mod) => this.props.popovermgr.modalPortal(mod.UserProfileUpdate,
            this.props, "userprofileupdatemodale", { disableOverlayDismiss: true }));
    };

    showBetaFeatures = (arg) => {
        this.setState({
            showBetaFeatures: arg
        }, () => {
            inwink.showBetaFeatures(this.state.showBetaFeatures);
        });
    };

    gotologs = (arg: React.MouseEvent<any>) => {
        this.togglePopin(arg);
        let params = '';
        if (this.props.event && this.props.event.eventid) {
            params = `?eventid=${this.props.event.eventid}`;
        }
        inwink.history.push(`/root/logs${params}`);
    };

    togglePopin = (arg: React.MouseEvent<any>) => {
        if (arg) {
            arg.preventDefault();
            arg.stopPropagation();
        }

        this.props.userpopinContext.togglePopin();
    };

    render() {
        let edittrads;
        let adminlogs;
        let contentsearch;
        if (this.props.rights?.apprights?.trads?.edit) {
            edittrads = <li key="edit-trads" className="clickable edittradsmenu">
                <button
                    type="button"
                    className="linkstyle"
                    onClick={this.toggleEditTrads}
                >
                    <i className={"edittrads inwink-checked " + (this.props.user.editTrads ? "edit-on" : "edit-off")} />
                    <AppTextLabel i18nService={this.props.i18n} i18n="app.edittrads" />
                </button>
            </li>;
        }

        if (this.props.rights?.apprights?.logs) {
            adminlogs = <li key="admin-logs" className="clickable">
                <button
                    type="button"
                    className="linkstyle"
                    onClick={this.gotologs}
                >
                    <AppTextLabel i18nService={this.props.i18n} i18n="app.logs" />
                </button>
            </li>;
            contentsearch = <UserProfilePopinContentSearch {...this.props} />;
        }

        let logoutLoader;
        if (this.state.logoutLoading) {
            logoutLoader = <Loader message="" />;
        }

        return <div className={"appshell-profile-popin" + (this.props.userpopinContext.showPopin ? " showpopin" : "")}>
            <div className="overlay-profile" onClick={this.togglePopin} />
            <div className="profile-popin">
                <div className="profile-popin-content">
                    <ul className="profile-actions">
                        <li key="current-user" className="currentuser">
                            <div className="icon">
                                <i className="inwink-account" />
                            </div>
                            <div className="details">
                                <div className="username">
                                    <button
                                        type="button"
                                        className="linkstyle"
                                        onClick={this.updateProfile}
                                    >
                                        <i className="inwink-pencil" />{this.props.user.currentUser.fullname}
                                    </button>
                                </div>
                                <div className="logout">
                                    <button
                                        type="button"
                                        className="linkstyle"
                                        style={{display: "flex"}}
                                        onClick={this.signout}
                                    >
                                        <AppTextLabel i18nService={this.props.i18n} i18n="userprofile.signout" />
                                        {logoutLoader}
                                    </button>
                                </div>
                                <div className="langs">
                                    {
                                        this.props.i18n.supported.map((l, i) => {
                                            return <button
                                                type="button"
                                                key={i}
                                                onClick={() => this.changeLng(l)}
                                                className={(l === this.state.currentLang) ? 'important' : ''}
                                            >
                                                {l.toUpperCase()}
                                            </button>;
                                        })
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <a className="linkstyle" href="https://training.inwink.com/" target="_blank" rel="noreferrer">
                                <AppTextLabel i18n="userprofile.inwinktraining" />
                            </a>
                        </li>
                        <li key="event-selection" className="eventselection">
                            <AppTextLabel
                                className="changeevent"
                                component="a"
                                onClick={this.changeEvent}
                                i18nService={this.props.i18n}
                                i18n="userprofile.change_project"
                            />
                            <RecentContents
                                {...this.props}
                            >
                                {(elements) => {
                                    return <>
                                        <div className="recentcontents">
                                            {elements?.map((elmt) => {
                                                return <button
                                                    type="button"
                                                    className={"linkstyle " + elmt.type}
                                                    key={elmt.element.id}
                                                    onClick={(arg) => this.selectContent(elmt, arg)}
                                                >
                                                    <img
                                                        alt="inwink"
                                                        src={(inwink.config.assetsUrl || "/")
                                                            + "assets/images/inwink_logo_people.svg"}
                                                        className="eventlogo"
                                                    />
                                                    {elmt.element.title || elmt.element.name}
                                                </button>;
                                                
                                            })}
                                        </div>
                                    </>;
                                }}
                            </RecentContents>
                            {contentsearch}
                        </li>
                        {adminlogs}
                        {edittrads}
                        <li>
                            <a className="linkstyle" href="https://training.inwink.com/" target="_blank" rel="noreferrer">
                                <AppTextLabel i18n="userprofile.inwinktraining" />
                            </a>
                        </li>
                        <li>
                            <a className="linkstyle" href="https://www.inwink.com" target="_blank" rel="noreferrer">
                                <AppTextLabel i18n="userprofile.visitinwink" />
                            </a>
                        </li>
                    </ul>

                </div>
                <button
                    type="button"
                    className="btnclosepopin"
                    onClick={this.togglePopin}
                >
                    <i className="inwink-dialog-cancel" />
                </button>
            </div>
        </div>;
    }
}

import * as assign from 'lodash/assign';
import { logging } from '@inwink/logging';
import { States } from './services';
import { rightsActions } from './rightsservice';
// import { getParentNames as getEventParentNames } from '../api/events/eventdetail';
// import { getParentNames as getCommunityParentNames} from '../api/community/communitydetail';

const logger = logging.getLogger("data");

const INITIAL_STATE = {
    customerid: null,
    currentTenantId: null,
    detail: null,
    menuUpdate: null,
    tenantDetail: null
} as States.ICustomerState;

export function customerReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "CUSTOMER_SETID": {
            const clean = state.customerid !== action.payload.customerid
                ? INITIAL_STATE
                : null;
            return assign({}, state, clean, {
                customerid: action.payload.customerid,
                menuUpdate: new Date()
            });
        }
        case "CUSTOMER_SETTENANTID": {
            return assign({}, state, {
                currentTenantId: action.payload.tenantid,
                tenantDetail: action.payload.tenantdetail
            });
        }
        case "CUSTOMER_CLEAR": {
            return INITIAL_STATE;
        }
        case "CUSTOMER_SETDETAIL": {
            return assign({}, state, action.payload);
        }
        case "CUSTOMER_REFRESHMENU": {
            return assign({}, state, {
                menuUpdate: new Date()
            });
        }
        case "CUSTOMER_TENANTCLEAR": {
            return assign({}, state, {
                currentTenantId: null,
                tenantDetail: null,
                tenantUpdate: new Date()
            });
        }
        default:
            return state;
    }
}

export const customerActions = {
    refreshMenu() {
        return (dispatch) => {
            dispatch({ type: 'CUSTOMER_REFRESHMENU' });
        };
    },
    setCustomerId(customerid: string) {
        logger.info("CUSTOMER_SETID " + customerid);
        return (dispatch, getState) => {
            dispatch({ type: 'CUSTOMER_SETID', payload: { customerid: customerid } });
            rightsActions.customerRights(customerid)(dispatch, getState);
        };
    },
    setTenant(tenantid: string, tenantDetail: AuthTenant.ITenant) {
        logger.info("CUSTOMER_SETTENANTID " + tenantid);
        return (dispatch, getState) => {
            dispatch({ type: 'CUSTOMER_SETTENANTID', payload: { tenantid: tenantid, tenantdetail: tenantDetail } });
            rightsActions.tenantRights(tenantDetail.customerId, tenantid)(dispatch, getState);
        };
    },
    // setCustomerDetailForEvent(eventid: string, customerid: string) {
    //     logger.info("CUSTOMER_SETDETAIL " + customerid);
    //     // const ds = customerDataSource(customerid);
    //     return (dispatch, getState) => {
    //         getEventParentNames(eventid).then((res) => {
    //             const state: States.IAppState = getState();
    //             let payload = {
    //                 detail: {
    //                     name: res.customerName
    //                 },
    //                 customerid: customerid,
    //                 currentTenantId: res.tenantId,
    //                 tenantDetail: {
    //                     name: res.tenantName
    //                 }
    //             } as Partial<States.ICustomerState>;
    //             if (state.customer?.customerid !== customerid) {
    //                 payload = { detail: Object.assign({}, state.customer.detail, payload.detail) };
    //             }
    //             dispatch({ type: 'CUSTOMER_SETDETAIL', payload: payload });
    //         });
    //     };
    // },
    // setCustomerDetailForCommunity(communityId: string, customerid: string) {
    //     logger.info("CUSTOMER_SETDETAIL " + customerid);
    //     // const ds = customerDataSource(customerid);
    //     return (dispatch, getState) => {
    //         getCommunityParentNames(communityId).then((res) => {
    //             const state: States.IAppState = getState();
    //             let payload = {
    //                 detail: {
    //                     name: res.customerName
    //                 },
    //                 customerid: customerid,
    //                 currentTenantId: res.tenantId,
    //                 tenantDetail: {
    //                     name: res.tenantName
    //                 }
    //             } as Partial<States.ICustomerState>;
    //             if (state.customer?.customerid !== customerid) {
    //                 payload = { detail: Object.assign({}, state.customer.detail, payload.detail) };
    //             }
    //             dispatch({ type: 'CUSTOMER_SETDETAIL', payload: payload });
    //         });
    //     };
    // },
    setCustomerFullDetail(detail) {
        logger.info("CUSTOMER_SETDETAIL", detail);
        return (dispatch) => {
            dispatch({ type: 'CUSTOMER_SETDETAIL', payload: { detail: detail, tenantDetail: null, currentTenantId: null } });
        };
    },
    clearCurrentCustomer(): (dispatch, getState) => void {
        return (dispatch) => {
            dispatch({ type: "CUSTOMER_CLEAR" });
        };
    },
    clearCurrentTenant(): (dispatch, getState) => void {
        return (dispatch) => {
            dispatch({ type: "CUSTOMER_TENANTCLEAR" });
        };
    }
};

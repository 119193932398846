import * as omit from 'lodash/omit';
import type { IPopoverDismissManager, IPopoverManager } from '@inwink/modals';
import type { Entities } from '@inwink/entities/entities';
import type { ICMSContext } from "../../../../cmscontext";
import { React, i18NHelperContext } from '../../../../../commons';
import { ModalContent } from "../../../../ui/modals/modalcontent";
import { SimpleTabs, ISimpleTabItemGroup } from "../../../../ui/simpletabs";
import { confirmModal } from "../../../../ui/modals/confirmmodal";
import { States } from '../../../../../services/services';
import type { IEntityGroupDefinition } from '../../../definitions';
import { FilterResume } from './filterresume';
import { FilterAdvanced } from './filteradvanced';
import type { IFiltersPanelProps } from './definitions';
import type { IEntityPageConfiguration } from '../../entitypagebase.props';

import "./filters.less";

interface IFiltersProps {
    cmscontext: ICMSContext;
    popover?: IPopoverDismissManager;
    popovermgr: IPopoverManager;
    filters: any;
    entityconfiguration: IEntityPageConfiguration;
    i18n: States.i18nState;
    user: States.IAppUserState;
    entityParent?: string;
    onCompleted?: (res?) => void;
    personnalized?: any;
    i18nHelper?: Entities.i18nHelper;
}

interface IFiltersState {
    template: Entities.IEntityTemplateV3;
    filters: any;
    removed: any[];
    added: any[];
    isDirtyFilters: boolean;
    isFetching: boolean;
    groups: Record<string, IEntityGroupDefinition>;
}

export class Filters extends React.Component<IFiltersProps, IFiltersState> {
    panels: ISimpleTabItemGroup[];

    constructor(props) {
        super(props);
        this.state = {
            template: null,
            filters: Object.assign({}, this.props.filters),
            removed: [],
            added: [],
            isDirtyFilters: false,
            isFetching: true,
            groups: null
        };

        this.panels = [{
            items: [
                {
                    id: "resume",
                    label: "filters.resume",
                    component: FilterResume,
                },
                {
                    id: "advanced",
                    label: "filters.advanced",
                    component: FilterAdvanced,
                }
            ]

        }];

        if (props.personnalized) {
            this.panels[0].items.push({
                id: "personnalized",
                label: "filters.personnalized",
                component: props.personnalized,
            });
        }
    }

    save() {
        this.props.onCompleted(this.state.filters);
    }

    componentDidMount() {
        this.props.entityconfiguration.datasource.worktemplate().then((template) => {
            const entityDefinition: IEntityGroupDefinition = {
                title: 'entity',
                template: template,
                entityName: this.props.entityconfiguration.entityName
            };

            this.setState({
                template: template,
                isFetching: false,
                groups: {
                    entity: entityDefinition
                }
            });
        }, () => {
            this.setState({ isFetching: false });
        });
    }

    addFilter = (expression, key) => {
        if (expression) {
            this.setState((prevState) => {
                return {
                    filters: Object.assign(prevState.filters, {
                        [key]: expression
                    }),
                    isDirtyFilters: true,
                    added: [...prevState.added, key]
                };
            });
        } else {
            this.removeFilter(key);
        }
    };

    removeFilter = (key) => {
        this.setState((prevState) => {
            return {
                filters: omit(prevState.filters, key),
                isDirtyFilters: true,
                removed: [...prevState.removed, key]
            };
        });
    };

    getPanelProps = () => {
        const props: IFiltersPanelProps = {
            popovermgr: this.props.popovermgr,
            cmscontext: this.props.cmscontext,
            i18n: this.props.i18n,
            i18nHelper: this.props.i18nHelper,
            user: this.props.user,
            template: this.state.template,
            filters: this.state.filters,
            removeFilter: this.removeFilter,
            addFilter: this.addFilter,
            entityconfiguration: this.props.entityconfiguration,
            entityParent: this.props.entityParent,
            groups: this.state.groups
        };
        return props;
    };

    canClosePage = () => {
        if (this.state.isDirtyFilters) {
            return confirmModal(this.props.popovermgr, "modal.confirm.exit", "modal.confirm.entitydetail").then((res) => {
                if (res) {
                    this.save();
                    return Promise.resolve(false);
                }
                return Promise.resolve(true);
            });
        }
        return Promise.resolve(true);
    };

    render() {
        const actions = [{
            i18n: "actions.apply",
            direction: "right",
            callback: () => { this.save(); },
            isImportant: true
        },
        {
            i18n: "actions.close",
            direction: "left",
            callback: () => { this.props.onCompleted(); },
            isImportant: false
        },
        {
            i18n: "actions.reset",
            direction: "left",
            callback: () => {
                window.localStorage.removeItem("inwink_entityview");
                this.props.onCompleted("reset");
            },
            isImportant: false
        }];

        return <ModalContent
            isFetching={this.state.isFetching}
            onCompleted={this.props.onCompleted}
            actions={actions}
            title="actions.filter"
        >
            <div className="filters">
                {this.state.template
                    ? <SimpleTabs desktopmode="top" getPanelProps={this.getPanelProps} groups={this.panels} /> : null}
            </div>
        </ModalContent>;
    }
}

export function openFilters(
    popovermgr: IPopoverManager, cmscontext: ICMSContext, filters, i18n, user: States.IAppUserState,
    entityconfiguration: IEntityPageConfiguration, entityParent?, personnalized?
) {
    const props: IFiltersProps = {
        cmscontext,
        popovermgr,
        filters,
        i18n,
        user,
        entityconfiguration,
        entityParent,
        personnalized
    };

    return popovermgr.modalPortal((fprops) => <i18NHelperContext.Consumer>{
        (i18nHelper) => <Filters {...fprops} i18nHelper={i18nHelper} />
    }</i18NHelperContext.Consumer>, props, 'filters-modal', {
        disableOverlayDismiss: true
    });
}

import { defaultPostHeaderForJsonData, IAPIProxy } from '@inwink/apibase';
import type {
    IInWinkAdminEntityDataSource,
    IInwinkEntityQueryResult,
    IInwinkEntityUpdateOptions
} from '@inwink/apibase/inwinkdatasource';
import { Entities } from '@inwink/entities/entities';
import { expressionBuilder } from '@inwink/expressions/expressionbuilder';
import { convertFromOldFormat, explode } from '@inwink/expressions/converter';
import { IExpressionFilter } from '@inwink/expressions/expressionbuilder';
import type { IColumn } from '../../components/entities/definitions';
import { ISavePayloadEntityFormater } from '../../components/entities/formater/PayloadEntityFormaters';

interface IInwinkEntityV3QuerySelects {
    [key: string]: boolean | IInwinkEntityV3QuerySelects;
}

export interface IInwinkEntityV3QueryOptions {
    page?: {
        index?: number;
        size?: number;
        returnTotalCount?: boolean;
    };
    search?: string;
    order?: any;
    selects?: IInwinkEntityV3QuerySelects;
    filters?: IExpressionFilter;
    where?: any;
    totalCount?: boolean;
    /**
     * @deprecated format v2
     */
    expression?: any;
    queryString?: string;
    continuationTokenMode?: boolean;
}
export interface IInwinkEntityDataSourceOptions {
    query?: IInwinkEntityV3QueryOptions;
    beforeCreate?: (entity: any) => any;
    beforeUpdate?: (entity: any) => any;
    additionnalColumns?: IDataSourceV3Column[];
}

export interface IDataSourceV3Options extends IInwinkEntityDataSourceOptions {
    forceDirectMassDelete?: boolean;
    expandWorkTemplates?: boolean | Record<string, boolean>;
    savePayloadFormater?: ISavePayloadEntityFormater;
    viewSuffix?: string;
    customWorkTemplateProviders?: Record<string, () => Promise<Entities.IEntityTemplateV3>>;
}

export interface IDataSourceV3Column {
    key: string;
    titles?: Record<string, string>;
    type: string;
    isSelected?: boolean;
    orderable?: boolean;
    filterable?: boolean;
    valuesList?: any;
    expands?: string[];
}

export interface IImportPreviewResult {
    entities: Array<{
        entity: any;
        modelErrors: string[];
        validationErrors: string[]
    }>;
    exceptionMessage?: string;
}

export interface IImportPreviewQuery {
    identifiers: any;
    entities: any[];
}
/**
 * @todo migration v3
 */
export interface IInWinkAdminEntityV3DataSource<T> extends IInWinkAdminEntityDataSource<T> {
    query?: (options?: IInwinkEntityV3QueryOptions, expands?: any[]) => Promise<IInwinkEntityQueryResult<T>>;
}

export interface IExportV3Payload extends IInwinkEntityV3QueryOptions {
    columns: IExportV3Column[];
    expands: string[];
    title?: string;
}

export interface IPathPattern {
    $format: {
        $pattern: string,
        $values: any[]
    };
}
interface IExportFile {
    pathPattern: IPathPattern;
    selector: Record<string, any>;
    format?: string;
}

export interface IExportFilePayload extends IInwinkEntityV3QueryOptions {
    files: IExportFile[];
}
export interface IExportV3Column {
    label: string;
    selector: any;
}

export interface IMassUpdateRequest<T> {
    filters: any;
    input: Partial<T>;
}

export interface IDataSourceV3<T> extends IInWinkAdminEntityV3DataSource<T> {
    entityName: string;
    options: IDataSourceV3Options;
    customColumns: IDataSourceV3Column[];
    excludeColumns: string[];
    getAll(queryOptions?: IInwinkEntityV3QueryOptions, expands?): Promise<IInwinkEntityQueryResult<T>>;
    getById: (entityid) => Promise<T>;
    importPreview: (query: IImportPreviewQuery) => Promise<IImportPreviewResult>;
    worktemplate: (forceReload?: boolean, disableExpandWorkTemplates?: boolean) => Promise<Entities.IEntityTemplateV3>;
    exportV3: (query: IExportV3Payload, direct?: boolean) => Promise<any>;
    exportFile: (query: IExportFilePayload, direct?: boolean) => Promise<any>;
    massUpdate: (input: Partial<T>, filters, direct?: boolean, populateWithMergeArray?: boolean) => Promise<any>;
    massDelete: (queryOptions?: IInwinkEntityV3QueryOptions) => Promise<IInwinkEntityQueryResult<T>>,
    getDocumentUrl: (entityid: string, templateid: string, lang: string) => Promise<any>;
    queryHistory?: (options?: IInwinkEntityV3QueryOptions, expands?: any[]) => Promise<IInwinkEntityQueryResult<T>>;
    queryRemoved?: (options?: IInwinkEntityV3QueryOptions, expands?: any[]) => Promise<IInwinkEntityQueryResult<T>>;
    countRemoved?: (options?: IInwinkEntityV3QueryOptions) => Promise<number>;
}

export function getSelectsFromExpands(expands: string[], selects = {}) {
    if (!!expands && expands.length > 0) {
        const arrayToObject = (_obj, arr, idx) => {
            const obj = _obj;
            const token = arr[idx];
            if (idx === arr.length - 1) {
                if (typeof obj[token] === "object") {
                    obj[token].$all = true;
                } else {
                    obj[token] = true;
                }
            } else {
                if (obj[token] === true) {
                    obj[token] = {};
                }
                obj[token] = obj[token] || {};
                const cur = obj[token];

                return arrayToObject(cur, arr, idx + 1);
            }
        };

        expands.forEach((x) => {
            arrayToObject(selects, x.split("."), 0);
        });
    }

    const selectsKeys = Object.keys(selects);
    if (selectsKeys.length === 0) {
        return null;
    }

    return selects;
}

interface IQueryArgs {
    filters: any;
    selects: any;
    orders: any;
    page: any;
}

export function getFilters(dataSourceOptions?: IDataSourceV3Options, queryOptions?: IInwinkEntityV3QueryOptions) {
    const filterIsValid = (filter: Record<string, any>) => {
        return !!filter && Object.keys(filter).length > 0;
    };
    let filters = queryOptions && queryOptions.where;
    const hasDefaultOptionsSetOnDataSource = dataSourceOptions && dataSourceOptions.query;
    if (queryOptions || hasDefaultOptionsSetOnDataSource) {
        const hasDefaultFiltersOnDataSource = dataSourceOptions && dataSourceOptions.query && dataSourceOptions.query.filters;
        const hasCustomFiltersOnQuery = queryOptions && queryOptions.filters;

        if ((queryOptions && queryOptions.filters) || hasDefaultFiltersOnDataSource) {
            if (filterIsValid(hasCustomFiltersOnQuery) && filterIsValid(hasDefaultFiltersOnDataSource)) {
                filters = expressionBuilder((e) => e.And(
                    [
                        (_e) => _e.FromFilter(queryOptions.filters),
                        (_e) => _e.FromFilter(dataSourceOptions.query.filters),
                    ]
                ));
            } else if (filterIsValid(hasCustomFiltersOnQuery)) {
                filters = expressionBuilder((e) => e.FromFilter(queryOptions.filters));
            } else if (filterIsValid(hasDefaultFiltersOnDataSource)) {
                filters = expressionBuilder((e) => e.FromFilter(dataSourceOptions.query.filters));
            } else {
                filters = null;
            }
        }

        let hasOldSyntaxQueryFilters = queryOptions && queryOptions.expression;
        if (
            hasOldSyntaxQueryFilters
            && hasOldSyntaxQueryFilters.constructor === Object
            && Object.keys(hasOldSyntaxQueryFilters).length === 0) {
            // an empty object will fuck that up
            hasOldSyntaxQueryFilters = false;
        }
        const hasOldSyntaxDataSourceFilters = dataSourceOptions?.query?.expression;
        if (hasOldSyntaxQueryFilters || hasOldSyntaxDataSourceFilters) {
            let f;
            if (hasOldSyntaxQueryFilters && hasOldSyntaxDataSourceFilters) {
                if (hasOldSyntaxQueryFilters.length) {
                    f = { and: [...hasOldSyntaxQueryFilters, hasOldSyntaxDataSourceFilters] };
                } else {
                    f = { and: [hasOldSyntaxQueryFilters, hasOldSyntaxDataSourceFilters] };
                }
            } else if (hasOldSyntaxQueryFilters) {
                f = hasOldSyntaxQueryFilters;
            } else {
                f = hasOldSyntaxDataSourceFilters;
            }

            if (!Array.isArray(f)) {
                f = [f];
            }
            let oldFilter;
            if (f && f.length && f[0].and) {
                oldFilter = convertFromOldFormat(f[0].and);
            } else if (f && f.length) {
                oldFilter = f && f.length && convertFromOldFormat(f);
            }

            if (filters) {
                if (oldFilter) {
                    filters = expressionBuilder((e) => e.And(
                        [
                            (_e) => _e.FromFilter(filters),
                            (_e) => _e.FromFilter(oldFilter)
                        ]
                    ));
                    // filters = { $and: [filters, oldFilter] };
                }
            } else if (oldFilter) {
                filters = expressionBuilder((e) => e.FromFilter(oldFilter));
                // filters = oldFilter;
            }
        }
    }

    if (filters && filters.$and && !filters.$and.length) {
        filters = null;
    }

    return filters;
}

function getQueryArgs(dataSourceOptions?: IDataSourceV3Options,
    queryOptions?: IInwinkEntityV3QueryOptions, expands?): Promise<IQueryArgs> {
    return new Promise((resolve) => {
        const filters = getFilters(dataSourceOptions, queryOptions);
        let page;
        let orders;
        let selects;

        const hasDefaultOptionsSetOnDataSource = dataSourceOptions?.query;

        if (queryOptions || hasDefaultOptionsSetOnDataSource) {
            if (queryOptions && queryOptions.page) {
                page = { index: queryOptions.page.index, size: queryOptions.page.size };
            } else if (dataSourceOptions?.query?.page) {
                page = { index: dataSourceOptions.query.page.index, size: dataSourceOptions.query.page.size };
            }

            if (queryOptions?.order || dataSourceOptions?.query?.order) {
                orders = [];
                if (queryOptions && queryOptions.order) {
                    queryOptions.order.forEach((_order) => {
                        orders.push({ desc: _order.desc, value: explode(_order.by, {}) });
                    });
                } else if (dataSourceOptions && dataSourceOptions.query && dataSourceOptions.query.order) {
                    dataSourceOptions.query.order.forEach((_order) => {
                        orders.push({ desc: _order.desc, value: explode(_order.by, {}) });
                    });
                }
            }

            const hasQuerySelects = !!(queryOptions && queryOptions.selects);
            const hasDefaultSelects = !!(dataSourceOptions && dataSourceOptions.query && dataSourceOptions.query.selects);

            if (hasQuerySelects || hasDefaultSelects) {
                selects = [];
                selects = Object.assign(
                    {},
                    (dataSourceOptions && dataSourceOptions.query && dataSourceOptions.query.selects),
                    (queryOptions && queryOptions.selects)
                );
            }
            selects = getSelectsFromExpands(expands, selects);
            resolve({
                filters: filters,
                selects: selects,
                orders: orders,
                page: page
            });
        } else {
            resolve({
                filters: filters,
                selects: selects,
                orders: orders,
                page: page
            });
        }
    });
}

export function getEntityDataSource<T>(
    proxy: IAPIProxy,
    rootPath: string,
    entityRoute: string,
    entityName: string,
    userpreferencesProvider?: (datasource) => void,
    dataSourceOptions?: IDataSourceV3Options
): IDataSourceV3<T> {
    const rootUrl = rootPath + entityRoute;
    let continuationToken = '';

    function getTemplateFor(entityname) {
        return proxy.getJson(rootPath + entityname + '/worktemplate').then((res) => {
            return res as Entities.IEntityTemplateV3;
        });
    }

    function loadChildTemplates(path: string, template: Entities.IEntityTemplateV3) {
        const fields = Object.keys(template.fields);
        const promises = fields.map((fieldKey) => {
            const field = template.fields[fieldKey];
            const fieldPath = path ? `${path}.${fieldKey}` : fieldKey;
            const rootLevel = !path;
            const canProcess = (dataSourceOptions?.expandWorkTemplates
                && typeof dataSourceOptions?.expandWorkTemplates === 'object'
                && dataSourceOptions?.expandWorkTemplates[fieldPath])
                || (rootLevel && dataSourceOptions?.expandWorkTemplates === true);
            if (canProcess) {
                if ((field.type === "Entity" || field.type === "Entities") && field.value) {
                    const fv = (field.value as string).toLowerCase();
                    const customWorkTemplateProvider = dataSourceOptions?.customWorkTemplateProviders
                        && dataSourceOptions.customWorkTemplateProviders[fv];
                    return ((customWorkTemplateProvider && customWorkTemplateProvider())
                        || getTemplateFor(fv)).then((childtemplate) => {
                        (field as any).template = childtemplate;
                        const childkey = path ? path + "." + field.key : field.key;
                        return loadChildTemplates(childkey, childtemplate);
                    }, () => {
                        console.error("cannot get child template for " + fv);
                    });
                }
            }
            return null;
        }).filter((f) => !!f);

        if (promises.length) {
            return Promise.all(promises).then(() => {
                return template;
            });
        }

        return Promise.resolve(template);
    }

    const _query = (path: string, queryOptions?: IInwinkEntityV3QueryOptions, expands?) => {
        if (!queryOptions || !queryOptions.page || !queryOptions.page.size) {
            return _getAll(path, queryOptions, expands);
        }
        return getQueryArgs(dataSourceOptions, queryOptions, expands).then((args) => {
            const url = _computeQueryUrl(path, queryOptions, args);

            return proxy.postJson(url, JSON.stringify({
                filters: args.filters,
                selects: args.selects,
                orders: args.orders,
                page: args.page
            }), defaultPostHeaderForJsonData).then((res: any) => {
                continuationToken = res.continuationToken;
                return res;
            });
        });
    };

    const _computeQueryUrl = (path: string, queryOptions: IInwinkEntityV3QueryOptions, args: IQueryArgs) => {
        const url = rootUrl + path;

        const params = [];
        const totalCount = queryOptions?.totalCount;
        if (totalCount) {
            params.push('totalCount=' + totalCount);
        }

        if (queryOptions?.queryString) {
            params.push(queryOptions.queryString);
        }

        if (queryOptions?.continuationTokenMode || dataSourceOptions?.query?.continuationTokenMode) {
            if (args.page?.index === 0) {
                continuationToken = "";
            }

            params.push(`continuationToken=${encodeURIComponent(continuationToken)}`);
        }

        return `${url}?${params.join("&")}`;
    };

    const _getAll = (path: string, queryOptions?: IInwinkEntityV3QueryOptions, expands?) => {
        return getQueryArgs(dataSourceOptions, queryOptions, expands).then((qargs) => {
            const _args = qargs;
            _args.page = _args.page || { index: 0, size: 200 };
            if (!_args.page.size || _args.page.size > 300) {
                _args.page.size = 300;
            }

            if (!_args.page.index) {
                _args.page.index = 0;
            }

            const url = _computeQueryUrl(path, queryOptions, _args);

            const result: IInwinkEntityQueryResult<T> = {
                data: []
            };

            const queryBucket = (queryargs) => {
                const { $url, ...args } = queryargs;
                return proxy.postJson(url, JSON.stringify(args), defaultPostHeaderForJsonData).then((res: any) => {
                    continuationToken = res.continuationToken;
                    if (res.data && res.data.length) {
                        result.data.push(...res.data);
                        if (res.data.length === queryargs.page.size) {
                            const newArgs = Object.assign({}, queryargs, {
                                $url: url,
                                page: Object.assign({}, queryargs.page, {
                                    index: queryargs.page.index + 1
                                })
                            });
                            return queryBucket(newArgs);
                        }
                    }
                });
            };

            return queryBucket({ $url: url, ..._args }).then(() => {
                return result;
            });
        });
    };

    const ds: IDataSourceV3<T> = {
        entityName: entityName,
        options: dataSourceOptions,
        customColumns: [],
        excludeColumns: [],
        isV3: true,
        getById(entityid) {
            return proxy.getJson(rootUrl + '/' + entityid);
        },
        import(entities) {
            return proxy.postJson(rootUrl + '/import', JSON.stringify(entities), defaultPostHeaderForJsonData);
        },
        importPreview(query: IImportPreviewQuery) {
            return proxy.postJson(rootUrl + '/import/preview', JSON.stringify(query), defaultPostHeaderForJsonData);
        },
        get(entityid, options: IInwinkEntityV3QueryOptions, _idpropname?: string, expands?: any[]) {
            let idpropname = _idpropname;
            if (!idpropname) {
                idpropname = "id";
            }
            const updatedoptions = Object.assign({}, options, {
                filters: Object.assign({}, options && options.filters, {
                    [idpropname]: entityid
                }),
                page: {
                    size: 1
                }
            });

            return this.query(updatedoptions, expands).then((res) => {
                return res.data && res.data[0];
            });
        },
        update(_entity, options?: IInwinkEntityUpdateOptions): Promise<T> {
            let entity = _entity;
            if (dataSourceOptions && dataSourceOptions.beforeUpdate) {
                entity = dataSourceOptions.beforeUpdate(entity);
            }

            const payload = Object.assign({}, options, {
                entity: entity
            });

            return proxy.postJson(rootUrl + '/' + entity.id, JSON.stringify(payload), defaultPostHeaderForJsonData);
        },
        create(_entity, options?: IInwinkEntityUpdateOptions): Promise<T> {
            let entity = _entity;
            if (dataSourceOptions && dataSourceOptions.beforeCreate) {
                entity = dataSourceOptions.beforeCreate(entity);
            }
            const payload = Object.assign({}, options, {
                entity: entity
            });
            return proxy.postJson(rootUrl, JSON.stringify(payload), defaultPostHeaderForJsonData);
        },
        count(queryOptions?: IInwinkEntityV3QueryOptions) {
            const filters = getFilters(dataSourceOptions, queryOptions);
            let url = rootUrl + "/count";
            if (queryOptions?.queryString) {
                const paramQuery = url.includes("?") ? "&" : "?";
                url = url + paramQuery + queryOptions.queryString;
            }
            return proxy.postJson(url, JSON.stringify({ filters }), defaultPostHeaderForJsonData);
        },
        getAll(queryOptions?: IInwinkEntityV3QueryOptions, expands?): Promise<IInwinkEntityQueryResult<T>> {
            return _getAll("/query", queryOptions, expands);
        },
        queryHistory(queryOptions?: IInwinkEntityV3QueryOptions, expands?): Promise<IInwinkEntityQueryResult<T>> {
            return _query("/history/query", queryOptions, expands);
        },
        queryRemoved(queryOptions?: IInwinkEntityV3QueryOptions, expands?): Promise<IInwinkEntityQueryResult<T>> {
            return _query("/history/removed/query", queryOptions, expands);
        },
        countRemoved(queryOptions?: IInwinkEntityV3QueryOptions) {
            const filters = getFilters(dataSourceOptions, queryOptions);
            return proxy.postJson(rootUrl + "/history/removed/count", JSON.stringify({ filters }), defaultPostHeaderForJsonData);
        },
        query(queryOptions?: IInwinkEntityV3QueryOptions, expands?): Promise<IInwinkEntityQueryResult<T>> {
            return _query("/query", queryOptions, expands);
        },
        massDelete(queryOptions?: IInwinkEntityV3QueryOptions, directDelete = false): Promise<IInwinkEntityQueryResult<T>> {
            const direct = dataSourceOptions.forceDirectMassDelete ? true : directDelete;
            return getQueryArgs(dataSourceOptions, queryOptions).then((args) => {
                const url = `${rootUrl}/massdelete?direct=${dataSourceOptions.forceDirectMassDelete ? true : direct}`;
                return proxy.postJson(url, JSON.stringify({
                    filters: args.filters,
                }), defaultPostHeaderForJsonData);
            });
        },
        delete(ids: string[], directDelete = false, customPayload?: any): Promise<any> {
            const direct = dataSourceOptions?.forceDirectMassDelete ? true : directDelete;
            if (customPayload && customPayload.entityName && customPayload.entityName === 'eventuseraccess') {
                const promises = [];
                ids.forEach((id) => {
                    promises.push(proxy.postJson(`${rootUrl}/delete/${id}`, JSON.stringify({}), defaultPostHeaderForJsonData));
                });
                return Promise.all(promises);
            } if (
                (ids && ids.length === 1)
                || (customPayload && customPayload.length === 1)
            ) {
                const body = {entity: {}};
                if (ids) {
                    body.entity = {id: ids[0]};
                } else {
                    body.entity = customPayload[0].entity;
                }
                // delete one entity
                return proxy.postJson(rootUrl + '/delete/', JSON.stringify(body), defaultPostHeaderForJsonData);
            } if (ids && ids.length > 0) {
                // Delete multple entity
                const filters = { id: ids };
                return proxy.postJson(`${rootUrl}/massdelete?direct=${direct ? "true" : "false"}`,
                    JSON.stringify({ filters }), defaultPostHeaderForJsonData);
            } if (!ids && customPayload && customPayload.length > 1) {
                const queryOptions = {
                    expression: {
                        or: customPayload.map((item) => {
                            return {
                                and: Object.keys(item.entity).map((key) => ({
                                    name: key,
                                    op: "eq",
                                    val: item.entity[key]
                                }))
                            };
                        })
                    }
                };
                return _query(`/massdelete?direct=${direct}`, queryOptions);
            }

            return Promise.reject(new Error("not implemented"));
        },
        entitytemplate(includeStaticFields: boolean, includeIdField = false): Promise<Entities.IEntityTemplate> {
            return ds.worktemplate().then((template: Entities.IEntityTemplateV3) => {
                return workTemplateToV2(template, includeIdField);
            });
        },
        worktemplate(forceReload?: boolean, disableExpandWorkTemplates?: boolean): Promise<Entities.IEntityTemplateV3> {
            const data = ds as any;

            if (!forceReload && data.cachedWorkTemplate) {
                return data.cachedWorkTemplate;
            }

            const promise = getTemplateFor(entityRoute).then((template: Entities.IEntityTemplateV3) => {
                if (disableExpandWorkTemplates !== true && dataSourceOptions?.expandWorkTemplates) {
                    return loadChildTemplates("", template);
                }

                return template;
            }).then((template) => {
                return template;
            });

            data.cachedWorkTemplate = promise.then((res) => {
                return res;
            }, (err) => {
                data.cachedWorkTemplate = null;
                return Promise.reject(err);
            });

            return promise;
        },
        updateentitytemplate(template): Promise<any> {
            const tmp = Object.assign({}, template, {
                // on ne sauvegarde pas les champs de type inherited
                fields: template.fields.filter((f) => f.inherited !== true)
            });
            return proxy.postJson(rootUrl + '/updateentitytemplate', JSON.stringify(tmp), defaultPostHeaderForJsonData);
        },
        columns(): Promise<any> {
            return ds.worktemplate().then((template) => {
                if (!template) {
                    return [];
                }
                const fields = Object.keys(template.fields).map((key) => Object.assign({}, template.fields[key], { key: key }));
                let columns: any = fields.filter((field) => {
                    return !field.metadata || !field.metadata.isTechnical;
                }).map((field) => fieldToColumn(field.key, field));

                const expandedColumns = [];
                fields.forEach((field) => {
                    if (field.type === "Entity" && field.template && (!field.metadata || !field.metadata.isTechnical)) {
                        const childFields = Object.keys(field.template.fields);
                        childFields.forEach((childFieldKey) => {
                            const childfield = field.template.fields[childFieldKey];
                            const isEntity = childfield.type === "Entity" || childfield.type === "Entities";
                            if (!isEntity && (!childfield.metadata || !childfield.metadata.isTechnical)) {
                                const column = fieldToColumn(field.key + "." + childFieldKey, childfield);
                                expandedColumns.push(column);
                            }
                        });
                    }
                });

                columns = [...columns, ...this.customColumns, ...expandedColumns];

                if (dataSourceOptions.additionnalColumns && dataSourceOptions.additionnalColumns.length > 0) {
                    columns = [...columns, ...dataSourceOptions.additionnalColumns];
                }

                if (this.excludeColumns && this.excludeColumns.length) {
                    columns = columns.filter((c) => {
                        return !(this.excludeColumns.indexOf(c.key) >= 0);
                    });
                }
                return columns;
            });
        },
        exportV3(query: IExportV3Payload, direct = false) {
            const payload = {
                filters: getFilters(dataSourceOptions, query),
                columns: query.columns,
                selects: getSelectsFromExpands(query.expands, query.selects),
                orders: [],
                title: query.title || null
            };

            if (query.order) {
                query.order.forEach((_order) => {
                    payload.orders.push({ desc: _order.desc, value: explode(_order.by, {}) });
                });
            }

            return proxy.postJson(`${rootUrl}/export/excel?direct=${direct ? "true" : "false"}`, JSON.stringify(payload), {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        },
        exportFile(query: IExportFilePayload, direct = false) {
            const payload: any = {
                filters: getFilters(dataSourceOptions, query),
                files: query.files,
                selects: query.selects || null
            };

            return proxy.postJson(`${rootUrl}/export/files?direct=${direct ? "true" : "false"}`, JSON.stringify(payload), {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        },
        massUpdate(input: Partial<T>, filters: { filters: any, where: any, expression: any }, direct = false,
            populateWithMergeArray = false): Promise<any> {
            const payload = {
                input, filters: { filters: getFilters(dataSourceOptions, filters) }
            };
            const populatewithmergearrayQueryString = `&populatewithmergearray=${populateWithMergeArray ? "true" : "false"}`;

            return proxy.postJson(`${rootUrl}/massupdate?direct=${direct ? "true" : "false"}${populatewithmergearrayQueryString}`,
                JSON.stringify(payload), {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        },
        getDocumentUrl(entityid: string, templateid: string, lang: string): Promise<any> {
            return proxy.getJson(`${rootUrl}/${entityid}/doc/${templateid}?direct=true&lang=${lang}`);
        }
    };

    if (userpreferencesProvider) {
        userpreferencesProvider(ds);
    }

    return ds;
}

export function workTemplateToV2(template: Entities.IEntityTemplateV3, includeIdField = false) {
    if (!template) {
        return null;
    }
    const entitytemplate: Entities.IEntityTemplate = {
        languages: null,
        scopes: template.scopes,
        oldKeys: template.oldKeys,
        fields: Object
            .keys(template.fields)
            .filter((key) => {
                let _r = false;
                if (includeIdField && key === "id") {
                    _r = true;
                } else if (template.fields[key] && (template.fields[key].metadata)) {
                    _r = !template.fields[key].metadata.isTechnical;
                }

                return _r;
            })
            .map((key) => {
                const field = template.fields[key];
                const res = fieldV3toV2(field);

                return res;
            })
    };

    return entitytemplate;
}
export function fieldV3toV2(field: Entities.IEntityFieldTemplateV3) {
    let nestedEntity: Record<string, Entities.IEntityFieldTemplate> = null;
    if (field.nestedEntity) {
        nestedEntity = {};
        Object.values(field.nestedEntity).forEach((f) => {
            nestedEntity[f.key] = fieldV3toV2(f);
        });
    }

    const res: Entities.IEntityFieldTemplate = {
        key: field.key,
        type: field.type,
        canBeNull: field.metadata?.canBeNull,
        defaultDisplay: field.display && field.display.defaultDisplay,
        labels: field.display && field.display.labels,
        descriptions: field.display && field.display.descriptions,
        placeholders: field.display && field.display.placeholders,
        remarks: field.display && field.display.remarks,
        tooltip: field.display && field.display.tooltip,
        isStatic: field.metadata && field.metadata.isStatic,
        isLocalizable: field.metadata && field.metadata.isLocalizable,
        isLocked: field.metadata && field.metadata.isLocked,
        applyTo: field.metadata && field.metadata.applyTo,
        isMandatory: field.validation && field.validation.isMandatory,
        isMandatoryFor: field.validation && field.validation.isMandatoryFor,
        min: field.validation && field.validation.min,
        max: field.validation && field.validation.max,
        value: field.value,
        valuesList: field.valuesList,
        valuesListSort: field.display && field.display.valuesListSort,
        isPublicFile: field.file && field.file.isPublicFile,
        mimeTypes: field.file && field.file.mimeTypes,
        maxSize: field.file && field.file.maxSize,
        isCollection: field.metadata && field.metadata.isCollection,
        regex: field?.validation?.regex,
        isReadOnly: field.metadata?.readOnly
    };

    if ((field.metadata as any)?.inherited) {
        (res as any).inherited = true;
        (res as any).origin = (field.metadata as any).origin;
    }

    if (nestedEntity) {
        res.nestedEntity = nestedEntity;
    }
    return res;
}
export function fieldToColumn(key: string, field: Entities.IEntityFieldTemplateV3, patch: Partial<IColumn> = null) {
    let cellClassSuffix = '';
    if (field.type === 'Bool' || field.type === 'Guid' || field.key === 'Status') {
        cellClassSuffix = 'cell-center';
    } else if (field.type === "File") {
        cellClassSuffix = 'cell-action';
    }
    const column: IColumn = {
        id: key,
        key: key,
        column: field,
        cssClass: `${"col-" + key} ${cellClassSuffix}`,
        titles: field.display && field.display.labels,
        type: field.type,
        isStatic: field.metadata && field.metadata.isStatic,
        isSelected: true,
        canBeNull: field.metadata && field.metadata.canBeNull,
        orderable: fieldIsOrderable(),
        filterable: fieldIsFilterable(),
        valuesList: field.valuesList
    };

    return Object.assign(column, patch);
}

export function fieldIsOrderable(): boolean {
    return true;
}

export function fieldIsFilterable(): boolean {
    return true;
}

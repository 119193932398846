import { defaultPostHeaderForJsonData } from '@inwink/apibase';
import { getBackOfficeProxy } from './index';

export function me() {
    const proxy = getBackOfficeProxy();
    return proxy.getJson("me");
}

export function preferences() {
    const proxy = getBackOfficeProxy();
    return proxy.getJson("me/preferences");
}

export function profile() {
    const proxy = getBackOfficeProxy();
    return proxy.getJson("me/profile");
}

export function setProfile(_profile) {
    const proxy = getBackOfficeProxy();
    return proxy.postJson("me/profile", JSON.stringify(_profile), defaultPostHeaderForJsonData);
}
export function setPreferences(_preferences) {
    const proxy = getBackOfficeProxy();
    return proxy.postJson("me/preferences", JSON.stringify(_preferences), defaultPostHeaderForJsonData);
}
export function setPreferredLanguage(lng: string) {
    const proxy = getBackOfficeProxy();
    return proxy.postJson("me/preferences/" + lng);
}

export function boRights() {
    const proxy = getBackOfficeProxy();
    return proxy.getJson(`me/rights/backoffice`);
}

export function customerRights(customerId) {
    const proxy = getBackOfficeProxy();
    return proxy.getJson(`me/rights/customer/${customerId}`);
}

export function tenantRights(customerId, tenantId) {
    const proxy = getBackOfficeProxy();
    return proxy.getJson(`me/rights/customer/${customerId}/tenant/${tenantId}`);
}

export function eventRights(customerId, eventId) {
    const proxy = getBackOfficeProxy();
    return proxy.getJson(`me/rights/customer/${customerId}/event/${eventId}`);
}

export function communityRights(customerId, communityId) {
    const proxy = getBackOfficeProxy();
    return proxy.getJson(`me/rights/customer/${customerId}/community/${communityId}`);
}

export interface IUserEvent {
    id: string;
    customerId: string;
    tenantId: string;
    title: string;
    eventKey: string;
    date: {
        start: string;
        end: string;
    };
}

export function myevents(): Promise<IUserEvent[]> {
    const proxy = getBackOfficeProxy();
    return proxy.getJson(`me/events`);
}

import * as React from 'react';
import { AppTextLabel } from '../../commons';
import { ModalContent } from "../ui/modals/modalcontent";

interface IEntityUpdateAllPageConfirmProps {
    numberItemSelected?: number;
    onSave: () => void;
    onCompleted: (onSave?: boolean) => void;
}

export class EntityUpdateAllPageConfirm extends React.Component<IEntityUpdateAllPageConfirmProps, null> {
    render() {
        const { onCompleted, numberItemSelected } = this.props;
        let trad = '';
        if (numberItemSelected <= 1) {
            trad = 'entity.update.confirm';
        } else if (numberItemSelected > 1) {
            trad = 'entities.update.confirm';
        }

        const actions = [
            {
                i18n: "actions.cancel",
                direction: "left",
                callback: () => onCompleted(false),
                isImportant: false
            },
            {
                i18n: "actions.validate",
                direction: "right",
                callback: () => onCompleted(true),
                isImportant: true
            }
        ];

        return (
            <ModalContent title="actions.validate" actions={actions} onhide={this.props.onCompleted}>
                <div className="formpage modal-content-padding">
                    <AppTextLabel component="label" i18n={trad} className="warning-message" inject={{ count: numberItemSelected }} />
                </div>
            </ModalContent>
        );
    }
}

import { fileDownload } from '@inwink/fileutils/filedownload';
import type { Entities } from '@inwink/entities/entities';
import * as find from 'lodash/find';
import { getEventEntityDataSource } from '../../../../api/events/index';
import type { ISlickGridColumn, ICustomCell } from '../definitions';
import type { IEntityGridProps } from './index';
import { openEntityDetailV3 } from '../../../../data/openEntityDetail';

/* Every click event on grid will pass through this function (which return a closure) */
export const gridSubscribeOnclick = (_this, props: IEntityGridProps) => {
    return (e, args) => {
        const dataitem = args.grid.getDataItem(args.row) as Entities.IEntity;
        const cell = args.grid.getColumns()[args.cell] as ISlickGridColumn;
        const cellCol = cell.entityColumn;
        const cellColField = cellCol?.field;

        const ctx = props.helpers.getActionContext(null, null);
        ctx.selection = props.viewsState.selection;
        ctx.tools = {
            refresh: () => {
                _this.datamgr.refreshGrid(_this.props);
            },
            setSelection: (selection) => {
                props.viewsManager.setSelection(selection);
            }
        };
        ctx.component = _this;

        const entityactions = props.entityconfiguration.entityactions || props.entityactions;

        if (cell.field === 'edit') {
            // si pas d'action par défaut
            if (!props.onItemSelected) {
                return props.helpers.openEntityDetail(dataitem, false, false, ctx).then((res) => {
                    if (res) { _this.datamgr.refreshGrid(_this.props); }
                });
            }
            if (props.onItemSelected) {
                props.onItemSelected(e, ctx, dataitem);
            }
        } else if (cell.field === "_itemactions") {
            if (entityactions && entityactions.entityActions && entityactions.entityActions.length === 1) {
                entityactions.entityActions[0].action(e, ctx, dataitem);
            } else {
                props.helpers.showEntityActions(e, props.helpers.getActionContext(dataitem, null), dataitem);
                // _this.showEntityActions(e.target, dataitem);
            }
        } else if (entityactions && entityactions.entityActions && find(entityactions.entityActions, { id: cell.field })) {
            const action = entityactions.entityActions.filter((act) => act.id === cell.field)[0];
            if (action) {
                const actionCtx = props.helpers.getActionContext(action.params, action);
                actionCtx.selection = props.viewsState.selection;
                actionCtx.tools = {
                    refresh: () => {
                        _this.datamgr.refreshGrid(_this.props);
                    },
                    setSelection: (selection) => {
                        props.viewsManager.setSelection(selection);
                    }
                };
                actionCtx.component = _this;

                const _callback = action.action(e, actionCtx, dataitem);

                if (_callback && typeof _callback.then === 'function') {
                    _callback.then((res) => {
                        if (res) {
                            _this.gridData.updateItem(dataitem.id, res);
                            // _this.grid_data.updateItem(dataitem.id, _this.formatmgr.formatRows([res])[0]);
                            _this.grid.invalidate();
                            _this.grid.render();
                        }
                    });
                }
            }
        } else if (entityactions && entityactions.titleActions && find(entityactions.titleActions, { id: cell.field })) {
            const callback = find(entityactions.titleActions, { id: cell.field }).action(e, ctx, dataitem);
            if (callback && typeof callback.then === 'function') {
                callback.then((res) => {
                    if (res) {
                        _this.gridData.updateItem(dataitem.id, res);
                        // _this.grid_data.updateItem(dataitem.id, _this.formatmgr.formatRows([res])[0]);
                        _this.grid.invalidate();
                        _this.grid.render();
                    }
                });
            }
        } else if (cellColField
            && (cellColField.type === 'File'
                || (cellColField.type === 'Image' && cellColField.file
                    && cellColField.file.isPublicFile !== true))) {
            if (props.template) {
                const key = cellColField.key;
                const fileInfo = dataitem[key];

                const field = props.template.fields[key];

                if (field) {
                    const fileMgr = props.entityconfiguration.fileInputManager || props.cmscontext.fileInputManager;
                    fileDownload(
                        fileMgr,
                        props.entityconfiguration.entityName,
                        key,
                        dataitem.id,
                        fileInfo,
                        field.file && field.file.isPublicFile,
                        null
                    );
                }
            }
        } else if (cellColField && cellColField.type === 'Document') {
            if (props.template) {
                const key = cellColField.key;
                const field = props.template.fields[key];

                if (field && field.value) {
                    const documentTemplateDatasource = props.entityconfiguration.documentTemplateDatasource
                        || getEventEntityDataSource<Entities.IDocumentTemplate>(props.cmscontext.relatedTo,
                            'documenttemplate', 'documenttemplate');
                    return documentTemplateDatasource.getById(field.value)
                        .then((documentTemplate) => {
                            return fileDownload(
                                {
                                    createTmpFile: null,
                                    tryRenewTmpAccessFile: null,
                                    renewTmpAccessFile: null,
                                    copyToTmp: null,
                                    getFileAccess: () => {
                                        return props.entityconfiguration.datasource.getDocumentUrl(dataitem.id,
                                            field.value, props.cmscontext.displayLanguage);
                                    }
                                },
                                props.entityconfiguration.entityName,
                                key,
                                dataitem.id,
                                { type: documentTemplate.outputFormat.toLowerCase(), url: null, name: key },
                                false,
                                null
                            );
                        });
                }
            }
        } else if (cellCol
                && (cellCol.openChildEntity || (cellColField?.type === 'Entity'))) {
            let entityName = (cellCol.parent?.field ? cellCol.parent.field.value : cellColField.value);
            let entityContent = dataitem[cellCol.openChildEntity] || dataitem[cellCol.key];
            if (!entityContent) {
                if (dataitem[cellCol.key]) {
                    entityContent = dataitem[cellCol.key];
                } else {
                    // pas terrible, mais ça fait le job...
                    const _m = cellCol.key.match(/(\w{1,})\.(\w{1,})/);
                    if (_m && _m.length === 3 && dataitem[_m[1]] && dataitem[_m[1]][cellColField.key]) {
                        entityContent = dataitem[_m[1]][cellColField.key];
                        // on doit redéfinir sinon il ouvre cell.entityColumn.parent.field.value qui est "Session"
                        // alors qu'on veut sessiontimeslotview par exemple
                        entityName = cellColField.value;
                    }
                }
            }

            if (e.target.getAttribute('data-inwink-type') === 'document') {
                const key = e.target.getAttribute('data-inwink-type');
                const id = e.target.getAttribute('data-inwink-document-id');
                const document = JSON.parse(decodeURIComponent(e.target.getAttribute('data-inwink-document')));

                if (id && key && document) {
                    const fileMgr = props.entityconfiguration.fileInputManager || props.cmscontext.fileInputManager;
                    fileDownload(fileMgr, "Document", key, id, document, false, null);
                }
            } else if (entityName && entityContent) {
                console.log("entity clicked", cellColField.value, cell);
                return openEntityDetailV3(
                    props.popovermgr,
                    props.cmscontext,
                    entityName,
                    props.entityConfigurationArgs,
                    false,
                    props.entityRights.canUpdate,
                    false,
                    entityContent,
                    null, props.runMode === 'removedentitypage'
                ).then((res) => {
                    if (res) {
                        _this.datamgr.refreshGrid(_this.props);
                    }
                });
            }
        } else if (cellCol?.allowOpenEntity) {
            return openEntityDetailV3(
                props.popovermgr,
                props.cmscontext,
                props.entityconfiguration.entityName,
                props.entityConfigurationArgs,
                false,
                props.entityRights.canUpdate,
                false,
                dataitem,
                null,
                props.runMode === 'removedentitypage'
            ).then((res) => {
                if (res) {
                    _this.datamgr.refreshGrid(_this.props);
                }
            });
        } else if (cellCol?.cellProcessor
            && (cellCol.cellProcessor as ICustomCell).clickHandler) {
            (cellCol.cellProcessor as ICustomCell).clickHandler(e, dataitem, cell, _this.props);
        }
    };
};

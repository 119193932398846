import type { Entities } from '@inwink/entities/entities';
import { loadAssets } from '@inwink/react-utils/loadassets';
import * as findIndex from 'lodash/findIndex';
import * as uniq from 'lodash/uniq';
import { AppTextLabel, IPopoverManager, LoadingState, React } from '../../../../commons';
import type { States } from '../../../../services/services';
import type { ICMSContext } from "../../../cmscontext";
import { confirmModal } from "../../../ui/modals/confirmmodal";
import type {
    IEntityPageConfiguration, IEntityViewsState, IEntityPageActions, IEntityPageComputedActions,
    EntityPageActionCallBackArg, IEntityScreenHelpers, EntityPageRunMode
} from '../entitypagebase.props';
import type { IRow } from '../definitions';
import { getGridAssets } from './gridAssets';
import { GridSettingsEditor } from './entitygrid.settings';
import { EntityGridData } from './entitygrid.data';
import { EntityGridDataFormat } from './entitygrid.dataformat';
import { EntityGridColumns } from './entitygrid.columns';
import { gridSubscribeOnclick } from './entitygrid.subscribe.onclick';
import { EntityViewsManager } from '../entitypagebase.viewmgr';
import { showActionsMenu } from '../actions';

import "./entitygrid.less";

declare let Slick;

interface IEntityGridState {
    isReady?: boolean;
    rows?: IRow[];
    isFetching?: boolean;
    offset?: number;
    isFetchingFilter?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    count_rows?: number;
    pageSize?: number;
    hideTirette?: boolean;
    expression?: any;
    languages?: string[];
}

export interface IEntityGridProps {
    helpers: IEntityScreenHelpers;
    popovermgr?: IPopoverManager;
    viewsManager: EntityViewsManager;
    viewsState: IEntityViewsState;
    entityconfiguration: IEntityPageConfiguration;
    entityactions: IEntityPageActions;
    onItemSelected?: (arg: React.MouseEvent<any>, entityargs: EntityPageActionCallBackArg, item: any) => void;
    entityRights?: InWinkBO.IEntityRights;
    cmscontext: ICMSContext;
    i18n?: States.i18nState;
    i18nHelper: Entities.i18nHelper;
    title: string;
    entityConfigurationArgs: any;
    entityPageComponent: any;
    refreshGrid?: Date;
    setRefreshGrid?: () => void;
    setTinyLoader?: (loader: boolean) => void;
    applyForValues?: Entities.IApplyForValues[];
    template?: Entities.IEntityTemplateV3;
    showSettings: boolean;
    onShowSettings: (show: boolean) => void;
    onGridReady?: () => void;
    entityConfs: Record<string, IEntityPageConfiguration>;
    runMode?: EntityPageRunMode;
    expression?: any;
    expands?: any;
    computedActions: IEntityPageComputedActions;
    // configurationProvider?: (entityname: string, args) => Promise<IEntityPageConfiguration>;
}
interface ISlickGridOptions {
    enableCellNavigation: boolean;
    enableColumnReorder: boolean;
    rowHeight: number;
    enableTextSelectionOnCells: boolean;
    frozenColumn?: number;
    forceFitColumns?: boolean;
}

export class EntityGrid extends React.Component<IEntityGridProps, IEntityGridState> {
    datamgr: EntityGridData;

    columnsmgr: EntityGridColumns;

    formatmgr: EntityGridDataFormat;

    gridData: any;

    grid: any;

    fetching: boolean;

    checkboxSelector: any;

    optionsGrid: ISlickGridOptions;

    rowsDifference: number;

    noMoreRowToLoad: boolean;

    faildTime: number;

    translate: any;

    loadGridScriptsPromise: Promise<any>;

    startSearch: (search: string) => void;

    unmounted: boolean;

    enableSelectionSub: boolean;

    gridRef = React.createRef<HTMLDivElement>();

    constructor(props: IEntityGridProps) {
        super(props);

        this.datamgr = new EntityGridData(this);
        this.formatmgr = new EntityGridDataFormat(this);
        this.columnsmgr = new EntityGridColumns(this,
            this.props.cmscontext.relatedTo + '-'
            + (this.props.entityconfiguration.storeKey || this.props.entityconfiguration.entityName));
        this.optionsGrid = {
            enableCellNavigation: true,
            enableColumnReorder: true,
            rowHeight: 55,
            enableTextSelectionOnCells: true,
            // frozenColumn: 2,//marche pas :'(
        };

        this.faildTime = 0;
        this.rowsDifference = 0;
        this.noMoreRowToLoad = false;
        this.enableSelectionSub = true;
        this.state = {
            rows: [],
            isFetching: true,
            // eslint-disable-next-line react/no-unused-state
            offset: 0,
            // eslint-disable-next-line react/no-unused-state
            isFetchingFilter: false,
            // eslint-disable-next-line react/no-unused-state
            pageSize: 60,
            hideTirette: this.props.entityconfiguration.disableTirette
        };

        this.loadGridScriptsPromise = loadAssets(getGridAssets(inwink.config.env));
        // this.startSearch = debounce(this.datamgr.search, 750);
    }

    componentDidMount() {
        this.loadGridScriptsPromise.then(() => {
            if (this.unmounted) {
                return;
            }

            // console.log("prepare grid");
            this.checkboxSelector = new Slick.CheckboxSelectColumn({
                cssClass: "slick-cell-checkboxsel",
                width: 50,
                disabled: {
                    role: "Owner"
                }
            });
            this.initGrid();
        }, (e) => {
            console.error(e);
        });
    }

    getMassOperationSuffix = () => {
        const suffix = `${this.props.cmscontext.relatedTo}.${this.props.entityconfiguration.entityName.toLowerCase()}`;
        // if (this.props.cmscontext.relatedKind === "AuthTenant") {
        //     suffix = `${this.props.cmscontext.customerId}.${this.props.entityconfiguration.entityName.toLowerCase()}`;
        // }
        return suffix;
    };

    componentWillUnmount() {
        this.unmounted = true;
        const suffix = this.getMassOperationSuffix();
        window.removeEventListener("resize", this.resizeGrid);
        window.removeEventListener(`inwink.massDelete.${suffix}`, this.refreshOnMassDelete);
        window.removeEventListener(`inwink.massUpdate.${suffix}`, this.refreshOnMassUpdate);
        if (this.grid) {
            this.grid.destroy();
            this.grid = null;
            this.gridData = null;
        }
    }

    componentDidUpdate(prevProps: IEntityGridProps) {
        if (!this.gridData) {
            return; //  la grille n'est pas prête
        }

        let mustRefresh = this.props.refreshGrid > prevProps.refreshGrid;

        if (
            (this.props.viewsState && (!prevProps.viewsState
                || (this.props.viewsState.queryExpression !== prevProps.viewsState.queryExpression)))
            || (this.props.entityConfs !== prevProps.entityConfs)
        ) {
            this.props.helpers.setLoadingState(true);
            this.columnsmgr.initColumns(this.props.viewsState
                && this.props.viewsState.currentview
                && this.props.viewsState.currentview.content
                && this.props.viewsState.currentview.content.columnKeys,
            this.props.entityConfs);

            this.datamgr.initData(this.props);
            // mustRefresh = true; if  we init we don't have to refresh also...
        } else {
            const viewContent = this.props.viewsState?.currentview?.content;
            const previousViewContent = prevProps.viewsState?.currentview?.content;

            if (prevProps.viewsState.showSelection !== this.props.viewsState.showSelection
                || (this.props.viewsState.showSelection
                    && this.props.viewsState.selection.length < prevProps.viewsState.selection.length)
            ) {
                mustRefresh = true;
            }

            if (viewContent) {
                if (JSON.stringify(previousViewContent) !== JSON.stringify(viewContent)) {
                    mustRefresh = true;
                }

                if (!previousViewContent || viewContent.columnKeys !== previousViewContent.columnKeys) {
                    this.columnsmgr.updateColumnsSelection(viewContent.columnKeys);
                }
                if (!previousViewContent || viewContent.order !== previousViewContent.order) {
                    this.renderOrderByChevrons(viewContent.order);
                }

                if (!previousViewContent || viewContent.filters !== previousViewContent.filters) {
                    uniq(Object.keys(viewContent.filters).concat(Object.keys(viewContent.filters))).map((filter) => {
                        this.toggleFilter(filter, Object.keys(viewContent.filters).indexOf(filter) !== -1, false);
                        return null;
                    });
                }
            }
        }

        if (mustRefresh) {
            this.datamgr.refreshGrid(this.props);
        }

        if (prevProps.viewsState
            && prevProps.viewsState.selection
            && prevProps.viewsState.selection.length
            && (!this.props.viewsState || !this.props.viewsState.selection || !this.props.viewsState.selection.length)) {
            this.grid.setSelectedRows([]);
        }
    }

    setGridListeners = () => {
        const { grid } = this;

        this.columnsmgr.setGridListeners();
        this.datamgr.setGridListeners();

        grid.onSelectedRowsChanged.subscribe((e, args) => {
            // Je ne veux refaire la selection que lorsqu'il y a eu un click sur une checkbox
            // ou que la selection a effectivement changée
            if (this.enableSelectionSub === true) {
                const selection = [...this.props.viewsState.selection];
                const selectionsIds = selection.map((v) => v.id);
                const selectedIndex = args.grid.getSelectedRows();

                this.state.rows.forEach((v, i) => {
                    let item = v;
                    if (item.entity) { item = item.entity; }
                    const alreadySelectionned = item.id && selectionsIds.indexOf(item.id);
                    if (alreadySelectionned > -1 && selectedIndex.indexOf(i) < 0) {
                        // if IS IN selection AND IS IN selectedRows -> delete from selection
                        selection.splice(alreadySelectionned, 1);
                    } else if (alreadySelectionned < 0 && selectedIndex.indexOf(i) > -1) {
                        // if IS NOT in selection AND IS IN selectedRows -> add
                        selection.push({ ...item });
                    }
                });

                this.props.viewsManager.setSelection(selection);
            } else {
                this.enableSelectionSub = true;
            }
        });

        grid.onContextMenu.subscribe((e) => {
            if (this.props.computedActions.secondaryActions) {
                e.preventDefault();
                e.stopPropagation();
                const cell = grid.getCellFromEvent(e);
                // showContextMenu({
                //     left: e.clientX,
                //     top: e.clientY,
                //     width: 1,
                //     height: 1
                // } as any, {...this.props, item: cell });

                showActionsMenu({
                    left: e.clientX,
                    top: e.clientY,
                    width: 1,
                    height: 1
                } as any, {
                    popovermgr: this.props.popovermgr,
                    viewsState: this.props.viewsState,
                    helpers: this.props.helpers,
                    computedActions: this.props.computedActions,
                    item: cell,
                    fromContextMenu: true,
                    entityconf: this.props.entityconfiguration
                });

                return false;
            }
        });

        grid.onClick.subscribe(gridSubscribeOnclick(this, this.props));

        grid.onViewportChanged.subscribe((e, args) => {
            if (!this.gridData) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { count_rows } = this.state;
            const length = this.gridData.getLength();
            if (this.fetching || length >= (count_rows + (this.rowsDifference)) || this.noMoreRowToLoad) {
                return;
            }
            const lastRow = args.grid.getViewport().bottom;
            if (lastRow >= args.grid.getDataLength() - 15) {
                this.datamgr.fetchNextPage();
            }
        });
    };

    initGrid = () => {
        const gridNode = this.gridRef.current;
        const { resizeGrid, setGridListeners, optionsGrid } = this;
        this.gridData = new Slick.Data.DataView();
        this.gridData.getItemMetadata = (index) => {
            if (!this.unmounted && this.gridData) {
                const item = this.gridData.getItem(index);
                if (item && item.id) {
                    return { cssClasses: 'entity-' + item.id };
                }
            }
        };

        this.grid = new Slick.Grid(gridNode, this.gridData, [], optionsGrid);
        const columnKeys = this.props.viewsState?.currentview?.content?.columnKeys;
        this.columnsmgr.initColumns(columnKeys, this.props.entityConfs);

        const order = this.props.viewsState
            && this.props.viewsState.currentview
            && this.props.viewsState.currentview.content
            && this.props.viewsState.currentview.content.order;

        if (order && order.by) {
            this.grid.setSortColumn(order.by.toLowerCase(), !order.desc);
        } else {
            this.grid.setSortColumn(null, null);
        }

        setGridListeners();

        const headerButtonsPlugin = new Slick.Plugins.HeaderButtons();
        headerButtonsPlugin.onCommand.subscribe((e, args) => {
            const column = args.column;
            const command = args.command;
            // if (command === "toggle-column") {
            //     toggleColumn(column.key, false);
            // } else
            if (command === "filters") {
                this.props.helpers.openFilter(column, e.target.parentElement);
            }
        });

        this.grid.setSelectionModel(new Slick.RowSelectionModel({ selectActiveRow: false }));
        const plugins = [this.checkboxSelector, new Slick.AutoTooltips(), headerButtonsPlugin];
        plugins.forEach((plugin) => this.grid.registerPlugin(plugin));

        window.addEventListener("resize", resizeGrid);
        const suffix = this.getMassOperationSuffix();
        window.addEventListener(`inwink.massDelete.${suffix}`, this.refreshOnMassDelete);
        window.addEventListener(`inwink.massUpdate.${suffix}`, this.refreshOnMassUpdate);
        this.resizeGrid();
        this.renderOrderByChevrons(order);

        // AFTER the grid is init (or suppose to at least), we init data loading
        if (this.props.onGridReady) {
            this.props.onGridReady();
        }

        this.datamgr.initData(this.props).then(() => {
            // Hide title:
            const checkboxHeadNode = document.querySelector('.checkbox-inwink-header');
            if (checkboxHeadNode) {
                checkboxHeadNode.setAttribute('title', '');
            }
            this.setState({ isReady: true });
        });
    };

    refreshOnMassDelete = (...args) => {
        let body = "confirm.modal.massdelete.refresh.body.fallback";
        if (args && args[0] && args[0].detail?.count) {
            body = this.props.i18nHelper.translate('confirm.modal.massdelete.refresh.body', { count: args[0].detail.count });
        }
        return confirmModal(this.props.popovermgr, "confirm.modal.massdelete.refresh.title", body).then((res) => {
            if (res === true) {
                this.props.viewsManager.triggerRefreshGrid();
            }
        });
    };

    refreshOnMassUpdate = () => {
        const body = "confirm.modal.massUpdate.refresh.body.fallback";
        return confirmModal(this.props.popovermgr, "confirm.modal.massUpdate.refresh.title", body).then((res) => {
            if (res === true) {
                this.props.viewsManager.triggerRefreshGrid();
            }
        });
    };

    resizeGrid = () => {
        setTimeout(() => this.grid?.resizeCanvas(), 1000);
    };

    toggleFilter = (columnId, show, columnActive) => {
        const columns = this.grid.getColumns();
        const columnIndex = findIndex(columns, { id: columnId.toString().toLowerCase() });
        if (columnIndex !== -1) {
            const button = columns[columnIndex].header
                && columns[columnIndex].header.buttons
                && columns[columnIndex].header.buttons[0];
            if (button) {
                button.cssClass = (show) ? "inwink-filter_on" : "inwink-filter";
            }
            columns[columnIndex].headerCssClass = (columnActive) ? "header-filter" : "";
            this.grid.setColumns(columns);
            this.grid.invalidate();
            this.grid.render();
        }
    };

    getExportFilteredQueryOptions = () => {
        const columnKeys = this.props.viewsState?.currentview?.content?.columnKeys;
        const viewcontent = this.props.viewsState?.currentview?.content;
        let order = null;
        if (viewcontent?.order) {
            order = Array.isArray(viewcontent.order) ? viewcontent.order : [viewcontent.order];
        }

        return {
            search: viewcontent && viewcontent.search,
            expression: this.props.viewsState.queryExpression,
            columns: columnKeys && columnKeys.map((k) => {
                if (k.toLowerCase() === 'userid') {
                    return 'IsConnected';
                    // isconnected est déduit de la présence de userid mais n'existe pas sur l'entité
                }
                return k;
            }),
            order: order
        };
    };

    renderOrderByChevrons = (order) => {
        if (this.grid && order && order.by) {
            // on retire tous les chevrons
            document.querySelectorAll(".slick-sort-indicator").forEach((e) => {
                e.classList.remove("inwink-chevron-down");
                e.classList.remove("inwink-chevron-up");
            });
            const col = this.grid.getColumnIndex(order.by);
            const desc = !!order.desc;
            if (col) {
                // on ajoute le chevron sur l'order en cours
                const ind = document.querySelectorAll(".slick-header-column")[col].querySelector(".slick-sort-indicator");
                if (ind) {
                    const cl = ind.classList;
                    cl.add(desc ? "inwink-chevron-down" : "inwink-chevron-up");
                    cl.add(desc ? "slick-sort-indicator-desc" : "slick-sort-indicator-asc");
                }
            }
        } else if (!order || !order.by) {
            // si on a plus d'order, on retire tous les chevrons
            document.querySelectorAll(".slick-sort-indicator").forEach((e) => {
                e.classList.remove("inwink-chevron-down");
                e.classList.remove("inwink-chevron-up");
            });
        }
    };

    showMenu = (arg: React.MouseEvent<any>) => {
        if (this.props.computedActions.secondaryActions) {
            arg.preventDefault();
            arg.stopPropagation();

            showActionsMenu({
                left: arg.clientX,
                top: arg.clientY,
                width: 1,
                height: 1
            } as any, {
                popovermgr: this.props.popovermgr,
                viewsState: this.props.viewsState,
                helpers: this.props.helpers,
                computedActions: this.props.computedActions,
                item: null,
                fromContextMenu: true,
                entityconf: this.props.entityconfiguration
            });

            return false;
        }
    };

    render() {
        let tirette = null;

        const columnKeys = this.props.viewsState?.currentview?.content?.columnKeys;
        if (!this.state.hideTirette) {
            tirette = <GridSettingsEditor
                helpers={this.props.helpers}
                entityconfiguration={this.props.entityconfiguration}
                popovermgr={this.props.popovermgr}
                cmscontext={this.props.cmscontext}
                i18n={this.props.i18n}
                i18nHelper={this.props.i18nHelper}
                titlePage={this.props.title}
                entityPageComponent={this.props.entityPageComponent}
                columnsmgr={this.columnsmgr}
                template={this.props.template}
                selectedColumns={columnKeys}
                entityRights={this.props.entityRights}
                filteredExportQueryOptions={this.getExportFilteredQueryOptions}
                isOpen={this.props.showSettings}
                onChange={this.props.onShowSettings}
                entityConfs={this.props.entityConfs}
                runMode={this.props.runMode}
            />;
        }

        return <LoadingState isLoading={!this.state.isReady}>
            <div className="gridWrapper">
                <div className="gridInner">
                    <div id="grid" ref={this.gridRef} onContextMenu={this.showMenu} />
                </div>
                {tirette}
                <div className={"norowtodisplay" + ((!this.state.isFetching && !this.state.count_rows) ? ' norow' : '')}>
                    <AppTextLabel i18nService={this.props.i18n} i18n="entity.norowtodisplay" />
                </div>
            </div>
        </LoadingState>;
    }

    toggleEnableSelectionSub = (bool) => {
        this.enableSelectionSub = bool;
    };
}

/* eslint-disable max-classes-per-file */
import type { Entities } from '@inwink/entities/entities';
import { Link } from 'react-router-dom';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { React, AppAsyncButton, AppTextLabel, DisplayDate, TransitionGroup, Loader } from '../../commons';
import { SimpleFadeTransition } from "../transitions";
import type { IAppShellUserProps } from './appshelluser';
import { notificationsActions, getLocation } from '../../services/notificationservice';
import { EntityIcon } from '../ui/entityicon';
import { getExportFileAccess } from '../../api/file';

import './appshelluser.notifs.less';

export interface IAppShellNotificationsPopoverProps extends IAppShellUserProps {
    onCompleted?: (res?) => void;
}

export class AppShellNotificationsPopover extends React.Component<IAppShellNotificationsPopoverProps, any> {
    constructor(props: IAppShellNotificationsPopoverProps) {
        super(props);
        this.state = {
            notifications: props.notifications
        };
    }

    componentDidUpdate(prevProps: IAppShellNotificationsPopoverProps) {
        if (prevProps.notifications !== this.props.notifications) {
            this.setState({ notifications: this.props.notifications });
        }
    }

    refresh = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        return this.props.notificationsActions.checkUnread() as any;
    };

    dismissAll = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const prom = this.props.notificationsActions.dismissAll() as any;
        return prom.then(() => {
            this.props.onCompleted();
        });
    };

    removeItem = (item, close) => {
        const allnotifs = this.state.notifications ? [...this.state.notifications] : [];
        const idx = allnotifs.indexOf(item);
        if (idx >= 0) {
            allnotifs.splice(idx, 1);
            this.setState({ notifications: allnotifs });
            if (close || allnotifs.length === 0) {
                this.props.onCompleted();
            }
        }
    };

    render() {
        let notifs;
        let footer;
        const classNames = ["appshellusernotifications"];
        if (this.state.notifications && this.state.notifications.length) {
            notifs = this.state.notifications.slice(0, 10).map((notif) => {
                return <SimpleFadeTransition key={notif.id}>
                    <AppShellNotificationItem
                        notification={notif}
                        notificationsActions={this.props.notificationsActions}
                        onRemove={this.removeItem}
                    />
                </SimpleFadeTransition>;
            });
        }

        if (this.props.event.eventdetail) {
            classNames.push("withfooter");
            footer = <footer>
                <Link
                    to={"/" + this.props.event.eventdetail.customerId + "/e/" + this.props.event.eventid + "/mynotifications"}
                    onClick={() => this.props.onCompleted()}
                >
                    <AppTextLabel i18n="adminnotifications.mynotifications" />
                </Link>
            </footer>;
        }

        return <div className={classNames.join(" ")}>
            <header>
                <AppAsyncButton onClick={this.refresh}><AppTextLabel i18n="adminnotifications.refresh" /></AppAsyncButton>
                <AppAsyncButton onClick={this.dismissAll}><AppTextLabel i18n="adminnotifications.dismissall" /></AppAsyncButton>
            </header>
            {/* <NotifItemWrapper pose={this.state.itemVisibility}>
                {notifs}
            </NotifItemWrapper> */}
            <TransitionGroup component="section">
                {notifs}
            </TransitionGroup>
            {footer}
        </div>;
    }
}

interface IAppShellNotificationItemProps {
    notification: Entities.IAdminNotification;
    notificationsActions?: typeof notificationsActions;
    onRemove: (notification: Entities.IAdminNotification, close: boolean) => void;
}

class AppShellNotificationItem extends React.Component<IAppShellNotificationItemProps, any> {
    // _dismiss(close){
    //     var prom = this.props.notificationsActions.dismiss(this.props.notification.id) as any;
    //     return prom.then(() => {
    //         this.props.onRemove(this.props.notification, close);
    //     });
    // }

    dismiss = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        const delayed = new Promise((resolve) => setTimeout(resolve, 400));
        return (this.props.notificationsActions.dismiss(this.props.notification.id, delayed) as any).then(() => {
            this.props.onRemove(this.props.notification, false);
        });
    };

    itemClicked = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const n = this.props.notification;

        this.props.onRemove(this.props.notification, true);
        const location = getLocation(this.props.notification.customerId,
            this.props.notification.authTenantId,
            this.props.notification.eventId, this.props.notification.communityId,
            n.relatedToKind, n.relatedToId, n.relatedToParentId);
        if (location) {
            inwink.history.push(location);
        }
    };

    downloadFile = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const notif = this.props.notification;
        if (notif.messageData && notif.messageData.Blob) {
            let anchor;
            const oldanchor = document.getElementById("exportlink");
            if (oldanchor != null) {
                anchor = oldanchor;
            } else {
                anchor = document.createElement("A") as HTMLAnchorElement;
                anchor.id = 'exportlink';
                document.body.insertBefore(anchor, document.getElementById("approot"));
            }

            return getExportFileAccess(this.props.notification.eventId,
                this.props.notification.communityId,
                this.props.notification.authTenantId,
                notif.messageData.Blob.FileName).then((token) => {
                anchor.href = token.url + token.sas;
                anchor.download = notif.messageData.Blob.Title + ".xlsx";
                anchor.click();
            }, (err) => {
                console.error(err);
                return Promise.reject();
            });
        }
        console.warn("no file to download !");
        return Promise.reject();
    };

    render() {
        let title;
        let loading;
        let download;
        let onClick = null;
        if (this.props.notification.state === "Running") {
            loading = <div className="loader"><Loader message="" /></div>;
        }

        if (this.props.notification.title) {
            title = <h4 className="title">{this.props.notification.title}</h4>;
        }

        if (this.props.notification.relatedToKind !== "DataExport") {
            onClick = this.itemClicked;
        }

        if (this.props.notification.relatedToKind === "DataExport" && this.props.notification.notificationType === "exportend") {
            download = <div className="download">
                <AsyncButton onClick={this.downloadFile}>
                    <i className="inwinkbo-import" /> <AppTextLabel i18n="actions.download" />
                </AsyncButton></div>;
        }

        return (
            <div className={"notification" + (onClick ? " clickable" : "")} onClick={onClick}>
                <div className="notification-content">
                    <div className="icon">
                        <EntityIcon entityName={this.props.notification.relatedToKind} defaultIcon="inwink-idea" /></div>
                    <div className="detail">
                        <DisplayDate
                            component="div"
                            className="date"
                            date={this.props.notification.messageDate}
                            format="calendar"
                        />
                        {title}
                        <div className="message">{this.props.notification.message || "n/a"}</div>
                        {download}
                    </div>
                    {loading}
                    <div className="actions">
                        <AppAsyncButton className="inwink-cancel" onClick={this.dismiss} />
                    </div>
                </div>
                {AppShellNotificationProgress(this.props)}
            </div>
            // </NotifItem>
        );
    }
}

function AppShellNotificationProgress(props: IAppShellNotificationItemProps) {
    const progress = props.notification.progress;
    if (props.notification.state === 'Ended'
    || props.notification.state === 'Error' || progress == null || progress === undefined) {
        return null;
    }

    const progressValue = (progress || 0) + '%';

    return <div className="notification-progress">
        <div className="notification-progress-bar">
            <div className="progress-value" style={{ width: progressValue }} />
        </div>
        <div className="notification-progress-value">{progressValue}</div>
    </div>;
}

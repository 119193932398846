export interface ITracker {
    trackPage?: (location: string) => void;
    trackAction?: (category: string, action: string, label?: string) => void;
    trackError?: (err) => void;
}

let _trackers: ITracker[] = [];

export function registerTrackers() {
    _trackers = [];
    let res = [];

    res.push(appInsightTracker());

    res = res.filter((t) => !!t);

    if (res.length) {
        return res;
    }
}

export function registerTracker(tracker: ITracker) {
    _trackers.push(tracker);
    return () => {
        const idx = _trackers.indexOf(tracker);
        if (idx >= 0) {
            _trackers.splice(idx, 1);
        }
    };
}

export const tracker = {
    trackPage(location) {
        _trackers.forEach((t) => {
            try {
                if (t.trackPage) { t.trackPage(location); }
            } catch (ex) {
                console.error(ex);
            }
        });
    },

    trackAction(category: string, action: string, label?: string) {
        _trackers.forEach((t) => {
            try {
                if (t.trackAction) { t.trackAction(category, action, label); }
            } catch (ex) {
                console.error(ex);
            }
        });
    }
} as ITracker;

declare let appInsights;
function appInsightTracker() {
    const ttracker = {
        trackPage(location) {
            if (typeof appInsights === "undefined") return;

            appInsights.trackPageView(location);
        },

        trackAction(category: string, action: string, label?: string) {
            if (typeof appInsights === "undefined") return;

            appInsights.trackEvent(category + ":" + action + (label ? ":" + label : ""));
        }
    } as ITracker;

    return registerTracker(ttracker);
}

import * as React from 'react';
import type { IEntityPageConfiguration } from "../entitypagev3/entitypagebase.props";

export interface IEntityPickerProps {
    multiselect?: boolean;
    disableAddNew?: boolean;
    entityName: string;
    entityConfiguration?: IEntityPageConfiguration;
    entityArgs?: any;
    selection: any[];
    readOnly?: boolean;
    matchKey?: string;
    excludes?: string[];
    expands?: string[];
    modaleTitle?: string | ((props?: any) => any);
    onChange: (selectedIds: string[], selected: any[]) => void;
    forceDefaultView?: boolean;
}

let component = null;

export const EntityPicker = (props: IEntityPickerProps) => {
    if (component) {
        return <React.Suspense fallback={<div />}>{React.createElement(component, props)}</React.Suspense>;
    }
    return null;
};

export function setEntityPicker(pickercomponent: any) {
    component = pickercomponent;
}

import * as React from 'react';
import { EntityForm, IEntityFormState } from "@inwink/entityform";
import type { Entities } from "@inwink/entities/entities";
import type { IFileInputManager } from "@inwink/fileutils/fileapi";
import { AppTextLabel, LoadingState } from '../../commons';
import { customEditors } from "../entityform";
import { EntityFieldsPicker } from "../pickers/entityfields";
import type { ICMSContext } from "../cmscontext";
import { ToggleInput } from '../ui/configinputs';

interface IEntityUpdateAllPageStep1Props {
    cmscontext: ICMSContext;
    entityname: string;
    entity: any;
    populateWithMergeArrayEnabled: boolean;
    fileInputManager?: IFileInputManager;
    onEntityChanged?: (entity: any, isvalid: boolean) => void;
    onValidationChanged?: (isValid: boolean) => void;
    entitytemplate: Entities.IEntityTemplate;
    selectedFields: string[];
    currenttemplate: Entities.IEntityTemplate;
    customFormComponent?: any;
    onFieldChanged: (fields: string[]) => void;
    onChangePopulateWithMergeArray: (value: boolean) => void;
    hideFields: string[];
}

export function EntityUpdateAllPageStep1(props: IEntityUpdateAllPageStep1Props) {
    const {
        cmscontext,
        entity,
        entityname,
        populateWithMergeArrayEnabled,
        fileInputManager,
        onEntityChanged,
        onFieldChanged,
        onChangePopulateWithMergeArray,
        entitytemplate,
        currenttemplate,
        selectedFields,
        customFormComponent,
        hideFields
    } = props;

    const hasMultiSelectTypeInCurrentTemplate = () => {
        return currenttemplate?.fields?.length > 0
            && currenttemplate?.fields.some((t) => t.type === 'Array' || t.type === 'MultiSelectList');
    };

    return (
        <div className="formpage modal-content-padding">
            <LoadingState isLoading={!entitytemplate}>
                {
                    entitytemplate && (
                        <div className="formpage updateallform entityform">
                            <AppTextLabel component="p" i18n="updateall.explainselectedproperties" />
                            <EntityFieldsPicker
                                classNames={["updateall-modal-fields"]}
                                cmscontext={cmscontext}
                                fields={selectedFields}
                                onChange={onFieldChanged}
                                entityname={entityname}
                                excludeTypes={["image", "entity", "guid"]}
                                entitytemplate={entitytemplate}
                                multiselect={true}
                                excludeFields={hideFields}
                            />
                            {currenttemplate.fields.length > 0
                                ? <AppTextLabel component="p" i18n="updateall.explaintemplate" /> : null}
                            <EntityForm
                                fileinputmanager={fileInputManager || cmscontext.fileInputManager}
                                eventid={cmscontext.relatedTo}
                                displayLanguage={cmscontext.displayLanguage}
                                languages={cmscontext.languages}
                                entityName={entityname}
                                entityTemplate={currenttemplate}
                                entityValue={entity}
                                onValueChanged={(ent, formstate: IEntityFormState) => onEntityChanged(ent, formstate.isValid)}
                                showValidations={true}
                                customComponentProvider={customEditors}
                                customFormComponent={customFormComponent}
                                disableAppyFor={true}
                                datacontext={props}
                            />
                            {hasMultiSelectTypeInCurrentTemplate() && (
                                <ToggleInput
                                    i18n="massupdate.populatewithmergearrayenabled.toggle"
                                    value={populateWithMergeArrayEnabled}
                                    onChange={(x) => onChangePopulateWithMergeArray(x)}
                                    canEditTrad={false}
                                />
                            )}
                        </div>
                    )
                }
            </LoadingState>
        </div>
    );
}

import { React, toastError, toastSuccess, IPopoverManager, WithPopoverManager } from '../../commons';
import { States } from 'services/services';
import { connectwith } from '@inwink/react-utils/decorators';
import { AssetsPicker, IAssetDataSource } from '@inwink/assetspicker';
import { assetsPickerModal, IAssetsCatalogProps } from '@inwink/assetspicker/assetspicker';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n';

// import './eventassets.less';

// aller "voler" le code ici pour le Drag&Drop : https://github.com/okonet/react-dropzone/blob/master/src/index.js

// export const types = {
//     image: ["png", "jpg", "jpeg", "gif", "ico"],
//     font: ["ttf", "eot", "woff", "otf", "svg"],
//     touch: ['png']
// };

// // export const maxSize = 5000000;
// export const maxSize = 5 * 1024 * 1024;

interface IEventAssetPickerProps {
    i18nHelper?: Entities.i18nHelper;
    popovermgr?: IPopoverManager;
    value: string;
    onChange: (url: string) => void;
    datasource: IAssetDataSource;
    // fileInputManager: IAssetFileInputManager;
    displaymode?: "preview" | "filename";
    disabledDirectUrl?: boolean;
    i18n?: States.i18nState;
    forceReadOnly?: boolean;
    cleanFileName?: boolean;
}

export function eventAssetPickerModal(
    popovermgr: IPopoverManager,
    datasource: IAssetDataSource,
    i18n: Entities.i18nService,
    currentvalue?,
    displaymode?,
    disabledDirectUrl?) {
    let props: IAssetsCatalogProps = {
        popovermgr: popovermgr,
        disabledDirectUrl: disabledDirectUrl,
        datasource: datasource,
        displaymode: displaymode || "preview",
        value: currentvalue,
        notifyError: (code) => toastError(i18n, code),
        notifySuccess: (code) => toastSuccess(i18n, code)
    };
    // return popovermgr.modalPortal(EventAssetsCatalog, props, "eventassets-modal-catalog");
    return assetsPickerModal(props);
}

@connectwith((state: States.IAppState) => ({ i18n: state.i18n }), null)
@withI18nHelper()
export class EventAssetPicker extends React.Component<IEventAssetPickerProps, any> {
    render() {
        return <WithPopoverManager>
            {(popovermgr) => <AssetsPicker
                displaymode={this.props.displaymode}
                disabledDirectUrl={this.props.disabledDirectUrl}
                readOnly={this.props.forceReadOnly}
                onChange={this.props.onChange}
                datasource={this.props.datasource}
                notifyError={(code) => toastError(this.props.i18nHelper.i18n, code)}
                notifySuccess={(code) => toastSuccess(this.props.i18nHelper.i18n, code)}
                value={this.props.value}
                cleanFileName={this.props.cleanFileName}
                popovermgr={this.props.popovermgr || popovermgr} />
            }
        </WithPopoverManager>;
    }
}

import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import { IDataSourceV3 } from '@@api/base/datasource';
import { getSetting } from '../../../services/usersettings';
import type { States } from '../../../services/services';

export interface ICustomerRecentContentsProps {
    user: States.IAppUserState;
    reverse?: boolean;
    excludes?: string[];
    customerId?: string;
    trigger?: any;
    children?: (elements: IRecentContentsElement[], isLoading: boolean) => any;
}

// export function CustomerRecentContents(props: ICustomerRecentContentsProps) {
//     return <CMSContextProvider.Consumer>
//         {(cmscontext) => <i18NHelperContext.Consumer>
//             {(i18nhelper) => <CustomerRecentContentsComponent {...props} i18nHelper={i18nhelper} cmscontext={cmscontext} />}
//         </i18NHelperContext.Consumer>}
//     </CMSContextProvider.Consumer>;
// }

interface IRecentContentsComponentProps extends ICustomerRecentContentsProps {
    i18nHelper?: Entities.i18nHelper;
    eventDatasource?: IDataSourceV3<Entities.IEntity>;
    communityDatasource?: IDataSourceV3<Entities.IEntity>;
    maxContents?: number;
}

export interface IRecentContentsState {
    elements?: IRecentContentsElement[];
    loading: boolean;
}

export interface IRecentContentsElement { type: 'event' | 'community', element: any }

interface IRecentContentsInitialElement {
    event?: any;
    community?: any;
    recentEntry: {
        lastVisitDate?: moment.Moment;
    }
}

export class RecentContents
    extends React.Component<IRecentContentsComponentProps, IRecentContentsState> {
    
    unmounted = false;

    constructor(props: IRecentContentsComponentProps) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentWillUnmount(): void {
        this.unmounted = true;
    }

    componentDidMount() {
        setTimeout(() => {
            this.computeData(this.props);
        }, 10);
    }

    computeData(props) {
        if (this.unmounted) {
            return;
        }
        this.setState({
            loading: true,
            elements: null
        }, () => {
            Promise.all([
                this.loadRecentEventsPreferences(props),
                this.loadRecentCommunitiesPreferences(props)
            ]).then((res2) => {
                const events = res2[0];
                const communities = res2[1];
                const initialElements: IRecentContentsInitialElement[] = [];
                if (events) {
                    initialElements.push(...events);
                }
                if (communities) {
                    initialElements.push(...communities);
                }

                let elements = initialElements.filter((e) => (!!e)).sort((a, b) => {
                    if (a.recentEntry.lastVisitDate && b.recentEntry.lastVisitDate) {
                        return moment(b.recentEntry.lastVisitDate).diff(a.recentEntry.lastVisitDate);
                    } if (a.recentEntry.lastVisitDate) {
                        return -1;
                    } if (b.recentEntry.lastVisitDate) {
                        return 1;
                    }
                    return 0;
                });

                if (this.props.reverse) {
                    elements = elements.reverse();
                }

                if (this.props.maxContents) {
                    elements = elements.slice(0, this.props.maxContents);
                }

                if (this.unmounted) {
                    return;
                }
                this.setState({
                    loading: false,
                    elements: elements.map((e) => {
                        return {
                            type: e.event ? 'event' : 'community',
                            element: e.event || e.community
                        };
                    })
                });
            });
        });
    }

    loadEvents(recentEvents: Entities.IMyEvent[]) {
        const expression = {
            or: recentEvents.map((evt) => {
                return { name: "id", op: "eq", val: evt.id };
            })
        };

        return this.props.eventDatasource.query({
            selects: {
                $all: true,
                stat: {
                    $all: true
                },
                // authTenant: {
                //     name: true
                // }
            },
            expression: expression
        }).then((res) => {
            if (res && res.data) {
                return recentEvents.map((evt) => {
                    return { event: res.data.filter((loadedEvt) => loadedEvt.id === evt.id)[0], recentEntry: evt };
                }).filter((evt) => (!!evt?.event));
            }
        }, (err) => {
            console.error("error loading events", err);
        }).then((events) => {
            return events;
        });
    }

    loadRecentEventsPreferences(props: IRecentContentsComponentProps) {
        if (!props.user.currentUser) {
            return Promise.resolve(null);
        }

        return getSetting("recentEvents-" + props.user.currentUser.id).then((recentEventsStr) => {
            let recentEvents: Entities.IMyEvent[] = [];
            if (recentEventsStr) {
                recentEvents = JSON.parse(recentEventsStr);
                if (this.props.customerId) {
                    recentEvents = recentEvents.filter((e) => e.customerId === this.props.customerId);
                }
                if (this.props.excludes) {
                    recentEvents = recentEvents.filter((e) => {
                        return !this.props.excludes.some((eid) => eid === e.id);
                    });
                }
            }
            if (recentEvents && recentEvents.length) {
                if (!this.props.eventDatasource) {
                    return recentEvents.map((e) => {
                        return {
                            event: e,
                            recentEntry: e
                        };
                    });
                }
                return this.loadEvents(recentEvents);
            }
            return [];
        });
    }

    loadCommunities(recentCommunities: Entities.IEntity[]) {
        const expression = {
            or: recentCommunities.map((evt) => {
                return { name: "id", op: "eq", val: evt.id };
            })
        };

        return this.props.communityDatasource.query({
            selects: {
                $all: true,                
                // authTenant: {
                //     name: true
                // }
            },
            expression: expression
        }).then((res) => {
            if (res && res.data) {
                return recentCommunities.map((evt) => {
                    return { community: res.data.filter((loadedCommunity) => loadedCommunity.id === evt.id)[0],
                        recentEntry: evt };
                }).filter((evt) => (!!evt?.community));
            }
        }, (err) => {
            console.error("error loading communities", err);
        }).then((communities) => {
            return communities;
        });
    }

    loadRecentCommunitiesPreferences(props: IRecentContentsComponentProps) {
        if (!props.user.currentUser) {
            return Promise.resolve(null);
        }

        return getSetting("recentCommunities-" + props.user.currentUser.id).then((recentEventsStr) => {
            let recentCommunities = [];
            if (recentEventsStr) {
                recentCommunities = JSON.parse(recentEventsStr);
                if (this.props.customerId) {
                    recentCommunities = recentCommunities.filter((e) => e.customerId === this.props.customerId);
                }
                if (this.props.excludes) {
                    recentCommunities = recentCommunities.filter((e) => {
                        return !this.props.excludes.some((eid) => eid === e.id);
                    });
                }
            }
            if (recentCommunities && recentCommunities.length) {
                if (!this.props.communityDatasource) {
                    return recentCommunities.map((e) => {
                        return {
                            community: e,
                            recentEntry: e
                        };
                    });
                }
                return this.loadCommunities(recentCommunities);
            }
            return [];
        });
    }

    componentDidUpdate(prevprops: IRecentContentsComponentProps) {
        if (
            (prevprops.user.currentUser !== this.props.user.currentUser)
            || (prevprops.trigger !== this.props.trigger)) {
            this.computeData(this.props);
        }
    }

    render() {
        if (!this.state.elements || !this.state.elements.length) {
            return null;
        }

        if (this.props.children) {
            return <>{this.props.children(this.state.elements, this.state.loading)}</>;
        }

        return null;
    }
}

import type { Entities } from '@inwink/entities/entities';
import { getRouteTargetPage } from '@inwink/react-utils/navigation/getroutetargetpage.community';
import { getPreviewUrl } from '../../commons';
import { ICommunityDetail } from '@@data/communities/communitydata';

export function openCommunityPreview(
    eventdetail: ICommunityDetail,
    _url = "",
    name = "inwinkpreview",
    pageAlias?,
    _pageType?
) {
    let pageType = _pageType;
    let url = _url;
    if (pageType === "contentpage") {
        pageType = "content";
    }
    const targetPage = getRouteTargetPage(pageAlias, null, null, true);
    if (pageAlias && pageType) {
        // remove 'page' when redirecting on root page
        if (targetPage.length === 0) { pageType = null; }
        url = (pageType ? pageType + '/' : "") + targetPage;
    }
    window.open(getPreviewUrl(eventdetail) + "/" + url, name, "");
}
/* eslint-disable max-classes-per-file */
import { Entities } from '@inwink/entities/entities';
import { React, bindActionCreators, connectwith } from '../../commons';
import type { IAppShellHeaderPartProps } from './appshellheader';
import { UserPopinContext, IUserPopinContext } from './userpopincontext';
import { notificationsActions } from '../../services/notificationservice';
import { States } from '../../services/services';
import { AppShellNotificationsPopover } from './appshelluser.notifs';
import { AppShellHeaderActionButton } from './appshellheaderactionbutton';

export interface IAppShellUserProps extends IAppShellHeaderPartProps {
    notifications?: Entities.IAdminNotification[];
    notificationsCount?: number;
    showNotifs?: boolean;
    notificationsActions?: typeof notificationsActions;
    afterClick: () => void;
    parent: any;
}

interface IAppShellUserContentProps extends IAppShellUserProps {
    userpopinContext: IUserPopinContext;
}
interface IAppShellUserContentState {
}

export function AppShellUser(props: IAppShellUserProps) {
    return <UserPopinContext.Consumer>
        {(userpopinContext) => <AppShellUserContent {...props} userpopinContext={userpopinContext} />}
    </UserPopinContext.Consumer>;
}

@connectwith(
    (state: States.IAppState) => ({
        notifications: state.notifications.notifications,
        notificationsCount: state.notifications.notificationsCount,
        showNotifs: state.notifications.showNotifs
    }),
    (dispatch) => ({ notificationsActions: bindActionCreators(notificationsActions, dispatch) })
)
class AppShellUserContent extends React.Component<IAppShellUserContentProps, IAppShellUserContentState> {
    notificationsPopover: any;

    showNotifsBtn = React.createRef<HTMLDivElement>();

    constructor(props: IAppShellUserContentProps) {
        super(props);
        this.state = {
        };
    }

    showNotifications = (arg?: React.MouseEvent<any>) => {
        if (arg && arg.preventDefault) {
            arg.preventDefault();
            arg.stopPropagation();
        }
        this.notificationsPopover = this.props.popovermgr.popoverPortal(this.props.parent || arg.target as HTMLElement,
            AppShellNotificationsPopover, this.props, "appshellnotifspopover");
        this.notificationsPopover.then(() => {
            this.notificationsPopover = null;
        });
        this.props.afterClick();
    };

    componentDidUpdate(prevProps: IAppShellUserProps) {
        if (this.notificationsPopover && prevProps.notifications !== this.props.notifications) {
            this.notificationsPopover.setProps(this.props);
        }

        if (prevProps.showNotifs !== this.props.showNotifs && !this.notificationsPopover) {
            this.showNotifications({ target: this.showNotifsBtn.current } as any);
        }
    }

    toggleProfile = (arg: React.MouseEvent<any>) => {
        if (arg) {
            arg.stopPropagation();
            arg.preventDefault();
        }
        this.props.userpopinContext.togglePopin();
        this.props.afterClick();
    };

    render() {
        if (!this.props.user?.currentUser) {
            return <div className="profile empty" />;
        }

        return <>
            <AppShellHeaderActionButton
                ref={this.showNotifsBtn}
                icon="inwink-bell"
                title="appheaderactions.shownotifications"
                className="toggleNotifications"
                badge={this.props.notificationsCount}
                onClick={this.showNotifications}
            />
            <AppShellHeaderActionButton
                icon="inwink-account"
                title="appheaderactions.showprofile"
                className="toggleProfile"
                onClick={this.toggleProfile}
            />
        </>;
    }
}

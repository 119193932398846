import type { Entities } from '@inwink/entities/entities';
import { IPopoverManager, React } from '../../../../commons';
import { ICMSContext, CMSContextProvider } from "../../../cmscontext";
import type { States } from '../../../../services/services';
import type { IEventEntityGridArgs } from '../../../../entities/event/gridconfiguration';
import type { IEntityPageConfiguration } from '../entitypagebase.props';

export interface IExportModalProps {
    popovermgr: IPopoverManager;
    i18n: States.i18nState;
    i18nHelper?: Entities.i18nHelper;
    user: States.IAppUserState;
    cmscontext: ICMSContext;
    entityName: string;
    entityArgs: IEventEntityGridArgs;
    entityconfiguration?: IEntityPageConfiguration;
    entityConfs: Record<string, IEntityPageConfiguration>;
    expression: any;
    order: any;
    onCompleted?: (res?) => void;
    selectedColumns: string[];
    selection?: any[];
    options?: {
        resolveBlob: boolean;
        disableGuid: boolean;
        isImportMode: boolean;
    };
}

export interface IOpenExport {
    popovermgr: IPopoverManager;
    i18n: States.i18nState;
    // get i18nHelperContext instead of passing it
    user: States.IAppUserState;
    // cmscontext: ICMSContext;
    entityPageConfiguration?: IEntityPageConfiguration;
    entityName: string;
    entityArgs: IEventEntityGridArgs;
    entityConfs: Record<string, IEntityPageConfiguration>;
    expression: any;
    order: any;
    selectedColumns: string[];
    selection?: any[];
    options?: {
        resolveBlob?: boolean;
        disableGuid?: boolean;
        isImportMode?: boolean;
        CMSContext?: {eventId: string, customerId: string}
    };
}

export function openExport(_exportargs: IOpenExport) {
    const exportargs = _exportargs;
    exportargs.selection = exportargs.selection ? exportargs.selection : [];
    if (!exportargs.options) {
        exportargs.options = {
            resolveBlob: false,
            disableGuid: false,
            isImportMode: false
        };
    } else {
        exportargs.options.resolveBlob = exportargs.options.resolveBlob ? exportargs.options.resolveBlob : false;
        exportargs.options.disableGuid = exportargs.options.disableGuid ? exportargs.options.disableGuid : false;
        exportargs.options.isImportMode = exportargs.options.isImportMode ? exportargs.options.isImportMode : false;
    }

    return import(/* webpackChunkName: "entitypagev3-exportmodal" */ "./exportmodal").then((exportmodalmod) => {
        return import(/* webpackChunkName: "entitypagev3-cmscontextwrapper" */ "../../../../components/cmscontext.wrapper")
            .then((cmscontextwrappermod) => {
                return exportargs.popovermgr.modalPortal(
                    (props) => {
                        let _c = <CMSContextProvider.Consumer>
                            {(cmscontext) => React.createElement(exportmodalmod.ExportModal, { ...props, cmscontext, ...exportargs })}
                        </CMSContextProvider.Consumer>;

                        if (exportargs.options.CMSContext) {
                            _c = <cmscontextwrappermod.CMSContextWrapper
                                customerId={exportargs.options.CMSContext.customerId}
                                eventId={exportargs.options.CMSContext.eventId}
                            >
                                {_c}
                            </cmscontextwrappermod.CMSContextWrapper>;
                        }

                        return _c;
                    },
                    null, "exportmodalv3popover"
                );
        });
    });
}

export function openFileExport(
    popovermgr: IPopoverManager,
    i18n: States.i18nState,
    i18nHelper: Entities.i18nHelper,
    user: States.IAppUserState,
    cmscontext,
    entityName,
    entityArgs,
    entityConfs: Record<string, IEntityPageConfiguration>,
    expression: any,
    order: any,
    selectedColumns: string[]
) {
    return import(/* webpackChunkName: "modexportfile" */ './exportfilemodal')
    .then((mod) => {
        const props: any = {
            popovermgr,
            i18n,
            i18nHelper,
            user,
            cmscontext,
            entityName,
            entityArgs,
            expression,
            order,
            selectedColumns,
            entityConfs
        };

        return popovermgr.modalPortal(mod.ExportFileModal, props, "exportfilemodalpopover")
    });
}

import { React, AppTextLabel } from '../../../commons';
import * as moment from 'moment';
import { DatePicker } from './datepicker';
import { getPropertyValue } from '@inwink/utils/methods';
import { immutable } from '@inwink/utils/immutable';

interface IDateInputBaseProps {
    canEditTrad: boolean;
    i18n: string;
    minDate?: moment.Moment;
    type? : 'date' | 'time' | 'datetime';
}

export interface IDateInputProps extends IDateInputBaseProps {
    value: moment.Moment;
    onChange: (val: moment.Moment) => void;
}

export class DateInput extends React.PureComponent<IDateInputProps, any> {
    onChange = (date?: any) => {
        this.props.onChange(date);
    }

    render() {
        return <div className="fieldbloc">
            <AppTextLabel component="label" i18n={this.props.i18n} />
            <AppTextLabel component="div" className="field-desc" i18n={this.props.i18n + ".desc"} defaultText={this.props.canEditTrad ? this.props.i18n + ".desc" : ""} />
            <DatePicker selected={this.props.value} minDate={this.props.minDate} onChange={this.onChange} type={this.props.type || "date"} />
        </div>;
    }
}

export interface IDateInputPropertyProps extends IDateInputBaseProps {
    data: any;
    path: string;
    onChange: (data:any) => void;
}

export class DateInputProperty extends React.PureComponent<IDateInputPropertyProps, any> {
    onChange = (date?: any) => {
        let data = immutable.set(this.props.data, this.props.path, date);
        this.props.onChange(data);
    }

    render() {
        let val = getPropertyValue(this.props.data, this.props.path);
        return <DateInput {...this.props} value={val} onChange={this.onChange} />;
    }
}